import React, {useEffect, useMemo, useState} from 'react';
import './Rosters.css';
import {FilterSwitches} from '../../containers/FilterTag';
import {Button, Card, Col, Input, Modal, Row, Tabs} from 'antd';
import {ReactComponent as BusPlus} from '../../assets/icons/BusPlus.svg';
import {ReactComponent as BusStat} from '../../assets/icons/BusStat.svg';
import {ReactComponent as BusQuote} from '../../assets/icons/BusQuote.svg';
import {debounce, find, values} from 'lodash';
import dayjs from 'dayjs';
import {useAppContext} from '../../libs/contextLib';
import {SyncOutlined} from '@ant-design/icons';
import WeeklyRosters2 from "./WeeklyRosters2";
import DateSelector from "./DateSelector";

const {Search} = Input;

function Rosters2() {
    const {setBreadcrumb, isAdmin} = useAppContext();
    const [mode, setMode] = useState('');
    const [view, setView] = useState('weekly');
    const [isLoading, setLoading] = useState(true);

    const [selectedDate, setSelectedDate] = useState(dayjs());


    const [showDashboards, setShowDashboards] = useState(true);
    const [showUnallocated, setShowUnallocated] = useState(false);
    const [unAllocatedCount, setUnAllocatedCount] = useState(0);
    const [totalSecs, setTotalSecs] = useState(0);
    const [driverHours, setDriverHours] = useState(0);
    const [wages, setWages] = useState(0);
    const [overtime, setOvertime] = useState(0);
    const [otpercentage, setOTPercentage] = useState(0);


    useEffect(() => {
        const prefix = view === 'weekly' ? 'Weekly' : 'Daily';
        const breadcrumb = mode === 'vehicle' ? 'Vehicle Roster' : mode === 'staff' ? 'Staff Roster' : 'Rosters'
        setBreadcrumb(`${prefix} ${breadcrumb}`);
    }, [mode]);


    return (
        <>
            <div className="d-flex align-items-center justify-content-end filter-options-main top-float">
                <div className="d-flex justify-content-end w-separator" style={{gap: '10px'}}>
                    <Button type="primary" className="icon-button" onClick={() => {
                        setMode('vehicle');
                    }}> Vehicles</Button>
                    <Button type="primary" className="icon-button" onClick={() => {
                        setMode('staff');
                    }}> Staff</Button>
                </div>
                <div className="filter-dropdown w-separator">
                    <DateSelector initialDate={selectedDate} onChange={setSelectedDate} view={view}/>
                </div>
                {/* todo - uncomment this! */}
                {/*<div className="justify-content-end">*/}

                {/*    <Button onClick={() => {*/}
                {/*        setView('daily');*/}
                {/*    }} type="secondayr" className={`icon-button ${view === 'daily' ? 'active' : ''}`}>Daily</Button>*/}

                {/*    <Button onClick={() => {*/}
                {/*        setView('weekly');*/}
                {/*    }} type="primary" className={`icon-button ${view === 'weekly' ? 'active' : ''}`}>Weekly</Button>*/}

                {/*    /!*<Button type="primary"*!/*/}
                {/*    /!*        className={`icon-button btn-filled`}*!/*/}
                {/*    /!*        onClick={async () => {*!/*/}
                {/*    /!*            setCalculatingScenario(true);*!/*/}
                {/*    /!*            // const newScenario1 = await JobScenario.smartAllocate(unallocatedShiftBats, loadedVehicles, selectedDate);*!/*/}
                {/*    /!*            const newScenario2 = await JobScenario.allocate(unallocatedShiftBats, loadedVehicles, selectedDate);*!/*/}
                {/*    /!*            setScenarios([...scenarios, newScenario2]);*!/*/}
                {/*    /!*            setCalculatingScenario(false);*!/*/}
                {/*    /!*        }}>Auto scenarios</Button>*!/*/}
                {/*</div>*/}
            </div>
            <div className="dispatch-list">
                <>
                    <Row gutter={[20, 20]}
                         className={`Dashboards mb-4 ${showDashboards ? 'show' : 'hide'}`}>
                        <Col xs={24} lg={6} onClick={() => {
                            setShowUnallocated(!showUnallocated);
                        }}>
                            <Card bordered={false}
                                  className="card-main card-stats card-stats-flex">
                                <BusPlus/>
                                <div>
                                    <h4>Unallocated</h4>
                                    <div className="text-lg">{isLoading ?
                                        <SyncOutlined spin/> : unAllocatedCount}</div>
                                </div>
                            </Card>
                        </Col>
                        <Col xs={24} lg={12}>
                            <Card bordered={false}
                                  className="card-main card-stats card-stats-profit card-stats-flex">
                                <BusStat/>
                                <div>
                                    <h4>Driver Hours</h4>
                                    <div className="text-lg">{driverHours}</div>
                                </div>
                                <div>
                                    <h4>Overtime</h4>
                                    <div className="text-lg">{overtime}</div>
                                </div>
                                <div>
                                    <h4>Overtime %</h4>
                                    <div className="text-lg">{otpercentage}%</div>
                                </div>
                            </Card>
                        </Col>
                        <Col xs={24} lg={6}>
                            <Card bordered={false}
                                  className="card-main card-stats card-stats-flex">
                                <BusQuote/>
                                <div>
                                    <h4>Total Wages</h4>
                                    <div className="text-lg">${wages.toFixed(2)}</div>
                                </div>
                            </Card>
                        </Col>
                    </Row>

                    <div
                        className="d-flex align-items-center justify-content-between filter-options-main">
                        <div className="d-flex align-items-center" style={{gap: '10px'}}>
                            {/* <Button type="primary" className="icon-button" icon={<PlusOutlined/>} onClick={addNewBlock}>New
                            Block</Button> */}
                            <Button type="primary"
                                    className={`icon-button btn-filled btn-error ${showUnallocated ? 'active' : ''}`}
                                    onClick={() => {
                                        setShowUnallocated(!showUnallocated);
                                    }}><i>{isLoading ? <SyncOutlined
                                spin/> : unAllocatedCount}</i> Unallocated</Button>
                        </div>
                        <div className="d-flex align-items-center">
                            <div className="justify-content-end w-separator">
                                <Search
                                    id="basic-addon1"
                                    style={{
                                        width: 200,
                                    }}
                                    allowClear={true}
                                    // onChange={(e) => setFilterDebounced(e.target.value)}
                                />
                            </div>
                            <div className="filter-switches">
                                {/*<FilterSwitches filterItems={filterItems} filterBy={filterBy}*/}
                                {/*                setFilterBy={setFilterBy}/>*/}
                            </div>
                        </div>
                    </div>

                    <WeeklyRosters2
                        isLoading={isLoading}
                        setLoading={setLoading}
                        startDate={selectedDate.startOf('week')}
                        endDate={selectedDate.endOf('week')}
                        showUnallocated={showUnallocated}
                        setUnAllocatedCount={setUnAllocatedCount}
                    />

                </>

            </div>

        </>
    );
}



export default React.memo(Rosters2);
