import {ulid} from "ulid";
import dayjs from "../dayjs";
import {DATE_STRING} from "./schedule";


const cat1Types = ['Distracting the driver by persistent noise or calling out to the driver',
    'Displaying materials of an offensive, obscene, pornographic, threatening, abusive or defamatory nature on a device such as mobile phone, iPad or laptop computer',
    'Failing to wear the seat belt properly adjusted and fastened, if one is available',
    'Failing to show a travel pass (except where passes are not issued)',
    'Eating or drinking (other than water) on the bus, unless for medical reasons or with the written permission of the bus operator',
    'Smoking a product e.g. tobacco or a similar substance, including e-cigarettes',
    'Using offensive or racist language',
    'Pressing the stop button continually',
    'Behaving so as to adversely affect the comfort or safety of other passengers',
    'Not offering seats to adult passengers including people with a disability, elderly or expectant mothers',
    'Playing music loudly and being a general nuisance',
    'Groups of students participating in Category 1 unacceptable behaviours.']

const ca2Types = [
    'Bullying, intimidation and harassment of other passengers',
    'Allowing any part of their body to protrude from the bus while the bus is in motion',
    'Stopping others from disembarking at their stop',
    'Verbally threatening the driver',
    'Standing on steps or in areas not set aside for standing and refusing to sit down',
    'Pushing and shoving when boarding or exiting the bus',
    'Swinging on bus handrails',
    'Throwing things inside or out of the bus',
    'Fighting with other passengers',
    'Causing damage to property in buses',
    'Spitting or discharging other bodily fluids (including urine and mucous)',
    'Exposing body parts',
    'Groups of students participating in Category 2 dangerous behaviours.']

const cat3Types = [
    'Pushing students out of the doors or windows',
    'Interfering with the driving controls or the emergency door release',
    'Assaulting the driver or other passengers',
    'Acting in a manner or participating in activities that may be deemed inappropriate or of a sexual nature',
    'Interfering with safety equipment',
    'Recklessly or negligently endangering the safety of other passengers or themselves',
    'Destruction of bus property',
    'Carrying a weapon (including replica weapons) or dangerous items',
    'Using matches, lighters or other flammable items'
]

export const IncidentStatus = {reported: "Reported", pending: 'Pending', closed: "Closed"}
export const IncidentCategory = {
    cat1: {label: 'Category 1 - Unacceptable behaviour', types: cat1Types, name: 'Unacceptable behaviour'},
    cat2: {label: 'Category 2 - Dangerous behaviour', types: ca2Types, name: 'Dangerous behaviour'},
    cat3: {
        label: 'Category 3 - Highly dangerous or life-threatening behaviour',
        types: cat3Types,
        name: 'Highly dangerous or life-threatening behaviour'
    }
}
export const CorrespondenceType = {verbal: {label: "Verbal"}, email: {label: "Email"}}
export const CorrespondenceRecipient = {
    guardian: {label: "Guardian", selectable: true},
    school: {label: "School"}
}
export const IncidentOutcome = {
    pending: {label: 'Pending', validFn: m => false},
    softWarning: {label: 'Soft warning', validFn: m => m.outcomeDate},
    hardWarning: {label: 'Hard warning', validFn: m => m.outcomeDate},
    suspended: {label: 'Suspended', expiry: true, validFn: m => m.outcomeDate && m.outcomeExpiry && m.outcomePeriod},
    banned: {label: 'Banned', validFn: () => true}
}

export class Correspondence {
    constructor(props) {
        this.id = ulid();
        this.label = null; // The label of the correspondence
        this.message = null; // The message sent in the correspondence
        this.type = "email"; // The type of correspondence
        this.recipient = null; // The CorrespondenceRecipient
        this.recipientId = null; // The id of the guardian involved
        this.date = dayjs(); // The date of the correspondence
        this.printDate = dayjs(); // The date of printing the correspondence
        Object.assign(this, props)

        if (typeof this.date === "string") {
            this.date = dayjs(this.date, DATE_STRING)
        }

        if (typeof this.printDate === "string") {
            this.printDate = dayjs(this.printDate, DATE_STRING)
        }
    }

    getContactInfo({schoolStops, guardians}) {
        const contacts = this.recipient === 'school' ? schoolStops :
            this.recipient === 'guardian' ? guardians : []

        // const contact = this.recipient === 'guardian' ? this.guardian : this.recipient === 'school' ? this.school : null
        const contact = contacts.find(c => [c.schoolStopId, c.guardianId].includes(this.recipientId))
        if (!contact) {
            return "No contact info"
        }
        let name = this.recipient === 'guardian' ? contact.name() : this.recipient === 'school' ? contact.stopName : null
        return `${name} ${this.type === 'email' ? contact.email :
            this.type === 'written' ? (contact.postalAddress || contact.address) :
                this.type === 'verbal' ? contact.phone : "No contact info"}`;
    }

    toJson() {
        return {...this, date: this.date.format(DATE_STRING), printDate: this.date.format(DATE_STRING)}
    }
}

export class Incident {
    constructor(props) {
        this.incidentId = ulid();
        this.referenceId = null; // User created ref number
        this.category = "cat1"; // IncidentCategory
        this.outcome = "pending"; // OutcomeStatus
        this.outcomeDate = null; // The expiry date of the outcome
        this.outcomeExpiry = null; // The expiry date of the outcome
        this.outcomePeriod = null; // The duration of the outcome
        this.employeeId = null; // THe id of the employee involved
        this.routeNumber = null; // The route on which the incident occurred
        this.lat = null; // The latitude of the incident
        this.lon = null; // The longitude of the incident
        this.label = null;  // The label of the incident
        this.description = null; // The description of the incident
        this.date = dayjs(); // The date of the incident
        this.duration = 0; // The duration of the incident
        this.media = []; // list of uploaded image URLs
        this.status = "reported"
        this.correspondences = []; // list of correspondence
        this.notifiedAuthorities = false; // Whether the authorities have been notified
        Object.assign(this, props)

        this.init();
    }

    init() {
        if (this.date && typeof this.date === "string") {
            this.date = dayjs(this.date, DATE_STRING)
        }
        if (this.outcomeDate && typeof this.outcomeDate === "string") {
            this.outcomeDate = dayjs(this.outcomeDate, DATE_STRING)
        }
        if (this.outcomeExpiry && typeof this.outcomeExpiry === "string") {
            this.outcomeExpiry = dayjs(this.outcomeExpiry, DATE_STRING)
        }
        if (this.correspondences?.length) {
            this.correspondences = this.correspondences.map(c => new Correspondence(c))
        }
    }

    outcomeDuration() {
        return this.outcomeExpiry && this.outcomeDate ? this.outcomeExpiry.diff(this.outcomeDate, 'days') : 0
    }

    validate() {
        return this.incidentId?.length && this.category && this.date && (this.outcome !== 'suspended' || (this.outcomeExpiry && this.outcomeDate))
    }

    toJson() {
        return {
            ...this,
            date: this.date ? this.date.format(DATE_STRING) : undefined,
            outcomeDate: this.outcomeDate ? this.outcomeDate.format(DATE_STRING) : undefined,
            outcomeExpiry: this.outcomeExpiry ? this.outcomeExpiry.format(DATE_STRING) : undefined,
            correspondences: this.correspondences.map(c => c.toJson())
        }
    }

}
