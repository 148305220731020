import React from 'react'
import {FEATURE} from "../../model/features";
import {Col, Row} from "react-bootstrap";
import {scheduleModelData} from "../../services/ModelService";
import {useAppContext} from "../../libs/contextLib";
import ScheduleName from "../../components/ScheduleName";
import EditScheduleView from "./EditScheduleView";
import {PREDEFINED_SCHEDULES, Schedule} from "../../model/schedule";
import EditableList from "../EditableList";
import {values} from "lodash";
import {forwardSchedule} from "../../services/routeService";

const filterItems = {
    Private: {name: 'Private', color: 'gold', showFn: s => !s.shared, on: true},
    Shared: {name: 'Public', color: 'purple', showFn: s => s.shared},
    Active: {showFn: (s) => s.shared || (!s.isObsolete() && !s.isFuture())},
    // Diverted: {showFn: (s) => s.diverted},
    Future: {showFn: (s) => !s.shared && s.isFuture()},
    Expired: {showFn: (s) => !s.shared && s.isObsolete()},
    Warning: {showFn: (s) => s.warning?.length}
};

const headerCols = <>
    <Col lg={3}>Name</Col>
    <Col lg={2}>Included</Col>
    <Col lg={2}>Excluded</Col>
    <Col lg={2}>Operating days</Col>
</>

function Schedules() {

    const {schedules, setSchedules} = useAppContext();

    const editableView = ({item = new Schedule(), ...props}) => <EditScheduleView
        modelData={scheduleModelData}
        editing={Schedule.clone(item)}
        createItemFn={item => new Schedule(item)}
        validateFn={schedule => (schedule.includedSchedules?.length || schedule.schedulePeriods?.length) && Object.keys(schedule.operatingDays).some(day => schedule.operatingDays[day])}
        {...props}
    />

    const itemColumns = (schedule) => (<>
        <Col lg={3}>
            <ScheduleName schedule={schedule}/>
        </Col>
        <Col lg={2}>
            <Row>
                <Col>
                    {schedule.includedSchedules?.length ? schedule.includedSchedules.map((s, i) => (
                        <Row
                            key={'inSched-' + i}><Col><ScheduleName schedule={s}/></Col></Row>)) : ''}
                    {/*{schedule.schedulePeriods?.map((p, i) => (*/}
                    {/*    <Row*/}
                    {/*        key={`sched_per_${i}`}><Col>{p.toString() || ''}</Col></Row>))}*/}

                    {schedule.includedSchedules?.length + schedule.schedulePeriods?.length > 5 ? <Row
                        key={schedule.scheduleId + 'inPs'}><Col>{schedule.schedulePeriods?.length} Schedules {schedule.schedulePeriods?.length ? ' with ' : 'Total of '}{`${schedule.totalDays(schedule.schedulePeriods) || 0} days
                                                        included`}</Col></Row> : schedule.schedulePeriods?.map((p, i) => (
                        <Row
                            key={'exP-' + i}><Col>{p.toString()}</Col></Row>))}
                </Col>
            </Row>
        </Col>
        <Col lg={2}>
            <Row>
                <Col>
                    {schedule.excludedSchedules?.length ? schedule.excludedSchedules.map((s, i) => (
                        <Row
                            key={'exSched-' + i}><Col><ScheduleName schedule={s}/></Col></Row>)) : ''}
                    {schedule.excludedSchedules?.length + schedule.excludedPeriods?.length > 5 ? <Row
                        key={schedule.scheduleId + 'exPs'}><Col>{schedule.excludedSchedules?.length ? '+' : 'Total of '}{`${schedule.totalDays(schedule.excludedPeriods) || 0} days
                                                        excluded`}</Col></Row> : schedule.excludedPeriods?.map((p, i) => (
                        <Row
                            key={'exP-' + i}><Col>{p.toString()}</Col></Row>))}
                </Col>
            </Row>
        </Col>
        <Col lg={2}>{schedule.operatingDays?.toString()}</Col>
    </>)

    return (
        <EditableList filterItems={filterItems} feature={FEATURE.schdls} items={schedules}
                      defaultItems={values(PREDEFINED_SCHEDULES)}
                      modelData={scheduleModelData} setItems={setSchedules}
                      modelCopyFn={item => Schedule.clone({...item, shared: undefined})}
                      headerCols={headerCols}
                      itemIdKey={'scheduleId'}
                      replicateFn={forwardSchedule}
                      createItemFn={item => new Schedule(item)}
                      editableView={editableView} itemColumns={itemColumns}
                      isEditable={s => !s.shared}
                      isReplicable={s => !s.shared}
                      isDeletable={s => !s.shared}
                      itemName={'schedule'}
        />
    )
}

export default React.memo(Schedules)
