import React, {useCallback, useEffect, useState} from "react";
import {ReactComponent as Down} from "../../assets/icons/Down.svg";
import {SyncOutlined} from "@ant-design/icons";
import ShiftBatSelectRowEdit from "./ShiftBatSelectRowEdit";
import {Button, Input, Tooltip} from "antd";
import {ReactComponent as Check} from "../../assets/icons/Check.svg";
import {ReactComponent as Close} from "../../assets/icons/Close.svg";
import {ReactComponent as Services} from "../../assets/icons/Services.svg";
import {Col, Row} from "react-bootstrap";

function ShiftBatDeadRowEdit({
                                 route,
                                 title, onUpdate,setDuration, duration,
                                 updating, onCreate, setRoute, setEditRouteRow,
                                 shiftBatRow, setTitle, description, setDescription,
                                 isLoading, onCancel
                             }) {
    const [options, setOptions] = useState([])
    const [deadRow, setDeadRow] = useState(shiftBatRow);

    useEffect(() => {
        if (!shiftBatRow.routes) {
            return
        }
        // Set the service options when allRoutes change
        const opts = Object.keys(shiftBatRow.routes).map(routeId => ({
            value: routeId,
            label: shiftBatRow.routes[routeId].routeName || 'Default'
        }))
        setOptions(opts);
    }, [shiftBatRow, setOptions])

    const isValid = useCallback(() => {
        return title?.length && deadRow.route?.waypoints?.length
    }, [title, deadRow])

    return (
        <>
            <div>
                <Row className="row-compact">
                    <Col className="row-col-gap">
                        <ShiftBatSelectRowEdit
                            className="inputselect-small w-100"
                            suffixIcon={!shiftBatRow.routes || isLoading ? <SyncOutlined spin={true}/> :
                                <Down/>}
                            // disabled={title !== route.routeName}
                            value={route.routeId}
                            placeholder={"Select Special"}
                            onChange={routeId => {
                                const newRoute = shiftBatRow.routes[routeId]
                                setEditRouteRow(editRouteRow => ({
                                    ...editRouteRow,
                                    route: newRoute
                                }))
                                setRoute(newRoute);
                                setTitle(newRoute.routeName)
                                setDescription(newRoute.routeDetails)
                                setDeadRow(deadrow => ({...deadrow, route: newRoute}))
                            }}
                            options={options}
                            itemName="deadrun"
                            newPlaceholder="Deadrun name"
                            addNew={(e, title) => {
                                if (title?.length) {
                                    setTitle(title)
                                    onCreate(e, {
                                        ...shiftBatRow,
                                        title,
                                        description,
                                        route,
                                    })
                                }
                            }}
                        />
                        <div className="input-btn-wrap">
                            <Input placeholder="Driver instructions" className="inputtext-small input-desc"
                                value={description || ""}
                                onChange={e => setDescription(e.target.value)}/>
                            <Tooltip placement="bottom" title="Generate Left/Rights for deadrun">
                                <Button className="sb-directions"
                                        icon={updating && shiftBatRow.needsUpdate() ? <SyncOutlined spin={true}/> :
                                            <Services />}
                                        disabled={updating}
                                        onClick={e => {
                                            shiftBatRow.buildDirections(true).then((description) => setDescription(description))
                                        }}/>
                            </Tooltip>
                        </div>
                    </Col>
                    <Col md="auto" className="row-col-gap">
                        <Input placeholder="Duration" className="inputtext-small"
                               addonAfter="mins"
                               onChange={e => {
                                   setDuration(e.target.value)
                               }}
                               value={duration}/>
                    </Col>
                    <Col md="auto">
                        <div className="sb-edit-controls">
                            <Button className="sb-done"
                                    icon={updating && shiftBatRow.needsUpdate() ? <SyncOutlined spin={true}/> :
                                        <Check/>}
                                    disabled={!isValid() || updating}
                                    onClick={e => {
                                        onUpdate(e, {
                                            ...shiftBatRow,
                                            title,
                                            duration: parseInt(duration) * 60,
                                            description,
                                            route,
                                        })
                                    }}/>
                            <Button className="sb-close"
                                    icon={updating && shiftBatRow.needsUpdate() ? <SyncOutlined spin={true}/> :
                                        <Close/>}
                                    disabled={updating} onClick={e => onCancel(e, shiftBatRow)}/>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    );
}

export default React.memo(ShiftBatDeadRowEdit)
