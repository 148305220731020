import React from "react";
import useMapControl from "react-map-gl/dist/esm/components/use-map-control";


export default function MapToolbar({style, children}) {
    const {containerRef} = useMapControl({captureClick: true, captureDrag: true, captureScroll: true, captureDoubleClick: true, capturePointerMove: true});

    return (
        <div style={style} >
            <div className="mapboxgl-ctrl mapboxgl-ctrl-group" ref={containerRef}>
                {children}
            </div>
        </div>
    )
}

