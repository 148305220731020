import { cloneDeep } from "lodash";
import { LEAVE_TYPES } from "../../features/EmployeeHR/EmployeeCalendar";

export class Leave {
    constructor(data, external) {
        this.id = null;
        this.type = null;
        this.startDate = null;
        this.endDate = null;
        this.status = null;

        Object.assign(this, data);
        if (data && external) {
            this.start = new Date(data.startDate).toISOString().split("T")[0];
            this.end = new Date(data.endDate).toISOString().split("T")[0];
            this.title = external?.leaveBalances
                ? external?.leaveBalances.find((balance) => balance.leaveTypeID === data.leaveTypeID)?.leaveName
                : LEAVE_TYPES.find((type) => type.value === data.leaveTypeID)?.label;
            this.id = data.leaveApplicationID || data.id;

            this.type = data.leaveTypeID;
        } else {
            this.start = new Date(data.startDate).toISOString().split("T")[0];
            this.end = new Date(data.endDate).toISOString().split("T")[0];
            this.title = LEAVE_TYPES.find((type) => type.value === data.leaveTypeID)?.label;
            this.id = data.id;

            this.type = data.leaveTypeID;
        }
    }

    length() {
        return this.endDate.getDate() - this.startDate.getDate();
    }

    clone(leaves) {
        return new Leave(cloneDeep(leaves));
    }
}
