import React from "react";
import './TripOverview.css';
import {toHrsMinsSecs, toKmMs} from "../libs/formatLib";
import {renderConnectionImg} from "./Legs";

function DirectTripOverview({tripPlan, setShowMap}) {
    return (
        <div className="TripOverview">
            <div className="d-flex flex-row justify-content-left align-items-center">
                <div className="d-flex flex-column leaving-wrap">
                    <div className="leaving-text">{tripPlan.legs[0].type}</div>
                    <div className="leaving-time">Now</div>
                    <div className="leaving-text"></div>
                </div>
                <div className="d-flex flex-column route-main-wrap">
                    <div className={'route-duration-wrap'}>
                        <div>
                            <strong>{toKmMs(tripPlan.legs[0].distance, 0)}</strong>
                            <span>({tripPlan.legs[0].duration > 59 ? `${toHrsMinsSecs(tripPlan.legs[0].duration, false, true, 0)}` : '<1min'})</span>
                        </div>
                    </div>
                    <div className="d-flex flex-row align-items-stretch flex-wrap">
                        <div className="ConnectStart connect-transfer">
                            {renderConnectionImg(tripPlan.legs[0].type)}
                            <div className="DetailsLabel">{toKmMs(tripPlan.legs[0].distance, 0)} | {tripPlan.legs[0].duration > 59 ? `${toHrsMinsSecs(tripPlan.legs[0].duration, false, true, 0)}` : '<1min'}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default React.memo(DirectTripOverview);
