import React, {useState} from "react";
import "./Shift.css";
import {Button, Modal} from "antd";
import {useAppContext} from "../libs/contextLib";
import {driverShiftModelData, vehicleShiftModelData} from "../services/ModelService";

export default function ShiftModal({shiftType, visible, closeDisplay, handleNewShift}) {

    const {apiKey} = useAppContext();
    const [shiftName, setShiftName] = useState();
    const [isLoading, setIsLoading] = useState(false);

    function validateForm() {
        return shiftName && shiftName.length;
    }

    async function handleSubmit(event) {
        event.preventDefault();

        console.log('Submitting Shift.')

        setIsLoading(true);

        let shift = {
            shiftName: shiftName,
        }
        shift.shiftId = "_"
        shift.userId = apiKey

        try {
            // await createShift(shift, shiftType)
            if (shiftType === 'driver') {
                await driverShiftModelData.save(shift);
            } else {
                await vehicleShiftModelData.save(shift);
            }
            setIsLoading(false);
            if (handleNewShift) {
                handleNewShift(shift)
            }
            closeDisplay()
            setShiftName(null)
        } catch (e) {
            console.log(e)
        }
        setIsLoading(false);
    }

    return (
        <Modal
            open={visible}
            title={`New ${shiftType} shift`}
            onCancel={closeDisplay}
            destroyOnClose
            centered
            footer={[
                <Button className="btn-secondary" key="back" onClick={closeDisplay}>
                    Return
                </Button>,
                <Button className="btn-primary" key="submit" type="primary" loading={isLoading} onClick={handleSubmit}
                        disabled={!validateForm()}>
                    Submit
                </Button>,
            ]}
        >
            <div className="Shift">
                <div className="row">
                    <div className="react-js-cron">
                        <div className="react-js-cron-field react-js-cron-period">
                            <span className="seventyPx text-right">Name</span>
                            <input type="text" className="ShiftInput" placeholder="shift name"
                                   value={shiftName} onChange={(e) => setShiftName(e.target.value)}/>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
}
