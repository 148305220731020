import React, {useCallback, useEffect, useRef, useState} from "react";
import {
    Button,
    Col,
    Row,
    ToggleButton,
    ToggleButtonGroup
} from "react-bootstrap";
import {Modal, Select, TimePicker} from "antd";
import "./TripPlannerAccordian.css"
import {TimeFilter, TimeFilterType} from "../model/timeFilter";
import dayjs from "../dayjs";

import {ReactComponent as Refine} from '../assets/icons/Refine.svg';

const {Option} = Select;

const getTypeName = (type, anyDate) => {
    if (anyDate) {
        return type === 'LEAVING' ? 'PM' : type === 'ARRIVING' ? 'AM' : 'All'
    } else {
        return TimeFilterType[type]
    }
}


const SCHOOL_ONLY_OPT = "school";

function TripPlannerTimePrefsView({
                                      clearFn,
                                      timeFilter,
                                      setTimeFilter,
                                      showPrefs,
                                      disabled
                                  }) {

    const [show, setShow] = useState(false);
    const target = useRef(null);

    const now = dayjs();

    const [type, setType] = useState(timeFilter?.type || TimeFilterType.NOW)
    const [schoolDays, setSchoolDays] = useState(timeFilter?.anyDate)
    const [startTime, setStartTime] = useState(now);

    useEffect(() => {
        setShow(showPrefs)
    }, [setShow, showPrefs])

    useEffect(() => {
        console.log('settings based on timeFilter...', timeFilter)
        const now = dayjs();
        setType(timeFilter?.type || TimeFilterType.NOW);
        setSchoolDays(timeFilter?.anyDate);
        setStartTime(timeFilter?.startTime || now);
    }, [timeFilter, setType, setSchoolDays, setStartTime])

    const savePrefs = useCallback(({type, anyDate, startTime}) => {
        if (clearFn) {
            clearFn();
        }
        setTimeFilter(timeFilter => {
            if (!schoolDays) {
                console.log('setting time filter', type, schoolDays, startTime)
                return new TimeFilter({
                    ...timeFilter,
                    type,
                    anyDate: false,
                    windowInSecs: 24 * 3600 * 7, // 16 hours
                    startTime: startTime.startOf('m')
                })
            } else {
                const timeFilter = new TimeFilter();
                if (type === TimeFilterType.ARRIVING) {
                    timeFilter.setSchoolAM();
                } else if (type === TimeFilterType.ALL) {
                    timeFilter.setSchool();
                } else {
                    timeFilter.setSchoolPM();
                }
                return timeFilter;
            }
        })
    }, [clearFn, setTimeFilter, schoolDays]);

    const getDays = () => {
        return Array.from(Array(14).keys()).map(d => {
            const now = dayjs().add(d, 'day');
            if (d === 0) return {date: now.format('YYYYMMDD'), str: now.format('[Today] (ddd)')}
            if (d === 1) return {
                date: now.format('YYYYMMDD'),
                str: now.format('[Tomorrow] (ddd)')
            }
            return {date: now.format('YYYYMMDD'), str: now.format('DD/MM (ddd)')}
        })
    }

    const overlay = useCallback(({type, anyDate: schoolDays, startTime}) => {
        return <Modal
            width="400px"
            open={show}
            centered
            title={`Time and date of travel`}
            destroyOnClose
            getContainer="#busable-embed,#root"
            onCancel={() => {
                setType(timeFilter.type)
                setStartTime(timeFilter.startTime)
                setSchoolDays(timeFilter.anyDate)
                setShow(false)
            }}
            footer={[
                <Button key="back" variant={"outline-danger"} className="btn-secondary mr-2"
                        size={"sm"} onClick={() => {
                    setType(timeFilter.type)
                    setStartTime(timeFilter.startTime)
                    setSchoolDays(timeFilter.anyDate)
                    setShow(false)
                }}>
                    Cancel
                </Button>,
                <Button className="btn-primary" key="apply" variant={"outline-primary"}
                        size={"sm"}
                        onClick={() => {
                            savePrefs({type, anyDate: schoolDays, startTime});
                            setShow(false);
                        }}>
                    Apply
                </Button>,
            ]}
        >
            <div>
                <Row>
                    <Col md={7}>
                        <Select
                            style={{minWidth: '150px'}}
                            className={'ml-2 mt-2'}
                            onChange={val => {
                                if (val === SCHOOL_ONLY_OPT) {
                                    setSchoolDays(true);
                                    return;
                                } else {
                                    setSchoolDays(false);
                                }
                                const d = dayjs(val, 'YYYYMMDD').startOf('day');
                                if (d.diff(dayjs().startOf('day'), 'days') > 0 && (type === TimeFilterType.NOW || type === TimeFilterType.ALL)) {
                                    setType(TimeFilterType.LEAVING);
                                }
                                setStartTime(startTime => {
                                    return startTime.clone().year(d.year()).month(d.month()).date(d.date())
                                });
                            }}
                            value={schoolDays ? SCHOOL_ONLY_OPT : startTime.format('YYYYMMDD')}>
                            <Option key={"SchoolOnlyOpt"} value={SCHOOL_ONLY_OPT}>School days</Option>
                            {getDays().map((day) => (
                                <Option key={day.date} value={day.date}>{day.str}</Option>
                            ))}
                        </Select>
                    </Col>
                    {!schoolDays ? <>
                            <Col md={5}>
                                <TimePicker
                                    className={'ml-2 mt-2'}
                                    value={startTime}
                                    format={"HH:mm"}
                                    onChange={val => {
                                        setStartTime(startTime => {
                                            return startTime.clone().hour(val.hour()).minute(val.minute())
                                        });
                                    }}
                                    allowClear={false}
                                    changeOnBlur={true}
                                />
                            </Col>
                        </>
                        : <></>
                    }
                </Row>
                <Row><Col xs={12}>
                    <ToggleButtonGroup type="radio" name="type" value={type}
                                       className="ml-2 mt-3"
                                       size="sm"
                                       onChange={val => {
                                           setType(val)
                                           console.log(val)
                                           if (val === TimeFilterType.NOW) {
                                               setStartTime(dayjs());
                                           }
                                       }}>
                        {Object.keys(TimeFilterType).filter(t => schoolDays ? t !== 'NOW' : t !== 'ALL').map((t, idx) => (

                            <ToggleButton
                                key={`TypeSelectionBtn-${idx}`}
                                variant="outline-primary"
                                name={TimeFilterType[t]}
                                value={TimeFilterType[t]}
                                checked={type === TimeFilterType[t]}
                            >{getTypeName(t, schoolDays)}</ToggleButton>
                        ))}
                    </ToggleButtonGroup>
                </Col>
                </Row>
            </div>
        </Modal>
    }, [show, setShow, setType, savePrefs, timeFilter]);

    return (
        <div ref={target} className={'btn-wrap'}>
            <Button variant={"link"} className={'pill-field-btn'} disabled={disabled}
                    size={"sm"}
                    onClick={() => setShow(true)}>{timeFilter.toString()}</Button>
            <Button variant={"light"} className={'pill-btn'} disabled={disabled}
                    size={"sm"}
                    onClick={() => setShow(true)}>
                <Refine/><span>Refine</span>
            </Button>
            {overlay({
                type, anyDate: schoolDays, startTime
            })}
        </div>
    )
}

export default React.memo(TripPlannerTimePrefsView);

