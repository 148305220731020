import {Button, Popover, Select, Switch} from "antd";
import React, {useCallback, useEffect, useState} from "react";
import {Input, Modal, Tooltip} from "antd/lib";
import {ReactComponent as Accessible} from '../assets/icons/Accessible.svg';
import {ReactComponent as Info} from '../assets/icons/info-sm.svg';
import RouteNumber from "./RouteNumber";
import {Col, Row} from "react-bootstrap";
import {schedule_options} from "../model/schedule";
import {keyBy} from "lodash/collection";
import {isEqual} from "lodash";

const TripDetailsModal = ({
                              operator,
                              iconOnly,
                              width,
                              popPlacement,
                              disabled,
                              schedules,
                              route,
                              trip,
                              validationHelper,
                              setTrip
                          }) => {
    const [visible, setVisible] = useState(false);
    const [alias, setAlias] = useState('');
    const [headsign, setHeadsign] = useState('');
    // const [scheduleIds, setScheduleIds] = useState([]);
    const [wheelchair, setWheelchair] = useState(false);
    const [headsignWarning, setHeadsignWarning] = useState(false);

    useEffect(() => {
        setAlias(trip?.transferAlias || '')
        setHeadsign(trip?.headsign || '')
        // setScheduleIds(trip?.scheduleIds || [])
        setWheelchair(trip?.wheelchair || false)
    }, [trip]);

    // let obsoleteTrip = false;
    // if (schedules?.length) {
    //     const schedulesById = keyBy(schedules, 'scheduleId');
    //     obsoleteTrip = trip.hasObsoleteCalendar(schedulesById)
    // }
    const save = useCallback((trip) => {
        if (trip && trip.transferAlias === alias &&
            trip.headsign === headsign &&
            trip.wheelchair === wheelchair) return;
        setTrip({
            ...trip,
            transferAlias: alias,
            headsign,
            // scheduleIds,
            wheelchair
        })
    }, [setTrip, alias, headsign, wheelchair]);

    return (
        <>
            <Popover placement={popPlacement}
                     title={<span>Edit service details</span>}
                     content={<div><p>Edit additional details of service starting at {trip?.getStartTime()}</p></div>}
                     trigger="hover">
                {iconOnly ?
                    <Button disabled={disabled}
                            onClick={() => {
                                setVisible(true)
                            }}
                            className="transfer-icon icon-button btn-filled btn-fade"><Info width={16} height={16}/>
                    </Button>
                :
                <div className="d-flex TripDetailsInfo">
                    {headsign &&
                        <Button disabled={disabled}
                                onClick={() => {
                                    setVisible(true)
                                }}
                                className="icon-button">{headsign}
                        </Button>
                    }
                    <Button disabled={disabled}
                            onClick={() => {
                                setVisible(true)
                            }}
                            className={`icon-button accessible-icon ${wheelchair ? 'accessible-active' : ''}`}><Accessible/>
                    </Button>
                </div>}
            </Popover>
            <Modal
                open={visible}
                width={width || '50%'} style={{top: 60}}
                title={<div className="d-flex align-items-center" style={{gap: "15px"}}><div className={"trip-details-header d-flex flex-row"}><div className={""}><RouteNumber route={route}/></div></div>{trip?.getStartTime() || '--:--'} - Trip Details</div>}
                onCancel={() => {
                    setVisible(false)
                }}
                destroyOnClose={true}
                footer={[
                    <Button key="back" type="primary" className="btn-primary" onClick={() => {
                        save(trip);
                        setVisible(false);
                    }}>
                        Done
                    </Button>,
                ]}
            >
                <div className="pt-4">
                    <div className="row">
                        <div className="col-lg-6">
                            <div><h5 className="title mt-2">Trip Headsign</h5></div>
                            <Tooltip title={"TODIS only supports 21 characters"}
                                    open={headsignWarning}>
                                <Input
                                    className={`Headsign ${headsignWarning || headsign?.length > 21 ? 'warning' : ''}`}
                                    placeholder={"Headsign..."}
                                    value={headsign || ''}
                                    onChange={({target: {value}}) => {
                                        setHeadsign(value)
                                        setHeadsignWarning(value?.length > 21)
                                    }}
                                    onFocus={({target: {value}}) => setHeadsignWarning(value?.length > 21)}
                                    onBlur={() => setHeadsignWarning(false)}
                                />
                            </Tooltip>
                        </div>
                        {!!operator.opts?.shiftbat?.transfersShowHeadsign && <>
                            <div className="col-lg-6">
                                <div><h5 className="title mt-2">Transfer alias</h5></div>
                                <Input
                                    className={`TransferAlias`}
                                    placeholder={'Transfer alias...'}
                                    value={alias || ''}
                                    onChange={({target: {value}}) => {
                                        setAlias(value)
                                    }}
                                />
                            </div>
                        </>}
                    </div>
                    {/*<div className="row mt-3">*/}
                    {/*    <div className="col-lg-12">*/}
                    {/*        <div><h5 className="title mt-2">Operating Calendars</h5></div>*/}
                    {/*        <Tooltip*/}
                    {/*            title={!trip?.scheduleIds?.length ? 'Please provide at least one Calendar for this service.' : obsoleteTrip ? 'There is an expired Calendar for this service.' : null}><Select*/}
                    {/*            className={`w-100`}*/}
                    {/*            status={validationHelper?.global?.some(m => m.type === 'schedule' && m.tripId === trip?.tripId) || obsoleteTrip ? 'error' : ''}*/}
                    {/*            placeholder="Operating calendars"*/}
                    {/*            onChange={scheduleIds => {*/}
                    {/*                setScheduleIds(scheduleIds)*/}
                    {/*            }}*/}
                    {/*            value={scheduleIds}*/}
                    {/*            mode="multiple"*/}
                    {/*            allowClear*/}
                    {/*            showSearch={true}*/}
                    {/*            filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}*/}
                    {/*            filterSort={(optionA, optionB) =>*/}
                    {/*                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())*/}
                    {/*            }*/}
                    {/*            style={{*/}
                    {/*                width: '100%'*/}
                    {/*            }}*/}
                    {/*            options={schedule_options(schedules)}*/}
                    {/*        /></Tooltip>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    <div className="row mt-3">
                        <div className="col-lg-12">
                            <div><h5 className="title mt-2">Accessible</h5></div>
                            <Accessible className={wheelchair && 'accessible-active'}/>
                            <Switch
                                className="ml-2"
                                onChange={val => {
                                    setWheelchair(val)
                                }}
                                checked={wheelchair}
                            />
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
}

export default React.memo(TripDetailsModal)