import * as React from 'react';
import {useCallback} from 'react';
import {Badge, ListGroup} from 'react-bootstrap';
import {Popover} from 'antd';
import {flatten} from 'lodash';
import {DATE_STRING} from '../model/busRoute';
import useAllSchedules from '../hooks/useAllSchedules';

const getScheduleNameAndStartTime = (trip, schedule, date) => {
    return (<div className="d-flex justify-content-center align-items-center">
        <div>{trip.getStartTime()} {schedule?.scheduleName || date?.format(DATE_STRING) || '--/--/---'}</div>
    </div>);
};

function CharterTimesPopover({charter, schedules, placement = 'left'}) {

    const popover = useCallback(() => (
        <div className="overflow-auto" style={{maxHeight: '50vh'}} onClick={(e) => e.stopPropagation()}>
            <ListGroup>
                {flatten(charter?.itinerary?.map(route => route.services.map(trip => trip.scheduleIds?.length ? trip.scheduleIds.map(sId => (
                            <ListGroup.Item key={`trip-start-list-${route.routeId}-${sId}-${trip.tripId}`}>
                                {getScheduleNameAndStartTime(trip, schedules[sId])}
                            </ListGroup.Item>
                        )
                    ) : trip.dates?.length ? trip.dates.map(d => (
                        <ListGroup.Item key={`trip-date-list-${route.routeId}-${d}-${trip.tripId}`}>
                            {getScheduleNameAndStartTime(trip, null, d)}
                        </ListGroup.Item>
                    )) : <ListGroup.Item key={`trip-date-list-${route.routeId}-${trip.tripId}`}>
                        {getScheduleNameAndStartTime(trip, null, null)}
                    </ListGroup.Item>
                )))}

            </ListGroup>
        </div>
    ), [charter, schedules]);

    const CharterTimeOverlay = () => (
        <Popover content={popover}
                 trigger="hover"
                 placement={placement}>
            <Badge variant="secondary" size="sm" className="badge-outline">
                {`${charter.itinerary.length || 0} legs, ${charter.itinerary.reduce((acc, route) => acc + route.services.length, 0) || 0} services, ${charter.duties?.reduce((acc, duty) => acc + duty.getRunningDates(schedules).length, 0) || 0} more days`}
            </Badge>
        </Popover>
    );

    if (!charter?.itinerary?.length || !schedules) {
        return null;
    }

    return (<>
        {charter.itinerary.length === 1 && charter.itinerary[0].services === 1 && charter.itinerary[0].services[0].scheduleIds?.length ?
            <Badge variant="secondary" size="sm" className="badge-outline">
                {getScheduleNameAndStartTime(charter.itinerary[0].services[0], schedules[charter.itinerary[0].services[0].scheduleIds[0]])}
            </Badge> : charter.itinerary.length === 1 && charter.itinerary[0].services === 1 && charter.itinerary[0].services[0].dates?.length ?
                <Badge variant="secondary" size="sm" className="badge-outline">
                    {charter.itinerary[0].services[0].dates.map(d => (
                        <ListGroup.Item key={`trip-date-list-${d}`}>
                            {getScheduleNameAndStartTime(charter.itinerary[0].services[0], null, d)}
                        </ListGroup.Item>
                    ))}
                </Badge>
                : <CharterTimeOverlay/>}
    </>);
}

export default React.memo(CharterTimesPopover);
