import {Button, Col, Form, Row} from 'react-bootstrap';
import React, {useCallback, useEffect, useState} from 'react';
import LoaderButton from './LoaderButton';
import {Divider, Input, Select, Slider, Switch, Tooltip} from 'antd';
import TimeField from '../libs/timeField';
// eslint-disable-next-line
import {FEATURE} from '../model/features';
import {dayjsToSecsSinceMidnight, secsSinceMidnightToDayjs} from '../model/timeFilter';
import {useAppContext} from '../libs/contextLib';
import {filter, keyBy} from 'lodash/collection';
import {debounce} from 'lodash/function';
import {toKmMs} from '../libs/formatLib';
import {ReactComponent as Accessible} from '../assets/icons/Accessible.svg';
import {CheckOutlined, CloseOutlined, CompassOutlined} from '@ant-design/icons';
import dayjs from '../dayjs';
import {Popconfirm} from 'antd/lib';
import {reverseGeocode} from '../libs/mapLib';

const {Option} = Select;

export const STOP_TYPES_OPTIONS = [{value: 'bus', label: 'Bus stop'}, {value: 'school', label: 'School'},
    {value: 'venue', label: 'Venue'}, {value: 'depot', label: 'Depot'}, {
        value: 'nonpub',
        label: 'Non public'
    }, {value: 'charter', label: 'Charter'}];
export const stopTypes = keyBy(STOP_TYPES_OPTIONS, 'value');

const timeFilterVal = (min) => (min > 0 ? `${min} min${min > 1 ? 's' : ''}` : 'None');

function EditStop({
                      handleStopMoved,
                      cancelEditStop,
                      handleSaveEdit,
                      stop,
                      allStops,
                      setUpdated,
                      allowedStopTypes,
                      distanceToCursor,
                      type,
                      buildToStop, setBuildToStop,
                      ...props
                  }) {

    const {isAdmin, operator} = useAppContext();

    const [isSaving, setIsSaving] = useState(false);
    const [verified, setVerified] = useState(0);
    const [wheelchair, setWheelchair] = useState(false);
    const [stopId, setStopId] = useState('');
    const [geohash, setGeohash] = useState('');
    const [stopCode, setStopCode] = useState('');
    const [duplicateStopCode, setDuplicateStopCode] = useState(false);
    const [stopName, setStopName] = useState('');
    const [stopType, setStopType] = useState(type || 'bus');
    const [suburb, setSuburb] = useState('');
    const [postcode, setPostcode] = useState('');
    const [radius, setRadius] = useState(25);
    const [startBell, setStartBell] = useState(null);
    const [startBellWindow, setStartBellWindow] = useState(0);
    const [endBell, setEndBell] = useState(null);
    const [endBellWindow, setEndBellWindow] = useState(0);
    const [aliases, setAliases] = useState({});

    const [lat, setLat] = useState(0.0);
    const [lon, setLon] = useState('0.0');

    const [reverseGeocoding, setReverseGeocoding] = useState(false);

    const isCharterTypeStop = stopType === 'charter';

    const getAliases = useCallback(() => {
        return operator.opts?.aliases?.points?.length && filter(operator.opts?.aliases?.points, {'stopType': (stopType || 'bus')});
    }, [operator, stopType]);

    useEffect(() => {
        console.log('Stop updated: ', stop);
        if (!stop.moved) {
            stop.stopId?.length && setStopId(stop.stopId);
            Number.isFinite(stop.verified) && setVerified(stop.verified);
            stop.stopCode?.length && setStopCode(stop.stopCode);
            stop.stopName?.length && setStopName(stop.stopName);
            stop.stopType?.length && setStopType(stop.stopType);
            stop.suburb?.length && setSuburb(stop.suburb);
            stop.postcode?.length && setPostcode(stop.postcode);
            stop.radius > 0 && setRadius(stop.radius);
            stop.aliases && setAliases(stop.aliases);
            stop.wheelchair && setWheelchair(stop.wheelchair);
            Number.isFinite(stop.startBell) && setStartBell(secsSinceMidnightToDayjs(stop.startBell));
            Number.isFinite(stop.startBellWindow) && setStartBellWindow(stop.startBellWindow ? stop.startBellWindow / 60 : 0);
            Number.isFinite(stop.endBell) && setEndBell(secsSinceMidnightToDayjs(stop.endBell));
            Number.isFinite(stop.endBellWindow) && setEndBellWindow(stop.endBellWindow ? stop.endBellWindow / 60 : 0);
        }
        stop.radius > 0 && setRadius(stop.radius);
        setLat(stop.lat);
        setLon(stop.lon);
        Number.isFinite(stop.lat) && Number.isFinite(stop.lon) && setGeohash(stop.geohash);

    }, [setStopId, setVerified, setStopCode, setStopName, setStopType, setLat, setLon, stop, setRadius, setSuburb, setPostcode,]);

    useEffect(() => {
        setDuplicateStopCode(stopCode?.length && Object.values(allStops).some(s => (s.stopCode?.toLowerCase() || '') === stopCode?.toLowerCase() && s.stopId !== stopId && !s.master));
    }, [stopCode, allStops, stopId, setDuplicateStopCode]);

    const isInvalid = useCallback(() => {
        return !stopType?.length || !stopName?.length || !Number.isFinite(parseFloat(lat)) || !Number.isFinite(parseFloat(lon))
            || stop.lat < -90 || stop.lat > 90 || stop.lon < -180 || stop.lon > 180;
    }, [stopType, stopName, lat, lon, stop, duplicateStopCode]);

    const handleSave = debounce(useCallback(async (stop) => {
        if (stop.imported && !window.confirm('This is an imported point from another source system. Updates may cause a data mismatch. Are you sure you want to continue?')) {
            return;
        }
        setIsSaving(true);
        const editedStop = {
            ...stop,
            verified,
            stopCode,
            stopType,
            suburb, postcode,
            stopName,
            radius,
            wheelchair,
            lat: parseFloat(lat),
            lon: parseFloat(lon),
            startBell: startBell ? dayjsToSecsSinceMidnight(startBell) : '',
            startBellWindow: startBellWindow ? startBellWindow * 60 : null,
            endBell: endBell ? dayjsToSecsSinceMidnight(endBell) : '',
            endBellWindow: endBellWindow ? endBellWindow * 60 : null
        };
        if (getAliases()?.length && aliases) {
            editedStop.aliases = aliases;
        }
        await handleSaveEdit(editedStop);
        setIsSaving(false);
    }, [setIsSaving, handleSaveEdit, verified, stopCode, stopType, stopName, lat, lon, suburb, postcode, radius,
        startBell, startBellWindow, endBell, endBellWindow, getAliases, aliases, wheelchair, operator]), 500);

    const onTimeFieldChange = useCallback((e, value, date = dayjs()) => {
        if (!value) {
            return;
        }
        value = dayjs(value, 'HH:mm');
        if (!value.isValid()) {
            return;
        }
        const time = dayjsToSecsSinceMidnight(value);
        return date.clone().startOf('day').add(time, 's');
    }, []);

    const findPostcode = useCallback(() => {
        if (lat && lon) {
            setReverseGeocoding(true);
            reverseGeocode({lat, lon})
                .then(data => {
                    if (data?.postcode) {
                        setPostcode(data.postcode);
                    }
                    if (data?.suburb || data?.town || data?.city) {
                        setSuburb(data.suburb || data.town || data.city);
                    }
                    if (!stopName?.length && data?.address_line1) {
                        setStopName(data.address_line1.trim());
                    }
                })
                .finally(() => setReverseGeocoding(false));
        }
    }, [lat, lon, stopName, setStopName, setPostcode, setSuburb, setReverseGeocoding]);

    useEffect(() => {
        if (!suburb?.length && !postcode?.length && !stopName?.length && lat && lon) {
            findPostcode();
        }
    }, [suburb, postcode, stopName, lat, lon]);

    return (
        <div className="EditStop py-3 px-3">
            <Row>
                <Col className="d-flex flex-column justify-content-center">
                    <h3 className="title" style={{marginBottom: 0}}>{props.charter ? 'Charter Stop' : 'Point'} Details</h3>
                    {distanceToCursor > -1 ?
                        <div className="text-info">Distance to cursor: {toKmMs(distanceToCursor)}</div> : <></>}
                </Col>
                {/*{setBuildToStop && <Col className="d-flex flex-column justify-content-center">*/}
                {/*    <h3 className="title" style={{marginBottom: 0}}>Build to stop</h3>*/}
                {/*    <Checkbox checked={buildToStop} onChange={(e) => setBuildToStop(e.target.checked)}/>*/}
                {/*</Col>}*/}
                {!isCharterTypeStop && <Col xs="auto" style={{paddingRight: 0}}>
                    <Accessible className={wheelchair && 'accessible-active'}/>
                    <Tooltip placement="bottom" title="Accessible">
                        <Switch
                            className="default-switch ml-2"
                            onChange={val => {
                                setWheelchair(val);
                                setUpdated(true);
                            }}
                            checked={wheelchair}
                        />
                    </Tooltip>
                </Col>}
                {!isCharterTypeStop && <Col xs="auto">
                    <Tooltip placement="bottom" title="Verified">
                        <Switch className="verify-switch"
                                checkedChildren={<CheckOutlined/>}
                                unCheckedChildren={<CloseOutlined/>}
                                onChange={(v) => {
                                    setVerified(v ? 1 : 0);
                                    setUpdated(true);
                                }}
                                checked={verified === 1}
                        />
                    </Tooltip>
                </Col>}
            </Row>
            <div className="row" style={{paddingTop: '20px'}}>
                <div className="col-lg-12">
                    {isAdmin && <>
                        <Row>
                            <Col>
                                <Form.Group className="FormGroup">
                                    <Form.Label className="mb-0">Point ID</Form.Label>
                                    <Form.Control as="input" size="sm" value={stopId} disabled/>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="FormGroup">
                                    <Form.Label className="mb-0">Geohash</Form.Label>
                                    <Form.Control as="input" size="sm" value={geohash} disabled/>
                                </Form.Group>
                            </Col>
                        </Row>
                    </>
                    }
                    {!isCharterTypeStop && <Row>
                        <Col className="pr-2">
                            <Form.Group className="FormGroup">
                                <Form.Label className="mb-0">Point type</Form.Label>
                                <Select
                                    value={stopType}
                                    name={'stopType'}
                                    onChange={(val) => {
                                        setStopType(val);
                                        setUpdated(true);
                                    }}
                                    options={STOP_TYPES_OPTIONS.filter(opt => !allowedStopTypes || allowedStopTypes.includes(opt.value))}
                                />
                            </Form.Group>
                        </Col>
                        <Col className="pl-2">
                            <Form.Group className="FormGroup">
                                <Form.Label className="mb-0">Source ID</Form.Label>
                                <Tooltip
                                    title={duplicateStopCode ? 'Duplicate stop code. One of your stops already has this stop code.' : ''}>
                                    <Form.Control className={duplicateStopCode ? 'alert-danger' : ''} as="input"
                                                  size="sm" placeholder="Enter source ID" value={stopCode}
                                                  onChange={(e) => {
                                                      if (e.target.value.length <= 9) {
                                                          setStopCode(e.target.value);
                                                          setUpdated(true);
                                                      }
                                                  }}
                                    /></Tooltip>
                            </Form.Group>
                        </Col>
                    </Row>}
                    <Row>
                        <Col>
                            <Form.Group className="FormGroup">
                                <Form.Label className="mb-0">Point name</Form.Label>
                                <Form.Control as="input" size="sm" placeholder="Enter point name" value={stopName}
                                              onChange={(e) => {
                                                  setStopName(e.target.value);
                                                  setUpdated(true);
                                              }}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    {!isCharterTypeStop && <Row>
                        <Col className="pr-2">
                            <Form.Group className="FormGroup">
                                <Form.Label className="mb-0">Suburb</Form.Label>
                                <Form.Control as="input" size="sm"
                                              placeholder="Enter suburb" value={suburb}
                                              onChange={e => {
                                                  setSuburb(e.target.value);
                                              }}
                                />
                            </Form.Group>
                        </Col>
                        <Col className="pl-2 pr-2">
                            <Form.Group className="FormGroup">
                                <Form.Label className="mb-0">Postcode</Form.Label>
                                <Form.Control as="input" size="sm"
                                              placeholder="Enter postcode" value={postcode}
                                              onChange={e => {
                                                  setPostcode(e.target.value);
                                              }}
                                />
                            </Form.Group>
                        </Col>
                        <Col xs="auto" className="pl-2">
                            <Tooltip placement={'bottom'}
                                     title={`Autofill the ${!stopName?.length ? 'point name,' : ''} suburb and postcode`}>
                                <Popconfirm
                                    title={`Are you sure you want to update the ${!stopName?.length ? 'point name,' : ''} suburb and postcode?`}
                                    onConfirm={e => findPostcode()}>
                                    <Button style={{marginTop: '21px'}} className="btn-secondary"
                                            size="sm" variant="outline-dark" disabled={reverseGeocoding}
                                    >
                                        <CompassOutlined spin={reverseGeocoding}/></Button>
                                </Popconfirm>
                            </Tooltip>
                        </Col>
                    </Row>}

                    <Row>
                        <Col className="pr-2">
                            <Form.Group className="FormGroup">
                                <Form.Label className="mb-0">Latitude</Form.Label>
                                <Form.Control as="input" type="number" step="0.001" size="sm"
                                              placeholder="Enter latitude" value={lat}
                                              onChange={(e) => {
                                                  let lat = parseFloat(e.target.value);
                                                  setLat(e.target.value);
                                                  if (Number.isFinite(lat)) {
                                                      setUpdated(true);
                                                      handleStopMoved({stop, lat, lon, radius});
                                                  }
                                              }}
                                />
                            </Form.Group>
                        </Col>
                        <Col className="pl-2">
                            <Form.Group className="FormGroup">
                                <Form.Label className="mb-0">Longitude</Form.Label>
                                <Form.Control as="input" type="number" step="0.001" size="sm"
                                              placeholder="Enter longitude" value={lon}
                                              onChange={(e) => {
                                                  let lon = parseFloat(e.target.value);
                                                  setLon(e.target.value);
                                                  if (Number.isFinite(lon)) {
                                                      setUpdated(true);
                                                      handleStopMoved({stop, lat, lon, radius});
                                                  }
                                              }}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    {!isCharterTypeStop && <Row>
                        <Col>
                            <Form.Group className="FormGroup">
                                <Form.Label className="mb-0">Radius</Form.Label>
                                <div style={{padding: '0 15px 0 8px'}}>
                                    <Slider
                                        marks={Object.fromEntries(Array.from(Array(6).keys()).map((index) => [index * 50, (index * 50) + 'm']))}
                                        min={0}
                                        max={250}
                                        value={radius}
                                        onChange={(r) => {
                                            setRadius(r);
                                            handleStopMoved({stop, lat, lon, radius: r});
                                            setUpdated(true);
                                        }}
                                    />
                                </div>
                            </Form.Group>
                        </Col>
                    </Row>}
                </div>
            </div>
            {
                !props.charter && getAliases()?.length ?
                    <div>
                        <Divider/>
                        <Row>
                            <Col className="d-flex flex-column justify-content-center">
                                <h3 className="title" style={{marginBottom: '15px'}}>Stop Aliases</h3>
                            </Col>
                        </Row>
                        <Row>
                            {getAliases().map(alias => {
                                return <Col xs={6}><Form.Group className="FormGroup">
                                    <Form.Label
                                        className="mb-0">{alias.label}</Form.Label>
                                    <Form.Control as="input" size="sm"
                                                  maxLength={alias.max}
                                                  placeholder={alias.label + '...'}
                                                  value={aliases[alias.aliasId]}
                                                  onChange={(e) => {
                                                      setUpdated(true);
                                                      setAliases(aliases => ({
                                                          ...aliases,
                                                          [alias.aliasId]: e.target.value
                                                      }));
                                                  }}
                                    />
                                </Form.Group></Col>;
                            })}
                        </Row>
                    </div> : <></>
            }

            {['school', 'venue'].includes(stopType) &&
                <>
                    <Divider/>
                    <Row>
                        <Col className="d-flex flex-column justify-content-center">
                            <h3 className="title"
                                style={{marginBottom: '15px'}}>{stopType === 'school' ? `School Bell` : 'Opening'} Times</h3>
                        </Col>
                    </Row>
                    <ul className="SchoolBellTimes">
                        <li>
                            <Form.Group className="FormGroup">
                                <Form.Label className="mb-0">Arrivals</Form.Label>

                                <Input
                                    addonAfter="min(s)"
                                    defaultValue="00"
                                    maxLength="2"
                                    value={startBellWindow}
                                    onChange={(e) => {
                                        setStartBellWindow(e.target.value);
                                    }}
                                />

                                {/* <Select
                                    size='lg'
                                    className="w-100"
                                    onChange={setStartBellWindow}
                                    value={startBellWindow}
                                    disabled={!startBell}
                                >
                                    {Array.from(Array(60).keys()).map(min => {
                                            return <Option key={`StartBellLimit-${min}`}
                                                            value={min}>{timeFilterVal(min)}</Option>;
                                        }
                                    )}
                                </Select> */}
                            </Form.Group>
                        </li>
                        <li>
                            <Form.Group className="FormGroup">
                                <Form.Label
                                    className="mb-0">{stopType === 'school' ? `Start Bell` : 'Opening'}</Form.Label>
                                <TimeField className={`InputTimeField text-center`}
                                           value={startBell ? dayjs(startBell).format('HH:mm') : '00:00'}
                                           onChange={(e, value) => setStartBell(onTimeFieldChange(e, value))}
                                />

                                {/* <TimePicker
                                    onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                    }}
                                    value={startBell}
                                    format={"HH:mm"}
                                    onChange={setStartBell}
                                /> */}

                            </Form.Group>
                        </li>
                        <li>
                            <Form.Group className="FormGroup">
                                <Form.Label
                                    className="mb-0">{stopType === 'school' ? `End Bell` : 'Closing'}</Form.Label>

                                <TimeField className={`InputTimeField text-center`}
                                           value={endBell ? dayjs(endBell).format('HH:mm') : '00:00'}
                                           onChange={(e, value) => setEndBell(onTimeFieldChange(e, value))}
                                />

                                {/* <TimePicker
                                    onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                    }}
                                    value={endBell}
                                    format={"HH:mm"}
                                    onChange={setEndBell}
                                /> */}
                            </Form.Group>
                        </li>
                        <li>
                            <Form.Group className="FormGroup">
                                <Form.Label className="mb-0">Departures</Form.Label>
                                <Input
                                    addonAfter="min(s)"
                                    defaultValue="00"
                                    maxLength="2"
                                    value={endBellWindow}
                                    onChange={(e) => {
                                        setEndBellWindow(e.target.value);
                                    }}
                                />
                                {/* <Select
                                    size='lg'
                                    className="w-100"
                                    onChange={setEndBellWindow}
                                    value={endBellWindow}
                                    disabled={!endBell}
                                >
                                    {Array.from(Array(60).keys()).map(min => {
                                            return <Option key={`EndBellLimit-${min}`}
                                                            value={min}>{timeFilterVal(min)}</Option>;
                                        }
                                    )}
                                </Select> */}
                            </Form.Group>
                        </li>
                    </ul>
                </>
            }

            <Row>
                <Col>
                    <div className="ButtonGroup">
                        <Button
                            className="btn-secondary"
                            size="sm"
                            onClick={(e) => {
                                setUpdated(false);
                                cancelEditStop(stop);
                            }}>Cancel</Button>

                        {/*<LoaderButton*/}
                        {/*    onClick={async (e) => await _handleDeleteStop(e, props.stop)}*/}
                        {/*    size="sm"*/}
                        {/*    isLoading={isDeleting}*/}
                        {/*    style={{*/}
                        {/*        height: "28px",*/}
                        {/*        paddingTop: "0px",*/}
                        {/*    }}*/}
                        {/*    variant="danger"*/}
                        {/*>*/}
                        {/*    Delete*/}
                        {/*</LoaderButton>*/}
                        <LoaderButton
                            onClick={async () => {
                                await handleSave(stop);
                            }}
                            size="sm"
                            isLoading={isSaving}
                            disabled={isSaving || isInvalid()}
                            feature={FEATURE.stps}
                            className="btn-primary"
                        >
                            Save
                        </LoaderButton>
                    </div>
                </Col>
            </Row>
        </div>
    );
}

export default React.memo(EditStop);

