import React, {useCallback, useEffect, useState} from 'react';
import {Button, Input} from 'antd';
import {ReactComponent as Down} from '../../assets/icons/Down.svg';
import {values} from 'lodash/object';
import ShiftBatSelectRowEdit from './ShiftBatSelectRowEdit';
import {SyncOutlined} from '@ant-design/icons';
import {ReactComponent as Check} from '../../assets/icons/Check.svg';
import {ReactComponent as Close} from '../../assets/icons/Close.svg';
import ShiftBatSelectType from './ShiftBatSelectType';
import {Col, Row} from 'react-bootstrap';
import ShiftBatTimePicker from './ShiftBatTimePicker';
import EditStop from '../../components/EditStop';
import {stopModelData} from '../../services/ModelService';

function ShiftBatStopRowEdit({
                                 shiftBatRow,
                                 setTime, setDescription,
                                 allStops, updating, onUpdate, onCancel,
                                 stop, setStop, setAllStops,
                                 setTitle, charter,
                                 resetRow, type, time,
                                 title, setOnMapSelection,
                                 description, allowRoutes
                             }) {
    const [options, setOptions] = useState([]);
    const [newStop, setNewStop] = useState(null);

    const onMapSelect = useCallback(data => {
        console.log('onMapSelect', data);
        if (data?.stopId) {
            // data is an existing stop
            setStop(data);
        } else if (data?.lat && data?.lon) {
            // data is a point on the map
            setNewStop({...data, stopId: '_', stopType: charter ? 'charter' : 'bus'});
        }
    }, [setStop]);

    useEffect(() => {
        setOnMapSelection(selectionFn =>
            selectionFn ? null : data => onMapSelect(data)
        );
    }, []);

    useEffect(() => {
        // Set the service options whenallRoutes change
        setOptions([{
            value: 'new',
            label: 'Create new point'
        }].concat(values(allStops).filter(stop => stop.verified > 0 && stop.duplicate !== 1).map(stop => ({
            label: stop.stopName,
            value: stop.stopId
        }))));
    }, [allStops, setOptions]);

    return (
        <>
            {/*<EditStopModal*/}
            {/*    stop={new Stop({*/}
            {/*        ...selectedPt,*/}
            {/*        stopId: '_',*/}
            {/*        stopName: '',*/}
            {/*        duplicate: -1*/}
            {/*    })}*/}
            {/*    allowedStopTypes={charter ? ['bus', 'charter'] : ['bus']}*/}
            {/*    visible={showCreateStop}*/}
            {/*    setVisible={setShowCreateStop}*/}
            {/*    cancelEditStop={async () => {*/}
            {/*        setShowCreateStop(false);*/}
            {/*    }}*/}
            {/*    handleSaveEdit={async (stop) => {*/}
            {/*        await createNewStop(stop);*/}
            {/*        handleAddStop(stop, buildToStop);*/}
            {/*        setShowCreateStop(false);*/}
            {/*        setShowAllStops(true);*/}
            {/*    }}*/}
            {/*    allStops={allStops}*/}
            {/*    type={charter ? 'charter' : type ? type : 'bus'}*/}
            {/*    buildToStop={buildToStop}*/}
            {/*    setBuildToStop={setBuildToStop}*/}
            {/*/>*/}
            <div key={shiftBatRow.id}>
                <Row className="row-compact">
                    <Col md="auto">
                        <ShiftBatTimePicker time={time} setTime={setTime}/>
                    </Col>
                    <Col className="row-col-gap" style={{maxWidth: 'calc(100% - 77px)'}}>
                        <ShiftBatSelectType type={type} resetRow={resetRow} className={'inputselect-small'}
                                            allowRoutes={allowRoutes}/>
                        <Input size="small" placeholder="Driver instructions" className="inputtext-small"
                               value={description || ''}
                               onChange={e => setDescription(e.target.value)}/>
                        {newStop && <EditStop stop={newStop} type={'charter'} cancelEditStop={() => setNewStop(null)}
                                              handleSaveEdit={async (stop) => {
                                                  stop.verified = 1;
                                                  stop.duplicate = -1;
                                                  await stopModelData.save(stop);
                                                  setStop(stop);
                                                  setNewStop(null);
                                                  setAllStops(allStops => ({...allStops, [stop.stopId]: stop}));
                                              }}
                                              allowedStopTypes={['bus', 'charter']} allStops={allStops}
                        />}

                        <ShiftBatSelectRowEdit className={'inputselect-small w-100'} ttTitle={'Select a point'}
                                               suffixIcon={<Down/>}
                                               value={stop?.stopId || 'new'}
                                               placeholder={'Select stop'}
                                               onChange={value => {
                                                   if (value === 'new') {
                                                       return setOnMapSelection(selectionFn =>
                                                           selectionFn ? null : data => onMapSelect(data)
                                                       );
                                                   }
                                                   const stop = allStops[value];
                                                   setStop(stop);
                                                   setTitle(stop.stopName);
                                                   // setStopRow(stoprow => ({...stoprow, stop: stop, title: stop.stopName}))
                                               }}
                                               options={options}
                        />
                    </Col>
                    <Col md="auto">
                        <div className="sb-edit-controls">
                            <Button className="sb-done"
                                    icon={<Check/>}
                                    disabled={updating || !stop}
                                    onClick={e => onUpdate(e, {
                                        ...shiftBatRow,
                                        type,
                                        time: parseInt(time),
                                        title,
                                        stop,
                                        description,
                                    })}/>
                            <Button className="sb-close"
                                    icon={updating && shiftBatRow.needsUpdate() ? <SyncOutlined spin={true}/> :
                                        <Close/>}
                                    disabled={updating} onClick={e => onCancel(e, shiftBatRow)}/>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    );
}

export default React.memo(ShiftBatStopRowEdit);
