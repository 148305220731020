import React from "react";
import "./ShiftBat.css";
import {Button, Input, Select} from "antd";
// import {CloseOutlined} from "@ant-design/icons";
import {Image} from "react-bootstrap";
//import {LinkContainer} from "react-router-bootstrap";
import {ReactComponent as Info} from '../../assets/icons/Info.svg';
import {ReactComponent as Print} from '../../assets/icons/Print.svg';
import {ReactComponent as Edit} from '../../assets/icons/Edit.svg';
import {ReactComponent as Calendar} from '../../assets/icons/Calendar.svg';
import {ReactComponent as Timer} from '../../assets/icons/Timer.svg';
import {ReactComponent as Services} from '../../assets/icons/Services.svg';
import {ReactComponent as DeadRunning} from '../../assets/icons/DeadRunning.svg';
import {ReactComponent as Break} from '../../assets/icons/Break.svg';
import {ReactComponent as Warning} from '../../assets/icons/Warning.svg';
import {ReactComponent as Plus} from '../../assets/icons/Plus.svg';
import {ReactComponent as Minus} from '../../assets/icons/Minus.svg';
import {ReactComponent as Check} from '../../assets/icons/Check.svg';
import {ReactComponent as Undo} from '../../assets/icons/Undo.svg';
import {ReactComponent as Reset} from '../../assets/icons/Reset.svg';
import {ReactComponent as Redo} from '../../assets/icons/Redo.svg';
import {ReactComponent as Down} from '../../assets/icons/Down.svg';
import {ReactComponent as Close} from '../../assets/icons/Close.svg';

const { Option } = Select;

function ShiftBatViewTemplate() {
    return (
        <div className="ShiftBatView">

            {/* Start Header actions: View mode */}
            <div className="d-flex align-items-center justify-content-between page-main-controls">
                <div className="d-flex page-sec-controls">
                    <Button type="primary" className="icon-button btn-filled" icon={<Print />}>Print</Button>
                    <a href={'https://learn.busable.app/driver-shift-bats'} target="_blank" rel="noreferrer noopener"><Button type="primary" className="icon-button btn-filled" icon={<Info />}>Help</Button></a>
                </div>
                <Button type="primary" className="icon-button btn-filled" icon={<Edit />}>Edit</Button>
            </div>
            {/* End Header actions: View mode */}

            {/* Start Header actions: Edit mode */}
            <div className="d-flex align-items-center justify-content-between page-main-controls">
                <a href={'https://learn.busable.app/driver-shift-bats'} target="_blank" rel="noreferrer noopener"><Button type="primary" className="icon-button btn-filled" icon={<Info />}>Help</Button></a>
                <div className="d-flex page-sec-controls">
                    <Button type="primary" className="icon-button btn-filled" icon={<Undo />}>Undo (2)</Button>
                    <Button type="primary" className="icon-button btn-filled btn-error" icon={<Reset />}>Reset</Button>
                    <Button type="primary" className="icon-button btn-filled" icon={<Redo />}>Redo (0)</Button>
                    <Button type="primary" className="icon-button btn-filled btn-success" icon={<Check />}>Save</Button>
                </div>
            </div>
            {/* End Header actions: Edit mode */}

            <div className="card-main d-flex align-items-center justify-content-between">
                <div className={`row RouteTitle flex-nowrap align-items-center`}>
                    <div className={`RouteLogo`} style={{borderColor: '#00b26a'}}>
                        <Image src={'https://prod-rm-web-infra-gpx-s3-gpxfiles47af3947-1f2jzq8rsjwfq.s3.ap-southeast-2.amazonaws.com/public/Bus.svg'} className="BusIcon" />
                    </div>
                    <div className={`RouteNumber`} style={{backgroundColor: '#00b26a'}}>1028</div>
                    <div className={`RouteInfo`}>
                        <h1 style={{color: "#000"}}>Campbelltown to Eagle vale</h1>
                        <div className={`RouteDetails`}>st Gregory HS</div>
                    </div>
                </div>
                <div className="d-flex info-text-wrap">
                    <div className="icon-info-text"><Timer/> 5 Hrs 25 Mins</div>
                    <div className="icon-info-text"><Services/> 227 Kms</div>
                    <div className="icon-info-text"><Calendar/> 05/05/2020</div>
                </div>
            </div>
            <div className="card-main shift-bat-map no-pad d-flex">
                <div className="map-holder" style={{width: '65%'}}></div>
                <div className="map-shiftbats" style={{width: '35%'}}>
                    <h3 className="title">Shift Bat</h3>
                    <div className="sb-items-wrap edit-mode-">
                        <div className="row d-flex align-items-center sb-row current-edit">
                            <div className="col-sm-1 sb-time"><span>7:10</span></div>
                            <div className="col-sm-11 d-flex RouteTitle flex-nowrap align-items-center sb-info">
                                <div className={`RouteInfo`}>
                                    <h4 className="title">Start at Depot - 18 Wonga</h4>
                                    <span className={`RouteDetails`}>Pre Departure check MUST be completed</span>
                                </div>

                                {/* Start Row controls: Edit mode */}
                                <div className="sb-controls">
                                    <Button className="sb-delete-row" icon={<Minus />} />
                                    <Button className="sb-edit-row" icon={<Edit />} />
                                    <Button className="sb-add-row" icon={<Plus />} />
                                </div>
                                {/* End Row controls: Edit mode */}
                            </div>

                            {/* Start Row edit: Edit mode */}
                            <div className="col-lg-12 sb-row-edit">
                                <div>
                                    <Input size="small" placeholder="Time" className="inputtext-small sb-time-edit" />
                                    <Select size="small" defaultValue="Service" className="inputselect-small" suffixIcon={<Down />}>
                                        <Option value="Service">Service</Option>
                                        <Option value="Notes">Notes</Option>
                                        <Option value="Break">Break</Option>
                                        <Option value="AddLocation">Add Location</Option>
                                    </Select>
                                    <Input size="small" placeholder="Title" className="inputtext-small" />
                                    <div className="sb-edit-controls">
                                        <Button className="sb-done" icon={<Check />} />
                                    </div>
                                </div>
                                <div>
                                    <Input size="small" placeholder="Description" className="inputtext-small" />
                                    <div className="sb-edit-controls">
                                        <Button className="sb-close" icon={<Close />} />
                                    </div>
                                </div>
                            </div>
                            {/* End Row edit: Edit mode */}
                        </div>

                        <div className="row d-flex align-items-center sb-row ">
                            <div className="col-sm-1 sb-time"><span>7:10</span></div>
                            <div className="col-sm-11 d-flex RouteTitle flex-nowrap align-items-center sb-info">
                                <div className={`RouteInfo`}>
                                    <h4 className="title">Depart Depot</h4>
                                    <span className={`RouteDetails`}>Show “School Bus” on Destination (106 on Desto), Activate “Wig Wags”, Set Ticket Machine to Shift 6, Route 05PI, Trip No: 0715</span>
                                </div>

                                {/* Start Row controls: Edit mode */}
                                <div className="sb-controls">
                                    <Button className="sb-delete-row" icon={<Minus />} />
                                    <Button className="sb-edit-row" icon={<Edit />} />
                                    <Button className="sb-add-row" icon={<Plus />} />
                                </div>
                                {/* End Row controls: Edit mode */}
                            </div>
                            <div className="col-lg-12 sb-row-routes">
                                <div className="row align-items-center">
                                    <div className="col-sm-1 sb-time"><span>7:10</span></div>
                                    <div className="col-sm-1 sb-step-no"><span style={{backgroundColor: '#00B26A'}}>1</span></div>
                                    <div className="col-sm-10 d-flex RouteTitle flex-nowrap align-items-center sb-info">
                                        <div className={`RouteInfo`}>
                                            <h4 className="title">Depart Depot</h4>
                                            <Button className="sb-add-comment" icon={<Plus />} />
                                        </div>
                                    </div>

                                    {/* Start Row edit: Edit mode */}
                                    <div className="col-lg-12 sb-row-edit">
                                        <div>
                                            <Input size="small" placeholder="Time" className="inputtext-small sb-time-edit" />
                                            <Input size="small" placeholder="Notes" className="inputtext-small" />
                                            <Select size="small" defaultValue="Normal" className="inputselect-small" suffixIcon={<Down />}>
                                                <Option value="Normal">Normal</Option>
                                                <Option value="Medium">Medium</Option>
                                                <Option value="High">High</Option>
                                            </Select>
                                            <div className="sb-edit-controls">
                                                <Button className="sb-close" icon={<Close />} />
                                                <Button className="sb-done" icon={<Check />} />
                                            </div>
                                        </div>
                                    </div>
                                    {/* End Row edit: Edit mode */}
                                </div>

                                <div className="row align-items-center align-items-stretch justify-content-center">
                                    <div className="col-sm-1 sb-time"><span>7:10</span></div>
                                    <div className="col-sm-1 sb-step-no"><span style={{backgroundColor: '#00B26A'}}>2</span></div>
                                    <div className="col-sm-10 d-flex RouteTitle flex-nowrap align-items-center sb-info">
                                        <div className={`RouteInfo`}>
                                            <h4 className="title">Eaglevale Market Place</h4>
                                            <Button className="sb-add-comment" icon={<Plus />} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row align-items-center align-items-stretch justify-content-center sb-comment-row">
                                    <div className="col-sm-1 sb-time"></div>
                                    <div className="col-sm-1 sb-step-no"></div>
                                    <div className="col-sm-10 d-flex RouteTitle flex-nowrap align-items-center sb-info">
                                        <div className="sb-comment-row-info">Set Ticket Machine to Shift 6, Route 05PI</div>

                                        {/* Start Row controls: Edit mode */}
                                        <div className="sb-controls">
                                            <Button className="sb-delete-row" icon={<Minus />} />
                                            <Button className="sb-edit-row" icon={<Edit />} />
                                            <Button className="sb-add-row" icon={<Plus />} />
                                        </div>
                                        {/* End Row controls: Edit mode */}
                                    </div>
                                </div>
                                <div className="row align-items-center align-items-stretch justify-content-center sb-comment-row sb-comment-important">
                                    <div className="col-sm-1 sb-time"></div>
                                    <div className="col-sm-1 sb-step-no"></div>
                                    <div className="col-sm-10 d-flex RouteTitle flex-nowrap align-items-center sb-info">
                                        <div className="sb-comment-row-info">Pre Departure check MUST be completed</div>

                                        {/* Start Row controls: Edit mode */}
                                        <div className="sb-controls">
                                            <Button className="sb-delete-row" icon={<Minus />} />
                                            <Button className="sb-edit-row" icon={<Edit />} />
                                            <Button className="sb-add-row" icon={<Plus />} />
                                        </div>
                                        {/* End Row controls: Edit mode */}
                                    </div>
                                </div>
                                <div className="row align-items-center">
                                    <div className="col-sm-1 sb-time"><span>7:10</span></div>
                                    <div className="col-sm-1 sb-step-no"><span style={{backgroundColor: '#00B26A'}}>3</span></div>
                                    <div className="col-sm-10 d-flex RouteTitle flex-nowrap align-items-center sb-info">
                                        <div className={`RouteInfo`}>
                                            <h4 className="title">Depart Depot</h4>
                                            <Button className="sb-add-comment" icon={<Plus />} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row align-items-center align-items-stretch justify-content-center">
                                    <div className="col-sm-1 sb-time"><span>7:10</span></div>
                                    <div className="col-sm-1 sb-step-no"><span style={{backgroundColor: '#FFBA34'}}>4</span></div>
                                    <div className="col-sm-10 d-flex RouteTitle flex-nowrap align-items-center sb-info">
                                        <div className={`RouteInfo`}>
                                            <h4 className="title">Eaglevale Market Place</h4>
                                            <Button className="sb-add-comment" icon={<Plus />} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row align-items-center align-items-stretch justify-content-center sb-row-transfers">
                                    <div className="col-sm-1 sb-time"><span>7:10</span></div>
                                    <div className="col-sm-11 d-flex RouteTitle flex-nowrap align-items-center sb-info">
                                        <div className="sb-transfer-row-info">Pick Up John Therry From
                                            <span>AM02</span>
                                            <span>AM03</span>
                                            <span>AM04</span>
                                            <span>AM05</span>
                                            <span>AM09</span>
                                            <span>AM22</span>
                                            <span>AM23</span>
                                            <span>AM09</span>
                                            <span>AM22</span>
                                            <span>AM23</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row d-flex align-items-center sb-row">
                            <div className="col-sm-1 sb-time"></div>
                            <div className="col-sm-11 d-flex RouteTitle flex-nowrap align-items-center sb-info">
                                <div className={`RouteInfo`}>
                                    <h4 className="title">Start of School Run</h4>
                                </div>

                                {/* Start Row controls: Edit mode */}
                                <div className="sb-controls">
                                    <Button className="sb-delete-row" icon={<Minus />} />
                                    <Button className="sb-edit-row" icon={<Edit />} />
                                    <Button className="sb-add-row" icon={<Plus />} />
                                </div>
                                {/* End Row controls: Edit mode */}
                            </div>
                        </div>

                        <div className="row d-flex align-items-center sb-row">
                            <div className="col-sm-1 sb-time"><span>23:59</span></div>
                            <div className="col-sm-11 d-flex RouteTitle flex-nowrap align-items-center sb-info">
                                <div className={`RouteLogo`} style={{borderColor: '#00b26a'}}>
                                    <Image src={'https://prod-rm-web-infra-gpx-s3-gpxfiles47af3947-1f2jzq8rsjwfq.s3.ap-southeast-2.amazonaws.com/public/Bus.svg'} className="BusIcon" />
                                </div>
                                <div className={`RouteNumber`} style={{backgroundColor: '#00b26a'}}>1028</div>
                                <div className={`RouteInfo`}>
                                    <h4 className="title">Campbelltown to Eagle vale</h4>
                                    <span className={`RouteDetails`}>st Gregory HS</span>
                                </div>

                                {/* Start Row controls: Edit mode */}
                                <div className="sb-controls">
                                    <Button className="sb-delete-row" icon={<Minus />} />
                                    <Button className="sb-edit-row" icon={<Edit />} />
                                    <Button className="sb-add-row" icon={<Plus />} />
                                </div>
                                {/* End Row controls: Edit mode */}
                            </div>
                        </div>

                        <div className="row d-flex align-items-center sb-row">
                            <div className="col-sm-1 sb-time"><span>7:10</span></div>
                            <div className="col-sm-11 d-flex RouteTitle flex-nowrap align-items-center sb-info">
                                <div className={`RouteIcon`}><DeadRunning /></div>
                                <div className={`RouteInfo`}>
                                    <h4 className="title">5km Dead Running</h4>
                                </div>

                                {/* Start Row controls: Edit mode */}
                                <div className="sb-controls">
                                    <Button className="sb-delete-row" icon={<Minus />} />
                                    <Button className="sb-edit-row" icon={<Edit />} />
                                    <Button className="sb-add-row" icon={<Plus />} />
                                </div>
                                {/* End Row controls: Edit mode */}
                            </div>
                        </div>

                        <div className="row d-flex align-items-center sb-row current-edit">
                            <div className="col-sm-1 sb-time"><span>7:10</span></div>
                            <div className="col-sm-11 d-flex RouteTitle flex-nowrap align-items-center sb-info">
                                <div className={`RouteIcon`}><Break /></div>
                                <div className={`RouteInfo`}>
                                    <h4 className="title">Break (30 mins)</h4>
                                </div>

                                {/* Start Row controls: Edit mode */}
                                <div className="sb-controls">
                                    <Button className="sb-delete-row" icon={<Minus />} />
                                    <Button className="sb-edit-row" icon={<Edit />} />
                                    <Button className="sb-add-row" icon={<Plus />} />
                                </div>
                                {/* End Row controls: Edit mode */}
                            </div>

                            {/* Start Row edit: Edit mode */}
                            <div className="col-lg-12 sb-row-edit">
                                <div>
                                    <Input size="small" placeholder="Time" className="inputtext-small sb-time-edit" />
                                    <Select size="small" defaultValue="Break" className="inputselect-small" suffixIcon={<Down />}>
                                        <Option value="Service">Service</Option>
                                        <Option value="Notes">Notes</Option>
                                        <Option value="Break">Break</Option>
                                        <Option value="AddLocation">Add Location</Option>
                                    </Select>
                                    <Input size="small" placeholder="Duration" className="inputtext-small" addonAfter="mins" />
                                    <div className="sb-edit-controls">
                                        <Button className="sb-close" icon={<Close />} />
                                        <Button className="sb-done" icon={<Check />} />
                                    </div>
                                </div>
                            </div>
                            {/* End Row edit: Edit mode */}
                        </div>

                        <div className="row d-flex align-items-center sb-row">
                            <div className="col-sm-1 sb-time"><span>7:10</span></div>
                            <div className="col-sm-11 d-flex RouteTitle flex-nowrap align-items-center sb-info">
                                <div className={`RouteIcon`}><DeadRunning /></div>
                                <div className={`RouteInfo`}>
                                    <h4 className="title">20km Dead Running</h4>
                                </div>
                                <Warning className="icon-sm" />

                                {/* Start Row controls: Edit mode */}
                                <div className="sb-controls">
                                    <Button className="sb-delete-row" icon={<Minus />} />
                                    <Button className="sb-edit-row" icon={<Edit />} />
                                    <Button className="sb-add-row" icon={<Plus />} />
                                </div>
                                {/* End Row controls: Edit mode */}
                            </div>
                        </div>

                        <div className="row d-flex align-items-center sb-row sb-important">
                            <div className="col-sm-1 sb-time"></div>
                            <div className="col-sm-11 d-flex RouteTitle flex-nowrap align-items-center sb-info">
                                <div className={`RouteIcon`}><Warning /></div>
                                <div className={`RouteInfo`}>
                                    <h4 className="title">Walk to Back Seat of Your Bus to Check No Child is Left on Bus</h4>
                                </div>

                                {/* Start Row controls: Edit mode */}
                                <div className="sb-controls">
                                    <Button className="sb-delete-row" icon={<Minus />} />
                                    <Button className="sb-edit-row" icon={<Edit />} />
                                    <Button className="sb-add-row" icon={<Plus />} />
                                </div>
                                {/* End Row controls: Edit mode */}
                            </div>
                        </div>

                        <div className="row d-flex align-items-center sb-row">
                            <div className="col-sm-1 sb-time"><span>12:30</span></div>
                            <div className="col-sm-11 d-flex RouteTitle flex-nowrap align-items-center sb-info">
                                <div className={`RouteInfo`}>
                                    <h4 className="title">End at Depot - 18 Wonga</h4>
                                </div>

                                {/* Start Row controls: Edit mode */}
                                <div className="sb-controls">
                                    <Button className="sb-delete-row" icon={<Minus />} />
                                    <Button className="sb-edit-row" icon={<Edit />} />
                                    <Button className="sb-add-row" icon={<Plus />} />
                                </div>
                                {/* End Row controls: Edit mode */}
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
}

export default React.memo(ShiftBatViewTemplate)
