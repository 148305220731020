import React, { useCallback } from "react";
import "./Customers.css";
import { Card, Col, Menu } from "antd";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useAppContext } from "../../libs/contextLib";
import { ReactComponent as Calender } from "../../assets/icons/Calendar.svg";
import { ReactComponent as Bus } from "../../assets/icons/Bus.svg";
import { ReactComponent as Quote } from "../../assets/icons/Quote.svg";

function getItem({ label, key, icon, children, type, disabled }) {
    return {
        key,
        icon,
        children,
        label,
        type,
        disabled,
    };
}

function CustomerMenu({
    activeKey,
    customer,
    disabled = { details: false },
    hide = { details: false },
}) {
    const history = useHistory();
    const { setBreadcrumb } = useAppContext();
    setBreadcrumb(customer?.name || "New Customer");

    const items = useCallback(() => {
        if (!customer || !hide || !disabled) {
            return [];
        }
        const items = [];
        if (!hide.details) {
            items.push(
                getItem({
                    label: "Customer Details",
                    key: "1",
                    icon: <Calender width={20} height={20} />,
                    disabled: disabled.customer,
                })
            );
        }

        return items;
    }, [customer, disabled, hide]);

    const onMenuSelect = useCallback(
        ({ key }) => {
            const menu = items().find((item) => item.key === key).label;

            switch (menu) {
                case "Customer Details":
                    history.push(`/customers/${customer.customerId}`);
                    break;
                default:
                    break;
            }
        },
        [history, customer, items]
    );

    return (
        <Col xs={24} lg={5}>
            <Card bordered={false} className="card-main body-pad-0" style={{ height: "auto" }}>
                <div className="profile-summary">
                    <h2>{customer?.name || "New Customer"}</h2>
                    <div className="text-secondary">{customer?.date}</div>
                </div>
                <Menu
                    className="profile-menu"
                    mode="inline"
                    theme="light"
                    items={items()}
                    selectedKeys={[activeKey]}
                    onSelect={onMenuSelect}
                />
            </Card>
        </Col>
    );
}

export default React.memo(CustomerMenu);
