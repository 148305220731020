import {WebMercatorViewport} from "react-map-gl";
import {getBounds as geoGetBounds} from 'geolib';
import {getNearestSegment} from "./routes-lib";
import polyline from "@mapbox/polyline";
import {toCoordArray} from "./pathLib";
import geohash from "ngeohash";
import config from "../config";
import {roundToPrecision} from "./formatLib";


export const GEOHASH_LENGTH = 13;
export const LATLON_PRECISION = 6;

export const calculateGeoHash = (s, keyLength, log) => {
    if (s && Number.isFinite(s.lat) && Number.isFinite(s.lon)) {
        s.lat = roundToPrecision(s.lat, LATLON_PRECISION, log);
        s.lon = roundToPrecision(s.lon, LATLON_PRECISION, log);
        s.geohash = geohash.encode(s.lat, s.lon, GEOHASH_LENGTH)
        if (keyLength) {
            s.geokey = s.geohash.slice(0, keyLength)
        }
    }
    return s;
}

export const getViewport = ({geos, padding, minZoom = 1, maxZoom = 20, viewport}) => {
    let {longitude, latitude, zoom} = new WebMercatorViewport({
        width: (viewport?.width && viewport.width > 100) ? viewport.width : 800,
        height: (viewport?.height && viewport.height > 100) ? viewport.height : 600
    }).fitBounds(getBounds(geos, padding), {
        padding: 50
    });
    let actualZoom = zoom < minZoom ? minZoom : zoom
    actualZoom = actualZoom > maxZoom ? maxZoom : zoom
    return {
        width: (viewport?.width && viewport.width > 100) ? viewport.width : 800,
        height: (viewport?.height && viewport.height > 100) ? viewport.height : 600,
        longitude,
        latitude,
        zoom: actualZoom,
        bearing: 0,
        pitch: 0
    }
}

export const getViewportForStop = (stop, zoom = 17) => {
    return new WebMercatorViewport({
        width: 800,
        height: 600,
        latitude: Number.isFinite(stop.lat) ? stop.lat : 0,
        longitude: Number.isFinite(stop.lon) ? stop.lon : 0,
        zoom: zoom,
        pitch: 0,
        bearing: 0
    });
}

export const getViewportForStops = (stops, padding = 50, maxZoom = 17) => {
    const bounds = geoGetBounds(stops);
    const viewport = new WebMercatorViewport({
        width: 800,
        height: 600,
    }).fitBounds([[bounds.minLng, bounds.minLat], [bounds.maxLng, bounds.maxLat]], {
        padding: padding, maxZoom
    })

    const {width, height, latitude, longitude, zoom, pitch, bearing} = viewport;
    return {width, height, latitude, longitude, zoom, pitch, bearing}
    // return new WebMercatorViewport({
    //     width: 800,
    //     height: 600,
    //     latitude: Number.isFinite(stop.lat) ? stop.lat : 0,
    //     longitude: Number.isFinite(stop.lon) ? stop.lon : 0,
    //     zoom: zoom,
    //     pitch: 0,
    //     bearing: 0
    // });
}

// Find perpendicular point on path A-B from C
// function getSpPoint(A, B, C) {
//     var x1 = A.x, y1 = A.y, x2 = B.x, y2 = B.y, x3 = C.x, y3 = C.y;
//     var px = x2 - x1, py = y2 - y1, dAB = px * px + py * py;
//     var u = ((x3 - x1) * px + (y3 - y1) * py) / dAB;
//     var x = x1 + u * px, y = y1 + u * py;
//     return {x: x, y: y}; //this is D
// }

export const ensureWpAtStop = (stop, route, checkDirection, fromWp, furthestSeg) => {
    // console.log('ensureWpAtStop: ', stop.stopName, fromWp);
    const log = stop?.stopName?.includes('Church St at Lennox St')
    log && console.log('Ensuring stop @ %s from wpIdx %d', stop?.stopName, fromWp)
    let nearestSeg = getNearestSegment(stop, route.waypoints, {fromWpIdx: fromWp, checkDirection, log, furthestSeg});
    log && console.log(route.routeNumber, stop.stopName, nearestSeg)
    log && nearestSeg && console.log(route.routeNumber, stop.stopName, nearestSeg.segment.dist, nearestSeg.segment.distanceToNearestWp)
    if (nearestSeg && nearestSeg.segment.distanceToNearestWp - nearestSeg.segment.dist > 10) {

        log && console.log('Adding point...', route.routeNumber, nearestSeg.segment, stop.stopName);
        // let pointOnPath = getSpPoint(
        //     {
        //         x: nearestSeg.segment.start.lon,
        //         y: nearestSeg.segment.start.lat
        //     }, {x: nearestSeg.segment.end.lon, y: nearestSeg.segment.end.lat},
        //     {x: stop.lon, y: stop.lat})
        //
        // // var dist = Math.sqrt( Math.pow((pointOnPath.x-nearestSeg.segment.start.lon), 2) + Math.pow((pointOnPath.y-nearestSeg.segment.start.lat), 2) );
        // let dist = getDistanceInMetres(nearestSeg.segment.start, {lat: pointOnPath.y, lon: pointOnPath.x})
        // const newWp = {
        //     lat: pointOnPath.y,
        //     lon: pointOnPath.x,
        //     distance: nearestSeg.segment.start.distance + dist
        // }
        const newWp = {
            ...nearestSeg.segment.calculatedClosestWp,
            distance: nearestSeg.segment.start.distance + nearestSeg.distance,
            delta: nearestSeg.segment.start.delta + nearestSeg.delta
        }
        // console.log('new wp', newWp)
        // console.log('splicing at', nearestSeg.segment.endIdx)
        //     console.log('wp count before', route.waypoints.length)
        route.waypoints.splice(nearestSeg.segment.startIdx + 1, 0, newWp);
        // console.log('wp count after', route.waypoints.length)
        nearestSeg.addedWp = true
        nearestSeg.segment.nearestWpIdx = nearestSeg.segment.startIdx + 1
        // nearestSeg = getNearestSegment(stop, route.waypoints, checkDirection)
    }
    return nearestSeg
};
export const ensureWpAtStops = (route) => {
    route.stops.forEach(s => ensureWpAtStop(s, route))
}

export const toDeadBeforePath = (route) => {
    // const firstStop = route.stops.filter(s => !s.stopType || s.stopType !== 'depot')[0]
    // const nearestWp = getNearestWaypoint(firstStop, route.waypoints)
    // const nearestSeg = getNearestSegment(firstStop, route.waypoints)

    let startIdx = route.startWpIdx >= 0 ? route.startWpIdx : 0;

    let path = route.waypoints.slice(0, startIdx + 1).map(wp => {
        return [wp.lon, wp.lat]
    })
    // let pointOnPath = getSpPoint(
    //     {
    //         x: nearestSeg.segment.start.lon,
    //         y: nearestSeg.segment.start.lat
    //     }, {x: nearestSeg.segment.end.lon, y: nearestSeg.segment.end.lat},
    //     {x: firstStop.lon, y: firstStop.lat})
    // path.push([pointOnPath.x, pointOnPath.y])
    return path
    // return route.waypoints.slice(0,nearestWp.nearestIdx+1).map(wp => {
    //     return [wp.lon, wp.lat]
    // })
}

export const toDeadAfterPath = (route) => {
    // const allPublicStops = route.stops.filter(s => !s.stopType || s.stopType !== 'depot')
    // const lastStop = allPublicStops[allPublicStops.length - 1]
    // const nearestWp = getNearestWaypoint(lastStop, route.waypoints)
    // const nearestSeg = getNearestSegment(lastStop, route.waypoints)
    let endIdx = route.endWpIdx ? route.endWpIdx : route.waypoints.length;

    let path = route.waypoints.slice(endIdx, route.waypoints.length).map(wp => {
        return [wp.lon, wp.lat]
    })

    // let pointOnPath = getSpPoint(
    //     {
    //         x: nearestSeg.segment.start.lon,
    //         y: nearestSeg.segment.start.lat
    //     }, {x: nearestSeg.segment.end.lon, y: nearestSeg.segment.end.lat},
    //     {x: lastStop.lon, y: lastStop.lat})
    // path.unshift([pointOnPath.x, pointOnPath.y])
    return path
}

export const toPublicPath = (route) => {
    const log = route.routeNumber === 'PM20'
    log && console.log('Public Path for %s, startWpIdx: %d, endWpIdx: %d, wp length: %d', route.routeNumber, route.startWpIdx, route.endWpIdx, route.waypoints.length)
    let startIdx = route.startWpIdx >= 0 ? route.startWpIdx : 0;
    let endIdx = route.endWpIdx ? route.endWpIdx + 1 : route.waypoints.length;
    // console.log(route.routeNumber, 'route length', route.waypoints.length, 'slice', route.startWpIdx, startIdx, route.endWpIdx, endIdx)
    return route.waypoints.slice(startIdx, endIdx).map(wp => {
        return [wp.lon, wp.lat]
    });
};

// export const toPublicPath = (route) => {
//     const allPublicStops = route.stops.filter(s => 'depot' !== s.stopType)
//     const firstStop = allPublicStops[0]
//     const nearestWpFirst = getNearestWaypoint(firstStop, route.waypoints)
//     // const nearestWpFirst = getNearestSegment(firstStop, route.waypoints)
//     if (!nearestWpFirst) {
//         return []
//     }
//
//     const lastStop = allPublicStops[allPublicStops.length - 1]
//     const nearestWpLast = getNearestWaypoint(lastStop, route.waypoints)
//     // const nearestWpLast = getNearestSegment(lastStop, route.waypoints)
//     if (!nearestWpLast) {
//         return []
//     }
//     let path = route.waypoints.slice(nearestWpFirst.nearestIdx, nearestWpLast.nearestIdx+1).map(wp => {
//         return [wp.lon, wp.lat]
//     })
//
//     // let firstPointOnPath = getSpPoint(
//     //     {
//     //         x: nearestWpFirst.segment.start.lon,
//     //         y: nearestWpFirst.segment.start.lat
//     //     }, {x: nearestWpFirst.segment.end.lon, y: nearestWpFirst.segment.end.lat},
//     //     {x: firstStop.lon, y: firstStop.lat})
//     // path.unshift([firstPointOnPath.x, firstPointOnPath.y])
//     // let lastPointOnPath = getSpPoint(
//     //     {
//     //         x: nearestWpLast.segment.start.lon,
//     //         y: nearestWpLast.segment.start.lat
//     //     }, {x: nearestWpLast.segment.end.lon, y: nearestWpLast.segment.end.lat},
//     //     {x: lastStop.lon, y: lastStop.lat})
//     // path.push([lastPointOnPath.x, lastPointOnPath.y])
//     return path
// }

export const toPath = (geo) => {
    if (geo.waypoints) {
        return geo.waypoints.map(wp => {
            if (Number.isFinite(wp.lon) && Number.isFinite(wp.lat)) {
                return [wp.lon, wp.lat]
            } else {
                return [0, 0]
            }
        })
    }
    return [[geo.lon, geo.lat]]
}

export const getBounds = (geos, padding) => {
    padding = padding || 0.0
    geos = Array.isArray(geos) ? geos : [geos]
    let points = []
    geos.forEach(geo => {
        points = points.concat(toPath(geo))
    })
    if (!points.length) {
        points = [[0, 0], [0, 0]];
    }
    const west = Math.min(...points.map((p) => p[0]));
    const east = Math.max(...points.map((p) => p[0]));
    const south = Math.min(...points.map((p) => p[1]));
    const north = Math.max(...points.map((p) => p[1]));
    return [[west, south], [east + padding, north]]
}

export const toSegmentedGeoJson = (route, noDeadRunning) => {
    const {routeNumber, routeName, routeDetails} = route
    const geojson = {
        type: 'FeatureCollection',
        features: [
            {
                id: "deadBefore",
                type: "Feature",
                geometry: {
                    type: "LineString",
                    coordinates: toDeadBeforePath(route)
                },
                properties: {routeNumber, routeName, routeDetails, route}
            },
            {
                id: "public",
                type: "Feature",
                geometry: {
                    type: "LineString",
                    coordinates: toPublicPath(route)
                },
                properties: {routeNumber, routeName, routeDetails, route}
            },
            {
                id: "deadAfter",
                type: "Feature",
                geometry: {
                    type: "LineString",
                    coordinates: toDeadAfterPath(route)
                },
                properties: {routeNumber, routeName, routeDetails, route}
            },
        ]
    };

    return geojson;
}

export const toPublicGeoJson = (routes) => {

    if (!Array.isArray(routes)) {
        routes = [routes];
    }
    const features = routes.map(route => {
        const {routeNumber, routeName, routeDetails, colour} = route;
        return {
            id: "public",
            type: "Feature",
            geometry: {
                type: "LineString",
                coordinates: toPublicPath(route)
            },
            properties: {routeNumber, routeName, routeDetails, colour, route}
        }
    })
    return {
        type: 'FeatureCollection',
        features
    };
}

export const fromGeoJson = (geojson) => {
    let routeFeature = geojson.features[0];
    return routeFeature.geometry.coordinates.map(coord => {
        return {lat: coord[1], lon: coord[0]}
    })
}


export const waypointsFromPolyline = (encodedPolyline, precision) => {
    let points = polyline.decode(encodedPolyline, precision);
    return points.map(coord => {
        return {lat: coord[0], lon: coord[1]}
    })
}


export const toGeoJsonMP = (stops) => {
    const geojson = {
        type: 'FeatureCollection',
        features: [
            {
                type: "Feature",
                geometry: {
                    type: "MultiPoint",
                    coordinates: stops.map(st => {
                        if (Number.isFinite(st.lon) && Number.isFinite(st.lat)) {
                            return [st.lon, st.lat]
                        } else {
                            return [0, 0]
                        }
                    })
                },
            }
        ]
    };

    return geojson;
}


export const toGeoJsonPtLineCollection = (fromStop, stops) => {
    const geojson = {
        type: 'FeatureCollection',
        features: stops.map(stop => {
            const feature = {
                type: "Feature",
                geometry: {
                    type: "LineString",
                    coordinates: []
                },
                properties: {}
            }
            if (Number.isFinite(fromStop.lon) && Number.isFinite(fromStop.lat) && Number.isFinite(stop.lon) && Number.isFinite(stop.lat)) {
                feature.geometry.coordinates = [[fromStop.lon, fromStop.lat], [stop.lon, stop.lat]]
            }
            return feature;
        })
    };
    return geojson
}

export const toGeoJsonWps = (route, points) => {
    return points.map((wp, idx) => {
        return {
            type: "Feature",
            geometry: {
                type: "Point",
                coordinates: toCoordArray(wp)
            },
            properties: {route, wpIdx: idx}
        }})
}

export const toGeoJsonPtCollection = (route, points) => {
    return {
        type: 'FeatureCollection',
        features: toGeoJsonWps(route, points)
    };
}

export const toGeoJsonStopCollection = (stops, opts = {offsetSameStops: false, type: 'Point', objName: 'stop'}) => {
    const geojson = {
        type: 'FeatureCollection',
        features: stops.map((st, idx) => {
            const feature = {
                id: idx + 1, // TODO: for chartering stop numbers
                type: "Feature",
                geometry: {
                    type: opts.type || 'Point',
                    coordinates: []
                },
                [opts.objName || 'stop']: {...st}
            }
            if (Number.isFinite(st.lon) && Number.isFinite(st.lat)) {
                const count = opts.offsetSameStops ? stops.slice(0, idx).filter(stop => stop.stopId === st.stopId).length : 0
                feature.geometry.coordinates = [st.lon + (count * 0.001), st.lat]
            } else {
                feature.geometry.coordinates = [0, 0]
            }
            return feature;
        })
    };
    return geojson;
}

export const toGeoJson = (routes) => {
    if (!Array.isArray(routes)) {
        routes = [routes];
    }
    const features = routes.map(route => {
        const {routeNumber, routeName, routeDetails, colour, deadRunning} = route;
        return {
            type: "Feature",
            geometry: {
                type: "LineString",
                coordinates: toPath(route)
            },
            properties: {routeNumber, routeName, routeDetails, colour, deadRunning, route}
        }
    })
    return {
        type: 'FeatureCollection',
        features
    };
}

export const toGeoJsonLineSegments = route => {
    const {routeNumber, routeName, routeDetails, colour, deadRunning} = route;
    return route.waypoints.slice(1).map((wp, idx) => {
        const prevWp = route.waypoints[idx];
        return {
            type: "Feature",
            geometry: {
                type: "LineString",
                coordinates: [[prevWp.lon, prevWp.lat], [wp.lon, wp.lat]]
            },
            properties: {routeNumber, routeName, routeDetails, colour, deadRunning, route, segmentIdx: idx}
        }
    })
}

export const toGeoJsonWithPts = (route, points) => {
        // const {routeNumber, routeName, routeDetails, colour, deadRunning} = route;
        // const routePathFeature =  {
        //     type: "Feature",
        //     geometry: {
        //         type: "LineString",
        //         coordinates: toPath(route)
        //     },
        //     properties: {routeNumber, routeName, routeDetails, colour, deadRunning, route}
        // }
    return {
        type: 'FeatureCollection',
        features: toGeoJsonLineSegments(route).concat(toGeoJsonWps(route, points || route.waypoints))
    };
}

export const toLineString = (route) => {
    const {routeNumber, routeName, routeDetails} = route
    return {
        type: "Feature",
        geometry: {
            type: "LineString",
            coordinates: toPath(route)
        },
        properties: {routeNumber, routeName, routeDetails, route}
    };
}
export const reverseGeocode = async ({lat, lon}) => {
    if (lat && lon) {
        return fetch(`https://api.geoapify.com/v1/geocode/reverse?type=amenity&lat=${lat}&lon=${lon}&apiKey=${config.maps.geoapify.ctrxs}`)
            .then(response => response.json())
            .then(data => {
                return data?.features?.[0]?.properties
            })
    }
}