import React from "react";
import {dayjsToSecsSinceMidnight, secsSinceMidnightToDayjs} from "../../model/timeFilter";
import TimeField from "react-simple-timefield";
import dayjs from "../../dayjs";


function ShiftBatTimePicker({time, setTime}) {

    return (
        // <TimePicker
        //     className="inputtext-small sb-time-edit"
        //     value={secsSinceMidnightToDayjs(time)}
        //     format={"H:mm"}
        //     changeOnBlur={true}
        //     placeholder={"H:mm"}
        //     onOpenChange={open => {
        //         if (open) {
        //             setTime(null)
        //         }
        //     }}
        //     onChange={val => {
        //         if (val === null) {
        //             return setTime(null);
        //         }
        //         setTime(dayjsToSecsSinceMidnight(val));
        //     }}
        // />
        <TimeField
            className="inputtext-small sb-time-edit"
            // style={{width: 56}}
            // onFocus={() => setEditing(true)}
            // onBlur={() => {
            //     setEditing(false)
            //     setUpdated(true)
            // }}
            value={Number.isFinite(time) && time > 0 ? secsSinceMidnightToDayjs(time).format('HH:mm') : ''}                     // {String}   required, format '00:00' or '00:00:00'
            onChange={(e, value) => {
                if (!value?.length) {
                    return setTime(null)
                }
                value = dayjs(value, 'HH:mm')
                if (!value.isValid()) {
                    return setTime(null)
                }
                setTime(dayjsToSecsSinceMidnight(value));
            }}
        />
    );
}

export default React.memo(ShiftBatTimePicker)
