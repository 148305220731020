import React, {useCallback, useState} from "react";
import {Col, Form, Row} from "react-bootstrap";
import {useAppContext} from "../libs/contextLib";
import LoaderButton from "../components/LoaderButton";
import {DEFAULT_EDITABLE_PROPS} from "./EditableList";
import {ReactComponent as Check} from "../assets/icons/Check.svg";
import {ReactComponent as Close} from "../assets/icons/Close.svg";

function EditItemView(props) {
    const {
        item,
        feature,
        modelData,
        form,
        handleItemUpdate,
        handleCancel,
        itemIdFn,
        createItemFn,
        isEditable,
        validateFn,
        children
    } = {...DEFAULT_EDITABLE_PROPS, ...props}

    const {isAdmin} = useAppContext()
    // const [item, setItem] = useState(editing);
    const [isUpdating, setIsUpdating] = useState([]);

    // useEffect(() => {
    //     if (editing) {
    //         console.log({...editing})
    //     }
    //
    // }, [editing]);

    const handleUpdate = useCallback((id) => {
        console.log('updating item', id)
        setIsUpdating(prevIsUpdating => prevIsUpdating.concat(id))
        modelData.save(item)
            .then(() => {
                setIsUpdating(prevIsUpdating => prevIsUpdating.filter(scheduleId => scheduleId !== id))
                console.log('Pushing new: ' + id)
                handleItemUpdate(createItemFn(item))
            }).catch(e => {
            console.log(e)
        });
    }, [setIsUpdating, handleItemUpdate, item, createItemFn, modelData]);

    return item ? (
        <Row className="align-items-start">
            <Col lg={11}>
                <Form>
                    {isAdmin &&
                        <Row><Col><Form.Group className="mb-3" controlId={itemIdFn(item) + '_ItemId'}>
                            <Form.Label>ID</Form.Label>
                            <Form.Control type="text" disabled value={itemIdFn(item)}/>
                        </Form.Group></Col></Row>}
                    {form || children}
                </Form>
            </Col>
            <Col lg={1} className="d-flex justify-content-center btn-edit-controls" style={{paddingTop: 36}}>
                <LoaderButton
                    variant="outline-success"
                    className="btn-icon-fill btn-close"
                    onClick={(e) => {
                        handleCancel(item)
                    }}
                    title={isEditable(item) ? 'Cancel' : 'Close'}
                >
                    <Close />
                </LoaderButton>
                {isEditable(item) && <LoaderButton
                    variant="outline-primary"
                    className="btn-icon-fill btn-save"
                    onClick={(e) => handleUpdate(itemIdFn(item))}
                    disabled={isUpdating.indexOf(itemIdFn(item)) > -1 || !validateFn(item)}
                    isLoading={isUpdating.indexOf(itemIdFn(item)) > -1}
                    feature={feature}
                    title="Save"
                >
                    <Check />
                </LoaderButton>}</Col>
        </Row>
    ) : <div>Loading...</div>;
}

export default React.memo(EditItemView);

