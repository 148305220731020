export class ChatContext {

    static fromContext(context) {
        return new ChatContext(context);
    }

    static toId(context) {
        if (!context) {
            return 'all';
        }
        return `${context.type}_${context.id}_${context.subType}_${context.subTypeId}`;
    }

    constructor(context) {
        this.type = context?.type;
        this.id = context?.id;
        this.subType = context?.subType;
        this.subTypeId = context?.subTypeId;
        this.stopId = context?.stopId;
        this.stop = context?.stop;
        this.tripId = context?.tripId;
        this.routeId = context?.routeId;
        this.route = context?.route;
        this.lat = context?.lat;
        this.lon = context?.lon;
        this.setFocusPt = context?.setFocusPt;
        this.setFocusStop = context?.setFocusStop;
        this.setSelectedRouteIds = context?.setSelectedRouteIds;
        this.showAllComments = context?.showAllComments || false;
    }

    clone(context) {
        return new ChatContext({...this, ...context});
    }

    reset() {
        return new ChatContext({
            type: 'all',
            setFocusPt: this.setFocusPt,
            setFocusStop: this.setFocusStop,
            setSelectedRouteIds: this.setSelectedRouteIds
        });
    }

    showUnread() {
        this.showAllComments = true;
        return this;
    }

    isEqual(context) {
        return this.type === context.type && this.id === context.id
            && this.subType === context.subType && this.subTypeId === context.subTypeId;
    }

    getId() {
        return ChatContext.toId(this);
    }
}

class Comment {
    constructor(comment) {
        this.id = comment?.id;
        this.type = comment?.type;
        this.subType = comment?.subType;
        this.subTypeId = comment?.subTypeId;
        this.message = comment?.message;
        this.readBy = comment?.readBy;
    }
}

export class StopComment extends Comment {
    constructor(comment) {
        super({...comment, type: 'stop'});
        this.stopId = comment?.stopId;
        this.stop = comment?.stop;
    }
}

export class RouteComment extends Comment {
    constructor(comment) {
        super({...comment, type: 'route'});
        this.routeId = comment?.routeId;
        this.route = comment?.route;
    }
}

export class WaypointComment extends RouteComment {
    constructor(comment) {
        super({...comment, subType: 'wp'});
        this.lat = comment?.lat;
        this.lon = comment?.lon;
    }
}

export class StopTimeComment extends RouteComment {
    constructor(comment) {
        super({...comment, subType: 'st'});
        this.tripId = comment?.tripId;
        this.stopTimeId = comment?.stopTimeId;
    }
}