import {Popup} from "react-map-gl";
import {Button, Col, Row} from "antd";
import {CloudUploadOutlined, FilterOutlined} from "@ant-design/icons";
import {CopyToClipboard} from "react-copy-to-clipboard";
import RouteTitle from "./RouteTitle";
import React from "react";
import {noop} from "lodash";

export default function MapPopup({popupData, setPopupData, setSelectedStop = noop, messageApi, filterToQuery, fullUrl, key, ...props}) {
    return popupData && (popupData.routes || popupData.center) ? <Popup
            className="StopPopup"
            offsetTop={popupData.center ? -20 : 0}
            longitude={popupData.lon || popupData.longitude || popupData.center[0]}
            latitude={popupData.lat || popupData.latitude || popupData.center[1]}
            onClose={() => {
                setPopupData(null);
                setSelectedStop(null);
            }}
            closeButton={true}
            closeOnClick={false}
            dynamicPosition={true}
            captureScroll={true}
            capturePointerMove={true}
            captureDrag={true}
            captureClick={true}
        >
            {
                props.tripPlanner && props.handleFromClicked && props.handleToClicked && popupData.stopName ?
                    <>
                        <div className='row'>
                            <div className='col-lg-12 StopPopupHeader'>
                                {popupData.stopName}
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-lg-12 text-center'>
                                    <Button size={'small'} type={'primary'}
                                            style={{width: '100px'}}
                                            onClick={() => props.handleFromClicked?.(popupData)}>From</Button>
                                    <Button size={'small'} type={"primary"} danger={true}
                                            style={{width: '100px'}}
                                            onClick={() => props.handleToClicked?.(popupData)}>To</Button>
                            </div>
                        </div>
                    </>
                    :
                    popupData.shiftBatBuilder && popupData.stopName ?
                        <div className='row'>
                            <div className='col-lg-12 StopPopupHeader'>
                                {popupData.stopName}
                            </div>
                        </div>
                        :
                        popupData.stopName ?
                            <>
                                <div className='row'>
                                    <div className='col-lg-12 StopPopupHeader'>
                                        {popupData.stopName}
                                    </div>

                                </div>
                                {!props.tripPlanner && !props.shiftBatBuilder &&
                                    <div className='row'>
                                        <div className='col-lg-6'>
                                            <span>Routes served:</span>
                                        </div>
                                        <div className='col-lg-6'
                                             style={{
                                                 textAlign: 'right',
                                                 marginTop: '-8px',
                                                 paddingRight: '20px'
                                             }}>
                                            {!props.tripPlanner && !props.shiftBatBuilder && popupData.routes && popupData.routes.length &&
                                                <>
                                                    <FilterOutlined style={{paddingRight: '10px'}}
                                                                    onClick={(e) => {
                                                                        e.preventDefault();
                                                                        e.stopPropagation();
                                                                        props.handleSelectedRoutes(popupData.routes);
                                                                        messageApi?.info(`Route filter set.`)
                                                                    }}/>

                                                    <CopyToClipboard
                                                        text={`${fullUrl}/timetables/${key}?${filterToQuery(null, popupData)}`}
                                                        onCopy={() => {
                                                            console.log('Copied.')
                                                            messageApi?.info(`Route filter copied to clipboard.`)
                                                        }}>
                                                        <CloudUploadOutlined/>
                                                    </CopyToClipboard>
                                                </>
                                            }
                                        </div>
                                    </div>}

                            </> : popupData.routes ?
                                <div className='row'>
                                    <div className='col-lg-6'>
                                        <span className='h6'>Routes</span>
                                    </div>
                                    <div className='col-lg-6'
                                         style={{
                                             textAlign: 'right',
                                             marginTop: '-8px',
                                             paddingRight: '20px'
                                         }}>
                                        {!props.tripPlanner && !props.shiftBatBuilder && popupData.routes && popupData.routes.length &&
                                            <>
                                                <FilterOutlined style={{paddingRight: '10px'}}
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    e.stopPropagation();
                                                                    props.handleSelectedRoutes(popupData.routes);
                                                                    messageApi?.info(`Route filter set.`)
                                                                }}/>

                                                <CopyToClipboard
                                                    text={`${fullUrl}/timetables/${key}?${filterToQuery(popupData.routes)}`}
                                                    onCopy={() => {
                                                        console.log('Copied.')
                                                        messageApi?.info(`Route filter copied to clipboard.`)
                                                    }}>
                                                    <CloudUploadOutlined/>
                                                </CopyToClipboard>
                                            </>
                                        }
                                    </div>
                                </div>
                                : popupData.type === 'Feature' ?
                                    <div className='row'>
                                        <div className='col-lg-12'>
                                            <span className='h6'>{popupData.place_name}</span>
                                        </div>
                                    </div>
                                    : popupData.center ?
                                        <div className='row'>
                                            <div className='col-lg-12'>
                                                <span className='h6'>Location</span>
                                            </div>
                                        </div>
                                        : <></>
            }
            {
                !props.tripPlanner && popupData.routes ?
                    <div className='row'>
                        <div className='col-lg-12'>
                            <div
                                style={{
                                    overflowX: "hidden",
                                    overflowY: "auto",
                                    minWidth: "250px",
                                    maxHeight: "400px"
                                }}>
                                {
                                    popupData.routes.sort((a, b) => a.routeNumber.localeCompare(b.routeNumber)).filter(r => r.published).map(route => (
                                        <div className='row' key={`RouteRow-${route.routeId}`}>
                                            {props.handleSelectedRoute ?
                                                <div className='col-lg-12 RouteListContainerItem'
                                                     onClick={(e) => {
                                                         e.preventDefault();
                                                         e.stopPropagation();
                                                         props.handleSelectedRoute(route);
                                                     }}>
                                                    <RouteTitle route={route} size={'sm'}
                                                                key={`Popup-${route.routeId}`}/>
                                                </div>
                                                : props.handleSelectedTrip ?
                                                    <Col className='col-lg-12'>
                                                        <RouteTitle route={route} size={'sm'}
                                                                    key={`Popup-${route.routeId}`}/>

                                                        <Row>
                                                            <Col style={{overflow: "hidden"}}>
                                                                {route?.services && route.services.map(trip => (
                                                                    <Row>
                                                                        <Col
                                                                            sm={10}>{trip.getStartTime()}</Col><Col><Button
                                                                        onClick={() => props.handleSelectedTrip(route, trip)}>New
                                                                        row</Button></Col>
                                                                    </Row>
                                                                ))}
                                                            </Col>
                                                        </Row>
                                                    </Col> : <></>}
                                        </div>
                                    ))}
                            </div>
                        </div>
                    </div>
                    : !props.tripPlanner && (popupData.transfersTo || popupData.transfersFrom) ?
                        <div className='row'>
                            <div className='col-lg-12'>
                                <h3>Transfers</h3>
                                <div>Latitude: {popupData.center[1]}</div>
                                <div>Longitude: {popupData.center[0]}</div>
                            </div>
                        </div>
                        : !props.tripPlanner ?
                            <div className='row'>
                                <div className='col-lg-12'>
                                    <div>Latitude: {popupData.center[1]}</div>
                                    <div>Longitude: {popupData.center[0]}</div>
                                </div>
                            </div>
                            : <></>
            }
        </Popup> :
        <></>
}