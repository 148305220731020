// Expects a buffer of some kind
import {find, flatten, snakeCase, uniq, values} from 'lodash';
import dayjs from '../dayjs';
import {getStartTimeAsSecondsSinceMidnight} from './formatLib';
import {getDistanceInMetres} from './routes-lib';
import AdmZip from 'adm-zip';
import {isEqualWith} from 'lodash/lang';
import {findIndex} from 'lodash/array';
import {dayjsToSecsSinceMidnight} from '../model/timeFilter';
import {ulid} from 'ulid';

const SCHOOL_STOP_SEARCH_ARRAY = ['school', 'college', 'grammar'];

const addOperatorDetailsToStop = (stop, operator) => {
    stop.userId = operator.operatorKey;
    stop.stopId = stop.stopId || snakeCase(operator.operatorId + '_' + stop.stopCode);
    stop.routes = [];
    stop.stopType = operator.gtfs?.options?.school && SCHOOL_STOP_SEARCH_ARRAY.some(search => stop.stopName.toLowerCase().indexOf(search) > -1) ? 'school' : stop.stopType;
    return stop;
};

// HACK: overrideId added to distinguish importing from and uploaded gtfs or from TfNSW import.
export const convertGtfsStopToBusableStop = (gtfsStop, {
    operator = null,
    overrideId = false,
    idx = -1,
    customConverters = {}
}) => {
    Object.keys(gtfsStop).forEach(key => {
        // Remove non-alphanumeric except underscore characters from key
        const newKey = key.toLowerCase().replace(/[^a-z_]/g, '');
        if (newKey !== key) {
            gtfsStop[newKey] = gtfsStop[key];
            delete gtfsStop[key];
        }
    });
    let stopCode = customConverters.stopCode?.(gtfsStop, idx) || (overrideId ? gtfsStop.stop_code?.trim() : gtfsStop.stop_id?.trim());
    if (!stopCode) {
        stopCode = gtfsStop[Object.keys(gtfsStop).find(key => key.toLowerCase().includes('code'))];
    }
    const stop = {
        stopId: customConverters.stopId?.(gtfsStop, idx) || (overrideId ? (gtfsStop.stop_id?.length ? gtfsStop.stop_id.trim() : ulid()) : null),
        stopCode,
        minorCode: customConverters.minorCode?.(gtfsStop, idx) || null,
        stopName: customConverters.stopName?.(gtfsStop, idx) || gtfsStop.stop_name?.trim() || gtfsStop.name?.trim() || gtfsStop[Object.keys(gtfsStop).find(key => key.toLowerCase().includes('name'))] || undefined,
        imported: 1,
        lat: customConverters.lat?.(gtfsStop, idx) || parseFloat(gtfsStop.stop_lat || gtfsStop.lat || gtfsStop.latitude || 0),
        lon: customConverters.lon?.(gtfsStop, idx) || parseFloat(gtfsStop.stop_lon || gtfsStop.lon || gtfsStop.longitude || gtfsStop.long || gtfsStop.lng || 0),
        stopType: customConverters.stopType?.(gtfsStop, idx) || 'bus',
        verified: customConverters.verified?.(gtfsStop, idx) || stopCode?.length > 0 ? 1 : 0,
        duplicate: customConverters.duplicate?.(gtfsStop, idx) || -1
    };
    if (operator) {
        addOperatorDetailsToStop(stop, operator);
    }
    return stop;
};

/**
 * Converts [dayjs1, dayjs2] to [[dateStr1, dateStr2]]
 * @param dates [dayjs]
 * @returns {[[string]]}
 */
export const datesToPeriods = (dates) => {

    const periods = [];
    if (dates.length) {
        let start = dates[0], prevDate = dates[0];
        dates.forEach((date, idx) => {
            if (idx === 0) {
                return;
            }

            if (date.diff(prevDate, 'd') > 1) {
                periods.push([start.format('DD/MM/YYYY'), prevDate.format('DD/MM/YYYY')]);
                start = date;
            }
            prevDate = date;
        });
        periods.push([start.format('DD/MM/YYYY'), prevDate.format('DD/MM/YYYY')]);
    }
    return periods;
};


export const getDataType = (buffer) => {

    const zip = new AdmZip(buffer);
    const zipEntries = zip.getEntries();
    if (zipEntries.length === 0) {
        return;
    }

    if (zipEntries.every(entry => entry.entryName.match(/\.zip$/i) || entry.entryName.match(/\.xml$/i))) {
        return 'todis';
    } else if (zipEntries.every(entry => entry.entryName.match(/\.txt$/i))) {
        return 'gtfs';
    }
};

export const uzipGtfsData = (buffer, parse) => {

    const zip = new AdmZip(buffer);
    const zipEntries = zip.getEntries();

    let routes, trips, stopTimes, stops, calendars, calendarDates, shapes, agencies;
    const csvOptions = {
        columns: true,
        skip_empty_lines: true
    };
    console.log('Unpacking Zip...');
    zipEntries.forEach((entry) => {
        console.log('Unpacking ', entry.entryName);

        try {
            if (entry.entryName.match(/^routes/i)) {
                routes = parse(zip.readAsText(entry), csvOptions);
            } else if (entry.entryName.match(/^trips/i)) {
                trips = parse(zip.readAsText(entry), csvOptions);
                // console.log(zip.readAsText(entry));
            } else if (entry.entryName.match(/^stop_times/i)) {
                // console.log(zip.readAsText(entry));
                stopTimes = parse(zip.readAsText(entry), csvOptions);
            } else if (entry.entryName.match(/^stops/i)) {
                stops = parse(zip.readAsText(entry), csvOptions);
            } else if (entry.entryName.match(/^calendar_dates/i)) {
                // console.log(zip.readAsText(entry));
                calendarDates = parse(zip.readAsText(entry), csvOptions);
            } else if (entry.entryName.match(/^calendar/i)) {
                // console.log(zip.readAsText(entry));
                calendars = parse(zip.readAsText(entry), csvOptions);
            } else if (entry.entryName.match(/^shapes/i)) {
                // console.log(zip.readAsText(entry));
                shapes = parse(zip.readAsText(entry), csvOptions);
            } else if (entry.entryName.match(/^agency/i)) {
                // console.log(zip.readAsText(entry));
                agencies = parse(zip.readAsText(entry), csvOptions);
                agencies.forEach(agency => agency.agency_id = agency.agency_id || snakeCase(agency.agency_name));
            }
        } catch (e) {
            console.log(e, e);
        }
    });
    console.log('Zip unpacked successfully.');
    console.log('%d route, %d trips, %d stopTimes, %d stops, %d calDates, %d calendars, %d shapes, %d agencies',
        routes?.length, trips?.length, stopTimes?.length, stops?.length, calendarDates?.length,
        calendars?.length, shapes?.length, agencies?.length);

    return {routes, trips, stopTimes, stops, calendarDates, calendars, shapes, agencies};
};


export const getGtfsDataForOperator = (operator, data, agencyIds, exts = []) => {
    if (data.exts) {
        exts = exts.concat(data.exts);
    }
    agencyIds = agencyIds || operator.gtfs?.agencyIds;
    if (!agencyIds && exts['all-agencies']) {
        agencyIds = data.agencies.map(a => a.agency_id);
    }
    let {
        routes = [],
        trips = [],
        stopTimes = [],
        stops = [],
        calendarDates = [],
        calendars = [],
        shapes = [],
    } = data;
    let routesByAgencyId = {};

    console.log('Filtering contents for ', operator.operatorId);
    console.log(data);
    console.log('AgencyIds: ', agencyIds);
    console.log('Exts: ', exts);
    if (agencyIds?.length) {
        let routeIds = [];
        agencyIds.forEach(agencyId => {
            routesByAgencyId[agencyId] = routes.filter(route => !route.agency_id || route.agency_id === agencyId);
            routeIds = routeIds.concat(routesByAgencyId[agencyId].map(route => route.route_id));
        });
        console.log('%d routes', routeIds.length);

        trips = trips.filter(trip => routeIds.indexOf(trip.route_id) > -1);
        const tripIds = trips.map(trip => trip.trip_id);
        console.log('%d trips', tripIds.length);
        stopTimes = stopTimes.filter(st => tripIds.indexOf(st.trip_id) > -1);
        const stopTimeIds = uniq(stopTimes.map(st => st.stop_id));
        console.log('%d stoptimes', stopTimeIds.length);
        stops = stops.filter(stop => stopTimeIds.indexOf(stop.stop_id) > -1);
        console.log('%d stops', stops.length);
        const serviceIds = trips.map(trip => trip.service_id);
        calendars = calendars.filter(cal => serviceIds.indexOf(cal.service_id) > -1);
        console.log('%d calendars', calendars.length);
        calendarDates = calendarDates.filter(calD => serviceIds.indexOf(calD.service_id) > -1);
    } else {
        uniq(routes.map(route => route.agency_id)).forEach(agencyId => {
            routesByAgencyId[agencyId] = routes.filter(route => route.agency_id === agencyId);
        });
    }

    const shapesById = {};
    shapes.forEach(shape => {
        shapesById[shape.shape_id] = shapesById[shape.shape_id] || [];
        shapesById[shape.shape_id].push(shape);
    });
    Object.keys(shapesById).forEach(shapeId => {
        shapesById[shapeId].sort((s1, s2) => s1.shape_pt_sequence - s2.shape_pt_sequence);
    });
    console.log('Filtered successfully.');

    console.log('Building model...');
    const ascDateSort = (d1, d2) => d1.unix() - d2.unix();
    const schedulesById = {};
    calendars.forEach(calendar => {
        const calDates = calendarDates.filter(cd => cd.service_id === calendar.service_id);
        const excludingDates = calDates.filter(cd => parseInt(cd.exception_type) === 2).map(cd => dayjs(cd.date, 'YYYYMMDD')).sort(ascDateSort);
        const includingDates = calDates.filter(cd => parseInt(cd.exception_type) === 1).map(cd => dayjs(cd.date, 'YYYYMMDD')).sort(ascDateSort);

        const includedPeriods = datesToPeriods(includingDates).map(p => {
            p.push('forceInclude');
            return p;
        });
        const excludedPeriods = datesToPeriods(excludingDates);

        const schedule = {
            schedulePeriods: [[
                dayjs(calendar.start_date, 'YYYYMMDD').format('DD/MM/YYYY'),
                dayjs(calendar.end_date, 'YYYYMMDD').format('DD/MM/YYYY')
            ]].concat(includedPeriods),

            excludedPeriods,
            operatingDays: [
                parseInt(calendar.monday) === 1,
                parseInt(calendar.tuesday) === 1,
                parseInt(calendar.wednesday) === 1,
                parseInt(calendar.thursday) === 1,
                parseInt(calendar.friday) === 1,
                parseInt(calendar.saturday) === 1,
                parseInt(calendar.sunday) === 1
            ],
            scheduleId: snakeCase(operator.operatorId + ' ' + calendar.service_id),
            scheduleName: snakeCase(operator.operatorId + ' ' + calendar.service_id),
            userId: operator.operatorKey
        };
        schedulesById[calendar.service_id] = schedule;
    });

    console.log('%d Schedules found for %s', Object.keys(schedulesById).length, operator.operatorId);

    const getTripId = ({route_id, trip_id, route_direction}, stopTimes) => {
        // if (exts.includes('tfnsw') && stopTimes?.length) {
        //     return snakeCase(operator.operatorId + '_' + route_id + '_' + route_direction + "_" + stopTimes[0].arriveSecs)
        // }
        return snakeCase(operator.operatorId + '_' + trip_id);
    };

    const stopsById = {};
    stops.forEach(stop => {
        stopsById[stop.stop_id] = convertGtfsStopToBusableStop(stop, {operator, override: exts.includes('busable')});
    });
    console.log('%d Stops found for %s', Object.keys(stopsById).length, operator.operatorId);

    const stopTimesById = {};
    try {
        stopTimes.filter(st => stopsById[st.stop_id]).forEach(st => {
            // console.log("Adding st for ", st, stopsById[st.stop_id]);
            const arrivalTime = dayjs(st.arrival_time, 'HH:mm:ss');
            const departureTime = dayjs(st.departure_time, 'HH:mm:ss');
            stopTimesById[st.trip_id] = stopTimesById[st.trip_id] || [];
            stopTimesById[st.trip_id].push({
                stopId: stopsById[st.stop_id].stopId,
                arriveSecs: dayjsToSecsSinceMidnight(arrivalTime),
                departSecs: dayjsToSecsSinceMidnight(departureTime),
                timingPoint: parseInt(st.timepoint) === 1,
                sequence: parseInt(st.stop_sequence)
            });
        });

        Object.keys(stopTimesById).forEach(tripId => {
            stopTimesById[tripId].sort((st1, st2) => st1.sequence - st2.sequence)
                .forEach((st, idx) => {
                    delete st.sequence;
                    const firstSt = stopTimesById[tripId][0];
                    const tripStartAsSecsSinceMidnight = getStartTimeAsSecondsSinceMidnight(firstSt);
                    if (idx > 0) {
                        st.delta = getStartTimeAsSecondsSinceMidnight(stopTimesById[tripId][idx]) - tripStartAsSecsSinceMidnight;
                    }
                });
        });

        Object.keys(stopTimesById).forEach(tripId => {
            stopTimesById[tripId].forEach((st, idx) => {
                st.stopTimeId = `${getTripId(find(trips, ['trip_id', tripId]), stopTimesById[tripId])}_ST_${idx}`;
            });
        });
        console.log('%d StopTimes found for %s', Object.keys(stopTimesById).length, operator.operatorId);

    } catch (e) {
        console.log('Error reading stop_times', e);
    }

    const tripsByShapeId = {};

    try {
        trips.forEach(trip => {
            tripsByShapeId[trip.shape_id] = tripsByShapeId[trip.shape_id] || [];
            tripsByShapeId[trip.shape_id].push(trip);
        });
        console.log('%d Trips found for %s', Object.keys(tripsByShapeId).length, operator.operatorId);
    } catch (e) {
        console.log('Error reading trips', e);
    }

    const routesById = {};
    try {
        Object.keys(routesByAgencyId).forEach(agencyId => {
            routesByAgencyId[agencyId].forEach(route => {
                routesById[route.route_id] = {
                    userId: operator.operatorKey,
                    contractId: agencyId,
                    author: 'GTFS Importer',
                    published: 1,
                    routeNumber: route.route_short_name,
                    // routeName: route.route_long_name,
                    // routeDetails: route.route_desc,
                    routeType: parseInt(route.route_type) === 712 ? 'School' : 'Regular',
                    colour: !route.route_color ? '#000000' : route.route_color.startsWith('#') ? route.route_color : '#' + route.route_color,
                };
            });
        });
        console.log('%d Routes found for %s', Object.keys(routesById).length, operator.operatorId);

    } catch (e) {
        console.log('Error reading trips', e);
    }
    const routeVariantsByShapeId = {};

    Object.keys(tripsByShapeId).forEach(shapeId => {
        routeVariantsByShapeId[shapeId] = [];
        tripsByShapeId[shapeId].forEach(trip => {
            let route, idx = 0;
            if (!routeVariantsByShapeId[shapeId].length) {
                route = {...routesById[trip.route_id]};
                routeVariantsByShapeId[shapeId].push(route);
            } else {
                idx = findIndex(routeVariantsByShapeId[shapeId], route => isEqualWith(route.stopTimes, stopTimesById[trip.trip_id],
                    (st1, st2) => st1?.length === st2?.length && st1?.every((st, idx) => st.stopId === st2[idx].stopId)));
                if (idx < 0) {
                    route = {...routesById[trip.route_id]};
                    routeVariantsByShapeId[shapeId].push(route);
                } else {
                    route = routeVariantsByShapeId[shapeId][idx];
                }
            }
            const schedule = schedulesById[trip.service_id];
            route.stopTimes = route.stopTimes || stopTimesById[trip.trip_id];
            // route.startTime = getStartTimeAsSecondsSinceMidnight(route.stopTimes[0]);
            route.services = route.services || [];
            let _trip = find(route.services, trip => trip.tripId === getTripId(trip, stopTimesById[trip.trip_id]));
            if (!_trip) {
                _trip = {
                    tripId: getTripId(trip, stopTimesById[trip.trip_id]),
                    scheduleIds: [],
                    stopTimes: stopTimesById[trip.trip_id],
                    tripName: trip.route_direction
                };
                route.services.push(_trip);
            }
            _trip.scheduleIds.push(schedule.scheduleId);

            route.routeId = route.routeId || snakeCase(operator.operatorId + '_' + trip.shape_id + '_' + idx);
            route.waypoints = route.waypoints || shapesById[trip.shape_id].map(shape => ({
                lat: parseFloat(shape.shape_pt_lat),
                lon: parseFloat(shape.shape_pt_lon),
                distance: parseInt(shape.shape_dist_traveled)
            }));
            route.routeName = (exts.includes('tfnsw') && trip.route_direction?.length) ? trip.route_direction : trip.trip_short_name;
            route.routeDetails = trip.trip_headsign || route.routeDetails;
            route.routeHeadsign = trip.trip_headsign;
            route.waypoints.forEach((wp, idx, array) => {
                if (!Number.isFinite(wp.distance)) {
                    if (idx === 0) {
                        wp.distance = 0;
                    } else {
                        const prevWp = array[idx - 1];
                        const dist = getDistanceInMetres(wp, prevWp);
                        wp.distance = prevWp.distance + dist;
                    }
                }
            });
            route.waypoints = route.waypoints.filter(wp => {
                if (!Number.isFinite(wp.lat) || !Number.isFinite(wp.lon) || !Number.isFinite(wp.distance)) {
                    console.log('WAYPOINT has dodge data', wp);
                    return false;
                }
                return true;
            });
            const directionId = parseInt(trip.direction_id);
            route.direction = route.direction || (directionId === 0 ? (route.routeType === 'School' ? 'PM' : 'Outbound') : directionId === 1 ? (route.routeType === 'School' ? 'AM' : 'Inbound') : 'Loop');
        });
    });

    Object.keys(routeVariantsByShapeId).forEach(shapeId => {
        const rvs = routeVariantsByShapeId[shapeId];
        rvs.forEach(rv => {
            if (rv.services.some(t => t.stopTimes?.length !== rv.stopTimes?.length)) {
                console.log('!! TRIPS OUT OF WHACK!! ');
            }
        });
    });
    console.log('%d RouteVariants found for %s', Object.keys(routeVariantsByShapeId).length, operator.operatorId);

    const rvs = flatten(values(routeVariantsByShapeId));
    console.log('Schedules: ', Object.keys(schedulesById).length, 'Stops: ', Object.keys(stopsById).length, 'Routes: ', Object.keys(routeVariantsByShapeId).length);
    console.log('Unique Routes Ids', uniq(rvs).map(r => r.routeId).length);
    console.log('Routes', rvs.map(r => r.routeNumber));

    return {schedules: values(schedulesById), routes: rvs, stops: values(stopsById)};
};

