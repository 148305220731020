import {useEffect, useState} from 'react';
import { charterModelData } from '../../services/ModelService';

export default function useAllCharters() {
    const [allCharters, setAllCharters] = useState(null);

    useEffect(() => {
        const listener = charterModelData.addListener({
            setterFn: charters => setAllCharters(charters),
            loaded: charters => setAllCharters(charters)
        })

        return () => {
            if(listener) {
                charterModelData.removeListener(listener);
            }
        }
    }, [])

    return {
        allCharters
    };
}