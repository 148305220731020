import {Select} from "antd";
import React, {useEffect, useState} from "react";
import {employeeModelData} from "../../services/ModelService";
import {pickBy, values} from "lodash";

function EmployeeSelect({onSelect, employeeFilter = () => true, ...props}) {
    const [employees, setEmployees] = useState({})
    const [employeeOpts, setEmployeeOpts] = useState([])
    const [employeeId, setEmployeeId] = useState(props.value)

    const updateOpts = (employees, setEmployeeOpts, employeeFilter) => {
        const validEmployees = values(pickBy(employees, e => e.firstName && e.lastName && !e.isDeleted))
            .filter(employeeFilter);
        setEmployeeOpts(validEmployees
            .map(e => ({
                value: e.employeeID,
                label: `${e.firstName} ${e.lastName}`
            })))
    }
    useEffect(() => {
        let driverListener = employeeModelData.addListener({
            loaded: emps => setEmployees(emps),
            setterFn: setEmployees
        })

        return () => {
            employeeModelData.removeListener(driverListener)
        }
    }, [setEmployees])

    useEffect(() => {
        updateOpts(employees, setEmployeeOpts, employeeFilter);
    }, [employees]);

    return <Select value={employeeId}
                   onChange={employeeId => {
                       setEmployeeId(employeeId);
                       onSelect(employeeId, employees[employeeId]);
                   }}
                   options={employeeOpts} {...props}/>
}

export default React.memo(EmployeeSelect);