import { cloneDeep } from "lodash";

export class Qualification {
    constructor(data) {
        this.id = null
        this.name = null;
        this.completedDate = null;
        this.certificate = null;

        Object.assign(this, data);
    }

    clone(qualifications) {
        return new Qualification(cloneDeep(qualifications));
    }
}