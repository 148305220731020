import React, {memo, useState} from 'react';
import {Allocation, AllocationBtnModal, AllocationModal, AllocationModalDiv} from './AllocationModal';
import {DeleteOutlined, VerticalAlignMiddleOutlined, EyeOutlined} from '@ant-design/icons';
import {Button, InputNumber, Popover, TimePicker, Tooltip, Typography} from 'antd';
import {employeeName} from './SelectEmployeeView';
import ShareModal from '../../components/ShareModal';
import ShiftBatPrintModal from '../ShiftBat/ShiftBatPrintModal';
import config from '../../config';
import {dayjsToSecsSinceMidnight, secsSinceMidnightToDayjs} from '../../model/timeFilter';
import {ReactComponent as Edit} from "../../assets/icons/Edit.svg";
import { ReactComponent as Print } from "../../assets/icons/Print.svg";
import { ReactComponent as Warning } from "../../assets/icons/Warning.svg";
import {ReactComponent as Check} from '../../assets/icons/Check.svg';

const Paragraph = Typography.Paragraph;


const WorkItemCard = ({
                          workItem,
                          unallocated,
                          selected,
                          statusColour,
                          employeeData,
                          allVehicles,
                          showMenu,
                          onSelectWorkItem,
                          saveRoster,
                          roster
                      }) => {
    return <div key={`work-item-div-${workItem.workItemId}`}
                className={`roster-item ${unallocated ? 'item-unallocated' : ''} ${selected ? 'item-selected' : ''} ${workItem?.optional ? 'item-optional' : ''} ${workItem.compliance?.length ? 'item-compliance' : ''}`}>
        <div className="roster-item-wrap" onClick={e => {
            e.stopPropagation();
            e.preventDefault();
            if (workItem.optional) {
                onSelectWorkItem(workItem);
            } else {
                // Going to close
                // if (showMenu) {
                // setShowContextMenu(showMenu => {
                //     return {...showMenu, [workItem.workItemId]: false};
                // });
                onSelectWorkItem(null);
                // }
            }
        }}>
            {!unallocated ?
                <Allocation employeeId={employeeData.getEmployee(workItem.employeeId)?.employeeID}
                            employeeName={employeeName(employeeData.getEmployee(workItem.employeeId))}
                            vehicleName={allVehicles[workItem.vehicleId]?.vehicleName}
                            statusColour={statusColour}/>
                // <Allocation {...workItem}
                //             statusColour={statusColour}/>
                : <></>}
            <div>
                <div className="roster-name mb-1">{workItem.workItemName}</div>
                <div className="roster-time">{workItem.startTimeFormatted}-{workItem.endTimeFormatted}</div>
            </div>
            <span className="icon-success"><Check/></span>
        </div>
        {/* <div className={`roster-card-menu`}> */}
            {/*{!unallocated ? <Tooltip title={'Show the menu for this work item.'}><Button*/}
            {/*    className={`icon-button btn-filled ${showContextMenu ? 'active' : ''}`}*/}
            {/*    icon={<VerticalAlignTopOutlined/>}*/}
            {/*    onClick={e => {*/}
            {/*        e.stopPropagation();*/}
            {/*        e.preventDefault();*/}
            {/*        setShowContextMenu(showMenu => {*/}
            {/*            return {...showMenu, [workItem.workItemId]: !showMenu[workItem.workItemId]};*/}
            {/*        });*/}
            {/*    }}/></Tooltip> : null}*/}
            {/* <Tooltip title={`Allocate work item to a roster.`}>
                <Button className={`icon-button btn-filled`}
                        onClick={e => {
                            e.stopPropagation();
                            e.preventDefault();
                            onSelectWorkItem(workItem);
                        }} icon={<VerticalAlignMiddleOutlined/>}/></Tooltip>
            {!unallocated ?
                <Tooltip
                    title={`Remove work item from ${roster.rosterName} and return to Unallocated.`}><Button
                    className={'icon-button btn-filled btn-error btn-delete'} icon={<DeleteOutlined/>}
                    onClick={() => {
                        roster.removeWorkItem(workItem);
                        saveRoster(roster);
                    }}/></Tooltip> : null} */}
        {/* </div> */}
    </div>;
};


const WorkItemView = memo(({
                               scenario,
                               roster,
                               workItem,
                               selected,
                               unallocated,
                               onSelectWorkItem,
                               employeeData,
                               allStops,
                               allRoutes,
                               schedules,
                               allVehicles,
                               saveRoster,
                               statusColour = '#C1C5CE',
                               operator
                           }) => {
    const [showMenu, setShowMenu] = useState(false);
    const [showContextMenu, setShowContextMenu] = useState({});
    const [printShiftBat, setPrinShiftBat] = useState(false);


    return (
        <>{workItem.optional ? <Tooltip title={`Allocate ${workItem.workItemName} to roster ${roster.rosterName}.`}>
                    <WorkItemCard onSelectWorkItem={onSelectWorkItem}
                                  unallocated={unallocated} selected={selected} statusColour={statusColour}
                                  employeeData={employeeData} allVehicles={allVehicles} showMenu={showMenu}
                                  saveRoster={saveRoster} roster={roster} workItem={workItem}/></Tooltip> :
                <div>
                    {/* <Popover trigger={'click'} title={workItem.workItemName}
                             content={<div>
                                 <a href={`${config.app.BASE_URL}/shiftbats/${workItem.duty?.shiftBatId}`}
                                    target={'_blank'}>View
                                     duty</a>
                                 <ShareModal shiftBat={workItem.duty}/>
                                 <ShiftBatPrintModal
                                     allStops={allStops}
                                     allRoutes={allRoutes}
                                     apiKey={operator?.operatorId}
                                     shiftBat={workItem.duty}
                                     visible={printShiftBat}
                                     schedules={schedules}
                                     setVisible={setPrinShiftBat}
                                 />
                                 <Button onClick={() => setPrinShiftBat(!printShiftBat)}>Print</Button>
                                 {!unallocated &&
                                     <div>
                                         <AllocationModal
                                             roster={roster}
                                             scenario={scenario}
                                             employeeId={workItem.employeeId}
                                             vehicleId={workItem.vehicleId}
                                             employeeData={employeeData}
                                             allVehicles={allVehicles}
                                             save={({employee, vehicle}) => {
                                                 workItem.setEmployee(employee);
                                                 workItem.setVehicle(vehicle);
                                                 saveRoster(roster);
                                             }}
                                         />
                                         <div>
                                             <label>Scheduled hours</label>
                                             <span>{workItem.scheduledHours}</span>
                                         </div>
                                         <div>
                                             <label>Actual start</label>
                                             <TimePicker value={secsSinceMidnightToDayjs(workItem.actualStart)}
                                                         needConfirm={false} showNow={false} format={'HH:mm'}
                                                         onChange={time => {
                                                             workItem.actualStart = dayjsToSecsSinceMidnight(time);
                                                             saveRoster(roster);
                                                         }}/>
                                         </div>
                                         <div>
                                             <label>Actual end</label>
                                             <TimePicker value={secsSinceMidnightToDayjs(workItem.actualEnd)}
                                                         needConfirm={false} showNow={false}
                                                         format={'HH:mm'} onChange={time => {
                                                 workItem.actualEnd = dayjsToSecsSinceMidnight(time);
                                                 saveRoster(roster);
                                             }}/>
                                         </div>
                                         <div>
                                             <label>Actual pay hours</label>
                                             <InputNumber value={workItem.actualPayHours} onChange={value => {
                                                 workItem.actualPayHours = value;
                                                 saveRoster(roster);
                                             }}/>
                                         </div>
                                     </div>}
                             </div>}> */}
                        <div key={`work-item-div-${workItem.workItemId}`}
                             className={`roster-item ${unallocated ? 'item-unallocated' : ''} ${selected ? 'item-selected' : ''} ${workItem?.optional ? 'item-optional' : ''} ${workItem.compliance?.length ? 'item-compliance' : ''}`}>
                            <div className="roster-item-wrap" onClick={e => {
                                e.stopPropagation();
                                e.preventDefault();
                                if (workItem.optional) {
                                    onSelectWorkItem(workItem);
                                } else {
                                    // Going to close
                                    // if (showMenu) {
                                    // setShowContextMenu(showMenu => {
                                    //     return {...showMenu, [workItem.workItemId]: false};
                                    // });
                                    onSelectWorkItem(workItem);
                                    // }
                                }
                            }}>
                                {!unallocated ?
                                    <Allocation employeeId={employeeData.getEmployee(workItem.employeeId)?.employeeID}
                                                employeeName={employeeName(employeeData.getEmployee(workItem.employeeId))}
                                                vehicleName={allVehicles[workItem.vehicleId]?.vehicleName}
                                                statusColour={statusColour}/>
                                    // <Allocation {...workItem}
                                    //             statusColour={statusColour}/>
                                    : <></>}
                                <div>
                                    <div className="roster-name mb-1">{workItem.workItemName}</div>
                                    <div className="roster-time">{workItem.startTimeFormatted}-{workItem.endTimeFormatted}</div>
                                </div>
                                {workItem.compliance?.length ? <Tooltip title={workItem.compliance?.length ? workItem.compliance.map(c => <div className='mb-2'>{c}</div>) : null}><span className="icon-warning"><Warning/></span></Tooltip> : null}
                            </div>
                            {selected ? <div className={`roster-card-menu`}>
                                {!unallocated ? <Tooltip title={`Edit ${workItem.workItemName}`} placement="right">
                                    <AllocationBtnModal
                                        workItem={workItem}
                                        roster={roster}
                                        scenario={scenario}
                                        employeeId={workItem.employeeId}
                                        vehicleId={workItem.vehicleId}
                                        employeeData={employeeData}
                                        allVehicles={allVehicles}
                                        allStops={allStops}
                                        allRoutes={allRoutes}
                                        schedules={schedules}
                                        operator={operator}
                                        save={({employee, vehicle}) => {
                                            workItem.setEmployee(employee);
                                            workItem.setVehicle(vehicle);
                                            saveRoster(roster);
                                        }}
                                        saveRoster={saveRoster}
                                    />
                                </Tooltip> : null}
                                <Tooltip title={`View duty`} placement="right"><a href={`${config.app.BASE_URL}/shiftbats/${workItem.duty?.shiftBatId}`} target={'_blank'}><EyeOutlined/></a></Tooltip>
                                <Tooltip title={`Share`} placement="right"><ShareModal shiftBat={workItem.duty}/></Tooltip>
                                <ShiftBatPrintModal
                                    allStops={allStops}
                                    allRoutes={allRoutes}
                                    apiKey={operator?.operatorId}
                                    shiftBat={workItem.duty}
                                    visible={printShiftBat}
                                    schedules={schedules}
                                    setVisible={setPrinShiftBat}
                                />
                                <Tooltip title={`Print`} placement="right"><Button onClick={() => setPrinShiftBat(!printShiftBat)}><Print style={{width: 16}}/></Button></Tooltip></div> : null}

                                {/*{!unallocated ? <Tooltip title={'Show the menu for this work item.'}><Button*/}
                                {/*    className={`icon-button btn-filled ${showContextMenu ? 'active' : ''}`}*/}
                                {/*    icon={<VerticalAlignTopOutlined/>}*/}
                                {/*    onClick={e => {*/}
                                {/*        e.stopPropagation();*/}
                                {/*        e.preventDefault();*/}
                                {/*        setShowContextMenu(showMenu => {*/}
                                {/*            return {...showMenu, [workItem.workItemId]: !showMenu[workItem.workItemId]};*/}
                                {/*        });*/}
                                {/*    }}/></Tooltip> : null}*/}
                                {/* <Tooltip title={`Allocate work item to a roster.`}>
                                    <Button className={`icon-button btn-filled`}
                                            onClick={e => {
                                                e.stopPropagation();
                                                e.preventDefault();
                                                onSelectWorkItem(workItem);
                                            }} icon={<VerticalAlignMiddleOutlined/>}/></Tooltip> */}
                                {/* {!unallocated ?
                                    <Tooltip
                                        title={`Remove work item from ${roster.rosterName} and return to Unallocated.`}><Button
                                        className={'icon-button btn-filled btn-error btn-delete'}
                                        icon={<DeleteOutlined/>}
                                        onClick={() => {
                                            roster.removeWorkItem(workItem);
                                            saveRoster(roster);
                                        }}/></Tooltip> : null} */}
                        </div>
                    {/* </Popover> */}
                </div>
        }</>
    );
});


export const WorkItemForDay = memo(({
                                        scenario,
                                        roster,
                                        unallocated,
                                        workItems,
                                        selectedWorkItem,
                                        onSelectWorkItem,
                                        statusColour,
                                        employeeData,
                                        allVehicles,
                                        saveRoster,
                                        allStops,
                                        allRoutes,
                                        schedules,
                                        operator,
                                    }) => {
    const rosterId = roster?.rosterId;
    return (
        <div key={'roster-jobs-' + rosterId}
             className={`${unallocated ? 'unallocated-' : ''}roster-cell`}>
            {workItems
                .sort((a, b) => (a.duty?.getStartTime() || 0) - (b.duty?.getStartTime() || 0))
                .map(workItem => {
                        return <WorkItemView key={rosterId + '-work-item-card-' + workItem.workItemId}
                                             scenario={scenario}
                                             roster={roster}
                                             workItem={workItem}
                                             selected={selectedWorkItem?.workItemId === workItem.workItemId}
                                             unallocated={unallocated}
                                             onSelectWorkItem={workItem => {
                                                 onSelectWorkItem(workItem, selectedWorkItem, roster);
                                             }}
                                             statusColour={statusColour}
                                             employeeData={employeeData}
                                             allVehicles={allVehicles}
                                             saveRoster={saveRoster}
                                             allStops={allStops}
                                             allRoutes={allRoutes}
                                             schedules={schedules}
                                             operator={operator}
                        />;
                    }
                )}
        </div>
    );
});