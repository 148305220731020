import {toHrsMinsSecs, toKmMs, toTime} from './formatLib';
import AdmZip from 'adm-zip';
import csvStringify from 'csv-stringify';
import {
    getArrivalTimeAsSecondsSinceMidnight,
    getDepartureTimeAsSecondsSinceMidnight,
    getDistanceInMetres
} from './routes-lib';
import {BusRoute} from '../model/busRoute';
import {Schedule} from '../model/schedule';
import {find, flatten, groupBy, isObject, keyBy, last, mapValues, pick, uniq, values} from 'lodash';
import config from '../config';
import {flattenDeep, uniqBy} from 'lodash/array';
import {API} from 'aws-amplify';
import {ShiftBat, ShiftBatRowType} from '../model/shiftBat';
import {findLast} from 'lodash/collection';

export const DUPLICATE_SOURCE_ID_TEXT = 'Duplicate source id';

function getTripId(route, trip) {
    if (trip) {
        return `${route.routeId}_${trip.getStartTime().replaceAll(':', '')}`;
    } else {
        return `${route.routeId}_${String(route.startHour).padStart(2, '0')}${String(route.startMin).padStart(2, '0')}`;
    }
}

export const getRoutesAsFlatArray = (routes) => {
    return routes.map(route => {
        route.stops = route.stops.map((stop, idx) => {
            return {
                'Route number': route.routeNumber,
                'Route name': route.routeName,
                'Sequence': (idx + 1),
                'Stop Id': stop.stopId,
                'Stop Name': stop.stopName,
                'Expected Arrival': idx > 0 ? toHrsMinsSecs(stop.delta) : '-',
                'Distance': idx > 0 ? toKmMs(stop.distance) : '-',
                'Link time': idx > 0 ? toHrsMinsSecs(stop.delta - route.stops[idx - 1].delta) : '-',
                'Longitude': stop.lon,
                'Latitude': stop.lat,
                'Author': route.author,
            };
        });
        return route;
    }).flat();
};


export const getUniqueStops = (routes) => {
    let stops = {};
    routes.forEach(route => {
        route.stops.forEach((stop, idx) => {
            if (!stops[stop.stopId]) {
                stops[stop.stopId] = stop;
            }
        });
    });
    return stops;
};

export const validateStop = (stopsById, stop, tfnsw) => {

    let warnings = [].concat((stop.outOfSync || []).map(m => `Source mismatch: ${m}`));
    if (stop.verified && !stop.stopCode?.length) {
        warnings.push('No source id');
    } else if (stop.verified && stop.stopCode?.length && Object.values(stopsById).some(s => !s.master &&
        s.stopId !== stop.stopId &&
        (s.stopCode?.toLowerCase() || '') === stop.stopCode.toLowerCase())) {
        warnings.push(DUPLICATE_SOURCE_ID_TEXT);
    }
    warnings = uniq(warnings);
    return warnings;
};

export const validateStopTime = (i, routeValidations) => {
    return routeValidations.stops[i]?.filter(v => !v.warning && !v.stop && v.msg);
};

export const validStopFilter = (stopsById, route, st, i, tfnsw, routeValidations) => {
    const existingStop = stopsById[st.stopId];
    if (!existingStop) return false;

    if (validateStop(stopsById, existingStop, tfnsw)?.length) return false;
    if (validateStopTime(i, routeValidations)?.length) return false;
    return true;
};

export const getStopTimes = (routes, schedules, stopsById, tfnsw, excludeInvalid) => {
    let stopTimes = [];
    routes.forEach(route => {
        const routeToCheck = new BusRoute(route);
        const routeValidations = routeToCheck.validate();
        route.services.forEach(trip => {
            try {
                const stopTimesForTrip = [];
                trip.stopTimes.forEach((stopTime, idx) => {
                    const baseStop = route.stops[idx];
                    const arrTime = toTime(getArrivalTimeAsSecondsSinceMidnight(trip, stopTime), true);
                    const depTime = toTime(getDepartureTimeAsSecondsSinceMidnight(trip, stopTime), true);
                    if (stopsById[stopTime.stopId] && baseStop &&
                        (!excludeInvalid || validStopFilter(stopsById, route, stopTime, idx, tfnsw, routeValidations))) {
                        if (Number.isFinite(baseStop.distance) && baseStop.distance >= 0) {
                            stopTimesForTrip.push({
                                trip_id: trip.tripId || getTripId(route, trip),
                                arrival_time: arrTime,
                                departure_time: depTime,
                                stop_id: stopTime.stopId,
                                stop_sequence: idx,
                                stop_headsign: stopTime.headsign?.length ? stopTime.headsign : '',
                                pickup_type: 0,
                                drop_off_type: 0,
                                shape_dist_traveled: baseStop.distance,
                                timepoint: stopTime.timingPoint ? 1 : 0,
                                stop_note: ''
                            });
                        }
                    }
                });
                stopTimesForTrip.forEach((st, idx) => {
                    st.stop_sequence = idx;
                });
                stopTimes = stopTimes.concat(stopTimesForTrip);
            } catch (e) {
                console.log('Couldn\'t add trip: ', e);
            }
        });
    });
    return stopTimes;
};

export const getCalendarForAgency = (routes, schedules, publishedShiftBats) => {
    let calendars = [];
    let exceptions = [];
    schedules = schedules.filter(schedule => {
        if (!schedule.getAllScheduledPeriods()?.length) return false;
        if (!schedule.operatingDays || !Object.keys(schedule.operatingDays).length) return false;
        return routes.some(route => {
            return route.services.some(trip => {
                return trip.scheduleIds.includes(schedule.scheduleId);
            });
        }) || publishedShiftBats?.some(sb => sb.scheduleIds?.includes(schedule.scheduleId));
    });
    schedules.forEach((schedule, idx) => {
        const cal = {
            service_id: schedule.scheduleId,
            start_date: schedule.getFirstActiveDate().format('YYYYMMDD'),
            end_date: schedule.getLastActiveDate().format('YYYYMMDD')
        };
        Object.keys(schedule.operatingDays).forEach(opDay => cal[opDay] = schedule.operatingDays[opDay] ? 1 : 0);
        calendars.push(cal);
        schedule.getAllScheduledPeriods().forEach((period, idx) => {
            if (idx === 0) {
                return;
            }
            period.forEachDate().forEach(date => {
                exceptions.push({
                    service_id: schedule.scheduleId,
                    date: date.format('YYYYMMDD'),
                    exception_type: 1
                });
            });
        });

        if (schedule.getAllExcludedPeriods().length) {
            schedule.getAllExcludedPeriods().forEach(period => {
                period.forEachDate().forEach(date => {
                    exceptions.push({
                        service_id: schedule.scheduleId,
                        date: date.format('YYYYMMDD'),
                        exception_type: 2
                    });
                });
            });
        }
    });
    // make exceptions unique based on service_id, date, exception_type
    exceptions = uniqBy(exceptions, e => `${e.service_id}_${e.date}_${e.exception_type}`);
    return {calendars, exceptions};
};

export const getTripsForRoute = (route) => {
    let trips = [];
    route.services.forEach(trip => {
        trip.scheduleIds.forEach(scheduleId => {
            trips.push({
                route_id: route.routeNumber.split('-')[0],
                service_id: scheduleId,
                trip_short_name: route.routeName,
                trip_id: trip.tripId || getTripId(route, trip),
                shape_id: route.routeId,
                trip_headsign: trip.headsign?.length ? trip.headsign : route.routeHeadsign || '',//`${route.routeName} ${route.routeDetails}`,
                direction_id: route.direction === 'Outbound' || route.direction === 'PM' ? 0 : 1,//loop = inbound, am = inbound
                wheelchair_accessible: trip.wheelchair ? 1 : 0,
                block_id: '',  // TODO Add block ID
            });
        });
    });
    return trips;
};

export const getShapes = (routes) => {
    let shapes = [];
    routes.forEach(route => {
        route.waypoints.forEach((wp, idx) => {
            shapes.push({
                shape_id: route.routeId,
                shape_pt_lat: wp.lat.toFixed(6),
                shape_pt_lon: wp.lon.toFixed(6),
                shape_pt_sequence: idx,
                shape_dist_traveled: wp.distance
            });
        });
    });
    return shapes;
};

export const toCsv = async (rows) => {
    return new Promise((resolve, reject) => {
        csvStringify(rows, {
            header: true,
            delimiter: ',',
            quoted: true,
            quoted_empty: true
        }, (err, output) => {
            if (err) {
                console.log(`Error CSVing rows.`, err);
                reject(err);
            }
            resolve(output);
        });
    });
};

export const downloadGtfs = async (operator) => {
    return await fetch(`${config.lambda.toGtfs.URL}/${operator.operatorId}`, {
        mode: 'cors',
        method: 'GET',
        cache: 'no-cache',
        headers: {
            'x-api-key': operator.operatorKey,
            'accept': 'application/zip'
        }
    });
};

export const publishTfnsw = async ({companyId, opts}) => {
    return await API.post('events', `/events`, {
        body: {
            source: 'busable.publish',
            detailType: 'PublishTfNSW',
            detail: {type: 'tfnsw', companyId, opts}
        }
    });
};

export const getStops = (stopsById, tfnsw) => {
    return Object.keys(stopsById).map(stopId => {
        let stop = stopsById[stopId];
        const gtfsStop = {
            stop_id: stop.stopId,
            stop_name: stop.stopName,
            stop_code: stop.stopCode,
            stop_lat: stop.lat.toFixed(6),
            stop_lon: stop.lon.toFixed(6),
            location_type: '',
            parent_station: '',
            wheelchair_boarding: stop.wheelchair ? 1 : '',
            platform_code: ''
        };
        if (tfnsw) {
            gtfsStop.suburb = stop.suburb || '';
            gtfsStop.postcode = stop.postcode || '';
        }
        if (stop.aliases) {
            Object.keys(stop.aliases).forEach(aId => {
                gtfsStop[aId] = stop.aliases[aId] || '';
            });
        }
        return gtfsStop;
    });
};
export const getRouteVariants = (routes) => {
    return routes.map(r => {
        let distance = r.waypoints?.reduce((prev, wp, idx) => {
            if (idx === 0) return prev;
            return prev + getDistanceInMetres(r.waypoints[idx - 1], wp);
        }, 0) || 0;
        const gtfsRv = {
            contract_id: r.contractId,
            route_id: r.routeNumber?.split('-')?.[0] || '---',
            route_name: r.routeLabel,
            route_variant_id: r.routeId,
            route_variant_number: r.routeNumber?.split('-')?.[1] || '1',
            route_variant_name: r.routeName,
            route_variant_desc: r.routeDetails,
            route_variant_status: r.published === 1 ? 'published' : r.published === 0 ? 'approved' : 'draft',
            route_variant_direction: r.direction,
            route_variant_type: r.routeType || 'Regular',
            route_variant_distance_km: parseFloat((distance / 1000).toFixed(3))
        };

        return gtfsRv;
    });
};

export const getDeadRuns = (deadruns = [], shiftBats) => {
    const deadRunRows = [];
    shiftBats.forEach(shiftBat => shiftBat.rows.forEach((row, idx, rows) => {
        if (idx === 0 || idx === rows.length - 1) {
            return;
        }
        if (row.type === ShiftBatRowType.dead) {
            const deadrun = deadruns[row.routeId];
            let type = 'missing';
            const prevTimeRow = findLast(rows, row => row.getEndTime() > 0, idx - 1);
            const nextTimeRow = find(rows, row => row.getTime() > prevTimeRow.getEndTime(), idx + 1);
            if (deadrun?.startStop?.stopType === 'depot') {
                type = 'pull-out';
            } else if (deadrun?.endStop?.stopType === 'depot') {
                type = 'pull-in';
            } else if (deadrun) {
                type = 'reposition';
            }
            deadRunRows.push({
                shiftId: shiftBat.shiftBatId,
                type,
                start: prevTimeRow?.getEndTime() || 0,
                end: nextTimeRow?.getTime() || ((prevTimeRow?.getEndTime() || 0) + (deadrun?.duration || 0)),
                shape_id: deadrun?.routeId,
                distance: deadrun?.waypoints?.length ? last(deadrun.waypoints).distance : ''
            });
        }
    }));
    return deadRunRows;
};


export const getWeeklyScenario = (scenario) => {
    const workItems = flattenDeep(scenario.weeklyRosters.map(r => r.workItems?.map(workItemsForDay => workItemsForDay.map(wi => wi.toCsv()))));
    return workItems;
};

export const getCsvRoutes = (routes, operator) => {
    return uniqBy(routes.map(r => ({
        ...r,
        routeNumber: r.routeNumber.split('-')[0]
    })), 'routeNumber').map(route => {
        const csvRoute = {
            route_id: route.routeNumber,
            agency_id: operator.accreditationId || operator.operatorId,
            route_short_name: route.routeNumber,
            route_desc: route.routeLabel?.length ? route.routeLabel : '',
            route_type: 3,
            route_color: route.colour?.slice(1) || '',
            route_text_color: 'FFFFFF',
            network_id: route.contractId || ''
        };
        return csvRoute;
    });
};


export const buildGtfsZip = async (operator, routes, schedules, stopsById, driverShifts, vehicleShifts, shiftBats, deadruns, {
    exts = {tfnsw: false},
    excludeInvalid = false
}) => {

    // DONE: agency.txt
    const {tfnsw} = exts;

    routes = routes.map(route => {
        const busRoute = new BusRoute(route);
        busRoute.setBaseStops(stopsById, busRoute.stopTimes, {excludeLinkedStops: true});
        return busRoute;
    });


    if (tfnsw) {
        routes = routes.filter(r => r.contractId?.length);
    }

    schedules = values(schedules).map(schedule => new Schedule(schedule)).map(schedule => {
        schedule.setSubSchedules(schedules);
        return schedule;
    });
    console.log(schedules);

    let agenciesCsv = await toCsv([{
        agency_id: operator.accreditationId || operator.operatorId,
        agency_name: operator.accreditationName || operator.operatorName,
        agency_url: operator.operatorUrl,
        agency_timezone: 'Australia/Sydney',
        agency_lang: 'EN',
        agency_phone: ''
    }]);

    const routesById = keyBy(routes, 'routeId');
    const publishedShiftBats = await Promise.all(shiftBats?.filter(sb => sb.published === 1).map(async sb => {
        sb = new ShiftBat(sb);
        await Promise.all(sb.rows.filter(row => row.type === ShiftBatRowType.service).map(async row => row.updateRow({
            allStops: stopsById,
            allRoutes: routesById
        })));
        return sb;
    }));

    console.log('Done stops CSV');
    // DONE: routes.txt

    let routesCsv = await toCsv(getCsvRoutes(routes, operator));
    console.log('Done routes CSV');

    // DONE: trips.txt

    let trips = [];
    const routeByTripId = {};
    routes.forEach(route => {
        const tripsForRoute = getTripsForRoute(route, schedules);
        tripsForRoute.forEach(t => {
            if (tfnsw) {
                t.route_variant_id = route.routeId;
            }
            routeByTripId[t.trip_id] = route;
        });
        trips = trips.concat(tripsForRoute);
    });
    console.log('Done trips CSV');

    let shiftsCsv, rvCsv, deadRuns;
    if (tfnsw) {
        const shifts = [];
        const tripsById = keyBy(trips, 'trip_id');
        // Add all shifts from shift bats
        publishedShiftBats
            .forEach(sb => {
                sb?.rows.filter(r => {
                    return r.type === 'Service' && r.tripId && r.route?.contractId?.length;
                })
                    .forEach(r => {
                        sb.scheduleIds
                            .forEach(sId =>
                                shifts.push({
                                    trip_id: r.tripId,
                                    driver_shift: sb.shiftBatNumber || '',
                                    vehicle_shift: sb.shiftBatNumber || '',
                                    service_id: sId,
                                    duration: sb.getShiftTime(),
                                    shift_id: sb.shiftBatId,
                                    distance: sb.getShiftDistance()
                                }));
                    });
            });
        const shiftsByTripId = keyBy(shifts, 'trip_id');
        // Go through the trips that aren't in shifts, and add their driverShifts
        trips.filter(trip => !shiftsByTripId[trip.trip_id]).forEach(trip => {
            const route = routeByTripId[trip.trip_id];
            if (!driverShifts[route?.driverShift]?.shiftName) {
                return;
            }
            shifts.push({
                trip_id: trip.trip_id,
                driver_shift: driverShifts[route?.driverShift]?.shiftName || '',
                vehicle_shift: vehicleShifts[route?.vehicleShift]?.shiftName || '',
                service_id: trip.service_id,
                shift_id: trip.shift_id,
            });
        });
        shiftsCsv = await toCsv(shifts);
        rvCsv = await toCsv(getRouteVariants(routes));
        if (deadruns) {
            deadRuns = getDeadRuns(deadruns, publishedShiftBats);
        }
    }

    console.log('Done shifts CSV');

    let tripsCsv = await toCsv(trips);

    // DONE: stop_times.txt
    const stopTimes = getStopTimes(routes, schedules, stopsById, tfnsw, excludeInvalid);
    let stopTimesCsv = await toCsv(stopTimes);
    console.log('Done stopTimes CSV');

    let {calendars, exceptions} = getCalendarForAgency(routes, schedules, publishedShiftBats);
// DONE: calendar.txt
    let calendarsCsv = await toCsv(calendars);
    console.log('Done Calendars CSV');
    // DONE: calendar_dates.txt
    let calendar_datesCsv = await toCsv(exceptions);
    // DONE: shapes.txt
    let shapes = routes;
    if (deadruns && deadRuns) {
        shapes = routes.concat(values(pick(deadruns, deadRuns.map(dr => dr.shape_id))));
    }
    let shapesCsv = await toCsv(getShapes(shapes));
    console.log('Done Shapes CSV');


    // DONE: stops.txt
    let stops = keyBy(values(stopsById).filter(stop => (!stop.duplicate || stop.duplicate < 1) && (!stop.stopType || stop.stopType === 'bus') && stopTimes.some(st => st.stop_id === stop.stopId)), 'stopId');
    let stopsCsv = await toCsv(getStops(stops, tfnsw));

    console.log('Preparing zip...');

    let zip = new AdmZip();

    zip.addFile('agency.txt', agenciesCsv);
    zip.addFile('stops.txt', stopsCsv);
    zip.addFile('routes.txt', routesCsv);
    zip.addFile('trips.txt', tripsCsv);
    if (shiftsCsv) {
        zip.addFile('shifts.txt', shiftsCsv);
    }
    if (deadRuns) {
        zip.addFile('dead_runs.txt', (await toCsv(deadRuns)));
    }
    if (rvCsv) {
        zip.addFile('route_variants.txt', rvCsv);
    }
    zip.addFile('stop_times.txt', stopTimesCsv);
    zip.addFile('calendar.txt', calendarsCsv);
    zip.addFile('calendar_dates.txt', calendar_datesCsv);
    zip.addFile('shapes.txt', shapesCsv);
    console.log('Prepared zip');
    return zip;

};

export const buildHRMtoZip = async (operator, employees) => {
    let zip = new AdmZip();
    console.log('Preparing zip...');
    const groupedEmployees = groupBy(employees, (employee) => employee.constructor.name);

    // Generate CSV files for each data class and add them to the zip
    for (const className in groupedEmployees) {
        const classEmployees = groupedEmployees[className];
        const flattenedEmployees = classEmployees.map(employee =>
            mapValues(employee, value =>
                isObject(value) ? values(value).join(' ') : value
            )
        );
        const csvData = await toCsv(flattenedEmployees);
        zip.addFile(`${className.toLowerCase()}.csv`, csvData);
    }
    return zip;
};
