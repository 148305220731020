import React from "react";
import {Modal} from "antd";
import ShiftBatPrintView from "./ShiftBatPrintView";

function ShiftBatPrintModal({
                                apiKey,
                                shiftBat,
                                allStops, allRoutes,
                                visible,
                                schedules,
                                setVisible
                            }) {
    return (
        <Modal
            width={1000}
            style={{ top: 50 }}
            open={visible}
            destroyOnClose
            okText={`Print`}
            onCancel={() => setVisible(null)}
            onOk={() => window.print()}
        >
            <ShiftBatPrintView
                allRoutes={allRoutes}
                allStops={allStops}
                operatorId={apiKey}
                shiftBat={shiftBat}
                schedules={schedules}/>
        </Modal>
    );
}

export default React.memo(ShiftBatPrintModal)
