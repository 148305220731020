import * as React from 'react';
import './RouteTitle.css'
import {hasLogo} from "../libs/routes-lib";
import {Image} from "react-bootstrap";
import config from "../config";
import {Link} from "react-router-dom";
import {noop} from "lodash";
import {Tooltip} from "antd";
import useComments from "../hooks/useComments";

function RouteNumber({
                         size,
                         route,
                         colour,
                         editRouteNumber,
                         children,
                         logo,
                         tooltip,
                         noLogo,
                         link,
                         meta,
                         forceNoCache,
                         alias,
                         onMouseOverFn = noop,
                         onMouseLeaveFn = noop,
                     }) {
    link = link && route.routeId?.length
    tooltip = tooltip || `${route?.routeNumber}: ${route?.routeName}` || null
    const routeNumber = alias?.length ? alias : route?.routeNumber || ""
    colour = colour || route?.colour || "#D9D9D9"
    size = size || '';

    const div = <div className="d-flex flex-row align-items-center justify-content-center route-number-wrap"
                     onMouseOver={e => {
                         e.stopPropagation()
                         onMouseOverFn(e)
                     }}
                     onMouseLeave={e => {
                         e.stopPropagation()
                         onMouseLeaveFn(e)
                     }}
    >

        {!noLogo &&
            <div className={`RouteLogo${size.length ? `-${size}` : ''}`} style={{borderColor: colour}}>
                {hasLogo(route) ?
                    <Image
                        src={`https://${config.s3.BUCKET}.s3.ap-southeast-2.amazonaws.com/public/${route.routeLogo}${forceNoCache ? '?d=Date.now()' : ''}`}/>
                    : <Image
                        src={'https://prod-rm-web-infra-gpx-s3-gpxfiles47af3947-1f2jzq8rsjwfq.s3.ap-southeast-2.amazonaws.com/public/Bus.svg'}
                        className="BusIcon"/>
                }
            </div>}
        {route && routeNumber &&
            <div className={`RouteNumber${size.length ? `-${size}` : ''}`} style={{backgroundColor: colour}}
                 title={`${route.routeNumber} ${route.routeName}`}
                 contentEditable={!!editRouteNumber}
                 onBlur={(e) => {
                     if (editRouteNumber && e.target.textContent !== route.routeNumber) {
                         editRouteNumber(e.target.textContent)
                     }
                 }}>{editRouteNumber ? route.routeNumber : routeNumber} {children}</div>
        }
    </div>

    return (
        link ?
            <Link to={"/services/" + route.routeId} onClick={e => {
                if (!e.metaKey && meta) {
                    e.preventDefault();
                }
            }}>
                <Tooltip overlay={tooltip}>
                    {div}
                </Tooltip>
            </Link>
            :
            <Tooltip overlay={tooltip}>
                {div}
            </Tooltip>
    );
}

export default React.memo(RouteNumber);
