import * as React from 'react';
import './RouteTitle.css'
import {Badge, ListGroup} from 'react-bootstrap';
import RouteNumber from "./RouteNumber";
import DisplayStop from "./DisplayStop";
import {Popover} from "antd";
import {useCallback, useEffect, useState} from "react";
import LoaderButton from "./LoaderButton";

function TransfersList({stop, handleSaveStop, placement = 'left', noLabel = false}) {

    const [transfers, setTransfers] = useState([]);
    const [isDeleting, setIsDeleting] = useState(false);

    useEffect(() => {
        let _transfers = [];
        stop.routes?.filter(r => r.transfers?.length).forEach(routeWithTransfers => {
            _transfers = _transfers.concat(routeWithTransfers.transfers.map(t => ({fromRoute: routeWithTransfers, ...t})));
        });
        setTransfers(_transfers);

    }, [stop, setTransfers])

    const handleDelete = useCallback(async (transfer, transferIdx) => {
        setIsDeleting(true);
        try {
            stop.routes[stop.routes.findIndex(r => r.routeId === transfer.fromRoute.routeId)].transfers.splice(transferIdx, 1);
            await handleSaveStop(stop);
        } catch (err) {
            console.log(err);
        }
        setIsDeleting(false);
    }, [setIsDeleting, handleSaveStop, stop]);

    const popover = (
        <div>
            <ListGroup>
                {transfers.map((t, transferIdx) => {
                    return (
                        <ListGroup.Item key={`transfers-list--${stop.stopId}`}>
                            <div><RouteNumber size={'sm'} route={t.fromRoute}/></div>
                            <div><DisplayStop stop={stop} noRoutes noWarning noInterchange/></div>
                            {t.toStopId !== stop.stopId &&
                                <div><DisplayStop stop={t.toStop} noRoutes noWarning noInterchange/></div>
                            }
                            <div><RouteNumber size={'sm'} route={t.toRoute}/></div>
                            <div>
                                <LoaderButton
                                    onClick={() => handleDelete(t, transferIdx)}
                                    size="sm"
                                    variant={'danger'}
                                    isLoading={isDeleting}
                                    style={{
                                        height: "28px",
                                        paddingTop: "0px",
                                    }}
                                >
                                    Delete
                                </LoaderButton></div>
                        </ListGroup.Item>
                    )
                })}

            </ListGroup>
        </div>
    );

    const TransfersOverlay = () => (
        <Popover content={popover}
                 trigger="click"
                 placement={placement}>
            <Badge pill variant="light" size='sm' className='badge-outline badge-light'>
                {noLabel ? transfers.length : `Transfers: ${transfers.length}`}
            </Badge>
        </Popover>
    );

    return (
        <TransfersOverlay/>
    );
}

export default React.memo(TransfersList);