import { cloneDeep } from "lodash";

export class Tax {
    constructor(data) {
        this.taxFileNumber = null;
        this.effectiveDate = null;
        this.taxFreeThreshold = null;

        this.taxRecords = []

        Object.assign(this, data);
    }

    clone(tax) {
        return new Tax(cloneDeep(tax));
    }
}