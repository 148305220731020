import store from 'store2';
import {toCoordStr} from "../libs/pathLib";
import {APP_VERSION} from "../App";

const VALID_WEEKS = 1
const SECS_IN_WEEKS = 86400 * 7
const VALID_MS = VALID_WEEKS * SECS_IN_WEEKS * 1000

class StopDistCache {
    constructor() {
        this.data = null; // {mode: {coord: {stopId: {dist, duration, time}}}}
    }

    getData(mode, coord, stopId) {
        if (!this.data) {
            this.read();
        }
        if (typeof coord === 'object') {
            coord = toCoordStr(coord);
        }
        if(!this.data[mode] || !this.data[mode][coord] || !this.data[mode][coord][stopId]) {
            return {dist: -1, duration: -1}
        }
        const data = this.data[mode][coord][stopId]
        if(!data.time || (Date.now() - data.time) > VALID_MS) {
            delete this.data[mode][coord][stopId]
            return {dist: -1, duration: -1}
        }
        return data;
    }

    setData(mode, coord, stopId, dist, duration) {

        if (!coord || !stopId || !mode) {
            return
        }

        if (typeof coord === 'object') {
            coord = toCoordStr(coord);
        }
        this.data[mode] = this.data[mode] || {};
        this.data[mode][coord] = this.data[mode][coord] || {};
        this.data[mode][coord][stopId] = {dist, duration, time: this.data[mode][coord][stopId]?.time || Date.now()};
    }

    write() {
        try {
            console.log('Storing', 'RMW.TripPlanner.StopDistCache')
            store.set('RMW.TripPlanner.StopDistCache', this.data)
        } catch (e) {
            console.log(`Couldn't store cache data. Error: ${e}`, e);
        }
    }

    read() {
        console.log(`Reading StopDistCache to localStorage...`);
        this.data = this.data || {};
        Object.assign(this.data, store.get('RMW.TripPlanner.StopDistCache'))
        if (!this.data.version || this.data.version !== APP_VERSION) {
            this.data = {version: APP_VERSION}
            store.remove('RMW.TripPlanner.StopDistCache')
        }
    }
}

const stopDistCache = new StopDistCache({});
export {stopDistCache}

