import store from 'store2';
import {toCoordStr} from "../libs/pathLib";
import dayjs from "../dayjs";

const VALID_MONTHS = 6
const SECS_IN_MONTH = 2628288

const forceReloadDate = dayjs('24-11-2022', 'DD-MM-YYYY')

class DirCache {
    constructor() {
        this.data = {}; // {profile: {coord: {coord: {waypoints, duration, dist}}}}
    }

    getData(profile, start, end, speed) {
        if (typeof start === 'object') {
            start = toCoordStr(start);
        }
        if (typeof end === 'object') {
            end = toCoordStr(end);
        }
        if (!this.data[profile] || !this.data[profile][start] || !this.data[profile][start][end] || !this.data[profile][start][end][speed]) {
            return undefined
        }
        const data = this.data[profile][start][end][speed];
        if(!data.time || (Date.now() - data.time) > VALID_MONTHS * SECS_IN_MONTH * 1000 || (Date.now() - data.time) < forceReloadDate.valueOf()) {
            delete this.data[profile][start][end][speed]
            return undefined
        }
        return data;
    }

    setData(profile, start, end, speed, dir) {
        if (!profile || !start || !end || !speed || !dir?.waypoints || !dir?.distance || !dir?.duration) {
            return
        }
        if (typeof start === 'object') {
            start = toCoordStr(start);
        }
        if (typeof end === 'object') {
            end = toCoordStr(end);
        }
        this.data[profile] = this.data[profile] || {};
        this.data[profile][start] = this.data[profile][start] || {};
        this.data[profile][start][end] = this.data[profile][start][end] || {};
        this.data[profile][start][end][speed] = this.data[profile][start][end][speed] || {};
        this.data[profile][start][end][speed] = dir;
        this.data[profile][start][end][speed].time = this.data[profile][start][end][speed].time || Date.now()
        this.write();
    }

    write() {
        try {
            console.log('Storing', 'RMW.TripPlanner.DirCache.')
            store.set('RMW.TripPlanner.DirCache', this.data)
        } catch (e) {
            console.log(`Couldn't store cache data. Error: ${e}`, e);
        }
    }

    read() {
        console.log(`Reading DirCache to localStorage...`);
        this.data = this.data || {};
        Object.assign(this.data, store.get('RMW.TripPlanner.DirCache'))
    }
}

const dirCache = new DirCache({});
dirCache.read();
export {dirCache}
