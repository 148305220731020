import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {FEATURE} from '../../model/features';
import {Col} from 'react-bootstrap';
import EditableList from '../EditableList';
import {Job, JobScenario} from '../../model/job';
import {jobModelData} from '../../services/ModelService';
import {DATE_STRING} from '../../model/schedule';
import EditJobView from './EditJobView';
import dayjs from '../../dayjs';
import useAllShiftBats from '../../hooks/useAllShiftBats';
import useAllVehicles from '../../hooks/useAllVehicle';
import LoadMessage from '../../components/LoadMessage';
import useAllCharters from '../../features/Charters/useAllCharters';
import {Button, List, Tooltip} from 'antd';
import {AiFillFastBackward, AiFillFastForward} from 'react-icons/all';
import useJobsForDate from '../../hooks/useJobsForDate';
import useUnallocated from '../../hooks/useUnallocated';
import useAllEmployees from '../../hooks/useAllEmployees';
import {find, noop} from 'lodash';

const filterItems = {
    Active: {showFn: (s) => s.allocation && s.job},
};

const headerCols = (<>
    <Col lg={2}>Date</Col>
    <Col lg={2}>Start</Col>
    <Col lg={2}>End</Col>
    <Col lg={1}>Job</Col>
    <Col lg={1}>Allocation</Col>
</>);

function Jobs() {

    const [date, setDate] = useState(dayjs());
    const {allJobs, vehicleJobs, employeeJobs, setAllJobs} = useJobsForDate({date});
    const {unallocatedShiftBats, unallocatedCharters} = useUnallocated({date});
    const [selectedShiftBat, setSelectedShiftBat] = useState(null);
    const {allShiftBats, allShiftBatsArray} = useAllShiftBats();
    const filter = useMemo(() => {
        return {filter: {duty: selectedShiftBat, date}};
    }, [selectedShiftBat, date]);
    const {allVehicles, allVehiclesArray, filteredVehicles} = useAllVehicles();
    const [loadedVehicles, setLoadedVehicles] = useState(null);
    const {allCharters} = useAllCharters();
    // const {allDrivers, filteredDrivers} = useAllDrivers({filter: {duty: selectedShiftBat}});
    const {allEmployees} = useAllEmployees();
    const [allocating, setAllocating] = useState(false);
    const [jobSets, setJobSets] = useState([]);

    useEffect(() => {
        if (!allVehiclesArray || !vehicleJobs) {
            return;
        }
        const loadedVehicles = allVehiclesArray.map(vehicle => vehicle.clone());
        vehicleJobs.forEach(job => {
            const vehicle = find(loadedVehicles, {vehicleId: job.allocationId});
            vehicle.addJob(job);
        });
        setLoadedVehicles(loadedVehicles);
    }, [allVehiclesArray, vehicleJobs, setLoadedVehicles]);

    const itemColumns = useCallback(item => {
        if (!allCharters || !allShiftBats || !allVehicles || !allEmployees) return <></>;
        let jobName = allShiftBats[item?.typeId]?.shiftBatNumber;
        if (item.type === 'charter') {
            jobName = allCharters[item?.typeId].name;
        }
        let allocationName = allEmployees?.[`#EMPLOYEE#${item?.allocationId}#EXTERNAL`]?.firstName;
        if (item.allocationType === 'vehicle') {
            allocationName = allVehicles[item?.allocationId]?.vehicleName || allVehicles[item?.allocationId]?.vehicleRego;
        }
        return (<>
            <Col lg={2}>{item?.date?.format(DATE_STRING)}</Col>
            <Col lg={2}>{item?.job?.getStartTime({asDayJs: true})?.format('HH:mm')}</Col>
            <Col lg={2}>{item?.job?.getEndTime({asDayJs: true})?.format('HH:mm')}</Col>
            <Col lg={1}>{jobName}</Col>
            <Col lg={1}>{allocationName}</Col>
        </>);
    }, [allShiftBats, allVehicles, allCharters, allEmployees]);

    const editableView = ({item = new Job({date: dayjs()}), ...props}) => {
        return <EditJobView
            modelData={jobModelData}
            editing={item.clone()}
            createItemFn={item => new Job(item || {})}
            validateFn={item => item.date && item.typeId?.length && item.allocationId}
            {...props}
        />;
    };

    const jobToString = (job) => {
        if (!job?.job || !job.getDuty) {
            return 'No job';
        }
        return `${job.getDuty().shiftBatNumber} ${job.stats ? job.stats.leading.distance : 'no stats'} ${job.leadingDeadrun?.duration} ${job.getDuty().getStartTime({asDayJs: true}).format('HH:mm')} ${job.getDuty().getActualStartTime({asDayJs: true}).format('HH:mm')} ${job.getDuty().getActualEndTime({asDayJs: true}).format('HH:mm')} ${job.getDuty().getEndTime({asDayJs: true}).format('HH:mm')} ${job.trailingDeadrun?.duration} ${job.stats ? job.stats.trailing.distance : 'no stats'} `;
    };

    // const allocateSBs = () => {
    //
    //     // const loadedVehicles = allVehiclesArray.map(vehicle => vehicle.clone());
    //     // vehicleJobs.forEach(job => {
    //     //     const vehicle = find(loadedVehicles, {vehicleId: job.allocationId});
    //     //     vehicle.addJob(job);
    //     // });
    //
    //     JobSet.allocate(unallocatedShiftBats, loadedVehicles, date).then(jobSet => {
    //         setJobSets(jobSets => jobSets.concat(jobSet));
    //         setAllocating(false);
    //     });
    // };

    const showDiv = (<>{allShiftBats && allVehicles ?
            <div>
                <div><Button className={'mr-2'} onClick={() => {
                    setAllocating(true);

                    JobScenario.smartAllocate(unallocatedShiftBats, loadedVehicles, date).then(jobSet => {
                        setJobSets(jobSets => jobSets.concat(jobSet));
                        setAllocating(false);
                    });
                }}>Smart</Button>
                    <Button className={'mr-2'} onClick={() => {
                        setAllocating(true);

                        JobScenario.allocate(unallocatedShiftBats, loadedVehicles, date).then(jobSet => {
                            setJobSets(jobSets => jobSets.concat(jobSet));
                            setAllocating(false);
                        });
                    }}>Allocate</Button>
                    <Button className={'mr-2'} onClick={() => setDate(date.subtract(1, 'day'))}
                            icon={<AiFillFastBackward/>}/>{date.format('ll')}<Button className={'ml-2'}
                                                                                     onClick={() => setDate(date.add(1, 'day'))}
                                                                                     icon={<AiFillFastForward/>}/>
                </div>
                <div>ShiftBats: {unallocatedShiftBats?.map(sb => {
                    const job = new Job({job: sb, date: date});
                    return <Tooltip key={'tt-' + sb.shiftBatId}
                                    title={jobToString(job)}>
                        <Button
                            onClick={() => setSelectedShiftBat(sb)}>{sb.shiftBatNumber}</Button></Tooltip>;
                })}</div>
                <List
                    style={{width: '100%'}}
                    bordered
                    dataSource={loadedVehicles}
                    renderItem={(vehicle, index) => {
                        return <List.Item key={'vl' + index}>
                            {vehicle.vehicleName} {vehicle.jobs.map(j => <span>{jobToString(j)}</span>)}
                            <span style={{color: 'grey'}}>{jobToString(vehicle.jobOption)}</span>
                            <Button onClick={() => jobModelData.save(new Job({
                                date,
                                name: `${vehicle.jobOption.job.shiftBatNumber}-V`,
                                job: vehicle.jobOption.job,
                                typeId: vehicle.jobOption.job.shiftBatId,
                                type: 'shiftbat',
                                allocationType: 'vehicle',
                                allocationId: vehicle.vehicleId,
                                allocation: vehicle
                            })).then(job => 'saved job')}>Save</Button>
                        </List.Item>;
                    }}
                />
                {jobSets.map((jobSet, i) => {
                    return <div key={'js' + i}>
                        <Button className={'mr-2'} onClick={() => {
                            jobSet.save().then(() => {
                                setJobSets([]);
                            });
                        }}>Save Job set</Button>
                        <List
                            style={{width: '100%'}}
                            bordered
                            dataSource={jobSet.vehicles}
                            renderItem={(vehicle, index) => {
                                return <List.Item key={'pv-' + index}>
                                    {vehicle.vehicleName} {vehicle.jobs.map(j => <span>{jobToString(j)}</span>)}
                                </List.Item>;
                            }}
                        />

                        <EditableList filterItems={filterItems} feature={FEATURE.rstrs} items={jobSet.jobs}
                                      modelCopyFn={item => item.clone()} createItemFn={item => new Job(item)}
                                      modelData={jobModelData}
                                      editableView={editableView} itemColumns={itemColumns} headerCols={headerCols}
                                      name={'pj'} setItems={noop} fetch={false}
                        />
                        <div>Total Distance: {jobSet.getTotalDistance()}</div>
                    </div>;
                })}
                <div>Charters: {unallocatedCharters?.map(ch => ch.name).join(', ')}</div>
                <div>{filteredVehicles?.length}</div>
                <EditableList filterItems={filterItems} feature={FEATURE.rstrs} items={allJobs}
                              modelCopyFn={item => item.clone()} createItemFn={item => new Job(item)}
                              modelData={jobModelData}
                              editableView={editableView} itemColumns={itemColumns} headerCols={headerCols}
                              name={'jobs'} setItems={setAllJobs} fetch={false}
                />
            </div> : <LoadMessage message={'Loading...'}/>}</>
    );

    return allocating ? <LoadMessage message={'Allocating...'}/> : showDiv;
}

export default React.memo(Jobs);
