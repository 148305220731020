import React, { useEffect, useState } from "react";
import { Button, Col, Input, message, Modal, Row, Upload } from "antd";
import { ReactComponent as Email } from "../assets/icons/Email.svg";
import { ReactComponent as FileIcon } from "../assets/icons/Link.svg";
import TextArea from "antd/es/input/TextArea";
import { sendEmailViaTemplate } from "../libs/mailer";
import { useAppContext } from "../libs/contextLib";

export const mapAttachmentsToFormat = async (files) => {
    // Function to convert a file to base64 (only if it's a Blob or File)
    const convertToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => resolve(reader.result.split(",")[1]); // Removing the base64 prefix
            reader.onerror = (error) => reject(error);
            reader.readAsDataURL(file);
        });
    };

    // Function to convert Ant Design's Upload file object to a File
    const convertAntdFileToNativeFile = (antdFile) => {
        return new File([antdFile.originFileObj], antdFile.name, {
            type: antdFile.type,
            lastModified: antdFile.lastModified,
        });
    };

    // Map through the list of attachments and handle different cases
    const attachmentPromises = files.map(async (file) => {
        // If it's already in the correct format
        if (file.BinaryContent && file.Name && file.ContentType && file.Size) {
            return file;
        }

        // If it's an Ant Design upload file object, convert it to a native File object
        if (file.originFileObj) {
            const nativeFile = convertAntdFileToNativeFile(file);
            const base64Content = await convertToBase64(nativeFile);
            return {
                BinaryContent: base64Content, // Base64 string without the prefix
                Name: nativeFile.name, // File name
                ContentType: nativeFile.type, // MIME type (e.g., application/pdf)
                Size: nativeFile.size, // File size in bytes
            };
        }

        // If it's a Blob or File object, convert it to base64
        if (file instanceof Blob || file instanceof File) {
            const base64Content = await convertToBase64(file);
            return {
                BinaryContent: base64Content, // Base64 string without the prefix
                Name: file.name, // File name
                ContentType: file.type, // MIME type
                Size: file.size, // File size in bytes
            };
        }

        throw new Error("Unsupported attachment format");
    });

    // Wait for all attachments to be processed
    const attachments = await Promise.all(attachmentPromises);

    return attachments;
};

export default function EmailButton({ show = true, mail: initialMail = {}}) {
    const { messageApi } = useAppContext();
    const [sending, setSending] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [mail, setMail] = useState({
        to: [],
        cc: [],
        bcc: [],
        message: null,
        attachments: [],
        ...initialMail,
    });

    useEffect(() => {
        setMail((prev) => {
            return { ...prev, ...initialMail };
        });
    }, [initialMail]);

    const handleSendAsEmail = async () => {
        setSending(true);
        try {
            const attachments = await mapAttachmentsToFormat(mail.attachments);
            await sendEmailViaTemplate({
                to: mail.to,
                cc: mail.cc,
                bcc: mail.bcc,
                attachments,
                merge: {
                    message: mail.message || "",
                },
                subject: mail.subject,
                template: mail.template || "Email: General",
            });
            // send event back
            if (mail.onMailComplete) {
                mail.onMailComplete();
            }
            setSending(false);
            clear();
        } catch (err) {
            console.log("Something went wrong");
            messageApi.error("Something went wrong while sending email");
            setSending(false);
        }
    };

    const clear = () => {
        setMail({
            to: [],
            cc: [],
            bcc: [],
            message: null,
            attachments: [],
            ...initialMail,
        });
        setShowModal(false);
    };

    const onOpen = () => {
        setShowModal(true)

        if (mail.onMailOpen) {
            mail.onMailOpen();
        }
    }

    if (!show) return null;

    return (
        <>
            <Button type="primary" className="icon-button btn-filled" icon={<Email />} onClick={onOpen}>
                Send
            </Button>
            <Modal
                open={showModal}
                title={`Confirm email options`}
                onCancel={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setShowModal(false);
                }}
                destroyOnClose
                footer={[
                    <Button key="back" onClick={clear}>
                        Cancel
                    </Button>,
                    <Button key="submit" type="primary" loading={sending} onClick={handleSendAsEmail}>
                        Send
                    </Button>,
                ]}
            >
                <Col xs={24} lg={24}>
                    <Row gutter={[20, 20]}>
                        <Col xs={24} md={24}>
                            <label>TO</label>
                            <Input
                                placeholder="Enter TO emails"
                                value={mail.to}
                                onChange={({ target: { value } }) => setMail({ ...mail, to: value.split(",") })}
                            />
                        </Col>
                        <Col xs={24} md={24}>
                            <label>CC</label>
                            <Input
                                placeholder="Enter CC emails"
                                value={mail.cc}
                                onChange={({ target: { value } }) => setMail({ ...mail, cc: value.split(",") })}
                            />
                        </Col>
                        <Col xs={24} md={24}>
                            <label>BCC</label>
                            <Input
                                placeholder="Enter BCC emails"
                                value={mail.bcc}
                                onChange={({ target: { value } }) => setMail({ ...mail, bcc: value.split(",") })}
                            />
                        </Col>
                        <Col xs={24} md={24}>
                            <label>Subject</label>
                            <Input
                                placeholder="Enter Subject"
                                value={mail.subject}
                                onChange={({ target: { value } }) => setMail({ ...mail, subject: value })}
                            />
                        </Col>
                        <Col xs={24} md={24}>
                            <label>Message</label>
                            <TextArea
                                placeholder="Enter message"
                                value={mail.message}
                                onChange={({ target: { value } }) => setMail({ ...mail, message: value })}
                            />
                        </Col>
                        <Col xs={24} md={24}>
                            <label style={{ marginRight: 20 }}>Attachments</label>
                            <Upload
                                beforeUpload={(file) => {
                                    // const _file = new File(file)
                                    // setMail({ ...mail, attachments: mail.attachments.map((at) => (at.name === file.name ? _file : at)) });
                                    return false;
                                }}
                                onChange={({ fileList: newFileList }) => {
                                    console.log(">>> new", newFileList, mail.attachments);
                                    setMail({ ...mail, attachments: newFileList });
                                }}
                                onRemove={(e) => {
                                    console.log(">>> onRemove", e);
                                }}
                                onPreview={(e) => {}}
                                fileList={mail.attachments}
                                listType="text"
                                multiple
                            >
                                <div className="logo-upload">
                                    <FileIcon />
                                </div>
                            </Upload>
                        </Col>
                    </Row>
                </Col>
            </Modal>
        </>
    );
}
