import {toBase64} from "../libs/formatLib";
import React, {useState} from "react";
import {Modal, Upload} from "antd";
import {ReactComponent as Photo} from '../assets/icons/Photo.svg';

function LogoUpload({handleOnRemove, fileList, setFileList, ...props}) {

    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [previewTitle, setPreviewTitle] = useState('');
    // const [fileList, setFileList] = useState([])

    const handleCancel = () => setPreviewOpen(false);
    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await toBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewOpen(true);
        setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
    };
    const handleChange = ({fileList: newFileList}) => setFileList(newFileList);
    const uploadButton = (
        <div className="logo-upload">
            <Photo />
        </div>
    );

    return (
        <>
            <Upload
                onRemove={(file) => {
                    setFileList([]);
                    handleOnRemove && handleOnRemove(file)
                }}
                beforeUpload={(file) => {
                    setFileList([file]);
                    return false
                }}
                maxCount={1}
                fileList={fileList}
                listType="picture-card"
                onPreview={handlePreview}
                onChange={handleChange}
                {...props}
            >
                {fileList?.length < 1 && uploadButton}
            </Upload>
            <Modal open={previewOpen} title={previewTitle} footer={null}
                   onCancel={handleCancel}>
                <img
                    alt={`Logo`}
                    style={{
                        width: '100%',
                    }}
                    src={previewImage}
                />
            </Modal>
        </>
    )
}

export default React.memo(LogoUpload)
