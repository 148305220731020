import {useCallback, useEffect, useRef, useState} from "react";
import {Auth} from "aws-amplify";
import store from 'store2';
import {getFullUrl} from "../index";

export function useFormFields(initialState) {
    const [fields, setValues] = useState(initialState);

    return [
        fields,
        function (event) {
            setValues({
                ...fields,
                [event.target.id]: event.target.value
            });
        }
    ];
}

export const getKey = async () => {
    if (process.env.REACT_APP_KEY) {
        return process.env.REACT_APP_KEY;
    }
    let params = new URLSearchParams(window.location.search);
    if (params['_k']) {
        return params['_k'];
    }

    let pathArray = window.location.pathname.split('/');
    if (pathArray.length >= 3 && ['timetables', 'trip_planner', 'school_list', 'stop_list', 'p_sb'].includes(pathArray[1])) {
        return pathArray[2];
    }

    try {
        const key = store.session.get("busable-operator-id");
        if(key) {
            return key;
        }
    } catch (e) {
        console.log(e)
    }

    try {
        await Auth.currentSession();
        const user = await Auth.currentUserInfo();
        if (!user?.attributes) {
            return null
        }
        return user.attributes['custom:operatorId'];
    } catch (e) {
        console.log('Error getting key from user: ', e)
    }
    return null;
}

export const GetFullUrl = () => {
    const [url, setUrl] = useState(null);
    useEffect(() => {
        setUrl(getFullUrl());
    }, [setUrl])
    return url;
}

export const useScroll = (options) => {
    const scrollRef = useRef(null);
    let scrolled = useRef(null);

    const executeScroll = useCallback(() => {
        if (scrolled.current !== scrollRef.current) {
            scrollRef.current && scrollRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'nearest',
                inline: 'start',
                ...options
            });
            scrolled.current = scrollRef.current
        } else {
            // console.log('not scrolling')
        }
    }, [options])

    return [executeScroll, scrollRef];
};
