import * as React from 'react';
import './RouteTitle.css';
import {noop} from "lodash/util";
import {Input} from "antd/lib";
import {useEffect, useState} from "react";
import {BusRoute} from "../model/busRoute";
import LogoUpload from "./LogoUpload";

function RouteTitleEdit({
                            size,
                            route,
                            colour,
                            setRoute,
                            validationHelper = {},
                            setUpdated = noop,
                            fileList, setFileList,
                            editRouteNumber, editRouteName, editRouteDetails,
                            handleLogoRemove, onBlurRouteNumber = noop, onBlurRouteName = noop,
                            onBlurRouteDetails = noop
                        }) {
    const [routeNumber, setRouteNumber] = useState("")
    const [routeName, setRouteName] = useState("")
    const [routeDetails, setRouteDetails] = useState("")
    const [routeColour, setRouteColour] = useState("#000")

    size = size || '';

    useEffect(() => {
        setRouteNumber(route.routeNumber);
        setRouteName(route.routeName);
        setRouteDetails(route.routeDetails);
        setRouteColour(colour || route.colour || "#000");
    }, [route, colour])

    return (
        <>
            <div className={`row RouteTitle flex-nowrap align-items-center w-100`}>
                <div className='d-flex align-items-center' style={{flexBasis: 'min-content'}}>
                    <div className={`EditRouteLogo${size.length ? `-${size}` : ''} Edit-sm`}
                         style={{borderColor: routeColour}}>
                        <LogoUpload fileList={fileList} setFileList={setFileList}
                                    className="icon-logo"
                                    handleOnRemove={handleLogoRemove}/>
                    </div>
                    <Input
                        className={`RouteNumber${size.length ? `-${size}` : ''} ${validationHelper.routeNumber ? 'alert-danger' : ''}`}
                        style={{backgroundColor: routeColour}}
                        value={routeNumber}
                        placeholder="ID"
                        onChange={({target: {value}}) => {
                            if (value !== routeNumber) {
                                setRouteNumber(value)
                                if (editRouteNumber) {
                                    editRouteNumber(value)
                                }
                                if (setRoute) {
                                    setRoute(route => new BusRoute({
                                        ...route,
                                        routeNumber: value
                                    }))
                                }
                                setUpdated(true);
                            }
                        }}
                        onBlur={onBlurRouteNumber}
                    />
                </div>
                <div className='d-flex flex-column align-items-center w-100 ml-3'>
                    <Input
                        className={`RouteInfo${size.length ? `-${size}` : ''} w-100 d-flex flex-column mb-2 ${validationHelper.routeName ? 'alert-danger' : ''}`}
                        style={{gap: "8px"}}
                        value={routeName}
                        placeholder="Name"
                        onChange={({target: {value}}) => {
                            if (value !== routeName) {
                                setRouteName(value)
                                if (editRouteName) {
                                    editRouteName(value)
                                }
                                if (setRoute) {
                                    setRoute(route => new BusRoute({
                                        ...route,
                                        routeName: value
                                    }))
                                }
                                setUpdated(true);
                            }
                        }}
                        onBlur={onBlurRouteName}
                    />
                    {size !== 'sm' &&
                        <Input className={`RouteDetails${size.length ? `-${size}` : ''}`}
                               placeholder={'Description'}
                               onChange={({target: {value}}) => {
                                   if (value !== routeDetails) {
                                       setRouteDetails(value)
                                       if (editRouteDetails) {
                                           editRouteDetails(value)
                                       }
                                       if (setRoute) {
                                           setRoute(route => new BusRoute({
                                               ...route,
                                               routeDetails: value
                                           }));
                                       }
                                       setUpdated(true);
                                   }
                               }}
                               onBlur={onBlurRouteDetails}
                               value={routeDetails}/>
                    }
                </div>
            </div>
        </>
    );
}

export default React.memo(RouteTitleEdit);
