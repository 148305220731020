import React from 'react';
import ReactDOM from 'react-dom';
import {Amplify} from 'aws-amplify';
import {BrowserRouter as Router} from 'react-router-dom';
import './index.css';
import App from './App';
import config from './config';
import reportWebVitals from './reportWebVitals';
import TripPlanner from './containers/TripPlanner';
import Timetables from './containers/Timetables';
import axios from 'axios';
import log from 'loglevel';
import {ConfigProvider} from 'antd';
import StopTimetablesList from './containers/StopTimetablesList';
import ShiftBats from './features/ShiftBat/ShiftBats';
import {routesModelExpiryService} from './services/ModelService';

import './dayjs'
import en_GB from 'antd/locale/en_GB';

// import dayjs from 'dayjs';
//
// import customParseFormat from 'dayjs/plugin/customParseFormat';
// import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
// import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
// import isBetween from 'dayjs/plugin/isBetween';
// import isoWeek from 'dayjs/plugin/isoWeek';
// import localizedFormat from 'dayjs/plugin/localizedFormat';
// import localeData from 'dayjs/plugin/localeData';
// import weekday from 'dayjs/plugin/weekday';
//
// import 'dayjs/locale/en-au';
//
// dayjs.locale('en-au');
// dayjs.extend(customParseFormat)
//     .extend(isSameOrAfter)
//     .extend(isSameOrBefore)
//     .extend(isBetween)
//     .extend(isoWeek)
//     .extend(localizedFormat)
//     .extend(localeData)
//     .extend(weekday);

log.setDefaultLevel('WARN');
// log.getLogger("TripPlanner").setLevel("DEBUG");
// log.getLogger("BusRoute").setLevel("DEBUG");
// log.getLogger("Schedule").setLevel("DEBUG");
// log.getLogger("ModelService").setLevel("DEBUG");
// log.getLogger("PointManager").setLevel("DEBUG")


export const getFullUrl = () => {
    const protocol = window.location.protocol;
    const domain = window.location.hostname;
    const port = window.location.port;
    return `${protocol}//${domain}${port && port.length ? `:${port}` : ''}`;
};


if (!config.local) {
    console.log(`Configuring AMPLIFY...`);
    console.log('COGNITO: ', config.cognito);
    Amplify.configure({
        Auth: {
            mandatorySignIn: true,
            region: config.cognito.REGION,
            userPoolId: config.cognito.USER_POOL_ID,
            identityPoolId: config.cognito.IDENTITY_POOL_ID,
            userPoolWebClientId: config.cognito.APP_CLIENT_ID
        },
        Storage: {
            region: config.s3.REGION,
            bucket: config.s3.BUCKET,
            identityPoolId: config.cognito.IDENTITY_POOL_ID
        },
        API: config.apiGateway.API
    });

    axios.defaults.timeout = 300000;
    axios.interceptors.request.use((config) => {
        config.timeout = 300000;
        return config;
    }, (error) => {
        return Promise.reject(error);
    });
}


let src = document.currentScript.src;
if (!src.includes('?')) {
    src = window.location.search;
}
const params = new URLSearchParams('?' + src.split('?')[1]);
const operatorId = params.get('operator_id');
const relPath = params.get('rel_path');
const [view, type] = params.get('view')?.split(':') || [];
const views = params.get('views')?.split(',');
const containers = params.get('containers')?.split(',');


if (window.location.pathname.endsWith('/refresh')) {
    routesModelExpiryService.clearDb();
}

if (operatorId?.length && view?.length) {
    console.log('Openning view...');
    ReactDOM.render(
        <React.StrictMode>
            <div className={`App publicContainer py-3 trip-planner-wrap`}>
                {view === 'timetables' ?
                    <Timetables operatorId={operatorId}/> :
                    view === 'trip_planner' ?
                        <TripPlanner operatorId={operatorId}/> :
                        view === 'school_list' ?
                            <StopTimetablesList operatorId={operatorId} relPath={relPath} view={type !== 'card'}/> :
                            view === 'venue_list' ?
                                <StopTimetablesList operatorId={operatorId} stopType={'venue'} relPath={relPath}
                                                    view={type !== 'card'}/> :
                                view === 'shiftbats' ?
                                    <ShiftBats operatorId={operatorId}/> :
                                    <>Incorrect setup. Please check your configuration setting.</>
                }
            </div>
        </React.StrictMode>,
        document.getElementById('busable-embed') || document.getElementById('root')
    );
} else if (operatorId?.length && views?.length && containers?.length) {
    console.log('Openning views...');
    views.forEach((screen, idx) => {
        let [view, type] = screen?.split(':') || [];
        ReactDOM.render(
            <React.StrictMode>
                <div className={`App publicContainer py-3 trip-planner-wrap`}>
                    {view === 'timetables' ?
                        <Timetables operatorId={operatorId}/> :
                        view === 'trip_planner' ?
                            <TripPlanner operatorId={operatorId}/> :
                            view === 'school_list' ?
                                <StopTimetablesList operatorId={operatorId} view={type !== 'card'}/> :
                                view === 'venue_list' ?
                                    <StopTimetablesList operatorId={operatorId} stopType={'venue'}
                                                        view={type !== 'card'}/> :
                                    view === 'shiftbats' ?
                                        <ShiftBats operatorId={operatorId}/> :
                                        <>Incorrect setup. Please check your configuration setting.</>
                    }
                </div>
            </React.StrictMode>,
            document.getElementById(containers[idx])
        );
    });
} else {
    console.log('Openning main app...');
    ReactDOM.render(
        <React.StrictMode>
            <Router>
                <ConfigProvider theme={{
                    token: {
                        fontFamily: 'Work Sans',
                    },
                    hashed: false,
                    locale: en_GB,
                }}>
                    <App/>
                </ConfigProvider>
            </Router>
        </React.StrictMode>,
        document.getElementById('root')
    );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
