import {useEffect, useState} from 'react';
import {vehicleTypeModelData} from '../services/ModelService';
import {values} from 'lodash';

const useAllVehicleTypes = ({filter = null} = {}) => {
    const [allVehicleTypes, setAllVehicleTypes] = useState(null);
    const [allVehicleTypesArray, setAllVehicleTypesArray] = useState([]);

    useEffect(() => {
        const load = async () => {
            try {
                const vehicleTypes = await vehicleTypeModelData.getAll();
                setAllVehicleTypes(vehicleTypes);
            } catch (e) {
                console.log(e);
            }
        };

        load().then(() => console.log('VehicleType List Updated.'));

        const vehicleTypesListener = vehicleTypeModelData.addListener({
            setterFn: setAllVehicleTypes, loaded: vehicleTypes => {
                setAllVehicleTypes(vehicleTypes);
            }
        });

        return () => {
            if (vehicleTypesListener)
                vehicleTypeModelData.removeListener(vehicleTypesListener);
        };
    }, [setAllVehicleTypes]);

    useEffect(() => {
        if(!allVehicleTypes) return;
        setAllVehicleTypesArray(values(allVehicleTypes));
    }, [allVehicleTypes, setAllVehicleTypesArray]);

    return {
        allVehicleTypes, allVehicleTypesArray
    };
};

export default useAllVehicleTypes;

