import React, {useCallback, useState} from 'react';
import '../containers/Timetable.css';
import RouteMapViewer from '../components/RouteMapViewer';
import LoadMessage from '../components/LoadMessage';
import TimetableRouteList from '../components/TimetableRouteList';
import {flatten, uniqBy} from 'lodash/array';
import {useAppContext} from '../libs/contextLib';

function RoutesViewer({
                          allRoutes,
                          allSchedules,
                          allStops,
                          filteredRoutes,
                          selectedSchedule,
                          fetching,
                          verifiedOnly,
                          setSelectedSchedule,
                          filter,
                          handleSelectedRoutes,
                          handleSelectedRoute,
                          setFilter,
                          handleStopClick,
                          allowComments,
                          setAllowComments,
                          showComments,
                          setShowComments,
                          showWpSelector,
                          expand = false
                      }) {

    const {operator} = useAppContext();
    const [selectedRouteIds, setSelectedRouteIds] = useState([]);
    const [highlightedRouteIds, setHighlightedRouteIds] = useState([]);
    const [zoom, setZoom] = useState([]);
    const [selectedStop, setSelectedStop] = useState(null);
    const [focusStop, setFocusStop] = useState(null);
    const [focusTransfers, setFocusTransfers] = useState({to: [], from: []});
    const [focusPt, setFocusPt] = useState(null);

    const getScheduleOptions = useCallback((route) => {
        return uniqBy(flatten(route.services.map(trip => trip.scheduleIds.map(sId => allSchedules[sId]))), 'scheduleId').filter(s => !!s).map(s => ({
            label: s.scheduleName,
            value: s.scheduleId,
            disabled: false
        }));
    }, [allSchedules]);

    return (
        <div className="Timetable">
            {!fetching ? (
                <>
                    <div className="card-main map-holder-wrap no-pad d-flex">
                        <div className="map-holder" style={{width: '65%'}}>
                            <div className="MapPlaceholder">
                                <RouteMapViewer routes={allRoutes}
                                                allStops={allStops}
                                                verifiedOnly={verifiedOnly}
                                                fetching={fetching}
                                                allowComments={allowComments}
                                                setAllowComments={setAllowComments}
                                                showComments={showComments}
                                                setShowComments={setShowComments}
                                                focusPt={focusPt}
                                                setFocusPt={setFocusPt}
                                                filteredRoutes={filteredRoutes}
                                                selectedRouteIds={selectedRouteIds}
                                                highlightedRouteIds={highlightedRouteIds}
                                                selectedStop={selectedStop}
                                                showWpSelector={showWpSelector}
                                                handleSelectedRoutes={handleSelectedRoutes}
                                                handleSelectedRoute={handleSelectedRoute}
                                                focusTransfers={focusTransfers}
                                                focusStop={focusStop}
                                                setFocusStop={setFocusStop}
                                                handleStopClick={handleStopClick}
                                                setFilter={setFilter}
                                                filter={filter}
                                                operatorId={operator?.operatorId}
                                                zoom={zoom}
                                                setZoom={setZoom}/>
                            </div>
                        </div>
                        <TimetableRouteList filteredRoutes={filteredRoutes}
                                            setHighlightedRouteIds={setHighlightedRouteIds}
                                            highlightedRouteIds={highlightedRouteIds}
                                            setZoom={setZoom}
                                            zoom={zoom}
                                            showComments={showComments}
                                            selectedSchedule={selectedSchedule}
                                            setSelectedSchedule={setSelectedSchedule}
                                            fetching={fetching}
                                            selectedStop={selectedStop}
                                            setSelectedStop={setSelectedStop}
                                            setFocusStop={setFocusStop}
                                            focusStop={focusStop}
                                            schedules={allSchedules}
                                            getScheduleOptions={getScheduleOptions}
                                            allStops={allStops}
                                            operator={operator}
                                            focusTransfers={focusTransfers}
                                            setFocusTransfers={setFocusTransfers}
                                            expand={expand}
                        />
                    </div>
                </>
            ) : <LoadMessage message={'Loading Timetables...'} size={'lg'}/>}

        </div>


    );
}

export default React.memo(RoutesViewer);
