import * as React from 'react';
import './RouteTitle.css'
import {Badge, ListGroup} from 'react-bootstrap';
import DisplayStop from "./DisplayStop";
import {Popover} from "antd";

export default function LinkedStopsList({interchange, placement= 'left'}) {
    const popover = (
        <div>
            <ListGroup>
                {interchange.map(s => (
                        <ListGroup.Item key={`filtered-list-IC-${s.stopId}`}>
                            <DisplayStop size="sm" noWarning noRoutes noInterchange stop={s.stop}/>
                        </ListGroup.Item>
                    )
                )}
            </ListGroup>
        </div>
    );

    const InterchangeOverlay = () => (
        <Popover content={popover}
                 trigger="hover"
        placement={placement}>
            <Badge pill variant="light" size='sm' className='badge-outline badge-light'>
                {`Linked: ${interchange.length}`}
            </Badge>
        </Popover>
        // <OverlayTrigger placement="auto" trigger={interchange.length ? 'click' : 'none'} overlay={popover}>
        //     <Badge pill variant="light" size='sm'>
        //         {`Linked: ${interchange.length}`}
        //     </Badge>
        // </OverlayTrigger>
    );

    return (
        <InterchangeOverlay/>
    );
}

