import {useEffect, useState} from 'react';

const useAll = ({modelService}) => {
    const [all, setAll] = useState(null);
    const [allAsArray, setAllAsArray] = useState(null);

    useEffect(() => {
        const listener = modelService.addListener({
            setterFn: setAll, loaded: all => {
                setAll(all);
            }
        });

        return () => {
            if (listener)
                modelService.removeListener(listener);
        };
    }, []);

    useEffect(() => {
        if (all) {
            setAllAsArray(Object.values(all));
        }
    }, [all]);

    return {
        all, allAsArray, setAll
    };
};

export default useAll;

