import React from "react";
import {SyncOutlined, WarningFilled, GlobalOutlined, CopyOutlined} from "@ant-design/icons";
import Pin from "./Pin";
import RouteNumberList from "./RouteNumberList";
import TransfersList from "./TransfersList";
import LinkedStopsList from "./LinkedStopsList";
import TransferListOverlay from "./TransferListOverlay";
import {Tooltip, Row, Col} from "antd";
import {Button} from "react-bootstrap";
import {useAppContext} from "../libs/contextLib";

function DisplayStop({stop, sequence, noWarning, noRoutes, noInterchange, noCode, className, transfers, handleSaveStop}) {
    const {messageApi} = useAppContext() || {};
    return (
        <div className={className}>
            {stop ?
                <Row wrap={false} align="middle">
                    <Col flex="none" className={"route-step-number"}>
                        <Pin size={20} type={stop.stopType} sequence={sequence || stop.sequence}/>
                    </Col>
                    <Col flex="auto" className={"route-step-name pl-1"} style={{wordWrap: "break-word", lineHeight: "18px"}} title='Stop name'>
                        {stop.stopName} {!noCode && stop.stopCode ? `(${stop.stopCode})` : ''}
                    </Col>
                    {!noRoutes &&
                        <Col flex="none" className="pl-1">
                            <span><RouteNumberList routes={stop.routes || []}/></span>
                        </Col>
                    }
                    {!noInterchange && stop.hasLinkedStops && stop.hasLinkedStops() &&
                        <Col flex="none" className="pl-1">
                            <span><LinkedStopsList interchange={stop.linkedStops}/></span>
                        </Col>
                    }
                    {!noInterchange &&
                        <Col flex="none" className="pl-1">
                            <span><TransferListOverlay stop={stop} transfers={transfers}/></span>
                        </Col>
                    }
                    {!noInterchange && stop.hasTransfers && stop.hasTransfers() &&
                        <Col flex="none" className="pl-1">
                            <span><TransfersList stop={stop} handleSaveStop={handleSaveStop}/></span>
                        </Col>
                    }
                    {!noWarning &&
                        <Col flex="none" className="pl-2 pr-1 d-flex align-items-center">{stop.master ?
                            <GlobalOutlined title='Point from shared data source' style={{color: '#0670F9'}}/>
                        : !stop.verified ?
                            <WarningFilled title='Unverified point' style={{color: '#dc3545'}}/>
                        : stop.outOfSync?.length ?
                            <Tooltip title={<div>Source data mismatch: <div>{stop.outOfSync.map(m => <div>{m}</div>)}</div></div>}><WarningFilled style={{color: '#dc3545'}}/></Tooltip>
                        : <></>}
                        </Col>
                    }
                    {/* {stop.stopCode && <Col flex="none" className="pl-1"><Button title='Copy stop code' size={'sm'} variant={"light"} className="icon-button"
                            onClick={e => {
                                e.stopPropagation()
                                navigator.clipboard.writeText(stop.stopCode).then(() => {
                                    messageApi && messageApi.info(<div><p>Stop code copied to clipboard</p></div>, 10)
                                })
                            }}><CopyOutlined/></Button></Col> } */}
                </Row> : <SyncOutlined spin={true}/>}
        </div>
    )
}

export default React.memo(DisplayStop);

