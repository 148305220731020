import { cloneDeep } from "lodash";

export class Banking {
    constructor(data) {
        this.accountName = null;
        this.bsb = null;
        this.accountNumber = null;
        this.bankName = null;
        this.effectiveDate = null;

        this.payrollRecords = []

        Object.assign(this, data);
    }

    clone(accountInformation) {
        return new Banking(cloneDeep(accountInformation));
    }
}