import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Avatar, Button, Carousel, Collapse, Flex, Input, List, Tooltip, Typography} from 'antd';
import useComments, {unreadFirstThenNewest, unreadLastThenOldest} from '../hooks/useComments';
import dayjs from '../dayjs';
import {routeCommentModelData, stopCommentModelData} from '../services/ModelService';
import {useAppContext} from '../libs/contextLib';
import DisplayStop from './DisplayStop';
import RouteTitle from './RouteTitle';
import {ReactComponent as Pin} from '../assets/icons/Pin.svg';
import {ReactComponent as Check} from '../assets/icons/Check.svg';
import {ReactComponent as Chat} from '../assets/icons/Chat.svg';
import {ReactComponent as Trash} from '../assets/icons/Trash.svg';
import {ReactComponent as Close} from '../assets/icons/Close.svg';
import {groupBy, map, uniq} from 'lodash';
import {toTimeAgo} from '../libs/formatLib';
import {ChatContext} from '../model/Comment';
import {Space} from 'antd/es';
import {Popconfirm} from 'antd/lib';

export const isUnread = (c, editor) => !c.readBy?.includes(editor);

export const markAllAsRead = ({comments, chatContext, editor}) => {
    if (comments?.length) {
        const readComments = comments.filter(c => !c.readBy?.includes(editor)).map(c => {
            c.readBy = c.readBy || [];
            c.readBy.push(editor);
            return c;
        });
        if (readComments?.length) {
            if (chatContext.type === 'route') {
                routeCommentModelData.save(readComments).then(() => {
                    console.log('read comments saved');
                });
            } else if (chatContext.type === 'stop') {
                stopCommentModelData.save(readComments).then(() => {
                    console.log('read comments saved');
                });
            }
        }
    }
};
export const deleteAll = ({comments}) => {
    if (comments?.length) {
        routeCommentModelData.delete(comments.filter(c => c.type === 'route' && c.archived).map(c => c.refId), true, true).then(() => {
            console.log('archived route comments deleted');
        });
        stopCommentModelData.delete(comments.filter(c => c.type === 'stop' && c.archived).map(c => c.refId), true, true).then(() => {
            console.log('stop comments deleted');
        });
    }
};
export const archiveAll = ({comments}) => {
    if (comments?.length) {
        routeCommentModelData.save(comments.filter(c => c.type === 'route').map(c => {
            c.archived = true;
            return c;
        })).then(() => {
            console.log('route comments archived');
        });
        stopCommentModelData.save(comments.filter(c => c.type === 'stop').map(c => {
            c.archived = true;
            return c;
        })).then(() => {
            console.log('route comments archived');
        });
    }
};

export const markAsRead = ({comment, editor}) => {
    if (comment) {
        comment.readBy = comment.readBy || [];
        comment.readBy.push(editor);
        comment.readBy = uniq(comment.readBy);
        if (comment.type === 'route') {
            routeCommentModelData.save(comment).then(() => {
                console.log('read comments saved');
            });
        } else if (comment.type === 'stop') {
            stopCommentModelData.save(comment).then(() => {
                console.log('read comments saved');
            });
        }
    }
};

const Commenter = ({comment}) => {
    return <Flex justify={'space-between'} align={'center'} className="mb-2">
        <div className="d-flex align-items-center">
            <Avatar size={30}
                    className={'mr-2'}>{comment.author?.length ? comment.author?.charAt(0).toUpperCase() : ''}</Avatar>
            <div className="text-title-sm">{comment.author}</div>
        </div>
        <div className="text-xs-info">{toTimeAgo(dayjs().diff(dayjs(comment.createdAt), 'seconds'))}</div>
    </Flex>;
};

export const Comment = ({comment, chatContext, editor}) => {
    return (
        <div className={'w-100'}>
            <Commenter comment={comment}/>
            <Flex justify={'space-between'}><Flex>
                {/*comment.stop ?
                <DisplayStop stop={comment.stop}/> : comment.route && comment.subType === 'wp' ?
                    <Button type={"text"} onClick={() => {
                        chatContext.setSelectedRouteIds?.([comment.routeId]);
                        chatContext.setFocusPt?.(comment);
                    }}><Image preview={false} height={24}
                              src={'/location-pin.png'}/></Button> : <></>*/}
                <Typography.Paragraph
                    style={{marginBottom: '0', lineHeight: '18px'}}>{comment.message}</Typography.Paragraph></Flex>
                {//isUnread(comment, editor) &&
                    // <Tooltip title={"Mark as resolved"}><Button onClick={() => {
                    //     markAsRead({comment, editor})
                    // }} icon={<CheckOutlined/>}/></Tooltip>
                }</Flex>
            {/*<Typography.Text><Commenter editor={editor}/><small>{`By: ${comment.author || 'Anonymous'} @ ${toHrsMinsSecs(dayjs(comment.createdAt).diff(dayjs(), 'seconds'))}`}</small></Typography.Text>*/}
        </div>
    );
};

export const ChatHeader = ({chatContext, comments, editor}) => {
    return <Flex justify={'space-between'}>
        <div>{chatContext.route ?
            <RouteTitle route={chatContext.route}/> : chatContext.stop ?
                <DisplayStop stop={chatContext.stop}/> :
                'Unknown context'}</div>
        {comments.some(c => isUnread(c, editor)) && <div><Button onClick={() => {
            markAllAsRead({comments, chatContext: chatContext, editor});
        }} icon={<Check/>}/></div>}
    </Flex>;
};

export const ChatList = ({comments, chatContext, editor, initialShowAll = false}) => {
    const [showAll, setShowAll] = useState(initialShowAll);
    // const {comments} = useComments({chatContext});
    return (<List
        dataSource={comments}
        renderItem={item => (<>
            <List.Item
                style={{display: showAll || item.unRead || chatContext?.showAllComments ? 'block' : 'none'}}>
                <Comment comment={item} chatContext={chatContext} editor={editor}/>
            </List.Item>
            {!chatContext?.showAllComments ? <List.Item>
                <Button onClick={() => setShowAll(showAll => !showAll)}>{showAll ? 'Hide' : 'Show'} resolved</Button>
            </List.Item> : <></>}
            <List.Item>
                <ChatInput chatContext={ChatContext.fromContext(comments[0])}/>
            </List.Item>
        </>)}></List>);
};

export const ChatPages = ({
                              chatContext,
                              editor,
                              groupComments,
                              newChatContext,
                              onClose,
                              onSave,
                              selectedChatContext,
                              onChange
                          }) => {
    const carousel = useRef();
    useEffect(() => {
        if (!carousel.current?.innerSlider?.props?.children?.length) {
            return;
        }
        let id = null;
        if (newChatContext) {
            id = 'new';
        } else if (selectedChatContext) {
            id = selectedChatContext.getId();
        }

        if (id) {
            const idx = carousel.current.innerSlider.props.children.findIndex(slide => {
                return slide?.props?.children?.[0]?.props?.children?.[0].props?.contextId === id;
            });

            if (idx > -1) {
                carousel.current.goTo(idx);
            }
        }
    }, [newChatContext, carousel, selectedChatContext, groupComments]);

    return (<Carousel arrows={newChatContext || groupComments?.length > 1} infinite={false}
                      dots={newChatContext || groupComments?.length > 1} ref={carousel}
        // beforeChange={(_, current) => onChange(current)}
                      beforeChange={(oldIndex, current) => {
                          if (current === oldIndex) {
                              return;
                          }
                          console.log(`Selected comment group: ${current}`);
                          const comment = groupComments?.[current]?.[0];
                          onChange(current);
                          if (!newChatContext && comment) {
                              console.log(`Selected comment: ${comment.message}, ${comment.lat}, ${comment.lon}`);
                              if (Number.isFinite(comment.lat) && Number.isFinite(comment.lon)) {
                                  chatContext.setSelectedRouteIds?.([comment.routeId]);
                                  chatContext.setFocusPt?.(comment);
                              }
                          }
                      }}
    >
        {groupComments.map((group, i) => <ChatList contextId={new ChatContext(group[0])?.getId()}
                                                   key={'gl-' + group[0].id} editor={editor} comments={group}
                                                   chatContext={chatContext}/>)}
        {newChatContext ?
            <ChatInput contextId={'new'} chatContext={newChatContext} onClose={onClose} onSave={(message, chatContext) => {
                onSave(message, chatContext);
                // console.log('going to last slide ', carousel.current.innerSlider.props.children.length)
                // setTimeout(() => {
                // carousel.current.goTo(carousel.current.innerSlider.props.children.length - 1)
                // }, 1000)
            }}/> :
            null
        }
    </Carousel>);
};

export const RouteChat = ({
                              chatContext,
                              editor,
                              newChatContext,
                              onClose,
                              onSave,
                              selectedChatContext
                          }) => {
    const {isAuthenticated} = useAppContext();
    const [archived, setArchived] = useState(false);
    const {comments} = useComments({chatContext, archived});
    const [groupedComments, setGroupedComments] = useState([]);
    const [selectedGroupIdx, setSelectedGroupIdx] = useState(0);


    useEffect(() => {
        const grouped = groupBy(comments, comment => `${comment.subType}_${comment.subTypeId}`);
        const groupedComments = map(grouped, group => group);
        groupedComments.forEach(group => group.sort(unreadLastThenOldest));
        groupedComments.sort(unreadFirstThenNewest);
        setGroupedComments(groupedComments);
    }, [comments, setGroupedComments, newChatContext]);

    return <div>
        <Flex justify={'space-between'} align={'center'}>
            <RouteTitle route={chatContext.route}/>
            <Tooltip
                title={`${archived ? 'Hide' : 'Show'} archived comments for route ${chatContext?.route?.routeNumber || 'Unknown'}`}>
                <Button onClick={() => setArchived(!archived)} icon={<Chat style={{width: '16px'}}/>}
                        className={archived ? 'icon-button secondary-btn active' : 'icon-button secondary-btn'}/>
            </Tooltip>
        </Flex>
        {(groupedComments?.length < 1) &&
            <div className="text-info-md py-2">No {archived ? 'archived ' : ''}comments</div>}
        <ChatPages editor={editor} groupComments={groupedComments} chatContext={chatContext}
                   newChatContext={newChatContext} onClose={onClose} onSave={() => {

            onSave();
            setSelectedGroupIdx(groupedComments.length);
        }}
                   selectedChatContext={selectedChatContext} onChange={setSelectedGroupIdx}/>
        {groupedComments?.length > 1 && <div className="text-xs-info CarouselInfo">{selectedGroupIdx + 1} out
            of {groupedComments?.length + (newChatContext ? 1 : 0)}</div>}
        <div className="CarouselActions">
            {groupedComments?.[selectedGroupIdx]?.length &&
                <Tooltip title={'Show Point'} placement={'bottom'}>
                    <Button icon={<Pin style={{height: '16px'}}/>} className="icon-button secondary-btn"
                            onClick={() => {
                                chatContext.setSelectedRouteIds?.([groupedComments?.[selectedGroupIdx]?.[0]?.route?.routeId]);
                                chatContext.setFocusPt?.(groupedComments?.[selectedGroupIdx]?.[0]);
                            }}/></Tooltip>}
            {comments?.length ? comments.every(c => !c.unRead) &&
                <Tooltip
                    title={`Mark all comments for route ${chatContext?.route?.routeNumber || 'Unknown'} as read`}
                    placement={'bottom'}>
                    <Popconfirm
                        title={`Are you sure you want to mark all comments for route ${chatContext?.route?.routeNumber || 'Unknown'} as read`}
                        onConfirm={() => {
                            markAllAsRead({comments, chatContext, editor});
                        }}><Button icon={<Check/>}
                                   className="icon-button secondary-btn icon-stroke"/></Popconfirm></Tooltip> : <></>}
            {comments?.length && isAuthenticated ? <Tooltip
                title={`${archived ? 'Delete' : 'Archive'} all comments for route ${chatContext?.route?.routeNumber || 'Unknown'}`}
                placement={'bottom'}>
                <Popconfirm
                    title={`Are you sure you want to ${archived ? 'delete' : 'archive'} all comments for route ${chatContext?.route?.routeNumber || 'Unknown'}`}
                    onConfirm={() => {
                        archived ? deleteAll({comments}) : archiveAll({comments});
                    }}>
                    <Button icon={<Trash style={{height: '14px'}}/>}
                            className="icon-button secondary-btn"/></Popconfirm></Tooltip> : null}
        </div>
    </div>;
};

export const ChatInput = ({chatContext, onSave, onClose}) => {
    const {user, messageApi} = useAppContext();
    const {newComment, editor, setEditor} = useComments({chatContext});
    const [message, setMessage] = useState('');

    const handleSendMessage = useCallback(() => {
        if (!message.trim()) return;
        try {
            newComment(message);
            messageApi.info('Comment saved');
            setMessage('');
            onSave && onSave(message, chatContext);
        } catch (e) {
            messageApi.error('Error saving comment: ' + e.message, 30);
        }
    }, [message, newComment, messageApi, onSave, chatContext, setMessage]);

    return <Flex vertical={true} className="w-100">
        {!user && <Input value={editor} onChange={e => setEditor(e.target.value)} placeholder="Your name..."
                         className="CommenterName"/>}
        <Flex style={{minHeight: '32px'}}>
            {user &&
                <Avatar className={'mr-2'} size={30}>{editor?.length ? editor?.charAt(0).toUpperCase() : ''}</Avatar>}
            <Space.Compact className="NewCommentWrap">
                <Input value={message} onChange={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    setMessage(e.target.value);
                }} placeholder="Add comment..." onPressEnter={handleSendMessage}/>
                <Button type="primary" disabled={!message || !editor} onClick={handleSendMessage} icon={<Check/>}/>
                {onClose &&
                    <Button className="icon-button secondary-btn" onClick={onClose}
                            icon={<Close style={{height: '10px'}}/>}/>}
            </Space.Compact>
        </Flex>
    </Flex>;
};

const ChatComponent = ({chatContext, hideInput}) => {
    const {editor} = useAppContext();
    const [message, setMessage] = useState('');
    const {comments, groupedComments, newComment} = useComments({chatContext});
    const [showAll, setShowAll] = useState([]);
    const [filteredGroups, setFilteredGroups] = useState([]);

    // useEffect(() => {
    //     if (groupedComments?.length) {
    //         setFilteredGroups(groupedComments);
    //     }
    //         let unreadGroups = groupedComments.map(group => group.filter(c => !c.readBy?.includes(editor)));
    //         setFilteredGroups(unreadGroups);
    //         return;
    //     setFilteredGroups(groupedComments);
    // }, [setFilteredGroups, groupedComments, unreadOnly, editor]);

    return (
        <div>
            {/*{chatContext?.type === 'all' ?*/}
            <Collapse
                accordion={true}
                items={groupedComments
                    .map(group => {
                            const key = `${group[0].type}_${group[0].id}`;
                            return {
                                key,
                                label: <ChatHeader chatContext={chatContext} comments={group} editor={editor}/>,
                                children: <ChatList editor={editor} comments={group} chatContext={chatContext}/>
                            };
                        }
                    )}/>
            {/*:*/}
            {/*<List*/}
            {/*    dataSource={comments}*/}
            {/*    renderItem={item => (*/}
            {/*        <List.Item>*/}
            {/*            <Comment comment={item} chatContext={chatContext}/>*/}
            {/*        </List.Item>*/}
            {/*    )}*/}
            {/*/>*/}
            {/*}*/}
            {comments?.length === 0 && !hideInput && <>
                <ChatInput chatContext={chatContext}/>
            </>
            }
        </div>
    )
        ;
};

export default React.memo(ChatComponent);