import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import isBetween from "dayjs/plugin/isBetween";
import isoWeek from "dayjs/plugin/isoWeek";
import localizedFormat from 'dayjs/plugin/localizedFormat'

dayjs.extend(customParseFormat)
    .extend(isSameOrAfter)
    .extend(isSameOrBefore)
    .extend(isBetween)
    .extend(isoWeek)
    .extend(localizedFormat)

export default dayjs
