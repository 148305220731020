import React, {useCallback, useState} from 'react';
import {Button, Modal} from 'antd';
import {InboxOutlined, SyncOutlined} from '@ant-design/icons';
import {Upload} from 'antd/lib';
import {s3UploadPrivateDirectly} from '../../libs/awsLib';
import {getDataType, getGtfsDataForOperator, uzipGtfsData} from '../../libs/gtfs-lib';
import {parse} from 'csv-parse/dist/esm/sync';
import Nav from 'react-bootstrap/Nav';

const {Dragger} = Upload;

const IMPORT_TYPES = {todis: {label: 'Todis'}, gtfs: {label: 'Gtfs'}};

function GtfsImportModal({operator, messageApi, btnClassName}) {
    const [visible, setVisible] = useState(false);

    const [file, setFile] = useState(null);
    const [uploading, setUploading] = useState(false);
    const [success, setSuccess] = useState(null);
    const [data, setData] = useState(null);
    const [serviceCount, setServiceCount] = useState(0);
    const [type, setType] = useState(null);

    const handleUpload = useCallback(() => {
        const upload = async () => {
            try {
                const filename = `import/${type}/${operator.operatorId}/${file.name}`;
                await s3UploadPrivateDirectly(file, filename);
                setSuccess(true);
                messageApi.info(IMPORT_TYPES[type].label+' uploaded. Please give us a minute to import your data.', 10);
            } catch (e) {
                console.error(`Error uploading ${type} file`, e);
                messageApi.error(`There was a problem uploading the ${type} file. Please try again.`, 10);
            }

            setUploading(false);
            setVisible(false);
            setFile(null);
            setData(null);
            setUploading(false);

        };
        if (file) {
            setUploading(true);
            upload().then(() => {
                console.log('imported');
                setVisible(false);
            });
        }
    }, [setVisible, messageApi, file, setFile, setUploading, operator.operatorId]);

    const props = {
        beforeUpload: async (file) => {

            const buffer = await file.arrayBuffer();
            const type = getDataType(Buffer.from(buffer));
            if (!type) {
                messageApi.error(`The uploaded file is empty.`, 10);
                return;
            }
            setType(type);

            if (type === 'todis') {
                setFile(file);
                return;
            }
            const data = uzipGtfsData(Buffer.from(buffer), parse);
            console.log(data);
            const {
                routes,
                trips
            } = data;

            if (!routes?.length || !trips?.length) {
                console.log(`The uploaded file is not in Gtfs format or does not contain any routes or services).`)
                messageApi.error(`The uploaded file is not in Gtfs format or does not contain any routes or services).`, 10);
            } else {
                const {
                    routes,
                    stops,
                } = getGtfsDataForOperator(operator, data, null, (operator.gtfs?.exts || []).concat('all-agencies'));
                const serviceCount = routes?.reduce((prev, route) => route.services.length + prev, 0);
                console.log(`Service count: ${serviceCount}`);

                const stopValidation = data.stops.length - stops.length;
                if (stopValidation) {
                    messageApi.warning(`${stopValidation} stops will not be imported.`, 10);
                }
                const serviceValidation = data.trips.length - serviceCount;
                if (serviceValidation) {
                    messageApi.warning(`${serviceValidation} trips will not be imported.`, 10);
                }
                setData(data);
                setServiceCount(serviceCount);
                setFile(file);
            }

            return false;
        },
        multiple: false,
        maxCount: 1,
        showUploadList: false
    };

    return (
        <>
            <Nav.Link onClick={() => setVisible(true)}>{uploading ? <SyncOutlined spin/> : <></>}Import</Nav.Link>
            <Modal
                open={visible}
                width="70%"
                title={`Import Gtfs`}
                onCancel={() => {
                    setFile(null);
                    setUploading(false);
                    setVisible(false);
                }}
                destroyOnClose
                footer={[
                    <Button key="back" className="btn-secondary" onClick={() => {
                        setFile(null);
                        setUploading(false);
                        setVisible(false);
                    }}>
                        {success ? 'Done' : 'Cancel'}
                    </Button>,
                    <Button
                        key={'import'}
                        hidden={success}
                        type="primary"
                        onClick={handleUpload}
                        disabled={!file || success}
                        loading={uploading}
                        style={{
                            marginTop: 16,
                        }}
                    >
                        {uploading ? 'Importing' : 'Start Import'}
                    </Button>,
                ]}
            >
                <div className="Import">
                    <div className="row">
                        <div className="col-lg-12">
                            {file ? (uploading ? <SyncOutlined spin/> : <div>
                                    <p>Click Start Import to import {type === 'gtfs' ? `${serviceCount} services and
                                    ${data?.stops?.length} stops` : 'the TODIS data'} from {file.name} to your region.</p>
                                </div>) :
                                success ?
                                    <div>
                                        <h3>Upload successful</h3>
                                        <p>We successfully uploaded {type === 'gtfs' ? `${data?.trips?.length || 0} services,
                                            and ${data?.stops?.length || 0} stops.` : `${file.name}.`} Please give us a
                                            few minutes to import the data</p>
                                    </div>
                                    : <Dragger {...props}>
                                        <p className="ant-upload-drag-icon">
                                            <InboxOutlined style={{color: '#6B54F9'}}/>
                                        </p>
                                        <p className="ant-upload-text">Drag and drop Gtfs or TODIS file
                                            here</p>
                                        <p>(Or click to browse)</p>
                                        <p className="ant-upload-hint">
                                            The uploaded file must be either Gtfs or TODIS data format
                                        </p>
                                    </Dragger>}
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
}

export default React.memo(GtfsImportModal);
