import React, {useCallback, useEffect, useState} from "react";
import {Button, Checkbox, Modal, Radio, Switch, Typography} from "antd";
import {SyncOutlined, InboxOutlined} from '@ant-design/icons';
import {Table, Tag, Upload} from "antd/lib";
import {toBase64, toUtf8String} from "../../libs/formatLib";
import {stopModelData} from "../../services/ModelService";
import {parse} from "csv-parse/dist/esm/sync";
import {keyBy} from "lodash/collection";
import {convertGtfsStopToBusableStop} from "../../libs/gtfs-lib";
import {isMatch} from "lodash/lang";
import {pick} from "lodash/object";
import {getDistanceInMetres} from "../../libs/routes-lib";
import fileDownload from "js-file-download";
import dayjs from "../../dayjs";
import {toCsv} from "../../libs/exportLib";
import {ReactComponent as Import} from "../../assets/icons/Import.svg";
import {useAppContext} from "../../libs/contextLib";
import {s3Upload, s3UploadDirectly, s3UploadPrivateDirectly} from "../../libs/awsLib";
import LoadMessage from "../../components/LoadMessage";
import {API} from "aws-amplify";
import {ulid} from "ulid";
import {trimStart} from "lodash";

const {Dragger} = Upload;
const {Text} = Typography;

export const importTypes = {
    sharedStops: {
        label: 'Shared bus stops',
        stopType: 'bus',
        shared: true,
        customConverters: {
            stopId: r => r.tsn,
            stopCode: r => r.tsn,
        },
        validate: ({csvTitleRow}) => csvTitleRow.includes('tsn')
            && csvTitleRow.includes('name')
            && csvTitleRow.includes('suburb')
            && csvTitleRow.includes('latitude')
            && csvTitleRow.includes('longitude')
    }, sharedSchool: {
        label: 'Shared school stops',
        stopType: 'school',
        shared: true,
        customConverters: {
            stopId: r => r.acaraid.trim(),
            stopCode: r => r.acaraid.trim(),
        },
        validate: ({csvTitleRow}) => csvTitleRow.includes('acara id')
            && csvTitleRow.includes('school name')
            && csvTitleRow.includes('latitude')
            && csvTitleRow.includes('longitude')
    }, gtfs: {
        label: 'Gtfs',
        validate: ({csvTitleRow}) => csvTitleRow.includes('stop_name') && csvTitleRow.includes('stop_lat') && csvTitleRow.includes('stop_lon')
    }, ts: {
        label: 'Traffic Studio Stops',
        customConverters: {
            stopId: () => ulid(),
            stopName: (gtfsStop, idx) => `Stop ${String(idx).padStart(3, '0')} ${gtfsStop.time.trim()}`
        },
        validate: ({csvTitleRow, firstRow}) => csvTitleRow.includes('vehicle identity') && csvTitleRow.includes('time') && csvTitleRow.includes('longitude') && csvTitleRow.includes('latitude') && firstRow.split(',')[1] === 'door close'
    }, auto: {
        label: 'Automatic',
        customConverters: {
            stopName: (gtfsStop, idx) => gtfsStop[Object.keys(gtfsStop).find(key => key.toLowerCase().includes('name'))].trim() || `Stop ${idx}`
        },
        validate: () => true
    }
}
const importOptions = Object.keys(importTypes).map(key => ({value: key, label: importTypes[key].label}))

function ImportModal({operator, messageApi, btnClassName}) {
    const {isAdmin, email} = useAppContext();
    const [visible, setVisible] = useState(false);

    const [file, setFile] = useState(null);
    const [uploading, setUploading] = useState(false);
    const [newData, setNewData] = useState(null);
    const [updateData, setUpdateData] = useState(null);
    const [missingData, setMissingData] = useState(null);
    const [stops, setStops] = useState(null);
    const [importType, setImportType] = useState('auto')
    const [success, setSuccess] = useState(null)
    const [shared, setShared] = useState(false);

    const getTableColumns = useCallback(() => {

        const allColumns = {
            name: {
                title: 'Stop Name',
                dataIndex: 'stopName',
                key: 'stopName',
                render: (text, record) => record.modified?.includes('stopName') ? <>
                        <Text>{record.oldName}</Text><br/><Text
                        type="success">{text}</Text></> :
                    <Text>{text}</Text>,
                sorter: (a, b) => a.stopName ? a.stopName.localeCompare(b.stopName) : 1,
            },
            stopCode: {
                title: 'Stop Code',
                dataIndex: 'stopCode',
                key: 'stopCode',
                render: (text, record) => record.modified?.includes('stopCode') ?
                    <Text type="success">{text}</Text> : record.stopCode ? <Text>{text}</Text> :
                        <></>,
                sorter: (a, b) => a.stopCode ? a.stopCode.localeCompare(b.stopCode) : 1,
            },
            moved: {
                title: 'Moved (m)',
                dataIndex: 'moved',
                key: 'moved',
                render: (text, record) => record.modified?.includes('lat') || record.modified?.includes('lon') ?
                    <Text
                        type={record.distance < 10 ? "success" : record.distance < 20 ? "warning" : "danger"}>{record.distance}</Text> : <></>,
                sorter: (a, b) => a.distance - b.distance,
            },
            lat: {
                title: 'Latitude',
                dataIndex: 'lat',
                key: 'lat',
            },
            lon: {
                title: 'Longitude',
                dataIndex: 'lon',
                key: 'lon',
            },
            action: {
                title: 'Action',
                key: 'action',
                dataIndex: 'action',
                render: (_, {tags}) => (
                    <>
                        {tags.map((tag) => (
                            <Tag color={tag === 'modify' ? 'green' : tag === 'new' ? 'geekblue' : 'volcano'}
                                 key={tag}>
                                {tag.toUpperCase()}
                            </Tag>
                        ))}
                    </>
                ),
                filters: [
                    {
                        text: 'NEW',
                        value: 'new',
                    },
                    {
                        text: 'MODIFIED',
                        value: 'modify',
                    },
                    {
                        text: 'MISSING',
                        value: 'missing',
                    },
                ],
                onFilter: (value, record) => record.tags[0].indexOf(value) === 0,
                sorter: (a, b) => {
                    // sort by 'new' first, then 'modify', then 'missing'
                    const order = ['new', 'modify', 'missing'];
                    return order.indexOf(b.tags[0].toLowerCase()) - order.indexOf(a.tags[0].toLowerCase());
                },
                defaultFilteredValue: ['new', 'modify'],
                defaultSortOrder: 'descend',
            }
        };

        if (importType === 'gtfs') {
            return [allColumns.name, allColumns.stopCode, allColumns.lat, allColumns.lon, allColumns.moved, allColumns.action];
        } else {
            return [allColumns.name, allColumns.stopCode, allColumns.lat, allColumns.lon, allColumns.moved, allColumns.action];
            // return [allColumns.name, allColumns.lat, allColumns.lon, allColumns.action];
        }

    }, [importType])

    useEffect(() => {
        const listener = stopModelData.addListener({
            setterFn: setStops, loaded: stops => {
                setStops(stops);
            }
        })
        return () => {
            stopModelData.removeListener(listener);
        }
    }, [setStops]);

    const handleUpload = useCallback(() => {
        const upload = async () => {
            try {
                if (newData?.stops?.length) {
                    await stopModelData.save(newData?.stops)
                    setSuccess(success => ({...success, newStops: newData.stops.length}))
                    setNewData(null);
                }
                if (updateData?.stops?.length) {
                    await stopModelData.save(updateData?.stops)
                    setSuccess(success => ({...success, updatedStops: updateData.stops.length}))
                    setUpdateData(null);
                }
                if (missingData?.stops?.length) {
                    // await stopModelData.delete(missingData.stops.map(s => s.stopId), true)
                    // setSuccess(success => ({...success, deletedStops: missingData.stops.length}))
                    setMissingData(null)
                }
            } catch (e) {
                messageApi.error('Really sorry, but there was a problem with the import: ' + e, 10)
                console.log("Import error: " + e, e)
            }

            setUploading(false)

            if (!newData && !updateData && !missingData) {
                setVisible(false)
            }
        }
        if (newData || updateData || missingData) {
            setUploading(true);
            if (importTypes[importType].shared && shared) {

                const filename = `import/master_stops/australia#tfnsw#${importTypes[importType].stopType}/${file.name}`
                s3UploadPrivateDirectly(file, filename).then(() => {
                    messageApi.info('Source system points uploaded. Running point validation checks...', 10)
                }).catch(e => {
                    console.error('Error uploading shared stops', e)
                    messageApi.error('There was a problem uploading the source system points. Please try again.', 10);
                }).finally(() => {
                    setUploading(false);
                    setVisible(false);
                    setFile(null);
                });


                // toBase64(file, true).then(buffer => {
                //     API.put("third_party", `/sharedStops`, {
                //         body: {filename: file.name, authorityId: encodeURIComponent('australia#tfnsw#bus'), buffer}
                //     }).then(response => {
                //         console.log('response', response);
                //         if (response.success) {
                //             messageApi.info('Shared stops uploaded successfully. Please allow 10 minutes for the import to finish.', 10);
                //         } else {
                //             messageApi.error('There was a problem uploading the shared stops. Please try again.', 10);
                //         }
                //         setUploading(false);
                //
                //     })
                // })
            } else {
                upload().then(() => {
                    console.log('imported')
                    setVisible(false);
                });
            }
        }
    }, [newData, updateData, missingData, setNewData, setUpdateData, setMissingData, setVisible, messageApi, importType, shared, file, setFile]);

    const props = {
        beforeUpload: async (file) => {
            try {
                setFile(file);
                const csv = await toUtf8String(file)
                let newImportType = Object.keys(importTypes).find(key => importTypes[key].validate({
                    file,
                    csv,
                    firstRow: csv.split('\n')[1].toLowerCase(),
                    csvTitleRow: csv.split('\n')[0].toLowerCase()
                }))
                setImportType(newImportType)

                let zippedStops = parse(csv, {
                    columns: true,
                    skip_empty_lines: true
                }).map((gtfsStop, idx) => convertGtfsStopToBusableStop(gtfsStop, {
                    operator,
                    overrideId: true,
                    idx,
                    customConverters: importTypes[newImportType].customConverters
                }));

                if (importTypes[newImportType].shared && shared) {
                    setNewData({stops: zippedStops})
                    return false
                }

                zippedStops = zippedStops.filter(s => s.lat && s.lon);
                const newStops = []
                const updatedStops = []
                const missingStops = []

                const comparableFields = ['stopCode', 'lat', 'lon', 'stopName'];
                zippedStops.forEach(zipped => {
                    const newData = pick(zipped, comparableFields)
                    const existing = stops[zipped.stopId]
                    if (!existing) {
                        newStops.push(zipped);
                        return
                    }
                    existing.stopCode = existing.stopCode || ''
                    if (!isMatch(pick(existing, comparableFields), newData)) {
                        let distance;
                        const modified = comparableFields.filter(field => {
                            if (['lat', 'lon'].includes(field)) {
                                const locationChanged = existing[field].toFixed(6) !== newData[field].toFixed(6)
                                if (locationChanged) {
                                    distance = getDistanceInMetres(existing, newData)
                                }
                                return locationChanged
                            }
                            return existing[field] !== zipped[field];
                        })

                        if (modified.length) {
                            if (zipped.verified === 1 && existing.verified !== 1) {
                                existing.verified = 1;
                            }
                            updatedStops.push({
                                ...existing, ...pick(zipped, comparableFields),
                                modified,
                                distance,
                                oldName: modified.includes('stopName') ? existing.stopName : undefined
                            });
                        }
                    }
                })
                const zippedStopsById = keyBy(zippedStops, "stopId")
                Object.keys(stops).filter(stopId => (!stops[stopId].stopType || stops[stopId].stopType === 'bus') && stops[stopId].verified === 1).forEach(stopId => {
                    if (!zippedStopsById[stopId]) {
                        missingStops.push(stops[stopId]);
                    }
                })
                setNewData({stops: newStops})
                setUpdateData({stops: updatedStops})
                setMissingData({stops: missingStops})
            } catch (e) {
                messageApi.error("There was a problem reading " + file.name + ". Error: " + e, 10)
                console.log("There was a problem reading " + file.name + ": ", e)
            }
            return false;
        },
        multiple: false,
        maxCount: 1,
        showUploadList: false
    };

    const getStopsContent = useCallback(() => {
        return missingData.stops.map(stop => {
            return {
                key: stop.stopId,
                tags: ['missing'],
                location: `${stop.lat}, ${stop.lon}`,
                ...stop,
            }
        }).concat(newData.stops.map(stop => {
            return {
                key: stop.stopId + stop.stopName + stop.lat + stop.lon,
                tags: ['new'],
                location: `${stop.lat}, ${stop.lon}`,
                ...stop,
            }
        })).concat(updateData.stops.map(stop => {
            return {
                key: stop.stopId,
                tags: ['modify'],
                location: `${stop.lat}, ${stop.lon}`,
                ...stop,
            }
        }))

    }, [newData, updateData, missingData])

    const downloadPreImport = useCallback(async () => {
        let data = []
        if (!newData || !updateData || !missingData) {
            return
        }
        const convert = ({existing = null, stop, action}) => ({
            stop_id: existing?.stopId,
            new_stop_id: stop?.stopId,
            stop_name: existing?.stopName,
            new_stop_name: stop?.stopName,
            stop_code: existing?.stopCode,
            new_stop_code: stop?.stopCode,
            stop_lat: existing?.lat,
            new_stop_lat: stop?.lat,
            stop_lon: existing?.lon,
            new_stop_lon: stop?.lon,
            moved: existing && stop ? getDistanceInMetres(existing, stop) : '',
            action
        })
        data = data.concat(newData.stops.map(stop => convert({stop, action: 'NEW'})))
            .concat(updateData.stops.map(stop => convert({existing: stops[stop.stopId], stop, action: 'UPDATE'})))
            .concat(missingData.stops.map(stop => convert({existing: stop, action: 'MISSING'})))

        fileDownload(Buffer.from(await toCsv(data)), `Busable_${operator.operatorId}_PreImport_${dayjs().format("YYYY-MM-DD_HH:mm")}.csv`, 'text/csv');

    }, [newData, updateData, missingData, stops, operator])

    return (
        <>
            <Button onClick={() => setVisible(true)} type='primary' icon={<Import className={'mr-2'}/>}
                    className={btnClassName}>{uploading && <SyncOutlined spin/>} Import</Button>
            <Modal
                open={visible}
                width="70%"
                title={`Import Points`}
                onCancel={() => {
                    setFile(null)
                    setUploading(false)
                    setVisible(false)
                    setNewData(null)
                    setUpdateData(null)
                    setMissingData(null)
                    setSuccess(null);
                    setImportType('auto')
                }}
                destroyOnClose
                footer={[
                    <Button key="download" type="dashed"
                            disabled={!newData || !updateData || !missingData}
                            onClick={() => downloadPreImport()}>
                        Download
                    </Button>,
                    <Button key="back" className="btn-secondary" onClick={() => {
                        setFile(null)
                        setUploading(false)
                        setVisible(false)
                        setNewData(null)
                        setUpdateData(null)
                        setMissingData(null)
                        setSuccess(null);
                        setImportType('auto')
                    }}>
                        {success ? 'Done' : 'Cancel'}
                    </Button>,
                    <Button
                        key={"import"}
                        hidden={success}
                        type="primary"
                        onClick={handleUpload}
                        disabled={!file || success}
                        loading={uploading}
                        style={{
                            marginTop: 16,
                        }}
                    >
                        {uploading ? 'Importing' : 'Start Import'}
                    </Button>,
                ]}
            >
                <div className="Import">
                    <>
                        {stops ?
                            <>
                                {isAdmin || email === 'nikhil.sharma@transport.nsw.gov.au' ?
                                    <div className="row">
                                        <div className="col-lg-12 mb-2">
                                            <span>Target Database: </span>
                                            <Switch checked={shared} onChange={setShared}
                                                    checkedChildren={'Point Layers'}
                                                    unCheckedChildren={operator.operatorName}/>
                                            <div className="mt-2"> ⚠️ WARNING: Importing to 'Point Layers' updates
                                                point layer data for all Busable users.
                                            </div>
                                        </div>

                                    </div> : <></>
                                }
                                <div className="row">
                                    <div className="col-lg-12 mb-2">
                                        {importType?.length &&
                                            <Text strong={true}>{importTypes[importType].label} import</Text>
                                        }
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-12">
                                        {file && newData && updateData && missingData ? (
                                            <>
                                                <Table columns={getTableColumns()} dataSource={getStopsContent()}
                                                       bordered
                                                       rowClassName={(record) => record.tags?.includes('modify') ? 'RowModified' : ''}
                                                       className="DataTable mt-2"/>
                                            </>
                                        ) : file ? uploading ? <SyncOutlined spin/> : <div>
                                                <p>Click Start Import to import {file.name} to the {importTypes[importType].label}&nbsp;
                                                    database.</p>
                                            </div> :
                                            success ?
                                                <div>
                                                    <h3>Import successful</h3>
                                                    <p>You successfully imported {success.newStops || 0} new points,
                                                        and updated {success.updatedStops || 0}.</p>
                                                </div>
                                                : <Dragger {...props}>
                                                    <p className="ant-upload-drag-icon">
                                                        <InboxOutlined style={{color: '#6B54F9'}}/>
                                                    </p>
                                                    <p className="ant-upload-text">Drag and drop stop file here</p>
                                                    <p>(Or click to browse)</p>
                                                    <p className="ant-upload-hint">
                                                        The uploaded file must be a CSV file and should conform to GTFS stop
                                                        data format (<a
                                                        href="https://learn.busable.app/p/c08742ab-cc77-40de-97ce-46e235f14c1c/"
                                                        rel="noreferrer" target="_blank">learn more</a>)
                                                    </p>
                                                </Dragger>}
                                    </div>
                                </div>
                            </>
                            : <LoadMessage message={"Loading points..."} size={"lg"}/>
                        }
                    </>
                </div>
            </Modal>
        </>
    );
}

export default React.memo(ImportModal);
