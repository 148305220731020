import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {useAppContext} from '../libs/contextLib';
import {useTimeTravelState} from '../libs/useTimeTravelState';
import {noop} from 'lodash';

function useModel({
                      withId,
                      initialInstance,
                      modelService,
                      maxSize = 10,
                      edit = false,
                      enforce = true,
                      onSave = noop,
                      editOnLoad = noop
                  }) {
    const history = useHistory();
    const initialModelRef = useRef(initialInstance);
    const {apiKey, isAuthenticated} = useAppContext();
    const [mode, setMode] = useState({edit});
    const {[modelService.idName]: paramId} = useParams();
    const [model, setModel, controls] = useTimeTravelState(initialInstance, {maxSize});

    const id = withId || paramId;

    useEffect(() => {
        const load = async () => {

            if (!isAuthenticated) {
                console.log('NOT AUTHENTICATED');
                history.push('/');
                return;
            }

            if (!id && enforce) {
                console.log('NO ID!');
                history.push(`/${modelService.path}`);
                return;
            }

            let model = id !== '_new_' ? (await modelService.get(apiKey, id)) : initialInstance;
            if (id === '_new_') {
                setMode({edit: true});
            }
            console.log('Loaded model: ', model);
            if (!model && enforce) {
                history.push(modelService.path);
            } else if (!model) {
                model = modelService.create(initialInstance);
                modelService.setId(model);
            }

            if (editOnLoad(model)) {
                setMode({edit: true});
            }
            controls.reset(model);
            initialModelRef.current = model;
        };

        load().then(() => console.log('Model loaded.'));
    }, [id, enforce, apiKey, setModel, initialModelRef, isAuthenticated, history, editOnLoad]);

    const save = useCallback(() => {
        modelService.save(model).then(() => {
            console.log('Saved model.');
            onSave(model);
        }).catch(e => {
            console.log(e);
        });
    }, [apiKey, model, modelService]);

    const del = useCallback(() => {
        modelService.delete(modelService.getId(model)).then(() => {
            console.log('Saved model.');
        }).catch(e => {
            console.log(e);
        });
    }, [apiKey, model, modelService]);

    const returnValue = useMemo(() => {
        return {
            model,
            setModel,
            controls, initialModelRef,
            mode, setMode,
            save, del
        };
    }, [model,
        setModel,
        controls, initialModelRef,
        mode, setMode, save, del]);
    return returnValue;
}

export default useModel;
