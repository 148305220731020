import React, {useCallback, useEffect, useState} from "react";
import {Slider} from "antd";
import useMapControl from "react-map-gl/dist/esm/components/use-map-control";
import {CaretLeftOutlined, CaretRightOutlined} from "@ant-design/icons";
import {last} from "lodash/array";
import {ButtonGroup, Button} from "react-bootstrap";
import {ReactComponent as Close} from "../assets/icons/Close.svg";
import {secsSinceMidnightToDayjs} from "../model/timeFilter";

function MapWpSelectorToolbar({
                                  style,
                                  wpIdxsInView,
                                  routes, trips,
                                  setSelectedStartWpIdx,
                                  setSelectedEndWpIdx,
                                  selectedStartWpIdx,
                                  selectedEndWpIdx,
                                  single
                              }) {

    const [wpIdx, setWpIdx] = useState([0, 0]);
    const [wpTimes, setWpTimes] = useState(null);
    const {containerRef} = useMapControl({
        captureClick: true,
        captureDrag: true,
        captureScroll: true,
        captureDoubleClick: true,
        capturePointerMove: true
    });

    useEffect(() => {
        if (!routes?.length || !trips?.length) {
            return
        }
        const actualRoutes = routes.filter(r => !!r);
        const actualTrips = trips.filter(t => !!t);
        if (actualRoutes.length !== actualTrips.length) {
            return;
        }
        let wpTimes = []
        actualRoutes.forEach((r, i) => {
            const trip = actualTrips[i]
            const wpPassingTimes = r.getWpPassingTimesForTrip(trip)
            wpTimes = wpTimes.concat(wpPassingTimes.map(pt => {
                return pt ? (secsSinceMidnightToDayjs(pt)?.format(("HH:mm")) || "?") : "?"
            }));
        })
        setWpTimes(wpTimes)
    }, [wpIdxsInView, routes, trips, setWpTimes])

    useEffect(() => {
        if (wpIdxsInView) {
            setWpIdx([wpIdxsInView.findIndex(idx => (selectedStartWpIdx === -1 ? 0 : selectedStartWpIdx) === idx),
                wpIdxsInView.findIndex(idx => (selectedEndWpIdx === -1 ? 0 : selectedEndWpIdx) === idx)]);
        }
    }, [wpIdxsInView, selectedStartWpIdx, selectedEndWpIdx, setWpIdx])

    const formatter = useCallback((index) => {
        const wpIndexInView = wpIdxsInView[index]
        if (wpTimes) {
            return wpTimes[wpIndexInView]
        } else {
            return wpIndexInView
        }
    }, [wpIdxsInView, wpTimes])

    return (
        <div className="d-flex flex-row map-wp-selector" style={{...style}} ref={containerRef}>
            <Slider
                range={single ? false : {draggableTrack: true}}
                min={0}
                max={(wpIdxsInView?.length || 2) - 1}
                step={parseInt(wpIdxsInView?.length * 0.005)}
                value={wpIdx}
                tooltip={{formatter}}
                onChange={(value) => {
                    setWpIdx(value)
                    if (single) {
                        setSelectedStartWpIdx(wpIdxsInView[value]);
                        setSelectedEndWpIdx(wpIdxsInView[value])
                    } else {
                        setSelectedStartWpIdx(wpIdxsInView[value[0]]);
                        setSelectedEndWpIdx(wpIdxsInView[value[1]])
                    }
                }}
            />
            <ButtonGroup className="map-wp-selector-actions" size="sm">
                <Button onClick={() => {
                    if (single && selectedStartWpIdx > 0) {
                        setSelectedStartWpIdx(selectedStartWpIdx - parseInt(wpIdxsInView.length * 0.005));
                        setSelectedEndWpIdx(selectedEndWpIdx - parseInt(wpIdxsInView.length * 0.005));
                    } else if (selectedStartWpIdx === selectedEndWpIdx && selectedStartWpIdx === last(wpIdxsInView)) {
                        setSelectedStartWpIdx(wpIdxsInView[0]);
                        setSelectedEndWpIdx(wpIdxsInView[0]);
                    } else if (selectedEndWpIdx > selectedStartWpIdx) {
                        setSelectedEndWpIdx(selectedEndWpIdx - 1);
                    } else if (selectedStartWpIdx > 0 && selectedEndWpIdx === selectedStartWpIdx) {
                        setSelectedStartWpIdx(selectedStartWpIdx - 1);
                        setSelectedEndWpIdx(selectedEndWpIdx - 1);
                    }
                }}><CaretLeftOutlined/></Button>
                <Button onClick={() => {

                    if (single && selectedEndWpIdx < last(wpIdxsInView) - 1) {
                        setSelectedStartWpIdx(selectedStartWpIdx + parseInt(wpIdxsInView.length * 0.005));
                        setSelectedEndWpIdx(selectedEndWpIdx + parseInt(wpIdxsInView.length * 0.005));
                    } else if (selectedStartWpIdx === selectedEndWpIdx && (selectedStartWpIdx === wpIdxsInView[0] || selectedStartWpIdx === -1)) {
                        setSelectedStartWpIdx(last(wpIdxsInView));
                        setSelectedEndWpIdx(last(wpIdxsInView));
                    } else if (selectedEndWpIdx < last(wpIdxsInView) - 1) {
                        setSelectedEndWpIdx(selectedEndWpIdx + 1);
                    }
                }}><CaretRightOutlined/></Button>
                <Button className="actio-btn-close" onClick={() => {
                    setSelectedStartWpIdx(-1)
                    setSelectedEndWpIdx(-1)
                }}><Close/></Button>
            </ButtonGroup>
        </div>
    )
}

export default React.memo(MapWpSelectorToolbar)
