import React, {useEffect, useState} from 'react';
import './Charters.css';
import LoadMessage from '../../components/LoadMessage';
import {useAppContext} from '../../libs/contextLib';
import useAllCharters from './useAllCharters';
import {debounce, values} from 'lodash';
import {filterFn, FilterSwitches} from '../../containers/FilterTag';
import {Button, Card, Col, Input, Row} from 'antd';
import {PlusOutlined} from '@ant-design/icons';
import {ReactComponent as BusPlus} from '../../assets/icons/BusPlus.svg';
import {ReactComponent as BusQuote} from '../../assets/icons/BusQuote.svg';
import {ReactComponent as BusTick} from '../../assets/icons/BusTick.svg';
import {LinkContainer} from 'react-router-bootstrap';
import CharterList from './CharterList';

const {Search} = Input;

export const filterItems = {
    new: {
        name: 'new',
        color: 'processing',
        showFn: (r) => r.status === 'new',
    },
    sent: {
        name: 'Quote Sent',
        color: 'success',
        showFn: (r) => r.status === 'sent',
    },
    accepted: {
        name: 'Accepted',
        color: 'success',
        showFn: (r) => r.status === 'accepted',
    },
    scheduled: {
        name: 'Scheduled',
        color: 'warning',
        showFn: (r) => r.status === 'scheduled',
    },
    completed: {
        name: 'Completed',
        color: 'success',
        showFn: (r) => r.status === 'completed',
    },
    cancelled: {
        name: 'Cancelled',
        color: 'success',
        showFn: (r) => r.status === 'cancelled',
    },
};

const nameSortFn = (a, b) => {
    if (!a.name?.length || !b.name?.length) {
        return 1;
    }
    return a.name.localeCompare(b.name);
};

function Charters() {
    const {operator} = useAppContext();
    const {allCharters: charters} = useAllCharters();
    const [filteredCharters, setFilteredCharters] = useState([]);
    const [filterBy, setFilterBy] = useState([]);
    const [filter, setFilter] = useState('');
    const setFilterDebounced = debounce(setFilter, 250);

    useEffect(() => {
        if (!charters || !values(charters).length) {
            setFilteredCharters([]);
        }

        let charterList = values(charters).filter(charter => charter.type === 'charter#detail');
        if (filter) {
            const _filter = filter.toLowerCase();
            charterList = charterList.filter(
                (r) =>
                    (r.name && r.name.toLowerCase().indexOf(_filter) > -1) ||
                    (r.requested_by && r.requested_by.toLowerCase().indexOf(_filter) > -1)
            );
        }
        setFilteredCharters(filterFn(charterList, filterItems, filterBy, 'charterId', nameSortFn));
    }, [charters, setFilteredCharters, filterBy, filter]);

    const getStatusCount = (status) => {
        return values(charters)?.filter((c) => c.status === status).length ?? 0;
    };

    return (
        <>
            {charters && filteredCharters ? (
                <div className="charters-list">
                    <Row gutter={[20, 20]}>
                        <Col xs={24} lg={8}>
                            <Card bordered={false} className="card-main card-stats card-stats-flex">
                                <BusPlus/>
                                {/*<div>*/}
                                {/*    <h4>Overdue Enquiries</h4>*/}
                                {/*    <div className="text-lg">{getStatusCount('new')}</div>*/}
                                {/*</div>*/}

                                <div>
                                    <h4>New Inquiries</h4>
                                    <div className="text-lg">{getStatusCount('new')}</div>
                                </div>
                            </Card>
                        </Col>
                        <Col xs={24} lg={8}>
                            <Card bordered={false} className="card-main card-stats card-stats-flex">
                                <BusQuote/>
                                <div>
                                    <h4>Quote Sent</h4>
                                    <div className="text-lg">{getStatusCount('sent')}</div>
                                </div>
                            </Card>
                        </Col>
                        <Col xs={24} lg={8}>
                            <Card bordered={false} className="card-main card-stats card-stats-flex">
                                <BusTick/>
                                <div>
                                    <h4>Quote Accepted</h4>
                                    <div className="text-lg">{getStatusCount('accepted')}</div>
                                </div>
                            </Card>
                        </Col>
                        {/* <Col xs={24} lg={6}>
                            <Card bordered={false} className="card-main card-stats">
                                <Row>
                                    <Col className="d-flex align-items-center">
                                        <Bus />
                                    </Col>
                                    <Col>
                                        <div>
                                            <h4>Cancelled</h4>
                                        </div>
                                        <div className="text-lg">{getStatusCount('cancelled')}</div>
                                    </Col>
                                </Row>
                            </Card>
                        </Col> */}
                    </Row>
                    <div className="d-flex align-items-center justify-content-between filter-options-main mt-4">
                        <div className="d-flex align-items-center" style={{gap: '10px'}}>
                            <LinkContainer to="/charters/_new_">
                                <Button type="primary" className="icon-button" icon={<PlusOutlined/>}>
                                    Add Charter
                                </Button>
                            </LinkContainer>
                            {/* <ImportStudents operator={operator} messageApi={messageApi}
                                            existingStudents={keyBy(values(students), 'authorityId')}
                                            setImportedStudents={setImportedStudents}/> */}
                        </div>
                        <div className="d-flex align-items-center">
                            <div className="justify-content-end w-separator">
                                <Search
                                    id="basic-addon1"
                                    style={{
                                        width: 200,
                                    }}
                                    allowClear={true}
                                    onChange={(e) => setFilterDebounced(e.target.value)}
                                />
                            </div>
                            <div className="filter-switches">
                                <FilterSwitches filterItems={filterItems} filterBy={filterBy}
                                                setFilterBy={setFilterBy}/>
                            </div>
                        </div>
                    </div>
                    <CharterList charters={filteredCharters}/>
                </div>
            ) : (
                <LoadMessage message="Loading charters..."/>
            )}
        </>
    );
}

export default React.memo(Charters);
