import {Button, Modal} from 'antd';
import React, {useCallback, useMemo, useState} from 'react';
import {CustomerDetailsCard} from './CustomerDetails';
import {customerModelData} from '../../services/ModelService';
import {Customer} from '../../model/customer';

export default function CustomerDetailsModal({visible, closeDisplay, onNewCustomer}) {

    const mode = useMemo(() => ({edit: true}), []);
    const [customer, setCustomer] = useState(new Customer({customerId: '_'}));
    const [isLoading, setIsLoading] = useState(false);

    const setCustomerWith = useCallback(
        (propName, value) => {
            if (!Array.isArray(propName)) {
                propName = [propName];
                value = [value];
            }
            setCustomer(customer => {
                const newCustomer = {...customer};
                propName.forEach((prop, idx) => {
                    if (value[idx] === undefined || value[idx] === null) {
                        delete newCustomer[prop];
                        return;
                    }
                    newCustomer[prop] = value[idx];
                });
                return newCustomer;
            });
        },
        [setCustomer]
    );

    return (
        <Modal
            open={visible}
            title={`New customer`}
            onCancel={closeDisplay}
            destroyOnClose
            centered
            width={800}
            footer={[
                <Button className="btn-secondary" key="back" onClick={closeDisplay}>
                    Cancel
                </Button>,
                <Button className="btn-primary" key="submit" type="primary" loading={isLoading} onClick={async () => {
                    setIsLoading(true);
                    await customerModelData.save(customer);
                    console.log('customer saved');
                    onNewCustomer(customer);
                    setIsLoading(false);
                }}
                        disabled={!customer?.email || !customer?.name}>
                    Submit
                </Button>,
            ]}
        >
            <CustomerDetailsCard mode={mode} customer={customer}
                                 setCustomerWith={setCustomerWith}/>
        </Modal>
    );
}