import { ulid } from "ulid";
import {BusRoute} from "./busRoute";
import {cloneDeep} from "lodash";

export class Deadrun extends BusRoute {
    constructor(data) {
        super(data)
        this.startStop = null;
        this.endStop = null;
        Object.assign(this, data)
        // this.stops = [this.startStop, this.endStop];
        // this.stopTimes = [this.startStop, this.endStop];
        this.startStopId = this.startStopId || (this.startStop ? this.startStop.stopId : null)
        this.endStopId = this.endStopId || (this.endStop ? this.endStop.stopId : null)
        this.deadrun = true
    }

    clone(field, value) {
        const newRoute = super.clone(field, value)
        newRoute.routes = cloneDeep(this.routes)
        return new Deadrun(newRoute);
    }

}



export class CharterRouteRun extends Deadrun {
    constructor(props) {
        super(props)
        this.charterId = ulid();
        this.type = 'charter#route'
        Object.assign(this, props);

        this.startStopId = this.startStopId || (this.startStop ? this.startStop.stopId : null)
        this.endStopId = this.endStopId || (this.endStop ? this.endStop.stopId : null)
        this.deadrun = true

        this.routeId = this.createId()
    }

    createId() {
        return `${this.startStopId}#${this.endStopId}#`;
        // return `${this.startStopId}#${this.endStopId}#${
        //     this.routeName?.length ? this.routeName : ""
        // }`;
    }
}