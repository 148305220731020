import React from "react";
import {ReactComponent as Break} from "../../assets/icons/Break.svg";
import {ShiftBatRowNotePriority, ShiftBatRowType} from "../../model/shiftBat";
import {ReactComponent as DeadRunning} from "../../assets/icons/DeadRunning.svg";
import {ReactComponent as Warning} from '../../assets/icons/Warning.svg';
import "./ShiftBat.css";
import RouteNumber from "../../components/RouteNumber";
import {SyncOutlined} from "@ant-design/icons";
import {uniq} from "lodash/array";
import {useDroppable} from "@dnd-kit/core";
import {toHrsMinsSecs, toKmMs} from "../../libs/formatLib";
import ShiftBatStopRowPrint from "./ShiftBatStopRowPrint";

function ShiftBatRowPrint({
                              shiftBatRow,
                              editMode, printMode,
                              viewing,
                              updating,
                              setViewing,
                              setSelectedStop, setHighlightedRouteIds,
                              setFocusStop, selectedStop,
                              onSave
                          }) {

    const {isOver, setNodeRef} = useDroppable({
        id: shiftBatRow.id,
    });
    const style = {
        color: isOver ? 'green' : undefined,
    };

    return (
        <div ref={setNodeRef} style={style}
             key={shiftBatRow.id}
             className={`sb-row ${shiftBatRow?.priority === ShiftBatRowNotePriority.high ? "sb-important" : ""} ${shiftBatRow.type === ShiftBatRowType.service ? "sb-row-service" : ""}`}>
            <div className={`row d-flex align-items-center ml-0 mr-0`}>
                <div
                    className="col-1 sb-time">{!shiftBatRow.hideTime && shiftBatRow.type !== ShiftBatRowType.dead && Number.isFinite(shiftBatRow.time) &&
                    <span>{updating ?
                        <SyncOutlined spin={true}/> : shiftBatRow.toTimeString(true)}</span>}</div>
                <div className="col-2 sb-icon">
                    <div className={`RouteIcon`}>
                        {shiftBatRow.type !== ShiftBatRowType.note && shiftBatRow.type !== ShiftBatRowType.stop &&
                        [ShiftBatRowType.break, ShiftBatRowType.breakMeal, ShiftBatRowType.breakBroken].includes(shiftBatRow.type) ?
                            <Break/> : shiftBatRow.type === ShiftBatRowType.dead ?
                                <DeadRunning/> : shiftBatRow.type === ShiftBatRowType.service ?
                                    <RouteNumber route={shiftBatRow.route}/> : <></>}
                        {shiftBatRow?.priority === ShiftBatRowNotePriority.high ? <Warning/> : ""}
                    </div>
                </div>
                <div className="col-9 d-flex RouteTitle align-items-center sb-info" style={{cursor: 'pointer'}}
                     onClick={() => {
                         if (printMode) {
                             return
                         }
                         if (printMode) {
                             return
                         }
                         if (shiftBatRow.type === ShiftBatRowType.service) {
                             setViewing(viewing => ({...viewing, [shiftBatRow.id]: !viewing[shiftBatRow.id]}));
                         }
                         setFocusStop(null);
                     }}>

                    <div className={`RouteInfo`}>
                        <div className="row align-items-stretch justify-content-center">
                            <div className="col-5 d-flex flex-column justify-content-center">
                                <div className="d-flex align-items-center service-title-wrap">
                                    {shiftBatRow.type === ShiftBatRowType.service ?
                                        <div className={`RouteIcon`}><RouteNumber route={shiftBatRow.route}/>
                                        </div> : <></>}
                                    {shiftBatRow?.priority === ShiftBatRowNotePriority.high ?
                                        <Warning className="sb-row-warning"/> : ""}
                                    <h4 className="title">
                                        {shiftBatRow.type !== ShiftBatRowType.service && shiftBatRow.title}{shiftBatRow.type === ShiftBatRowType.dead && shiftBatRow.route ? ` (${toHrsMinsSecs(shiftBatRow.route.duration, false, true, 1)}, ${toKmMs(shiftBatRow.route.distance)})` : <></>}
                                        {shiftBatRow.type === ShiftBatRowType.service ? shiftBatRow.title : <></>}
                                    </h4>
                                </div>
                                {shiftBatRow.type === ShiftBatRowType.service ?
                                    <div className="service-desc-wrap">{shiftBatRow.route.routeDetails}</div> : <></>}
                            </div>
                            <div className="col-7 d-flex align-items-center flex-wrap">{shiftBatRow.description &&
                                <span className={`RouteDetails`}>{shiftBatRow.description}</span>}</div>
                        </div>
                    </div>
                </div>
            </div>
            {shiftBatRow.type === ShiftBatRowType.service && viewing[shiftBatRow.id] &&
                // <ShiftBatServiceRow key={'SBServR' + shiftBatRow.id} shiftBatRow={shiftBatRow} editMode={editMode}
                //                     onSave={onUpdate} setSelectedStop={setSelectedStop} printMode={printMode}
                //                     selectedStop={selectedStop} setFocusStop={setFocusStop}/>

                <div className="col-lg-12 sb-row-routes">
                    {shiftBatRow.getStopRows().map(shiftBatStopRow => (<div key={'SBSR' + shiftBatRow.id}>
                        <ShiftBatStopRowPrint route={shiftBatRow.route} shiftBatStopRow={shiftBatStopRow}
                                              setSelectedStop={setSelectedStop}
                                              setFocusStop={setFocusStop} selectedStop={selectedStop}
                                              printMode={printMode}
                                              editMode={editMode} onSave={(e) => onSave(e, shiftBatRow)}/>
                    </div>))}
                </div>}
            {shiftBatRow.type === ShiftBatRowType.service ?
                <div className="service-trip-bar" style={{backgroundColor: shiftBatRow.route.colour}}></div> : ''}
        </div>
    );

}

export default React.memo(ShiftBatRowPrint)
