import React from "react";
import {Select} from "antd";
import {ReactComponent as Down} from "../../assets/icons/Down.svg";
import {SelectableShiftBatRowTypes, ShiftBatRowType, ShiftBatRowTypeLabel} from "../../model/shiftBat";
// import {Tooltip} from "antd/lib";

function ShiftBatSelectType({
                                type,
                                resetRow,
                                ttPlacement = 'left',
                                ...props
                            }) {

    return (
        // <Tooltip title="Change row type" placement={ttPlacement}>
            <Select size="small" className="inputselect-small"
                    suffixIcon={<Down/>} value={type || ShiftBatRowType.note}
                    disabled={type === ShiftBatRowType.dead}
                    onChange={type => {
                        resetRow(type)
                    }}
                    {...props}
            >
                {SelectableShiftBatRowTypes.map(type => (
                    <Select.Option key={'SBRT-' + type}
                                   value={ShiftBatRowType[type]}>{ShiftBatRowTypeLabel[type]}</Select.Option>)
                )}
            </Select>
        // </Tooltip>
    );

}

export default React.memo(ShiftBatSelectType)
