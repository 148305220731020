import React, {useCallback, useEffect, useMemo, useState} from 'react';
import './Charters.css';
import {charterModelData, deadrunModelData, shiftBatModelData} from '../../services/ModelService';
import {Charter} from '../../model/charter';
import useModel from '../../hooks/useModel';
import SaveMenu from '../../components/SaveMenu';
import LoadMessage from '../../components/LoadMessage';
import {Button, Col, Row, Tabs} from 'antd';
import CharterMenu from './CharterMenu';
import {ShiftBat, ShiftBatRowType} from '../../model/shiftBat';
import useAllStops from '../../hooks/useAllStops';
import {differenceWith, find, keyBy, last, values} from 'lodash';
import EditShiftBat from '../ShiftBat/EditShiftBat';
import useAllTransfers from '../../hooks/useAllTransfers';
import {arrayMove, horizontalListSortingStrategy, SortableContext, useSortable} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';
import {closestCenter, DndContext, PointerSensor, useSensor} from '@dnd-kit/core';
import {PlusOutlined} from '@ant-design/icons';
import {toKmMs} from '../../libs/formatLib';
import {ulid} from 'ulid';
import {createAcronym} from './CharterItinerary';
import {ReactComponent as Close} from '../../assets/icons/Close.svg';
import {ReactComponent as Print} from '../../assets/icons/Print.svg';
import dayjs from '../../dayjs';
import {DATE_DAY_STRING} from '../../model/busRoute';
import useAllSchedules from '../../hooks/useAllSchedules';
import ShiftBatPrintModal from '../ShiftBat/ShiftBatPrintModal';
import ShareModal from '../../components/ShareModal';
import {useAppContext} from '../../libs/contextLib';
import useAllRoutes from '../../hooks/useAllRoutes';

const editOnLoad = charter => !charter?.duties?.length;

function CharterDuty({initialInstance}) {
    const {allStops, allDepots, setAllStops} = useAllStops();
    const {allTransfers} = useAllTransfers();
    const {allSchedules} = useAllSchedules();
    const {allRoutes} = useAllRoutes();
    const [printShiftBat, setPrintShiftBat] = useState(false);
    const {apiKey} = useAppContext();
    const {
        model: charter,
        setModel: setCharter,
        initialModelRef: initialCharterRef,
        mode,
        setMode,
        controls,
    } = useModel({
        modelService: charterModelData,
        initialInstance: initialInstance || new Charter(),
        editOnLoad
    });

    const [selectedShiftId, setSelectedShiftId] = useState(charter?.duties?.[0]?.shiftBatId);
    const [routes, setRoutes] = useState(null);

    useEffect(() => {
        if (charter.duties?.length) {
            setSelectedShiftId(id => {
                return id?.length ? id : charter.duties[0].shiftBatId;
            });
        }
    }, [charter.duties, setSelectedShiftId]);

    const missingDates = useCallback(() => {
        if (!allSchedules) {
            return [];
        }
        let missingDates = [];
        charter.itinerary.forEach(route => {
            route.services.forEach(trip => {
                const dutyForTrip = charter.duties.find(duty => duty.rows.filter(r => r.type === ShiftBatRowType.service).find(row => row.tripId === trip.tripId));
                const legDates = trip.getRunningDates(allSchedules);
                const dutyDates = dutyForTrip?.getRunningDates(allSchedules) || [];
                let missing = differenceWith(legDates, dutyDates, (date1, date2) => dayjs(date1).isSame(dayjs(date2), 'day'));
                if (missing?.length) {
                    missingDates = missingDates.concat(missing.map(date => ('Charter leg ' + route.routeNumber + ' : ' + trip.getStartTime() + ' @ ' + date.format(DATE_DAY_STRING))));
                }
            });
        });
        return missingDates;
    }, [charter.itinerary, charter.duties, allSchedules]);

    const newShift = (charter, shiftNum = 1) => {
        const shift = new ShiftBat({
            shiftBatId: ulid(),
            shiftBatNumber: createAcronym(charter.name) + '-' + shiftNum,
            shiftBatName: 'Leg ' + shiftNum,
            shiftBatDetails: charter.name,
            published: 1,
            charter: true
        });
        return shift;
    };

    useEffect(() => {
        if (!allStops || !allDepots) {
            return;
        }
        if (charter?.itinerary?.length && !charter.duties?.length) {
            ShiftBat.fromCharterItinerary({
                charter,
                depot: values(allDepots)?.[0],
                allStops, allRoutes, allTransfers,
                deadrunModelData
            }).then(duty => {
                setCharter(charter => new Charter({...charter, duties: [duty]}));
                setMode({edit: true});
                setSelectedShiftId(duty.shiftBatId);
            });
        }
    }, [charter.itinerary, charter.duties, allStops, allDepots, setCharter, setMode]);

    useEffect(() => {
        setRoutes(keyBy(charter.itinerary, 'routeId'));
    }, [charter.itinerary, setRoutes]);

    const DraggableTabNode = ({className, ...props}) => {
        const {attributes, listeners, setNodeRef, transform, transition} = useSortable({
            id: props['data-node-key'],
        });
        const style = {
            ...props.style,
            transform: CSS.Translate.toString(transform),
            transition,
            cursor: 'move',
        };
        return React.cloneElement(props.children, {
            ref: setNodeRef,
            style,
            ...attributes,
            ...listeners,
        });
    };
    const sensor = useSensor(PointerSensor, {
        activationConstraint: {
            distance: 10,
        },
    });
    const onDragEnd = ({active, over}) => {
        if (active.id !== over?.id) {
            setCharter(charter => {
                const prev = charter.duties;
                const activeIndex = prev.findIndex((i) => i.shiftBatId === active.id);
                const overIndex = prev.findIndex((i) => i.shiftBatId === over?.id);
                return new Charter({...charter, duties: arrayMove(prev, activeIndex, overIndex)});
            });
        }
    };

    const selectedShift = useMemo(() => {
        if (!charter.duties?.length) {
            return null;
        }
        return selectedShiftId ? find(charter.duties, {shiftBatId: selectedShiftId}) : charter.duties[0];
    }, [selectedShiftId, charter.duties]);

    return (
        <div className="charter-details w-secondary-menu">
            {printShiftBat ? <ShiftBatPrintModal
                    allStops={allStops}
                    allRoutes={routes}
                    apiKey={apiKey}
                    shiftBat={selectedShift}
                    visible={printShiftBat}
                    schedules={allSchedules}
                    setVisible={setPrintShiftBat}/> : <></>}
            {charter.duties && allStops && routes && allTransfers ? (
                <>
                    <SaveMenu
                        save={async () => {
                            await shiftBatModelData.save(charter.duties);
                            console.log('Shifts saved.');
                            await charterModelData.save(charter);
                            console.log('Charter saved.');
                            setMode({edit: false});
                        }}
                        editMode={mode.edit}
                        id={charter.charterId}
                        setEditMode={(edit) => setMode({edit})}
                        controls={controls}
                        updated={controls.updated}
                        modelInstance={charter}
                        initialInstanceRef={initialCharterRef}
                        listUrl={`/charters`}
                        modelService={charterModelData}
                        closeView={true}
                    />
                    <Row gutter={[0, 20]} className="w-100">
                        {!mode.overview && (
                            <CharterMenu
                                charter={charter}
                                activeKey={'3'}
                                setCharter={setCharter}
                                disabled={{
                                    charter: mode.edit,
                                    itinerary: mode.edit,
                                    duty: false,
                                    quote: mode.edit || !charter.duties?.length,
                                }}
                            />
                        )}
                        <Col xs={24} lg={24}>
                            <Row gutter={[20, 20]}>
                                <Col xs={24}>
                                    <Tabs hideAdd
                                          size={'middle'}
                                          onChange={setSelectedShiftId}
                                          activeKey={selectedShiftId || charter.duties?.[0]?.shiftBatId}
                                          type="card"
                                          renderTabBar={(tabBarProps, DefaultTabBar) => (
                                              <DndContext sensors={[sensor]} onDragEnd={onDragEnd}
                                                          collisionDetection={closestCenter}>
                                                  <SortableContext items={charter.duties.map((i) => i.shiftBatId)}
                                                                   strategy={horizontalListSortingStrategy}>
                                                      <DefaultTabBar {...tabBarProps}>
                                                          {(node) => (
                                                              <DraggableTabNode {...node.props} key={node.key}>
                                                                  {node}
                                                              </DraggableTabNode>
                                                          )}
                                                      </DefaultTabBar>
                                                  </SortableContext>
                                              </DndContext>
                                          )}
                                          tabBarExtraContent={<div
                                              className="d-flex align-items-center">
                                                {!mode.edit && selectedShift && <>
                                                    <Button type="primary" className="icon-button btn-filled mr-2" icon={<Print/>}
                                                        disabled={!selectedShift}
                                                        onClick={async event => {
                                                            setPrintShiftBat(true);
                                                        }}>Print</Button>
                                                    <ShareModal shiftBat={selectedShift}/>
                                                </>}
                                              {mode.edit && charter.duties?.length ?
                                                  <Button onClick={() => {
                                                      if (!selectedShift) {
                                                          return;
                                                      }
                                                      const shift = selectedShift.clone('shiftBatId', ulid());
                                                      setCharter(charter => new Charter({
                                                          ...charter,
                                                          duties: charter.duties.concat(shift)
                                                      }));
                                                      setSelectedShiftId(shift.shiftBatId);
                                                  }} type="primary" className="icon-button mr-2"
                                                          icon={<PlusOutlined/>}>Copy</Button> : <></>}
                                              {mode.edit ?
                                                  <Button onClick={() => {
                                                      let shift = newShift(charter, charter.duties.length);
                                                      setCharter(charter => new Charter({
                                                          ...charter,
                                                          duties: charter.duties.concat(shift)
                                                      }));
                                                      setSelectedShiftId(shift.shiftBatId);
                                                  }} type="primary" className="icon-button mr-2"
                                                          icon={<PlusOutlined/>}>Add</Button> : <> </>}
                                              {mode.edit && charter.duties.length > 1 ?
                                                  <Button danger={true} onClick={() => {
                                                      const idx = charter.duties.findIndex(itinerary => {
                                                          return itinerary.shiftBatId === selectedShift.shiftBatId;
                                                      });
                                                      const newShift = charter.duties.filter(itinerary => {
                                                          return itinerary.shiftBatId !== selectedShift.shiftBatId;
                                                      });
                                                      setCharter(charter => new Charter({
                                                          ...charter,
                                                          duties: newShift
                                                      }));
                                                      setSelectedShiftId(idx > newShift.length - 1 ? last(newShift).shiftBatId : newShift[idx].shiftBatId);
                                                  }} type="primary"
                                                          className="icon-button btn-filled btn-error icon-10"
                                                          icon={<Close/>}>Remove</Button> : <></>}</div>}
                                          items={charter.duties.map(shift => {
                                              return {
                                                  key: shift.shiftBatId,
                                                  label: shift.shiftBatName + ` (${toKmMs(shift.getShiftDistance())})`,
                                                  children:
                                                      <EditShiftBat charter={true} shiftBat={shift}
                                                                    initialEditMode={mode?.edit}
                                                                    setShiftBatHistory={newShift => {
                                                                        if (typeof newShift === 'function') {
                                                                            newShift = newShift(shift);
                                                                        }
                                                                        setCharter(charter => {
                                                                            return new Charter({
                                                                                ...charter,
                                                                                duties: charter.duties.map(shift => {
                                                                                    if (shift.shiftBatId === newShift.shiftBatId) {
                                                                                        return newShift;
                                                                                    }
                                                                                    return shift;
                                                                                })
                                                                            });
                                                                        });
                                                                    }}
                                                                    allStops={allStops}
                                                                    allTransfers={allTransfers} allRoutes={routes}
                                                                    setAllStops={setAllStops} allSchedules={allSchedules}
                                                      />
                                              };
                                          })}/>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </>
            ) : (
                <LoadMessage message="Loading Charter Details..."/>
            )}
        </div>
    );
}

export default React.memo(CharterDuty);
