import * as React from 'react';
import './RouteTitle.css'
import {Badge, ListGroup,} from 'react-bootstrap';
import RouteNumber from "./RouteNumber";
import {Popover} from "antd";
import {useEffect, useState} from "react";

function TransferListOverlay({transfers, stop, allStops, placement = 'left'}) {

    const [transfersForStop, setTransfersForStop] = useState([])

    useEffect(() => {
        if(transfers?.length && stop?.stopId) {
            setTransfersForStop(transfers.filter(t => [t.fromStopId, t.toStopId].includes(stop.stopId)))
        }
    }, [transfers, stop])

    const popover = (
        <div style={{maxHeight: '75vh', overflowY: 'scroll'}}>
            <div className="transfers-popover mb-2">
                <span className="text-sm-info">Transfers From</span>
                <ListGroup>
                    {transfersForStop.filter(t => t.toStopId === stop.stopId).map(r => (
                            <ListGroup.Item key={`filtered-list-RN-${r.toRouteId}`}>
                                <RouteNumber link
                                             size="sm" route={{
                                    routeId: r.toRouteId,
                                    routeNumber: r.toRouteNumber,
                                    colour: r.toRouteColour
                                }}/>
                            </ListGroup.Item>
                        )
                    )}
                </ListGroup>
            </div>
            <div className="transfers-popover">
                <span className="text-sm-info">Transfers To</span>
                <ListGroup>
                    {transfersForStop.filter(t => t.fromStopId === stop.stopId).map(r => (
                            <ListGroup.Item key={`filtered-list-RN-${r.fromRouteId}`}>
                                <RouteNumber link
                                             size="sm" route={{
                                    routeId: r.fromRouteId,
                                    routeNumber: r.fromRouteNumber,
                                    colour: r.fromRouteColour
                                }}/>
                            </ListGroup.Item>
                        )
                    )}
                </ListGroup>
            </div>
        </div>
    );

    const TransfersOverlay = () => (
        <>{
            transfersForStop.length ?
                <Popover content={popover}
                         trigger="hover" placement={placement}>
                    <Badge pill variant="light" size='sm' className='badge-outline badge-light'>
                        {`${transfersForStop.length}`}
                    </Badge>
                </Popover> :
                <Badge pill variant="light" size='sm' className='badge-outline badge-light'>
                    {`${transfersForStop.length}`}
                </Badge>
        }</>

    );

    return (
        <TransfersOverlay/>
    );
}

export default React.memo(TransferListOverlay);
