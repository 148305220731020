import {Select} from "antd";
import React, {useEffect, useState} from "react";
import {shiftBatModelData} from "../../services/ModelService";
import {values} from "lodash";

function ShiftBatSelect({onSelect, shiftBatFilter = () => true, ...props}) {
    const [shiftBats, setShiftBats] = useState({})
    const [shiftBatOpts, setShiftBatOpts] = useState([])
    const [shiftBatId, setShiftBatId] = useState(props.value)

    useEffect(() => {
        let shiftBatList = shiftBatModelData.addListener({
            loaded: sbs => setShiftBats(sbs),
            setterFn: setShiftBats
        })

        return () => {
            shiftBatModelData.removeListener(shiftBatList)
        }
    }, [setShiftBats])

    useEffect(() => {
        setShiftBatOpts(values(shiftBats)
            .filter(shiftBatFilter)
            .map(shiftBat => ({
                value: shiftBat.shiftBatId,
                label: `${shiftBat.shiftBatNumber}`
            })));
    }, [shiftBats, setShiftBatOpts])


    return <Select value={shiftBatId}
                   onChange={shiftBatId => {
                       setShiftBatId(shiftBatId);
                       onSelect(shiftBatId, shiftBats[shiftBatId]);
                   }}
                   options={shiftBatOpts} {...props}/>
}

export default React.memo(ShiftBatSelect);