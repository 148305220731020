import {cloneDeep} from "lodash";


export class Shift {
    constructor(data) {
        this.shiftId = "_";
        this.shiftName = null;
        Object.assign(this, data)
    }

    static clone(shift) {
        return new Shift(cloneDeep(shift));
    }

    isObsolete() {
        return false
    }

    isFuture() {
        return false
    }
}
