import { cloneDeep } from "lodash";

export class Entitlement {
    constructor(data) {
        this.effectiveStartDate = null;
        this.effectiveEndDate = null;
        this.title = null;
        this.reason = null;
        this.payRate = null

        this.entitlements = {
            permanent: {
                workWeek: null,
                perHour: null,
                timeAndHalf: null,
                doubleTime: null,
                doubleTimeAndHalf: null,
            },
            casual: {
                workWeek: null,
                perHour: null,
                timeAndHalf: null,
                doubleTime: null,
                doubleTimeAndHalf: null,
            },
            allowance: {
                workWeek: null,
                perHour: null,
                timeAndHalf: null,
                doubleTime: null,
                doubleTimeAndHalf: null,
            }
        }
        
        Object.assign(this, data)
    }

    static clone(entitlement) {
        return new Entitlement(cloneDeep(entitlement));
    }
}