import React, { useEffect, useState } from "react";
import './Customers.css'
import LoadMessage from "../../components/LoadMessage";
import { useAppContext } from "../../libs/contextLib";
import useAllCustomers from "./useAllCustomers";
import { values } from "lodash";
import { filterFn, FilterSwitches } from "../../containers/FilterTag";
import { Button, Card, Col, Input, Row } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { ReactComponent as Bus } from "../../assets/icons/Bus.svg";
import { ReactComponent as Calendar } from "../../assets/icons/Calendar.svg";
import { debounce } from "lodash";
import { LinkContainer } from "react-router-bootstrap";
import CustomerList from "./CustomerList";

const { Search } = Input;

export const filterItems = {
    completed: {
        name: "Completed",
        color: "success",
        showFn: (r) => !r.status || r.status === "completed",
    },
    allocated: {
        name: "Allocated",
        color: "success",
        showFn: (r) => !r.status || r.status === "allocated",
    },
    quoted: {
        name: "Quoted",
        color: "warning",
        showFn: (r) => !r.status || r.status === "quoted",
    },
    cancelled: {
        name: "Cancelled",
        color: "success",
        showFn: (r) => !r.status || r.status === "cancelled",
    },
};

const nameSortFn = (a, b) => {
    if (!a.name?.length || !b.name?.length) {
        return 1
    }
    return a.name.localeCompare(b.name);
}

function Customers() {
    const { operator } = useAppContext();
    const { allCustomers: customers } = useAllCustomers();
    const [filteredCustomers, setFilteredCustomers] = useState([]);
    const [filterBy, setFilterBy] = useState([]);
    const [filter, setFilter] = useState("");
    const setFilterDebounced = debounce(setFilter, 250);

    useEffect(() => {
        if (!customers || !values(customers).length) {
            setFilteredCustomers([]);
        }

        let customerList = values(customers);
        if (filter) {
            const _filter = filter.toLowerCase();
            customerList = customerList.filter(
                (r) =>
                    (r.name && r.name.toLowerCase().indexOf(_filter) > -1) ||
                    (r.requested_by && r.requested_by.toLowerCase().indexOf(_filter) > -1)
            );
        }
        setFilteredCustomers(filterFn(customerList, filterItems, filterBy, "customerId", nameSortFn));
    }, [customers, setFilteredCustomers, filterBy, filter]);


    return (
        <>
            {customers && filteredCustomers ? (
                <div className="customers-list">
                    <div className="d-flex align-items-center justify-content-between filter-options-main">
                        <div className="d-flex align-items-center" style={{ gap: "10px" }}>
                            <LinkContainer to="/customers/_new_">
                                <Button type="primary" className="icon-button" icon={<PlusOutlined />}>
                                    Add Customer
                                </Button>
                            </LinkContainer>
                            {/* <ImportStudents operator={operator} messageApi={messageApi}
                                            existingStudents={keyBy(values(students), 'authorityId')}
                                            setImportedStudents={setImportedStudents}/> */}
                        </div>
                        <div className="d-flex align-items-center">
                            <Search
                                id="basic-addon1"
                                style={{
                                    width: 200,
                                }}
                                allowClear={true}
                                onChange={(e) => setFilterDebounced(e.target.value)}
                            />
                            <div className="filter-switches">
                                <FilterSwitches filterItems={filterItems} filterBy={filterBy} setFilterBy={setFilterBy} />
                            </div>
                        </div>
                    </div>
                    <CustomerList customers={filteredCustomers}/>
                </div>
            ) : (
                <LoadMessage message="Loading customers..." />
            )}
        </>
    );
}

export default React.memo(Customers);
