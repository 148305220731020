import {Select} from "antd";
import React, {useEffect, useState} from "react";
import {charterModelData} from "../../services/ModelService";
import {values} from "lodash";

function CharterSelect({onSelect, charterFilter = () => true, ...props}) {
    const [charters, setCharters] = useState({})
    const [charterOpts, setCharterOpts] = useState([])
    const [charterId, setCharterId] = useState(props.value)

    useEffect(() => {
        let charterListener = charterModelData.addListener({
            loaded: ch => setCharters(ch),
            setterFn: setCharters
        })

        return () => {
            charterModelData.removeListener(charterListener)
        }
    }, [setCharters])

    useEffect(() => {
        setCharterOpts(values(charters)
            .filter(charterFilter)
            .map(charter => ({
                value: charter.charterId,
                label: `${charter.name}`
            })));
    }, [charters, setCharterOpts])


    return <Select value={charterId}
                   onChange={charterId => {
                       setCharterId(charterId);
                       onSelect(charterId, charters[charterId]);
                   }}
                   options={charterOpts} {...props}/>
}

export default React.memo(CharterSelect);