import {camelCase, snakeCase} from "lodash/string";

export const apiUrlRegexp = /REACT_APP_([A-Z_]+?)_API_URL/;
export const lambdaUrlRegexp = /REACT_APP_([A-Z_]+?)_LAMBDA_URL/;

console.log(process.env)
const prod = {
    app: {
        BASE_URL: 'https://enable.busable.app'
    },
    s3: {
        REGION: "ap-southeast-2",
        BUCKET: "prod-busable-storagestack-busablebucketfa6d3fb9-o3vold4lv08y",
    },
    apiGateway: {
        REGION: "ap-southeast-2",
        WSS: "wss://n77u4aibx5.execute-api.ap-southeast-2.amazonaws.com/prod",
        TRACKABLE_WSS: 'wss://uil74wktr6.execute-api.ap-southeast-2.amazonaws.com/prod',
        API: {
            endpoints: [
                {
                    name: "routes",
                    endpoint: "https://sxoj421pu6.execute-api.ap-southeast-2.amazonaws.com",
                    region: "ap-southeast-2"
                },
                {
                    name: "transfer",
                    endpoint: "https://av7d8d9xh9.execute-api.ap-southeast-2.amazonaws.com",
                    region: "ap-southeast-2"
                },
                {
                    name: "driver",
                    endpoint: "https://akse038jjf.execute-api.ap-southeast-2.amazonaws.com",
                    region: "ap-southeast-2"
                },
                {
                    name: "vehicle",
                    endpoint: "https://5p7xyfo542.execute-api.ap-southeast-2.amazonaws.com",
                    region: "ap-southeast-2"
                },
                {
                    name: 'shift_bat', endpoint: " https://40fucnjss8.execute-api.ap-southeast-2.amazonaws.com",
                    region: "ap-southeast-2"
                },
                {
                    name: 'deadrun', endpoint: "https://vfpgey8c2l.execute-api.ap-southeast-2.amazonaws.com",
                    region: "ap-southeast-2"
                },
                {
                    name: 'ref', endpoint: "https://h7nrfb8auk.execute-api.ap-southeast-2.amazonaws.com",
                    region: "ap-southeast-2"
                },
                {
                    name: 'log', endpoint: "https://j2on6o67j9.execute-api.ap-southeast-2.amazonaws.com",
                    region: "ap-southeast-2"
                },
                {
                    name: 'events', endpoint: "https://i5rh3rfj3g.execute-api.ap-southeast-2.amazonaws.com",
                    region: "ap-southeast-2"
                },
                {
                    name: "third_party",
                    endpoint: "https://felv930otc.execute-api.ap-southeast-2.amazonaws.com",
                    region: "ap-southeast-2"
                },
                {
                    name: "operators",
                    endpoint: "https://zzywf9q68c.execute-api.ap-southeast-2.amazonaws.com",
                    region: "ap-southeast-2"
                },
                {
                    name: "student",
                    endpoint: "https://cyolwv6n85.execute-api.ap-southeast-2.amazonaws.com",
                    region: "ap-southeast-2"
                },
                {
                    name: "sms",
                    endpoint: "https://p3v3susjp1.execute-api.ap-southeast-2.amazonaws.com",
                    region: "ap-southeast-2"
                },
                {
                    name: "charter",
                    endpoint: "https://998g15uy23.execute-api.ap-southeast-2.amazonaws.com",
                    region: "ap-southeast-2"
                },
                {
                    name: "public",
                    endpoint: "https://u6mcv8i5ae.execute-api.ap-southeast-2.amazonaws.com",
                    region: "ap-southeast-2"
                },
                {
                    name: "job",
                    endpoint: "https://2003evks4i.execute-api.ap-southeast-2.amazonaws.com",
                    region: "ap-southeast-2"
                },
                {
                    name: "customer",
                    endpoint: "https://x28lac080h.execute-api.ap-southeast-2.amazonaws.com",
                    region: "ap-southeast-2"
                },
                {
                    name: "vehicleType",
                    endpoint: "https://3bfukhja61.execute-api.ap-southeast-2.amazonaws.com",
                    region: "ap-southeast-2"
                },
                {
                    name: "shift",
                    endpoint: "https://wjqpozpeh3.execute-api.ap-southeast-2.amazonaws.com",
                    region: "ap-southeast-2"
                },
                {
                    name: "roster",
                    endpoint: "https://ntw386e1ck.execute-api.ap-southeast-2.amazonaws.com",
                    region: "ap-southeast-2"
                }

            ]
        }
    },
    cognito: {
        REGION: "ap-southeast-2",
        USER_POOL_ID: "ap-southeast-2_BWWYMNFJR",
        APP_CLIENT_ID: "620gaq44su0img4tf7ljts2c89",
        IDENTITY_POOL_ID: "ap-southeast-2:728ac1f6-ef9a-4418-accd-6f179ad8e45e",
    },
    maps: {
        KEY: "AIzaSyCqhZ56-F0-1I313GCXuBOYUztCNLGUAdg",
        mabBox: "pk.eyJ1IjoiZ2pyd2ViYmVyIiwiYSI6ImNrcmx5bmd3YzJxdWcycG00Mm1ydGEwcGUifQ.hzLzm-bpjB2Nci43izcg-A",
        geoapify: {
            ctrxs: "0139accdc5d84b5084289bccffa2a90d",
            blpctn: "837db0932a254b9584923c6a36e3fdf2",
            bldbbo: "8d4ff339b5a44c289accee1a595d7a6e",
            bltmwrth: "4f3326f97be04107abe7207ae1dc9960",
            blbthrst: "5fa71575daf94f328e85a69c384cb41e"
        }
    },
    lambda: {
        toGtfs: {
            URL: "https://tklvd5ovi5gnl2vu2ht5ut3dju0cnzne.lambda-url.ap-southeast-2.on.aws"
        },
        uploadGtfs: {
            URL: "https://aswixtsitleivrfpjzid4rx3j40shhln.lambda-url.ap-southeast-2.on.aws"
        }
    },
    email: {
        ELASTIC_EMAIL_KEY: 'EFDA288C1FDDD78446AA5C2DE2F5521E15E2B011B988FFDADC68056155EFD00745ABF7730FB2F40D4644E3262B05C9FE'
    },
    cms: {
        URL: 'https://cms.busable.app',
        APIKEY: 'ba3b1e8c6ea3fa120b493347332570bf8143cd8eaec62cb6d2e8c455282b3c7000a0381336eddc4d04e3ba6371f431b644b054ea3dc4bf68ef6388235b3fbf888b513a189eed9cd44116354f86df7ba4ffd573732686fb3df1753447fbfe379659326f62b1613729ca3140c6bb4b8c9ab77cad43137f71897687628ca314b7e9'
    }

};
const staging = {
    app: {
        BASE_URL: 'http://localhost:3000'
    },
    s3: {
        REGION: "ap-southeast-2",
        BUCKET: "staging-busable-storagestac-busablebucketfa6d3fb9-1217nxj03z054",
    },
    apiGateway: {
        REGION: "ap-southeast-2",
        WSS: "wss://axo0t14wde.execute-api.ap-southeast-2.amazonaws.com/staging",
        TRACKABLE_WSS: 'wss://jeaggqhnwf.execute-api.ap-southeast-2.amazonaws.com/staging',
        API: {
            endpoints: [
                {
                    name: "routes",
                    endpoint: "https://mlegjof2hf.execute-api.ap-southeast-2.amazonaws.com",
                    region: "ap-southeast-2"
                },
                {
                    name: "transfer",
                    endpoint: "https://pn5dvssax1.execute-api.ap-southeast-2.amazonaws.com",
                    region: "ap-southeast-2"
                },
                {
                    name: "driver",
                    endpoint: "https://a61dzgd9ik.execute-api.ap-southeast-2.amazonaws.com",
                    region: "ap-southeast-2"
                },
                {
                    name: "vehicle",
                    endpoint: "https://oah8v3beq5.execute-api.ap-southeast-2.amazonaws.com",
                    region: "ap-southeast-2"
                },
                {
                    name: 'shift_bat', endpoint: "https://wazkd6j6m0.execute-api.ap-southeast-2.amazonaws.com",
                    region: "ap-southeast-2"
                },
                {
                    name: 'deadrun', endpoint: "https://0ytldz973k.execute-api.ap-southeast-2.amazonaws.com",
                    region: "ap-southeast-2"
                },
                {
                    name: 'ref', endpoint: "https://kuaieusjl2.execute-api.ap-southeast-2.amazonaws.com",
                    region: "ap-southeast-2"
                },
                {
                    name: 'log', endpoint: "https://4w392a7tv9.execute-api.ap-southeast-2.amazonaws.com",
                    region: "ap-southeast-2"
                },
                {
                    name: 'events', endpoint: "https://wj2uxhtxeg.execute-api.ap-southeast-2.amazonaws.com",
                    region: "ap-southeast-2"
                },
                {
                    name: "third_party",
                    endpoint: "https://qfrmzsqt8g.execute-api.ap-southeast-2.amazonaws.com",
                    region: "ap-southeast-2"
                },
                {
                    name: "operators",
                    endpoint: "https://vljb52kmca.execute-api.ap-southeast-2.amazonaws.com",
                    region: "ap-southeast-2"
                },
                {
                    name: "student",
                    endpoint: "https://w16cqtmxa2.execute-api.ap-southeast-2.amazonaws.com",
                    region: "ap-southeast-2"
                },
                {
                    name: "sms",
                    endpoint: "https://jq1cil20d0.execute-api.ap-southeast-2.amazonaws.com",
                    region: "ap-southeast-2"
                },
                {
                    name: "charter",
                    endpoint: "https://gklcj2cfu9.execute-api.ap-southeast-2.amazonaws.com",
                    region: "ap-southeast-2"
                },
                {
                    name: "public",
                    endpoint: "https://kq8lc7hjfi.execute-api.ap-southeast-2.amazonaws.com",
                    region: "ap-southeast-2"
                },
                {
                    name: "job",
                    endpoint: "https://47ck9ee9da.execute-api.ap-southeast-2.amazonaws.com",
                    region: "ap-southeast-2"
                },
                {
                    name: "customer",
                    endpoint: "https://dbx60wmuue.execute-api.ap-southeast-2.amazonaws.com",
                    region: "ap-southeast-2"
                },
                {
                    name: "vehicleType",
                    endpoint: "https://dky01hqrb4.execute-api.ap-southeast-2.amazonaws.com",
                    region: "ap-southeast-2"
                },
                {
                    name: "shift",
                    endpoint: "https://erwtfbmfn9.execute-api.ap-southeast-2.amazonaws.com",
                    region: "ap-southeast-2"
                },
                {
                    name: "roster",
                    endpoint: "https://nsjvt1h9y1.execute-api.ap-southeast-2.amazonaws.com",
                    region: "ap-southeast-2"
                }
            ]
        }
    },
    cognito: {
        REGION: "ap-southeast-2",
        USER_POOL_ID: "ap-southeast-2_BWWYMNFJR",
        APP_CLIENT_ID: "620gaq44su0img4tf7ljts2c89",
        IDENTITY_POOL_ID: "ap-southeast-2:f775397e-bdde-4c86-b6f0-6197e1f77c84",
    },
    maps: {...prod.maps},
    lambda: {
        toGtfs: {
            URL: "https://3c33yld2slzslyqbnbxzsleseu0iqymz.lambda-url.ap-southeast-2.on.aws"
        },
        uploadGtfs: {
            URL: "https://zfplugmor6jvt3vbx2cf6svc7e0lhwum.lambda-url.ap-southeast-2.on.aws"
        }
    },
    email: {
        ELASTIC_EMAIL_KEY: 'EFDA288C1FDDD78446AA5C2DE2F5521E15E2B011B988FFDADC68056155EFD00745ABF7730FB2F40D4644E3262B05C9FE'
    },
    cms: {
        URL: 'http://localhost:1337',
        APIKEY: '2399f748d5984084bcd586494173fca082ffa23fa0543785c24dd48c2cfdab9a2257cc4bb4ee7c4d4bf04bca62a34b042fc3db90a6bcaa7ff18d3813fa2c1143e0de9ab246e39c0e97d6f01afb2294949b32db73f2437fcec77292c26c7a1085385e0c354d42abe051c2c789308f761f8e1cb9ed78a3710f2f9e84ce8a70fdbb'
    }

};

const pilot = {
    app: {
        BASE_URL: 'http://localhost:3000'
    },
    s3: {
        REGION: "ap-southeast-2",
        BUCKET: "pilot-busable-storagestack-busablebucketfa6d3fb9-tsyans9kctmc",
    },
    apiGateway: {
        REGION: "ap-southeast-2",
        WSS: "wss://0boylvfs00.execute-api.ap-southeast-2.amazonaws.com/pilot",
        API: {
            endpoints: [
                {
                    name: "routes",
                    endpoint: "https://h6vxoapgd3.execute-api.ap-southeast-2.amazonaws.com",
                    region: "ap-southeast-2"
                },
                {
                    name: "transfer",
                    endpoint: "https://p5cjr4fnmc.execute-api.ap-southeast-2.amazonaws.com",
                    region: "ap-southeast-2"
                },
                {
                    name: "driver",
                    endpoint: "https://pp9gbw8d8a.execute-api.ap-southeast-2.amazonaws.com",
                    region: "ap-southeast-2"
                },
                {
                    name: "vehicle",
                    endpoint: "https://pkd7262wy4.execute-api.ap-southeast-2.amazonaws.com",
                    region: "ap-southeast-2"
                },
                {
                    name: 'shift_bat', endpoint: "https://z73d7jatol.execute-api.ap-southeast-2.amazonaws.com",
                    region: "ap-southeast-2"
                },
                {
                    name: 'deadrun', endpoint: "https://0cvxz9f5ec.execute-api.ap-southeast-2.amazonaws.com",
                    region: "ap-southeast-2"
                },
                {
                    name: 'ref', endpoint: "https://rc1auezh8f.execute-api.ap-southeast-2.amazonaws.com",
                    region: "ap-southeast-2"
                },
                {
                    name: 'log', endpoint: "https://mr075cpfy4.execute-api.ap-southeast-2.amazonaws.com",
                    region: "ap-southeast-2"
                },
                {
                    name: 'events', endpoint: "https://ndss1e58vh.execute-api.ap-southeast-2.amazonaws.com",
                    region: "ap-southeast-2"
                },
                {
                    name: "third_party",
                    endpoint: "https://iut9ccwilc.execute-api.ap-southeast-2.amazonaws.com",
                    region: "ap-southeast-2"
                },
                {
                    name: "operators",
                    endpoint: "https://vd9mi5oz5k.execute-api.ap-southeast-2.amazonaws.com",
                    region: "ap-southeast-2"
                },
                {
                    name: "students",
                    endpoint: "https://w16cqtmxa2.execute-api.ap-southeast-2.amazonaws.com",
                    region: "ap-southeast-2"
                },
                {
                    name: "sms",
                    endpoint: "https://jq1cil20d0.execute-api.ap-southeast-2.amazonaws.com",
                    region: "ap-southeast-2"
                },
                {
                    name: "charter",
                    endpoint: "https://gklcj2cfu9.execute-api.ap-southeast-2.amazonaws.com",
                    region: "ap-southeast-2"
                },
                {
                    name: "public",
                    endpoint: "https://kq8lc7hjfi.execute-api.ap-southeast-2.amazonaws.com",
                    region: "ap-southeast-2"
                },
                {
                    name: "job",
                    endpoint: "https://47ck9ee9da.execute-api.ap-southeast-2.amazonaws.com",
                    region: "ap-southeast-2"
                },
                {
                    name: "vehicleType",
                    endpoint: "https://dky01hqrb4.execute-api.ap-southeast-2.amazonaws.com",
                    region: "ap-southeast-2"
                }
            ]
        }
    },
    cognito: {
        REGION: "ap-southeast-2",
        USER_POOL_ID: "ap-southeast-2_BWWYMNFJR",
        APP_CLIENT_ID: "620gaq44su0img4tf7ljts2c89",
        IDENTITY_POOL_ID: "ap-southeast-2:0cc18ed2-137c-406e-a293-8f48b0b687de",
    },
    maps: {...prod.maps},
    lambda: {
        toGtfs: {
            URL: "https://4ih244bj3nhlnzshw5w6bnnjza0tdnpa.lambda-url.ap-southeast-2.on.aws"
        },
    },
    email: {
        ELASTIC_EMAIL_KEY: 'EFDA288C1FDDD78446AA5C2DE2F5521E15E2B011B988FFDADC68056155EFD00745ABF7730FB2F40D4644E3262B05C9FE'
    },
    cms: {
        URL: 'http://localhost:1337',
        APIKEY: '2399f748d5984084bcd586494173fca082ffa23fa0543785c24dd48c2cfdab9a2257cc4bb4ee7c4d4bf04bca62a34b042fc3db90a6bcaa7ff18d3813fa2c1143e0de9ab246e39c0e97d6f01afb2294949b32db73f2437fcec77292c26c7a1085385e0c354d42abe051c2c789308f761f8e1cb9ed78a3710f2f9e84ce8a70fdbb'
    }

};
const develop = {
    app: {
        BASE_URL: 'http://localhost:3000'
    },
    s3: {
        REGION: "ap-southeast-2",
        BUCKET: "develop-busable-storagestac-busablebucketfa6d3fb9-1jsl2lx39d1k5",
    },
    apiGateway: {
        REGION: "ap-southeast-2",
        WSS: "wss://hvfy6wlwt0.execute-api.ap-southeast-2.amazonaws.com/develop",
        API: {
            endpoints: [
                {
                    name: "routes",
                    endpoint: "https://je0w3mlxqd.execute-api.ap-southeast-2.amazonaws.com",
                    region: "ap-southeast-2"
                },
                {
                    name: "transfer",
                    endpoint: "https://n3tsmibaml.execute-api.ap-southeast-2.amazonaws.com",
                    region: "ap-southeast-2"
                },
                {
                    name: "driver",
                    endpoint: "https://xt0301vkqf.execute-api.ap-southeast-2.amazonaws.com",
                    region: "ap-southeast-2"
                },
                {
                    name: "vehicle",
                    endpoint: "https://5jbr4usw03.execute-api.ap-southeast-2.amazonaws.com",
                    region: "ap-southeast-2"
                },
                {
                    name: 'shift_bat', endpoint: "https://7t983smkbf.execute-api.ap-southeast-2.amazonaws.com",
                    region: "ap-southeast-2"
                },
                {
                    name: 'deadrun', endpoint: "https://q5y1izvpd1.execute-api.ap-southeast-2.amazonaws.com",
                    region: "ap-southeast-2"
                },
                {
                    name: 'ref', endpoint: "https://2q4n53akjj.execute-api.ap-southeast-2.amazonaws.com",
                    region: "ap-southeast-2"
                },
                {
                    name: 'log', endpoint: "https://xbq6ntxa67.execute-api.ap-southeast-2.amazonaws.com",
                    region: "ap-southeast-2"
                },
                {
                    name: 'events', endpoint: "https://bhx7i6d24i.execute-api.ap-southeast-2.amazonaws.com",
                    region: "ap-southeast-2"
                },
                {
                    name: "third_party",
                    endpoint: "https://tsgf73v8cg.execute-api.ap-southeast-2.amazonaws.com",
                    region: "ap-southeast-2"
                },
                {
                    name: "operators",
                    endpoint: "https://vljb52kmca.execute-api.ap-southeast-2.amazonaws.com",
                    region: "ap-southeast-2"
                },
                {
                    name: "students",
                    endpoint: "https://w16cqtmxa2.execute-api.ap-southeast-2.amazonaws.com",
                    region: "ap-southeast-2"
                },
                {
                    name: "sms",
                    endpoint: "https://q5y1izvpd1.execute-api.ap-southeast-2.amazonaws.com",
                    region: "ap-southeast-2"
                },
                {
                    name: "charter",
                    endpoint: "https://q5y1izvpd1.execute-api.ap-southeast-2.amazonaws.com",
                    region: "ap-southeast-2"
                },
                {
                    name: "public",
                    endpoint: "https://kq8lc7hjfi.execute-api.ap-southeast-2.amazonaws.com",
                    region: "ap-southeast-2"
                },
                {
                    name: "job",
                    endpoint: "https://47ck9ee9da.execute-api.ap-southeast-2.amazonaws.com",
                    region: "ap-southeast-2"
                },
                {
                    name: "vehicleType",
                    endpoint: "https://dky01hqrb4.execute-api.ap-southeast-2.amazonaws.com",
                    region: "ap-southeast-2"
                }
            ]
        }
    },
    cognito: {
        REGION: "ap-southeast-2",
        USER_POOL_ID: "ap-southeast-2_EdE8ekw42",
        APP_CLIENT_ID: "3bifrb5tm0f1tkhg8rhffgjonq",
        IDENTITY_POOL_ID: "ap-southeast-2:ca3b0223-bf53-4e84-ac9b-5cde4f3a40b8",
    },
    maps: {...prod.maps},
    lambda: {
        toGtfs: {
            URL: "https://vyb2umlcacrojj7z567ikehiee0cwqdr.lambda-url.ap-southeast-2.on.aws"
        },
    },
    email: {
        ELASTIC_EMAIL_KEY: 'EFDA288C1FDDD78446AA5C2DE2F5521E15E2B011B988FFDADC68056155EFD00745ABF7730FB2F40D4644E3262B05C9FE'
    },
    cms: {
        URL: 'http://localhost:1337',
        APIKEY: '2399f748d5984084bcd586494173fca082ffa23fa0543785c24dd48c2cfdab9a2257cc4bb4ee7c4d4bf04bca62a34b042fc3db90a6bcaa7ff18d3813fa2c1143e0de9ab246e39c0e97d6f01afb2294949b32db73f2437fcec77292c26c7a1085385e0c354d42abe051c2c789308f761f8e1cb9ed78a3710f2f9e84ce8a70fdbb'
    }

};
export const dev = {
    app: {
        BASE_URL: 'http://localhost:3000',
    },
    s3: {
        REGION: process.env.REACT_APP_REGION,
        BUCKET: process.env.REACT_APP_BUCKET,
    },
    apiGateway: {
        REGION: process.env.REACT_APP_REGION,
        WSS: process.env.REACT_APP_WSS_URL,
        API: {
            endpoints: Object.keys(process.env).filter(envKey => envKey.match(apiUrlRegexp)).map(envKey => {
                const match = envKey.match(apiUrlRegexp)
                return {
                    name: snakeCase(match[1]),
                    endpoint: process.env[envKey],
                    region: process.env.REACT_APP_REGION
                }
            })
        },
        TRACKABLE_WSS: 'wss://axo0t14wde.execute-api.ap-southeast-2.amazonaws.com/staging'
    },
    cognito: {
        REGION: process.env.REACT_APP_REGION,
        USER_POOL_ID: process.env.REACT_APP_USER_POOL_ID,
        APP_CLIENT_ID: process.env.REACT_APP_USER_POOL_CLIENT_ID,
        IDENTITY_POOL_ID: process.env.REACT_APP_IDENTITY_POOL_ID,
    },
    maps: {...prod.maps},
    lambda: {},
    email: {
        ELASTIC_EMAIL_KEY: 'EFDA288C1FDDD78446AA5C2DE2F5521E15E2B011B988FFDADC68056155EFD00745ABF7730FB2F40D4644E3262B05C9FE'
    },
    cms: {
        URL: 'http://localhost:1337',
        APIKEY: '2399f748d5984084bcd586494173fca082ffa23fa0543785c24dd48c2cfdab9a2257cc4bb4ee7c4d4bf04bca62a34b042fc3db90a6bcaa7ff18d3813fa2c1143e0de9ab246e39c0e97d6f01afb2294949b32db73f2437fcec77292c26c7a1085385e0c354d42abe051c2c789308f761f8e1cb9ed78a3710f2f9e84ce8a70fdbb'
    }
};
Object.keys(process.env).filter(envKey => lambdaUrlRegexp.test(envKey)).forEach(envKey => {
    const match = envKey.match(lambdaUrlRegexp)
    dev.lambda[camelCase(match[1])] = {URL: process.env[envKey].slice(0, -1)}
})

const local = {
    local: true,
    ...staging
}

const configs = {local, staging, dev, develop, pilot, prod}

console.log(`Config: ${process.env.REACT_APP_STAGE || dev}`)

// if (process.env.REACT_APP_STAGE && !configs[process.env.REACT_APP_STAGE]) {
//     throw new Error(process.env.REACT_APP_STAGE + ` is not usable. ${Object.keys(configs)} are the only stages that work.`)
// }

const config = {
    MAX_ATTACHMENT_SIZE: 2000000,
    ...(configs[process.env.REACT_APP_STAGE] || configs.dev),
};

console.log(configs[process.env.REACT_APP_STAGE] || configs.dev)
export default config;
