import { cloneDeep } from "lodash";

export class License {
    constructor(data) {
        this.id = null
        this.name = null;
        this.type = null;
        this.expiryDate = null;
        this.verifiedDate = null;
        this.proof = null;

        Object.assign(this, data);
    }

    clone(license) {
        return new License(cloneDeep(license));
    }
}