import {useAppContext} from '../libs/contextLib';
import store from 'store2';
import {getEditor} from "./getEditor";
import {useCallback, useEffect, useState} from "react";

const useEditor = ({user}) => {
    const [editor, _setEditor] = useState(null);
    // const {user} = useAppContext(); // Assuming useAppContext returns an object with a user property

    useEffect(() => {
        // If user exists in context, return it
        if (user) {
            _setEditor(getEditor({user})?.editor);
            return;
        }

        // Attempt to get the user from localStorage using store module
        const localStorageUser = store.session.get('user');

        if (localStorageUser) {
            _setEditor(localStorageUser)
        }

    }, [_setEditor, user]);

    const setEditor = useCallback((user) => {
        _setEditor(user);
        store.session.set('user', user);
    }, [_setEditor]);

    return [editor, setEditor];
};

export default useEditor;