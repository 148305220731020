import {useEffect, useMemo, useState} from "react";
import {useAppContext} from "../libs/contextLib";


export default function useAllowComments() {
    const {operator, isAuthenticated} = useAppContext({operator: {features: {commenting: () => false}}});
    const searchParams = useMemo(() => new URLSearchParams(window.location.search), []);
    const [allowComments, setAllowComments] = useState(false);

    useEffect(() => {
        if (!operator?.features?.commenting) {
            return;
        }
        const params = new URLSearchParams(searchParams);
        setAllowComments(operator?.features.commenting() && (isAuthenticated || params.get('_cmnt') === 'v1'));
    }, [searchParams, setAllowComments, operator])

    return [allowComments, setAllowComments];
}