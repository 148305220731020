import {Modal} from "antd";
import React, {useCallback, useRef, useState} from "react";
import ChatComponent from "./ChatComponent";
import Draggable from 'react-draggable';
import {useAppContext} from "../libs/contextLib";

function ChatModal({
                       visible,
                       setVisible,
                   }) {

    const {chatContext, setChatContext} = useAppContext();
    const [disabled, setDisabled] = useState(true);
    const [bounds, setBounds] = useState({
        left: 0,
        top: 0,
        bottom: 0,
        right: 0,
    });
    const draggleRef = useRef(null);
    const onStart = (_event, uiData) => {
        const {clientWidth, clientHeight} = window.document.documentElement;
        const targetRect = draggleRef.current?.getBoundingClientRect();
        if (!targetRect) {
            return;
        }
        setBounds({
            left: -targetRect.left + uiData.x,
            right: clientWidth - (targetRect.right - uiData.x),
            top: -targetRect.top + uiData.y,
            bottom: clientHeight - (targetRect.bottom - uiData.y),
        });
    };

    const onClose = useCallback(() => {
        setVisible(false)
        setChatContext(cc => cc.reset())
    }, [setVisible, setChatContext])

    return (
        <>
            <Modal
                title={
                    <div
                        style={{
                            width: '100%',
                            cursor: 'move',
                        }}
                        onMouseOver={() => {
                            if (disabled) {
                                setDisabled(false);
                            }
                        }}
                        onMouseOut={() => {
                            setDisabled(true);
                        }}
                        // fix eslintjsx-a11y/mouse-events-have-key-events
                        // https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/blob/master/docs/rules/mouse-events-have-key-events.md
                        onFocus={() => {
                        }}
                        onBlur={() => {
                        }}
                        // end
                    >
                        Comments
                        {/*{chatContext ? (`${chatContext?.type === 'route' ? (`Route: ${chatContext?.route?.routeNumber} ${chatContext?.subType === 'wp' ?*/}
                        {/*    `WP: ${chatContext.lat}, ${chatContext.lon}` : chatContext?.subType === 'st' ?*/}
                        {/*        `Stop time: ${toTime(chatContext?.stopTime.arriveSecs)} ${chatContext?.stop?.stopName}` : ''}`) : chatContext?.stop?.stopName}`) : 'Chat'}*/}
                    </div>
                }
                open={visible}
                onClose={onClose}
                onCancel={onClose}
                onOk={onClose}
                maskClosable={true}
                mask={false}
                destroyOnClose
                closable={true}
                modalRender={(modal) => (
                    <Draggable
                        disabled={disabled}
                        bounds={bounds}
                        nodeRef={draggleRef}
                        onStart={(event, uiData) => onStart(event, uiData)}
                    >
                        <div ref={draggleRef}>{modal}</div>
                    </Draggable>
                )}
            >
                {chatContext?.type === 'all' || chatContext?.id ?
                    <ChatComponent
                        key={`Comment-${chatContext.route ? chatContext.route?.routeId : chatContext.stop ? chatContext.stop.stopId : 'all'}`}/> :
                    <div>Please select a route, stop time or stop to comment on</div>
                }
            </Modal>
        </>
    );
}

export default React.memo(ChatModal);