import React, {useEffect, useState} from "react";
import "./Export.css";
import {Button, Modal} from "antd";
import {SyncOutlined} from '@ant-design/icons';
import {onError} from "../libs/errorLib";
import Nav from "react-bootstrap/Nav";
import {Checkbox, Input, Popconfirm, Select, Tabs, Tooltip} from "antd/lib";
import LoaderButton from "./LoaderButton";
import {API} from "aws-amplify";
import {routesModelExpiryService} from "../services/ModelService";
import {toHrsMinsSecs} from "../libs/formatLib";
import store from "store2";
import {setUserOperator} from "../services/routeService";
import {find} from "lodash/collection";
import dayjs from "@busable/core/src/dayjs";
import {useAppContext} from "../libs/contextLib";

const DeleteTab = ({sourceOperator, setSourceOperator, operatorOpts, handleDelete, isLoading, apiKey}) => {
    return (<div>
        <p>Source operator: <Select style={{width: '100%'}} dropdownStyle={{zIndex: 12001}} value={sourceOperator}
                                    onChange={setSourceOperator}
                                    mode="multiple"
                                    options={operatorOpts?.filter(op => (!op.tier || op.tier === 't') && op.value !== apiKey) || []}/>
        </p>
        <h5>Delete operator: {sourceOperator} in {process.env.REACT_APP_STAGE}</h5>
        <p><Popconfirm
            zIndex={10000}
            title={`Are you sure you want to delete ${sourceOperator?.length > 3 ? `${sourceOperator.length} operators` : sourceOperator} in env: ${process.env.REACT_APP_STAGE}? This will remove all data relating to the operator.`}
            onConfirm={handleDelete}><LoaderButton
            isLoading={isLoading}>Delete</LoaderButton></Popconfirm></p>
    </div>)
}

const CleanTab = ({targetOperator, setTargetOperator, isLoading, operatorOpts, handleClean}) => {
    return (<div>

        <p>Clean operator: <Select style={{width: '100%'}} dropdownStyle={{zIndex: 12001}}
                                   value={targetOperator}
                                   onChange={setTargetOperator}
                                   options={operatorOpts}/></p>
        <p>Clean operator ID: <Input value={targetOperator} onChange={e => setTargetOperator(e.target.value)}/></p>
        <h5>Clean operator: {targetOperator} in {process.env.REACT_APP_STAGE}</h5>
        <p><Popconfirm
            zIndex={10000}
            title={`Are you sure you want to clean ${targetOperator} in env: ${process.env.REACT_APP_STAGE}?`}
            onConfirm={handleClean}><LoaderButton
            isLoading={isLoading}>Clean</LoaderButton></Popconfirm></p>
    </div>)
}

export default function AdminModal({allowedOperators, isAdmin, apiKey, messageApi}) {
    const [visible, setVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [sourceStage, setSourceStage] = useState(["prod", "staging", "pilot", "skunk"].includes(process.env.REACT_APP_STAGE) ? "prod" : "develop");
    const [sourceOperator, setSourceOperator] = useState(null);
    const [targetOperator, setTargetOperator] = useState(apiKey);
    const [operatorOpts, setOperatorOpts] = useState(null);
    // const [newOperatorId, setNewOperatorId] = useState("");
    // const [newOperatorName, setNewOperatorName] = useState("");
    const [optimise, setOptimise] = useState(false);
    const [remove, setRemove] = useState(false);

    useEffect(() => {
        setOperatorOpts([{
            operatorName: "Create new",
            operatorId: "_new_"
        }].concat(allowedOperators || [{operatorName: "Demo", operatorId: "demo"}])
            // .concat([{operatorName: "Create new", operatorId: "_new_"}])
            .map(operator => (
                {label: operator.operatorName, value: operator.operatorId, tier: operator.tier}
            )))
    }, [allowedOperators, setOperatorOpts])

    async function handleSubmit(event) {
        event.preventDefault();
        setIsLoading(true);

        try {
            const time = Date.now()
            console.log("Copying %s %s to %s %s...", sourceStage, sourceOperator, process.env.REACT_APP_STAGE, targetOperator)

            const sourceOperatorName = allowedOperators?.find(op => op.operatorId === sourceOperator)?.operatorName

            await API.post("events", `/events`, {
                body: {
                    source: 'busable.copyOperator',
                    detailType: 'CopyOperator',
                    detail: {
                        sourceStage,
                        sourceOperatorId: sourceOperator,
                        targetOperatorId: targetOperator === '_new_' ? sourceOperator : targetOperator,
                        dryrun: false,
                        remove,
                        optimise,
                        update: targetOperator === "_new_" ? {
                            operatorId: sourceOperator + "_" + dayjs().format('YYYYMMDD'),
                            operatorName: (sourceOperatorName || sourceOperator) + " " + dayjs().format('YYYYMMDD')
                        } : null
                    }
                }
            });
            // await API.get("operators", `/copyOperator/${sourceStage}/${sourceOperator}/${targetOperator}?dryrun=false&remove=${remove}&optimise=${optimise}&create=true`)
            console.log("Copying done... Took %s", toHrsMinsSecs((Date.now() - time) / 1000))
            messageApi.info(`Sent event to copy ${sourceOperator} to ${targetOperator}. Please give it a few minutes to complete.`, 10)
            // const operator = find(allowedOperators, op => op.operatorId === targetOperator)
            // console.log('Setting operator: ', operator.operatorId)
            // try {
            //     window.location.replace(`/switch/${operator.companyId}/${operator.operatorId}`)
            // } catch (e) {
            //     console.log(e);
            // }
        } catch (e) {
            console.log(e, e)
            onError(e);
        } finally {
            setIsLoading(false);
        }

    }

    async function handleClean(event) {
        event.preventDefault();
        setIsLoading(true);

        try {
            const time = Date.now()
            console.log("Cleaning %s %s to %s %s...", targetOperator)

            await API.post("events", `/events`, {
                body: {
                    source: 'busable.cleanData',
                    detailType: 'CleanData',
                    detail: {
                        operatorId: targetOperator,
                        dryrun: false
                    }
                }
            });
            // await API.get("operators", `/cleanOperator/${targetOperator}`)
            console.log("Cleaning done... Took %s", toHrsMinsSecs((Date.now() - time) / 1000))

        } catch (e) {
            console.log(e, e)
            onError(e);
        } finally {
            setIsLoading(false);
        }

    }

    async function handleDelete(event) {
        event.preventDefault();
        setIsLoading(true);

        try {
            const time = Date.now()
            let sourceOperators = sourceOperator
            if (!Array.isArray(sourceOperators)) {
                sourceOperators = [sourceOperator]
            }
            await Promise.all(sourceOperators.map(async sourceOperator => {
                console.log("Deleting operator %s...", sourceOperator)
                console.log(`/operators/${sourceOperator}`)
                await API.post("events", `/events`, {
                    body: {
                        source: 'busable.removeOperator',
                        detailType: 'RemoveOperator',
                        detail: {operatorId: sourceOperator}
                    }
                });
                // await API.del("routes", `/operators/${sourceOperator}`)
                console.log("Deleting done... Took %s", toHrsMinsSecs((Date.now() - time) / 1000))
            }))
            if (sourceOperators.includes(apiKey)) {

                console.log('Resetting cache...');
                await routesModelExpiryService.clearDb();

                console.log('Setting operator: demo')
                try {
                    store.session.set("busable-operator-id", "demo")
                    await setUserOperator({operatorId: "demo", companyId: "busable"});
                    window.location = window.location.origin + 'refresh'
                } catch (e) {
                    console.log(e);
                }
            }
        } catch (e) {
            console.log(e, e)
            onError(e);
        } finally {
            setIsLoading(false);
        }

    }

    const items = [
        {
            key: 'copy',
            label: 'Copy',
            children: <div>
                <h5>Copy operator</h5>
                <p>Source stage: <Input value={sourceStage} onChange={e => setSourceStage(e.target.value)}/></p>
                <p>Target stage: {process.env.REACT_APP_STAGE}</p>
                <p>Source operator: <Select style={{width: '100%'}} dropdownStyle={{zIndex: 12001}}
                                            value={sourceOperator}
                                            onChange={setSourceOperator}
                                            options={operatorOpts?.slice(1)}/></p>
                <p>Source operator ID: <Input value={sourceOperator} onChange={e => setSourceOperator(e.target.value)}/>
                </p>
                <p>Target operator: <Select style={{width: '100%'}} dropdownStyle={{zIndex: 12001}}
                                            value={targetOperator}
                                            onChange={setTargetOperator}
                                            options={operatorOpts}/></p>
                <Checkbox checked={optimise} onChange={e => setOptimise(e.target.checked)}/> Optimise routes
                <Checkbox className="ml-2"
                          disabled={(sourceOperator === targetOperator && sourceStage === process.env.REACT_APP_STAGE) || process.env.REACT_APP_STAGE === 'prod'}
                          checked={remove} onChange={e => setRemove(e.target.checked)}/><Tooltip
                title={`Remove all data from ${process.env.REACT_APP_STAGE}: ${targetOperator} and start fresh.`}> Force
                clean {process.env.REACT_APP_STAGE}: {targetOperator}</Tooltip>
                <p><Popconfirm
                    zIndex={10000}
                    title={`Are you sure you want to copy ${sourceStage}:${sourceOperator} -> ${process.env.REACT_APP_STAGE}:${targetOperator === '_new_' ? sourceOperator + "_" + dayjs().format('YYYYMMDD') : targetOperator}`}
                    onConfirm={handleSubmit}><LoaderButton
                    isLoading={isLoading}>Copy</LoaderButton></Popconfirm></p>
            </div>
        },
        {
            key: 'delete',
            label: 'Delete',
            children: <DeleteTab handleDelete={handleDelete} sourceOperator={sourceOperator} isLoading={isLoading}
                                 setSourceOperator={setSourceOperator} operatorOpts={operatorOpts} apiKey={apiKey}/>
        },
        {
            key: 'clean',
            label: 'Clean',
            children: <CleanTab handleClean={handleClean} targetOperator={targetOperator} isLoading={isLoading}
                                setTargetOperator={setTargetOperator} operatorOpts={operatorOpts}/>
        },
    ];

    return (
        <> {isAdmin ? <>
            <Nav.Link onClick={() => setVisible(true)}>
                {isLoading ? <SyncOutlined spin/> : <></>} Admin
            </Nav.Link>
            <Modal
                open={visible}
                width={800}
                title={`Admin stuff`}
                onCancel={() => setVisible(false)}
                destroyOnClose
                footer={[
                    <Button key="back" className="btn-secondary" onClick={() => setVisible(false)}>
                        Close
                    </Button>,
                ]}
            >
                <div className="Admin">
                    <div className="row">
                        <div className="col-lg-12">
                            <Tabs defaultActiveKey="copy" items={items}/>
                        </div>
                    </div>
                </div>
            </Modal></> : <></>}
        </>
    );
}
