import React, {useCallback, useEffect, useState} from 'react';
import './Rosters.css';
import {Button, DatePicker, Flex, Tabs} from 'antd';
import {ReactComponent as Close} from '../../assets/icons/Close.svg';
import {CheckOutlined, PlusOutlined, SyncOutlined, CopyOutlined, DragOutlined} from '@ant-design/icons';
import DateSelector from './DateSelector';
import useAll from '../../hooks/useAll';
import {rosterModelData} from '../../services/ModelService';
import {WeeklyRoster, WeeklyScenario} from '../../model/roster';
import useAllShiftBats from '../../hooks/useAllShiftBats';
import LoadMessage from '../../components/LoadMessage';
import useAllSchedules from '../../hooks/useAllSchedules';
import dayjs from '../../dayjs';
import {find} from 'lodash';
import WeeklyScenarioView from './WeeklyScenarioView';
import useAllEmployees from '../../hooks/useAllEmployees';
import useAllVehicles from '../../hooks/useAllVehicle';
import {ulid} from 'ulid';
import EditPopover from '../../components/EditPopover';
import useAllStops from '../../hooks/useAllStops';
import useAllRoutes from '../../hooks/useAllRoutes';
import {ComplianceDisclaimerModal} from './ComplianceDisclaimerModal';
import {useAppContext} from '../../libs/contextLib';
import {Prompt} from 'react-router-dom';
import {ReactComponent as Check} from '../../assets/icons/Check.svg';

function Rosters() {
    const {setBreadcrumb, operator} = useAppContext();
    const [mode, setMode] = useState('');
    const [view, setView] = useState('weekly');
    const [isLoading, setIsLoading] = useState(true);
    const [weeklyScenarios, setWeeklyScenarios] = useState([]);
    const [selectedScenario, setSelectedScenario] = useState(null);
    const [showDashboards, setShowDashboards] = useState(true);
    const [calculatingScenario, setCalculatingScenario] = useState(false);

    const [savingScenario, setSavingScenario] = useState(false);
    const employeeData = useAllEmployees();
    const {allVehicles} = useAllVehicles();
    const {allStops} = useAllStops();
    const {allRoutes} = useAllRoutes();

    const [selectedDate, setSelectedDate] = useState(date => !date ? dayjs().startOf('week') : date);
    const {
        all: allRosters,
        allAsArray: allRostersAsArray,
        setAll: setAllRosters
    } = useAll({modelService: rosterModelData});

    const [previousRoster, setPreviousRoster] = useState(null);

    const {allShiftBatsArray} = useAllShiftBats({filterFn: sb => sb.published >= 0});
    const {allSchedules} = useAllSchedules();

    const [unallocatedRoster, setUnallocatedRoster] = useState(null);
    const [unallocatedCount, setUnallocatedCount] = useState(0);

    const [showImportScenarios, setShowImportScenarios] = useState(false);

    useEffect(() => {
        const beforeunload = (e) => {
            // if (updated) {
                e.returnValue = 'unloading';
                return 'unloading';
            // }
        };
        window.addEventListener('beforeunload', beforeunload);
        return () => {
            window.removeEventListener('beforeunload', beforeunload);
        };
    }, []);

    const save = useCallback(weeklyScenarios => {
        if (!weeklyScenarios?.length) return;
        setSavingScenario(true);
        rosterModelData.save({
            rosterId: selectedDate.format('YYYYMMDD'),
            date: selectedDate,
            scenarios: weeklyScenarios
        }).then(() => {
            setSavingScenario(false);
        });
    }, [selectedDate, setSavingScenario]);

    useEffect(() => {
        if (!allRosters || !setPreviousRoster) return;
        const lastWeeksRoster = allRosters?.[selectedDate?.clone().subtract(1, 'week')?.format('YYYYMMDD')];
        setPreviousRoster(lastWeeksRoster?.scenarios?.filter(scenario => scenario.active)?.[0] || lastWeeksRoster?.scenarios?.[0]);
    }, [allRosters, selectedDate, setPreviousRoster]);

    useEffect(() => {
        if (!allShiftBatsArray || !selectedDate || !allSchedules || !setUnallocatedRoster || !setUnallocatedCount || !setIsLoading) return;
        let date = selectedDate.clone();
        const unallocatedRoster = WeeklyRoster.from({rosterId: 'unallocated', rosterName: 'Unallocated', date});
        let count = 0;
        for (let i = 0; i < 7; i++) {
            allShiftBatsArray.forEach(duty => {
                if (duty.isRunning(date, allSchedules)) {
                    unallocatedRoster.addDuty(date.weekday(), duty);
                    count++;
                }
            });
            date = date.add(1, 'day');
        }
        setUnallocatedCount(count);
        setUnallocatedRoster(unallocatedRoster);
        setIsLoading(false);
        console.log('SET UNALLOCATED ROSTER');
    }, [allShiftBatsArray, setUnallocatedRoster, setUnallocatedCount, setIsLoading, allSchedules, selectedDate]);

    const importScenarios = useCallback((weeklyScenarios, replace = false) => {
        weeklyScenarios = weeklyScenarios.map(scenario => {
            scenario.unallocatedRoster = unallocatedRoster.clone();
            scenario.updateUnallocatedRoster();
            scenario.setDate(selectedDate);
            if (!replace) {
                scenario.scenarioId = ulid();
                delete scenario.active;
            }
            return scenario;
        });
        setWeeklyScenarios(current => (replace || !current) ? weeklyScenarios : current.concat(weeklyScenarios));
        setSelectedScenario(scenario => scenario ? scenario : weeklyScenarios.find(s => s.active) || weeklyScenarios[0]);
        setShowImportScenarios(false);
    }, [unallocatedRoster, selectedDate, setWeeklyScenarios, setShowImportScenarios]);

    useEffect(() => {
        if (!setSelectedScenario || !setWeeklyScenarios || !unallocatedRoster ||
            !selectedDate || !allRostersAsArray || !allRosters) return;

        //Create a new scenario if no allRostersAsArray
        const weeklyScenarios = allRosters[selectedDate.format('YYYYMMDD')]?.scenarios;
        if (!weeklyScenarios?.length) {
            const scenario = WeeklyScenario.from({
                date: selectedDate,
                name: 'Scenario 1',
                weeklyRosters: [WeeklyRoster.from({
                    date: selectedDate,
                    rosterName: 'ROS-1'
                })],
                unallocatedRoster
            });
            setSelectedScenario(scenario);
            setWeeklyScenarios(null);
        } else {
            importScenarios(weeklyScenarios, true);
        }
    }, [setSelectedScenario, setWeeklyScenarios, unallocatedRoster, allRosters,
        allRostersAsArray, selectedDate, importScenarios]);

    useEffect(() => {
        const prefix = view === 'weekly' ? 'Weekly' : 'Daily';
        const breadcrumb = mode === 'vehicle' ? 'Vehicle Roster' : mode === 'staff' ? 'Staff Roster' : 'Rosters';
        setBreadcrumb(`${prefix} ${breadcrumb}`);
    }, [mode]);

    const setScenario = useCallback(newScenario => {
        if (typeof newScenario === 'function') {
            newScenario = newScenario(selectedScenario);
        }
        setWeeklyScenarios(weeklyScenarios => weeklyScenarios.map(s => s.scenarioId === newScenario.scenarioId ? WeeklyScenario.from(newScenario) : s));
    }, [selectedScenario, setWeeklyScenarios]);

    const ImportScenarios = useCallback(() => {
        return <Flex justify={'center'}>
            <Button onClick={() => {
                const scenario = WeeklyScenario.from({
                    date: selectedDate,
                    name: 'Active',
                    weeklyRosters: [WeeklyRoster.from({
                        date: selectedDate,
                        rosterName: 'ROS-1'
                    })],
                    unallocatedRoster
                })
                importScenarios([scenario]);
                setSelectedScenario(scenario);
            }}>Create new weekly roster</Button>

            <Button
                disabled={!allRosters?.[selectedDate?.subtract(1, 'week').format('YYYYMMDD')]?.scenarios?.length}
                onClick={() => {
                    const weeklyScenarios = allRosters?.[selectedDate?.subtract(1, 'week').format('YYYYMMDD')]?.scenarios || [];
                    importScenarios(weeklyScenarios);
                }}>Import last weeks scenarios</Button>
            <div>
                <label>Choose week to import</label>
                <DatePicker picker={'week'} onChange={date => {
                    const weeklyScenarios = allRosters?.[date.startOf('week').format('YYYYMMDD')]?.scenarios || [];
                    importScenarios(weeklyScenarios);
                }}/></div>
        </Flex>;
    }, [importScenarios, selectedDate, allRosters]);

    console.log('SELECTED: ', selectedScenario?.scenarioId);
    return (
        <>

            <Prompt
                when={true}
                message="You have unsaved changes. Are you sure you want to leave?"
            />

            {allShiftBatsArray ? <>
                    <div className="d-flex align-items-center justify-content-end filter-options-main top-float">
                        <div className="d-flex justify-content-end w-separator" style={{gap: '10px'}}>
                            <ComplianceDisclaimerModal/>
                            <Button onClick={() => setShowImportScenarios(!showImportScenarios)}
                                    type="primary"
                                    className={`icon-button ${showImportScenarios ? 'active' : ''}`}>Import</Button>
                            <Button
                                disabled={!weeklyScenarios?.length}
                                onClick={() => save(weeklyScenarios)} type="primary" className="icon-button btn-filled btn-success"
                                icon={savingScenario ? <SyncOutlined spin={true}/> :
                                    <Check/>}>Save</Button>

                            {/*<Button type="primary" className="icon-button" onClick={() => {*/}
                            {/*    setMode('vehicle');*/}
                            {/*}}> Vehicles</Button>*/}
                            {/*<Button type="primary" className="icon-button" onClick={() => {*/}
                            {/*    setMode('staff');*/}
                            {/*}}> Staff</Button>*/}
                        </div>
                        <div className="filter-dropdown w-separator">
                            <DateSelector initialDate={selectedDate} onChange={date => {
                                save(weeklyScenarios);
                                setSelectedDate(date);
                            }} view={view}/>
                        </div>
                        <Button onClick={() => {
                                              setShowDashboards(!showDashboards);
                                          }} type="primary"
                                                  className={`icon-button ${showDashboards ? 'active' : ''}`}>Dashboard</Button>
                        {/* todo - uncomment this! */}
                        {/*<div className="justify-content-end">*/}

                        {/*    <Button onClick={() => {*/}
                        {/*        setView('daily');*/}
                        {/*    }} type="secondayr" className={`icon-button ${view === 'daily' ? 'active' : ''}`}>Daily</Button>*/}

                        {/*    <Button onClick={() => {*/}
                        {/*        setView('weekly');*/}
                        {/*    }} type="primary" className={`icon-button ${view === 'weekly' ? 'active' : ''}`}>Weekly</Button>*/}

                        {/*    /!*<Button type="primary"*!/*/}
                        {/*    /!*        className={`icon-button btn-filled`}*!/*/}
                        {/*    /!*        onClick={async () => {*!/*/}
                        {/*    /!*            setCalculatingScenario(true);*!/*/}
                        {/*    /!*            // const newScenario1 = await JobScenario.smartAllocate(unallocatedShiftBats, loadedVehicles, selectedDate);*!/*/}
                        {/*    /!*            const newScenario2 = await JobScenario.allocate(unallocatedShiftBats, loadedVehicles, selectedDate);*!/*/}
                        {/*    /!*            setScenarios([...scenarios, newScenario2]);*!/*/}
                        {/*    /!*            setCalculatingScenario(false);*!/*/}
                        {/*    /!*        }}>Auto scenarios</Button>*!/*/}
                        {/*</div>*/}
                    </div>
                    {showImportScenarios && <ImportScenarios/>}
                    <div className="dispatch-list">
                        {weeklyScenarios?.length ?
                            <Tabs hideAdd
                                  size={'middle'}
                                  onChange={scenarioId => {
                                      const scenario = find(weeklyScenarios, {scenarioId});
                                      setSelectedScenario(scenario);
                                  }}
                                  activeKey={selectedScenario?.scenarioId || weeklyScenarios[0]?.scenarioId}
                                  type="card"
                                  tabBarExtraContent={
                                      <div className="d-flex align-items-center">
                                          <Button onClick={() => {
                                              const _weeklyScenarios = weeklyScenarios.map(scenario => WeeklyScenario.from({
                                                  ...scenario,
                                                  active: scenario.scenarioId === selectedScenario?.scenarioId ? true : undefined
                                              }));
                                              setWeeklyScenarios(_weeklyScenarios);
                                              save(_weeklyScenarios);
                                          }} type="primary" className="icon-button mr-2"
                                                  icon={savingScenario ? <SyncOutlined spin={true}/> :
                                                      <CheckOutlined/>}>Activate</Button>

                                          {/* TODO: Add new empty Scenario */}
                                          <Button onClick={() => {
                                              const scenario = WeeklyScenario.from({
                                                  name: 'Scenario ' + (weeklyScenarios.filter(s => s.name.startsWith('Scenario')).length + 1),
                                                  date: selectedDate,
                                                  weeklyRosters: [WeeklyRoster.from({date: selectedDate})],
                                                  unallocatedRoster
                                              });
                                              setWeeklyScenarios([...weeklyScenarios, scenario]);
                                              setSelectedScenario(scenario);
                                          }} type="primary" className="icon-button mr-2"
                                                  icon={<PlusOutlined/>}>Add</Button>
                                          <Button onClick={() => {
                                              const scenario = selectedScenario.clone();
                                              scenario.scenarioId = ulid();
                                              setWeeklyScenarios([...weeklyScenarios, scenario]);
                                              setSelectedScenario(scenario);
                                          }} type="primary" className="icon-button mr-2"
                                                  icon={<CopyOutlined/>}>Copy</Button>
                                          <Button onClick={async () => {
                                              setCalculatingScenario(true);
                                              const newScenario = await WeeklyScenario.smartAllocate(allShiftBatsArray, allSchedules, {
                                                  date: selectedDate,
                                                  name: 'Auto ' + weeklyScenarios.filter(s => s.name.startsWith('Auto')).length + 1
                                              });
                                              // setShowDashboards(!!newScenario.unallocatedRoster?.getTotalJobs())
                                              setWeeklyScenarios([...weeklyScenarios, newScenario]);
                                              setSelectedScenario(newScenario);
                                              setCalculatingScenario(false);
                                          }} type="primary" className="icon-button mr-2"
                                                  icon={calculatingScenario ?
                                                      <SyncOutlined spin={true}/> :
                                                      <DragOutlined style={{fontSize: 13}}/>}>Auto</Button>
                                            <Button danger={true}
                                                  onClick={() => {
                                                      const idx = weeklyScenarios.findIndex(scenario => scenario.scenarioId === selectedScenario.scenarioId)
                                                      weeklyScenarios.splice(idx, 1)
                                                      setWeeklyScenarios(weeklyScenarios);
                                                      setSelectedScenario(weeklyScenarios[weeklyScenarios.length === 1 || idx === 0 ? 0 : idx-1]);
                                                  }} type="primary"
                                                  className="icon-button btn-filled btn-error icon-10"
                                                  icon={<Close/>}>Remove</Button>
                                      </div>}
                                  items={weeklyScenarios.map(scenario => {
                                      return {
                                          key: scenario.scenarioId,
                                          label: <Flex justify={'space-between'} align={'center'} style={{gap: 6}}>
                                              {
                                                  selectedScenario?.scenarioId === scenario.scenarioId ?
                                                      <EditPopover save={value => {
                                                          setScenario({
                                                              ...scenario,
                                                              name: value
                                                          });
                                                      }} value={scenario.name} title={'Edit scenario name'}/> :
                                                      <div>{scenario.name}</div>
                                              }
                                              {scenario.active && <span className="icon-success"><Check/></span>}
                                          </Flex>,
                                          children:
                                              <WeeklyScenarioView // originalScenario={scenario}
                                                  scenario={scenario}
                                                  setScenario={setScenario}
                                                  employeeData={employeeData}
                                                  allVehicles={allVehicles}
                                                  allSchedules={allSchedules}
                                                  showDashboards={showDashboards}
                                                  setShowDashboards={setShowDashboards}
                                                  calculatingScenario={calculatingScenario}
                                                  setCalculatingScenario={setCalculatingScenario}
                                                  isLoading={isLoading}
                                                  previousRoster={previousRoster}
                                                  unallocatedRoster={unallocatedRoster}
                                                  allStops={allStops}
                                                  allRoutes={allRoutes}
                                                  operator={operator}
                                              />
                                      };
                                  })}/> :
                            <ImportScenarios/>
                        }
                    </div>
                </>
                :
                <LoadMessage message={'Loading Rosters...'}/>
            }
        </>
    );
}


export default React.memo(Rosters);
