import {useEffect, useState} from 'react';
import {jobModelData} from '../services/ModelService';
import {keyBy, values} from 'lodash';

const useJobsForDate = ({date, type}) => {
    const [allJobs, setAllJobs] = useState(null);
    const [allJobsAsArray, setAllJobsAsArray] = useState(null);
    const [employeeJobs, setEmployeeJobs] = useState(null);
    const [vehicleJobs, setVehicleJobs] = useState(null);

    useEffect(() => {

        const setJobs = (jobs) => {
            setAllJobs(jobs);
            setAllJobsAsArray(values(jobs));
        };

        const jobListener = jobModelData.addListener({
            setterFn: jobs => setJobs(jobs), loaded: jobs => setJobs(jobs)
        });

        if (date) {
            jobModelData.fetchForDate(date).then(jobs => {
                setAllJobs(jobs ? keyBy(jobs, 'jobId') : {});
                setAllJobsAsArray(jobs || []);
            }).catch(e => console.error(e));
        }

        return () => {
            if (jobListener)
                jobModelData.removeListener(jobListener);
        };
    }, [setAllJobs, setAllJobsAsArray, date]);

    useEffect(() => {
        if (allJobs) {
            const employeeJobs = values(allJobs).filter(job => job.allocationType === 'employee');
            setEmployeeJobs(employeeJobs);
            setVehicleJobs(values(allJobs).filter(job => job.allocationType === 'vehicle'));
        }
    }, [allJobs, setEmployeeJobs, setVehicleJobs, type]);

    return {
        allJobs,
        allJobsAsArray,
        setAllJobs,
        employeeJobs,
        vehicleJobs
    };
};

export default useJobsForDate;

