import React from 'react'
import {InboxOutlined} from "@ant-design/icons";
import {Upload, Modal} from 'antd/lib'
import {Button} from "antd";

const {Dragger} = Upload;

function UploadModal({visible, setVisible, handleUpload, uploading, msgHdr, msgBdy, ...props}) {

    return (
        <Modal open={visible}
               destroyOnClose
               footer={[
                   <Button key="back" className="btn-secondary" onClick={() => {
                       setVisible(false)
                   }}>
                       {'Cancel'}
                   </Button>,
                   <Button
                       type="primary"
                       onClick={handleUpload}
                       loading={uploading}
                       style={{
                           marginTop: 16,
                       }}
                   >
                       {'Import'}
                   </Button>,
               ]}
        >
            <Dragger {...props}>
                <p className="ant-upload-drag-icon">
                    <InboxOutlined style={{color: '#6B54F9'}}/>
                </p>
                <p className="ant-upload-text">{msgHdr || 'Drag and drop file here'}</p>
                <p>(Or click to browse)</p>
                <p className="ant-upload-hint">
                    {msgBdy || 'Upload here.'}
                </p>
            </Dragger>
        </Modal>
    )
}

export default React.memo(UploadModal)
