import { useCallback, useEffect, useState } from "react";
import { pickBy, values } from "lodash";
import { useParams } from "react-router-dom";
import { useAppContext } from "../libs/contextLib";
import { employeeModelData } from "../services/ModelService";
import { Employee } from "../model/hrm/employee";
import { Employment } from "../model/hrm/employment";
import { Entitlement } from "../model/hrm/entitlement";
import { Banking } from "../model/hrm/banking";
import { Superannuation } from "../model/hrm/superannuation";
import { Tax } from "../model/hrm/tax";
import { License } from "../model/hrm/license";
import { Qualification } from "../model/hrm/qualifications";
import { Training } from "../model/hrm/training";
import { Leave } from "../model/hrm/leave";
import { startsWith } from "lodash";

const opts = { summary: true };

const useEmployeeData = () => {
    const { id } = useParams();
    const { apiKey } = useAppContext();
    const [employee, setEmployee] = useState({});
    const [originalEmployee, setOriginalEmployee] = useState({});
    const [data, setData] = useState(null)
    const [loading, setLoading] = useState(false);

    const idPrefix = `#EMPLOYEE#${id}`;

    const getEmployeeCount = useCallback((employees) => {
        return values(pickBy(employees, (v, k) => k.endsWith("#EXTERNAL"))).length ?? 0;
    }, []);

    const getStaffGroups = useCallback((employees) => {
        const staffGroups = values(pickBy(employees, (v, k) => k.endsWith("#EMPLOYMENT")))
            .flatMap((employee) => employee.staffGroups || [])
            .filter((value, index, self) => self.indexOf(value) === index);
        return staffGroups;
    }, []);

    const setEmployeeData = useCallback(
        (e, leaves) => {
            const external = e[`${idPrefix}#EXTERNAL`] || {};
            const data = {
                external,
                details: e[`${idPrefix}#DETAILS`] ? new Employee(e[`${idPrefix}#DETAILS`]) : {},
                employment: e[`${idPrefix}#EMPLOYMENT`] ? new Employment(e[`${idPrefix}#EMPLOYMENT`]) : {},
                entitlement: e[`${idPrefix}#ENTITLEMENT`] ? new Entitlement(e[`${idPrefix}#ENTITLEMENT`]) : {},
                banking: e[`${idPrefix}#BANKING`] ? new Banking(e[`${idPrefix}#BANKING`]) : {},
                superannuation: e[`${idPrefix}#SUPERANNUATION`] ? new Superannuation(e[`${idPrefix}#SUPERANNUATION`]) : {},
                tax: e[`${idPrefix}#TAX`] ? new Tax(e[`${idPrefix}#TAX`]) : {},
                licenses: values(pickBy(e, (v, k) => startsWith(k, `${idPrefix}#LICENSE`))).map((e) => new License(e)),
                qualifications: values(pickBy(e, (v, k) => startsWith(k, `${idPrefix}#QUALIFICATION`))).map((e) => new Qualification(e)),
                trainings: values(pickBy(e, (v, k) => startsWith(k, `${idPrefix}#TRAINING`))).map((e) => new Training(e)),
                leaves: values(pickBy(e, (v, k) => startsWith(k, `${idPrefix}#LEAVE`))).map((e) => new Leave(e, external)),
                totalEmployees: getEmployeeCount(e),
                staffGroups: getStaffGroups(e),
            };
            setEmployee(data);
            setOriginalEmployee(data);
        },
        [getEmployeeCount, getStaffGroups, idPrefix]
    );

    useEffect(() => {
        setLoading(true);

        const listener = {
            setterFn: setData,
            loaded: emp => setData(emp)
            // loaded: async (e) => {
            //     try {
            //         setLoading(false);
            //         setEmployeeData(e);
            //     } catch (error) {
            //         console.error(error);
            //     }
            // },
            // refresh: async () => {
            //     try {
            //         console.log("Employees refreshed.");
            //         const e = await employeeModelData.getAll(opts, `#EMPLOYEE#`);
            //         setEmployeeData(e);
            //     } catch (error) {
            //         console.error(error);
            //     }
            // },
        };

        employeeModelData.addListener(listener);

        return () => {
            employeeModelData.removeListener(listener);
        };
    }, [apiKey, id, setEmployeeData]);

    useEffect(() => {
        if(data) setEmployeeData(data);
    }, [data, setEmployeeData])

    const resetEmployee = () => {
        setEmployee(originalEmployee);
    };

    const hasChanges = useCallback(() => {
        return JSON.stringify(employee) !== JSON.stringify(originalEmployee);
    }, [employee, originalEmployee]);

    return [employee, setEmployee, resetEmployee, hasChanges];
};

export default useEmployeeData;
