import React, {memo, useCallback, useMemo} from 'react';
import {Table} from 'antd';
import './Rosters.css';
import {WeeklyRoster} from '../../model/roster';
import {capitalize, values} from 'lodash';
import dayjs from '../../dayjs';
import {WorkItemForDay} from './WorkItemForDay';
// import weeklyRosters from './WeeklyRosters';

// const WorkItemView = memo(({workItem, selected, unallocated, onClick, statusColour}) => {
//     return <div
//         style={{cursor: 'pointer'}}
//         key={`work-item-div-${workItem.workItemId}`}
//         className={`roster-item ${unallocated ? 'item-unallocated' : ''} ${selected ? 'item-selected' : ''} ${workItem?.optional ? 'item-optional' : ''}`}
//         onClick={onClick}>
//         {!unallocated ?
//             <Allocation {...workItem}
//                         statusColour={statusColour}/> : <></>}
//         <div>
//             <div className="roster-name mb-1">{workItem.name}</div>
//             <div
//                 className="roster-time">{workItem.startTimeFormatted}-{workItem.endTimeFormatted}</div>
//         </div>
//     </div>;
// });

export const WeeklyUnallocatedTable = memo(({
                                                scenario,
                                                selectedWorkItem,
                                                setSelectedWorkItem,
                                                scroll = {y: 300}
                                            }) => {

    const onSelectWorkItem = useCallback((workItem, selectedWorkItem) => {
        if (selectedWorkItem && selectedWorkItem.workItemId === workItem?.workItemId) {
            setSelectedWorkItem(null);
        } else {
            setSelectedWorkItem(workItem);
        }
    }, [setSelectedWorkItem]);

    const workItemRenderer = useCallback((workItems, roster, selectedWorkItem) => {
        return <WorkItemForDay roster={roster} workItems={workItems} selectedWorkItem={selectedWorkItem}
                               onSelectWorkItem={onSelectWorkItem} unallocated={true}/>;
    }, [setSelectedWorkItem, onSelectWorkItem]);

    const workItemColumns = useMemo(() => {
        return Array(7).fill().map((_, day) => ({
            title: capitalize(dayjs().weekday(day).format('dddd')), dataIndex: day,
            render: (_, roster) => {
                if (!roster) return <div>Loading...</div>;
                return workItemRenderer(roster.workItems[day], roster, selectedWorkItem);
            }
        }));
    }, [workItemRenderer, selectedWorkItem]);

    const columns = useMemo(() => {
        return [{
            title: 'Roster',
            dataIndex: 'rosterName',
            render: (_, roster)=> <div className="roster-cell-main"><div className="roster-name">{roster.rosterName}</div></div>
        }].concat(workItemColumns);

    }, [workItemColumns]);

    const unallocatedRosters = useMemo(() => {
        let time = Date.now();
        const weeklyRosters = {};
        scenario.unallocatedRoster?.workItems.forEach((workItemsForDay, day) => {
            workItemsForDay
                .forEach(workItem => {
                    const rosterId = workItem.getDuty().shiftBatId;
                    let roster = weeklyRosters[rosterId];
                    if (!roster) {
                        roster = WeeklyRoster.from({
                            rosterId, key: rosterId, startTime: workItem.getDuty().getStartTime(), rosterName: workItem.getDuty().shiftBatNumber
                        });
                        weeklyRosters[rosterId] = roster;
                    }
                    roster.workItems[day].push(workItem);
                    // roster.addWorkItem(workItem);
                });
        });

        console.log('WeeklyRoster unallocated data: ', Date.now() - time, 'ms');
        return values(weeklyRosters).sort((a, b) => a.startTime - b.startTime);
    }, [scenario?.unallocatedRoster?.workItems]);

    return (<>{unallocatedRosters?.length ?
        <div className={'weekly-roster-table unallocated-roster-table'}>
            <Table dataSource={unallocatedRosters} columns={columns}
                // expandable={{
                //     onExpand: (expanded, record) => {
                //         if (expanded) {
                //             setExpandedRosterIds([...expandedRosterIds, record.rosterId]);
                //         } else {
                //             setExpandedRosterIds(expandedRosterIds.filter(id => id !== record.rosterId));
                //         }
                //     },
                //     // expandedRowRender: (record) => (
                //     //     <p
                //     //         style={{
                //     //             margin: 0,
                //     //         }}
                //     //     >
                //     //         {record.description}
                //     //     </p>
                //     // ),
                //     expandRowByClick: true,
                // }}
                   pagination={false}
                //    virtual
                //    scroll={scroll}
                   rowClassName={() => 'unallocated-roster'}
            />
        </div> : <></>}
    </>);
});

