import {
    cloneDeep,
    find,
    isEmpty,
    isEqual,
    isObject,
    sortBy,
    startCase,
    union,
    unionBy,
    unionWith,
    uniqBy,
    values
} from "lodash";
import dayjs from "../dayjs";
import {ALL_FUTURE_TERMS, DATE_STRING, OPERATING_DAY_DEFAULTS, Period} from "./schedule";
import {ulid} from "ulid";
import {Incident} from "./incident";
import {flatten} from "lodash/array";
import {get, invoke, keys, omit, transform} from "lodash/object";
import {reduce} from "lodash/collection";
import {Stop} from "./busRoute";
import {getDistanceInMetres} from "../libs/routes-lib";
import {TravelSchedule} from "./travelSchedule";

export const importStudents = async (csvStr, parseSync, existingStudents, schoolStops) => {
    const csvStudents = parseSync(csvStr, {
        columns: true,
        skip_empty_lines: true,
        comment: '#',
        comment_no_infix: true
    })
    const importedStudents = []
    let errors = false;
    csvStudents.forEach(csvStudent => {
        try {
            importedStudents.push(Student.fromCsvRow(csvStudent, schoolStops));
        } catch (e) {
            console.log("Error parsing student: " + e, csvStudent)
            errors = true
        }
    })
    if (errors) {
        throw new Error("Some students could not be imported. Please check the file for errors.")
    }
    try {
        const newStudents = importedStudents.filter(student => !existingStudents[student.authorityId])
        const existingImportedStudents = importedStudents.filter(student => existingStudents[student.authorityId])

        // const updatedStudents = existingImportedStudents.filter(importedStudent => {
        //     const existingStudent = existingStudents[importedStudent.authorityId]
        //     // If existing student is in review, replace it with the imported student
        //     if (existingStudent.status === 'review') {
        //         importedStudent.studentId = existingStudent.studentId;
        //         return true;
        //     }
        //     if (existingStudent.isSameScheme(importedStudent)) {
        //         return true;
        //     }
        //     // If the existing scheme pass has expired and the imported pass is not expired, replace it
        //     if (existingStudent.isSchemeExpired(importedStudent.travelPasses?.[0]?.schemeId) && !importedStudent.travelPasses?.[0]?.isExpired()) {
        //         importedStudent.travelPasses[0].status = 'active';
        //         existingStudent.merge(importedStudent);
        //         // studentModelData.save(existingStudent).then(() => console.log("Student updated."))
        //     }
        //     return false;
        // })
        const updatedStudents = [];
        existingImportedStudents.filter(importedStudent => {
            const existingStudent = existingStudents[importedStudent.authorityId]
            // If existing student is in review, replace it with the imported student
            if (existingStudent.status === 'review') {
                importedStudent.studentId = existingStudent.studentId;
                console.log(`Exising student ${existingStudent.name()} is in review. Replacing with imported student`)
                updatedStudents.push(importedStudent)
                return
            }
            if (existingStudent.isSameScheme(importedStudent)) {
                updatedStudents.push(importedStudent)
                console.log(`Exising student ${existingStudent.name()} has the same scheme. Replacing with imported student`)
                return
            }
            // If the existing scheme pass has expired and the imported pass is not expired, replace it
            if (existingStudent.isSchemeExpired(importedStudent.travelPasses?.[0]?.schemeId) && !importedStudent.travelPasses?.[0]?.isExpired()) {
                importedStudent.travelPasses[0].status = 'active';
                existingStudent.merge(importedStudent);
                updatedStudents.push(existingStudent)
                console.log(`Exising student ${existingStudent.name()} scheme has expired. Replacing with imported student`)
            }
        })
        console.log(`New students: ${newStudents.length}, Updated students: ${updatedStudents.length}`)
        let studentsToSave = newStudents.concat(updatedStudents)
        // studentsToSave.forEach(student => {
        //     values(existingStudents).concat(studentsToSave).forEach(otherStudent => {
        //         if (student.status !== 'archived' && student.studentId !== otherStudent.studentId && student.authorityId === otherStudent.authorityId) {
        //             student.merge(otherStudent);
        //             otherStudent.status = 'archived'
        //         }
        //     })
        // })

        const allStudents = unionBy(studentsToSave, values(existingStudents), 'authorityId')
        allStudents.forEach(student => {
            const currentWarnings = student.warnings || [];
            student.warnings = student.validateStudent(allStudents)
            if (!isEqual(sortBy(currentWarnings), sortBy(student.warnings))) {
                studentsToSave.push(student)
            }
        });
        studentsToSave = uniqBy(studentsToSave, 'studentId')
        studentsToSave.forEach(student => {
            if (student.travelPasses.some(tp => tp.status === 'review')) {
                console.log(`Student ${student.name()} has a travel pass in review. Setting student to review`)
                student.status = 'review'
            }
        })
        return {studentsToSave, newStudents, updatedStudents};
    } catch (e) {
        console.log(e, e);
    }
}


const isWhiteSpaceOnly = str => !/\S/.test(str)
const findClosestStop = (stops, otherStop) => stops.reduce((closest, stop) => {
    const distance = getDistanceInMetres(stop, otherStop)
    if (!closest || distance < closest.distance) {
        return {stop, distance}
    }
    return closest
}, null)

export const getResult = (obj, key) => {
    const result = get(obj, key)
    if (typeof result === 'function') {
        return invoke(obj, key)
    }
    return result

}

const convertDate = (dateStr) => {
    dateStr = dateStr?.trim();
    if (!dateStr?.length) {
        return null
    }
    let date = dayjs(dateStr, "DD/MM/YYYY")
    if (date.isValid()) {
        return date;
    }
    date = dayjs(dateStr, "D/MM/YYYY")
    if (date.isValid()) {
        return date;
    }
    date = dayjs(dateStr, "DD/M/YYYY")
    if (date.isValid()) {
        return date;
    }
    date = dayjs(dateStr, "D/M/YYYY")
    if (date.isValid()) {
        return date;
    }
}

export const rankObjects = (mainObj, otherObjs, weightedKeys) => {
    return otherObjs
        .map(obj => {
            const rank = reduce(weightedKeys, (acc, keyWeight) => {
                const [key, weight] = keyWeight.split(':');
                const value1 = getResult(mainObj, key);
                const value2 = getResult(obj, key);

                if (isObject(value1) && isObject(value2)) {
                    const nestedRank = rankObjects(value1, [value2], [`${key}:${weight}`]);
                    return acc + nestedRank[0].rank * weight;
                } else {
                    return acc + (value1 === value2 ? 1 : 0) * weight;
                }
            }, 0);

            return {obj, rank};
        })
        .filter(item => item.rank !== 0)
        .sort((a, b) => b.rank - a.rank);
}

export const findDifferences = (obj1, obj2, omitKeys = []) => {
    const filteredObj1 = omit(obj1, omitKeys);
    const filteredObj2 = omit(obj2, omitKeys);

    const sameKeys = union(keys(filteredObj1), keys(filteredObj2));

    return transform(sameKeys, (result, key) => {
        if (isEqual(filteredObj1[key] || 0, filteredObj2[key] || 0)) return;

        if (isObject(filteredObj1[key]) && isObject(filteredObj2[key])) {
            if (filteredObj1[key].differences) {
                const nestedDiff = filteredObj1[key].differences(filteredObj2[key], omitKeys);
                if (!isEmpty(nestedDiff)) result[key] = nestedDiff;
            } else {
                const nestedDiff = findDifferences(filteredObj1[key], filteredObj2[key], omitKeys);
                if (!isEmpty(nestedDiff)) result[key] = nestedDiff;
            }
        } else {
            result[key] = [filteredObj1[key], filteredObj2[key]];
        }
    }, {});
}

export const findSchoolStop = (schoolStops, schoolName, schoolSuburb) => schoolStops.find(stop => {
    return Stop.simplifyStopName(stop.stopName) === Stop.simplifyStopName(schoolName)
})

// function findDifferences(obj1, obj2, omitKeys = []) {
//     const diff = {};
//
//     function recursiveDiff(obj1, obj2, path = '') {
//         forEach(obj1, (value, key) => {
//             const currentPath = path ? `${path}.${key}` : key;
//
//             if (isObject(value) && !isArray(value)) {
//                 recursiveDiff(obj1[key], obj2[key], currentPath);
//             } else if (!isEqual(value, obj2[key])) {
//                 const omitPath = path.split('.').slice(1).join('.');
//                 if (!omitKeys.includes(omitPath)) {
//                     set(diff, currentPath, obj2[key]);
//                 }
//             }
//         });
//     }
//
//     recursiveDiff(obj1, obj2);
//
//     return diff;
// }

export class Address {
    constructor(props) {
        this.line1 = null;
        this.line2 = null;
        this.suburb = null;
        this.state = null;
        this.postcode = null;
        Object.assign(this, props)
    }

    isValid() {
        return this.line1?.length && this.suburb?.length
    }

    toString(short) {
        if (!this.isValid()) {
            return '';
        }
        if (short) {
            return `${this.line1}, ${this.suburb}`;
        }
        return `${this.line1}${this.line2 ? ', ' + this.line2 : ''}, ${this.suburb} ${this.state} ${this.postcode}`;
    }

    isEqual(address) {
        return this.line1 === address.line1 && this.line2 === address.line2 && this.suburb === address.suburb &&
            this.state === address.state && this.postcode === address.postcode
    }

    /**
     * Check if two addresses are the same ignoring case and trimming whitespace
     * @param address
     * @returns {boolean}
     */
    isSame(address) {
        return this.line1?.toLowerCase().trim() === address?.line1?.toLowerCase().trim() &&
            this.line2?.toLowerCase().trim() === address?.line2?.toLowerCase().trim() &&
            this.suburb.toLowerCase().trim() === address?.suburb.toLowerCase().trim() &&
            this.state.toLowerCase().trim() === address?.state.toLowerCase().trim() &&
            this.postcode.toLowerCase().trim() === address?.postcode.toLowerCase().trim()
    }

    toCsvRow() {
        return {
            "Address Line 1": this.line1,
            "Address Line 2": this.line2,
            "Suburb": this.suburb,
            "State": this.state,
            "Postcode": this.postcode,
        }
    }

    static fromFeature(address, separator = '|') {
        if (!address.includes(separator)) {
            separator = ','
        }
        const [line1, suburb, state, postcode] = address.split(separator).map(part => part.trim());
        return new Address({line1, suburb, state, postcode})
    }
}

export class NamedPerson {
    constructor(props) {
        this.firstName = null;
        this.lastName = null;
        delete props?.name;
        Object.assign(this, props)
    }

    name() {
        return this.firstName?.length || this.lastName?.length ? startCase(`${this.firstName || ''} ${this.lastName || ''}`) : null
    }

}

export class Guardian extends NamedPerson {
    constructor(props) {
        super(props);
        this.student = null;
        this.guardianId = ulid();
        this.address = null;
        this.postalAddress = null;
        this.phone = null;
        this.email = null;
        this.relationship = "Parent";
        this.amStop = null;
        this.pmStop = null;
        this.amStopId = null;
        this.pmStopId = null;
        this.distanceToAmStop = -1;
        this.distanceToPmStop = -1;
        this.nearestStopChecked = false;
        Object.assign(this, props);

        // this.address = isWhiteSpaceOnly(this.address) ? null : this.address;
        // this.postalAddress = isWhiteSpaceOnly(this.postalAddress) ? null : this.postalAddress;

        this.address = new Address(this.address);
        this.postalAddress = new Address(this.postalAddress);

        // Make address and postalAddress the same if one is missing
        this.address = this.address.isValid() ? this.address : this.postalAddress;
        this.postalAddress = this.postalAddress.isValid() ? this.postalAddress : this.address;

        // if (this.address) {
        //     this.address = new Address(this.address);
        // }
        //
        // if (this.postalAddress) {
        //     this.postalAddress = new Address(this.postalAddress);
        // }
    }

    isValid() {
        return this.name()?.length && (this.email?.length || this.phone?.length) && this.address.isValid()
    }


    isEqual(guardian) {
        return this.guardianId === guardian.guardianId && this.name() === guardian.name() && this.email === guardian.email && this.phone === guardian.phone &&
            this.address.isEqual(guardian.address) && this.postalAddress.isEqual(guardian.postalAddress)
    }

    isSame(guardian) {
        return guardian && this.name?.()?.toLowerCase() === guardian.name?.()?.toLowerCase() &&
            this.email?.toLowerCase() === guardian.email?.toLowerCase() &&
            this.phone?.toLowerCase() === guardian.phone?.toLowerCase() &&
            this.address?.isSame(guardian.address)
    }

    addressToString(short) {
        return this.address?.isValid() ? this.address.toString(short) : this.postalAddress?.isValid() ? this.postalAddress.toString(short) : "No address provided"
    }

    static fromCsvRow(row) {
        return new Guardian({
            guardianId: row['Entitlement ID'].trim(),
            firstName: row["Applicant First Name"].trim(),
            lastName: row["Applicant Surname"].trim(),
            jointCustody: row["Joint Custody"].toLowerCase() === "true",
            // address: new Address({
            //     line1: row["Address Line 1"].trim(),
            //     line2: row["Address Line 2"].trim(),
            //     suburb: row["Suburb"].trim(),
            //     state: row["State"].trim(),
            //     postcode: row["Postcode"].trim()
            // }),
            // postalAddress: new Address({
            //     line1: row["Postal Address Line 1"].trim(),
            //     line2: row["Postal Address Line 2"].trim(),
            //     suburb: row["Postal Suburb"].trim(),
            //     state: row["Postal State"].trim(),
            //     postcode: row["Postal Postcode"].trim()
            // }),
            address: new Address({
                line1: row["Address Line 1"].trim(),
                line2: row["Address Line 2"].trim(),
                suburb: row["Suburb"].trim(),
                state: row["State"].trim(),
                postcode: row["Postcode"].trim()
            }),
            //`${row["Address Line 1"].trim()}, ${row["Address Line 2"].trim()}, ${row["Suburb"].trim()}, ${row["State"].trim()}, ${row["Postcode"].trim()}`,
            postalAddress: new Address({
                line1: row["Postal Address Line 1"].trim(),
                line2: row["Postal Address Line 2"].trim(),
                suburb: row["Postal Suburb"].trim(),
                state: row["Postal State"].trim(),
                postcode: row["Postal Postcode"].trim()
            }),
            //`${row["Postal Address Line 1"].trim()}, ${row["Postal Address Line 2"].trim()}, ${row["Postal Suburb"].trim()}, ${row["Postal State"].trim()}, ${row["Postal Postcode"].trim()}`,
            phone: row["Applicant Phone"].trim(),
            email: row["Applicant Email"].trim(),
        })
    }

    toCsvRow() {
        return {
            "Applicant First Name": this.firstName,
            "Applicant Surname": this.lastName,
            "Address Line 1": this.address?.line1,
            "Address Line 2": this.address?.line2,
            "Suburb": this.address?.suburb,
            "State": this.address?.state,
            "Postcode": this.address?.postcode,
            "Postal Address Line 1": this.postalAddress?.line1,
            "Postal Address Line 2": this.postalAddress?.line2,
            "Postal Suburb": this.postalAddress?.suburb,
            "Postal State": this.postalAddress?.state,
            "Postal Postcode": this.postalAddress?.postcode,
            "Applicant Phone": this.phone,
            "Applicant Email": this.email,
        }
    }

    toString() {
        return `${this.name()} ${this.email ? this.email : this.phone ? this.phone : this.address}`
    }

    flatten() {
        return {
            ...this,
            guardianFirstName: this.firstName,
            guardianLastName: this.lastName,
            firstName: undefined,
            lastName: undefined
        }
    }

    differences(guardian) {
        return findDifferences(this, guardian, ['student', 'guardianId']);
    }

    toJson() {
        let g = cloneDeep(this);
        delete g.student;
        delete g.amStop;
        delete g.pmStop;
        delete g.amRoutes;
        delete g.pmRoutes;
        delete g.nearestStopChecked;
        return g
    }

    // /**
    //  * Assumes allRoutes has been loaded by setBaseStops
    //  * @param allRoutes
    //  * @param allStops
    //  * @param transfers
    //  * @returns {Promise<void>}
    //  */
    // async setNearestStop(allRoutes, allStops, transfers, schedules, features) {
    //
    //     if (this.amRoutes && this.pmRoutes) {
    //         this.nearestStopChecked = true
    //         return
    //     }
    //     if (!allRoutes) return
    //     if (!this.student.schoolStop) return
    //     if (Array.isArray(allRoutes)) {
    //         allRoutes = keyBy(allRoutes, 'routeId');
    //     }
    //     // const allRoutesServicingSchool = flatten(this.student.schoolStop.linkedStops?.map(linkedStop => linkedStop.stop?.routes?.map(r => allRoutes[r.routeId]))).filter(r => !!r);
    //     // const amRoutesServicingSchool = allRoutesServicingSchool.filter(r => ['AM', 'Inbound', 'Loop'].includes(r.direction));
    //     // const pmRoutesServicingSchool = allRoutesServicingSchool.filter(r => ['PM', 'Outbound', 'Loop'].includes(r.direction));
    //     // const amStopsServicingSchool = uniqBy(flatten(amRoutesServicingSchool.map(route => route.stops)), 'stopId');
    //     // const pmStopsServicingSchool = uniqBy(flatten(pmRoutesServicingSchool.map(route => route.stops)), 'stopId');
    //
    //     let amJps, pmJps;
    //
    //     this.nearestStopChecked = true
    //
    //     // if (!amStopsServicingSchool?.length && !pmStopsServicingSchool?.length) return;
    //
    //     const result = await getCachedGeocode(this.addressToString());
    //     if (result?.[0]?.rank?.confidence > 0.8) {
    //         // const closestAmStop = findClosestStop(amStopsServicingSchool, result[0])
    //         // this.amStopId = closestAmStop?.stop?.stopId;
    //         // this.amStop = closestAmStop?.stop;
    //         // this.distanceToAmStop = closestAmStop?.distance > 0 ? Math.round(closestAmStop?.distance) : -1;
    //         // const closestPmStop = findClosestStop(pmStopsServicingSchool, result[0])
    //         // this.pmStopId = closestPmStop?.stop?.stopId;
    //         // this.pmStop = closestPmStop?.stop;
    //         // this.distanceToPmStop = closestPmStop?.distance > 0 ? Math.round(closestPmStop?.distance) : -1;
    //
    //
    //         const allRoutesArray = values(allRoutes)
    //         let filter = {
    //             from: {center: [result[0].lon, result[0].lat]},
    //             to: {center: [this.student.schoolStop.lon, this.student.schoolStop.lat]}
    //         }
    //         const timeFilter = new TimeFilter()
    //         timeFilter.setSchoolAM();
    //         const prefs = new Prefs({
    //             maxConnectStartTime: 20,
    //             maxConnectEndTime: 20,
    //         })
    //         const amJpFilter = new JourneyPlanFilter({
    //             filter,
    //             timeFilter,
    //             prefs,
    //             reverse: false,
    //             features,
    //             transfers,
    //             allStops,
    //             schedules,
    //             allRoutes: allRoutesArray
    //         });
    //         amJps = await amJpFilter.lookup();
    //         amJps = amJps.sort(JourneyPlan.sortByLeastWalking);
    //
    //         timeFilter.setSchoolPM();
    //         filter = {
    //             to: {center: [result[0].lon, result[0].lat]},
    //             from: {center: [this.student.schoolStop.lon, this.student.schoolStop.lat]}
    //         }
    //         const pmJpFilter = new JourneyPlanFilter({
    //             filter,
    //             timeFilter,
    //             prefs,
    //             reverse: false,
    //             features,
    //             transfers,
    //             allStops,
    //             schedules,
    //             allRoutes: allRoutesArray
    //         });
    //         pmJps = await pmJpFilter.lookup();
    //         pmJps = pmJps.sort(JourneyPlan.sortByLeastWalking);
    //
    //         this.amRoutes = uniqBy(amJps.map(jp => jp.getFirstRoute()), 'routeNumber');
    //         this.pmRoutes = uniqBy(pmJps.map(jp => jp.getFirstRoute()), 'routeNumber');
    //     }
    // }
    //
    // async getServiceRoutes(allRoutes, allStops, transfers, schedules, features) {
    //
    //     if (!allRoutes || !allStops || !transfers || !schedules || !features) throw new Error('Please provide all data for query.')
    //     if (!this.student.schoolStop) throw new Error("No school stop for student")
    //     if (Array.isArray(allRoutes)) {
    //         allRoutes = keyBy(allRoutes, 'routeId');
    //     }
    //     const result = await getCachedGeocode(this.addressToString());
    //     if (result?.[0]?.rank?.confidence > 0.8) {
    //
    //         const allRoutesArray = values(allRoutes)
    //         let filter = {
    //             from: {center: [result[0].lon, result[0].lat]},
    //             to: {center: [this.student.schoolStop.lon, this.student.schoolStop.lat]}
    //         }
    //         const timeFilter = new TimeFilter()
    //         timeFilter.setSchoolAM();
    //         const prefs = new Prefs({
    //             maxConnectStartTime: 20,
    //             maxConnectEndTime: 20,
    //         })
    //         const amJpFilter = new JourneyPlanFilter({
    //             filter,
    //             timeFilter,
    //             prefs,
    //             reverse: false,
    //             features,
    //             transfers,
    //             allStops,
    //             schedules,
    //             allRoutes: allRoutesArray
    //         });
    //         let amJps = await amJpFilter.lookup();
    //         amJps = amJps.sort(JourneyPlan.sortByLeastWalking);
    //
    //         timeFilter.setSchoolPM();
    //         filter = {
    //             to: {center: [result[0].lon, result[0].lat]},
    //             from: {center: [this.student.schoolStop.lon, this.student.schoolStop.lat]}
    //         }
    //         const pmJpFilter = new JourneyPlanFilter({
    //             filter,
    //             timeFilter,
    //             prefs,
    //             reverse: false,
    //             features,
    //             transfers,
    //             allStops,
    //             schedules,
    //             allRoutes: allRoutesArray
    //         });
    //         let pmJps = await pmJpFilter.lookup();
    //         pmJps = pmJps.sort(JourneyPlan.sortByLeastWalking);
    //
    //         const amRoutes = uniqBy(amJps.map(jp => jp.getFirstRoute()), 'routeNumber');
    //         const pmRoutes = uniqBy(pmJps.map(jp => jp.getFirstRoute()), 'routeNumber');
    //         // return
    //         return {amRoutes, pmRoutes}
    //     }
    //     throw new Error("Could not geocode: " + JSON.stringify(result, null, 2))
    // }
}

export class TravelPassScheme {
    constructor(props) {
        this.schemeId = null;
        this.type = "scheme"
        this.source = "scheme"
        this.guardianId = null;
        this.startDate = null;
        this.expireDate = null;
        this.travelPass = null
        Object.assign(this, props)
        this.schemeId = this.schemeId || this.type;
        //
        // if (this.travelPass) {
        //     this.travelPass = new TravelPass(this.travelPass);
        // }
    }
}

export const StudentStatus = {
    active: 'Active',
    rejected: 'Rejected',
    suspended: 'Suspended',
    revoked: 'Revoked'
};

export class Student extends NamedPerson {
    constructor(props) {
        super(props);
        this.studentId = "_";
        this.status = "active";
        this.authorityId = null;
        this.code = null;
        this.age = null;
        this.grade = null;
        this.guardians = []; // [Guardian]
        this.disability = false;
        this.scheme = null; // TravelPassScheme
        this.disabilityText = null;
        this.jointCustody = false;
        this.schoolStopId = null;
        this.schoolId = null;
        this.schoolName = null;
        this.travelPasses = [];// [TravelPass]
        this.travelPassHistory = []; // [TravelPass]
        this.misbehaviours = []; // Misbehaviours attached to this student
        Object.assign(this, props)

        if (this.guardians?.length) {
            this.guardians = this.guardians.map(g => new Guardian({...g, student: this}))
        }
        if (this.travelPasses?.length) {
            this.travelPasses = this.travelPasses.map(tp => new TravelPass({...tp, student: this}))
        }

        if (this.scheme) {
            this.scheme = new TravelPassScheme(this.scheme);
        }
        if (this.misbehaviours?.length) {
            this.misbehaviours = this.misbehaviours.map(m => new Misbehaviour({...m, student: this}))
        }
    }

    clone() {
        return new Student(cloneDeep(this));
    }

    getActiveTravelPassses() {
        return this.travelPasses.filter(tp => tp.isActive());
    }

    isSuspended() {
        return ['suspended'].includes(this.status) ||
            this.travelPasses.some(p => ['suspended'].includes(p.status)) ||
            this.misbehaviours.some(m => ['suspended'].includes(m.outcome) && m.outcomeExpiry?.isAfter(dayjs()))
    }

    isSuspendedOrBanned() {
        return ['suspended', 'banned'].includes(this.status) ||
            this.travelPasses.some(p => ['suspended', 'banned'].includes(p.status)) ||
            this.misbehaviours.some(m => m.outcome === 'banned' ||
                (['suspended'].includes(m.outcome) && m.outcomeExpiry?.isAfter(dayjs())))
    }

    isBanned() {
        return ['banned'].includes(this.status) ||
            this.travelPasses.some(p => ['banned'].includes(p.status)) ||
            this.misbehaviours.some(m => m.outcome === 'banned')
    }

    isActive(date = dayjs()) {
        return this.travelPasses?.some(travelPass => travelPass?.isActive(date));
    }

    isActiveStatus() {
        return !this.status || this.status === 'active'
    }

    isExpired(date = dayjs()) {
        return !this.isActive(date) && this.travelPasses?.every(travelPass => travelPass.isExpired(date));
    }

    isPaid(date = dayjs()) {
        return this.travelPasses?.every(travelPass => travelPass.isPaid(date));
    }

    isValid({allStudents} = {}) {
        return (!allStudents || allStudents.every(s => !this.authorityId?.length || s.authorityId !== this.authorityId)) &&
            this.guardians?.every(g => g.isValid()) && (!this.travelPasses || this.travelPasses?.every(tp => tp.isValid())) &&
            this.firstName?.length && this.lastName?.length && this.age && this.grade && this.schoolStopId
    }

    isSameScheme(importedStudent) {
        const schemeId = importedStudent.travelPasses?.[0]?.schemeId
        const schemeExpired = this.isSchemeExpired(schemeId)
        const schemeIneligible = this.isSchemeIneligible(importedStudent)
        const containsStudent = this.contains(importedStudent)
        console.log(`isSameScheme: schemeId ${schemeId} status: ${this.status}, schemeExpired: ${schemeExpired} schemeIneligible: ${schemeIneligible} containsStudent: ${containsStudent}`)
        return !['archived', 'rejected'].includes(this.status) && !schemeExpired && !schemeIneligible && !containsStudent
    }

    isEqual(student) {
        return this.authorityId === student.authorityId && this.schoolId === student.schoolId
            && this.firstName === student.firstName && this.lastName === student.lastName
            && this.grade === student.grade && this.age === student.age
            && this.jointCustody === student.jointCustody
            && this.guardians?.length === student.guardians?.length
            && this.guardians.every(g => {
                const otherGuardian = student.guardians.find(sG => sG.guardianId === g.guardianId)
                return g.isEqual(otherGuardian)
            })
            && this.travelPasses?.length === student.travelPasses?.length
            && this.travelPasses.every(tp => {
                const otherTravelPass = student.travelPasses.find(sG => sG.schemeId === tp.schemeId)
                return tp.isEqual(otherTravelPass)
            })
    }

    checkEligibility() {
        const eligibilityWarnings = [];
        if (!this.schoolName?.length) eligibilityWarnings.push('Missing school')
        if (!this.schoolStop) eligibilityWarnings.push('You do not serve ' + this.schoolName);
        if (this.schoolStop.stopType !== 'school') eligibilityWarnings.push('Expected school stop, found bus stop')

        return eligibilityWarnings;
    }

    validateStudent(students) {
        const warnings = [];
        students.forEach(otherStudent => {
            if (this.studentId === otherStudent.studentId || !this.isActiveStatus() || !otherStudent.isActiveStatus()) {
                return
            }
            if (this.authorityId?.length && otherStudent.authorityId === this.authorityId) {
                warnings.push(`Same student as ${otherStudent.name()}`)
            } else if (otherStudent.isSimilar(this)) {
                warnings.push(`Similar to ${otherStudent.name()}`)
            } else if (otherStudent.contains(this)) {
                warnings.push(`Contained by ${otherStudent.name()}`)
            }
        });
        return warnings
    }

    isSchemeIneligible(importedStudent) {
        const existingTp = find(this.travelPasses, {schemeId: importedStudent.travelPasses?.[0]?.schemeId})
        if (!existingTp) {
            return false
        }
        const addressTheSame = importedStudent.guardians?.[0]?.address.isSame(existingTp.guardian?.address);
        const schoolTheSame = importedStudent.schoolStopId === this.schoolStopId
        const ineligible = existingTp.eligible === false
        // console.log(`Checking if scheme is ineligible: , schoolTheSame: ${schoolTheSame}, addressTheSame: ${addressTheSame}, ineligible: ${ineligible}\n\n${importedStudent}\n\n${existingTp}`);
        return schoolTheSame && addressTheSame && ineligible;
    }

    isSchemeExpired(schemeId) {
        const existingTp = this.findMostRecentSchemeTp();
        const expired = existingTp.isExpired()
        // console.log(`isSchemeExpired: existingTP: ${existingTp?.travelSchedule} Expired: ${expired}`)
        return existingTp && expired;
    }

    findMostRecentSchemeTp() {
        return this.travelPasses?.filter(tp => tp.type === 'scheme').sort((tp1, tp2) => tp1.travelSchedule.endDate.unix() - tp2.travelSchedule.endDate.unix())?.[0]
    }

    findMostRecentTp() {
        return this.travelPasses?.sort((tp1, tp2) => tp1.travelSchedule.endDate.unix() - tp2.travelSchedule.endDate.unix())?.[0]
    }

    isEqual(student) {
        return this.authorityId === student.authorityId && this.schoolId === student.schoolId
            && this.firstName === student.firstName && this.lastName === student.lastName
            && this.grade === student.grade && this.age === student.age
            && this.jointCustody === student.jointCustody
            && this.guardians?.length === student.guardians?.length
            && this.guardians.every(g => {
                const otherGuardian = student.guardians.find(sG => sG.guardianId === g.guardianId)
                return g.isEqual(otherGuardian)
            })
            && this.travelPasses?.length === student.travelPasses?.length
            && this.travelPasses.every(tp => {
                const otherTravelPass = student.travelPasses.find(sG => sG.schemeId === tp.schemeId)
                return tp.isEqual(otherTravelPass)
            })
    }

    isSimilar(student) {
        return this.schoolStopId === student?.schoolStopId &&
            this.firstName?.toLowerCase().trim() === student?.firstName?.toLowerCase().trim() &&
            this.lastName?.toLowerCase().trim() === student?.lastName?.toLowerCase().trim() &&
            this.grade?.toLowerCase().trim() === student?.grade?.toLowerCase().trim() &&
            this.age?.toLowerCase().trim() === student?.age?.toLowerCase().trim()
    }

    contains(student) {
        const equalTps = student.travelPasses.every(newTp => {
            return !!this.travelPasses.find(thisTp => thisTp.isEqual(newTp))
        })
        const equalGuardians = student.guardians.every(newG => {
            return !!this.guardians.find(thisG => thisG.isSame(newG))
        })
        const result = this.authorityId === student.authorityId && this.schoolStopId === student.schoolStopId
            && this.firstName === student.firstName && this.lastName === student.lastName
            // && this.grade === student.grade && this.age === student.age
            && this.guardians?.length >= student.guardians?.length
            && equalGuardians
            && this.travelPasses?.length >= student.travelPasses?.length
            && equalTps

        // console.log(`Student contains: ${result} equalGuardians: ${equalGuardians} equalTps: ${equalTps}`)
        return result
    }

    static fromCsvRow(row, schoolStops) {
        //Entitlement ID,Accreditation ID,Operator,Student ID,First Name,Surname,Address Line 1,Address Line 2,Suburb,State,Postcode,School A Code,School,Grade,Age Range,Joint Custody,Postal Address Line 1,Postal Address Line 2,Postal Suburb,Postal State,Postal Postcode,Applicant First Name,Applicant Surname,Applicant Phone,Applicant Email,Date Joined,Expiry Date
        const guardian = Guardian.fromCsvRow(row);
        // const scheme = new TravelPassScheme({
        //     type: TravelPassSchemeType.scheme,
        //     source: TravelPassSchemeSource.scheme,
        //     expireDate: dayjs(row["Expiry Date"], "DD/MM/YYYY"),
        //     startDate: dayjs(row["Date Joined"], "DD/MM/YYYY"),
        //     guardianId: guardian.guardianId
        // })
        const schoolStop = findSchoolStop(schoolStops, row["School"], row["Suburb"])
        const eligible = (row["Eligible"] !== undefined || row["Override Eligible"] !== undefined) ? (row["Eligible"] === "Yes" || row["Override Eligible"] === "No") : undefined;
        const studentObj = {
            studentId: ulid(),
            authorityId: row["Student ID"].trim(),
            firstName: row["First Name"].trim(),
            lastName: row["Surname"].trim(),
            grade: row["Grade"].trim(),
            age: row["Age Range"].trim(),
            schoolStopId: schoolStop?.stopId,
            schoolName: schoolStop?.stopName || row["School"],
            schoolSuburb: schoolStop?.suburb || row["Suburb"],
            schoolStop,
            disability: row["Disability"] === "TRUE",
            guardians: [guardian]
        };
        const travelSchedule = new TravelSchedule({
            startDate: convertDate(row["Date Joined"]),
            endDate: convertDate(row["Expiry Date"]),
            travelDays: OPERATING_DAY_DEFAULTS.Weekdays.map(day => day ? 2 : 0)
        })
        const student = new Student({
            ...studentObj,
            travelPasses: [new TravelPass({
                schemeId: `${row["Entitlement ID"].trim()}_${travelSchedule.endDate.year()}`,
                type: "scheme",
                status: eligible === false ? "ineligible" : eligible ? "active" : "review",
                eligible: eligible !== false,
                ineligibleNotes: eligible === false ? (row["Reason"] || 'Unknown') : undefined,
                ineligibleReason: eligible === false ? 'Other' : undefined,
                grade: row["Grade"].trim(), travelSchedule, guardianId: guardian.guardianId
            })]
        })
        return student
    }

    toCsvRow() {

        return this.travelPasses.filter(tp => tp.type === 'scheme').map((tp, idx) => {
            // find guardian for this travel pass
            const guardian = this.guardians.find(g => g.guardianId === tp.guardianId)
            return {
                "Entitlement ID": tp.schemeId,
                "Student ID": this.authorityId,
                "First Name": this.firstName,
                "Surname": this.lastName,
                "Address Line 1": guardian?.address?.line1,
                "Address Line 2": guardian?.address?.line2,
                "Suburb": guardian?.address?.suburb,
                "State": guardian?.address?.state,
                "Postcode": guardian?.address?.postcode,
                "School A Code": this.schoolId,
                "School": this.schoolName,
                "Grade": this.grade,
                "Age Range": this.age,
                "Joint Custody": this.jointCustody ? "TRUE" : "FALSE",
                "Postal Address Line 1": guardian?.postalAddress?.line1,
                "Postal Address Line 2": guardian?.postalAddress?.line2,
                "Postal Suburb": guardian?.postalAddress?.suburb,
                "Postal State": guardian?.postalAddress?.state,
                "Postal Postcode": guardian?.postalAddress?.postcode,
                "Applicant First Name": guardian?.firstName,
                "Applicant Surname": guardian.lastName,
                "Applicant Phone": guardian?.phone,
                "Applicant Email": guardian?.email,
                "Date Joined": tp.schedulePeriods?.[0].period?.start?.format("DD/MM/YYYY"),
                "Expiry Date": tp.schedulePeriods?.[0].period?.end?.format("DD/MM/YYYY"),
                status: tp.status
            }
        })
    }

    flatten() {
        return flatten(this.travelPasses.map(tp => {
            const guardian = this.guardians.find(g => g.guardianId === tp.guardianId)
            return {...guardian.flatten(), ...tp, ...this}
        }))
    }

    merge(student) {

        if (!this.authorityId && student.authorityId) {
            // Update student details if the merged student has an authorityId (part of a scheme)
            this.authorityId = student.authorityId;
            this.firstName = student.firstName;
            this.lastName = student.lastName;
            this.grade = student.grade;
            this.age = student.age;
            this.jointCustody = student.jointCustody;
            this.schoolName = student.schoolName;
            this.schoolStopId = student.schoolStopId;
            this.disability = student.disability;
            this.disabilityText = student.disabilityText;
        }

        this.travelPasses = unionBy(this.travelPasses, [...student.travelPasses], 'schemeId');
        this.guardians = unionWith(this.guardians, [...student.guardians], (g1, g2) => g1.guardianId === g2.guardianId)
        this.misbehaviours = unionWith(this.misbehaviours, [...student.misbehaviours], (m1, m2) => m1.incidentId === m2.incidentId && m1.category === m2.category && m1.date.isSame(m2.date, 'day') && m1.label === m2.label)
        // update joint custody
        // if (this.guardians?.length > 1 && differenceWith(this.guardians, this.guardians, (g1, g2) => g1.guardianId !== g2.guardianId && g1.address.isEqual(g2.address)).length > 0) {
        //     this.jointCustody = true
        // } else {
        //     this.jointCustody = false
        // }

        // this.travelPasses = this.travelPasses.map(tp => {
        //     const newTp = student.travelPasses.find(sTp => sTp.schemeId === tp.schemeId)
        //     if (newTp) {
        //         return newTp
        //     }
        //     return tp
        // })
        // this.guardians = this.guardians.map(g => {
        //     const newG = student.guardians.find(sG => sG.guardianId === g.guardianId)
        //     if (newG) {
        //         return newG
        //     }
        //     return g
        // })
        //
        // this.misbehaviours = this.misbehaviours.map(m => {
        //     const newM = student.misbehaviours.find(sM => sM.incidentId === m.incidentId)
        //     if (newM) {
        //         return newM
        //     }
        //     return m
        // })
        return this;
    }

    differences(student, omitKeys = []) {
        return findDifferences(this, student, omitKeys);
    }

    toJson() {
        const student = this.clone();
        delete student.schoolStop
        delete student.homeStop
        delete student.expired
        delete student.modified
        delete student.key
        student.guardians = student.guardians.map(s => s.toJson ? s.toJson() : s)
        student.travelPasses = student.travelPasses.map(s => s.toJson ? s.toJson() : s)
        student.misbehaviours = student.misbehaviours.map(s => s.toJson ? s.toJson() : s)
        return student;
    }

    fromJson(stopsById, schoolStops) {
        if (this.schoolStopId) {
            this.schoolStop = stopsById[this.schoolStopId]
            this.schoolStop?.setLinkedStops(stopsById)
        } else if (this.schoolName?.length) {
            const schoolStop = findSchoolStop(schoolStops, this.schoolName, this.schoolSuburb);
            this.schoolStop = schoolStop
            this.schoolStopId = schoolStop?.stopId
            this.schoolSuburb = this.schoolSuburb || schoolStop?.suburb;
            this.schoolStop?.setLinkedStops(stopsById)
        }
        this.travelPasses.forEach(tp => {
            tp.stop = stopsById[tp.stopId]
            tp.schoolStop = stopsById[tp.schoolStopId]
            tp.schoolStop?.setLinkedStops(stopsById)
            tp.guardian = this.guardians.find(g => g.guardianId === tp.guardianId)
            tp.student = this;
        })
        this.guardians.forEach(g => {
            if (g.stopId) {
                g.amStop = stopsById[g.amStopId];
                g.amStop?.setLinkedStops(stopsById)
                g.pmStop = stopsById[g.pmStopId];
                g.pmStop?.setLinkedStops(stopsById)
            }
            g.student = this;
        });
        return this;
    }

}

export const TravelPassSchemeType = {
    // free: {key: 'free', label: "Free", expires: true, validate: tp => tp.expireDate},
    scheme: {
        key: 'scheme',
        label: "SSTS",
        name: 'School Student Travel Scheme (SSTS)',
        expires: true,
        validate: tp => tp.expireDate
    },
    // yearly: {
    //     key: 'yearly',
    //     label: "Yearly",
    //     header: 'Yearly Travel Pass',
    //     periods: SCHOOL_YEARS,
    //     payment: 200,
    //     validate: tp => tp.schedule?.schedulePeriods.every(p => p.isValid())
    // },
    singleTerm: {
        key: 'singleTerm',
        label: "Single Term",
        header: 'Single Term Travel Pass',
        periods: ALL_FUTURE_TERMS.filter(t => t.year === dayjs().year()),
        payment: {weekly: 4, discounts: {weekly: 12, discount: 10}},
        validate: tp => tp.schedule?.schedulePeriods.every(p => p.isValid())
    },
    terms: {
        key: 'term',
        label: "Terms",
        header: 'Consecutive Terms',
        periods: ALL_FUTURE_TERMS.filter(t => t.year === dayjs().year()).map(period => new Period({
            ...period,
            start: dayjs()
        })),
        payment: {weekly: 4, discounts: {weekly: 12, discount: 10}},
        validate: tp => tp.schedule?.schedulePeriods.every(p => p.isValid())
    },
}

export const TravelPassSchemeSource = {
    manual: "Manual",
    scheme: "Scheme",
    override: "Override"
}


export const TravelPassStatus = {
    active: "Active",
    suspended: "Suspended",
    expired: "Expired",
    revoked: "Revoked",
    rejected: 'Rejected'
}

const DAY_RATE = 200;

export class TravelPass {

    constructor(props) {
        this.travelPassId = ulid();
        this.student = null;
        this.schemeId = null;
        this.type = "scheme";
        this.eligible = true;
        // this.source = "scheme";
        this.grade = null;
        this.travelSchedule = null;
        this.paymentDate = null;
        this.paymentAmount = null;
        this.gst = null;
        this.issueDate = null;
        this.printDate = null;
        // this.startDate = null;
        // this.expireDate = null;
        this.qrCode = null;
        this.status = "review"; // [TravelPassStatus]
        this.statusUpdateDate = dayjs();
        this.incidentIds = []; // Incident ids attached to this travel pass
        this.guardianId = null;
        this.guardian = null;
        this.period = null;

        Object.assign(this, props)

        // if (this.type) {
        //     this.type = new TravelPassScheme(this.scheme);
        // }

        if (this.paymentDate?.length) {
            this.paymentDate = dayjs(this.paymentDate, DATE_STRING);
        }
        if (this.issueDate?.length) {
            this.issueDate = dayjs(this.issueDate, DATE_STRING)
        }
        if (this.printDate?.length) {
            this.printDate = dayjs(this.issueDate, DATE_STRING)
        }
        // if (this.expireDate?.length) {
        //     this.expireDate = dayjs(this.expireDate, DATE_STRING)
        // }
        // if (this.startDate?.length) {
        //     this.startDate = dayjs(this.startDate, DATE_STRING)
        // }
        if (this.period?.length) {
            this.period = new Period(this.period);
        }
        if (this.statusUpdateDate?.length) {
            this.statusUpdateDate = dayjs(this.statusUpdateDate, DATE_STRING);
        }
        this.travelSchedule = new TravelSchedule(this.travelSchedule || {})
        if (!props.schemeId && !props.cost) {
            this.cost = this.travelSchedule.calculatePrice();
        }
        // if (this.guardianId) {
        //     this.guardian = student.guardians.find(g => g.guardianId === this.guardianId)
        // }
        // if ('string' === typeof this.type) {
        //     this.type = TravelPassSchemeType[this.type];
        // }
    }

    needsPayment() {
        return this.type !== 'scheme' && !this.paymentDate
    }

    isValid() {
        return this.status && this.grade && this.guardianId
    }

    isEqual(travelPass) {
        return this.schemeId === travelPass.schemeId && this.type === travelPass.type
            && this.grade === travelPass.grade && this.eligible === travelPass.eligible
            && this.paymentDate === travelPass.paymentDate
            && this.guardianId === travelPass.guardianId
    }

    isActive(date = dayjs()) {
        return !this.isFuture(date) && !this.isExpired(date) && this.status === 'active';
    }

    isFuture(date = dayjs()) {
        return this.travelSchedule?.startDate?.isAfter(date, 'day');
    }

    isExpired(date = dayjs()) {
        return this.travelSchedule?.endDate?.isBefore(date, 'day');
    }

    isPaid() {
        return TravelPassSchemeType[this.type]?.payment && this.paymentDate
    }

    toJson() {
        return {
            ...this,
            paymentDate: this.paymentDate?.format(DATE_STRING),
            issueDate: this.issueDate?.format(DATE_STRING),
            printDate: this.printDate?.format(DATE_STRING),
            period: this.period?.toJson(),
            statusUpdateDate: this.statusUpdateDate?.format(DATE_STRING),
            travelSchedule: this.travelSchedule?.toJson ? this.travelSchedule.toJson() : undefined,
            type: typeof this.type === 'string' ? this.type : this.type?.key,
            guardian: undefined,
            student: undefined
        }
    }

    toString() {
        if (TravelPassSchemeType[this.type]?.periods && this.travelSchedule) {
            return this.travelSchedule.name
        } else if (TravelPassSchemeType[this.type]?.expires && this.travelSchedule?.end) {
            return `${TravelPassSchemeType[this.type]?.label}. Expires: ${this.travelSchedule?.end.format("DD/MM/YYYY")}`
        }
        return TravelPassSchemeType[this.type]?.label
    }

    differences(travelPass, omitKeys = ['student', 'guardian']) {
        return findDifferences(this, travelPass, omitKeys);
    }

    rank(otherObjs, weightedKeys = ['guardian.address:7', 'guardian.email:6', 'guardian.phone:5', 'guardian.name:4', 'student.name:3']) {
        return rankObjects(this, otherObjs, weightedKeys);
    }

}

export class Misbehaviour extends Incident {
    constructor(props) {
        super(props);
        this.student = null;
        this.studentId = null;
        this.authorityOutcomeLink = null;
        Object.assign(this, props)

        super.init();
    }

    toJson() {
        let g = super.toJson();
        delete g.student;
        return g
    }


}


export const PREVIEW_STUDENT = new Student({
    firstName: 'Sam',
    lastName: 'Smith',
    grade: '5',
    age: 'Under 12',
    schoolName: 'Busable Public School',
    guardians: [new Guardian({
        firstName: 'Andrew',
        lastName: 'Smith',
        address: new Address({line1: '1 Smith St', suburb: 'Smithville', state: 'NSW', postcode: '2000'}),
        postalAddress: new Address({line1: '1 Smith St', suburb: 'Smithville', state: 'NSW', postcode: '2000'})
    })],
    travelPasses: [new TravelPass({type: 'scheme'}), new TravelPass({
        type: 'term',
        paymentAmount: 110,
        gst: 10,
        cost: 100,
        invoiceNumber: '0001',
        receiptNumber: '0001'
    })],
    misbehaviours: [new Misbehaviour({category: 'cat1', type: "Doing some badness", date: dayjs()}),
        new Misbehaviour({
            category: 'cat2',
            type: "Doing lots of badness",
            date: dayjs(),
            outcomeDate: dayjs(),
            outcomeExpiry: dayjs().add(7, 'd'),
            outcomePeriod: 1
        }),
        new Misbehaviour({
            category: 'cat3',
            type: "Doing really really badness",
            date: dayjs(),
            outcomeDate: dayjs(),
            outcomeExpiry: dayjs().add(14, 'd'),
            outcomePeriod: 2
        })]
})
PREVIEW_STUDENT.guardians[0].student = PREVIEW_STUDENT;
PREVIEW_STUDENT.travelPasses[0].student = PREVIEW_STUDENT;
PREVIEW_STUDENT.travelPasses[0].guardian = PREVIEW_STUDENT.guardians[0];
PREVIEW_STUDENT.travelPasses[1].student = PREVIEW_STUDENT;
PREVIEW_STUDENT.travelPasses[1].guardian = PREVIEW_STUDENT.guardians[0];
PREVIEW_STUDENT.misbehaviours[0].student = PREVIEW_STUDENT;
