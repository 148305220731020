import {Job} from './job';
import {cloneDeep, last} from 'lodash';
import {combineDeadruns} from './shiftBat';

export const VEHICLE_STATUS = {
    active: 'Active', service: 'Service', repair: 'Repair',
};

export class Vehicle {

    static from(vehicle) {
        return new Vehicle(cloneDeep(vehicle));
    }

    constructor(data) {
        this.vehicleId = '_';
        this.vehicleRego = null;
        this.vehicleName = null;
        this.vehicleStatus = VEHICLE_STATUS.active;
        this.rate = null;
        this.vehicleTypeId = null; // vehicleTypeId
        this.vehicleType = null; // VehicleType
        Object.assign(this, data);
        delete this.temporary;

        this.jobs = [];
        this.jobOption = null;
    }

    getRate() {
        // if this vehicle has a rate override, use that, otherwise use the vehicleType rate
        return Number.isFinite(this.rate) && this.rate >= 0 ? this.rate : this.vehicleType?.getRate();
    }

    clone() {
        return Vehicle.from(this);
    }


    toJson() {
        const json = {...this};
        delete json.jobs;
        delete json.jobOption;
        delete json.vehicleType;
        return json;
    }

    isJobPossible(duty, actual = false) {
        // Check each job to see if the startTime and endTime overlap with the duty's startTime and endTime
        const dutyStartTime = actual ? duty.getActualStartTime({asDayJs: true}) : duty.getStartTime({asDayJs: true});
        const dutyEndTime = actual ? duty.getActualEndTime({asDayJs: true}) : duty.getEndTime({asDayJs: true});
        return this.jobs.every(job => {
            const jobStartTime = actual ? job.getActualStartTime({asDayJs: true}) : job.getStartTime({asDayJs: true});
            const jobEndTime = actual ? job.getActualEndTime({asDayJs: true}) : job.getEndTime({asDayJs: true});
            return jobEndTime.isBefore(dutyStartTime) && dutyEndTime.isAfter(jobStartTime) || dutyEndTime.isBefore(jobStartTime) && jobEndTime.isAfter(dutyStartTime);
        });
    }

    async addJobOption(job, type, date, {deadrunSortFn = null} = {}) {
        const duty = job.getDuty?.();
        if (!this.isJobPossible(duty, true)) {
            return null;
        }

        let leadingJobs = this.jobs
            .filter(job => job.getActualEndTime({asDayJs: true}).isBefore(duty.getActualStartTime({asDayJs: true})));
        const leadingJob = last(leadingJobs.sort((j1, j2) => j1.getDuty().getActualEndTime({asDayJs: true}).isBefore(j2.getDuty().getActualStartTime({asDayJs: true})) ? -1 : 1));
        let startTime = duty.getStartTime({asDayJs: true}), endTime = duty.getEndTime({asDayJs: true}),
            leadingDeadrun = {distance: 0, duration: 0}, trailingDeadrun = {distance: 0, duration: 0},
            untouchedLeadingDeadrun = {distance: 0, duration: 0}, untouchedTrailingDeadrun = {distance: 0, duration: 0},
            stats = {
                leading: {distance: 0, duration: 0},
                trailing: {distance: 0, duration: 0},
                total: {distance: 0, duration: 0}
            };
        if (leadingJob) {
            untouchedLeadingDeadrun = combineDeadruns([leadingJob.getTrailingDeadrun(), duty.getLeadingDeadrun()]);
            leadingDeadrun = await leadingJob.getDuty().getBestDeadrun(duty, untouchedLeadingDeadrun, deadrunSortFn);
            startTime = duty.getActualStartTime({asDayJs: true}).subtract(leadingDeadrun.duration, 's');
            stats.leading = {
                distance: (leadingDeadrun?.distance || 0) - (untouchedLeadingDeadrun?.distance || 0),
                duration: (leadingDeadrun?.duration || 0) - (untouchedLeadingDeadrun?.duration || 0)
            };
        }

        let trailingJobs = this.jobs
            .filter(job => job.getActualStartTime({asDayJs: true}).isAfter(duty.getActualEndTime({asDayJs: true})));
        const trailingJob = trailingJobs?.sort((j1, j2) => j1.getDuty().getActualStartTime({asDayJs: true}).isAfter(j2.getDuty().getActualEndTime({asDayJs: true})) ? -1 : 1)?.[0];
        if (trailingJob) {
            untouchedTrailingDeadrun = combineDeadruns([duty.getTrailingDeadrun(), trailingJob.getLeadingDeadrun()]);
            trailingDeadrun = await duty.getBestDeadrun(trailingJob.getDuty(), untouchedTrailingDeadrun, deadrunSortFn);
            endTime = duty.getActualEndTime({asDayJs: true}).add(trailingDeadrun.duration, 's');
            stats.trailing = {
                distance: (trailingDeadrun?.distance || 0) - (untouchedTrailingDeadrun?.distance || 0),
                duration: (trailingDeadrun?.duration || 0) - (untouchedTrailingDeadrun?.duration || 0)
            };
        }

        stats.total.distance = stats.leading.distance + stats.trailing.distance;
        stats.total.duration = stats.leading.duration + stats.trailing.duration;

        this.jobOption = new Job({
            ...job,
            allocationType: 'vehicle',
            allocationId: this.vehicleId,
            date,
            startTime,
            endTime,
            stats,
            untouchedLeadingDeadrun,
            untouchedTrailingDeadrun,
            leadingDeadrun,
            trailingDeadrun
        });
    }

}
