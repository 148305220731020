import {useEffect, useState} from 'react';
import {jobModelData} from '../services/ModelService';

const useAllJobs = ({filter} = {}) => {
    const [allJobs, setAllJobs] = useState(null);
    const [allJobsAsArray, setAllJobsAsArray] = useState([]);
    const [filteredJobs, setFilteredJobs] = useState([]);

    useEffect(() => {
        const jobsListener = jobModelData.addListener({
            setterFn: setAllJobs, loaded: jobs => {
                setAllJobs(jobs);
            }
        });

        return () => {
            if (jobsListener)
                jobModelData.removeListener(jobsListener);
        };
    }, [setAllJobs]);

    useEffect(() => {
        if (!allJobs) {
            return;
        }
        setAllJobsAsArray(Object.values(allJobs));
    }, [allJobs, setAllJobsAsArray]);

    useEffect(() => {
        if (!allJobsAsArray || !filter) {
            return;
        }
        let filtered;
        if (filter.date) {
            filtered = allJobsAsArray.filter(job => job.date.isSame(filter.date, 'day'));
        } else if (filter.fromDate && filter.toDate) {
            filtered = allJobsAsArray.filter(job => job.date.isSameOrAfter(filter.fromDate, 'day') && job.date.isSameOrBefore(filter.toDate, 'day'));
        }
        // Filter by jobType [shiftbat, charter, adhoc]
        if (filtered && filter.jobType) {
            filtered = filtered.filter(job => job.type === filter.jobType);
        }

        // Filter by allocationType [vehicle, employee]
        if (filtered && filter.allocationType) {
            filtered = filtered.filter(job => job.allocationType === filter.allocationType);
        }

        setFilteredJobs(filtered);
    }, [allJobsAsArray, setFilteredJobs, filter]);

    return {
        allJobs,
        allJobsAsArray,
        filteredJobs
    };
};

export default useAllJobs;

