import React, {useEffect, useState} from "react";
import {routeModelData} from "../services/ModelService";

const useAllRoutes = () => {
    const [allRoutes, setAllRoutes] = useState(null);

    useEffect(() => {
        const routesListener = routeModelData.addListener({
            setterFn: setAllRoutes, loaded: routes => {
                setAllRoutes(routes);
            }
        });

        return () => {
            if (routesListener)
                routeModelData.removeListener(routesListener)
        }
    }, [])

    return {
        allRoutes
    };
}

export default useAllRoutes;