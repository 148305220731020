import store from 'store2';

export const CONNECTIONS = {walk: 'Walking', drive: 'Driving'};
export const RANK_BY = {next: 'Next Service', time: 'Shortest Time', walking: 'Least Walking'};
export const
    WALKING_SPEED = {'4': 'Slow', '4.5': 'Average', '5': 'Fast'};

class Prefs {
    constructor({
                    maxConnectStartTime = 10, // minutes
                    maxConnectEndTime = 10, // minutes
                    rankBy = RANK_BY.time,
                    walkingSpeed = 4.5, // km/h
                    drivingSpeed = 50, // km/h
                    connectStart = CONNECTIONS.walk,
                    connectEnd = CONNECTIONS.walk,
                    avoidTransfers = false,
                    avoidSchool = false,
                }, save) {
        Object.assign(this, {
            maxConnectStartTime,
            maxConnectEndTime,
            rankBy,
            walkingSpeed,
            drivingSpeed,
            connectStart,
            connectEnd,
            avoidTransfers,
            avoidSchool
        });
        if (save) {
            this.write();
        }
    }

    getDurationConnectStart(distance, reverse) {
        console.log('calculating duration with distance', distance, this.walkingSpeed)
        switch (!reverse ? this.connectEnd : this.connectStart) {
            case CONNECTIONS.drive:
                return distance / (this.drivingSpeed / 3.6)
            default:
                return distance / (this.walkingSpeed / 3.6)
        }
    }

    getDurationConnectEnd(distance, reverse) {
        switch (!reverse ? this.connectStart : this.connectEnd) {
            case CONNECTIONS.drive:
                return distance / (this.drivingSpeed / 3.6)
            default:
                return distance / (this.walkingSpeed / 3.6)
        }
    }

    /**
     *
     * @returns {number} distance in metres
     */
    getMaxDistanceConnectStart(reverse) {
        switch (!reverse ? this.connectStart : this.connectEnd) {
            case CONNECTIONS.drive:
                console.log('Drving...', this.connectStart)
                return ((!reverse ? this.maxConnectStartTime : this.maxConnectEndTime) / 60) * this.drivingSpeed * 1000
            default:
                console.log('Ealking...', this.connectStart)
                return ((!reverse ? this.maxConnectStartTime : this.maxConnectEndTime) / 60) * this.walkingSpeed * 1000
        }
    }

    getDuration(reverse, distance) {
        switch (!reverse ? this.connectStart : this.connectEnd) {
            case CONNECTIONS.drive:
                return distance / this.drivingSpeed * 1000
            default:
                return distance / this.walkingSpeed * 1000
        }
    }

    /**
     *
     * @returns {number} distance in metres
     */
    getMaxDistanceConnectEnd(reverse) {
        switch (!reverse ? this.connectStart : this.connectEnd) {
            case CONNECTIONS.drive:
                return ((!reverse ? this.maxConnectEndTime : this.maxConnectStartTime) / 60) * this.drivingSpeed * 1000
            default:
                return ((!reverse ? this.maxConnectEndTime : this.maxConnectStartTime) / 60) * this.walkingSpeed * 1000
        }
    }

    getMaxDurationConnectStart(reverse) {
        return ((!reverse ? this.maxConnectStartTime : this.maxConnectEndTime) + 1) * 60
    }

    getMaxDurationConnectEnd(reverse) {
        return ((!reverse ? this.maxConnectEndTime : this.maxConnectStartTime) + 1) * 60;
    }

    getConnectStartSpeed(reverse) {
        switch (!reverse ? this.connectStart : this.connectEnd) {
            case CONNECTIONS.drive:
                return this.drivingSpeed
            default:
                return this.walkingSpeed
        }
    }

    getConnectEndSpeed(reverse) {
        switch (!reverse ? this.connectEnd : this.connectStart) {
            case CONNECTIONS.drive:
                return this.drivingSpeed
            default:
                return this.walkingSpeed
        }
    }

    set(key, val) {
        this[key] = val;
        this.writeKey(key, val);
    }

    writeKey(key, val) {
        try {
            console.log('Storing', 'RMW.TripPlanner.Prefs.' + key, val)
            store.set('RMW.TripPlanner.Prefs.' + key, val)
        } catch (e) {
            console.log(`Couldn't store data. Error: ${e}`, e);
        }
    }

    write() {
        console.log(`Writing prefs to localStorage...`);
        let _this = this;
        Object.keys(this).forEach(key => {
            _this.writeKey(key, _this[key]);
        })
    }

    read() {
        console.log(`Reading prefs from localStorage...`);
        const keys = Object.keys(this);
        if (keys && keys.length) {
            keys.forEach(key => {
                const val = store.get('RMW.TripPlanner.Prefs.' + key)
                if (val?.length) {
                    console.log('RMW.TripPlanner.Prefs.' + key, val);
                    this[key] = val;
                }
            });
        }
    }

    toString(reverse) {
        let str = '';

        if (this.maxConnectStartTime === this.maxConnectEndTime && this.maxConnectStartTime === 0) {
            str += 'stop to stop'
        } else {
            if (this.connectStart === this.connectEnd) {
                if (this.maxConnectStartTime === this.maxConnectEndTime) {
                    str += this.connectStart.toLowerCase() + ' at most ' + this.maxConnectStartTime + ' mins to first and from last stop';
                } else if (this.maxConnectStartTime > 0 && this.maxConnectEndTime > 0) {
                    str += this.connectStart.toLowerCase() + ' at most ' + (!reverse ? this.maxConnectStartTime : this.maxConnectEndTime) + ' mins to first stop and ' + (!reverse ? this.maxConnectEndTime : this.maxConnectStartTime) + ' mins from last stop';
                } else {
                    str += (!reverse ? this.maxConnectStartTime : this.maxConnectEndTime) > 0 ? this.connectStart.toLowerCase() + ' at most ' + (!reverse ? this.maxConnectStartTime : this.maxConnectEndTime) + ' mins to first stop ' : ''
                    str += (!reverse ? this.maxConnectEndTime : this.maxConnectStartTime) > 0 ? this.connectStart.toLowerCase() + ' at most ' + (!reverse ? this.maxConnectEndTime : this.maxConnectStartTime) + ' mins from last stop' : '';
                }
            } else {
                str += (!reverse ? this.maxConnectStartTime : this.maxConnectEndTime) > 0 ? this.connectStart.toLowerCase() + ' at most ' + (!reverse ? this.maxConnectStartTime : this.maxConnectEndTime) + ' mins to first stop' : '';
                str += this.maxConnectStartTime > 0 && this.maxConnectEndTime > 0 ? ' and ' : ''
                str += (!reverse ? this.maxConnectEndTime : this.maxConnectStartTime) > 0 ? this.connectEnd.toLowerCase() + ' at most ' + (!reverse ? this.maxConnectEndTime : this.maxConnectStartTime) + ' mins from last stop' : '';
            }
        }
        return str;
    }
}

const prefs = new Prefs({});
prefs.read();
export {prefs, Prefs}
