import {useEffect, useState} from "react";
import useAllShiftBats from "./useAllShiftBats";
import {values} from "lodash";
import useAllSchedules from "./useAllSchedules";
import useAllStops from './useAllStops';

const useShiftBatsForDate = ({date, validOnly = false}) => {
    const {allShiftBatsArray} = useAllShiftBats();
    const {allSchedules} = useAllSchedules();
    const [shiftBatsForDate, setShiftBatsForDate] = useState([]);
    const {allStops} = useAllStops();

    useEffect(() => {
        console.log("Shift Bats For Date Loading....")
        if (allShiftBatsArray && allSchedules) {
            const filteredSBs = allShiftBatsArray.filter(shiftBat => (!validOnly || shiftBat.isValid({allStops})) && shiftBat.isRunning(date, allSchedules));
            // setShiftBatsForDate(shiftBatsForDate => ({
            //     ...shiftBatsForDate,
            //     [date.format(DATE_STRING)]: filteredSBs
            // }));
            setShiftBatsForDate(filteredSBs);
        }
    }, [allShiftBatsArray, allSchedules, date, validOnly])

    return {
        shiftBatsForDate
    };
}

export default useShiftBatsForDate;