import {useCallback, useEffect, useState} from 'react';
import { customerModelData } from '../../services/ModelService';
import {Charter} from '../../model/charter';

export default function useAllCustomers() {
    const [allCustomers, setAllCustomers] = useState(null);

    useEffect(() => {
        const listener = customerModelData.addListener({
            setterFn: customers => setAllCustomers(customers),
            loaded: customers => setAllCustomers(customers)
        })

        return () => {
            if(listener) {
                customerModelData.removeListener(listener);
            }
        }
    }, [])

    return {
        allCustomers
    };
}