import {useEffect, useState} from "react";
import {values} from "lodash";
import useAllCharters from "../features/Charters/useAllCharters";
import dayjs from "../dayjs";
import useShiftBatsForDate from "./useShiftBatsForDate";
import useJobsForDate from "./useJobsForDate";

const useChartersForDate = ({date}) => {
    const {allCharters} = useAllCharters();
    const [chartersForDate, setChartersForDate] = useState([]);

    useEffect(() => {
        if (allCharters) {
            const filteredSBs = values(allCharters)
                .filter(charter => {
                    return dayjs(charter.startDate, "YYYY-MM-DD").isSame(date, 'day')
                });
            setChartersForDate(filteredSBs);
        }
    }, [allCharters, date])

    return {
        chartersForDate
    };
}

export default useChartersForDate;
