import {DATE_STRING, PUBLIC_HOLIDAY_SCHEDULES, SCHOOL_HOLIDAY_SCHEDULES, SCHOOL_TERMS_SCHEDULES} from "./schedule";
import dayjs from "../dayjs";
import {last} from "lodash";
import {ulid} from "ulid";

function calculateCompoundInterest(price, rate, time) {
    return Math.pow((1 - rate / time), time);
}


function countSpecificDayOfSchoolWeek(start, end, dayOfWeek, trips) {


    // Initialize the counter
    let specificDayCount = 0;

    // Loop through each day between the start and end date
    while (start.isBefore(end) || start.isSame(end)) {
        // Check if the current day is the specified day of the week
        if (start.day() === dayOfWeek) {
            // Check if the current day is not in the school holidays or public holidays
            if (SCHOOL_HOLIDAY_SCHEDULES[start.year()].schedulePeriods.every(p => !start.isBetween(p.start, p.end, null, '[]')) &&
                PUBLIC_HOLIDAY_SCHEDULES[start.year()].schedulePeriods.every(p => !start.isBetween(p.start, p.end, null, '[]'))) {
                specificDayCount += trips;
            }
        }
        // Move to the next day
        start = start.add(1, 'day');
    }

    return specificDayCount;
}

export const SCHOOL_TRAVEL_DAYS = [{am: true, pm: true}, {am: true, pm: true}, {am: true, pm: true}, {
    am: true,
    pm: true
}, {am: true, pm: true}];

/**
 * A TravelSchedule that is made up of School term Periods with an AM/PM and set of travel days and a given startDate/endDate
 * policy: {discount: {type: ['compound', 'term', 'trip'], [count: 50 // for trip type], [compoundRate: 0.0001]}
 *              start: ['term', 'date'], end: ['term', 'date'], discountArray: [1, 0.8, 0.75, 0.7], tripRate: 1.10}
 */
export class TravelSchedule {
    constructor({
                    year = dayjs().year(),
                    startDate,
                    endDate,
                    travelDays = SCHOOL_TRAVEL_DAYS,
                    termPassId,
                    policy = {
                        discount: {type: 'trip', count: 100},
                        start: 'date',
                        end: 'date',
                        discountArray: [0.8, 0.75, 0.725, 0.7],
                        tripRate: 1.10
                    }
                }) {

        this.termPassId = termPassId;
        if (year) {
            this.startDate = SCHOOL_TERMS_SCHEDULES[year].schedulePeriods[0].start
            this.endDate = last(SCHOOL_TERMS_SCHEDULES[year].schedulePeriods).end
        }

        if (startDate) {
            this.startDate = dayjs(startDate, DATE_STRING) || SCHOOL_TERMS_SCHEDULES[dayjs().year()].schedulePeriods[0].start;
        }
        // if the start date year is before this year, set startDate to the first school day of the year
        if (this.startDate.year() < dayjs().year()) {
            this.startDate = SCHOOL_TERMS_SCHEDULES[dayjs().year()].schedulePeriods[0].start;
        }
        if (!this.startDate.isValid()) {
            console.log('ERROR: Invalid Start Date', startDate)
            return
        }
        year = this.startDate.year();
        // if the start date is before the first school day of the year, set startDate to the first school day of the year
        if (this.startDate.isBefore(SCHOOL_TERMS_SCHEDULES[year].schedulePeriods[0].start)) {
            this.startDate = SCHOOL_TERMS_SCHEDULES[year].schedulePeriods[0].start
        }
        if (endDate) {
            this.endDate = dayjs(endDate, DATE_STRING) || last(SCHOOL_TERMS_SCHEDULES[dayjs().year()].schedulePeriods).end;
        }
        // if the end date year is after this year, set endDate to the last school day of the year
        if (this.endDate.year() > dayjs().year()) {
            this.endDate = last(SCHOOL_TERMS_SCHEDULES[dayjs().year()].schedulePeriods).end;
        }
        // if the end date is after the last school day of the year, set endDate to the last school day of the year
        if (this.endDate.isAfter(last(SCHOOL_TERMS_SCHEDULES[year].schedulePeriods).end)) {
            this.endDate = last(SCHOOL_TERMS_SCHEDULES[year].schedulePeriods).end
        }

        this.travelDays = travelDays;

        if (typeof this.startDate === 'string') {
            this.startDate = dayjs(this.startDate, DATE_STRING)
        }
        if (typeof this.endDate === 'string') {
            this.endDate = dayjs(this.endDate, DATE_STRING)
        }

        this.policy = policy;
    }

    toJson() {
        return {
            travelDays: this.travelDays,
            startDate: this.startDate.format(DATE_STRING),
            endDate: this.endDate.format(DATE_STRING),
            termPassId: this.termPassId
        }
    }

    getYear() {
        let year = this.startDate.year();
        let thisYear = dayjs().year();
        if (year < thisYear) {
            year = thisYear;
        }
        return year
    }

    getTotalTrips(policy) {
        let year = this.getYear();

        // Parse the dates using dayjs
        const startTerm = SCHOOL_TERMS_SCHEDULES[year].schedulePeriods[SCHOOL_TERMS_SCHEDULES[year].schedulePeriods.findIndex(p => p.contains(this.startDate))];
        const endTerm = SCHOOL_TERMS_SCHEDULES[year].schedulePeriods[SCHOOL_TERMS_SCHEDULES[year].schedulePeriods.findIndex(p => p.contains(this.endDate))];

        let start = policy.start?.term ? startTerm.start : this.startDate;
        const end = policy.end?.term ? endTerm.end : this.endDate;
        let totalDays = 0;
        this.travelDays.forEach((travelDay, idx) => {
            const trips = Number(travelDay.am) + Number(travelDay.pm);
            const dayOfWeek = idx + 1
            totalDays += countSpecificDayOfSchoolWeek(start, end, dayOfWeek, trips);
        })
        return totalDays;
    }

    // TODO: Fix this to match Picton's discount logic. The discount should be for single term, semester or full year.
    getTermDiscount(policy) {
        // calculate discount based on number of schedulePeriods in the Schedule
        const year = this.getYear();
        const startTerm = SCHOOL_TERMS_SCHEDULES[year].schedulePeriods.findIndex(p => p.contains(this.startDate));

        const endTerm = SCHOOL_TERMS_SCHEDULES[year].schedulePeriods.findIndex(p => p.contains(this.endDate));
        let terms = endTerm - startTerm;
        return policy.discountArray[terms];
    }

    getDiscountedRate(policy, {totalTrips = 0} = {}) {
        if (policy.discount?.type === 'term') {
            return policy.tripRate * this.getTermDiscount(policy);
        }
        if (policy.discount?.type === 'trip') {
            const discountIdx = Math.floor(totalTrips / (policy.discount?.count || 50))
            return policy.tripRate * (discountIdx > policy.discountArray.length - 1 ? last(policy.discountArray) : policy.discountArray[discountIdx]);
        }

        if (policy.discount?.type === 'compound') {
            const rate = calculateCompoundInterest(policy.tripRate, policy.discount.compoundRate || 0.35, totalTrips)
            return policy.tripRate * rate;
        }
        return 1
    }

    /*
    {
        discount: {type: 'trip', count: 100},
        start: 'date',
        end: 'date',
        discountArray: [0.8, 0.75, 0.725, 0.7],
        tripRate: 1.10
    }
    //Picton
    {
        discount: {
            type: 'term',
        },
        start: 'date', // start at the provided date
        end: 'term', // end at the end of term
        discountArray: [0.8, 0.75, 0.75, 0.7], // discount array for term
        tripRate: 1.10 // dollar per single trip
    }
     */
    calculatePrice() {
        const totalTrips = this.getTotalTrips(this.policy);
        const discountedRate = this.getDiscountedRate(this.policy, {totalTrips});
        return Math.floor(totalTrips * discountedRate);
    }

    differences(travelSchedule) {
        let differences = {};
        if (this.startDate.format(DATE_STRING) !== travelSchedule.startDate.format(DATE_STRING)) {
            differences.startDate = travelSchedule.startDate.format(DATE_STRING);
        }
        if (this.endDate.format(DATE_STRING) !== travelSchedule.endDate.format(DATE_STRING)) {
            differences.endDate = travelSchedule.endDate.format(DATE_STRING);
        }
        if (JSON.stringify(this.travelDays) !== JSON.stringify(travelSchedule.travelDays)) {
            differences.travelDays = travelSchedule.travelDays;
        }
        return differences;
    }
}
