import React, {useCallback, useState} from 'react';
import {Button, Modal} from 'antd';
import {Col, Row} from 'react-bootstrap';
import {useAppContext} from '../libs/contextLib';
import {ReactComponent as Share} from '../assets/icons/Share.svg';
import {ReactComponent as Link} from '../assets/icons/Link.svg';
import {ReactComponent as Email} from '../assets/icons/Email.svg';
import {ReactComponent as SMS} from '../assets/icons/SMS.svg';
import {ReactComponent as Whatsapp} from '../assets/icons/Whatsapp.svg';
import config from '../config';

export default function ShareModal({shiftBat, path = 'p_sb'}) {
    const {operator, messageApi} = useAppContext();
    const [ShareModal, setShareModal] = useState(false);

    const linkUrl = useCallback(() => operator && shiftBat && path ? `${config.app.BASE_URL}/${path}/${operator.operatorId}/${shiftBat.shiftBatId}` : '', [operator, shiftBat, path]);

    const handleCopy = useCallback(async () => {
        if (!navigator?.clipboard) {
            return messageApi.error(<>Clipboard API not supported. Please copy the link manually using <a href={linkUrl()} target={'_blank'}>this link</a></>, 30);
        }
        navigator.clipboard.writeText(linkUrl())
            .then(() => messageApi.info('Link to driver duty copied to clipboard.'))
            .catch(() => {
                messageApi.error(<>There was a problem copying to clipboard. Please try again or copy the link using <a href={linkUrl()} target={'_blank'}>this link</a></>, 30);
                navigator.clipboard.writeText(''); // clear clipboard
            });
    }, [linkUrl, messageApi]);

    return (operator ?
            <>
                <Button type="primary" className="icon-button btn-filled" icon={<Share style={{width: 14}}/>}
                        onClick={() => setShareModal(true)}>Share</Button>
                <Modal
                    open={ShareModal}
                    width={550}
                    title={`Share`}
                    onCancel={() => {
                        setShareModal(false);
                    }}
                    destroyOnClose={true}
                    footer={[
                        <Button key="back" className="btn-secondary" onClick={() => {
                            setShareModal(false);
                        }}>Cancel</Button>
                    ]}
                >
                    <div className="ShareModal pt-4 pb-2">
                        <Row>
                            <Col xs={12} sm={6}>
                                <Button className="btn-secondary icon-button btn-outline btn-lg"
                                        icon={<Link/>}
                                        onClick={() => handleCopy()}>Copy Link</Button>
                            </Col>
                            {/* <Col xs={12} sm={6}><Button className="btn-secondary icon-button btn-outline btn-lg icon-dark" icon={<Print />} onClick={() => window.print()}>Print</Button></Col> */}
                            <Col xs={12} sm={6}>
                                <Button className="btn-secondary icon-button btn-outline btn-lg"
                                        icon={<SMS style={{width: 18}}/>}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            window.open(`sms:?body=${shiftBat.shiftBatNumber}%20-%20${shiftBat.shiftBatName}%0D%0A${config.app.BASE_URL}/${path}/${operator.operatorId}/${shiftBat.shiftBatId}`, '_blank');
                                        }}>Send via SMS</Button>
                            </Col>
                            <Col xs={12} sm={6}>
                                <Button className="btn-secondary icon-button btn-outline btn-lg"
                                        icon={<SMS style={{width: 18}}/>}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            window.open(`sms:?body=${shiftBat.shiftBatNumber}%20-%20${shiftBat.shiftBatName}%0D%0A${config.app.BASE_URL}/${path}/${operator.operatorId}/${shiftBat.shiftBatId}`, '_blank');
                                        }}>Send via SMS (Bulk)</Button>
                            </Col>
                            <Col xs={12} sm={6}>
                                <Button className="btn-secondary icon-button btn-outline btn-lg"
                                        icon={<Email style={{width: 18, height: 18}}/>}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            window.open(`mailto:?subject=${shiftBat.shiftBatNumber}%20-%20${shiftBat.shiftBatName}&body=${shiftBat.shiftBatNumber}%20-%20${shiftBat.shiftBatName}%0D%0A${config.app.BASE_URL}/${path}/${operator.operatorId}/${shiftBat.shiftBatId}`, '_blank');
                                        }}>Send via Email</Button>
                            </Col>
                            <Col xs={12} sm={6}>
                                <Button className="btn-secondary icon-button btn-outline btn-lg" icon={<Whatsapp/>}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            window.open(`https://wa.me/?text=${shiftBat.shiftBatNumber}%20-%20${shiftBat.shiftBatName}%0D%0A${config.app.BASE_URL}/${path}/${operator.operatorId}/${shiftBat.shiftBatId}`, '_blank');
                                        }}>Send via Whatsapp</Button>
                            </Col>
                            {/*<Col xs={12} sm={6}>*/}
                            {/*    <ShiftBatPrintModal*/}
                            {/*        shiftBat={shiftBat}*/}
                            {/*        visible={printShiftBat}*/}
                            {/*        setVisible={setPrinShiftBat}*/}
                            {/*    />*/}
                            {/*    <Button onClick={() => setPrinShiftBat(!printShiftBat)}>Print</Button>*/}
                            {/*</Col>*/}
                            {/* <Col xs={12} sm={6}><Button className="btn-secondary icon-button btn-outline btn-lg" icon={<Embed />}>Copy Embed</Button></Col> */}
                            {/* <Col xs={12} sm={6}>{(operator.features.beta || operator.features.all) && <LoaderButton className="btn-secondary icon-button btn-outline btn-lg icon-dark" icon={<Reset />} isLoading={syncing} onClick={sync}>Sync</LoaderButton>}</Col> */}
                        </Row>
                    </div>
                </Modal>
            </> : <></>
    );
}
