import React, {useState} from "react";
import {Button, Tooltip} from "antd";
import {ReactComponent as Plus} from "../../assets/icons/Plus.svg";
import {toTime} from "../../libs/formatLib";
import Pin from "../../components/Pin";
import "./ShiftBat.css"
import {ulid} from "ulid";
import ShiftBatStopNoteEdit from "./ShiftBatStopNoteEdit";
import ShiftBatNoteRow from "./ShiftBatNoteRow";
import ShiftBatTransferRow from "./ShiftBatTransferRow";
import TransfersModal from "../Transfers/TransfersModal";
import {ToTopOutlined} from "@ant-design/icons";

function ShiftBatStopRow({
                             shiftBatStopRow,
                             trimStart,
                             trimEnd,
                             onSave,
                             editMode,
                             printMode,
                             setSelectedStop,
                             selectedStop,
                             setFocusStop
                         }) {

    const [editing, setEditing] = useState({})

    return (<>
        <div
            className={`row align-items-center align-items-stretch justify-content-center ${selectedStop && shiftBatStopRow?.stop.stopTimeId === selectedStop.stopTimeId ? 'Selected' : ''}`}
            style={{cursor: 'pointer'}}
            onClick={e => {
                if (printMode) {
                    return
                }
                e.stopPropagation();
                setSelectedStop(shiftBatStopRow.stop)
            }}
            onMouseOver={() => {
                if (printMode) {
                    return
                }
                setFocusStop(shiftBatStopRow.stop);
            }}>
            <div className="col-1 sb-time">{shiftBatStopRow.time > -1 &&
                <span>{toTime(shiftBatStopRow.time)}</span>}</div>
            <div className="col-2 sb-step-no">{shiftBatStopRow.stop &&
                <Pin size={20} type={shiftBatStopRow.stop.type}
                     sequence={shiftBatStopRow.sequence}/>}</div>
            <div className="col-9 d-flex RouteTitle flex-nowrap align-items-center sb-info">
                <div className={`RouteInfo`}>
                    <h4 className="title">{shiftBatStopRow.title}</h4>
                    {editMode ?
                        <div className="sb-row-trim-buttons-wrap">
                            {trimStart && <Tooltip title={"Start service"}><Button icon={<ToTopOutlined rotate={180}/>}
                                                                                 onClick={trimStart}/></Tooltip>}
                            {trimEnd && <Tooltip title={"End service"}><Button icon={<ToTopOutlined/>}
                                                                               onClick={trimEnd}/></Tooltip>}
                        </div> : <></>}
                    {editMode ? <Button className="sb-add-comment" icon={<Plus/>} onClick={e => {
                        e.stopPropagation();
                        const id = ulid();
                        shiftBatStopRow.addNote({id}, 0)
                        setEditing(editing => ({...editing, [id]: true}))
                    }}/> : <></>}
                </div>
            </div>
        </div>

        {shiftBatStopRow.transfersTo?.length ?
            <div className="row align-items-center align-items-stretch justify-content-center sb-comment-row">
                <div className="col-1 sb-time"></div>
                <div className="col-2 sb-step-no"></div>
                <div className="col-9 d-flex RouteTitle flex-nowrap align-items-center sb-info">
                    <ShiftBatTransferRow shiftBatStopRow={shiftBatStopRow} transfers={shiftBatStopRow.transfersTo}
                                         pickup={false}/></div>
            </div> : <></>

        }
        {shiftBatStopRow.transfersFrom?.length ?
            <div className="row align-items-center align-items-stretch justify-content-center sb-comment-row">
                <div className="col-1 sb-time"></div>
                <div className="col-2 sb-step-no"></div>
                <div className="col-9 d-flex RouteTitle flex-nowrap align-items-center sb-info">
                    <ShiftBatTransferRow shiftBatStopRow={shiftBatStopRow}
                                         transfers={shiftBatStopRow.transfersFrom}
                                         pickup={true}/></div>
            </div> : <></>
        }
        {shiftBatStopRow.noteRows && shiftBatStopRow.noteRows.map((shiftBatNote, idx) => (
            editing[shiftBatNote.id] ?
                <ShiftBatStopNoteEdit key={'ESTR-' + shiftBatNote.id} shiftBatNote={shiftBatNote}
                                      onCancel={(e, note) => {
                                          if (!note.title?.length) {
                                              shiftBatStopRow.removeNote(note)
                                              return onSave(e);
                                          }
                                          setEditing(editing => ({...editing, [shiftBatNote.id]: false}))
                                      }}
                                      onSave={(e, note) => {
                                          if (!note.title?.length) {
                                              shiftBatStopRow.removeNote(note)
                                              return onSave(e);
                                          }
                                          shiftBatStopRow.replaceNote(note);
                                          setEditing(editing => ({...editing, [shiftBatNote.id]: false}))
                                          onSave(e)
                                      }}/>
                :
                <ShiftBatNoteRow key={'STNR-' + shiftBatNote.id}
                                 shiftBatNoteRow={shiftBatNote} editMode={editMode}
                                 onEdit={e => setEditing(editing => ({...editing, [shiftBatNote.id]: true}))}
                                 onAdd={e => {
                                     const id = ulid();
                                     shiftBatStopRow.addNote({id}, idx + 1)
                                     setEditing(editing => ({...editing, [id]: true}))
                                 }}
                                 onDelete={e => {
                                     shiftBatStopRow.removeNote(shiftBatNote)
                                     onSave(e);
                                 }}
                />
        ))}
    </>);
}

export default React.memo(ShiftBatStopRow)
