import React, {useEffect, useState} from "react";
import {scheduleModelData, transferModelData} from "../services/ModelService";

const useAllSchedules = ()=> {
    const [allSchedules, setAllSchedules] = useState(null);

    useEffect(() => {
        const listener = scheduleModelData.addListener({
            setterFn: setAllSchedules, loaded: schedules => {
                setAllSchedules(schedules);
            }
        });

        return () => {
            if (listener)
                scheduleModelData.removeListener(listener)
        }
    }, [])

    return {
        allSchedules
    };
}

export default useAllSchedules;
