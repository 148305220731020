import React, {useCallback, useEffect, useState} from "react";
import {onError} from "../libs/errorLib";
import "./TripPlanner.css";
import RouteMapViewer from "../components/RouteMapViewer";
import {
    getDepartureTime,
    getDepartureTimeAsSecondsSinceMidnight,
    getDistanceInMetres,
    publicStopFilter
} from "../libs/routes-lib";
import LoadMessage from "../components/LoadMessage";
import {
    capitalize,
    cloneDeep,
    differenceWith,
    flatten,
    intersection,
    intersectionWith,
    last,
    uniq,
    uniqBy,
    uniqWith,
    values
} from "lodash";
// eslint-disable-next-line
import {Button, Col, Image, Row} from "react-bootstrap";
import {createUsage, prepareTransfers} from "../services/routeService";
import DisplayStop from "../components/DisplayStop";
import {localGeocoder} from "../libs/geocoderLib";
import TripLocation from "../components/TripLocation";
import {CONNECTIONS, Prefs, prefs as savedPrefs, RANK_BY} from "../model/prefs";
import TripOverview from "../components/TripOverview";
import {CloseOutlined} from "@ant-design/icons";
import {TimeFilter, TimeFilterType} from "../model/timeFilter";
import {Alert, message, Select} from "antd";
import {BaseStop, Stop} from "../model/busRoute";
import {toHrsMinsSecs, toKmMs, toMins, toTime} from "../libs/formatLib";
import {stopDistCache} from "../model/stopDistCache";
import DirectTripOverview from "../components/DirectTripOverview";
import dayjs from "../dayjs";
import config from "../config";
import {GetFullUrl, getKey} from "../libs/hooksLib";
import RouteNumber from "../components/RouteNumber";
import {renderConnectionImg} from "../components/Legs";
import TripPlannerTimePrefsView from "../components/TripPlannerTimePrefsView";
import TripPlannerPrefsView from "../components/TripPlannerPrefsView";
import {getCachedAccurateDurations, getCachedGeocode, getCachedSimpleDurations} from "../libs/pathLib";

import {ReactComponent as Switch} from '../assets/icons/Switch.svg';
import {ReactComponent as Target} from '../assets/icons/Target.svg';
import {ReactComponent as Down} from '../assets/icons/Down.svg';
import {ReactComponent as Print} from '../assets/icons/Print.svg';
import {ReactComponent as Send} from '../assets/icons/Send.svg';
import PrintTripModal from "../components/PrintTripModal";
import {JourneyPlanFilter} from "../model/journeyPlan";
import {find, keyBy} from "lodash/collection";
import log from 'loglevel';
import ReactGA from "react-ga4";
import {Popconfirm} from "antd/lib";
import Features, {FEATURE} from "../model/features";
import {QRCode} from "react-qrcode-logo";
import {useAppContext} from "../libs/contextLib";
import {routeModelData} from "../services/ModelService";
import {API} from "aws-amplify";
import {Schedule} from "../model/schedule";

const logger = log.getLogger('TripPlanner');

const {Option} = Select;

export const getAvailableDirections = (DIRECTIONS, filter) => {
    return DIRECTIONS.filter(d => (filter.school && d.service === 'school') || (filter.regular && d.service === 'regular'))
}

const getConnectionDuration = (tripPlan) => {
    const connectStartLeg = tripPlan.legs[0]
    const connectEndLeg = last(tripPlan.legs)
    return (connectStartLeg.type !== 'direct' ? connectStartLeg.duration : 0) + (connectEndLeg.type !== 'direct' ? connectEndLeg.duration : 0)
}

export const sortByShortestTime = (a, b) => {
    const diff = toMins(a.duration) - toMins(b.duration);
    if (Math.abs(diff) >= 1) {
        return diff
    }

    return getConnectionDuration(a) - getConnectionDuration(b);
}
export const sortByNextService = (a, b) => {
    if (!a.nextDepartureDate || !b.nextDepartureDate || !a.timeFilter || !b.timeFilter) {
        return sortByShortestTime(a, b)
    }
    let diff = 0
    if (a.timeFilter.type === TimeFilterType.ARRIVING) {
        const aTTS = Math.abs(a.timeFilter.startTime.diff(a.nextArrivalTime))
        const bTTS = Math.abs(b.timeFilter.startTime.diff(b.nextArrivalTime))
        diff = aTTS - bTTS
    } else {
        diff = a.nextDepartureTime - b.nextDepartureTime;
    }
    if (Math.abs(diff) > 90) {
        return diff
    }

    return sortByShortestTime(a, b);
}

export const sortByLeastWalking = (a, b) => {
    const diff = getConnectionDuration(a) - getConnectionDuration(b);
    if (Math.abs(diff) > 90) {
        return diff
    }
    return sortByNextService(a, b);
}
export const sortTrips = (a, b, rankBy) => {
    if (rankBy === RANK_BY.next) {
        logger.debug(`sorting by next service`)
        return sortByNextService(a, b);
    }
    if (rankBy === RANK_BY.time) {
        logger.debug(`sorting by time`)
        return sortByShortestTime(a, b);
    }
    return sortByLeastWalking(a, b);
}

// const sortTripPlans = (tripPlans, rankBy) => {
//     const next = tripPlans.sort(sortByNextService);
//     const time = tripPlans.sort(sortByShortestTime);
//     const walking = tripPlans.sort(sortByLeastWalking);
//
//     if (rankBy === RANK_BY.next) {
//         return sortByNextService(a, b);
//     }
//     if (rankBy === RANK_BY.time) {
//         logger.debug(`sorting by time`)
//         return sortByShortestTime(a, b);
//     }
// }

export const getCompositeRouteId = (tripPlan, leg) => {
    return tripPlan.departureTime + '|' + leg.route.startStopIdx + '|' + leg.route.endStopIdx + '|' + leg.route.routeId + (leg.merged ? '|' + leg.merged.join('|') : '')
}

export const getRouteIdFromCompositeId = compositeId => {
    const parts = compositeId.split('|');
    return parts.slice(3).join(',');
}

export default function TripPlanner({operatorId}) {
    const {isAuthenticated} = useAppContext();
    const [key, setKey] = useState();
    const [schedules, setSchedules] = useState(null);
    const [transfers, setTransfers] = useState(null);
    const [features, setFeatures] = useState(null);
    const [operator, setOperator] = useState({operatorName: null, operatorPhone: null, operatorEmail: null});
    // const [getConnectionDuration, setGetConnectionDuration] = useState(getCachedBasicDurations);
    const [mapRef, setMapRef] = useState(null);
    // const geocoderContainerFromRef = useRef(null);
    // const geocoderContainerToRef = useRef(null);
    const [viewState, setViewState] = useState(null);
    const [routes, setRoutes] = useState([]);
    const [showMap, setShowMap] = useState(false);

    const [tripPlans, setTripPlans] = useState([]);
    const [filteredTripPlans, setFilteredTripPlans] = useState([]);
    const [basicStops, setBasicStops] = useState(null);
    const [allStops, setAllStops] = useState(null);
    // eslint-disable-next-line
    const [stopsByRoute, setStopsByRoute] = useState({});
    const [filter, setFilter] = useState({});
// eslint-disable-next-line
    const [fromOptions, setFromOptions] = useState([]);
    // eslint-disable-next-line
    const [fromValue, setFromValue] = useState(null);
    // eslint-disable-next-line
    const [toOptions, setToOptions] = useState([]);
    const [toValue, setToValue] = useState(null);
    const [searchFrom, setSearchFrom] = useState(null);
    const [searchTo, setSearchTo] = useState(null);
    const [fetchingRoutes, setFetchingRoutes] = useState(false);
    const [fetchingParams, setFetchingParams] = useState(false);
    const [fetching, setFetching] = useState(true);
    const [highlightedRouteIds, setHighlightedRouteIds] = useState([]);
    const [zoom, setZoom] = useState([]);
    const [zoomStops, setZoomStops] = useState([]);
    const [selectedStop, setSelectedStop] = useState(null)
    const [focusStop, setFocusStop] = useState(null)
    const searchParams = window.location.search;
    // eslint-disable-next-line
    const [startMarker, setStartMarker] = useState(null);
    // eslint-disable-next-line
    const [endMarker, setEndMarker] = useState(null);
    // eslint-disable-next-line
    const [popupData, setPopupData] = useState(null);
    const [prefs, setPrefs] = useState(savedPrefs);
    // eslint-disable-next-line
    const [loadingCurrentPos, setLoadingCurrentPos] = useState(false)
    const [reverse, setReverse] = useState(false);
    const [placeholderFrom, setPlaceholderFrom] = useState(null)
    const [placeholderTo, setPlaceholderTo] = useState(null)
    const [rankBy, setRankBy] = useState(RANK_BY.walking);
    // eslint-disable-next-line
    const [show, setShow] = useState('All');
    const [showPrefs, setShowPrefs] = useState(false);
    const [timeFilter, setTimeFilter] = useState(null);
    const [filteredRoutes, setFilteredRoutes] = useState([])

    const [fromQuery, setFromQuery] = useState(false)

    const fullUrl = GetFullUrl();

    const applyAcs = useCallback(() => features?.tripPlannerAccurate(), [features]);
    const [messageApi, contextHolder] = message.useMessage();

    const [outsideDistance, setOutsideDistance] = useState(false);

    const getConnectionDuration = useCallback((profile, startCoord, coordinates, fallbackSpeed = 4.5) => {
        const exe = async (profile, startCoord, coordinates, fallbackSpeed) => {
            if (applyAcs() && key) {
                logger.debug('Getting accurate durations...')
                return await getCachedAccurateDurations(profile, startCoord, coordinates, fallbackSpeed, config.maps.geoapify[key])
            }
            logger.debug('Getting simple durations...')
            return getCachedSimpleDurations(profile, startCoord, coordinates, fallbackSpeed)
        };
        return exe(profile, startCoord, coordinates, fallbackSpeed);
    }, [applyAcs, key])

    useEffect(() => {
            const sortTripsFn = (t1, t2) => sortTrips(t1, t2, rankBy)
            logger.debug('Updating filteredTripPlans...')
            if (tripPlans) {
                if (!show || show === 'All') {
                    setFilteredTripPlans([...tripPlans].sort(sortTripsFn));
                    return
                }
                if (show === 'Regular') {
                    setFilteredTripPlans(tripPlans.filter(t => t.legs.filter(l => !!l.route).findIndex(l => l.route.routeType !== 'School') > -1).sort(sortTripsFn))
                    return
                }
                const tps = tripPlans.filter(t => t.legs.filter(l => l.route).findIndex(l => l.route.direction === show.toUpperCase()) > -1)
                setFilteredTripPlans(tps.sort(sortTripsFn))
            }
        }, [show, rankBy, tripPlans, setFilteredTripPlans]
    )

    useEffect(() => {
        logger.debug('setting filteredRoutes')
        setHighlightedRouteIds([])
        setZoom([]);
        // setViewState(null)
        setFilteredRoutes(uniqBy(flatten(filteredTripPlans.map(tp => flatten(tp.legs.filter(l => l.route).map(l => l.route)))), 'routeId'))
    }, [filteredTripPlans])

    useEffect(() => {

        if (fromQuery) {
            return
        }
        if (filter?.from?.stopType === 'school' && filter?.to?.stopType !== 'school') {
            setTimeFilter(timeFilter => {
                timeFilter = timeFilter || new TimeFilter();
                timeFilter.setSchoolPM();
                return new TimeFilter(timeFilter);
            })
        } else if (filter?.from?.stopType !== 'school' && filter?.to?.stopType === 'school') {
            setTimeFilter(timeFilter => {
                timeFilter = timeFilter || new TimeFilter();
                timeFilter.setSchoolAM();
                return new TimeFilter(timeFilter);
            })
        } else if (filter?.from?.stopType === 'school' && filter?.to?.stopType === 'school') {
            setTimeFilter(timeFilter => {
                timeFilter = timeFilter || new TimeFilter();
                timeFilter.setSchool();
                return new TimeFilter(timeFilter);
            })
        } else {
            setTimeFilter(timeFilter => timeFilter ? timeFilter : new TimeFilter());
        }

    }, [filter, setTimeFilter, fromQuery])

    useEffect(() => {
        async function onLoad() {
            try {
                const _key = operatorId || await getKey();
                setKey(_key);
                logger.debug('Getting stops in TripPlanner. for %s', _key);

                let time = Date.now()
                let {stops: basicStops, schedules, operator} = await API.get('public', `/jp/startup?_k=${_key}`,
                    {headers: {'x-accept-encoding': 'gzip'}});
                basicStops = basicStops.map(s => {
                    s.verified = 1
                    return new Stop(s)
                })
                schedules = keyBy(schedules.map(s => new Schedule(s)), 'scheduleId')
                Object.values(schedules).forEach(s => s.setSubSchedules(schedules))
                basicStops = keyBy(basicStops, 'stopId')
                setBasicStops(basicStops);
                setSchedules(schedules);
                operator.features = new Features(operator.features);
                setFeatures(operator.features)
                setOperator(operator);

                console.log('Took', Date.now() - time, 'ms to startup')
                // const stopsById = await API.get("routes", `${stopsUrl}&includingProps=stopId,stopName,lat,lon`);
                // let fetched = [];
                // let response = await API.post("routes", `${stopsUrl}&includingProps=stopId,stopName,lat,lon`)
                // while (response.LastEvaluatedKey) {
                //     fetched = fetched.concat(response.items || [])
                //     response = await API.post("routes", `${stopsUrl}&includingProps=stopId,stopName,lat,lon`, {body: {LastEvaluatedKey: response.LastEvaluatedKey}})
                // }
                // fetched = fetched.concat(response.items || [])
                // setAllStops(keyBy(fetched, 'stopId'));

                time = Date.now()
                API.get('public', `/jp/load?_k=${_key}`, {headers: {'x-accept-encoding': 'gzip'}}).then(({
                                                                                                             stops,
                                                                                                             transfers
                                                                                                         }) => {

                    const allStops = keyBy(stops, 'stopId')
                    Object.keys(basicStops).forEach(stopId => {
                        allStops[stopId] = new Stop({...basicStops[stopId], ...allStops[stopId]})
                    })
                    setAllStops(allStops)

                    setTransfers(prepareTransfers(transfers, schedules, allStops));
                    console.log('Took', Date.now() - time, 'ms to load')
                })
                logger.debug('Getting Operator and Schedules for %s', _key);

                setFetching(false)
            } catch (e) {
                onError(e);
            }
        }

        onLoad().then(() => {
            logger.debug('Stop data loaded.');
            ReactGA.send({hitType: "pageview", page: "/", title: "publishable-journey-planner"});
        });
    }, [setKey, setFetching, setAllStops, setBasicStops, setStopsByRoute, operatorId, setFeatures, setSchedules, setTransfers, setOperator]);


    // useEffect(() => {
    //     if (!key || !allStops) {
    //         return
    //     }
    //     const exe = async () => {
    //         logger.debug('Getting Operator and Schedules for %s', key);
    //         const operator = await getPubOperator(key);
    //         setFeatures(operator.features);
    //         setOperator(operator);
    //         const schedules = await scheduleModelData.getAll(key)
    //         setSchedules(schedules);
    //
    //         if (operator.features.transfers()) {
    //             let transfers = await getTransfers(key, schedules, allStops);
    //             setTransfers(transfers);
    //         }
    //     }
    //     exe().then(() => logger.debug('Operator applied.'));
    // }, [setFeatures, setSchedules, key, setTransfers, setOperator, allStops]);

    const onClear = useCallback((filterProp) => {
        setFilteredTripPlans([]);
        if (filterProp) {
            setFilter(filter => {
                delete filter[filterProp]
                return filter
            });
        }
        setTripPlans([]);
        setReverse(false);
        setZoomStops([]);
        setHighlightedRouteIds([])
        setZoom([]);
    }, [setFilter, setFilteredTripPlans, setTripPlans])

    const onClearFrom = useCallback(() => {
        return onClear('from')
    }, [onClear])

    const onClearTo = useCallback(() => {
        return onClear('to')
    }, [onClear])

    const getCurrentLocation = useCallback((from) => {
        setLoadingCurrentPos(true)
        if (from) {
            onClearFrom();
            setPlaceholderFrom('My current location')
        } else {
            onClearTo();
            setPlaceholderTo('My current location')
        }
        navigator.geolocation.getCurrentPosition(function (position) {
            logger.debug("Latitude is :", position.coords.latitude);
            logger.debug("Longitude is :", position.coords.longitude);
            if (from) {

                setFilter(filter => {
                    filter.from = {
                        place_name: position.coords.latitude + ',' + position.coords.longitude,
                        center: [position.coords.longitude, position.coords.latitude]
                    }
                    return {...filter}
                })
                // setSearchFrom(position.coords.latitude + ',' + position.coords.longitude)
                setPlaceholderFrom(null)
            } else {
                setFilter(filter => {
                    filter.to = {
                        place_name: position.coords.latitude + ',' + position.coords.longitude,
                        center: [position.coords.longitude, position.coords.latitude]
                    }
                    return {...filter}
                })
                // setSearchTo(position.coords.latitude + ',' + position.coords.longitude)
                setPlaceholderTo(null)
            }
            setLoadingCurrentPos(false)
        });
    }, [setLoadingCurrentPos, onClearFrom, onClearTo, setFilter]);

    // eslint-disable-next-line
    const findOnRoute = (stopsByRoute, startId, endId, currentLeg, routeStops, trip, trips, depth) => {
        if (++depth > 5) {
            return
        }
        // logger.debug(depth, startId, endId, currentLeg, routeStops);

        const startIdx = routeStops.findIndex(s => s.stopId === startId);
        trip = trip || {found: false, starting: currentLeg.routeId, legs: []}
        if (startIdx > -1 && startIdx < routeStops.length) {
            currentLeg.startIdx = startIdx
            currentLeg.startId = startId
            // logger.debug('Current leg ', currentLeg);
            const endIdx = routeStops.findIndex(s => s.stopId === endId)
            if (endIdx > -1 && endIdx > startIdx) {
                // logger.debug('Found ', endId, 'on', currentLeg.routeId, 'at', endIdx, 'after', startIdx)
                currentLeg.endIdx = endIdx
                currentLeg.endId = routeStops[endIdx].stopId
                // logger.debug('Last leg ', currentLeg);
                // let newTrip = {...trip, legs: [...trip.legs]};
                // trip.legs.push(currentLeg);
                trip.found = true;
                trips.push({...trip, legs: cloneDeep(trip.legs).concat(currentLeg)});
                return true
                // trip = {...trip, found: false, legs: [...trip.legs]}
                // trip.legs.pop();
                // trips.push({...trip, legs: [...trip.legs]});
                // logger.debug('current trips: ', trips);
                // trip.legs.pop();
            }
            for (let i = startIdx + 1; i < routeStops.length; i++) {

                // logger.debug('Checking stopIDX: ', i, 'on', currentLeg.routeId);
                const nextStop = routeStops[i]
                if (nextStop.transfers) {
                    // logger.debug('Checking transfers on ', nextStop.stopId)
                    Object.keys(nextStop.transfers).forEach(routeId => {
                        // logger.debug('Transferring to', routeId, 'at', nextStop.stopId);
                        currentLeg.endIdx = i;
                        currentLeg.endId = nextStop.stopId
                        // logger.debug('Added current leg ', currentLeg);
                        trip.legs.push(currentLeg);
                        findOnRoute(stopsByRoute, nextStop.stopId, endId, {routeId}, stopsByRoute[routeId], trip, trips, depth)
                        // trip = cloneDeep(trip);
                        // trip.legs = cloneDeep(trip.legs);
                        // currentLeg = last(trip.legs);
                        // delete currentLeg.endId;
                        // delete currentLeg.endIdx;


                        // if (trip.found) {
                        //     return
                        // }
                    })
                }
                trip.legs.pop();
                // if (trip.found) {
                // logger.debug('trip found. Starting new trip IDX ', i, 'on', currentLeg.routeId)
                // trip = cloneDeep(trip);
                // currentLeg = trip.legs.pop()
                // trip.found = false;
                // delete currentLeg.endId;
                // delete currentLeg.endIdx;
                // return
                // }
            }
        }
    }

// eslint-disable-next-line
//     const find = (stopsByRoute, allStops, startId, endId) => {
//         logger.debug('Looking for trips from', startId, 'to', endId);
//         const trips = [];
//         allStops[startId].routes.forEach(r => {
//             // const startIdx = routes[r.routeId].findIndex(s => s.stopId === startId);
//             // if (startIdx > -1) {
//             //     trips.push({routeId: r.routeId, startIdx})
//             //     if (routes[r.routeId].findIndex(s => s.stopId === startId)) {
//             //     }
//             // }
//             logger.debug('Travelling route', r.routeId)
//             // const startIdx = routes[r.routeId].findIndex(s => s.stopId === startId);
//             findOnRoute(stopsByRoute, startId, endId, {routeId: r.routeId}, stopsByRoute[r.routeId], null, trips, 0);
//         })
//         return trips
//     }

    useEffect(() => {
        logger.debug("Setting from options...")
        // setRoutes([]);
        // setFilteredRoutes([]);

        // if (!filter.to) {
        const _fromOpts = values(allStops).filter(s => s.verified);
        return setFromOptions(_fromOpts);
        // }
        //
        // const _fromOpts = values(allStops).filter(s => stopSelectionFilter(s, filter.to, (r) => {
        //     const direction = filter.to.stopType !== 'school' ? null : 'AM'
        //     return !direction || r.direction === direction
        // }, (stopRoute, filteredStopRoute) => stopRoute.minSequence < filteredStopRoute.maxSequence))
        // setFromOptions(_fromOpts);

    }, [allStops, setFromOptions]);

    useEffect(() => {

        logger.debug('building tree...');


        // setFilteredRoutes([]);
        // setRoutes([]);
        logger.debug("Setting to options...")
        // if (!filter.from) {
        const _toOpts = values(allStops).filter(s => s.verified);
        return setToOptions(_toOpts);
        // }
        //
        // const _toOptions = values(allStops).filter(s => stopSelectionFilter(s, filter.from, (r) => {
        //     const direction = filter.from.stopType !== 'school' ? null : 'PM'
        //     return direction ? r.direction === direction : r.direction !== 'PM'
        // }, (stopRoute, filteredStopRoute) => stopRoute.maxSequence > filteredStopRoute.minSequence));
        // setToOptions(_toOptions);
    }, [allStops, setToOptions]);

    useEffect(() => {
        // setFilter(getFilterFromParams(searchParams));
        if (!allStops || !values(allStops).length) {
            return
        }

        const params = new URLSearchParams(searchParams);
        const from = params.get("b_fr")
        const to = params.get("b_to")
        const time = params.get("b_ti");
        const connectStart = params.get("b_st");
        const connectEnd = params.get("b_en");
        const noTransfers = params.get("b_notx");
        const noSchool = params.get("b_nosc");
        const currentLocation = params.get("b_cl");
        const sort = decodeURI(params.get("b_so"));
        // const [fromType, fromMins] = params.get("cf").split();
        // const cf = params.get("cf");
        if (from?.length || to?.length) {

            const load = async () => {
                let filter;
                if (from?.length) {
                    if (currentLocation && !to?.length) {
                        getCurrentLocation(false)
                    }
                    filter = filter || {}
                    filter.from = {}
                    let [fromType, fromData, fromLatlon] = from.split("|")
                    if (fromType === 'poi') {
                        if (!fromLatlon) {
                            const result = await getCachedGeocode(fromData)
                            if (!result?.[0]?.lat || !result?.[0]?.lon) {
                                fromLatlon = '0,0';
                            }
                            fromLatlon = `${result[0].lat},${result[0].lon}`;
                        }
                        const [fromLat, fromLon] = fromLatlon.split(',').map(parseFloat);
                        filter.from.place_name = fromData;
                        filter.from.place_type = fromType;
                        filter.from.center = [fromLon, fromLat];
                    } else {
                        filter.from = allStops[fromData];
                        filter.from.place_name = filter.from.stopName
                    }
                }

                if (to?.length) {
                    if (currentLocation && !from?.length) {
                        getCurrentLocation(true)
                    }
                    filter = filter || {}
                    filter.to = {}
                    let [toType, toData, toLatlon] = to.split("|");

                    if (toType === 'poi') {
                        if (!toLatlon) {
                            const result = await getCachedGeocode(toData)
                            if (!result?.[0]?.lat || !result?.[0]?.lon) {
                                toLatlon = '0,0';
                            }
                            toLatlon = `${result[0].lat},${result[0].lon}`;
                        }
                        const [toLat, toLon] = toLatlon.split(',').map(parseFloat)
                        filter.to.place_name = toData;
                        filter.to.place_type = toType;
                        filter.to.center = [toLon, toLat];
                    } else {
                        filter.to = allStops[toData];
                        filter.to.place_name = filter.to.stopName
                    }

                }
                if (time?.length) {
                    let timeFilter = new TimeFilter();
                    if (time.startsWith("school|")) {
                        const direction = time.slice(time.indexOf("|") + 1).toLowerCase();
                        if (!direction?.length) {
                            return
                        }

                        if (direction === 'am') {
                            timeFilter.setSchoolAM();
                        } else if (direction === 'pm') {
                            timeFilter.setSchoolPM();
                        } else {
                            timeFilter.setSchool();
                        }
                    } else {
                        let [type, datetime] = time.split("|")
                        timeFilter.type = capitalize(type)
                        datetime = dayjs(datetime)
                        if (timeFilter.type !== TimeFilterType.NOW && datetime.isAfter(dayjs())) {
                            timeFilter.startTime = datetime;
                        }
                    }
                    setTimeFilter(timeFilter)
                }

                setPrefs(prefs => {
                    if (connectStart?.length) {
                        const [startType, startMaxTime] = connectStart.split("|")
                        prefs.set('connectStart', CONNECTIONS[startType])
                        prefs.set('maxConnectStartTime', parseInt(startMaxTime))
                    }
                    if (connectEnd?.length) {
                        const [endType, endMaxTime] = connectEnd.split("|")
                        prefs.set('connectEnd', CONNECTIONS[endType])
                        prefs.set('maxConnectEndTime', parseInt(endMaxTime))
                    }

                    if ("true" === noTransfers) {
                        prefs.set("avoidTransfers", true)
                    }
                    if ("true" === noSchool) {
                        prefs.set("avoidSchool", true)
                    }
                    return new Prefs(prefs, true)
                })

                if (sort?.length) {
                    setRankBy(sort)
                }

                setFromQuery(true)

                setFilter(filter);

                // setSearchFrom(filter.from.place_name);
                // setSearchTo(filter.to.place_name)

            }

            setFetchingParams(true)
            load().then(() => {
                console.log('Fetched params.')
                setFetchingParams(false)
            })
        } else if (currentLocation) {
            getCurrentLocation(true)
        }

    }, [setFilter, setRankBy, searchParams, setSearchFrom, setSearchTo, setFromQuery, allStops, setFetchingParams, setPrefs, getCurrentLocation]);

    const getNextTrip = useCallback((timeFilter, route, nextDepartureDate) => {
        if (timeFilter.type === TimeFilterType.ARRIVING) {
            return route.getNextTripBeforeDate(nextDepartureDate, timeFilter.anyDate, schedules);
        } else {
            return route.getNextTripAfterDate(nextDepartureDate, timeFilter.anyDate, schedules);
        }
    }, [schedules]);

// eslint-disable-next-line
    const createRouteTreeForStop = (allStops) => {

        const stopsByRoute = {}
        values(allStops).forEach(s => {
            s.routes.forEach(r => {
                if (!stopsByRoute[r.routeId]) stopsByRoute[r.routeId] = []
                stopsByRoute[r.routeId].push({stopId: s.stopId, stopName: s.stopName, sequence: r.minSequence});
                if (r.minSequence !== r.maxSequence) {
                    stopsByRoute[r.routeId].push({stopId: s.stopId, stopName: s.stopName, sequence: r.maxSequence});
                }
            })
        })
        // logger.debug('stopsByRoute:', stopsByRoute)

        Object.keys(stopsByRoute).forEach(routeId => stopsByRoute[routeId].sort((s1, s2) => s1.sequence - s2.sequence))

        const attachStops = (stop, route) => {
            // logger.debug('Attaching transfer', route.routeId, 'to', stop.stopId);
            stop.transfers = {}
            const stopIdx = stopsByRoute[route.routeId].findIndex(s => s.stopId === stop.stopId)
            stop.transfers[route.routeId] = stopsByRoute[route.routeId].slice(stopIdx + 1);
            if (!Object.keys(stop.transfers).length) delete stop.routes
        }

        Object.keys(stopsByRoute).forEach(routeId => {
            const routeStops = stopsByRoute[routeId];
            // logger.debug('stops for route', routeId, routeStops);
            routeStops.forEach(stop => {
                const routesForStop = allStops[stop.stopId].routes.filter(r => r.routeId !== routeId);
                routesForStop.forEach(route => {
                    attachStops(stop, route)
                })
            });
        })

        logger.debug("Route Tree", stopsByRoute);
        return stopsByRoute;
    }

    const getNearestStopsToCoord = useCallback(async (allVerifiedStopsArray, coord, maxDist, maxDuration, connectType, schoolOnly) => {
        const firstCut = allVerifiedStopsArray.filter(stop => !schoolOnly || stop.stopType === 'school').map(stop => ({
            ...stop,
            cheapDist: getDistanceInMetres(coord, stop)
        }))
            .filter(s => {
                return s.cheapDist <= maxDist
            })
            .map(s => {
                // logger.debug('Getting cached dist for %s', s.stopName)
                const {dist, duration} = stopDistCache.getData(connectType, coord, s.stopId);
                // logger.debug('Cached %d, %d for %s', dist, duration, s.stopName)
                s.dist = dist;
                s.duration = duration;
                // s.stopName === "St Johns College, Sheraton Rd" && logger.debug("Checking dist  St Johns College, Sheraton Rd", dist, duration)
                return s
            })

        const cached = firstCut.filter(s => s.dist >= 0)
        const missingDist = firstCut.filter(s => s.dist === -1 || s.dist === undefined).sort((s1, s2) => s1.cheapDist - s2.cheapDist);//.slice(0, 200);

        logger.debug('missing distances', missingDist.length)
        let newlyCached = []
        if (missingDist.length) {

            const {durations, distances} = await getConnectionDuration(connectType, coord, missingDist);

            newlyCached = missingDist.map((s, idx) => ({...s, dist: distances[idx], duration: durations[idx]}));
            newlyCached.forEach(s => {
                stopDistCache.setData(connectType, coord, s.stopId, s.dist, s.duration);
            })
            stopDistCache.write();
        }

        return cached.concat(newlyCached)
            .filter(s => s.duration <= maxDuration)
            .sort((s1, s2) => s1.duration - s2.duration)
            .map(s => {
                s = new Stop(s);
                s.setLinkedStops(allVerifiedStopsArray);
                return s;
            });
    }, [getConnectionDuration]);

    const getNearestStops = useCallback(async (allVerifiedStopsArray, maxDist, maxDuration, connectType, poi) => {
        logger.debug('getting stops near', maxDist, maxDuration, connectType, poi)
        // if (poi.type === 'Feature') {
        //     // Is an address or other poi
        //     const lat = poi.center[1];
        //     const lon = poi.center[0];
        //     return getNearestStopsToCoord(allStops, {lat, lon}, maxDist)
        // } else {
        //     // is a specific stop selected by user
        //     if (poi.stopType === 'school') {
        //         // if school stop, look for other school stops
        //         const lat = poi.center[1];
        //         const lon = poi.center[0];
        //         return getNearestStopsToCoord(allStops, {lat, lon}, maxDist, true);
        //     } else {
        //         // Is a bus stop of some kind
        //         return [{...poi, dist: 0}];
        //     }
        // }
        // if school stop, look for other school stops
        const lat = poi.center[1];
        const lon = poi.center[0];
        let stops = await getNearestStopsToCoord(allVerifiedStopsArray, {
            lat,
            lon
        }, maxDist, maxDuration, connectType, poi.stopType === 'school');
        if (features.access(FEATURE.lnks)) {
            stops.filter(stop => stop.hasLinkedStops()).forEach(stop => {
                logger.debug('near stop has links')
                stop.linkedStops.forEach(linkedStop => {
                    const ls = new Stop({
                        ...linkedStop.stop,
                        dist: stop.dist
                    })
                    if (stop.stopType === 'school') {
                        ls.startBell = stop.startBell;
                        ls.startBellWindow = stop.startBellWindow
                        ls.endBell = stop.endBell
                        ls.endBellWindow = stop.endBellWindow
                    }
                    stops.push(ls);
                })
            });
        }
        return stops;
    }, [getNearestStopsToCoord, features]);

    useEffect(() => {
            if (!schedules) {
                return;
            }

            // const getConnectedStops = (verifiedStops, stop) => {
            //     const stopsOnRoutes = {};
            //     stop.routes.forEach(route => {
            //         stopsOnRoutes[route.routeId] = verifiedStops.filter(otherStop => {
            //             if (stop.stopId === otherStop.stopId) {
            //                 return false
            //             }
            //             const sameRouteAsStop = otherStop.routes[otherStop.routes.findIndex(otherRoute => otherRoute.routeId === route.routeId)]
            //             return sameRouteAsStop && sameRouteAsStop.maxSequence > route.minSequence
            //         });
            //     })
            //     return stopsOnRoutes
            // }
            // const getFollowingStopsOnRoute = (verifiedStops, stop, route) => {
            //     const connectedStops = {}
            //     stop.routes.forEach(route => {
            //         connectedRoutes[route.routeId] = verifiedStops.filter(otherStop => {
            //             if (stop.stopId === otherStop.stopId) {
            //                 return false
            //             }
            //             return otherStop.routes.findIndex(otherRoute => otherRoute.routeId === route.routeId) > -1;
            //         });
            //     })
            //     return connectedRoutes
            // }

            const getRoutes = (stopsNear, possibleDirections = [], possibleRouteTypes = [], timeFilter, schedules) => {
                logger.debug(stopsNear, possibleDirections, possibleRouteTypes, timeFilter);
                return flatten(stopsNear.map(s => {

                    return s.routes ? s.routes
                        .filter(r => !possibleDirections.length || possibleDirections.indexOf(r.direction) > -1)
                        .filter(r => !possibleRouteTypes.length || possibleRouteTypes.indexOf(r.routeType || 'Regular') > -1)
                        .map(r => cloneDeep(r))
                        .filter(route => {
                            if (!timeFilter) {
                                delete route.services
                                return s.routes.findIndex(r => r.routeId === route.routeId) > -1
                            }
                            route.services = s.getPassingTimes(r => r.routeId === route.routeId, {
                                date: timeFilter.startTime,
                                school: timeFilter.anyDate,
                                time: timeFilter.getStartTime(),
                                window: timeFilter.type === TimeFilterType.ARRIVING ? 48 * 3600 : timeFilter.windowInSecs
                            }, schedules)
                            return route.services.length
                        }) : []
                }));
            };

            const getIntersectingRoutes = (stopsNearFrom, stopsNearTo, possibleDirections, possibleRouteTypes, timeFilter) => {
                const log = true;
                let routesFrom = uniqBy(getRoutes(stopsNearFrom, possibleDirections, possibleRouteTypes, [TimeFilterType.LEAVING, TimeFilterType.NOW, TimeFilterType.ALL].includes(timeFilter.type) ? timeFilter : null, schedules), 'routeId');
                log && logger.debug("routesFrom: ", routesFrom);
                let routesTo = uniqBy(getRoutes(stopsNearTo, possibleDirections, possibleRouteTypes, [TimeFilterType.ARRIVING, TimeFilterType.ALL].includes(timeFilter.type) ? timeFilter : null, schedules), 'routeId');
                log && logger.debug("routesTo: ", routesTo);
                let directRouteIds = uniq(intersection(routesFrom.map(r => r.routeId), routesTo.map(r => r.routeId)));
                log && logger.debug("directRouteIds: ", directRouteIds);

                return {routesFrom, routesTo, directRouteIds};
            }

            const createWp = (route, stopsNearPoint, startLat, startLon, fromIdx = 0, end = false) => {
                const log = route.routeNumber === '913'
                log && logger.debug('all stops near', stopsNearPoint, route.stops);
                const stopsNear = stopsNearPoint.filter(s => route.stops.findIndex(routeStop => routeStop.stopId === s.stopId) > -1).map(nearStop => {
                    const sequence = route.getPublicStopIndex(nearStop, 0, end) + 1// .stops.findIndex(routeStop => routeStop.stopId === nearStop.stopId) + 1
                    return {...nearStop, sequence}
                }).sort((s1, s2) => s1.dist - s2.dist)
                log && logger.debug('filtered stops near', stopsNear);
                return {
                    ...stopsNear[fromIdx],
                    nearIdx: fromIdx,
                    lat: startLat,
                    lon: startLon
                };
            }


            // const createWpsOnRoute = (route, tripId, stopsNearPoint, coord, timeFilter, fromIdx = 0, end = false) => {
            //     const log = route.routeNumber === 'AM09'
            //     // return stopsNearPoint.filter(s => route.stops.findIndex(routeStop => routeStop.stopId === s.stopId) > -1).map(nearStop => {
            //     return stopsNearPoint.filter(s => route.stopsAtStopInWindow(s, tripId, timeFilter, end)).map(nearStop => {
            //         const sequence = route.getPublicStopIndex(nearStop, 0, end) + 1// .stops.findIndex(routeStop => routeStop.stopId === nearStop.stopId) + 1
            //         const stop = {...nearStop, sequence, nearIdx: fromIdx, ...coord.center}
            //         log && logger.debug(stop)
            //         return stop;
            //     })
            // }

            // TODO: issue with school to school when School ALL selected - AM works.
            // TODO: issue with transfers
            const createStopTimesOnRoute = (route, tripId, stopsNearPoint, coord, timeFilter, fromIdx = 0, end = false) => {
                const log = route.routeNumber === 'AM09'
                const trip = find(route.services, ['tripId', tripId], 0)
                if (!trip) {
                    console.log('No trip')
                    return []
                }
                // return stopsNearPoint.filter(s => route.stops.findIndex(routeStop => routeStop.stopId === s.stopId) > -1).map(nearStop => {
                return flatten(stopsNearPoint.map(s => route.stopToStopTimesInWindow(s, tripId, timeFilter, end))).map(nearStopTime => {
                    const sequence = trip.stopTimes.findIndex(st => st.stopTimeId === nearStopTime.stopTimeId) + 1// .stops.findIndex(routeStop => routeStop.stopId === nearStop.stopId) + 1
                    const stop = {...nearStopTime, sequence, nearIdx: fromIdx, ...coord.center}
                    log && logger.debug(stop)
                    return stop;
                });
            }

            /**
             * Finds the best start and end wp based on the start lat/lon and end lat/lon. This handles the edge case where the
             * stop to board/alight the bus is not the closest stop on the route to the lat/lon.
             * @param route
             * @param stopsNearFrom
             * @param stopsNearTo
             * @param startLat
             * @param startLon
             * @param endLat
             * @param endLon
             * @returns {{startWp: (*&{nearIdx: number, lon, lat}), endWp: (*&{nearIdx: number, lon, lat})}}
             */
                // eslint-disable-next-line
            const findBestStartEndWp = (route, stopsNearFrom, stopsNearTo, startLat, startLon, endLat, endLon) => {
                    const log = route.routeNumber === '913'

                    let startWp, endWp, nearIdx = 0;
                    do {
                        startWp = createWp(route, stopsNearFrom, startLat, startLon, nearIdx)
                        log && logger.debug('startWp', startWp);
                        endWp = createWp(route, stopsNearTo, endLat, endLon, 0, true);
                        log && logger.debug('endWp', endWp);
                        nearIdx = nearIdx > 0 ? nearIdx + 1 : endWp.nearIdx + 1;
                    } while (startWp.sequence >= endWp.sequence && nearIdx < stopsNearFrom.length);

                    if (startWp.sequence >= endWp.sequence) {
                        startWp = null;
                        endWp = null;
                        nearIdx = 0;
                        do {
                            startWp = createWp(route, stopsNearFrom, startLat, startLon)
                            endWp = createWp(route, stopsNearTo, endLat, endLon, nearIdx);
                            nearIdx = nearIdx > 0 ? nearIdx + 1 : startWp.nearIdx + 1;
                        } while (startWp.sequence >= endWp.sequence && nearIdx < stopsNearTo.length);
                    }

                    return {startWp, endWp}
                }

            const groupBySequence = (wps, prefs) => {
                const startLinks = [];
                let prevSequence = 0;
                let shortestDistInLink = -1;
                wps.forEach((wp, idx) => {
                    if (idx === 0 || wp.sequence > (prevSequence + 1)) {
                        // if (idx > 0 && wp.dist > shortestDistInLink * 2) {
                        //     shortestDistInLink = wp.dist;
                        //     return;
                        // }
                        startLinks.push([wp]);
                        shortestDistInLink = wp.dist;
                        prevSequence = wp.sequence;
                        return;
                    }
                    if (wp.sequence === (prevSequence + 1)) {
                        shortestDistInLink = wp.dist < shortestDistInLink ? wp.dist : shortestDistInLink;
                        last(startLinks).push(wp);
                    }
                    prevSequence = wp.sequence;
                })
                return startLinks.sort((l1, l2) => l1[0].sequence - l2[0].sequence);
            }

            const findBestStartEndWpOnRoute = (route, tripId, stopsNearFrom, stopsNearTo, startPoi, endPoi, {
                arrivingTimeFilter,
                leavingTimeFilter
            }) => {
                const log = route.routeNumber === '945'
                let startWp, endWp;
                log && logger.debug(route.routeNumber, 'stopsNearFrom', stopsNearFrom)
                log && logger.debug(route.routeNumber, 'stopsNearTo', stopsNearTo)
                log && logger.debug(route.routeNumber, 'startPoi', startPoi)
                log && logger.debug(route.routeNumber, 'endPoi', endPoi)
                let startSTsOnRoute = createStopTimesOnRoute(route, tripId, stopsNearFrom, startPoi, leavingTimeFilter).sort((wp1, wp2) => wp1.sequence - wp2.sequence);
                startSTsOnRoute = uniqWith(startSTsOnRoute, BaseStop.isEqual)

                let endSTsOnRoute = createStopTimesOnRoute(route, tripId, stopsNearTo, endPoi, arrivingTimeFilter, startSTsOnRoute[0] ? startSTsOnRoute[0].sequence - 1 : 0, true).sort((wp1, wp2) => wp1.sequence - wp2.sequence);
                endSTsOnRoute = uniqWith(endSTsOnRoute, BaseStop.isEqual)

                log && logger.debug(route.routeNumber, 'startWpsOnRoute', startSTsOnRoute)
                log && logger.debug(route.routeNumber, 'endWpsOnRoute', endSTsOnRoute)
                const exactStartStops = startSTsOnRoute.filter(wp => wp.stopId === startPoi.stopId || wp.dist === 0)
                startWp = exactStartStops.length === 1 ? exactStartStops[0] : null
                const exactEndStops = endSTsOnRoute.filter(wp => wp.stopId === endPoi.stopId || wp.dist === 0)
                endWp = exactEndStops.length === 1 ? exactEndStops[0] : null
                // for (let i = startWpsOnRoute.length - 1; i >= 0; i--) {
                // for (let i = 0; i < startWpsOnRoute.length; i++) {
                //     startWp = startWpsOnRoute[i].stopId === startPoi.stopId || startWpsOnRoute[i].dist === 0 ? startWpsOnRoute[i] : null;
                // }
                // for (let j = 0; j < endWpsOnRoute.length; j++) {
                //     endWp = endWpsOnRoute[j].stopId === endPoi.stopId || endWpsOnRoute[j].dist === 0 ? endWpsOnRoute[j] : null;
                // }

                log && logger.debug(route.routeNumber, "start/end", startWp, endWp);

                if (startWp && endWp) {
                    return {startWp, endWp}
                }

                let startLink, endLink;

                // group wps on sequence links
                const startLinks = groupBySequence(startSTsOnRoute);
                log && logger.debug(route.routeNumber, 'start links', startLinks, startSTsOnRoute)
                const endLinks = groupBySequence(endSTsOnRoute);
                log && logger.debug(route.routeNumber, 'end links', endLinks, endSTsOnRoute)


                for (let j = 0; j < endLinks.length; j++) {
                    for (let i = startLinks.length - 1; i >= 0; i--) {
                        let maybeStartLink = differenceWith(startLinks[i], endLinks[j], (l1, l2) => l2.sequence <= l1.sequence);
                        let maybeEndLink = differenceWith(endLinks[j], startLinks[i], (l1, l2) => l1.sequence <= l2.sequence);
                        log && logger.debug('maybeStartLink', maybeStartLink)
                        log && logger.debug('maybeEndLink', maybeEndLink)
                        if (!maybeStartLink.length) {
                            log && logger.debug('No startLinks after filter.')
                            continue;
                        }

                        startLink = maybeStartLink;
                        endLink = maybeEndLink;
                        break;
                    }
                    if (startLink?.length && endLink?.length) {
                        break;
                    }
                }

                log && logger.debug(route.routeNumber, startWp, endWp, startLink, endLink);

                if (!startWp && startLink) {
                    startWp = startLink.sort((wp1, wp2) => wp1.dist - wp2.dist)[0] // TODO: Check this really is returning the closest WP in array
                }
                if (!endWp && endLink) {
                    endWp = endLink.sort((wp1, wp2) => wp1.dist - wp2.dist)[0]
                }

                return {startWp, endWp}
            }

            const getNextDepartureDate = (tripPlan, schedules) => {
                const routeLegs = tripPlan.legs.filter(l => l.type === 'route' && l.route?.services)
                // logger.debug('next departure data. Leg count: %d', routeLegs.length, routeLegs)
                const firstLegOfService = tripPlan.legs[tripPlan.legs.findIndex(l => l?.route?.services?.length)];
                for (let i = 0; i < routeLegs.length; i++) {
                    const routeLeg = routeLegs[i]
                    logger.debug(routeLeg)
                    routeLeg.nextDepartureDates = []
                    logger.debug(routeLeg.route.services)
                    for (let j = 0; j < routeLeg.route.services.length; j++) {
                        routeLeg.route.services[j].scheduleIds.forEach(sId => {
                            const scheduleForTrip = schedules[sId]
                            if (scheduleForTrip) {
                                const nextDepartureDates = scheduleForTrip.getNext7DepartureDates(timeFilter.startTime)
                                // logger.debug(routeLeg.route.routeNumber, ' -> ', nextDepartureDates)
                                const tripStartTime = getDepartureTimeAsSecondsSinceMidnight(routeLeg.route.services[j], routeLeg.route.services[j].getStopTime(firstLegOfService.route.getStartStop()));
                                if (nextDepartureDates[0].clone().startOf("d").add(tripStartTime, "s").isBefore(dayjs())) {
                                    nextDepartureDates.splice(0, 1);
                                }
                                routeLeg.nextDepartureDates = routeLeg.nextDepartureDates.concat(nextDepartureDates)
                            } else {
                                logger.debug('No schedule for sId: ', sId)
                            }
                        })
                    }
                    routeLeg.nextDepartureDates.sort((d1, d2) => d1.unix() - d2.unix())
                }

                let nextDepartureDates;
                if (routeLegs.length === 1) {
                    nextDepartureDates = routeLegs[0].nextDepartureDates
                } else if (routeLegs.length === 2) {
                    nextDepartureDates = intersectionWith(routeLegs[0]?.nextDepartureDates, routeLegs[1]?.nextDepartureDates, (d1, d2) => {
                        return d1.isSame(d2, 'date')
                    })
                } else if (routeLegs.length === 3) {
                    nextDepartureDates = intersectionWith(routeLegs[0]?.nextDepartureDates, routeLegs[1]?.nextDepartureDates, routeLegs[2]?.nextDepartureDates, (d1, d2) => {
                        return d1.isSame(d2, 'date')
                    })
                }
                logger.debug('next departure dates', nextDepartureDates);
                return nextDepartureDates
            }
            let requestTime = 0, elapsed = 0, startTime = 0, tripPlans = [];

            setTripPlans([])

            async function onLoad() {

                startTime = Date.now();
                try {
                    // const sortFn = (r1, r2) => r1.routeNumber.localeCompare(r2.routeNumber)
                    logger.debug('TIME FILTER: ', timeFilter);

                    logger.debug('Loading routes from ', filter.from, 'to', filter.to)
                    if (filter.from.place_name === filter.to.place_name) {
                        return;
                    }

                    const allVerifiedStopsArray = values(allStops).filter(s => publicStopFilter(s) && s.verified).map(s => new Stop(s));
                    // // logger.debug("All verified stop count: ", allVerifiedStopsArray.length)
                    // let routeTypes = []
                    // let directions = filter.directions || [];
                    // // logger.debug(filter.from.stopType, filter.to.stopType)
                    // // if (filter.from.stopType === 'school' && filter.to.stopType === 'school') {
                    // //     timeFilter.setSchool();
                    // // } else
                    // //     if (filter.from.stopType === 'school') {
                    // //     timeFilter.setSchoolPM();
                    // // } else if (filter.to.stopType === 'school') {
                    // //     timeFilter.setSchoolAM();
                    // // }
                    //
                    // const [startLon, startLat] = filter.from.center
                    // const [endLon, endLat] = filter.to.center
                    //
                    // let stopsNearFrom = await getNearestStops(allVerifiedStopsArray, prefs.getMaxDistanceConnectStart(reverse), prefs.getMaxDurationConnectStart(reverse), prefs.connectStart, filter.from);
                    // logger.debug("Stops near from: ", stopsNearFrom)
                    // let stopsNearTo = await getNearestStops(allVerifiedStopsArray, prefs.getMaxDistanceConnectEnd(reverse), prefs.getMaxDurationConnectEnd(reverse), prefs.connectEnd, filter.to);
                    // logger.debug("Stops near to: ", stopsNearTo)
                    //
                    // directions = uniq(directions);
                    // if (filter.regular) routeTypes.push('Regular')
                    // if (filter.school) routeTypes.push('School')
                    // routeTypes = uniq(routeTypes);
                    // if (prefs.avoidSchool) {
                    //     routeTypes = ["Regular"]
                    // }
                    //
                    // // logger.debug('stops near from:', stopsNearFrom.map(s => s.stopName))
                    // // logger.debug('stops near to:', stopsNearTo.map(s => s.stopName))
                    //
                    // let {
                    //     routesFrom,
                    //     routesTo,
                    //     directRouteIds
                    // } = getIntersectingRoutes(stopsNearFrom, stopsNearTo, directions, routeTypes, timeFilter);
                    // logger.debug('Intersecting routeIds: ', directRouteIds)
                    //
                    // let directConnection = await getCachedPath(prefs.connectStart, {
                    //     lat: startLat,
                    //     lon: startLon
                    // }, {lat: endLat, lon: endLon}, prefs.getConnectStartSpeed(reverse), config.maps.geoapify[key])
                    //
                    // if (directConnection?.duration < (prefs.getMaxDurationConnectStart(reverse) + prefs.getMaxDurationConnectEnd(reverse))) {
                    //     tripPlans.push({
                    //         legs: [{
                    //             startStop: filter.from,
                    //             endStop: filter.to,
                    //             type: prefs.connectStart,
                    //             ...directConnection,
                    //             route: new BusRoute({
                    //                 routeId: 'direct',
                    //                 waypoints: directConnection.waypoints
                    //             })
                    //         }],
                    //         duration: directConnection.duration,
                    //         secondsTilDeparture: 0,
                    //         type: 'direct'
                    //     })
                    // }
                    //
                    // timeFilter.trips = {}
                    // let stops = timeFilter.type === TimeFilterType.ARRIVING ? stopsNearTo : stopsNearFrom
                    //
                    // directRouteIds.forEach(rId => {
                    //     stops.forEach(stop => {
                    //         // const validTripIds = getValidTripsAtStopForRoute(rId, stop, timeFilter.anyDate, timeFilter.startTime, timeFilter.toWindowArray())
                    //         const validTripIds = stop.getPassingTimes(r => r.routeId === rId, {
                    //             date: timeFilter.startTime,
                    //             school: timeFilter.anyDate,
                    //             time: timeFilter.getStartTime(),
                    //             window: timeFilter.type === TimeFilterType.ARRIVING ? 48 * 3600 : timeFilter.windowInSecs
                    //         }, schedules).map(pt => pt.tripId)
                    //         validTripIds.forEach(tripId => {
                    //             tripPlans = tripPlans.concat({
                    //                 legs: [{
                    //                     // startStop: timeFilter.type === TimeFilterType.LEAVING ? stop : null,
                    //                     // endStop: timeFilter.type === TimeFilterType.ARRIVING ? stop : null,
                    //                     type: 'route',
                    //                     routeId: rId,
                    //                     tripId
                    //                 }]
                    //             })
                    //         })
                    //     })
                    // })
                    //
                    //
                    // logger.debug('tripPlans: ', tripPlans)
                    // const timeFilters = [];
                    //
                    // if (features.access(FEATURE.trnsfrs) && transfers) {
                    //
                    //     logger.debug('Looking fro transfers...', transfers)
                    //     logger.debug(timeFilter)
                    //
                    //     // Find routesFrom from stopsUpstream from nearStops to look for transfers
                    //     routesFrom.filter(r => directRouteIds.indexOf(r.routeId) === -1).forEach(routeFrom => {
                    //
                    //         logger.debug('Looking for transfer from Route ', routeFrom.routeNumber, routeFrom.routeId)
                    //         const stopsOnFromRoute = values(allStops).filter(s => {
                    //             if (!s.routes) {
                    //                 return false;
                    //             }
                    //             const stopOnRoute = s.routes[s.routes.findIndex(r => r.routeId === routeFrom.routeId)];
                    //             if (!stopOnRoute) {
                    //                 return false;
                    //             }
                    //             return stopOnRoute.maxSequence >= routeFrom.minSequence;
                    //         })
                    //         stopsOnFromRoute.forEach(stopOnFromRoute => {
                    //
                    //             const validTransfers = transfers.filter(t => !t.invalid && (!prefs.avoidTransfers || t.inSeat)).map(transfer => {
                    //                 const log = true //transfer.fromRouteNumber === '914' && transfer.toRouteNumber === '912A'
                    //
                    //                 // TODO: Ensure routesTo has a stop on the stopsNear
                    //                 if (transfer.isValidTransfer(routeFrom, stopOnFromRoute, routesTo, timeFilter, log)) {
                    //                     return {...transfer, legCount: 1}
                    //                 }
                    //
                    //                 if (transfer.isValidTransfer(routeFrom, stopOnFromRoute, null, timeFilter, log)) {
                    //                     const idx = transfer._trx.findIndex(leg2 => {
                    //                         const routeTo = routesTo[routesTo.findIndex(rTo => rTo.routeId === leg2.toRouteId)]
                    //                         if (!routeTo) {
                    //                             return false
                    //                         }
                    //                         return leg2.isValidTransfer(null, null, routesTo, timeFilter, log);
                    //                     });
                    //                     if (idx > -1) {
                    //                         const leg2 = transfer._trx[idx]
                    //                         logger.debug('Found multi leg transfer from %s -> %s ->  %s @ %s', transfer.fromRouteNumber, leg2.fromRouteNumber, leg2.toRouteNumber, leg2.fromStopId)
                    //                         return {...transfer, next: leg2, legCount: 2}
                    //                     }
                    //                 }
                    //
                    //                 // return transfer._trx.some(leg2 => {
                    //                 //     return leg2._trx.some(leg3 => routesTo.findIndex(rTo => rTo.routeNumber === leg3.toRouteNumber) > -1)
                    //                 // })
                    //                 log && logger.debug('no multi leg transfer.')
                    //                 return null;
                    //
                    //             }).filter(t => !!t)
                    //
                    //             logger.debug("Found valid transfers: ", validTransfers.length)
                    //             if (validTransfers?.length) {
                    //
                    //                 validTransfers.forEach(transfer => {
                    //                     logger.debug('appending tripplan...', transfer)
                    //
                    //                     logger.debug('1st leg: %s, rId: %s', transfer.fromRouteNumber, routeFrom.routeId)
                    //                     let legs = [{
                    //                         type: 'route',
                    //                         routeId: routeFrom.routeId,
                    //                         // startStop: filter.from.stopId ? filter.from : null,
                    //                         tripId: transfer.fromTripId,
                    //                         endStop: allStops[transfer.fromStopId],
                    //                         arrivingTimeFilter: new TimeFilter({
                    //                             anyDate: false,
                    //                             type: TimeFilterType.ARRIVING,
                    //                             startTime: getSecondsSinceMidnightAsDayjs(transfer.time + transfer.window),
                    //                             window: transfer.window
                    //                         })
                    //                     }, {
                    //                         type: 'transfer',
                    //                         route: new BusRoute({
                    //                             routeId: routeFrom.routeId + '_' + transfer.toRouteId,
                    //                             waypoints: transfer.waypoints || []
                    //                         }),
                    //                         inSeat: transfer.inSeat,
                    //                         dist: transfer.distance,
                    //                         duration: Math.round(transfer.duration || 0)
                    //                     }]
                    //
                    //                     if (transfer.legCount === 1) {
                    //                         logger.debug(' to : ', transfer.toRouteNumber)
                    //                         legs.push({
                    //                             type: 'route',
                    //                             routeId: transfer.toRouteId || undefined,
                    //                             startStop: allStops[transfer.toStopId],
                    //                             tripId: transfer.toTripId,
                    //                             minDepart: transfer.time + transfer.window,
                    //                             leavingTimeFilter: new TimeFilter({
                    //                                 anyDate: false,
                    //                                 type: TimeFilterType.LEAVING,
                    //                                 startTime: getSecondsSinceMidnightAsDayjs(transfer.time - transfer.window),
                    //                                 window: transfer.window
                    //                             })
                    //                             // endStop: filter.to.stopId ? filter.to : null,
                    //                         })
                    //                     } else if (transfer.legCount === 2) {
                    //                         // const idx = transfer._trx.findIndex(leg2 => routesTo.findIndex(rTo => rTo.routeNumber === leg2.toRouteNumber) > -1)
                    //                         // if (idx > -1) {
                    //                         logger.debug('2nd leg')
                    //                         logger.debug(' to : ', transfer.toRouteNumber)
                    //                         const leg2 = transfer.next
                    //                         logger.debug(' to : ', leg2.toRouteNumber)
                    //                         legs.push({
                    //                             type: 'route',
                    //                             routeId: transfer.toRouteId,
                    //                             // startStop: filter.from.stopId ? filter.from : null,
                    //                             startStop: allStops[transfer.toStopId],
                    //                             endStop: allStops[leg2.toStopId],
                    //                             tripId: transfer.toTripId,
                    //                             arrivingTimeFilter: new TimeFilter({
                    //                                 anyDate: false,
                    //                                 type: TimeFilterType.ARRIVING,
                    //                                 startTime: getSecondsSinceMidnightAsDayjs(leg2.time + leg2.window),
                    //                                 window: leg2.window
                    //                             }),
                    //                             leavingTimeFilter: new TimeFilter({
                    //                                 anyDate: timeFilter.anyDate,
                    //                                 type: TimeFilterType.LEAVING,
                    //                                 startTime: getSecondsSinceMidnightAsDayjs(transfer.time - transfer.window),
                    //                                 window: transfer.window
                    //                             })
                    //                         });
                    //                         legs.push({
                    //                             type: 'transfer',
                    //                             route: new BusRoute({
                    //                                 routeId: transfer.toRouteId + '_' + leg2.toRouteId,
                    //                                 waypoints: leg2.waypoints || []
                    //                             }),
                    //                             inSeat: leg2.inSeat,
                    //                             dist: leg2.distance,
                    //                             duration: Math.round(leg2.duration || 0)
                    //                         })
                    //                         legs.push({
                    //                             type: 'route',
                    //                             routeId: leg2.toRouteId || undefined,
                    //                             startStop: allStops[leg2.toStopId],
                    //                             tripId: leg2.toTripId,
                    //                             leavingTimeFilter: new TimeFilter({
                    //                                 anyDate: timeFilter.anyDate,
                    //                                 type: TimeFilterType.LEAVING,
                    //                                 startTime: getSecondsSinceMidnightAsDayjs(leg2.time - leg2.window),
                    //                                 window: leg2.window
                    //                             })
                    //                             // endStop: filter.to.stopId ? filter.to : null,
                    //                         })
                    //                     }
                    //
                    //                     if (legs.filter(l => l.type === 'route').every(l => l.routeId && l.tripId)) {
                    //                         tripPlans.push({
                    //                             type: 'transfer',
                    //                             legs: legs
                    //                         });
                    //                     }
                    //
                    //                 })
                    //             } else {
                    //                 logger.debug("No transfers found")
                    //             }
                    //         })
                    //     })
                    // }
                    //
                    // logger.debug('TIME FILTERs: ', timeFilters);
                    // timeFilters.push(timeFilter);
                    // tripPlans = tripPlans.filter(t => t.legs.length && t.legs.every(leg => {
                    //     if (leg.type === "route") {
                    //         return leg.routeId?.length && leg.routeId !== "_" && leg.tripId?.length;
                    //     }
                    //     return true;
                    // }))
                    //
                    // logger.debug('Before uniqueness: ', [...tripPlans])
                    // tripPlans = tripPlans.map(tp => new JourneyPlan(tp, allStops));
                    // tripPlans = uniqWith(tripPlans.sort((tp1, tp2) => tp1.legs.length - tp2.legs.length), JourneyPlan.isEqual)
                    // logger.debug('After uniqueness: ', [...tripPlans])
                    //
                    // logger.debug('tripplans1: ', util.inspect(tripPlans, {depth: 3}));
                    // let allPossibleRouteIds = flatten(tripPlans.map(tp => tp.legs.filter(l => l.type === 'route').map(l => ({
                    //     routeId: l.routeId,
                    //     tripId: l.tripId
                    // })))).filter(rId => !!rId)
                    // let routeFilter = {}
                    // allPossibleRouteIds.forEach(r => {
                    //     routeFilter[r.routeId] = routeFilter[r.routeId] || {tripIds: []}
                    //     routeFilter[r.routeId].tripIds.push(r.tripId)
                    // })
                    // logger.debug('route filter', routeFilter)
                    // let routes = []
                    // requestTime = Date.now();
                    // if (Object.keys(routeFilter).length) {
                    //     logger.warn('%d routeIds to fetch', Object.keys(routeFilter).length)
                    //     routes = await getPublicRoutes(key, routeFilter, {
                    //         shrinkRate: 0.00001
                    //     })
                    //     logger.warn('%d routes fetched', routes.length)
                    // }
                    // requestTime = Date.now() - requestTime
                    // routes.forEach(r => {
                    //     r.setBaseStops(allStops)
                    //     r.setSchedules(schedules)
                    // })
                    // logger.debug('Route count: ', routes.length)
                    // const routesById = keyBy(routes, 'routeId')
                    // logger.debug(routes)
                    //
                    // let tripCount = tripPlans.length
                    // logger.warn('Removed ', tripCount - tripPlans.length, 'trips due to no legs')
                    // tripCount = tripPlans.length
                    // logger.warn('tripplans2: ', util.inspect(tripPlans, {depth: 3}));
                    // tripPlans.forEach(tripPlan => {
                    //     logger.debug('leg count1', tripPlan.legs.length)
                    //     tripPlan.legs.filter(leg => leg.type === 'route' && leg.routeId).forEach(leg => {
                    //         const route = cloneDeep(routesById[leg.routeId]);
                    //         if (route) {
                    //             logger.debug(' Setting route', route.routeNumber, leg.routeId)
                    //             route.services = route.services.filter(trip => trip.tripId === leg.tripId);
                    //             leg.route = new BusRoute(route)
                    //             leg.trip = route.services[0]
                    //             // leg.route.setBaseStops(allStops)
                    //             logger.debug(leg.route)
                    //         } else {
                    //             logger.warn('No route for tripplan.leg with routeId: ', leg.routeId)
                    //         }
                    //     })
                    // })
                    //
                    // // Remove trip plans without a route or roue without a srevice
                    // const tripPlanCOunt = tripPlans.length
                    // tripPlans = tripPlans.filter(tripPlan => tripPlan.legs.filter(leg => leg.type === 'route').every(leg => leg.route?.services?.length))
                    // logger.warn('%d TripPlans removed because of no route', tripPlanCOunt - tripPlans.length)
                    //
                    // // logger.debug('leg count2', tripPlan.legs.length)
                    // // logger.debug('leg routes', tripPlan.legs.map(l => l.route?.routeNumber));
                    // tripPlans.forEach(tripPlan => {
                    //
                    //     logger.debug('leg count3', tripPlan.legs.length)
                    //     tripPlan.legs.filter(leg => leg.type === 'route').forEach((leg, idx) => {
                    //         if (tripPlan.invalid) {
                    //             return
                    //         }
                    //         const {
                    //             startWp,
                    //             endWp
                    //         } = findBestStartEndWpOnRoute(leg.route, leg.tripId, leg.startStop ? [leg.startStop] : stopsNearFrom,
                    //             leg.endStop ? [leg.endStop] : stopsNearTo,
                    //             leg.startStop || filter.from, leg.endStop || filter.to, {
                    //                 arrivingTimeFilter: leg.arrivingTimeFilter ||
                    //                     (
                    //                         timeFilter?.checkArrivalTime(leg.route.direction) ? timeFilter : undefined
                    //                     ),
                    //                 leavingTimeFilter: leg.leavingTimeFilter ||
                    //                     (
                    //                         timeFilter?.checkDepartureTime(leg.route.direction) ? timeFilter : undefined
                    //                     )
                    //
                    //             });
                    //         logger.debug('startwp', startWp, 'endwp', endWp)
                    //         if (!startWp || !endWp || startWp.sequence >= endWp.sequence) {
                    //             logger.warn('Could not find startWp/endWp')
                    //             tripPlan.invalid = true
                    //             return;
                    //         }
                    //         leg.route.calculateStartEnd({firstStop: startWp, lastStop: endWp, shortestRoute: true});
                    //         leg.duration = leg.trip.getDuration(leg.route);
                    //         logger.debug(leg.route.routeNumber, 'route duration', leg.duration);
                    //     });
                    //     logger.debug('leg durations', tripPlan.legs.map(l => l.duration));
                    //     logger.debug('leg count4', tripPlan.legs.length)
                    //     tripPlan.legs = tripPlan.legs.filter(l => l.type !== 'route' || l.duration);
                    //     tripPlan.duration = tripPlan.legs.filter(l => l.type !== 'direct').map(leg => leg.duration).reduce((prev, next) => prev + next, 0);
                    //     logger.debug('leg count5: ', tripPlan.legs.filter(l => l.route).map(l => l.route?.routeNumber).join('->'))
                    // })
                    //
                    // tripPlans = tripPlans.filter(tripPlan => {
                    //     const filter = !tripPlan.invalid
                    //     !filter && logger.warn('Removing trip plan of as we could not find a start/end for all sections.')
                    //     return filter
                    // })
                    //
                    // // TODO: check legs in transfer don't start and end at the same stop
                    // tripPlans = tripPlans.filter(tripPlan => {
                    //     const filter = tripPlan.type !== 'transfer' || tripPlan.legs.filter(l => l.type === 'route').every(l => l.route.getStartStop().stopId !== l.route.getEndStop().stopId)
                    //     !filter && logger.warn('Removing trip plan of type', tripPlan.type, ' ', tripPlan.legs?.filter(l => l.route).map(l => l.route.routeNumber), ' due to start and end stop being the same.')
                    //     return filter
                    // })
                    //
                    // tripPlans = tripPlans.filter(tripPlan => {
                    //     const filter = tripPlan.type !== 'transfer' || tripPlan.legs.length >= 3
                    //     !filter && logger.warn('Removing trip plan of type', tripPlan.type, ' ', tripPlan.legs?.filter(l => l.route).map(l => l.route.routeNumber), ' due to some routes missing.')
                    //     return filter
                    // })
                    //
                    // tripPlans = tripPlans.filter(tripPlan => {
                    //     const filter = tripPlan.duration > 0
                    //     !filter && logger.warn('Removing ', tripPlan.legs[0] && tripPlan.legs[0].route.routeNumber, 'due to no duration')
                    //     return filter
                    // })
                    //
                    // logger.warn('Removed ', tripCount - tripPlans.length, 'of', tripCount, 'trips due to no duration')
                    // tripCount = tripPlans.length
                    // if (!tripPlans.length) {
                    //
                    //     elapsed = Date.now() - startTime;
                    //     await createUsage({
                    //         userId: key,
                    //         feature: 'TripPlanner',
                    //         timestamp: dayjs().format(),
                    //         elapsed,
                    //         from: {search: filter.from.place_name, stop: !!filter.from.stopId},
                    //         to: {search: filter.to.place_name, stop: !!filter.to.stopId},
                    //         tripCount: 0,
                    //         prefs,
                    //         timeFilter: {...timeFilter, stopIds: undefined}
                    //     });
                    //     return
                    // }
                    // logger.debug('tripplans3: ', util.inspect(tripPlans, {depth: 3}));
                    //
                    //
                    // // let connectStartDurations = await getConnectionDuration(prefs.connectStart, {
                    // //     lat: startLat,
                    // //     lon: startLon
                    // // }, tripPlans.map(tripPlan => tripPlan.legs[0].route.getStartStop()).concat({
                    // //     lat: endLat,
                    // //     lon: endLon
                    // // }), prefs.getConnectStartSpeed(reverse));
                    //
                    //
                    // await Promise.all(tripPlans.filter(tripPlan => tripPlan.type !== 'direct').map(async (tripPlan, idx) => {
                    //     const firstLegOfService = tripPlan.legs[tripPlan.legs.findIndex(l => l?.route?.services?.length)];
                    //     logger.debug('Connecting start of %s @ %s with %d legs', firstLegOfService?.route?.routeNumber, firstLegOfService?.route?.getStartStop().stopName, tripPlan.legs.length);
                    //
                    //     let connectStartDirections = await getCachedPath(prefs.connectStart, {
                    //         lat: startLat,
                    //         lon: startLon
                    //     }, firstLegOfService.route.getStartStop(), prefs.getConnectStartSpeed(reverse))
                    //
                    //     const connectType = firstLegOfService.route.getStartStop().isLinkedStop(filter.from) ? 'direct' : !reverse ? prefs.connectStart : prefs.connectEnd;
                    //     tripPlan.legs.unshift(new Leg({
                    //         type: connectType,
                    //         ...connectStartDirections,
                    //         route: new BusRoute({
                    //             routeId: '_' + firstLegOfService.route.routeId,
                    //             waypoints: connectStartDirections?.waypoints || []
                    //         })
                    //     }))
                    //     let nextDepartureDates = getNextDepartureDate(tripPlan, schedules);
                    //     let i = 0
                    //     let nextDepartureDate = nextDepartureDates[i++]
                    //     let nextTrip = getNextTrip(timeFilter, firstLegOfService.route, nextDepartureDate)
                    //     if (!nextTrip) {
                    //         tripPlan.invalid = true
                    //         return
                    //     }
                    //     let tripStartTimeAsDayjs = getDepartureTimeAsDayjs(nextTrip, nextTrip.getStopTime(firstLegOfService.route.getStartStop()), null, nextDepartureDate);
                    //     while (!timeFilter.anyDate && i <= nextDepartureDates.length && timeFilter.type !== TimeFilterType.ARRIVING && tripPlan.diffDays === 0 && tripStartTimeAsDayjs.isBefore(timeFilter.startTime)) {
                    //         nextDepartureDate = nextDepartureDates[i++]
                    //         nextTrip = getNextTrip(timeFilter, firstLegOfService.route, nextDepartureDate)
                    //         if (!nextTrip) {
                    //             tripPlan.invalid = true
                    //             return
                    //         }
                    //         tripStartTimeAsDayjs = getDepartureTimeAsDayjs(nextTrip, nextTrip.getStopTime(firstLegOfService.route.getStartStop()), null, nextDepartureDate);
                    //     }
                    //
                    //     let diffDays = dayjs(nextDepartureDate).startOf('d').diff(dayjs(timeFilter.startTime).startOf('d'), 'd');
                    //     if (!timeFilter.anyDate && diffDays > nextDepartureDates.length) {
                    //         tripPlan.invalid = true
                    //         return
                    //     }
                    //     // let nextTrip = getNextTrip(timeFilter, firstLegOfService.route, nextDepartureDate)
                    //
                    //     let tripStartTimeAsSecsSinceMidnight = getDepartureTimeAsSecondsSinceMidnight(nextTrip, nextTrip.getStopTime(firstLegOfService.route.getStartStop()));
                    //
                    //     tripPlan.diffDays = diffDays;
                    //     tripPlan.timeFilter = timeFilter;
                    //     tripPlan.departureTime = tripStartTimeAsSecsSinceMidnight - (applyAcs() ? (!reverse ? (tripPlan.legs[0].type === 'direct' ? 0 : tripPlan.legs[0].duration) : (last(tripPlan.legs).type === 'direct' ? -last(tripPlan.legs).duration : 0)) : 0);
                    //     tripPlan.nextDepartureDate = nextDepartureDate
                    //     tripPlan.nextDepartureDates = nextDepartureDates
                    //     tripPlan.nextDepartureTime = dayjs.unix(nextDepartureDate.unix() + tripStartTimeAsSecsSinceMidnight)
                    //     // tripPlan.nextArrivalTime = dayjs.unix(nextDepartureDate.unix() + tripStartTimeAsSecsSinceMidnight + tripPlan.duration)
                    //     tripPlan.secondsTilDeparture = getSecondsTilNextDeparture(tripPlan.departureTime, !timeFilter.anyDate ? timeFilter.startTime : tripPlan.nextDepartureDate);
                    //     // tripPlan.secondsTilArrival = tripPlan.secondsTilDeparture + tripPlan.duration
                    //     tripPlan.estimated = !nextTrip.getStopTime(firstLegOfService.route.getStartStop())?.timingPoint
                    // }))
                    //
                    // tripPlans = tripPlans.filter(tripPlan => {
                    //     const filter = !tripPlan.invalid
                    //     !filter && logger.warn('Removing trip plan as we could not find a next trip.')
                    //     return filter
                    // })
                    //
                    // tripPlans = tripPlans.filter(tripPlan => {
                    //     // If the start is a linked stop, don't check distance.
                    //     const firstLeg = tripPlan.legs[0]
                    //     if (firstLeg.type === 'direct') return true;
                    //     const duration = Math.round(firstLeg.duration / 60) * 60;
                    //     if (duration <= prefs.getMaxDurationConnectStart(reverse)) return true
                    //     logger.warn('Removing trip with route', tripPlan.legs.filter(l => l.route).map(l => l.r?.routeNumber).join('->'), 'trips due start connection longer than allowed. Connect start', firstLeg?.duration);
                    //     return false
                    // })
                    // tripCount = tripPlans.length
                    //
                    // logger.debug('Removed ', tripCount - tripPlans.length, 'of', tripCount, 'trips due start connection longer than allowed')
                    //
                    // tripPlans = tripPlans.filter(tripPlan => {
                    //     // If the start is a linked stop, don't check distance.
                    //     if (tripPlan.type === 'direct' || tripPlan.nextDepartureDate) return true
                    //     // const firstLegOfService = tripPlan.legs[tripPlan.legs.findIndex(l => l?.route?.trips && Object.keys(l.route.trips).length)];
                    //     logger.debug('Removing trip with route', tripPlan.legs.filter(l => l.route).map(l => l.r?.routeNumber).join('->'), 'trips due no departure date.');
                    //     return false
                    // })
                    // tripCount = tripPlans.length
                    //
                    // logger.warn('Removed ', tripCount - tripPlans.length, 'of', tripCount, 'trips due to no departure date')
                    //
                    // // let connectEndDurations = await getConnectionDuration(prefs.connectEnd, {
                    // //     lat: endLat,
                    // //     lon: endLon
                    // // }, tripPlans.map(trip => last(trip.legs).route.getEndStop()), prefs.getConnectEndSpeed(reverse));
                    //
                    // await Promise.all(tripPlans.filter(tripPlan => tripPlan.type !== 'direct').map(async (tripPlan, idx) => {
                    //     const lastLegOfService = tripPlan.legs[findLastIndex(tripPlan.legs, l => l.route?.services?.length)];
                    //     logger.debug('Connecting end of %s @ %s with %d legs', lastLegOfService?.route?.routeNumber, lastLegOfService.route.getEndStop().stopName, tripPlan.legs.length);
                    //
                    //     let connectEndDirections = await getCachedPath(prefs.connectEnd, {
                    //         lat: endLat,
                    //         lon: endLon
                    //     }, lastLegOfService.route.getEndStop(), prefs.getConnectEndSpeed(reverse))
                    //
                    //     const connectType = lastLegOfService.route.getEndStop().isLinkedStop(filter.to) ? 'direct' : !reverse ? prefs.connectEnd : prefs.connectStart;
                    //     tripPlan.legs.push(new Leg({
                    //         type: connectType,
                    //         ...connectEndDirections,
                    //         route: new BusRoute({
                    //             routeId: lastLegOfService.route.routeId + '_',
                    //             waypoints: connectEndDirections?.waypoints || []
                    //         })
                    //     }))
                    //
                    //     let nextTrip = getNextTrip(timeFilter, lastLegOfService.route, tripPlan.nextDepartureDate)
                    //
                    //     if (!nextTrip) {
                    //         tripPlan.invalid = true
                    //         return
                    //     }
                    //     let tripEndTimeAsSecsSinceMidnight = getDepartureTimeAsSecondsSinceMidnight(nextTrip, nextTrip.getStopTime(lastLegOfService.route.getEndStop()))
                    //     if (!timeFilter.anyDate && timeFilter.type === TimeFilterType.ARRIVING && tripPlan.diffDays === 0 && tripEndTimeAsSecsSinceMidnight > timeFilter.getEndTime()) {
                    //         tripPlan.invalid = true
                    //     }
                    //     // if the next departuer is before now, then show tomorrows.
                    //     // if (timeFilter.type === TimeFilterType.ARRIVING && tripEndTimeAsDayjs.isAfter(timeFilter.startTime)) {
                    //     //     tripPlan.nextDepartureDate = tripPlan.nextDepartureDates[1]
                    //     //     const diffDays = dayjs(tripPlan.nextDepartureDate).startOf('d').diff(dayjs(timeFilter.startTime).startOf('d'), 'd')
                    //     //     if (!timeFilter.anyDate && diffDays > tripPlan.nextDepartureDates.length) {
                    //     //         tripPlan.invalid = true
                    //     //         return
                    //     //     }
                    //     //     nextTrip = getNextTrip(timeFilter, lastLegOfService.route, tripPlan.nextDepartureDate)
                    //     //     if (!nextTrip) {
                    //     //         tripPlan.invalid = true
                    //     //         return
                    //     //     }
                    //     //     tripEndTimeAsSecsSinceMidnight = getDepartureTimeAsSecondsSinceMidnight(nextTrip, nextTrip.getStopTime(lastLegOfService.route.getEndStop()))
                    //     //
                    //     // }
                    //     tripPlan.arrivalTime = tripEndTimeAsSecsSinceMidnight + (applyAcs() && last(tripPlan.legs).type !== 'direct' ? (!reverse ? last(tripPlan.legs).duration : -last(tripPlan.legs).duration) : 0);
                    //     tripPlan.nextArrivalTime = tripPlan.nextDepartureDate.clone().add(tripPlan.arrivalTime, 's')
                    //
                    // }))
                    //
                    // tripPlans = tripPlans.filter(tripPlan => {
                    //     const filter = !tripPlan.invalid
                    //     !filter && logger.warn('Removing trip plan as we could not find a next trip.')
                    //     return filter
                    // })
                    //
                    // tripPlans.forEach(tripPlan => {
                    //     logger.debug('Adding up tripPlan duration....')
                    //     // tripPlan.legs.filter(l => l?.route?.services?.length).forEach(l => {
                    //     //
                    //     //     let nextTrip = getNextTrip(timeFilter, l.route)
                    //     //     const tripStartTimeAsSecsSinceMidnight = getDepartureTimeAsSecondsSinceMidnight(nextTrip, nextTrip.getStopTime(l.route.getStartStop()))
                    //     //     const tripEndTimeAsSecsSinceMidnight = getDepartureTimeAsSecondsSinceMidnight(nextTrip, nextTrip.getStopTime(l.route.getEndStop()))
                    //     //     l.duration = tripEndTimeAsSecsSinceMidnight - tripStartTimeAsSecsSinceMidnight;
                    //     //     logger.debug('leg duration: ', l.duration)
                    //     // })
                    //     logger.debug('leg durations: ', tripPlan.legs.map(l => l.duration));
                    //     tripPlan.duration = tripPlan.legs.filter(l => l.type !== 'direct').map(leg => leg.duration).reduce((prev, next) => prev + next, 0);
                    //     logger.debug('DURATION: ', tripPlan.duration);
                    // })
                    // logger.debug('tripplans4: ', util.inspect(tripPlans, {depth: 3}));
                    //
                    // tripPlans = tripPlans.filter(tripPlan => {
                    //     const filter = tripPlan.duration > 0
                    //     !filter && logger.debug('Removing ', tripPlan.legs[0] && tripPlan.legs[0].route.routeNumber, 'due to no duration')
                    //     return filter
                    // })
                    // tripCount = tripPlans.length
                    // logger.warn('Removed ', tripCount - tripPlans.length, 'of', tripCount, 'trips due to no duration')
                    //
                    // tripPlans = tripPlans.filter(tripPlan => {
                    //     // If the destination is a linked stop, don't check distance.
                    //     const lastLeg = last(tripPlan.legs)
                    //     if (lastLeg.type === 'direct') return true;
                    //     const duration = Math.round(lastLeg.duration / 60) * 60;
                    //     if (duration <= prefs.getMaxDurationConnectEnd(reverse)) return true;
                    //     logger.warn('Removing trip with route', tripPlan.legs.filter(l => l.route).map(l => l.r?.routeNumber).join('->'), ' due to end connection longer than allowed');
                    //     return false
                    // });
                    //
                    // if (!tripPlans.length && (prefs.connectStart === CONNECTIONS.drive || prefs.connectEnd === CONNECTIONS.drive)) {
                    //     prefs.set('connectFrom', CONNECTIONS.walk)
                    //     prefs.set('connectTo', CONNECTIONS.walk)
                    //     // setPrefs(new Prefs(prefs, true))
                    //     return
                    // }
                    //
                    // // Update any tripPlan transfers for inSeat transfers
                    // logger.debug('TripPlans before inseat: ', util.inspect(tripPlans, {depth: 3}))
                    // // eslint-disable-next-line
                    // for (const _ in [0, 1]) { // Look for in-seat transfers twice, in case 2nd leg is also one.
                    //
                    //     tripPlans.filter(tp => tp.type === 'transfer' && tp.legs.some(l => l.type === 'transfer' && l.inSeat === true)).forEach(tripPlan => {
                    //         logger.debug('In seat transfer: ', {...tripPlan})
                    //         tripPlan.legs = tripPlan.legs.filter((l, idx) => {
                    //             if (idx === 0 || idx > 3) {
                    //                 return true
                    //             }
                    //             const prevLeg = tripPlan.legs[idx - 1];
                    //             const nextLeg = tripPlan.legs[idx + 1]
                    //             if (prevLeg.type === 'transfer' && prevLeg.inSeat === true) {
                    //                 return false;
                    //             }
                    //             if (l.type === 'transfer' && l.inSeat === true && prevLeg?.trip && nextLeg?.trip) {
                    //
                    //                 const nextLegStops = nextLeg.route.getStopsBetween(nextLeg.trip).concat(nextLeg.route.getEndStop());
                    //                 nextLegStops.unshift(nextLeg.route.getStartStop());
                    //
                    //                 // const endTimeOfPrevLeg = getDepartureTimeAsSecondsSinceMidnight(prevLeg.trip, prevLeg.trip.stopTimes[prevLeg.route.endStopIdx])
                    //                 // const startTimeOfNextLeg = nextLeg.trip.getStartTimeAsSecondsSinceMidnight();
                    //                 // const diff = startTimeOfNextLeg - endTimeOfPrevLeg
                    //
                    //                 // const additionalDelta = prevLeg.trip.stopTimes[prevLeg.route.endStopIdx].delta + diff
                    //                 // nextLegStops.forEach(stop => {
                    //                 //     stop.arriveSecs += diff
                    //                 //     stop.departSecs += diff
                    //                 // })
                    //                 prevLeg.trip.stopTimes = prevLeg.trip.stopTimes.slice(0, prevLeg.route.endStopIdx).concat(nextLegStops)
                    //                 prevLeg.route.stopTimes = cloneDeep(prevLeg.trip.stopTimes)
                    //                 prevLeg.route.stops = prevLeg.route.stops.slice(0, prevLeg.route.endStopIdx).concat(nextLegStops);
                    //                 prevLeg.route.endStopIdx = prevLeg.route.stops.length - 1;
                    //                 prevLeg.merged = prevLeg.merged || []
                    //                 prevLeg.merged.push(nextLeg.route.routeId);
                    //
                    //                 const nextLegWps = nextLeg.route.getActiveWaypoints()
                    //                 prevLeg.route.waypoints = prevLeg.route.waypoints.slice(0, prevLeg.route.endWpIdx).concat(nextLegWps)
                    //                 prevLeg.route.endWpIdx = prevLeg.route.waypoints.length - 1;
                    //                 const startTimeOfPrevLeg = getDepartureTimeAsSecondsSinceMidnight(prevLeg.trip, prevLeg.trip.stopTimes[prevLeg.route.startStopIdx])
                    //                 const endTimeOfNextLeg = getDepartureTimeAsSecondsSinceMidnight(nextLeg.trip, nextLeg.trip.stopTimes[nextLeg.route.endStopIdx])
                    //                 prevLeg.duration = endTimeOfNextLeg - startTimeOfPrevLeg
                    //                 return false;
                    //             }
                    //             return true;
                    //         })
                    //         tripPlan.duration = tripPlan.legs.filter(l => l.type !== 'direct').map(leg => leg.duration).reduce((prev, next) => prev + next, 0);
                    //     })
                    // }
                    //
                    // // tripPlans.sort((t1, t2) => sortTrips(t1, t2, rankBy))
                    // // logger.debug('TripPlans after inseat: ', util.inspect(tripPlans, {depth: 3}))
                    // tripPlans.forEach(tripPlan => tripPlan.legs.filter(leg => leg.route).forEach(leg => {
                    //     leg.route.routeId = getCompositeRouteId(tripPlan, leg);
                    // }));
                    //
                    // logger.debug('Before uniqueness: ', [...tripPlans])
                    // tripPlans = uniqWith(tripPlans, JourneyPlan.isEqual)
                    // logger.debug('After uniqueness: ', [...tripPlans])

                    let allRoutes;
                    if (isAuthenticated) {
                        allRoutes = await routeModelData.getAll()
                    }

                    const _prefs = new Prefs({...prefs}, false)
                    const jpFilter = new JourneyPlanFilter({
                        filter,
                        timeFilter,
                        prefs: _prefs,
                        reverse,
                        features,
                        transfers,
                        allStops,
                        schedules,
                        allRoutes: allRoutes ? values(allRoutes) : null,
                        key
                    });
                    tripPlans = await jpFilter.lookup();
                    while (!tripPlans?.length && _prefs.maxConnectStartTime < 90) {
                        _prefs.maxConnectStartTime += 5;
                        _prefs.maxConnectEndTime += 5;
                        tripPlans = await jpFilter.lookup();
                    }
                    setOutsideDistance(prefs.maxConnectStartTime !== _prefs.maxConnectStartTime)

                    setTripPlans(tripPlans || []);

                    elapsed = Date.now() - startTime;
                    await createUsage({
                        userId: key,
                        feature: 'TripPlanner',
                        timestamp: dayjs().format(),
                        elapsed,
                        from: {search: filter?.from?.place_name, stop: !!filter?.from?.stopId},
                        to: {search: filter?.to?.place_name, stop: !!filter?.to?.stopId},
                        tripCount: tripPlans.length,
                        tripPlans: tripPlans.map(tripPlan => ({
                            depart: tripPlan?.nextDepartureDate?.format("ddd, MMM Do ") + ' ' + toTime(tripPlan?.departureTime),
                            legs: tripPlan?.legs.filter(l => l.duration > 0).map(l => l.type === 'route' ? {route: l.route?.routeNumber} : {
                                type: l.type,
                                duration: l.duration,
                                dist: l.dist
                            })
                        })),
                        prefs,
                        timeFilter: {...timeFilter, stopIds: undefined}
                    });

                    console.log("TP elapsed: " + toHrsMinsSecs(elapsed / 1000))

                } catch (e) {
                    logger.error('TripPlan failed: ' + e, e);
                    elapsed = Date.now() - startTime;
                    await createUsage({
                        userId: key,
                        feature: 'TripPlanner',
                        timestamp: dayjs().format(),
                        elapsed,
                        from: {search: filter?.from?.place_name, stop: !!filter?.from?.stopId},
                        to: {search: filter?.to?.place_name, stop: !!filter?.to?.stopId},
                        prefs,
                        timeFilter: {...timeFilter, stopIds: undefined},
                        error: e
                    });
                } finally {
                    logger.info("Elapsed: ", elapsed, "RequestTime: ", requestTime);
                    console.log('JP search: ', operator.operatorName)
                    ReactGA.event({
                        category: "Publishable",
                        action: "journey_plan",
                        label: operator.operatorName
                    });
                    console.log("TP elapsed: " + toHrsMinsSecs(elapsed / 1000))
                }
            }

            // logger.debug(Object.keys(allStops).length, filter.to, filter.from)
            if (key && allStops && Object.keys(allStops).length && schedules && filter.to && filter.from && !fetchingRoutes && transfers) {
                setFetchingRoutes(true)
                logger.debug('Loading trips...');
                onLoad().finally(() => {
                    setFetchingRoutes(false)
                });
            }

        },
        // eslint-disable-next-line
        [key, applyAcs, schedules, filter, timeFilter, transfers, setRoutes, setFetchingRoutes, setFetchingParams, allStops, getNearestStops, prefs, reverse, features, getConnectionDuration, getNextTrip, operator]
    );


    useEffect(() => {
        setSelectedStop(selectedStop => {
            if (selectedStop) {
                return allStops[selectedStop.stopId];
            }
        })
    }, [allStops, setSelectedStop])

    const canFlipDirection = useCallback(() => {
        if (!filter.from || !filter.to) {
            return true;
        }
        // const fromStop = fromValue[0];
        // const toStop = toValue[0];
        // if (fromStop.stopType !== 'school' && toStop.stopType !== 'school') {
        //     return true;
        // }
        // return stopSelectionFilter(toStop, fromStop, (r) => {
        //     const direction = fromStop.stopType === 'school' ? null : 'AM'
        //     return direction ? r.direction === direction : r.direction !== 'AM'
        // }, (stopRoute, filteredStopRoute) => stopRoute.maxSequence < filteredStopRoute.minSequence);
        return true;
    }, [filter]);

    const onGeocodeResult = useCallback((event, filterProp) => {
        logger.debug('We have a ', filterProp, ' result', event.result)
        if (event.result.place_type.indexOf('stop') > -1) {
            const stop = event.result.properties.stop;
            stop.setLinkedStops(allStops);
            setSelectedStop(stop);
            // setPoiMarker(null);
            setFilter(filter => {
                filter[filterProp] = stop
                filter[filterProp].place_name = stop.stopName
                return {...filter}
            })
        } else {
            // setPoiMarker(event.result)
            setFilter(filter => {
                filter[filterProp] = event.result
                return {...filter}
            })
        }

        // setReverse(false);
        // eslint-disable-next-line
    }, [setSelectedStop, setFilter, setFilteredTripPlans, setTripPlans, allStops])

    const onGeocodeResultTo = useCallback((event) => {
        logger.debug('From selected.')
        onGeocodeResult(event, 'to')
        setToValue(event.result.place_name)
        setSearchTo(null)
    }, [onGeocodeResult, setToValue, setSearchTo])

    const onGeocodeResultFrom = useCallback((event) => {
        logger.debug('From selected.')
        onGeocodeResult(event, 'from')
        setFromValue(event.result.place_name)
        setSearchFrom(null)
    }, [onGeocodeResult, setFromValue, setSearchFrom])

    const theLocalGeoCoder = useCallback((query) => {
        return localGeocoder({
            query,
            allStops: allStops
        });
    }, [allStops]);

    const onViewportChange = useCallback((newViewport) => {
        logger.debug('onviewpot change TO')
        const geocoderDefaultOverrides = {transitionDuration: 1000};
        return setViewState({...newViewport, ...geocoderDefaultOverrides});
    }, [setViewState])

    const [printTripPlan, setPrintTripPlan] = useState(null);

    const urlToText = useCallback((useCurrentLocation = false) => {
        let url = `${fullUrl}${window.location.pathname}${window.location.pathname.endsWith("trip_planner") ? `/${key}` : ''}?`
        let query = ''
        if (filter.from) {
            query += '&b_fr=' + encodeURIComponent(`${filter.from.stopId ? 'stop|' : 'poi|'}${filter.from.stopId ? filter.from.stopId : filter.from.place_name}` + (!filter.from.stopId ? `|${filter.from.center[1]},${filter.from.center[0]}` : ''))
        }
        if (filter.to) {
            query += '&b_to=' + encodeURIComponent(`${filter.to.stopId ? 'stop|' : 'poi|'}${filter.to.stopId ? filter.to.stopId : filter.to.place_name}` + (!filter.to.stopId ? `|${filter.to.center[1]},${filter.to.center[0]}` : ''))
        }
        query += `&b_ti=` + encodeURIComponent(`${timeFilter.toQueryParams()}`) +
            `&b_st=` + encodeURIComponent(`${Object.keys(CONNECTIONS).filter(k => CONNECTIONS[k] === prefs.connectStart)[0]}|${prefs.maxConnectStartTime}`) +
            `&b_en=` + encodeURIComponent(`${Object.keys(CONNECTIONS).filter(k => CONNECTIONS[k] === prefs.connectEnd)[0]}|${prefs.maxConnectEndTime}`) +
            `&b_so=` + encodeURIComponent(`${rankBy}`) +
            (prefs.avoidTransfers ? '&b_notx=true' : '') +
            (prefs.avoidSchool ? '&b_nosc=true' : '');
        if (useCurrentLocation) {
            query += `&b_cl=true`
        }
        return url + query
    }, [fullUrl, filter, timeFilter, prefs, rankBy, key])

    return (
        <div className="TripPlanner">
            {contextHolder}
            <PrintTripModal
                apiKey={key}
                filter={filter}
                tripPlan={printTripPlan}
                open={!!printTripPlan}
                setVisible={setPrintTripPlan}
                allStops={allStops} viewState={viewState}
                timeFilter={timeFilter} toValue={toValue}
                setShowMap={setShowMap}/>
            {schedules && basicStops ? (
                <>
                    {/*<MapFilterModal stops={values(allStops)} filter={filter} setFilter={setFilter}/>*/}
                    <Row>
                        <Col lg={8} className="m-md-0">
                            <div className={`MapPlaceholder ${showMap ? 'ShowMap' : ''}`}>
                                <RouteMapViewer routes={routes}
                                                setMapRef={setMapRef}
                                                startMarker={startMarker}
                                                endMarker={endMarker}
                                                popupData={popupData}
                                                allStops={allStops || basicStops}
                                                viewState={viewState}
                                    // setViewState={setViewState}
                                                verifiedOnly={true}
                                                tripPlanner={true}
                                                handleFromClicked={(stop) => {
                                                    setFilter(filter => {
                                                        filter.from = {...stop, place_name: stop.stopName}
                                                        return {...filter}
                                                    })
                                                    // setFromValue([stop])
                                                    // setSearchFrom(stop.stopName)
                                                }}
                                                handleToClicked={(stop) => {
                                                    setFilter(filter => {
                                                        filter.to = {...stop, place_name: stop.stopName}
                                                        return {...filter}
                                                    })
                                                    // setToValue([stop])
                                                    // setSearchTo(stop.stopName)
                                                }}
                                                filteredRoutes={filteredRoutes}
                                                selectedRouteIds={flatten(zoom?.map(compositeRIds => compositeRIds.split(":")))}
                                                highlightedRouteIds={highlightedRouteIds.length ? highlightedRouteIds : flatten(zoom?.map(compositeRIds => compositeRIds.split(":")))}
                                                selectedStop={selectedStop}
                                                handleSelectedRoute={(route) => {
                                                    if (highlightedRouteIds.indexOf(route.routeId) > -1) {
                                                        setHighlightedRouteIds(selectedRouteIds => selectedRouteIds.filter(rId => rId !== route.routeId));
                                                        setZoom(zoom => zoom.filter(rId => rId !== route.routeId));
                                                    } else {
                                                        setHighlightedRouteIds(selectedRouteIds => selectedRouteIds.concat(route.routeId));
                                                        setZoom(zoom => zoom.concat(route.routeId));
                                                    }
                                                }}
                                                focusStop={focusStop}
                                                setFocusStop={setFocusStop}
                                                handleStopClick={(stop) => setSelectedStop(stop)}
                                                setFilter={setFilter}
                                                filter={filter}
                                                clearFn={onClear}
                                                operatorId={operatorId}/>
                                <span className="close-map" onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    setShowMap(false)
                                }}><CloseOutlined/></span>
                            </div>
                        </Col>
                        <Col lg={4} className=" RouteListContainer order-first order-lg-last">
                            <div className="SearchContainer">
                                {fetching ?
                                    <LoadMessage message={"Loading stops..."} size={"lg"}/>
                                    :
                                    <>

                                        <TripPlannerPrefsView setPrefs={setPrefs} prefs={prefs}
                                                              reverse={reverse}
                                                              clearFn={onClear}
                                                              showPrefs={showPrefs}
                                                              disabled={!!fetchingRoutes}
                                                              onClosePrefs={() => {
                                                                  setFromQuery(false)
                                                                  setShowPrefs(false)
                                                              }}/>
                                        <Row key='FromRow'>
                                            <div className="d-flex flex-row">
                                                <div className={"from-col-1 " + prefs.connectStart}>
                                                    <Button
                                                        disabled={!!fetchingRoutes}
                                                        title={prefs.connectStart + " to start stop"}
                                                        variant={"light"}
                                                        className={""}
                                                        onClick={() => setShowPrefs(true)}>
                                                        <div>
                                                            {renderConnectionImg(!reverse ? prefs.connectStart : prefs.connectEnd)}
                                                        </div>
                                                        <div
                                                            className="ConnectDetails">{!reverse ? prefs.maxConnectStartTime : prefs.maxConnectEndTime} mins
                                                        </div>
                                                    </Button>
                                                </div>
                                                <div className={"from-col-2"}
                                                     onClick={() => setFromQuery(false)}>
                                                    {/*{mapRef && !reverse ? fromSearch : toSearch}*/}
                                                    {mapRef && <TripLocation
                                                        reverseGeocode={true}
                                                        allStops={basicStops}
                                                        mapRef={mapRef}
                                                        onViewportChange={onViewportChange}
                                                        placeholder={!allStops ? 'Loading...' : (placeholderFrom || 'From')}
                                                        localGeocoder={theLocalGeoCoder}
                                                        onResult={onGeocodeResultFrom}
                                                        onClear={onClearFrom}
                                                        inputValue={searchFrom || filter?.from?.place_name}
                                                        disabled={!!fetchingRoutes || !allStops}
                                                        // localGeocoderOnly={fromQuery && searchFrom?.length > 0}
                                                        // minLength={fromQuery ? searchFrom?.length + 2 : 2}
                                                    />}
                                                    {/*<LocationSearch*/}
                                                    {/*    allStops={allStops}*/}
                                                    {/*    size="small"*/}
                                                    {/*    style={{width: "100%"}}*/}
                                                    {/*    suffixIcon={<Down/>}*/}
                                                    {/*    // placeholder={"From"}*/}
                                                    {/*    handleSelect={data => {*/}
                                                    {/*        console.log('Selected %s', data);*/}
                                                    {/*        // const stop = allStops[value];*/}
                                                    {/*        // setStop(stop);*/}
                                                    {/*        // setTitle(stop.stopName)*/}
                                                    {/*    }}*/}
                                                    {/*/>*/}
                                                    <div className={"TargetBtn"}
                                                         onClick={() => {
                                                             setFromQuery(false)
                                                             getCurrentLocation(true)
                                                         }}>
                                                        <Target/></div>
                                                </div>
                                                <div className={"from-col-3"}></div>
                                            </div>
                                        </Row>
                                        <Row key='SwitchRow'>
                                            <Col>
                                                <div className={"switch-col-1"}>
                                                    <Button title="Reverse trip"
                                                            className={"SwitchBtn"}
                                                            size={'sm'}
                                                            variant="outline"
                                                            disabled={!canFlipDirection() || !!fetchingRoutes}
                                                            active={reverse}
                                                            onClick={() => {
                                                                setFilteredTripPlans([]);
                                                                setTripPlans([]);
                                                                setHighlightedRouteIds([])
                                                                setZoom([])
                                                                setReverse(reverse => !reverse)
                                                                setFilter(filter => ({
                                                                    to: filter.from,
                                                                    from: filter.to
                                                                }))
                                                                // setSearchTo(filter?.from?.place_name);
                                                                // setSearchFrom(filter?.to?.place_name);
                                                            }}
                                                    >
                                                        <Switch/>
                                                    </Button>
                                                    <div className="switch-line"></div>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row key='ToRow'>
                                            <div className="d-flex flex-row">
                                                <div className={"to-col-1 " + prefs.connectEnd}>
                                                    <Button
                                                        title={prefs.connectEnd + " to end stop"}
                                                        variant={"light"}
                                                        className={""}
                                                        disabled={!!fetchingRoutes}
                                                        onClick={() => setShowPrefs(true)}>
                                                        <div>
                                                            {renderConnectionImg(!reverse ? prefs.connectEnd : prefs.connectStart)}
                                                        </div>
                                                        <div
                                                            className="ConnectDetails">{!reverse ? prefs.maxConnectEndTime : prefs.maxConnectStartTime} mins
                                                        </div>
                                                    </Button>
                                                </div>
                                                <div className={"to-col-2"}
                                                     onClick={() => setFromQuery(false)}>
                                                    {/*{mapRef && !reverse ? toSearch : fromSearch}*/}
                                                    {mapRef && <TripLocation
                                                        allStops={basicStops}
                                                        reverseGeocode={true}
                                                        mapRef={mapRef}
                                                        onViewportChange={onViewportChange}
                                                        placeholder={!allStops ? 'Loading...' : (placeholderTo || 'To')}
                                                        localGeocoder={theLocalGeoCoder}
                                                        onResult={onGeocodeResultTo}
                                                        onClear={onClearTo}
                                                        inputValue={searchTo || filter?.to?.place_name}
                                                        disabled={!!fetchingRoutes}
                                                        // localGeocoderOnly={fromQuery && searchTo?.length > 0}
                                                        // minLength={fromQuery ? searchTo?.length + 2 : 2}
                                                    />}
                                                    <div className={"TargetBtn"}
                                                         onClick={() => {
                                                             setFromQuery(false)
                                                             getCurrentLocation(false)
                                                         }}>
                                                        <Target/></div>
                                                </div>
                                                <div className={"to-col-3"}></div>
                                            </div>
                                        </Row>
                                        <Row className="filter-row-1">
                                            <div className="d-flex flex-row justify-content-between"
                                                 onClick={() => setFromQuery(false)}>
                                                <TripPlannerTimePrefsView setPrefs={setPrefs} prefs={prefs}
                                                                          setTimeFilter={setTimeFilter}
                                                                          timeFilter={timeFilter.clone()}
                                                                          clearFn={onClear}
                                                                          disabled={!!fetchingRoutes}/>
                                            </div>
                                        </Row>
                                        <Row key='PrefsRow' className="filter-row-2">
                                            <Col className={'mt-0 d-flex align-items-center'}><small>Sort by</small>
                                                <Select
                                                    size={'small'}
                                                    onChange={setRankBy}
                                                    disabled={!!fetchingRoutes}
                                                    value={rankBy}
                                                    suffixIcon={<Down/>}>
                                                    {Object.keys(RANK_BY).map(type => (
                                                        <Option key={RANK_BY[type]}
                                                                value={RANK_BY[type]}>{RANK_BY[type]}</Option>
                                                    ))}
                                                </Select>
                                            </Col>
                                            <Col xs="auto">
                                                <div className={'share-btn-wrap'}>
                                                    {filter.from && filter.to ?
                                                        <Button variant={"primary"} className="icon-button inline-icon"
                                                                onClick={() => {
                                                                    navigator.clipboard.writeText(urlToText()).then(() => {
                                                                        messageApi.info(<div><p>Journey plan URL copied
                                                                            to
                                                                            clipboard</p> <QRCode
                                                                            value={`${urlToText()}`}
                                                                            ecLevel="L" size={150}
                                                                            fgColor={"#000000"}
                                                                            qrStyle={"dots"}
                                                                        /></div>, 10)
                                                                    })
                                                                }}><Send/> Share</Button>
                                                        :
                                                        <Popconfirm
                                                            okText={"Yes"}
                                                            cancelText={"No"}
                                                            title={`Set share link to use current location?`}
                                                            onConfirm={() => {
                                                                navigator.clipboard.writeText(urlToText(true)).then(() => {
                                                                    messageApi.info(<div><p>Journey plan URL copied to
                                                                        clipboard</p> <QRCode
                                                                        value={`${urlToText(true)}`}
                                                                        ecLevel="L" size={150}
                                                                        fgColor={"#000000"}
                                                                        qrStyle={"dots"}
                                                                    /></div>)
                                                                }, 10)
                                                            }}
                                                            onCancel={() => {
                                                                navigator.clipboard.writeText(urlToText()).then(() => {
                                                                    messageApi.info(<div><p>Journey plan URL copied to
                                                                        clipboard</p> <QRCode value={`${urlToText()}`}
                                                                                              ecLevel="L" size={150}
                                                                                              fgColor={"#000000"}
                                                                                              qrStyle={"dots"}
                                                                    /></div>)
                                                                }, 10)
                                                            }}
                                                        >
                                                            <Button variant={"primary"}
                                                                    className="icon-button btn-outline inline-icon"><Send/> Share</Button>
                                                        </Popconfirm>
                                                    }
                                                </div>
                                            </Col>
                                            {/*<Col*/}
                                            {/*    className={'mt-0'} sm={6}><small>Service</small><Select*/}
                                            {/*    size={'small'}*/}
                                            {/*    onChange={setShow}*/}
                                            {/*    value={show}*/}
                                            {/*    suffixIcon={<Down/>}>*/}
                                            {/*    {['All', 'AM', 'PM', 'Regular'].map(show => (*/}
                                            {/*        <Option key={show} value={show}>{show}</Option>*/}
                                            {/*    ))}*/}
                                            {/*</Select></Col>*/}
                                        </Row>
                                    </>
                                }
                            </div>
                            <div className={'TripContainer'}>
                                {filteredTripPlans?.length ?
                                    <div className={'d-flex flex-row justify-content-center position-relative mb-2'}>
                                        <div className={'mt-2'}><span className={'stop-times-explainer'}>Estimated stop times are in italics</span>
                                        </div>
                                    </div> : ''}
                                {filteredTripPlans?.length && !timeFilter.anyDate && filteredTripPlans.filter(tp => tp.diffDays === 0).length === 0 ?
                                    <div className={'d-flex flex-row justify-content-center position-relative mb-2'}>
                                        <Alert
                                            style={{width: "400px"}}
                                            message={`No more services available ${timeFilter.startTime.isSame(dayjs(), 'd') ? 'today' : `on ${timeFilter.startTime.format("ddd Do MMM")}`}`}
                                            description="See below for next available services"
                                            type="warning"
                                            className="alert-warning"
                                            showIcon
                                        />
                                    </div> : filteredTripPlans?.length && !timeFilter.anyDate && timeFilter.type === TimeFilterType.ARRIVING && filteredTripPlans.filter(tp => tp.nextArrivalTime.isBefore(timeFilter.startTime)).length === 0 ?
                                        <div className={'d-flex flex-row justify-content-center position-relative mb-2'}
                                             style={{padding: '10px'}}>
                                            <Alert
                                                style={{width: "400px"}}
                                                message={`No services arriving before ${timeFilter.startTime.format("HH:mm ddd Do MMM")}`}
                                                description="See below for next available services"
                                                type="warning"
                                                className="alert-warning"
                                                showIcon
                                            />
                                        </div> : ''}
                                {filteredTripPlans?.length && outsideDistance ?
                                    <div className={'d-flex flex-row justify-content-center position-relative mb-2'}>
                                        <Alert
                                            style={{width: "400px"}}
                                            message={`No services within ${prefs.maxConnectStartTime} mins ${prefs.connectStart.toLowerCase()} time.`}
                                            description="See below for nearest services"
                                            type="warning"
                                            className="alert-warning"
                                            showIcon
                                        />
                                    </div> : <></>}
                                {filteredTripPlans.map((tripPlan, idx) => {
                                    const firstLegOfService = tripPlan.type === 'direct' ? tripPlan.legs[0] : tripPlan.legs[tripPlan.legs.findIndex(l => l.type === 'route')];
                                    const route = firstLegOfService.route
                                    const routeIdsInTripPlan = tripPlan.legs.filter(l => l.route?.routeId).map(leg => leg?.route?.routeId)
                                    const compositeRouteId = routeIdsInTripPlan.join(":")
                                    return (
                                        <Row key={"TripPlannerTripContainer" + idx} className={'trip-wrap'} style={{
                                            margin: `${idx > 0 ? '15px 0' : '0 0'}`,
                                            borderTop: `${idx > 0 ? '1px solid #EBEBEB' : ''}`,
                                            borderBottom: `${idx === tripPlans.length - 1 ? '1px solid #EBEBEB' : ''}`
                                        }}>
                                            <>
                                                <Row key={`TripPlannerOverviewContainer-${route.routeId}`}
                                                     onMouseOver={() => {
                                                         setHighlightedRouteIds(highlightedRouteIds => highlightedRouteIds.concat(routeIdsInTripPlan));
                                                     }}
                                                     onClick={() => {
                                                         if (tripPlan.type === 'direct') return
                                                         setSelectedStop(null);
                                                         setFocusStop(null);
                                                         if (zoom.includes(compositeRouteId)) {
                                                             // const _zoom = zoom.filter(rId => compositeRouteId !== rId)
                                                             // setZoom(_zoom);
                                                             setZoom([]);
                                                             // setHighlightedRouteIds(hlRIds => hlRIds.filter(rId => !routeIdsInTripPlan.includes(rId)))
                                                             // setHighlightedRouteIds([routeIdsInTripPlan])
                                                         } else {
                                                             // setZoom(zoom => zoom.concat(compositeRouteId));
                                                             setZoom([compositeRouteId])
                                                             // setHighlightedRouteIds(highlightedRouteIds => highlightedRouteIds.concat(routeIdsInTripPlan));
                                                             setHighlightedRouteIds(routeIdsInTripPlan)
                                                         }

                                                     }}
                                                     onMouseOut={() => {
                                                         // const _highlightedRouteIds = highlightedRouteIds.filter(rId => rId !== route.routeId)
                                                         // setHighlightedRouteIds(_highlightedRouteIds);
                                                         if (!zoom.includes(compositeRouteId)) {
                                                             setHighlightedRouteIds(hlRIds => hlRIds.filter(rId => !routeIdsInTripPlan.includes(rId)))
                                                         }
                                                     }}>
                                                    <Col key={`TripPlannerLegContainer-${route.routeId}`}
                                                         style={{cursor: "pointer", textAlign: "center"}}

                                                    >
                                                        {tripPlan.type === 'direct' ?
                                                            <DirectTripOverview tripPlan={tripPlan}
                                                                                setShowMap={setShowMap}/> :
                                                            <TripOverview tripPlan={tripPlan} setShowMap={setShowMap}/>}
                                                        {zoom.indexOf(compositeRouteId) > -1 &&
                                                            <div className={'trip-expanded-wrap'}>
                                                                <span className={'print-tripplan'} title="Print"
                                                                      onClick={event => {
                                                                          event.preventDefault()
                                                                          event.stopPropagation()
                                                                          setPrintTripPlan(tripPlan);
                                                                      }}><Print/></span>
                                                                {tripPlan.legs.slice(1, -1).map((leg, idx) => {
                                                                        let nextTrip = leg.trip;
                                                                        // if (leg.route?.services?.length) {
                                                                        //     nextTrip = getNextTrip(timeFilter, leg.route)
                                                                        // nextTrip = find(leg.route.services, ['tripId', leg.tripId])
                                                                        // }
                                                                        const lastLeg = idx === tripPlan.legs.length - 1
                                                                        return leg.type === 'route' ?
                                                                            (
                                                                                <div className={'trip-expanded-block'}
                                                                                     key={`trip-expand-block-${compositeRouteId}-${leg.route.routeId}`}>
                                                                                    {process.env.REACT_APP_VERBOSE && <>
                                                                                        <Row><Col>Trip
                                                                                            ID: {leg.tripId}</Col></Row>
                                                                                        <Row><Col>{getRouteIdFromCompositeId(leg.route.routeId).split(',').map(rId => (
                                                                                            <div><a
                                                                                                href={"/services/" + rId}>{leg.route.routeNumber}</a>
                                                                                            </div>))}</Col></Row>
                                                                                    </>}
                                                                                    <Row
                                                                                        className={'trip-expanded-change-block'}>
                                                                                        <Col xs={1}
                                                                                             className={`pl-0 trip-expanded-time-block ${nextTrip.getStopTime(leg.route.getStartStop(leg.trip.stopTimes)).timingPoint ? 'TimingPoint' : ''}`}>{getDepartureTime(nextTrip, nextTrip.getStopTime(leg.route.getStartStop(leg.trip.stopTimes)))}</Col>
                                                                                        <Col xs={11}><DisplayStop
                                                                                            sequence={leg.route.getStartStopSequence()}
                                                                                            stop={leg.route.getStartStop()}
                                                                                            noWarning
                                                                                            noRoutes
                                                                                            noInterchange/></Col>
                                                                                        <div
                                                                                            className={'trip-expanded-depart'}>Depart
                                                                                        </div>
                                                                                    </Row>
                                                                                    <Row
                                                                                        className={'trip-expanded-line-row'}>
                                                                                        <Col xs={1}></Col>
                                                                                        <Col xs={11}>
                                                                                            <div
                                                                                                className={'trip-expanded-line'}/>
                                                                                        </Col>
                                                                                    </Row>
                                                                                    {zoomStops.indexOf(leg.route.routeId) > -1 ? (
                                                                                            <>
                                                                                                {leg.route.getStopsBetween(nextTrip).map(stop => {
                                                                                                    return (
                                                                                                        <div onClick={(e) => {
                                                                                                            e.stopPropagation();
                                                                                                            e.preventDefault();
                                                                                                            const _zoomStops = zoomStops.filter(rId => rId !== leg.route.routeId)
                                                                                                            setZoomStops(_zoomStops);
                                                                                                        }}>
                                                                                                            <Row>
                                                                                                                <Col xs={1}
                                                                                                                     className={`pl-0 trip-expanded-time-block ${nextTrip.getStopTime(stop).timingPoint ? 'TimingPoint' : ''}`}>{getDepartureTime(nextTrip, nextTrip.getStopTime(stop))}</Col>
                                                                                                                <Col xs={11}>
                                                                                                                    <DisplayStop
                                                                                                                        stop={stop}
                                                                                                                        noWarning
                                                                                                                        noRoutes
                                                                                                                        noInterchange/>
                                                                                                                </Col>
                                                                                                            </Row>
                                                                                                            <Row
                                                                                                                className={'trip-expanded-line-row'}>
                                                                                                                <Col
                                                                                                                    xs={1}></Col>
                                                                                                                <Col xs={11}>
                                                                                                                    <div
                                                                                                                        className={'trip-expanded-line'}/>
                                                                                                                </Col>
                                                                                                            </Row>
                                                                                                        </div>
                                                                                                    )
                                                                                                })}
                                                                                            </>) :
                                                                                        <>
                                                                                            <Row
                                                                                                className={'trip-expanded-line-row'}
                                                                                                onClick={(e) => {
                                                                                                    e.stopPropagation();
                                                                                                    e.preventDefault();
                                                                                                    setZoomStops(zoomStops => zoomStops.concat(leg.route.routeId));
                                                                                                }}>
                                                                                                <Col xs={1}></Col>
                                                                                                <Col xs={11}>
                                                                                                    <div
                                                                                                        className={'trip-expanded-line'}/>
                                                                                                </Col>
                                                                                                <div
                                                                                                    className={'trip-expanded-stops-link'}>{leg.route.getStopCount()} Stops
                                                                                                </div>
                                                                                            </Row>
                                                                                            <Row
                                                                                                className={'trip-expanded-line-row'}>
                                                                                                <Col xs={1}></Col>
                                                                                                <Col xs={11}>
                                                                                                    <div
                                                                                                        className={'trip-expanded-line'}/>
                                                                                                </Col>
                                                                                            </Row>
                                                                                        </>
                                                                                    }

                                                                                    <Row>
                                                                                        <Col xs={1}
                                                                                             className={`pl-0 trip-expanded-time-block ${nextTrip.getStopTime(leg.route.getEndStop(leg.trip.stopTimes)).timingPoint ? 'TimingPoint' : ''}`}>{getDepartureTime(nextTrip, nextTrip.getStopTime(leg.route.getEndStop(leg.trip.stopTimes)))}</Col>
                                                                                        <Col xs={11}>
                                                                                            <DisplayStop
                                                                                                sequence={leg.route.getEndStopSequence()}
                                                                                                stop={leg.route.getEndStop()}
                                                                                                noWarning
                                                                                                noRoutes
                                                                                                noInterchange/></Col>
                                                                                        <div
                                                                                            className={'trip-expanded-arrive'}>Arrive
                                                                                        </div>
                                                                                    </Row>
                                                                                    <Row>
                                                                                        <Col>
                                                                                            <a
                                                                                                href={`${process.env.REACT_APP_URL || ''}/timetables/${key}?rs=${getRouteIdFromCompositeId(leg.route.routeId)}`}
                                                                                                target="_blank"
                                                                                                rel="noreferrer noopener"
                                                                                                className={'trip-expanded-full-link'}>View
                                                                                                full
                                                                                                timetable</a>
                                                                                        </Col>
                                                                                    </Row>
                                                                                </div>)
                                                                            : leg.type !== 'direct' ?
                                                                                <>
                                                                                    <Row className={"route-change-bus-row"}>
                                                                                        <Col xs={12}>
                                                                                            <div
                                                                                                className={"d-flex flex-row justify-content-center align-items-center"}>
                                                                                                <div
                                                                                                    className="route-change-bus-icon">
                                                                                                    {leg.type === 'transfer' && leg.duration > 0 ? renderConnectionImg('walking') :
                                                                                                        <Image
                                                                                                            src={`https://prod-rm-web-infra-gpx-s3-gpxfiles47af3947-1f2jzq8rsjwfq.s3.ap-southeast-2.amazonaws.com/public/${capitalize(leg.type)}.svg`}
                                                                                                            width="24"
                                                                                                            height="24"/>
                                                                                                    }
                                                                                                </div>
                                                                                                <div
                                                                                                    className={"ml-2 d-flex flex-row justify-content-start align-items-center route-change-bus-info"}>
                                                                                                    {leg.type === 'drive' ? 'Drive' : leg.type === 'walking' || (leg.type === 'transfer' && leg.duration > 0) ? `Walk to stop ${tripPlan.legs[idx + 2].route.getStartStop().stopName} and transfer to` : 'Transfer to '}
                                                                                                    <div
                                                                                                        className={"ml-2"}>{tripPlan.legs[idx + 2].route ?
                                                                                                        <RouteNumber
                                                                                                            route={tripPlan.legs[idx + 2].route}
                                                                                                            size={'sm'}/> : tripPlan.legs[idx + 2].special}</div>
                                                                                                </div>
                                                                                                <div className={"ml-2"}>
                                                                                                    {leg.type !== 'transfer' && <>
                                                                                                        {toKmMs(leg.distance, 0)} to {lastLeg ? toValue : 'stop ' + tripPlan.legs[idx + 2].route.getStartStop().stopName}
                                                                                                    </>
                                                                                                    }
                                                                                                </div>
                                                                                            </div>
                                                                                        </Col>
                                                                                    </Row>
                                                                                </>
                                                                                : <></>
                                                                    }
                                                                )}
                                                            </div>
                                                        }
                                                    </Col>
                                                </Row>
                                            </>
                                        </Row>
                                    )
                                })
                                }
                            </div>
                            {fetchingRoutes || fetchingParams ? (
                                <LoadMessage message={"Finding journeys..."} size={"lg"}/>
                            ) : !filteredTripPlans.length && filter.from && filter.to && !fetchingRoutes && !fetchingParams && !fetching ?


                                <div className={"NoTripFound msg-warning"}>
                                    <p>No journeys
                                        from <strong>{filter.from.place_name}</strong> to <strong>{filter.to.place_name}</strong> for
                                        the specified time.
                                    </p>
                                    <p>Refine your search or increase your walking or driving preferences <Button
                                        variant={"link"}
                                        size={"sm"}
                                        onClick={() => setShowPrefs(true)}>here</Button>.</p>
                                    {operator?.operatorPhone && operator?.operatorEmail &&
                                        <p>For assistance, please contact us on <a
                                            href={`tel:${operator.operatorPhone}`}>{operator.operatorPhone}</a> or copy
                                            your journey details by clicking &nbsp;
                                            <a href="#copy" onClick={() => {
                                                navigator.clipboard.writeText(urlToText()).then(() => {
                                                    messageApi.info('Journey plan URL copied to clipboard')
                                                })
                                            }}>here</a>, then email them to
                                            us
                                            at&nbsp;
                                            <a href={`mailto:${operator.operatorEmail}?subject=Journey planning enquiry`}>{operator.operatorEmail}</a>

                                            {/*<CopyToClipboard*/}
                                            {/*    text={`${process.env.REACT_APP_URL?.length ? process.env.REACT_APP_URL : fullUrl}/trip_planner/${key}?from=${filter.from.stopId ? 'stop|' : 'poi|'}${encodeURIComponent(filter.from.place_name)}&to=${filter.to.stopId ? 'stop|' : 'poi|'}${encodeURIComponent(filter.to.place_name)}&time=${timeFilter.toQueryParams()}&start=${Object.keys(CONNECTIONS).filter(k => CONNECTIONS[k] === prefs.connectStart)[0]}|${prefs.maxConnectStartTime}&end=${Object.keys(CONNECTIONS).filter(k => CONNECTIONS[k] === prefs.connectEnd)[0]}|${prefs.maxConnectEndTime}`}*/}
                                            {/*    onCopy={() => messageApi.info('Journey details copied to clipboard.')}>*/}
                                            {/*     <a href={"#"}>here</a>*/}
                                            {/*</CopyToClipboard>*/}
                                        </p>}
                                </div> : !filteredTripPlans.length && filter.from && filter.to && !fetchingRoutes && !fetchingParams && !fetching && show !== 'All' ?
                                    <div className={"msg-warning"}>
                                        <p>No <strong>{show}</strong> journeys available. Please refine
                                            your search.</p>
                                    </div> : <></>
                            }

                        </Col>
                    </Row>
                </>
            ) : <LoadMessage message={"loading map..."} size={"lg"}/>}

        </div>


    ); //End return
}

// export default React.memo(TripPlanner);

