import React, {useState} from "react";
// import "../containers/TripPlanner.css";
import {Modal, Select} from "antd";
import {Col, Image, Row} from "react-bootstrap";
import DirectTripOverview from "./DirectTripOverview";
import TripOverview from "./TripOverview";
import {capitalize, last} from "lodash";
import {TimeFilterType} from "../model/timeFilter";
import {getDepartureTime} from "../libs/routes-lib";
import DisplayStop from "./DisplayStop";
import {renderConnectionImg} from "./Legs";
import RouteNumber from "./RouteNumber";
import {toKmMs} from "../libs/formatLib";
import RouteMapViewer from "./RouteMapViewer";
import {CloseOutlined} from "@ant-design/icons";
import config from "../config";
import dayjs from "../dayjs";

// eslint-disable-next-line
const {Option} = Select;

function PrintTripModal({
                            apiKey,
                            tripPlan,
                            allStops,
                            viewState,
                            timeFilter,
                            setShowMap,
                            toValue,
                            open,
                            setVisible,
                            filter
                        }) {

    if(!tripPlan) return <></>
    const routeLegsInTripPlan = tripPlan.legs.filter(l => l.route);
    const firstLegOfService = tripPlan.type === 'direct' ? tripPlan.legs[0] : routeLegsInTripPlan[0];
    const route = firstLegOfService.route
    const routesInTripPlan = routeLegsInTripPlan.map(l => l.route);
    // const routeIdsInTripPlan = tripPlan.legs.filter(l => l.route?.routeId).map(leg => leg?.route?.routeId)
    const compositeRouteId = routesInTripPlan.map(r => r.routeId).join(":")

    // eslint-disable-next-line
    const [mapCount, setMapCount] = useState(1)

    return (
        <Modal
            width={1000}
            open={open}
            title={[<span>Print your journey</span>,
                <Select
                    size='small'
                    className="ml-2"
                    onChange={(count) => {
                        setMapCount(count)
                    }}
                    value={mapCount}
                >
                    {Array.from(Array(3).keys()).map(val => {
                            val += 1
                            return <Option key={`MapCount-${val}`}
                                           value={val}>{val} map{val > 1 ? 's' : ''}</Option>;
                        }
                    )}
                </Select>
            ]}
            destroyOnClose
            okText={`Print`}
            onCancel={() => setVisible(null)}
            onOk={() => window.print()}
        >
            <div className="TripPlanner PrintView">
                <Row className="customer-info-logo"><Col>
                    <Image
                        style={{maxHeight: "42px", marginBottom: "2px", minWidth: '32px'}}
                        src={`https://${config.s3.BUCKET}.s3.ap-southeast-2.amazonaws.com/public/${apiKey}/logo.png`}
                        onError={(e) => {
                        }}/>
                </Col></Row>
                <Row className="customer-info-text">
                    <Col xs={12}>
                    <div className='d-flex justify-content-between mb-2'>
                        <div>Your customised journey plan
                            from <strong>{filter.from.place_name}</strong> to <strong>{filter.to.place_name}</strong>
                        </div>
                        <div>{dayjs().format('LL')}</div>
                    </div>
                </Col>
                </Row>
                <Row>
                    <Col xs={12} className="m-md-0">
                        {Array.from(Array(mapCount).keys()).map(_ => (
                            <>
                                {mapCount > 1 ?
                                    <Row>
                                        <Col xs={12} className="m-md-0" key={`MapCol_${_}`}>
                                            <div className='mt-2 mt-1'><span className='font-weight-bolder strong'
                                                                             contentEditable>Map title</span></div>
                                        </Col>
                                    </Row>
                                    : <></>}
                                <Row>
                                    <Col xs={12} className="m-md-0" key={`MapCol_${_}`}>
                                        {/*{mapCount > 1 ? <div className='mt-2 mt-1'><span contentEditable>Map name</span> </div> : <></>}*/}
                                        <div className={`MapPlaceholder`}>
                                            <RouteMapViewer routes={routesInTripPlan}
                                                            noToolbar
                                                            allStops={allStops}
                                                            viewState={viewState}
                                                            filter={filter}
                                                // setViewState={setViewState}
                                                            verifiedOnly={true}
                                                            tripPlanner={true}
                                                            filteredRoutes={routesInTripPlan}
                                                            selectedRouteIds={[compositeRouteId]}
                                                            highlightedRouteIds={compositeRouteId.split(":")}
                                                // highlightedRouteIds={highlightedRouteIds.length ? highlightedRouteIds : flatten(zoom?.map(compositeRIds => compositeRIds.split(":")))}
                                                // handleSelectedRoute={(route) => {
                                                //     if (highlightedRouteIds.indexOf(route.routeId) > -1) {
                                                //         setHighlightedRouteIds(selectedRouteIds => selectedRouteIds.filter(rId => rId !== route.routeId));
                                                //         setZoom(zoom => zoom.filter(rId => rId !== route.routeId));
                                                //     } else {
                                                //         setHighlightedRouteIds(selectedRouteIds => selectedRouteIds.concat(route.routeId));
                                                //         setZoom(zoom => zoom.concat(route.routeId));
                                                //     }
                                                // }}
                                                // focusStop={focusStop}
                                                // setFocusStop={setFocusStop}
                                                // handleStopClick={(stop) => setSelectedStop(stop)}
                                                // setFilter={setFilter}
                                                // filter={filter}
                                                // clearFn={onClear}
                                            />
                                            <span className="close-map" onClick={(e) => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                setShowMap(false)
                                            }}><CloseOutlined/></span>
                                        </div>
                                    </Col>
                                </Row>
                            </>
                        ))}
                    </Col>
                    <Col xs={12} className="RouteListContainer">
                        <Row>
                            <Col>
                                <div className={'text-center mt-2'}><span className={'stop-times-explainer'}>Estimated stop times are in italics</span>
                                </div>
                            </Col>
                        </Row>
                        <Row key={"TripPlannerTripContainerPrint"} className={'trip-wrap'} style={{
                            margin: '15px 0',
                            borderTop: '1px solid #EBEBEB',
                            borderBottom: '1px solid #EBEBEB'
                        }}>
                            <div className={'TripContainer'}>
                                <Row key={`TripPlannerOverviewContainer-${route.routeId}`}>
                                    <Col key={`TripPlannerLegContainer-${route.routeId}`}
                                         style={{textAlign: "center"}}
                                    >
                                        {tripPlan.type === 'direct' ?
                                            <DirectTripOverview tripPlan={tripPlan} setShowMap={setShowMap}/> :
                                            <TripOverview tripPlan={tripPlan} setShowMap={setShowMap}/>}
                                        <div className={'trip-expanded-wrap'}>

                                            {tripPlan.legs.slice(1, -1).map((leg, idx) => {
                                                    let nextTrip;
                                                    if (leg.route?.services?.length) {
                                                        nextTrip = timeFilter.type === TimeFilterType.ARRIVING ? last(leg.route.services) : leg.route.services[0];
                                                    }
                                                    const lastLeg = idx === tripPlan.legs.length - 1
                                                    return leg.type === 'route' ?
                                                        <div className={'trip-expanded-block'}
                                                             key={`trip-expand-block-${compositeRouteId}-${leg.route.routeId}`}>
                                                            <Row
                                                                className={'trip-expanded-change-block'}>
                                                                <Col xs={1}
                                                                     className={`pl-0 trip-expanded-time-block ${nextTrip.getStopTime(leg.route.getStartStop()).timingPoint ? 'TimingPoint' : ''}`}>{getDepartureTime(nextTrip, nextTrip.getStopTime(leg.route.getStartStop()))}</Col>
                                                                <Col xs={11}><DisplayStop
                                                                    sequence={leg.route.getStartStopSequence()}
                                                                    stop={leg.route.getStartStop()}
                                                                    noWarning
                                                                    noRoutes
                                                                    noInterchange/></Col>
                                                                <div
                                                                    className={'trip-expanded-depart'}>Depart
                                                                </div>
                                                            </Row>
                                                            <Row
                                                                className={'trip-expanded-line-row'}>
                                                                <Col xs={1}></Col>
                                                                <Col xs={11}>
                                                                    <div
                                                                        className={'trip-expanded-line'}/>
                                                                </Col>
                                                            </Row>
                                                            <>
                                                                {leg.route.getStopsBetween(nextTrip).map(stop => {
                                                                    stop.timingPoint && console.log('Stop is timingPOINT!!')
                                                                    return (
                                                                        <div key={'PrintRow_' + stop.stopTimeId}>
                                                                            <Row>
                                                                                <Col xs={1}
                                                                                     className={`pl-0 trip-expanded-time-block ${nextTrip.getStopTime(stop).timingPoint ? 'TimingPoint' : ''}`}>{getDepartureTime(nextTrip, nextTrip.getStopTime(stop))}</Col>
                                                                                <Col xs={11}>
                                                                                    <DisplayStop
                                                                                        stop={stop}
                                                                                        noWarning
                                                                                        noRoutes
                                                                                        noInterchange/>
                                                                                </Col>
                                                                            </Row>
                                                                            <Row
                                                                                className={'trip-expanded-line-row'}>
                                                                                <Col xs={1}></Col>
                                                                                <Col xs={11}>
                                                                                    <div
                                                                                        className={'trip-expanded-line'}/>
                                                                                </Col>
                                                                            </Row>
                                                                        </div>
                                                                    )
                                                                })}
                                                            </>
                                                            <Row>
                                                                <Col xs={1}
                                                                     className={`pl-0 trip-expanded-time-block ${nextTrip.getStopTime(leg.route.getEndStop()).timingPoint ? 'TimingPoint' : ''}`}>{getDepartureTime(nextTrip, nextTrip.getStopTime(leg.route.getEndStop()))}</Col>
                                                                <Col xs={11}>
                                                                    <DisplayStop
                                                                        sequence={leg.route.getEndStopSequence()}
                                                                        stop={leg.route.getEndStop()}
                                                                        noWarning
                                                                        noRoutes
                                                                        noInterchange/></Col>
                                                                <div
                                                                    className={'trip-expanded-arrive'}>Arrive
                                                                </div>
                                                            </Row>
                                                        </div>
                                                        : leg.type !== 'direct' ?
                                                            <>
                                                                <Row className={"route-change-bus-row"}>
                                                                    <Col xs={12}>
                                                                        <div
                                                                            className={"d-flex flex-row justify-content-center align-items-center"}>
                                                                            <div
                                                                                className="route-change-bus-icon">
                                                                                {leg.type === 'transfer' && leg.duration > 0 ? renderConnectionImg('walking') :
                                                                                    <Image
                                                                                        src={`https://prod-rm-web-infra-gpx-s3-gpxfiles47af3947-1f2jzq8rsjwfq.s3.ap-southeast-2.amazonaws.com/public/${capitalize(leg.type)}.svg`}
                                                                                        width="24"
                                                                                        height="24"/>
                                                                                }
                                                                            </div>
                                                                            <div
                                                                                className={"ml-2 d-flex flex-row justify-content-start align-items-center route-change-bus-info"}>
                                                                                {leg.type === 'drive' ? 'Drive' : leg.type === 'walking' || (leg.type === 'transfer' && leg.duration > 0) ? `Walk to stop ${tripPlan.legs[idx + 2].route.getStartStop().stopName} and transfer to` : 'Transfer to '}
                                                                                <div
                                                                                    className={"ml-2"}>{tripPlan.legs[idx + 2].route ?
                                                                                    <RouteNumber
                                                                                        route={tripPlan.legs[idx + 2].route}
                                                                                        size={'sm'}/> : tripPlan.legs[idx + 2].special}</div>
                                                                            </div>
                                                                            <div className={"ml-2"}>
                                                                                {leg.type !== 'transfer' && <>
                                                                                    {toKmMs(leg.distance, 0)} to {lastLeg ? toValue : 'stop ' + tripPlan.legs[idx + 2].route.getStartStop().stopName}
                                                                                </>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </>
                                                            : <></>
                                                }
                                            )}
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Row>
                    </Col>
                </Row>
            </div>
        </Modal>
    );
}

export default React.memo(PrintTripModal)
