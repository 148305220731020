import React, {useEffect, useMemo, useState} from "react";
import {Modal, Button} from "antd";
import dayjs from "../dayjs";
import {noop} from "lodash/util";
import util from "util";
import {Collapse} from "antd/lib";
import {CopyToClipboard} from "react-copy-to-clipboard/lib/Component";

export default function FaultModal({
                                       feature,
                                       type,
                                       title,
                                       message,
                                       error,
                                       data,
                                       onClick = noop,
                                       onCancel,
                                       visible,
                                       setVisible
                                   }) {

    const MODEL_CONTENT_TYPES = useMemo(() => ({
        subscription: {
            title: "Subscription Required",
            content:
                <>
                    <p>We're sorry to interrupt your work.</p>
                    <p>A '{feature?.name}' subscription is required for you to use this feature.</p>
                    <p>
                        Please <Button className="btn-secondary"
                                       variant="link"
                                       href={`mailto:support@busable.app?subject=Busable%20subscription%20enquiry&body=${encodeURIComponent(`Hi,\n\nI would like to enquire about your ${feature?.name} subscription.\n\nThanks`)}`}>contact
                        us</Button> for more information.
                    </p>
                </>,
            onClick: onCancel,
        },
        trial: {
            title: "30 day free trial",
            content: <>Would you like to start your 30 day free trial of {feature?.name}?</>,
            onClick: () => {
                setIsLoading(true)
                onClick().then(() => setIsLoading(false))
            },
            ok: 'Start trial'
        },
        refresh: {
            title: "Refresh required",
            content: <>Your region has been updated. Please <Button variant="link"
                                                                    onClick={() => window.location.reload()}>refresh</Button> to
                update your session.</>,
            onClick: () => window.location.reload(),
            closeable: false,
            ok: 'Refresh'
        },
        loggedOut: {
            title: "Refresh required",
            content: <>Your session has been closed because you have logged in on another
                device.
                Please <Button variant="link" className="btn-secondary"
                               onClick={() => window.location.reload()}>refresh</Button> to log back in.</>,
            closeable: false,
            onClick: () => window.location.reload(),
            ok: 'Refresh'
        },
        offline: {
            title: 'Offline',
            content: "You do not seem to be connected to the internet. Please check your internet connection before continuing."
        },
        none: {
            title: 'No issues.',
            content: 'Everything seems to be running smoothly.'
        },
        saveRouteError: {
            title: "We are really sorry",
            content: <>
                <p>There was an issue saving your changes. Please
                    <Button
                        type="link"
                        href={`mailto:support@busable.app?subject=Busable%20error%20message%20${encodeURIComponent(dayjs().format("lll"))}&body=${error}${encodeURIComponent('\n\n')}${data ? JSON.stringify(data) : ''}`}
                    >contact support </Button>
                    with the below error.</p>
                {error && data ?
                    <Collapse items={[{key: 'error', label: error, children: <p>{JSON.stringify(data)}</p>}]}/> :
                    <p>{error}</p>}
            </>
        }
    }), [feature, error, onCancel, onClick]);

    const [faultState, setFaultState] = useState(MODEL_CONTENT_TYPES.subscription);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setFaultState(title?.length && message?.length ? {title, content: message} : MODEL_CONTENT_TYPES[type]);
        if (!type || type === 'none') {
            return setVisible(false);
        }
        setVisible(true)
    }, [setFaultState, setVisible, type, title, message, MODEL_CONTENT_TYPES])

    const footer = [
        <Button key={feature + "sub-ok"} loading={isLoading} className="btn-primary"
                onClick={() => {
                    faultState.onClick && faultState.onClick();
                    setVisible(false)
                }}>{faultState.ok || 'Ok'}</Button>]

    if (data) {
        footer.push(<CopyToClipboard
            text={JSON.stringify(data)}>
            <span className=''><Button>Copy error</Button></span>
        </CopyToClipboard>)
    }

    return (
        <>{faultState &&
            <Modal
                open={visible}
                maskClosable={false}
                onCancel={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setVisible(false)
                    onCancel && onCancel()
                }}
                destroyOnClose
                footer={footer}
                {...faultState}
            >
                {faultState.content}
            </Modal>}
        </>
    );

}


