import React, {useEffect, useState} from 'react';
import {Avatar, Button, Modal, Tag, Row, Col, Tooltip, Input, Radio} from 'antd';
import './Rosters.css';

const unallocated = {
    "name": "Unallocated",
    "staff": [""],
    "vehicle": "",
    "duties": [
        {
            "day": "Monday",
            "time": "07:00 - 09:00",
            "staff": "CH01",
            "type": "unallocated",
            "id": 1
        },
        {
            "day": "Monday",
            "time": "07:00 - 09:00",
            "staff": "CH02",
            "type": "unallocated",
            "id": 2
        },
        {
            "day": "Tuesday",
            "time": "07:00 - 09:00",
            "staff": "CH04",
            "type": "unallocated",
            "id": 3
        },
        {
            "day": "Tuesday",
            "time": "07:00 - 09:00",
            "staff": "CH05",
            "type": "unallocated",
            "id": 4
        },
        {
            "day": "Wednesday",
            "time": "09:20 - 11:00",
            "staff": "CH06",
            "type": "unallocated",
            "id": 5
        },
        {
            "day": "Saturday",
            "time": "09:20 - 11:00",
            "staff": "CH06",
            "type": "unallocated",
            "key": 0,
            "id": 6
        },
        {
            "day": "Sunday",
            "time": "09:20 - 11:00",
            "staff": "CH06",
            "type": "unallocated",
            "key": 0,
            "id": 7
        }
    ]
};

const initialRosters = [
    {
        "id": 0,
        "name": "Roster 1",
        "staff": ["Jane Smith"],
        "vehicle": "MO1111",
        "duties": [
            {
                "day": "Monday",
                "time": "07:00 - 09:00",
                "staff": "JS",
                "type": "shiftbat",
                "id": 8
            },
            {
                "day": "Monday",
                "time": "15:00 - 17:00",
                "staff": "JS",
                "type": "charter",
                "id": 9
            },
            {
                "day": "Tuesday",
                "time": "07:00 - 09:00",
                "staff": "JS",
                "type": "shiftbat",
                "id": 10
            },
            {
                "day": "Tuesday",
                "time": "15:00 - 17:00",
                "staff": "JS",
                "type": "charter",
                "id": 11
            },
            {
                "day": "Wednesday",
                "time": "07:00 - 09:00",
                "staff": "JS",
                "type": "shiftbat",
                "id": 12
            },
            {
                "day": "Wednesday",
                "time": "15:00 - 17:00",
                "staff": "JS",
                "type": "charter",
                "id": 13
            },
            {
                "day": "Thursday",
                "time": "07:00 - 09:00",
                "staff": "JS",
                "type": "shiftbat",
                "id": 14
            },
            {
                "day": "Thursday",
                "time": "15:00 - 17:00",
                "staff": "JS",
                "type": "charter",
                "id": 15
            },
            {
                "day": "Friday",
                "time": "07:00 - 09:00",
                "staff": "JS",
                "type": "shiftbat",
                "id": 16
            },
            {
                "day": "Friday",
                "time": "15:00 - 17:00",
                "staff": "JS",
                "type": "charter",
                "id": 17
            }
        ]
    },
    {
        "id": 1,
        "name": "Roster 2",
        "staff": ["Peter Pan", "Paul Jones"],
        "vehicle": "MO2222",
        "duties": [
            {
                "day": "Monday",
                "time": "07:00 - 09:00",
                "staff": "PP",
                "type": "shiftbat",
                "id": 18
            },
            {
                "day": "Monday",
                "time": "15:00 - 17:00",
                "staff": "PP",
                "type": "charter",
                "id": 19
            },
            {
                "day": "Tuesday",
                "time": "07:00 - 09:00",
                "staff": "PP",
                "type": "shiftbat",
                "id": 20
            },
            {
                "day": "Tuesday",
                "time": "15:00 - 17:00",
                "staff": "PP",
                "type": "charter",
                "id": 21
            },
            {
                "day": "Wednesday",
                "time": "07:00 - 09:00",
                "staff": "PP",
                "type": "shiftbat",
                "id": 22
            },
            {
                "day": "Wednesday",
                "time": "15:00 - 17:00",
                "staff": "PP",
                "type": "charter",
                "id": 23
            },
            {
                "day": "Thursday",
                "time": "07:00 - 09:00",
                "staff": "PP",
                "type": "shiftbat",
                "id": 24
            },
            {
                "day": "Thursday",
                "time": "15:00 - 17:00",
                "staff": "PP",
                "type": "charter",
                "id": 25
            },
            {
                "day": "Friday",
                "time": "07:00 - 09:00",
                "staff": "PP",
                "type": "shiftbat",
                "id": 26
            },
            {
                "day": "Friday",
                "time": "15:00 - 17:00",
                "staff": "PP",
                "type": "charter",
                "id": 27
            },
            {
                "day": "Tuesday",
                "time": "09:20 - 11:00",
                "staff": "PJ",
                "type": "shiftbat",
                "id": 28
            },
            {
                "day": "Tuesday",
                "time": "15:00 - 17:00",
                "staff": "PJ",
                "type": "charter",
                "id": 29
            }
        ]
    },
    {
        "id": 2,
        "name": "Roster 3",
        "staff": ["Unassigned"],
        "vehicle": "MO1234",
        "duties": [
            {
                "day": "Monday",
                "time": "07:00 - 09:00",
                "staff": "N/A",
                "type": "shiftbat",
                "id": 30
            },
            {
                "day": "Monday",
                "time": "15:00 - 17:00",
                "staff": "N/A",
                "type": "charter",
                "id": 31
            },
            {
                "day": "Tuesday",
                "time": "07:00 - 09:00",
                "staff": "N/A",
                "type": "shiftbat",
                "id": 32
            },
            {
                "day": "Tuesday",
                "time": "15:00 - 17:00",
                "staff": "N/A",
                "type": "charter",
                "id": 33
            },
            {
                "day": "Wednesday",
                "time": "07:00 - 09:00",
                "staff": "N/A",
                "type": "shiftbat",
                "id": 34
            },
            {
                "day": "Wednesday",
                "time": "15:00 - 17:00",
                "staff": "N/A",
                "type": "charter",
                "id": 35
            },
            {
                "day": "Thursday",
                "time": "07:00 - 09:00",
                "staff": "N/A",
                "type": "shiftbat",
                "id": 36
            },
            {
                "day": "Thursday",
                "time": "15:00 - 17:00",
                "staff": "N/A",
                "type": "charter",
                "id": 37
            },
            {
                "day": "Friday",
                "time": "07:00 - 09:00",
                "staff": "N/A",
                "type": "shiftbat",
                "id": 38
            },
            {
                "day": "Friday",
                "time": "15:00 - 17:00",
                "staff": "N/A",
                "type": "charter",
                "id": 39
            }
        ]
    }
];

const HEADERS = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

const WeeklyRosters2 = ({
                           isLoading,
                           setLoading,
                           startDate,
                           endDate,
                           showUnallocated,
                           setUnAllocatedCount
                       }) => {

    const [rosters, setRosters] = React.useState(initialRosters);
    const [unallocatedRosters, setUnallocatedRosters] = React.useState(unallocated);
    const [selectedRoster, setSelectedRoster] = React.useState(null);
    const [selectedUnallocated, setSelectedUnallocated] = React.useState(null);


    useEffect(() => {
        if (unallocatedRosters) {
            setLoading(false);
            setUnAllocatedCount(unallocatedRosters.duties.length)
        }
    }, [unallocatedRosters]);


    const onUnAllocatedItemSelect = (duty) => {
        const isSameItemSelected = duty && selectedUnallocated.key === duty.key;
        if (!isSameItemSelected) {
            const newRosters = rosters.map((roster, index) => {
                return {
                    ...roster,
                    shifts: [...roster.shifts, duty]
                }
            })
            setRosters(newRosters)
            setSelectedUnallocated(duty)
        } else {
            setRosters(initialRosters)
            setSelectedUnallocated(null)
        }
    }

    const onRosterItemSelect = (duty) => {
        const isSameItemSelected = duty && selectedRoster.key === duty.key;
        setSelectedRoster(isSameItemSelected ? null : duty)
    }

    const [showSelectEmployee, setShowSelectEmployee] = useState(false);
    const [showSelectVehicle, setShowSelectVehicle] = useState(false);

    return (
        <>
            <div style={{flexGrow: 1}}>
                <Row>
                    <Col span={3} className='roster-header'></Col>
                    {/* <Col span={1} className='roster-header'>Roster</Col>
                <Col span={1} className='roster-header'>Staff</Col>
                <Col span={1} className='roster-header'>Vehicle</Col> */}
                    {HEADERS.map((value) => (
                        <Col span={3} key={value} className='roster-header'>{value}</Col>
                    ))}
                </Row>
                {showUnallocated && unallocatedRosters &&
                    <UnAllocatedRosterRow unallocatedRosters={unallocatedRosters}
                                          onUnAllocatedItemSelect={onUnAllocatedItemSelect}/>
                }
                {rosters.map((roster) => (
                    <RosterRow roster={roster}
                               showSelectEmployee={showSelectEmployee}
                               setShowSelectEmployee={setShowSelectEmployee}
                               showSelectVehicle={showSelectVehicle}
                               setShowSelectVehicle={setShowSelectVehicle}
                               onRosterItemSelect={onRosterItemSelect}/>
                ))}
            </div>
            <SelectEmployeeView
                isOpen={showSelectEmployee}
                onClose={() => {
                    setShowSelectEmployee(false);
                }}
            />x
            <SelectVehicleView
                isOpen={showSelectVehicle}
                onClose={() => {
                    setShowSelectVehicle(false);
                }}
            />
        </>
    );
};

const RosterRow = ({
                       roster,
                       onRosterItemSelect,
                       showSelectEmployee,
                       setShowSelectEmployee,
                       showSelectVehicle,
                       setShowSelectVehicle
                   }) => {
    return (
        <Row key={roster.id}>
            <Col span={3} className="d-flex flex-column roster-cell-main" style={{gap: 8}}>
                <div className="roster-name">{roster.name} <span>(38H)</span></div>
                <div className="d-flex" style={{gap: 6}}>
                    {roster.staff && roster.staff.map((staffName, index) => (
                        <div key={index} className="roster-staff" onClick={() => {
                            setShowSelectEmployee(!showSelectEmployee);
                        }}>
                            <Tooltip title={`${staffName} (38H)`} placement='bottom'>
                                <Avatar size={24} style={{backgroundColor: '#C1C5CE'}}>JJ</Avatar>
                            </Tooltip>
                            <div className="roster-name"><span style={{fontSize: '12px'}}>38H</span></div>
                        </div>
                    ))}
                </div>
                <div className="roster-vehicle" style={{backgroundColor: '#007BFF'}} onClick={() => {
                    setShowSelectVehicle(!showSelectVehicle);
                }}>{roster.vehicle}</div>
            </Col>
            {['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].map((day) => (
                <RosterItems day={day} roster={roster} onRosterItemSelect={onRosterItemSelect}/>
            ))}
        </Row>
    )
}

const BearingMarker = ({rotation}) => {
    return (
        <div className="VehicleBearing">
            <div className="VehicleBearingMarker" style={{transform: `rotate(${rotation}deg)`}}></div>
        </div>
    );
};

const RosterItems = ({day, roster, onRosterItemSelect, statusColor = '#007BFF'}) => {
    return (
        <Col span={3} key={day} className="roster-cell">
            {roster.duties
                .filter((duty) => duty.day === day)
                .map((duty, idx) => (
                    <div key={idx} className={`roster-item shift ${duty.type}`} onClick={() => {
                        onRosterItemSelect(duty)
                    }}>
                        {/* <div className="d-flex align-items-center" style={{gap: 8}}>
                            <Avatar size={30} style={{backgroundColor: statusColor}}>{duty.staff}</Avatar>
                            <div className="roster-vehicle">{roster.vehicle}</div>
                        </div> */}
                        <div className="row RouteTitle flex-nowrap align-items-center w-100 py-0">
                            <div className={`RouteLogo`}>
                                <Avatar style={{backgroundColor: statusColor, verticalAlign: 'middle'}} size={40}
                                        src="https://thumbs.dreamstime.com/b/smiling-middle-aged-s-man-looking-camera-home-headshot-portrait-handsome-single-standing-happy-satisfied-confident-senior-208083425.jpg"
                                >JJ</Avatar>
                                <BearingMarker rotation={90}/>
                            </div>
                            <div className="RouteNumber" style={{backgroundColor: statusColor}}>{roster.vehicle}</div>
                        </div>
                        <div>
                            <div className="roster-name mb-1">AM01</div>
                            <div className="roster-time">{duty.time}</div>
                        </div>
                    </div>
                ))}
        </Col>
    )
};

const UnAllocatedRosterRow = ({unallocatedRosters, onUnAllocatedItemSelect}) => {
    return (
        <Row>
            <Col span={3} className="d-flex flex-column roster-cell-main" style={{gap: 8}}>
                <div className="roster-name">Unallocated<br/> Rosters</div>
            </Col>
            {['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].map((day) => (
                <Col span={3} key={day} className="roster-cell">
                    {unallocatedRosters.duties
                        .filter((duties) => duties.day === day)
                        .map((duty, idx) => (
                            <div key={idx} className={`roster-item item-unallocated`} onClick={() => {
                                onUnAllocatedItemSelect(duty)
                                // alert(JSON.stringify(shift));
                            }}>
                                <Avatar size={40} style={{backgroundColor: '#C1C5CE'}}>{duty.staff}</Avatar>
                                <div className="roster-time">{duty.time}</div>
                            </div>
                        ))}
                </Col>
            ))}
        </Row>
    )
}

const SelectEmployeeView = ({isOpen, onClose}) => {
    const [selectedStaff, setSelectedStaff] = useState(null);
    const [searchTerm, setSearchTerm] = useState("");

    const availableStaff = [
        {id: "js", name: "Jane Smith", hours: "38hrs"},
        {id: "pp", name: "Peter Pan", hours: "30hrs"},
        {id: "pj", name: "Paul Jones", hours: "8hrs"},
        {id: "kk", name: "Kath Kelly", hours: ""}
    ];

    const unavailableStaff = [
        {id: "ah", name: "Avi Hewa", status: "On leave"},
        {id: "sp", name: "Stefan Pear", status: "In training"}
    ];

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
    };

    const filteredStaff = availableStaff.filter((staff) =>
        staff.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    return (
        <>
            <Modal
                open={isOpen}
                afterOpenChange={open => {}}
                width={600}
                style={{top: 50}}
                title={<>Staff Allocation <span className="date"></span></>}
                onCancel={onClose}
                onClose={onClose}
                closable={true}
                destroyOnClose
                footer={[
                    <Button className="btn-secondary" key="" onClick={onClose}>Cancel</Button>,
                    <Button key="" type="primary" className="btn-primary" onClick={{}}>Allocate</Button>
                ]}
            >
                <div className="item-allocation">
                    <Input type="text" placeholder="Search staff" value={searchTerm} onChange={handleSearch} />
                    <div className="item-list">
                        {filteredStaff.map((staff) => (
                            <div key={staff.id} className="item-single">
                                <Radio name="staff" onChange={() => setSelectedStaff(staff)} checked={selectedStaff?.id === staff.id}>
                                    <div className="d-flex align-items-center" style={{gap: '8px'}}>
                                        <Avatar size={30} style={{backgroundColor: '#C1C5CE'}}>{staff.id.toUpperCase()}</Avatar>
                                        <span className="staff-name">{staff.name}</span>
                                    </div>
                                    <span className="staff-time">{staff.hours}</span>
                                </Radio>
                            </div>
                        ))}
                    </div>
                    <h6 className='mt-4 mb-0' style={{color: '#777', fontWeight: 600}}>Un-available Staff</h6>
                    <div className="item-list pt-3 pb-2">
                        {unavailableStaff.map((staff) => (
                            <div key={staff.id} className="item-unavailable">
                                <div className="d-flex align-items-center" style={{gap: '8px'}}>
                                    <Avatar size={30} style={{backgroundColor: '#C1C5CE'}}>{staff.id.toUpperCase()}</Avatar>
                                    <span className="staff-name">{staff.name}</span>
                                </div>
                                <span className="staff-status simple-tags"><Tag color="success">{staff.status}</Tag></span>
                            </div>
                        ))}
                    </div>
                </div>
            </Modal>
        </>
    )
};

const SelectVehicleView = ({isOpen, onClose}) => {
    const [searchTerm, setSearchTerm] = useState('');

    const availableVehicles = [
        {id: 'MO1234'},
        {id: 'MO1234'},
    ];

    const unavailableVehicles = [
        {id: 'MO1234', reason: 'Service maintenance'},
        {id: 'MO1234', reason: 'On loan to Operator XYZ'},
    ];

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const handleAllocate = () => {
        alert("Vehicles Allocated");
    };

    return (
        <Modal
            open={isOpen}
            afterOpenChange={open => {}}
            width={600}
            style={{top: 50}}
            title={<>Vehicle Allocation <span className="date"></span></>}
            onCancel={onClose}
            onClose={onClose}
            closable={true}
            destroyOnClose
            footer={[
                <Button className="btn-secondary" key="" onClick={onClose}>Cancel</Button>,
                <Button key="" type="primary" className="btn-primary" onClick={handleAllocate}>Allocate</Button>
            ]}
        >
            <div className="item-allocation">
                <Input type="text" placeholder="Search vehicle" value={searchTerm} onChange={handleSearchChange} />
                <div className="item-list">
                    {availableVehicles.map((vehicle, index) => (
                        <div key={index} className="item-single">
                            <Radio name="vehicle">
                                <span className="vehicle-name">{vehicle.id}</span>
                            </Radio>
                        </div>
                    ))}
                </div>
                <h6 className='mt-4 mb-0' style={{color: '#777', fontWeight: 600}}>Un-available Vehicles</h6>
                <div className="item-list pt-3 pb-2">
                    {unavailableVehicles.map((vehicle, index) => (
                        <div key={index} className="item-unavailable">
                            <span className="vehicle-name" style={{opacity: '0.7'}}>{vehicle.id}</span>
                            <span className="staff-status simple-tags"><Tag color="error">{vehicle.reason}</Tag></span>
                        </div>
                    ))}
                </div>
            </div>
        </Modal>
    );
};


export default React.memo(WeeklyRosters2);