import React, {useCallback, useState} from "react";
import "./Export.css";
import {Button,} from "antd";
import {Select} from "antd";

import LoaderButton from "./LoaderButton";
import RouteTitle from "./RouteTitle";
import DisplayStop from "./DisplayStop";
import {ButtonGroup, Col, Row} from "react-bootstrap";

const {Option} = Select;

function TransferView({stop, allStops, selectedStops, handleSaveTransfer, cancelTransferStop}) {
    const [isSaving, setIsSaving] = useState(false);
    const [fromRoute, setFromRoute] = useState(null);
    const [toStop, setToStop] = useState(null);
    const [toRoute, setToRoute] = useState(null);

    // const validateForm = useCallback(() => {
    //     return fromRoute && toRoute && toStop
    // }, [fromRoute, toRoute, toStop]);

    const handleSubmit = useCallback(async (event) => {
        event.preventDefault();
        setIsSaving(true);
        try {
            const {stopId, distance, duration, waypoints} = toStop;
            fromRoute.transfers = fromRoute.transfers || [];
            const transfer = {fromStopId: stop.stopId, toStopId: stopId, distance, duration, waypoints, toRouteId: toRoute.routeId, toRoute};
            console.log(toStop, transfer);
            fromRoute.transfers.push(transfer);
            await handleSaveTransfer(stop);
        } catch (err) {
            console.log(err);
        }
        setIsSaving(false);
    }, [setIsSaving, fromRoute, toRoute, stop, toStop, handleSaveTransfer]);

    return (
        <>
            {stop &&
                <div className="Transfer" style={{width: '100%'}}>
                    <div className="row">
                        <div className="col-lg-12">
                            <h6>Transfer from {stop.stopName}</h6>
                        </div>
                    </div>
                    {/*<div className="row">*/}
                    {/*    <div className="col-lg-12">*/}
                    {/*        <Select*/}
                    {/*            className="SchedulesDropdown"*/}
                    {/*            placeholder="Select a schedule"*/}
                    {/*            onChange={(scheduleId) => {*/}
                    {/*                setScheduleId(scheduleId)*/}
                    {/*                if (onScheduleChange) {*/}
                    {/*                    onScheduleChange(scheduleId);*/}
                    {/*                }*/}
                    {/*            }}*/}
                    {/*            value={scheduleId}*/}
                    {/*        >*/}
                    {/*            {schedules.map(schedule => (*/}
                    {/*                <Option key={`Schedules-${schedule.scheduleId}`}*/}
                    {/*                        value={schedule.scheduleId}>{schedule.scheduleName}</Option>)*/}
                    {/*            )}*/}
                    {/*        </Select>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    <div className="row">
                        <div className="col-lg-12">
                            <Select
                                size={'large'}
                                style={{width: '100%'}}
                                placeholder="Alighting route"
                                onChange={(value) => setFromRoute(stop.routes[stop.routes.findIndex(r => r.routeId === value)])}
                                value={fromRoute?.routeId}>
                                {
                                    stop?.routes?.map(route => (
                                        <Option value={route.routeId}><RouteTitle route={route}
                                                                                  size={'sm'}/></Option>))
                                }
                            </Select>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <Select
                                style={{width: '100%'}}
                                size={'large'}
                                disabled={!fromRoute}
                                placeholder={fromRoute ? "Boarding stop" : "Select alighting route first"}
                                onChange={(value) => {
                                    if (stop.stopId === value) {
                                        setToStop({...stop, distance: 0, duration: 0});
                                    } else {
                                        const linkedStop = stop.linkedStops[stop.linkedStops.findIndex(s => s.stopId === value)]
                                        const toStop = {...linkedStop.stop, ...linkedStop, stop: undefined};
                                        console.log(toStop)
                                        setToStop(toStop)
                                    }
                                }}
                                value={toStop?.stopId}>
                                {
                                    (stop.linkedStops?.map(ls => ls.stop) || []).concat(stop).map(stop => (
                                        <Option value={stop.stopId}><DisplayStop stop={stop} size={'sm'} noWarning
                                                                                 noRoutes noInterchange/></Option>))
                                }
                            </Select>

                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <Select
                                style={{width: '100%'}}
                                size={'large'}
                                disabled={!fromRoute || !toStop}
                                placeholder={fromRoute && toStop ? "Boarding route" : "Select alighting route and boarding stop first"}
                                onChange={(value) => setToRoute(toStop.routes[toStop.routes.findIndex(r => r.routeId === value)])}
                                value={toRoute?.routeId}>
                                {
                                    toStop?.routes?.filter(route => route.routeId !== fromRoute.routeId).map(route =>
                                        <Option value={route.routeId}><RouteTitle route={route} size={'sm'}/></Option>)

                                }
                            </Select>

                        </div>
                    </div>

                    <Row style={{marginLeft: 0}}>
                        <Col style={{textAlign: 'right'}}>
                            <ButtonGroup>
                                <Button size="sm"
                                        variant="success"
                                        style={{
                                            height: "28px",
                                            paddingTop: "0px",
                                        }}
                                        onClick={async (e) => {
                                            await cancelTransferStop(stop);
                                        }}>Cancel</Button>

                                <LoaderButton
                                    onClick={handleSubmit}
                                    size="sm"
                                    isLoading={isSaving}
                                    style={{
                                        height: "28px",
                                        paddingTop: "0px",
                                    }}
                                >
                                    Save
                                </LoaderButton>
                            </ButtonGroup>
                        </Col>
                    </Row>
                </div>
            }
        </>
    );
}

export default React.memo(TransferView);
