import React, {useCallback, useEffect, useMemo, useState} from 'react';
import './Charters.css';
import {charterModelData, routeModelData} from '../../services/ModelService';
import {Charter} from '../../model/charter';
import useModel from '../../hooks/useModel';
import SaveMenu from '../../components/SaveMenu';
import {Button, Col, Row, Tabs} from 'antd';
import CharterMenu from './CharterMenu';
import {BusRoute} from '../../model/busRoute';
import {PlusOutlined} from '@ant-design/icons';
import {ReactComponent as Close} from '../../assets/icons/Close.svg';
import {ulid} from 'ulid';
import EditRouteDetails from '../../components/EditRouteDetails';
import RoutesViewer from '../../components/RoutesViewer';
import useAllStops from '../../hooks/useAllStops';
import useAllRoutes from '../../hooks/useAllRoutes';
import useAllSchedules from '../../hooks/useAllSchedules';
import {chain, find, memoize} from 'lodash';
import {toKmMs} from '../../libs/formatLib';
import LoadMessage from '../../components/LoadMessage';
import {closestCenter, DndContext, PointerSensor, useSensor} from '@dnd-kit/core';
import {arrayMove, horizontalListSortingStrategy, SortableContext, useSortable,} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';
import {useDebounce} from 'rooks';

export const createAcronym = memoize((str) => {
    return chain(str)
        .words() // Split the string into words
        .map(word => word[0]) // Extract the first letter of each word
        .join('') // Combine the letters
        .toUpper() // Convert to uppercase
        .value() || '?'; // Get the final result
});

const editOnLoad = charter => !charter?.itinerary?.length;

function CharterItinerary({initialInstance}) {


    const {
        model: charter,
        setModel: setCharter,
        initialModelRef: initialCharterRef,
        mode,
        setMode,
        controls,
    } = useModel({
        modelService: charterModelData,
        initialInstance: initialInstance || new Charter({charterId: '_'}),
        editOnLoad
    });

    const {allStops, setAllStops} = useAllStops();
    const {allRoutes} = useAllRoutes();
    const {allSchedules} = useAllSchedules();
    const [selectedRouteId, setSelectedRouteId] = useState(null);

    const newRoute = (charter, legNum = 1) => {
        const route = new BusRoute({
            routeId: ulid(),
            routeNumber: createAcronym(charter.name) + '-' + legNum,
            routeName: 'Leg ' + legNum,
            routeDetails: charter.name,
            published: 1,
            charter: true
        });
        return route;
    };

    // useEffect(() => {
    //     if(charter.itinerary?.length) {
    //         setSelectedRouteId(id => {
    //             return id?.length ? id : charter.itinerary[0].routeId
    //         });
    //     }
    // }, [charter.itinerary, setSelectedRouteId]);

    useEffect(() => {
        if (!charter.itinerary?.length) {
            const route = newRoute(charter);
            setCharter(charter => new Charter({...charter, itinerary: [route]}));
            // setSelectedRouteId(route.routeId);
        }
    }, [charter.itinerary, setCharter, setSelectedRouteId]);

    const DraggableTabNode = ({className, ...props}) => {
        const {attributes, listeners, setNodeRef, transform, transition} = useSortable({
            id: props['data-node-key'],
        });
        const style = {
            ...props.style,
            transform: CSS.Translate.toString(transform),
            transition,
            cursor: 'move',
        };
        return React.cloneElement(props.children, {
            ref: setNodeRef,
            style,
            ...attributes,
            ...listeners,
        });
    };
    const sensor = useSensor(PointerSensor, {
        activationConstraint: {
            distance: 10,
        },
    });
    const onDragEnd = ({active, over}) => {
        if (active.id !== over?.id) {
            setCharter(charter => {
                const prev = charter.itinerary;
                const activeIndex = prev.findIndex((i) => i.routeId === active.id);
                const overIndex = prev.findIndex((i) => i.routeId === over?.id);
                return new Charter({...charter, itinerary: arrayMove(prev, activeIndex, overIndex)});
            });
        }
    };

    const itineraryValid = useCallback(() => {
        return charter.itinerary?.length && charter.itinerary.every(i => i.services?.length && i.stopTimes?.length && i.waypoints?.length);
    }, [charter.itinerary]);

    const setRouteHistory = useCallback((oldRoute, newRoute) => {
        if (typeof newRoute === 'function') {
            newRoute = newRoute(oldRoute);
        }
        console.log('Route update: ', newRoute);
        setCharter(charter => {
            return new Charter({
                ...charter,
                itinerary: charter.itinerary.map(route => {
                    if (route.routeId === newRoute.routeId) {
                        return newRoute;
                    }
                    return route;
                })
            });
        });
    }, [setCharter]);

    const setRouteHistoryDebounced = useDebounce(setRouteHistory, 500);

    const selectedRoute = useMemo(() => {
        if (!charter.itinerary?.length) {
            return null;
        }
        return selectedRouteId ? find(charter.itinerary, {routeId: selectedRouteId}) : charter.itinerary[0];
    }, [selectedRouteId, charter.itinerary]);

    return (
        <div className="charter-details w-secondary-menu">
            {charter.itinerary?.length ? (
                <>
                    <SaveMenu
                        save={async () => {
                            await routeModelData.save(charter.itinerary.map(route => route.clone()));
                            await charterModelData.save(charter);
                            setMode({edit: false});
                        }}
                        editMode={mode.edit}
                        id={charter.charterId}
                        setEditMode={(edit) => setMode({edit})}
                        controls={controls}
                        updated={controls.updated}
                        modelInstance={charter}
                        initialInstanceRef={initialCharterRef}
                        listUrl={`/charters`}
                        modelService={charterModelData}
                        validFn={itineraryValid}
                        closeView={true}
                    />
                    <Row gutter={[0, 20]} className="w-100">
                        {!mode.overview && (
                            <CharterMenu
                                charter={charter}
                                activeKey={'2'}
                                setCharter={setCharter}
                                disabled={{
                                    charter: mode.edit,
                                    itinerary: false,
                                    duty: mode.edit || !charter.itinerary?.length,
                                    quote: mode.edit || !charter.duties?.length,
                                }}
                            />
                        )}
                        <Col xs={24} lg={24}>
                            <Row gutter={[20, 20]}>
                                <Col xs={24}>
                                    {mode.edit && charter.itinerary?.length ?
                                        <Tabs hideAdd
                                              size={'middle'}
                                              onChange={setSelectedRouteId}
                                              activeKey={selectedRouteId || charter?.itinerary?.[0].routeId}
                                              type="card"
                                              renderTabBar={(tabBarProps, DefaultTabBar) => (
                                                  <DndContext sensors={[sensor]} onDragEnd={onDragEnd}
                                                              collisionDetection={closestCenter}>
                                                      <SortableContext items={charter.itinerary.map((i) => i.routeId)}
                                                                       strategy={horizontalListSortingStrategy}>
                                                          <DefaultTabBar {...tabBarProps}>
                                                              {(node) => (
                                                                  <DraggableTabNode {...node.props} key={node.key}>
                                                                      {node}
                                                                  </DraggableTabNode>
                                                              )}
                                                          </DefaultTabBar>
                                                      </SortableContext>
                                                  </DndContext>
                                              )}
                                              tabBarExtraContent={<div
                                                  className="d-flex align-items-center">
                                                  {mode.edit && charter.itinerary?.length &&
                                                      <Button onClick={() => {
                                                          if (!selectedRoute) {
                                                              return;
                                                          }
                                                          const route = selectedRoute.clone();
                                                          route.routeId = ulid();
                                                          setCharter(charter => new Charter({
                                                              ...charter,
                                                              itinerary: charter.itinerary.concat(route)
                                                          }));
                                                          setSelectedRouteId(route.routeId);
                                                      }} type="primary" className="icon-button mr-2"
                                                              icon={<PlusOutlined/>}>Copy</Button>}
                                                  {mode.edit &&
                                                      <Button onClick={() => {
                                                          let route = newRoute(charter, charter.itinerary.length);
                                                          setCharter(charter => new Charter({
                                                              ...charter,
                                                              itinerary: charter.itinerary.concat(route)
                                                          }));
                                                          setSelectedRouteId(route.routeId);
                                                      }} type="primary" className="icon-button mr-2"
                                                              icon={<PlusOutlined/>}>Add</Button>}
                                                  {mode.edit && charter.itinerary.length > 1 &&
                                                      <Button danger={true} onClick={() => {
                                                          const idx = charter.itinerary.findIndex(itinerary => itinerary.routeId === selectedRoute?.routeId);
                                                          const newItinerary = charter.itinerary.filter(itinerary => itinerary.routeId !== selectedRoute?.routeId);
                                                          setCharter(charter => new Charter({
                                                              ...charter,
                                                              itinerary: newItinerary
                                                          }));
                                                          setSelectedRouteId(idx >= newItinerary.length ? newItinerary[newItinerary.length - 1].routeId : newItinerary[idx].routeId);
                                                      }} type="primary"
                                                              className="icon-button btn-filled btn-error icon-10"
                                                              icon={<Close/>}>Remove</Button>}</div>}
                                              items={charter.itinerary.map(route => {
                                                  return {
                                                      key: route.routeId,
                                                      label: route.routeName + ` (${toKmMs(route.distance)})`,
                                                      children: <EditRouteDetails charter={charter}
                                                                                  allRoutes={allRoutes} allStops={allStops} setAllStops={setAllStops}
                                                                                  route={route}
                                                                                  setRouteHistory={newRoute => setRouteHistory(route, newRoute)}/>
                                                  };
                                              })}/> :
                                        <RoutesViewer
                                            routes={allRoutes}
                                            allSchedules={allSchedules}
                                            allStops={allStops}
                                            filteredRoutes={charter.itinerary}
                                            fetching={!allStops}
                                            verifiedOnly={false}
                                            allowComments={false}
                                            showComments={false}
                                            showWpSelector={true}
                                            expand={true}
                                        />}
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </>
            ) : (<LoadMessage message="Loading Charter Details..."/>)}
        </div>
    );
}

export default React.memo(CharterItinerary);
