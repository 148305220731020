import React, {useState} from 'react';
import {Button} from 'antd';
import {useAppContext} from '../libs/contextLib';
import fileDownload from 'js-file-download';
import {CloudDownloadOutlined} from '@ant-design/icons';
import AdmZip from 'adm-zip';
import dayjs from '../dayjs';

export default function DownloadButton({files, onDownloadClicked}) {
    const {messageApi} = useAppContext();
    const [downloading, setDownloading] = useState(false);

    const handleDownload = async () => {
        setDownloading(true);
        try {
            if (onDownloadClicked) {
                files = await onDownloadClicked();
            }
            let file = files[0];
            if (files?.length > 1) {
                file = new AdmZip();
                files.forEach((file) => {
                    file.addFile(file.name, file);
                });
                fileDownload(file.toBuffer(), `Busable_${dayjs().format('YYYY-MM-DD_HH:mm')}.zip`, 'application/zip');
            }
            fileDownload(file, file.name, file.type);
            // send event back
            setDownloading(false);
        } catch (err) {
            console.log('Something went wrong: ', err);
            messageApi.error('Something went wrong while downloading files: ', err);
            setDownloading(false);
        }
    };

    return (
        <>
            <Button type="primary" className="icon-button btn-filled" icon={<CloudDownloadOutlined/>}
                    loading={downloading} onClick={handleDownload}>
                Download
            </Button>
        </>
    );
}
