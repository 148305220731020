import React, {useCallback, useEffect, useState} from "react";
import {SyncOutlined} from "@ant-design/icons";
import {Button, Input} from "antd";
import {ReactComponent as Check} from "../../assets/icons/Check.svg";
import {ReactComponent as Close} from "../../assets/icons/Close.svg";
import ShiftBatSelectType from "./ShiftBatSelectType";
import ShiftBatSelectPriority from "./ShiftBatSelectPriority";
import {Col, Row} from "react-bootstrap";
import ShiftBatTimePicker from "./ShiftBatTimePicker";

//import {Space} from "antd/lib";

function ShiftBatNoteRowEdit({
                                 type, time, priority,
                                 description, title, onUpdate,
                                 updating, onCreate, hideTime,
                                 shiftBatRow, setTitle, setTime, setDescription,
                                 setPriority, isLoading, onCancel, resetRow, charter
                             }) {
    // eslint-disable-next-line
    const [options, setOptions] = useState([])

    useEffect(() => {
        if (!shiftBatRow?.notes) {
            return;
        }
        // Set the service options when allRoutes change
        const opts = Object.keys(shiftBatRow.notes).map(noteId => ({
            value: noteId,
            label: shiftBatRow.notes[noteId].title || 'New note'
        }))
        setOptions(opts);
    }, [shiftBatRow, setOptions])

    const isValid = useCallback(() => {
        return (hideTime || time > -1) && title?.length
    }, [hideTime, time, title])

    return (
        <>
            <div>
                <Row className="row-compact">
                    <Col md="auto">
                        {!hideTime && <ShiftBatTimePicker time={time} setTime={setTime} />}
                    </Col>
                    <Col className="row-col-gap">
                        <ShiftBatSelectType type={type} resetRow={resetRow}
                                            className={"inputselect-small"} />
                        <ShiftBatSelectPriority className="inputselect-small" priority={priority}
                                                setPriority={setPriority}/>
                        {/*<ShiftBatSelectRowEdit*/}
                        {/*    suffixIcon={!shiftBatRow?.loaded || isLoading ? <SyncOutlined spin={true}/> :*/}
                        {/*        <Down/>}*/}
                        {/*    // disabled={!shiftBatRow?.notes || !Object.keys(shiftBatRow.notes).length || isNew()}*/}
                        {/*    value={shiftBatRow?.refId}*/}
                        {/*    placeholder={"Select note"}*/}
                        {/*    className={"inputselect-small w-100"}*/}
                        {/*    onChange={noteId => {*/}
                        {/*        const newNote = shiftBatRow.notes[noteId]*/}
                        {/*        setTitle(newNote.title)*/}
                        {/*        setDescription(newNote.description)*/}
                        {/*        setPriority(newNote.priority)*/}
                        {/*    }}*/}
                        {/*    options={options}*/}
                        {/*    itemName="note"*/}
                        {/*    newPlaceholder="Note..."*/}
                        {/*    addNew={(e, title) => {*/}
                        {/*        if (title?.length) {*/}
                        {/*            setTitle(title)*/}
                        {/*            onCreate(e, {*/}
                        {/*                ...shiftBatRow,*/}
                        {/*                type,*/}
                        {/*                time: parseInt(time),*/}
                        {/*                title,*/}
                        {/*                description,*/}
                        {/*                priority,*/}
                        {/*            }, true)*/}
                        {/*        }*/}
                        {/*    }}*/}
                        {/*/>*/}
                        <Input placeholder="Note details" className="inputtext-small input-desc"
                               value={title || ""}
                               onChange={e => setTitle(e.target.value)}/>
                        <Input placeholder="Driver instructions" className="inputtext-small input-desc"
                               value={description || ""}
                               onChange={e => setDescription(e.target.value)}/>
                    </Col>
                    <Col md="auto">
                        <div className="sb-edit-controls">
                            <Button className="sb-done"
                                    icon={updating && shiftBatRow.needsUpdate() ? <SyncOutlined spin={true}/> :
                                        <Check/>}
                                    disabled={!isValid() || updating}
                                    onClick={e => onUpdate(e, {
                                        ...shiftBatRow,
                                        hideTime,
                                        type,
                                        time: parseInt(time),
                                        title,
                                        description,
                                        priority,
                                    })}/>
                            <Button className="sb-close"
                                    icon={updating && shiftBatRow.needsUpdate() ? <SyncOutlined spin={true}/> :
                                        <Close/>}
                                    disabled={updating} onClick={e => onCancel(e, shiftBatRow)}/>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    );
}

export default React.memo(ShiftBatNoteRowEdit)
