import {dayjsToSecsSinceMidnight, TimeFilterType} from "./timeFilter";

import log from 'loglevel';
import {find} from "lodash/collection";
const logger = log.getLogger('Transfers');

export class Transfer {

    constructor(props) {
        this.fromRouteNumber = null
        this.fromStopId = null
        this.fromTripId = null
        this.fromRouteId = null
        this.fromStopTimeId = null
        this.fromScheduleIds = [];
        this.fromStopPassingTImes = []
        this.toRouteNumber = null
        this.toStopId = null
        this.toTripId = null;
        this.toRouteId = null
        this.toScheduleIds = [];
        this.toStopTimeId = null
        this.toStopPassingTImes = []
        this.time = 0;
        this.window = 0;
        Object.assign(this, props)
    }

    isValidTransfer(routeFrom, stopOnFromRoute, routesTo, timeFilter, log) {
        if (routeFrom?.routeId && this.fromRouteId !== routeFrom.routeId) {
            log && logger.debug('Transfer fromRoute %s !== routeFrom %s', this.fromRouteNumber, routeFrom.routeNumber)
            return false
        }
        if (routesTo && !routesTo.map(r => r.routeId).includes(this.toRouteId)) {
            log && logger.debug('Transfer toRoute %s not in list of possible toRoutes %s', this.toRouteId, routesTo.map(r => r.routeNumber))
            return false
        }
        if(routeFrom?.services && routeFrom.services.findIndex(pt => pt.tripId === this.fromTripId) === -1) {
            log && logger.debug('Transfer fromTripId %s not in routeFrom passingTimes', this.fromTripId, routeFrom, routeFrom?.services)
            return false
        }
        // if(routesTo && routesTo.every(routeTo => routeTo?.passingTimes && routeTo.passingTimes.findIndex(pt => pt.tripId === this.toTripId) === -1)) {
         if(routesTo && routesTo.every(routeTo => {
            const passingTime = routeTo?.services && find(routeTo.services, pt => pt.tripId === this.toTripId)
            if (passingTime?.passingTime) {
                return passingTime.passingTime.id === this.toStopTimeId
            }
            return false
        })) {
            log && logger.debug('Transfer toTripId %s not in routeTos passingTimes', this.toTripId)
            return false
        }
        if (stopOnFromRoute?.stopId && this.fromStopId !== stopOnFromRoute.stopId) {
            log && logger.debug('Transfer fromStop %s !== stopOnFromRoute %s', this.fromStopId, stopOnFromRoute.stopId)
            return false
        }
        // log && logger.debug('Checking this %s -> %s in %s : %s @ %s', this.fromRouteNumber, this.toRouteNumber, this.schedule?.scheduleName, timeFilter.startTime.format('HH:mm'), stopOnFromRoute?.stopName)
        // if (timeFilter.anyDate ? !this.schedule?.isMonToFriOnly() : !this.schedule?.isActiveToday(timeFilter.startTime)) {
        //     log && logger.debug('Transfer from %s to %s in @ %s not active on date %s', this.fromRouteNumber, this.toRouteNumber, this.schedule?.scheduleName, timeFilter.startTime)
        //     return false
        // }

        // const startTimeAsSEcsSinceMidnight = dayjsToSecsSinceMidnight(timeFilter.startTime);
        // if (timeFilter.type === TimeFilterType.ARRIVING && this.time > startTimeAsSEcsSinceMidnight) {
        //     log && logger.debug('transfer time after filter time.')
        //     return false
        // }
        // log && logger.debug(this.time, startTimeAsSEcsSinceMidnight)
        // if ([TimeFilterType.LEAVING, TimeFilterType.NOW].includes(timeFilter.type) && this.time < startTimeAsSEcsSinceMidnight) {
        //     log && logger.debug('transfer time before filter time.')
        //     return false
        // }

        return true;
    }

}
