import {Switch, Tag, Tooltip, Space} from "antd";
import React, {useEffect} from "react";
//import {Col, Row} from "react-bootstrap";
import {noop, uniqBy, values} from "lodash";
import {debounce} from "lodash/function";

const defaultFilterItems = {
    Active: {name: 'Active', color: 'success', showFn: (r) => !r.future && !r.expired},
    // Diverted: {name: 'Diverted', color: '#faad14', showFn: (r) => r.diverted},
    NoTrips: {name: 'No trips', color: 'default', showFn: (r) => r.noTrips},
    Future: {name: 'Future', color: 'processing', showFn: (r) => r.future},
    Expired: {name: 'Expired', color: 'error', showFn: (r) => r.expired},
    Warning: {name: 'Validations', color: 'volcano', showFn: (r) => r.warning?.length},
    Daily: {name: 'Daily', color: 'default', showFn: (r) => r.daily?.length},
    Weekly: {name: 'Weekly', color: 'default', showFn: (r) => r.weekly?.length},
    Year: {name: 'Year', color: 'default', showFn: (r) => r.year?.length},
    Month: {name: 'Month', color: 'default', showFn: (r) => r.month?.length},
    Week: {name: 'Week', color: 'default', showFn: (r) => r.week?.length},
    Today: {name: 'Today', color: 'default', showFn: (r) => r.today?.length},
    Tomorrow: {name: 'Tomorrow', color: 'default', showFn: (r) => r.tomorrow?.length},
};

export function FilterTag({filterItem, model, setterFn}) {
    return (
        <Tag color={filterItem.color}
             closeIcon={!!filterItem.onClose}
             onClose={event => {
                 filterItem.onClose ? filterItem.onClose(event, model, setterFn) : noop()
             }}
        >{filterItem.name}</Tag>
    )
}

export function FilterTags({filterItems, model, setterFn}) {
    Object.keys(filterItems).forEach(key => {
        filterItems[key] = {...defaultFilterItems[key], ...filterItems[key]}
    })

    return (<Space size={[5, 5]} wrap className="justify-content-center">
        {values(filterItems).filter(item => !item.showFn || item.showFn(model)).map((item, idx) => {
            return (
                <span key={`filter-tag-col-${idx}`}>
                    {
                        item.name === 'Validations' && model.warning?.length ?
                            <Tooltip title={model.warning}><span><FilterTag filterItem={item}/></span></Tooltip> :
                            item.tooltipFn ? <Tooltip title={item.tooltipFn(model)}><span><FilterTag
                                    filterItem={item}/></span></Tooltip> :
                                <FilterTag filterItem={item} model={model} setterFn={setterFn}/>
                    }
                </span>
            )
        })}
    </Space>)
}

export function FilterSwitches({filterItems, filterBy, setFilterBy}) {

    Object.keys(filterItems).forEach(key => {
        filterItems[key].color = filterItems[key].color || defaultFilterItems[key].color
        filterItems[key].name = filterItems[key].name || defaultFilterItems[key].name
        filterItems[key].showFn = filterItems[key].showFn || defaultFilterItems[key].showFn
    })

    useEffect(() => {
        setFilterBy(Object.keys(filterItems).filter(key => filterItems[key].on === true));
    }, [filterItems, setFilterBy])

    return (<>
            {Object.keys(filterItems).map((key) => {
                const filterItem = filterItems[key]
                return (
                    <Switch key={`filter-switch-${filterItem.name}`}
                            style={{backgroundColor: filterBy.includes(key) ? filterItem.color : 'darkgrey'}}
                            checkedChildren={filterItem.name} unCheckedChildren={filterItem.name}
                            checked={filterBy.includes(key)}
                            onChange={checked => setFilterBy(filterBy => checked ? [...filterBy, key] : filterBy.filter(f => f !== key))}>{filterItem.name || "bla"}</Switch>)
            })
            }
        </>
    )
}


export const filterFn = (arr, filterItems, filterBy, idParam, baseSortFn) => {
    Object.keys(filterItems).forEach(key => {
        filterItems[key].showFn = filterItems[key].showFn || defaultFilterItems[key].showFn
    })
    if (!arr || !Array.isArray(arr)) {
        return arr;
    }
    if (!filterBy?.length) {
        return arr.sort(baseSortFn)
    }
    let result = []
    result = arr.filter(r => {
        return filterBy.every(filter => {
            return filterItems[filter].showFn(r)
        })
    })
    return uniqBy(result, idParam).sort(baseSortFn);
}

export const filterFnDebounced = debounce((arr, filterItems, filterBy, idParam, baseSortFn, callback) => {
    callback(filterFn(arr, filterItems, filterBy, idParam, baseSortFn))
}, 1000);
