import * as React from 'react';
import './RouteTitle.css';
import {Image} from "react-bootstrap";
import config from "../config";
import {hasLogo} from "../libs/routes-lib";
import {noop} from "lodash/util";
import {Link} from "react-router-dom";
import {Badge, Tooltip} from "antd";
import useComments from "../hooks/useComments";
import {useRef} from "react";

function RouteTitle({
                        size,
                        route,
                        colour,
                        link, meta,
                        tooltip,
                        editRouteNumber,
                        editRouteName,
                        editRouteDetails,
                        setUpdated = noop,
                        showUnreadBadge
                    }) {
    const chatContext = useRef({type: 'route', id: route?.routeId});
    const {unreadComments, comments} = useComments({chatContext: chatContext?.current})
    // const [allowComments] = useAllowComments();

    link = link && route?.routeId?.length
    const routeNumber = route?.routeNumber || ""
    const routeName = route?.routeName || ""
    const routeDetails = route?.routeDetails || ""
    const routeColour = colour || route?.colour || "#999"
    size = size || '';

    const div = <div className={`row RouteTitle flex-nowrap align-items-center w-100`}>
        <Badge
            color={unreadComments?.length ? "red" : "grey"}
            style={{display: showUnreadBadge && (unreadComments?.length || comments?.length) ? 'auto' : 'none'}} count={unreadComments?.length || comments?.length} size={"small"}>
            <div className={`RouteLogo${size.length ? `-${size}` : ''}`} style={{borderColor: routeColour}}>
                {hasLogo(route) ?
                    <Image
                        src={`https://${config.s3.BUCKET}.s3.ap-southeast-2.amazonaws.com/public/${route.routeLogo}`}/>
                    : <Image
                        src={'https://prod-rm-web-infra-gpx-s3-gpxfiles47af3947-1f2jzq8rsjwfq.s3.ap-southeast-2.amazonaws.com/public/Bus.svg'}
                        className="BusIcon"/>
                }
            </div>
        </Badge>

        <div className={`RouteNumber${size.length ? `-${size}` : ''}`} style={{backgroundColor: routeColour}}
             contentEditable={!!editRouteNumber} suppressContentEditableWarning
             onBlur={(e) => {
                 if (editRouteNumber && e.target.textContent !== routeNumber) {
                     editRouteNumber(e.target.textContent)
                     setUpdated(true);
                 }
             }}
        >{routeNumber}</div>
        <div className={`RouteInfo${size.length ? `-${size}` : ''} w-100 d-flex flex-column ml-3`}
             style={{gap: "4px"}}>
            <h1 style={{color: "#000"}}
                contentEditable={!!editRouteName} suppressContentEditableWarning
                onBlur={(e) => {
                    if (editRouteName && e.target.textContent !== routeName) {
                        editRouteName(e.target.textContent)
                        setUpdated(true);
                    }
                }}
            >{routeName}</h1>
            {size !== 'sm' &&
                <div className={`RouteDetails${size.length ? `-${size}` : ''}`}
                     contentEditable={!!editRouteDetails} suppressContentEditableWarning
                     onBlur={(e) => {
                         if (!e.target.textContent?.length) {
                             e.target.textContent = "via XYZ..."
                         } else if (editRouteDetails && e.target.textContent !== routeDetails) {
                             editRouteDetails(e.target.textContent)
                             setUpdated(true);
                         }
                     }}
                     onFocus={(e) => {
                         if (editRouteDetails && e.target.textContent === "via XYZ...") {
                             e.target.textContent = ""
                         }
                     }}
                >{routeDetails ? routeDetails : !!editRouteDetails ? "via XYZ..." : ""}</div>}
        </div>
    </div>

    return (
        link ?
            <Link to={"/services/" + route?.routeId} onClick={e => {
                if (!e.metaKey && meta) {
                    e.preventDefault();
                }
            }}>
                <Tooltip title={tooltip}>
                    {div}
                </Tooltip>
            </Link>
            :
            <Tooltip title={tooltip}>
                {div}
            </Tooltip>
    );
}

export default React.memo(RouteTitle);
