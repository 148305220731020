import Legs from "./Legs";
import React, {useEffect, useState} from "react";
import './TripOverview.css';
import {Button} from "react-bootstrap";
import {toHrsMinsSecs, toMins, toHrs, toTime} from "../libs/formatLib";

import {ReactComponent as Map} from '../assets/icons/Map.svg';
import dayjs from "../dayjs";

function TripOverview({tripPlan, setShowMap}) {

    const [update, setUpdate] = useState(0);
    const [daysDiff, setDaysDiff] = useState(0);
    const [dateOfDeparture, setDateOfDeparture] = useState(null);
    const [hrsTilDeparture, setHrsTilDeparture] = useState(0);
    const [minsTilDeparture, setMinsTilDeparture] = useState(0);

    useEffect(() => {
        const timer = setInterval(() => setUpdate(update => ++update), 60000);
        return () => clearInterval(timer);
    },[]);

    useEffect(() => {
        console.log('Updating time til departure...')
        if(tripPlan) {
            const today = dayjs().startOf('d');
            const nextDeparture = tripPlan.nextDepartureDate;
            const diff = nextDeparture.diff(today, 'day')
            setDaysDiff(diff)
            setDateOfDeparture(tripPlan.nextDepartureDate);
            setHrsTilDeparture(toHrs(tripPlan.secondsTilDeparture));
            setMinsTilDeparture(toMins(tripPlan.secondsTilDeparture));
        }
    }, [setHrsTilDeparture, setMinsTilDeparture, setDaysDiff, tripPlan, update])

    return (
        <div className="TripOverview">
            <div className="d-flex flex-row justify-content-left align-items-center">
                <div className="d-flex flex-column leaving-wrap">
                    <div className="leaving-text">Departing</div>
                    <div className="leaving-time">{daysDiff > 0 && daysDiff < 7 ? dateOfDeparture.format('ddd') : daysDiff > 6 ? dateOfDeparture.format('Do') : hrsTilDeparture >= 1 ? hrsTilDeparture : minsTilDeparture > 3 ? minsTilDeparture : 'NOW'}</div>
                    <div className="leaving-text">{daysDiff > 0 && daysDiff < 7 ? dateOfDeparture.format('Do MMM') :daysDiff > 6 ? dateOfDeparture.format('MMM YYYY') : hrsTilDeparture >= 1 ? `hour${hrsTilDeparture > 1 ? 's' : ''}` : minsTilDeparture > 3 ? `min${minsTilDeparture > 1 ? 's' : ''}` : ''}</div>
                </div>
                <div className="d-flex flex-column route-main-wrap">
                    <div className={`route-duration-wrap ${tripPlan.estimated ? 'font-italic' : ''}`}>
                        <div>
                            <strong>{toTime(tripPlan.departureTime)} - {toTime(tripPlan.arrivalTime)}</strong>
                            {/*// Added 60 seconds to duration, as we want to display the full start to end*/}
                            <span>({toHrsMinsSecs(tripPlan.duration, false, true)})</span>
                        </div>
                        <Button variant={"light"} className={'pill-btn show-map-btn'} size={"sm"} onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            setShowMap(true)
                        }}><Map /><span>View Map</span></Button>
                    </div>
                    {/*<div className="text-left small font-italic  EstimatedStopTimes">*/}
                    {/*    Estimated stop times*/}
                    {/*</div>*/}
                    <div className="d-flex flex-row">
                        <div><Legs tripPlan={tripPlan}/></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default React.memo(TripOverview);
