import React, {useEffect, useState} from "react";
import {stopModelData} from "../services/ModelService";
import {keyBy, values} from "lodash";
import {debounce} from 'lodash/function';

const useAllStops = () => {
    const [allStops, setAllStops] = useState(null);
    const [allDepots, setAllDepots] = useState(null);

    useEffect(() => {
        const stopsListener = stopModelData.addListener({
            setterFn: setAllStops, loaded: stops => {
                setAllStops(stops);
            }, refresh: debounce(() => {
                stopModelData.getAll().then(stopsById => setAllStops(stopsById));
            }, 1000)
        });

        return () => {
            if (stopsListener)
                stopModelData.removeListener(stopsListener)
        }
    }, [])

    useEffect(() => {
        if (allStops) {
            setAllDepots(keyBy(values(allStops).filter(stop => stop.stopType === 'depot'), 'stopId'));
        }
    }, [allStops, setAllDepots]);

    return {
        setAllStops,
        allStops,
        allDepots
    };
}

export default useAllStops;

