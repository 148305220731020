import config from '../config';
import util from 'util';
import {API, Auth} from 'aws-amplify';
import {BusRoute, DATE_STRING} from '../model/busRoute';
import {DateObject} from 'react-multi-date-picker';
import {chunk, flatten, uniqBy, values} from 'lodash';
import {Schedule} from '../model/schedule';
import {scheduleModelData, stopModelData} from './ModelService';
import Features from '../model/features';
import {ulid} from 'ulid';
import {Transfer} from '../model/transfer';
import {JourneyPlan} from '../model/journeyPlan';
import {cloneDeep} from 'lodash/lang';

import log from 'loglevel';
import {find} from 'lodash/collection';

import {reverseGeocode} from '../libs/mapLib';
import {Address} from '../model/student';

const logger = log.getLogger('RouteService');

const localRoutes = {};

export const deleteRoute = async (routeIds, apiKey) => {
    if (!Array.isArray(routeIds)) {
        routeIds = [routeIds];
    }

    if (config.local) {
        // return some made up routes
        return delete localRoutes[routeIds];
    }

    return API.del('routes', `/routes/${routeIds.join(',')}?_k=${apiKey}`);
};

export const publishRoute = async (routeId, apiKey) => {
    return API.get('routes', `/published/routes/${routeId}?_k=${apiKey}`);
};

export const unpublishRoute = async (routeId, apiKey) => {
    return API.del('routes', `/published/routes/${routeId}?_k=${apiKey}`);
};

export const duplicateRoute = async (routeId, apiKey) => {
    return API.post('routes', `/routes/${routeId}?_k=${apiKey}`);
};

export const divertRoute = async (routeId, divertPeriod, apiKey) => {
    const periodJson = divertPeriod.toJson();
    return API.post('routes', `/divert/routes/${routeId}?_k=${apiKey}`, {body: {period: periodJson}});
};

export const updateRoute = async (route) => {

    if (config.local) {
        // return some made up routes
        return localRoutes[route.routeId].update(route);
    }
    route.routeId = route.routeId === '_' ? ulid() : route.routeId;

    const {
        userId,
        charter,
        routeId,
        published,
        scheduleId,
        colour,
        direction,
        routeLogo,
        routeType,
        routeNumber,
        driverShift,
        vehicleShift,
        routeName,
        routeLabel,
        routeDescription,
        routeDetails,
        routeHeadsign,
        waypoints,
        stopTimes,
        startTime,
        services,
        author,
        createdAt,
        updatedAt,
        lastEditor,
        approvedBy,
        approvedAt,
        publishedBy,
        publishedAt,
        unpublishedBy,
        unpublishedAt,
        contractId,
        warnings
    } = route;

    let routeToUpdate = {
        userId,
        charter,
        routeId,
        published,
        scheduleId,
        colour,
        direction,
        routeLogo,
        routeType,
        routeNumber,
        driverShift,
        vehicleShift,
        routeName,
        routeLabel,
        routeDescription,
        routeDetails,
        routeHeadsign,
        waypoints,
        stopTimes,
        startTime,
        services,
        author,
        createdAt,
        updatedAt,
        lastEditor,
        approvedBy,
        approvedAt,
        publishedBy,
        publishedAt,
        unpublishedBy,
        unpublishedAt,
        contractId,
        warnings
    };
    routeToUpdate.routeName = routeToUpdate.routeName || routeToUpdate.routeNumber;
    routeToUpdate.createdAt = routeToUpdate.createdAt?.valueOf() || Date.now();
    routeToUpdate.updatedAt = routeToUpdate.updatedAt?.valueOf() || Date.now();
    routeToUpdate.stopTimes?.forEach(s => {
        delete s.avgSpd;
        delete s.distance;
        delete s.linkTime;
        delete s.transfersFrom;
        delete s.transfersTo;
        Object.keys(s).forEach(key => {
            if (s[key] === null) {
                delete s[key];
            }
        });
    });

    // Remove any invalid Trips

    // routeToUpdate.services = routeToUpdate.services.filter(trip => {
    //     return trip.scheduleIds?.length
    // })

    routeToUpdate.services = routeToUpdate.services?.map(trip => {
        trip = {...trip, dates: trip.dates?.map(d => typeof d === 'string' ? d : d.format(DATE_STRING))};
        delete trip.transfersFrom;
        delete trip.transfersTo;
        delete trip.stops;
        return trip
    });

    Object.keys(routeToUpdate).forEach(key => {
        if (routeToUpdate[key] === null) {
            delete routeToUpdate[key];
        }
    });
    // if (routeToUpdate.schedule) {
    //     delete routeToUpdate.schedule
    // Object.keys(routeToUpdate.schedule).forEach(key => {
    //     if (routeToUpdate.schedule[key] === null) {
    //         delete routeToUpdate.schedule[key]
    //     }
    // })
    // }
    logger.debug(`Updating route: ${routeToUpdate.routeNumber} Stop Times: ${routeToUpdate.stopTimes.length} Wps: ${routeToUpdate.waypoints.length}`);
    return API.put('routes', `/routes/${routeToUpdate.routeId}`, {
        body: routeToUpdate
    });
};

export const loadRoute = async (id, includeSchedule, apiKey, includeStopTimes) => {

    if (config.local) {
        // return some made up routes
        logger.debug(` loading route with id: ${id} from ${util.inspect(localRoutes)}`);
        const routes = await getRoutesWithOpts(apiKey);
        return find(routes, ['routeId', id]);
    }

    apiKey = apiKey || (await Auth.currentUserInfo()).attributes['custom:operatorId'];
    let query = {queryStringParameters: {stopTimes: includeStopTimes, '_k': apiKey}};
    let route = await API.get('routes', `/routes/${id}`, query);
    if (route) {
        if (includeSchedule && route.scheduleId) {
            route.schedule = await loadSchedule(route.scheduleId, apiKey);
        }
        return new BusRoute(route);
    }
};
export const getRoutes = async (includeStopTimes, includeStops, includePath, publishedOnly, scheduledOnly, loadSchedules, apiKey, responseFn, finishedFn) => {
    return getRoutesWithOpts(apiKey, responseFn,
        finishedFn, {includeStopTimes, includeStops, includePath, publishedOnly, scheduledOnly, loadSchedules});
};
export const getRoutesWithOpts = async (apiKey, responseFn, finishedFn, options = {}) => {
    logger.debug(options);
    apiKey = apiKey || (await Auth.currentUserInfo()).attributes['custom:operatorId'];
    const schedulesById = options.loadSchedules && await scheduleModelData.getAll();
    const stopsById = options.includeStops && await stopModelData.getAll();
    if (config.local) {
        // return some made up routes
        // return Object.keys(localRoutes).map(routeId => localRoutes[routeId])
        return await fetch(`/LOCAL_DATA/${apiKey}-routes.json`, {
            method: 'GET'
        }).then(response => response.json()).then(routes => {
            logger.debug(routes);
            let busRoutes = routesToBusRoutes(routes, schedulesById, stopsById || options.allStops);
            if (responseFn) {
                responseFn(busRoutes);
            }
            if (finishedFn) {
                finishedFn(busRoutes);
            }

            return busRoutes;
            // return keyBy(routes, 'routeId')
        });
    }

    let query = `?`;
    query += options.includeStopTimes ? 'stopTimes=true&' : '';
    query += options.includeStartTimes ? 'startTimes=true&' : '';
    // query += options.includeStops ? "stops=true&" : ""
    query += options.includePath ? 'waypoints=true&' : '';
    query += options.scheduledOnly ? 'scheduledOnly=true&' : '';
    query += options.publishedOnly ? 'publishedOnly=true&' : '';
    query += options.noDraft ? 'noDraft=true&' : '';
    query += options.stopId ? `stopId=${options.stopId}&` : '';
    query += options.stopIds?.length ? `stopIds=${options.stopIds.join(',')}&` : '';
    query += options.routeIds && options.routeIds.length ? `routeIds=${options.routeIds.join(',')}&` : '';
    query += options.directions && options.directions.length ? `directions=${options.directions.join(',')}&` : '';
    query += options.school && !options.regular ? `school=true&` : '';
    query += options.regular && !options.school ? `regular=true&` : '';
    query += options.shrinkRate ? `shrinkRate=${options.shrinkRate}&` : '';
    query += options.updatedAfter ? `updatedAfter=${options.updatedAfter}&` : '';
    query += options.limit ? `limit=${options.limit}&` : '';
    query += apiKey ? `_k=${apiKey}&` : '';
    if (!options.limit && !['prod', 'staging'].includes(process.env.REACT_APP_STAGE)) {
        query += 'limit=5&';
    }

    let path = 'pages';
    if (options.routeIds?.length) {
        path = 'pageMany';
    }
    // else fetch from AWS
    let allBusRoutes = [];
    let routesResponse = await API.get('routes', `/${path}${query.length > 1 ? query : ''}`, {headers: {'x-accept-encoding': 'gzip'}});
    logger.debug('PAGE RESPONSE: ', routesResponse);
    if (routesResponse) {
        let busRoutes = routesResponse.routes;//routesToBusRoutes(routesResponse.routes, schedulesById, stopsById || options.allStops);
        if (responseFn) {
            await responseFn(busRoutes);
        }

        while (routesResponse.lastRouteId) {
            allBusRoutes = allBusRoutes.concat(busRoutes);
            const newQuery = `${query}fromRouteId=${routesResponse.lastRouteId}${options.updatedAfter ? `&updateAfter=${options.updatedAfter}&fromUpdatedAfter=${routesResponse.lastUpdatedAfter}` : ''}${options.publishedOnly || options.noDraft ? `&fromRoutePublished=${routesResponse.lastRoutePublished}` : ''}`;
            routesResponse = await API.get('routes', `/pages${newQuery}`, {headers: {'x-accept-encoding': 'gzip'}});
            logger.debug('PAGE RESPONSE: ', routesResponse);

            busRoutes = routesResponse.routes;//routesToBusRoutes(routesResponse.routes, schedulesById, stopsById || options.allStops);
            if (responseFn) {
                await responseFn(busRoutes);
            }
        }
        allBusRoutes = allBusRoutes.concat(busRoutes);

        if (finishedFn) {
            finishedFn(allBusRoutes);
        }
        if (!responseFn) {
            return allBusRoutes;
        }
    } else {
        logger.debug('no pages response.');
    }

    return allBusRoutes;
};

export const createTransfer = async (transfer, apiKey) => {
    transfer.transferId = transfer.transferId || ulid();
    transfer.userId = transfer.userId || apiKey;
    logger.debug(`POSTing transfer: ${util.inspect(transfer)}`);
    return API.post('transfer', '/transfers', {
        body: transfer
    });
};

export const routeNumberSort = (t1, t2) => {
    const fromRouteNumber = t1?.fromRouteNumber?.localeCompare(t2.fromRouteNumber);
    if (fromRouteNumber === 0) {
        return t1?.toRouteNumber?.localeCompare(t2.toRouteNumber);
    }
    return fromRouteNumber;
};

export const prepareTransfers = (transfers, schedules, allStops) => {
    transfers.forEach(transfer => {
        transfer.schedule = schedules[transfer.scheduleId];
        if (typeof transfer.waypoints === 'string') {
            transfer.waypoints = JSON.parse(transfer.waypoints);
            transfer.distance = parseInt(transfer.distance);
            transfer.duration = parseInt(transfer.duration);
            transfer.time = parseInt(transfer.time);
            transfer.window = parseInt(transfer.window);
        }


        const fromStop = allStops[transfer.fromStopId];
        const fromRoute = fromStop?.routes[fromStop.routes.findIndex(r => r.routeId === transfer.fromRouteId)];
        const fromTrip = fromRoute?.services[transfer.fromTripId];//find(fromRoute.passingTimes, ['tripId', transfer.fromTripId])
        const arriveStopTime = find(fromTrip?.passingTimes, ['stopTimeId', transfer.fromStopTimeId]);
        transfer.time = arriveStopTime?.arriveSecs;
        transfer.fromRouteColour = fromRoute?.colour;

        const toStop = allStops[transfer.toStopId];
        const toRoute = toStop?.routes[toStop.routes.findIndex(r => r.routeId === transfer.toRouteId)];
        const toTrip = toRoute?.services[transfer.toTripId];// find(toRoute.passingTimes, ['tripId', transfer.toTripId])
        const departStopTime = find(toTrip?.passingTimes, ['stopTimeId', transfer.toStopTimeId]);
        transfer.window = departStopTime?.departSecs - arriveStopTime?.arriveSecs + 60;
        transfer.toRouteColour = toRoute?.colour;

        // delete transfer.fromTripId
        // delete transfer.warning;
        //distance
        //duration
        //time
        //window
        // const fromLog = transfer.fromRouteNumber === '571'
        //
        // fromLog && logger.info('The TRANSFER: ', transfer)

        // const fromStop = allStops[transfer.fromStopId];
        // fromLog && logger.info("From STOP: ", fromStop);
        // if (fromStop) {
        //     fromLog && logger.info("From stop: ", fromStop.stopName);
        //     const fromStopPassingTimes = fromStop.getPassingTimes(r => r.routeNumber === transfer.fromRouteNumber, transfer)
        //         .filter(fromStopPassingTime => fromStopPassingTime.scheduleIds.some(sId => schedules[sId] && (!schedules[sId].isFuture() && !schedules[sId].isObsolete())));
        //     fromLog && logger.info('FROM PASSING TIME: ', fromStopPassingTimes)
        //
        //     let fromStopPassingTime = null
        //     if (!fromStopPassingTimes.length) {
        //         logger.warn('NO TRIPS FOR Transfer fromRoute %s @ %s.', transfer.fromRouteNumber, fromStop.stopName)
        //     } else {
        //         fromStopPassingTime = fromStopPassingTimes[0]
        //     }
        //     if (fromStopPassingTimes.length > 1) {
        //         transfer.warning = `MULTIPLE TRIPS FOR Transfer from route ${transfer.fromRouteNumber} @ ${fromStop.stopName}.`
        //         logger.warn('MULTIPLE TRIPS FOR Transfer fromRoute %s @ %s. Using first one.', transfer.fromRouteNumber, fromStop.stopName)
        //     }
        //     transfer.fromTripId = fromStopPassingTime?.tripId;
        //     transfer.fromScheduleIds = fromStopPassingTime?.scheduleIds;
        //     transfer.fromRouteId = fromStopPassingTime?.routeId
        //     transfer.fromPassingTime = fromStopPassingTime?.passingTime
        //     transfer.fromStopPassingTimes = fromStopPassingTimes
        // } else {
        //     logger.warn('Could not find from stop with id ', transfer.fromStopId)
        //     transfer.warning = `From stop does not exist.`
        // }

        // const toLog = false;//transfer.toRouteNumber === 'S266'
        // const toStop = allStops[transfer.toStopId]
        // toLog && logger.info("To STOP: ", toStop);
        // if (toStop) {
        //     toLog && logger.info("To stop: ", toStop.stopName)
        //     const toStopPassingTimes = toStop.getPassingTimes(r => r.routeNumber === transfer.toRouteNumber, transfer)
        //         .filter(pt => {
        //             toLog && logger.info(pt, transfer.fromPassingTime)
        //             return !isFinite(transfer.fromPassingTime) || pt.passingTime >= transfer.fromPassingTime
        //         }).filter(toStopPassingTime => toStopPassingTime.scheduleIds.some(sId => schedules[sId] && (!schedules[sId].isFuture() && !schedules[sId].isObsolete())));
        //     toLog && logger.info('TO PASSING TIME: ', toStopPassingTimes)
        //
        //     let toStopPassingTime = null
        //
        //     if (!toStopPassingTimes.length) {
        //         logger.warn('NO TRIPS FOR Transfer toRoute %s @ %s.', transfer.toRouteNumber, toStop.stopName)
        //     } else {
        //         toStopPassingTime = toStopPassingTimes[0]
        //     }
        //     if (toStopPassingTimes.length > 1) {
        //         transfer.warning = `MULTIPLE TRIPS FOR Transfer to route ${transfer.toRouteNumber} @ ${fromStop.stopName}.`;
        //         logger.warn('MULTIPLE TRIPS FOR Transfer toRoute %s @ %s. Using first one.', transfer.toRouteNumber, toStop.stopName)
        //     }
        //
        //     transfer.toTripId = toStopPassingTime?.tripId;
        //     transfer.toScheduleIds = toStopPassingTime?.scheduleIds;
        //     transfer.toRouteId = toStopPassingTime?.routeId
        //     transfer.toPassingTime = toStopPassingTime?.passingTime
        //     transfer.toStopPassingTimes = toStopPassingTimes
        // } else {
        //     logger.info('Could not find to stop with id ', transfer.toStopId)
        //     transfer.warning = `To stop does not exist.`
        // }

        // if (!transfer.fromTripId) {
        //     transfer.invalid = true;
        //     transfer.warning = `Transfer from ${transfer.fromRouteNumber} @ ${toTime(transfer.time, false)} cannot be mapped to a trip.`
        // } else if (fromLog) {
        //     logger.info(transfer.fromTripId, transfer.fromRouteId)
        // }
        //
        // if (!transfer.toTripId) {
        //     transfer.warning = transfer.invalid ? `Routes ${transfer.fromRouteNumber} -> ${transfer.toRouteNumber} @ ${toTime(transfer.time, false, transfer.window)} do not map to any trips.` : `Transfer to ${transfer.toRouteNumber} @ ${toTime(transfer.time, false, transfer.window)} cannot be mapped to a trip.`
        //     transfer.invalid = true;
        // } else if (fromLog) {
        //     logger.info(transfer.toTripId, transfer.toRouteId)
        // }
        //
        // if (transfer.fromScheduleIds?.length && transfer.toScheduleIds?.length) {
        //     transfer.scheduleIds = intersection(transfer.fromScheduleIds, transfer.toScheduleIds)
        // }
        if (transfer.warning) {
            logger.warn(transfer.warning);
        }

    });

    transfers = transfers.map(t => new Transfer(t));
    transfers.filter(t => !t.invalid).forEach((leg1) => {
        leg1._trx = [];
        transfers.filter(t => !t.invalid && t !== leg1).forEach(leg2 => {
            if (leg2.fromStopId !== leg1.fromStopId &&
                leg2.fromRouteId === leg1.toRouteId &&
                leg2.fromTripId === leg1.toTripId &&
                leg2.time >= leg1.time
            ) {
                leg2 = cloneDeep(leg2);
                delete leg2._trx;
                leg1._trx.push(leg2);
            }
        });
    });
    return transfers;
};

export const getTransfers = async (apiKey, schedules, allStops, updatedAfter) => {

    let transfers = [];
    if (config.local) {
        transfers = await fetch(`/LOCAL_DATA/${apiKey}-transfers.json`, {
            method: 'GET'
        }).then(response => response.json());
    } else {
        transfers = await API.get('transfer', `/transfers?_k=${apiKey}${updatedAfter > 0 ? '&updatedAfter=' + updatedAfter : ''}`);
    }

    return prepareTransfers(transfers, schedules, allStops);
};

export const deleteTransfer = async (transferId, apiKey) => {
    return API.del('transfer', `/transfers/${transferId}?_k=${apiKey}`);
};

export const updateTransfer = async (transfer) => {
    return API.put('transfer', `/transfers/${transfer.transferId}`, {
        body: transfer
    });
};


export const createStudent = async (student, apiKey) => {
    student.studentId = student.studentId || ulid();
    student.userId = student.userId || apiKey;
    logger.debug(`POSTing student: ${util.inspect(student)}`);
    return API.post('routes', '/students', {
        body: student
    });
};

export const getStudents = async (apiKey) => {
    return await API.get('routes', `/students?_k=${apiKey}`);
};

export const deleteStudent = async (studentId, apiKey) => {
    return API.del('routes', `/students/${studentId}?_k=${apiKey}`);
};

export const updateStudent = async (student) => {
    return API.put('routes', `/students/${student.studentId}`, {
        body: student
    });
};
export const getManyRoutes = async (apiKey, routeIds, options = {}) => {
    apiKey = apiKey || (await Auth.currentUserInfo()).attributes['custom:operatorId'];
    return flatten(await Promise.all(chunk(routeIds, 5).map(async routeIds => {
        const rs = await API.post('routes', `/routes/many`, {
            body: flatten(options.timeFilters.map(timeFilter => timeFilter.toParams())),
            queryStringParameters: {...options, '_k': apiKey, ids: routeIds.join(',')}
        });
        return rs.map(r => new BusRoute(r));
    })));

};
export const getRoutesByTime = async (apiKey, routeIds, options = {}) => {
    apiKey = apiKey || (await Auth.currentUserInfo()).attributes['custom:operatorId'];
    return flatten(await Promise.all(chunk(routeIds, 5).map(async routeIds => {
        let query = `ids=${routeIds.join(',')}&`;
        query += options.shrinkRate ? `shrinkRate=${options.shrinkRate}&` : '';
        // query += options.timeFilters ? 's=' + flatten(options.timeFilters.map(timeFilter => timeFilter.toParams())).join(',') + '&' : ''
        query += options.active ? `active=${options.active}&` : '';
        query += apiKey ? `_k=${apiKey}&` : '';
        const rs = await API.post('routes', `/routes/byTime?${query}`, {
            body: {
                tf: flatten(options.timeFilters.map(timeFilter => timeFilter.toStopParams())),
                s: options.schedules ? values(options.schedules).map(s => s.toJson()) : undefined
            }

        });
        return rs.map(r => new BusRoute(r));
    })));

};
export const getPublicRoutes = async (apiKey, routeFilter, options = {}) => {
    apiKey = apiKey || (await Auth.currentUserInfo()).attributes['custom:operatorId'];
    // let query = options.shrinkRate ? `shrinkRate=${options.shrinkRate}&` : ''
    // query += apiKey ? `_k=${apiKey}&` : ""
    // const rs = await API.post("routes", `/public/routes?${query}`, {
    //     body: Object.keys(routeFilter).map(rId => ({routeId: rId, ...routeFilter[rId]}))
    // });
    // return rs.map(r => new BusRoute(r));
    return flatten(await Promise.all(chunk(Object.keys(routeFilter), 10).map(async routeIds => {
        let query = options.shrinkRate ? `shrinkRate=${options.shrinkRate}&` : '';
        query += apiKey ? `_k=${apiKey}&` : '';
        const rs = await API.post('routes', `/public/routes?${query}`, {
            body: routeIds.map(rId => ({routeId: rId, ...routeFilter[rId]}))
        });
        return rs.map(r => new BusRoute(r));
    })));

};


export const populateJps = async (apiKey, jps, options = {}) => {
    apiKey = apiKey || (await Auth.currentUserInfo()).attributes['custom:operatorId'];
    const rs = await API.post('routes', `/jps`, {
        body: {jps},
        queryStringParameters: {'_k': apiKey, shrinkRate: options.shrinkRate}
    });
    return rs.map(r => new JourneyPlan(r));
};

const routesToBusRoutes = (routes, schedulesById, stopsById) => {
    if (!routes) {
        return [];
    }
    return routes.map(route => {
        if (schedulesById && route.scheduleId) {
            route.schedule = schedulesById[route.scheduleId];
        }
        route = new BusRoute(route);
        if (stopsById && route.stopTimes) {
            route.setBaseStops(stopsById);
        }
        return route;
    });
};

export const getSchedules = async (apiKey) => {
    if (config.local) {
        return fetch(`/LOCAL_DATA/${apiKey}-schedules.json`, {
            method: 'GET'
        }).then(response => response.json());
    }

    return (await API.get('routes', `/schedules${apiKey ? `?_k=${apiKey}` : ''}`)).map(s => {
        return s;
    });
};

export const loadSchedule = async (scheduleId, apiKey) => {
    apiKey = apiKey || (await Auth.currentUserInfo()).attributes['custom:operatorId'];
    try {
        return await API.get('routes', `/schedules/${scheduleId}${apiKey ? `?_k=${apiKey}` : ''}`);
    } catch (e) {
        logger.debug(e);
    }
};

export const dirtySchedule = (clean) => {
    const s = {...clean};
    s.schedulePeriod = s.schedulePeriod && s.schedulePeriod.map(p => new DateObject({date: p, format: 'DD/MM/YYYY'}));
    s.excludedPeriod = s.excludedPeriod && s.excludedPeriod.map(p => new DateObject({date: p, format: 'DD/MM/YYYY'}));
    s.excludedDates = s.excludedDates && s.excludedDates.map(p => new DateObject({date: p, format: 'DD/MM/YYYY'}));
    return s;
};

const cleanSchedule = (schedule) => {
    let scheduleToUpdate = Schedule.clone(schedule).toJson();
    Object.keys(scheduleToUpdate).forEach(key => {
        if (scheduleToUpdate[key] === null) {
            delete scheduleToUpdate[key];
        }
    });
    return scheduleToUpdate;
};

export const createSchedule = async (schedule) => {
    schedule.scheduleId = schedule.scheduleId === '_' ? ulid() : schedule.scheduleId;
    // schedule = cleanSchedule(schedule)
    logger.debug(`POSTing schedules: ${schedule}`);
    return API.post('routes', '/schedules', {
        body: schedule
    });
};

export const forwardSchedule = async (schedule) => {
    schedule = cleanSchedule(schedule);
    logger.debug(`FORwarding schedules: ${schedule}`);
    return API.post('routes', '/forward/schedule', {
        body: schedule
    });
};

export const updateSchedule = async (schedule) => {
    // schedule = cleanSchedule(schedule)
    schedule.excludedPeriods = schedule.excludedPeriods || [];
    return API.put('routes', `/schedules/${schedule.scheduleId}`, {
        body: schedule
    });
};

export const deleteSchedule = async (scheduleId, apiKey) => {
    return API.del('routes', `/schedules/${scheduleId}?_k=${apiKey}`);
};

export const createShift = async (shift, shiftType) => {
    logger.debug(`POSTing shift: ${util.inspect(shift)}`);
    return API.post('routes', `/shifts/${shiftType}`, {
        body: shift
    });
};

export const getShifts = async (apiKey, shiftType) => {

    if (config.local) {
        if (shiftType === 'vehicle') {
            return fetch(`/LOCAL_DATA/${apiKey}-vehicle.json`, {
                method: 'GET'
            }).then(response => response.json());
        } else {
            return fetch(`/LOCAL_DATA/${apiKey}-driver.json`, {
                method: 'GET'
            }).then(response => response.json());
        }
    }

    return await API.get('routes', `/shifts/${shiftType}?_k=${apiKey}`);
};

export const getStops = async (apiKey) => {
    if (config.local) {
        return fetch(`/LOCAL_DATA/${apiKey}-stops.json`, {
            method: 'GET'
        }).then(response => response.json());
    }

    return await API.get('routes', `/stops${apiKey ? `?_k=${apiKey}` : ''}`);
};


export const createStopVerification = async (verification) => {
    logger.debug(`POSTing verification: ${util.inspect(verification)}`);
    // return API.post("routes", `/stops/verifications`, {
    //     body: verification
    // });
    await API.post('events', `/events`, {
        body: {
            source: 'busable.mergeStops',
            detailType: 'MergeStops',
            detail: {body: verification}
        }
    });
};

export const updateStop = async (stop) => {

    const {
        userId,
        stopId,
        lat,
        lon,
        stopName,
        stopDesc,
        stopType,
        stopCode,
        routes,
        duplicate,
        verified,
        imported,
        linkedStops,
        startBell,
        startBellWindow,
        endBell,
        endBellWindow,
        bearing
    } = stop;
    let stopToUpdate = {
        userId,
        stopId,
        lat,
        lon,
        stopName,
        stopDesc,
        stopType,
        stopCode,
        routes,
        duplicate,
        verified,
        imported,
        linkedStops,
        startBell, startBellWindow, endBell, endBellWindow,
        bearing
    };
    if (stop.stopType !== 'school') {
        delete stop.startBell;
        delete stop.startBellWindow;
        delete stop.endBell;
        delete stop.endBellWindow;
    }
    if (stopToUpdate.routes) {
        stopToUpdate.routes.forEach(r => {
            delete r.merged;
        });
    }

    stopToUpdate.linkedStops?.forEach(linkedStop => delete linkedStop.stop);

    Object.keys(stopToUpdate).forEach(key => {
        if (stopToUpdate[key] === null) {
            delete stopToUpdate[key];
        }
    });

    if (!stopToUpdate.postcode?.length && !stopToUpdate.suburb?.length) {
        const {postcode, suburb, town, city} = await reverseGeocode({lat, lon}) || {};
        stopToUpdate.postcode = postcode;
        stopToUpdate.suburb = suburb || town || city;
    }

    stopToUpdate.suburb = stopToUpdate.suburb?.toUpperCase();

    logger.debug('Saving stop', stopToUpdate);
    return API.put('routes', `/stops/${stopToUpdate.stopId}`, {
        body: stopToUpdate
    });
};

export const createStop = async (stop) => {
    if (Array.isArray(stop)) {
        await Promise.all(chunk(stop, 100).map(async chunk => {
            return API.post('routes', `/stops`, {
                body: chunk
            });
        }));
    } else {
        stop.stopId = stop.stopId === '_' ? ulid() : stop.stopId;
        logger.debug('creating stop.');

        return API.post('routes', `/stops`, {
            body: stop
        });
    }
};

export const deleteStop = async (stopIds, apiKey) => {
    if (!Array.isArray(stopIds)) {
        stopIds = [stopIds];
    }
    return API.del('routes', `/stops/${stopIds.map(id => encodeURIComponent(id)).join('|')}?_k=${apiKey}`);
};

export const saveOperator = async (operator) => {
    config.operator = operator;
    return await API.put('operators', `/operators/${operator.operatorId}`, {
        body: operator
    });

};
export const createOperator = async (operator) => {
    return await API.post('operators', `/operators`, {
        body: operator
    });
};

export const startFreeTrial = async (operator, feature) => {
    return await API.get('operators', `/trial/${encodeURIComponent(feature)}?_k=${operator.operatorId}`);
};

export const setUserOperator = async ({operatorId, companyId}) => {
    if (!config.local) {
        return await API.put('operators', `/users/operators/${companyId}/${operatorId}`);
    }
};

export const getOperator = async (apiKey, secure) => {

    if (config.local) {
        return fetch(`/LOCAL_DATA/${apiKey}.json`, {
            method: 'GET'
        }).then(response => response.json()).then(operator => {
            operator.features = new Features(operator.features);
            // operator.features = features;
            return operator;
        });
    }

    apiKey = apiKey || (await Auth.currentUserInfo()).attributes['custom:operatorId'];
    const operator = await API.get('operators', `/operators/${apiKey}${secure ? `?secure=true` : ''}`);
    if (!operator?.features) {
        throw new Error('No Operator ' + apiKey);
    }
    operator.features = new Features(operator.features);
    if (operator.operatorAddress) {
        operator.operatorAddress = new Address(operator.operatorAddress);
    }
    if (operator.operatorPostalAddress) {
        operator.operatorPostalAddress = new Address(operator.operatorPostalAddress);
    }
    // operator.features = features;
    return operator;
};

export const getPubOperator = async (apiKey) => {

    if (config.local) {
        return fetch(`/LOCAL_DATA/${apiKey}.json`, {
            method: 'GET'
        }).then(response => response.json()).then(operator => {
            operator.features = new Features(operator.features);
            return operator;
        });
    }

    apiKey = apiKey || (await Auth.currentUserInfo())?.attributes['custom:operatorId'];
    const operator = await API.get('operators', `/public/operators/${apiKey}`);
    if (!operator?.features) {
        throw new Error('No Operator ' + apiKey);
    }
    if ((await Auth.currentUserInfo())?.attributes['custom:isAdmin'] === 'true') {
        operator.features = new Features({all: true});
    } else {
        operator.features = new Features(operator.features);
    }
    // operator.features = features
    return operator;
};


export const createUsage = async (usage) => {
    logger.debug(`POSTing usage: ${util.inspect(usage)}`);
    // return API.post("routes", `/usage`, {
    //     body: usage
    // });
};

export const fetchMasterStopsInBounds = async ({bounds, excluding, types}) => {
    logger.debug(`Fetching bounded source points: `, bounds);
    return (await API.post('routes', `/masterStops/bounds`, {
        body: {bounds, excluding: uniqBy(excluding, 'geohash').map(stop => stop.geohash), types}
    })).map(s => {
        s.verified = 1;
        s.master = true;
        s.stopId = s.authorityId + '|' + s.stopCode;
        return s;
    });
};

export const syncXeroToBusable = async (apiKey) => {
    console.log(`Syncing Xero to Busable: `, apiKey);
    return API.get('third_party', `/employees${apiKey ? `?_k=${apiKey}` : ''}`);
};

export const importEmployees = async (employees, type, apiKey) => {
    return API.post('third_party', `/employees/import/${type}${apiKey ? `?_k=${apiKey}` : ''}`, {body: employees}, {headers: {'Content-Type': 'text/plain'}});
};

export const getCurrentLog = async (id, apiKey) => {
    return await API.get('routes', `/logs/${encodeURIComponent(id)}${apiKey ? `?_k=${encodeURIComponent(apiKey)}` : ''}`);
};
