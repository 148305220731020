import {cloneDeep} from "lodash";

export const DRIVER_STATUS = {
    active: "Active", sick: "Sick", holiday: "Holidays"
}

export class Driver {
    constructor(data) {
        this.driverId = "_";
        this.driverName = null;
        this.driverStatus = DRIVER_STATUS.active;
        Object.assign(this, data)
    }

    static clone(driver) {
        return new Driver(cloneDeep(driver));
    }

}
