import {flatten, forEach, last, sumBy} from 'lodash';
import {filter, findLast} from 'lodash/collection';
import {keys} from 'lodash/object';
import {dayjsToSecsSinceMidnight} from './timeFilter';
import {convertWorkItemsToTimeBlocks, WorkItem} from './shiftBat';

export const STD_RULES = {
    maxDailyHours: 12,
    minDailyRest: 7 * 60, // 7 hours in minutes
    maxWeeklyHours: 72,
    minWeeklyRest: 24 * 60, // 24 hours in minutes
    minWeeklyNightRests: 6,
    nightRest: {start: 22 * 60 * 60, end: 6 * 60 * 60}, // 10 PM to 6 AM
    breakRequirements: [{hours: 5.5, requiredBreak: 15}, {hours: 8, requiredBreak: 30}, {hours: 12, requiredBreak: 60}],
    minRestTimeBlock: 15 // min rest time block in minutes
};

export const chunkByDuration = (workItems, unit = 'minute', time = 60) => {
    const chunks = [];
    let currentChunk = [];
    let lastTime = null;

    forEach(workItems, item => {
        if (lastTime && item.getStartTime({asDayJs: true}).diff(lastTime, unit) >= time) {
            chunks.push(currentChunk);
            currentChunk = [];
        }
        currentChunk.push(item);
        lastTime = item.getEndTime({asDayJs: true});
    });

    if (currentChunk.length) {
        chunks.push(currentChunk);
    }

    return chunks;
};

export const checkBreaks = ({rules = STD_RULES, timeBlocks, compliance = {}}) => {
    const {breakRequirements} = rules;
    let maxHoursBeforeBreak = breakRequirements[0].hours;
    // Total work and break time counters
    let totalDriveTime = 0; // Total work time in minutes
    let totalWorkTime = 0; // Total work time in minutes
    let totalBreakTime = 0; // Total break time in minutes
    let breakTimeSinceLastBreak = 0;
    let workTimeSinceLastBreak = 0;
    let totalWorkTimeFor24HrCheck = 0;
    let totalWorkTimeFor7DayCheck = 0;
    let totalDriveTimeFor24HrCheck = 0;
    let totalDriveTimeFor7DayCheck = 0;

    const REQUIRED_NIGHT_BREAKS = rules.minWeeklyNightRests;  // Six nights in 7 days
    const REQUIRED_CONSECUTIVE_HOURS = 7; // At least 7 consecutive hours in 24

    let lastSessionEnd = null; // Track the end of the last session
    let validNightBreakDays = 0; // Track days with valid night breaks
    let consecutiveBreakFound = false; // Flag for 7-hour break in 24 hours

    for (let i = 0; i < timeBlocks.length; i++) {
        const currentSession = timeBlocks[i];

        const startTime = currentSession.getStartTime();
        const endTime = currentSession.getEndTime();

        const sessionDuration = endTime.diff(startTime, 'minute', true);

        const checkBreakRequirements = () => {
            // Check if total work time requires any breaks
            for (const {hours, requiredBreak} of breakRequirements) {
                // Object.keys(breakRequirements).sort((a, b) => b - a).forEach(hours => {
                //     const requiredBreak = breakRequirements[hours];
                const requiredTime = hours * 60; // Convert hours to minutes

                if (totalWorkTime >= requiredTime) {
                    if (totalBreakTime < requiredBreak) {
                        compliance[currentSession.id] = compliance[currentSession.id] || [];
                        compliance[currentSession.id].push(`Break time not met after ${(requiredTime / 60).toFixed(1)} hours. Total work time: ${(totalDriveTime / 60).toFixed(1)} hours. Required break time: ${requiredBreak} minutes. Total break time: ${totalBreakTime} minutes.`);

                        workTimeSinceLastBreak = 0;
                        breakTimeSinceLastBreak = 0;
                    }
                }

                if (workTimeSinceLastBreak >= requiredTime) {
                    if (breakTimeSinceLastBreak < requiredBreak) {
                        compliance[currentSession.id] = compliance[currentSession.id] || [];
                        compliance[currentSession.id].push(`Break time not met after ${(requiredTime / 60).toFixed(1)} hours. Total work time: ${(workTimeSinceLastBreak / 60).toFixed(1)} hours. Required break time: ${requiredBreak} minutes. Total break time: ${breakTimeSinceLastBreak} minutes.`);
                    }
                    workTimeSinceLastBreak = 0;
                    breakTimeSinceLastBreak = 0;
                }
            }

        };

        const checkDailyRequirements = () => {

            if (totalDriveTimeFor24HrCheck > rules.maxDailyHours * 60) {
                compliance[currentSession.id] = compliance[currentSession.id] || [];
                compliance[currentSession.id].push(`Daily working limit exceeded. Total work time: ${(totalDriveTimeFor24HrCheck / 60).toFixed(1)} hours. Maximum hours: ${rules.maxDailyHours} hours.`);
            }

            if (totalWorkTimeFor24HrCheck >= 24 * 60) {
                if (!consecutiveBreakFound) {
                    compliance[currentSession.id] = compliance[currentSession.id] || [];
                    compliance[currentSession.id].push(`Break time not met after 24 hours. Total work time: ${(totalWorkTimeFor24HrCheck / 60).toFixed(1)} hours. Required break time: ${REQUIRED_CONSECUTIVE_HOURS} consecutive hours. No valid break found.`);
                }
                totalWorkTimeFor24HrCheck = 0;
                consecutiveBreakFound = false;
                totalDriveTimeFor24HrCheck = 0;
            }

        };

        const checkWeeklyRequirements = () => {

            if (totalDriveTimeFor7DayCheck > rules.maxWeeklyHours * 60) {
                compliance[currentSession.id] = compliance[currentSession.id] || [];
                compliance[currentSession.id].push(`Weekly working limit exceeded. Total work time: ${(totalDriveTimeFor7DayCheck / 60).toFixed(1)} hours. Maximum hours: ${rules.maxWeeklyHours} hours.`);
            }

            if (totalWorkTimeFor7DayCheck >= 7 * 24 * 60) {
                if (validNightBreakDays < REQUIRED_NIGHT_BREAKS) {
                    compliance[currentSession.id] = compliance[currentSession.id] || [];
                    compliance[currentSession.id].push(`Break time not met after 7 days. Total work time: ${(totalWorkTimeFor7DayCheck / 60 / 24).toFixed(1)} days. Required night breaks: ${REQUIRED_NIGHT_BREAKS}. Nights with valid breaks: ${validNightBreakDays}.`);
                }
                totalWorkTimeFor7DayCheck = 0;
                validNightBreakDays = 0;
            }
        };


        if (currentSession.type === 'work' && sessionDuration > maxHoursBeforeBreak * 60) {
            compliance[currentSession.id] = compliance[currentSession.id] || [];
            compliance[currentSession.id].push(`Break time not met after ${maxHoursBeforeBreak} hours. Total work time: ${(sessionDuration / 60).toFixed(1)} hours. Required break time: ${breakRequirements[0].requiredBreak} minutes. Total break time: 0 minutes.`);
            continue;
        }

        // Add session duration to total work time if it's work
        if (currentSession.type.includes('work')) {
            totalDriveTime += sessionDuration;
            totalDriveTimeFor24HrCheck += sessionDuration;
            totalDriveTimeFor7DayCheck += sessionDuration;
        }
        // Calculate break time between sessions (if any) and check breaks
        if (lastSessionEnd) {
            const breakBetween = startTime.diff(lastSessionEnd, 'minute', true);

            if (breakBetween >= rules.minRestTimeBlock) {
                totalBreakTime += breakBetween;
            }
            totalWorkTime += breakBetween;

            breakTimeSinceLastBreak += breakBetween;
            workTimeSinceLastBreak += breakBetween;
            totalWorkTimeFor7DayCheck += breakBetween;
            totalWorkTimeFor24HrCheck += breakBetween;

            // Full 7 hour break found
            if (breakBetween >= REQUIRED_CONSECUTIVE_HOURS * 60) {
                consecutiveBreakFound = true;
            }

            // Check whether this break is more than a full day, then valid night breaks are unnecessary
            if (breakBetween >= 24 * 60) {
                validNightBreakDays = rules.minWeeklyNightRests;
            } else if (breakBetween >= rules.minDailyRest) {

                // Check whether this break is a valid night break

                const breakStartTime = dayjsToSecsSinceMidnight(lastSessionEnd);
                const breakEndTime = dayjsToSecsSinceMidnight(startTime);

                if (breakStartTime >= rules.nightRest.end || breakEndTime <= rules.nightRest.start) {
                    const breakBetweenInHours = startTime.startOf('days').diff(lastSessionEnd.startOf('days'), 'hours');
                    validNightBreakDays += breakBetweenInHours;
                }
            }
            checkBreakRequirements();
            checkWeeklyRequirements();

            if (totalWorkTimeFor24HrCheck >= 24 * 60) {
                totalWorkTimeFor24HrCheck = 0;
                // consecutiveBreakFound = false;
                totalDriveTimeFor24HrCheck = 0;
            }
            // checkDailyRequirements();

            if (totalWorkTimeFor7DayCheck >= 7 * 24 * 60) {
                totalWorkTimeFor7DayCheck = 0;
                validNightBreakDays = 0;
            }
        }

        totalWorkTime += sessionDuration;
        workTimeSinceLastBreak += sessionDuration;
        totalWorkTimeFor7DayCheck += sessionDuration;
        totalWorkTimeFor24HrCheck += sessionDuration;

        // Add session time to total break time if it's a break session
        if (currentSession.type.includes('break')) {
            totalBreakTime += sessionDuration;
            breakTimeSinceLastBreak = 0;
            workTimeSinceLastBreak = 0;


            if (sessionDuration >= REQUIRED_CONSECUTIVE_HOURS * 60) {
                consecutiveBreakFound = true;
            }


            // Check whether this break is a valid night break
            if (sessionDuration > rules.minDailyRest) {
                const breakStartTime = dayjsToSecsSinceMidnight(startTime);
                const breakEndTime = dayjsToSecsSinceMidnight(endTime);


                if (breakStartTime >= rules.nightRest.end || breakEndTime <= rules.nightRest.start) {
                    const breakBetweenInHours = endTime.startOf('days').diff(startTime.startOf('days'), 'hours');
                    validNightBreakDays += breakBetweenInHours;
                }
            }
        } else {
            checkBreakRequirements();
            checkDailyRequirements();
            checkWeeklyRequirements();
        }
        lastSessionEnd = endTime; // Update last session end time

    }

    return compliance;
};

export class DriverCompliance {

    static from({workItems}) {
        return new DriverCompliance({workItems});
    }

    static checkCompliance({employeeId, workItems, rules}) {
        return new DriverCompliance({employeeId, workItems, rules}).checkCompliance();
    }

    constructor({rules = STD_RULES, employeeId, workItems}) {
        this.rules = rules;
        this.employeeId = employeeId;
        this.workItems = workItems.map(d => WorkItem.from(d)).sort((a, b) => ((a.duty && a.getStartTime()) || 0) - ((b.duty && b.getStartTime()) || 0));
        // const [nightRestStartSecs, nightRestEndSecs] = this.rules.nightRest.map(time => dayjsToSecsSinceMidnight(dayjs(time, 'HH:mm')));
        this.nightRestStartSecs = null;
        this.nightRestEndSecs = null;
    }

    checkCompliance() {
        const time = Date.now();
        const compliance = {};
        const timeBlocks = convertWorkItemsToTimeBlocks(this.workItems); // Reverse the work items to start from the beginning of the period
        while (timeBlocks.length) {
            // Check if the driver complies with the daily limits for each work time block
            checkBreaks({rules: this.rules, timeBlocks, compliance});
            do {
                timeBlocks.shift();
            } while (timeBlocks.length && timeBlocks[0].type === 'break');
        }
        keys(compliance).forEach(key => {
            // Ensure compliance array is unique using lodash
            compliance[key] = filter(compliance[key], (value, index, self) => self.indexOf(value) === index);
        });
        console.log('Time taken for compliance:', Date.now() - time);
        return compliance;
    }
}

