import {cloneDeep} from 'lodash';
import {Job} from '../job';

export const GENDERS = {
    male: 'Male',
    female: 'Female',
    nonBinary: 'Non-binary',
};

export const DEFAULT_DRIVER_RATE = 35;
export const DEFAULT_MAX_DRIVER_HRS = 36;

export class Employee {
    constructor(data) {
        // this.employeeID = null;
        // this.firstName = null;
        // this.lastName = null;
        // this.dateOfBirth = null;
        // this.gender = null;
        // this.bloodGroup = null;
        // this.phone = null;
        // this.countryCode = "+61";
        // this.email = null;

        // this.medicalConditions = []
        // this.emergencyContact = {}


        Object.assign(this, data);
    }

    name() {
        return `${this.firstName} ${this.lastName}`;
    }

    clone(employee) {
        return new Employee(cloneDeep(employee));
    }

    async  addJobOption(duty) {
        // Check each job to see if the startTime and endTime overlap with the duty's startTime and endTime
        const overlappingJob = this.jobs.find(job => job.getActualStartTime({asDayJs: true}).isBefore(duty.getActualEndTime({asDayJs: true}))
            && job.getActualEndTime({asDayJs: true}).isAfter(duty.getActualStartTime({asDayJs: true})));
        if (overlappingJob) {
            return null;
        }

        const stats = {};

        this.jobOption = new Job({
            job: duty,
            stats,
        });
    }
}
