import {DatePicker, Select} from 'antd';
import {Tooltip} from 'antd/lib';
import React from 'react';
import {values} from 'lodash/object';


export const schedule_options = schedules => {
    return (Array.isArray(schedules) ? schedules : values(schedules)).map(schedule => ({
        label: (schedule.isFuture() ? 'Future: ' : '') + schedule.scheduleName,
        value: schedule.scheduleId
    }));
};

function CalendarSelector({obj, onCalendarChange, onDatesChange, schedules, obsolete, recurring, ...props}) {

    return (<Tooltip
        title={!obj.scheduleIds?.length || !obj.dates?.length ? `Please provide at least one ${recurring ? 'Calendar' : 'Date'} for this service.` :
            obsolete ? 'There is an expired Calendar for this service.' :
                null}>
        {recurring && onCalendarChange ?
            <Select
                className={`w-100`}
                placeholder="Operating calendars"
                mode="multiple"
                allowClear
                showSearch={true}
                filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                filterSort={(optionA, optionB) =>
                    (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                }
                style={{width: '100%'}}
                onChange={onCalendarChange}
                value={obj.scheduleIds}
                options={schedule_options(schedules)}
                {...props}
            /> : onDatesChange ?
                <DatePicker multiple
                            maxTagCount="responsive"
                            onChange={onDatesChange} value={obj.dates}
                    {...props}/> :
                <>Please add onDatesChange function</>}
    </Tooltip>);
}

export default React.memo(CalendarSelector);