import React, {useCallback} from "react";
import {Button, Input} from "antd";
import {ReactComponent as Check} from "../../assets/icons/Check.svg";
import {ReactComponent as Close} from "../../assets/icons/Close.svg";
import {ShiftBatRowType, ShiftBatRowTypeLabel, ShiftBatRowTypeReverseMap} from "../../model/shiftBat";
import "./ShiftBat.css";
import {SyncOutlined} from "@ant-design/icons";
import ShiftBatSelectType from "./ShiftBatSelectType";
import {Col, Row} from "react-bootstrap";
import ShiftBatTimePicker from "./ShiftBatTimePicker";

function ShiftBatBreakRowEdit({
                                  type, time, priority,
                                  title, onUpdate,
                                  updating, setDuration, duration,
                                  shiftBatRow, setTitle, setTime,
                                  onCancel, resetRow
                              }) {

    const isValid = useCallback(() => {
        if ([ShiftBatRowType.break, ShiftBatRowType.breakMeal, ShiftBatRowType.breakBroken].includes(type)) {
            return parseInt(duration) > 0
        }

    }, [type, duration])

    return (
        <div>
            <Row className="row-compact align-items-center">
                <Col md="auto">
                    <ShiftBatTimePicker time={time} setTime={setTime}/>
                </Col>
                <Col className="row-col-gap">
                    <ShiftBatSelectType type={type} resetRow={resetRow}
                                        className={"inputselect-small"}/>
                    <Input placeholder="Duration" className="inputtext-small"
                           addonAfter="mins"
                           onChange={e => {
                               setDuration(e.target.value)
                               setTitle(`${ShiftBatRowTypeLabel[ShiftBatRowTypeReverseMap[type]]} (${e.target.value} mins)`)
                           }}
                           value={duration}/>
                </Col>
                <Col md="auto">
                    <div className="sb-edit-controls dir-row">
                        <Button className="sb-done" icon={<Check/>}
                                disabled={!isValid() || updating}
                                onClick={e => onUpdate(e, {
                                    ...shiftBatRow,
                                    type,
                                    time: parseInt(time),
                                    title,
                                    priority,
                                    duration: parseInt(duration) * 60
                                })}/>
                        <Button className="sb-close"
                                icon={updating && shiftBatRow.needsUpdate() ?
                                    <SyncOutlined spin={true}/> : <Close/>}
                                disabled={updating}
                                onClick={e => onCancel(e, shiftBatRow)}/>
                    </div>
                </Col>
            </Row>
        </div>
    );
}

export default React.memo(ShiftBatBreakRowEdit)
