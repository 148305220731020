import React from "react";
import "./ShiftBat.css"
import RouteNumber from "../../components/RouteNumber";
import {useAppContext} from "../../libs/contextLib";
import {find} from "lodash";

function ShiftBatTransferRowPrint({shiftBatStopRow, transfers, pickup}) {
    const {operator} = useAppContext()

    return (<>
        {/*<div className="row align-items-center align-items-stretch justify-content-center sb-row-transfers">*/}
        {/*<div className="col-3 sb-time"><span>{shiftBatStopRow.toTimeString()}</span></div>*/}
        {/*<div className="col-9 d-flex RouteTitle flex-wrap align-items-center sb-info">*/}
        <div className="sb-transfer-row-info d-flex align-items-center w-100">
            <div>{pickup ? 'Pickup' : 'Transfer'}</div>
            {transfers.map(tx => <RouteNumber key={'TxRid' + tx.routeId} route={tx}
                                              alias={!!operator.opts?.shiftbat?.transfersShowHeadsign ? find(tx.services, {tripId: tx.txTripId})?.transferAlias : null}/>)}
            {/*<div className="col-7 d-flex align-items-center flex-wrap"></div>*/}
        </div>
        {/*</div>  */}
        {/*</div>*/}
        {/*</div>*/}
    </>);
}

export default React.memo(ShiftBatTransferRowPrint)
