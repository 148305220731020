import {Button, Select, Tabs} from "antd";
import React, {useCallback, useEffect, useState} from "react";
import StopTimesTimetable from "./StopTimesTimetable";
import {noop, values} from "lodash";
import {Col, Row} from "react-bootstrap";
import {driverModelData, vehicleModelData} from "../services/ModelService";
import {useAppContext} from "../libs/contextLib";
import {schedule_options} from "../model/schedule";
import {PlusOutlined} from '@ant-design/icons';
import CalendarSelector from './CalendarSelector';

const {TabPane} = Tabs;

const createTripKey = (trip) => {
    return trip?.tripId
}

export default function TripsTimetable({
                                           route, setRoute, allStops, setFocusStop, setSelectedStop,
                                           selectedStop, selectedScheduleId, setUpdated, immutable = false,
                                           activeOnly, operator, onTripChange = noop,
                                           focusTransfers, setFocusTransfers = noop, setChatOpen, setChatContext
                                       }) {

    const {isAdmin} = useAppContext() || {};
    const [activeKey, setActiveKey] = useState(null);
    // eslint-disable-next-line
    const [vehicles, setVehicles] = useState([]);
    // eslint-disable-next-line
    const [drivers, setDrivers] = useState([]);
    useEffect(() => {

        const load = async () => {
            const vehicles = await vehicleModelData.getAll();
            setVehicles(values(vehicles).map(v => ({label: v.vehicleName, value: v.vehicleId})))
            const drivers = values(await driverModelData.getAll());
            setDrivers(drivers.map(v => ({label: v.driverName, value: v.driverId})))
        }

        load().then(() => console.log('drivers/vehicles loaded'))

    }, [setVehicles, setDrivers])

    useEffect(() => {
        if (route?.services?.length) {
            let trip = route.services[0]
            if (selectedScheduleId) {
                trip = route.services.filter(trip => trip.scheduleIds.includes(selectedScheduleId))[0]
            }
            setActiveKey(createTripKey(trip))
        }
    }, [route, setActiveKey, selectedScheduleId])

    return (
        <div className="col-lg-12">
            <div className="TimetableSchedule mt-3">
                <Row>
                    <Col lg={12} className={'p-0'}>
                        <Tabs
                            hideAdd
                            type="card"
                            onChange={(tripId) => {
                                console.log('onChange', tripId)
                                setActiveKey(tripId)
                                onTripChange(tripId)
                            }}
                            size={'middle'}
                            activeKey={activeKey}>
                            {route?.services && route.services.filter(trip => !selectedScheduleId || trip.scheduleIds.includes(selectedScheduleId))
                                .sort((t1, t2) => (t1.stopTimes[0]?.departHour * 3600 + t1.stopTimes[0]?.departMin * 60) - (t2.stopTimes[0]?.departHour * 3600 + t2.stopTimes[0]?.departMin * 60))
                                .map(trip => (
                                    <TabPane tab={trip.getStartTime()}
                                             key={createTripKey(trip)}>
                                        <Row>
                                            <Col lg={12}>
                                                <StopTimesTimetable
                                                    route={route}
                                                    setRoute={setRoute}
                                                    operator={operator}
                                                    immutable={true}
                                                    allStops={allStops}
                                                    trip={trip.clone()}
                                                    setFocusStop={setFocusStop}
                                                    setSelectedStop={setSelectedStop}
                                                    selectedStop={selectedStop}
                                                    setUpdated={setUpdated}
                                                    focusTransfers={focusTransfers}
                                                    setFocusTransfers={setFocusTransfers}
                                                />
                                            </Col>
                                        </Row>
                                    </TabPane>
                                ))}
                        </Tabs>
                    </Col>
                </Row>
            </div>
        </div>
    )
}
