import { cloneDeep } from "lodash";

export class Training {
    constructor(data) {
        this.id = null
        this.name = null;
        this.description = null;
        this.completedDate = null;
        this.type = null;

        Object.assign(this, data);
    }

    isOverdue() {
        if (this.completedDate == null) {
            return true;
        }
        const currentDate = new Date();
        const completedDate = new Date(this.completedDate);
        return completedDate < currentDate;
    }

    clone(training) {
        return new Training(cloneDeep(training));
    }
}