import * as React from 'react';
import './ControlPanel.css'
import {useState} from "react";
import {Button, ButtonGroup} from "react-bootstrap";

function ControlPanel(props) {
    const [editing, setEditing] = useState(props.editing)
    const {
        startEditFn,
        finishEditFn,
        closeControlFn,
        createControlFn,
        delControlFn,
        resetControlFn,
        trimEndControlFn,
        trimStartControlFn,
        enableTrimControl
    } = props

    function handleEditBtnClicked(event) {
        if (editing) {
            finishEditFn()
        } else {
            startEditFn();
        }
        event.preventDefault();
        setEditing(!editing)
    }

    return (
        <div className="ControlPanel" {...props}>
            {props.children}
            {!props.immutable || closeControlFn ? <div className='Footer'>
                <div className="ButtonGroup">
                    {!props.immutable && resetControlFn && editing &&
                        <Button
                            className='float-left'
                            size="sm"
                            variant="warning"
                            onClick={() => {
                                if (window.confirm(`Are you sure you want to reset? All changes will be lost.`)) {
                                    resetControlFn();
                                }
                            }}>Reset</Button>
                    }
                    {!props.immutable && startEditFn &&
                        <Button className='float-left' size='sm' variant={editing ? 'success' : 'primary'}
                                onClick={(e) => handleEditBtnClicked(e)}>{editing ? 'Done' : 'Edit'}</Button>
                    }
                    {!props.immutable && createControlFn && !editing &&
                        <Button className='float-left' size='sm' variant='info'
                                onClick={createControlFn}>Add Stop</Button>
                    }
                    {!props.immutable && trimStartControlFn &&
                        <Button className='float-right' size='sm' variant='warning'
                                disabled={!enableTrimControl()}
                                onClick={trimStartControlFn}>Trim to start</Button>
                    }
                    {!props.immutable && trimEndControlFn &&
                        <Button className='float-right' size='sm' variant='warning'
                                disabled={!enableTrimControl()}
                                onClick={trimEndControlFn}>Trim to end</Button>
                    }
                    {!props.immutable && delControlFn && !editing &&
                        <Button className='float-left' size='sm' variant='danger'
                                onClick={delControlFn}>Remove</Button>
                    }
                    {closeControlFn ?
                        <Button className='float-right btn-secondary' size='sm'
                                onClick={closeControlFn}>Close</Button> : <></>
                    }
                </div>
            </div> : <></>}
        </div>
    );
}

export default React.memo(ControlPanel);
