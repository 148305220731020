import React, { useEffect, useState } from "react";
import LoaderButton from "../../components/LoaderButton";
import { ReactComponent as Copy } from "../../assets/icons/Copy.svg";
import { ReactComponent as Trash } from "../../assets/icons/Trash.svg";
import { ReactComponent as Warning } from "../../assets/icons/Warning.svg";
import { Customer } from "../../model/customer";
import { Link } from "react-router-dom";
import { ListGroup } from "react-bootstrap";
import { customerModelData } from "../../services/ModelService";

const CustomerList = ({ customers, mergeMode }) => {
    const [data, setData] = useState([]);

    useEffect(() => {
        if (customers && setData) {
            setData(
                customers.map((customer) => {
                    return new Customer({
                        ...customer,
                        key: customer.customerId,
                        // warnings: customer.validateCustomer(customers),
                    });
                })
            );
        }
    }, [customers, setData]);

    return (
        <ListGroup>
            <div className="row list-group-header">
                <div className="col-lg-10">Customers</div>
                <div className="col-lg-1">Status</div>
                <div className="col-lg-1 text-center">Actions</div>
            </div>
            {data.map((customer) => {
                return (
                    <Link key={customer.customerId} to={`/customers/${customer.customerId}`} style={{ cursor: "pointer" }}>
                        <ListGroup.Item>
                            <div className="row">
                                <div className="col-lg-10">{customer.name}</div>
                                <div className="col-lg-1">{customer.requestedBy}</div>
                                <div className="col-lg-1 d-flex justify-content-around">
                                    <LoaderButton
                                        className="btn-icon-control"
                                        size="sm"
                                        // isLoading={isCloning}
                                        onClick={() => {}}
                                    >
                                        <Copy />
                                    </LoaderButton>
                                    <LoaderButton
                                        className="btn-icon-control btn-delete"
                                        size="sm"
                                        // confirmTitle={`Are you sure you want to delete ${customer.name}?`}
                                        onClick={() => {
                                            console.log("Delete student", customer.customerId)
                                            customerModelData.delete(customer.customerId, true).then(() => {
                                                console.log("Customer deleted.")
                                            })
                                        }}
                                    >
                                        <Trash />
                                    </LoaderButton>
                                </div>
                            </div>
                        </ListGroup.Item>
                    </Link>
                );
            })}
        </ListGroup>
    );
};

export default React.memo(CustomerList);
