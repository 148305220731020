import * as React from "react";


export default function BearingIcon({width ='20px', height = '20px', bearing, color = '#000'}) {
    return <svg xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
                width={width}
                height={height}>
        <path fill={color}
              transform={`rotate(${bearing - 90}, 256, 256)`}
              d="M504 256C504 119 393 8 256 8S8 119 8 256s111 248 248 248 248-111 248-248zm-448 0c0-110.5 89.5-200 200-200s200 89.5 200 200-89.5 200-200 200S56 366.5 56 256zm72 20v-40c0-6.6 5.4-12 12-12h116v-67c0-10.7 12.9-16 20.5-8.5l99 99c4.7 4.7 4.7 12.3 0 17l-99 99c-7.6 7.6-20.5 2.2-20.5-8.5v-67H140c-6.6 0-12-5.4-12-12z"/>
    </svg>
}