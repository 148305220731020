import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Button, Card, Col, Input, Row, Splitter, Table} from 'antd';
import {PlusOutlined, SyncOutlined} from '@ant-design/icons';
import WeeklyRosterTable from './WeeklyRosterTable';
import {toTime} from '../../libs/formatLib';
import './Rosters.css';
import {ReactComponent as BusPlus} from '../../assets/icons/BusPlus.svg';
import {ReactComponent as BusStat} from '../../assets/icons/BusStat.svg';
import {ReactComponent as BusQuote} from '../../assets/icons/BusQuote.svg';
import {DEFAULT_MAX_DRIVER_HRS} from '../../model/hrm/employee';
import {WeeklyRoster} from '../../model/roster';
import {WeeklyUnallocatedTable} from './WeeklyUnallocatedTable';
import {getWeeklyScenario, toCsv} from '../../libs/exportLib';
import fileDownload from 'js-file-download';
import dayjs from '../../dayjs';

const {Search} = Input;

const WeeklyScenarioView = ({
                                // originalScenario,
                                scenario, setScenario,
                                previousRoster,
                                isLoading,
                                showDashboards,
                                unallocatedRoster, operator,
                                employeeData, allVehicles, allSchedules, allStops, allRoutes
                            }) => {

    const [showUnallocated, setShowUnallocated] = useState(true);
    // const [scenario, setScenario] = useState(originalScenario);

    const [selectedWorkItem, setSelectedWorkItem] = useState(null);

    const [driverHours, setDriverHours] = useState(0);
    const [wages, setWages] = useState(0);
    const [overtime, setOvertime] = useState(0);
    const [otpercentage, setOTPercentage] = useState(0);

    const unallocatedPanel = useRef();
    const rostersPanel = useRef();

    const columns = [
        {
            title: 'Roster',
            dataIndex: 'name',
        },
        {
            title: 'Monday',
            dataIndex: 'age',
        },
        {
            title: 'Tuesday',
            dataIndex: 'address',
        },
        {
            title: 'Wednesday',
            dataIndex: 'address',
        },
        {
            title: 'Thursday',
            dataIndex: 'name',
        },
        {
            title: 'Friday',
            dataIndex: 'age',
        },
        {
            title: 'Saturday',
            dataIndex: 'address',
        },
        {
            title: 'Sunday',
            dataIndex: 'address',
        },
    ];

    const saveRoster = useCallback(rosters => {
        rosters = Array.isArray(rosters) ? rosters : [rosters];
        setScenario(scenario => {
            rosters.filter(roster => !!roster).forEach(roster => {
                scenario.weeklyRosters = scenario.weeklyRosters.map(r => r.rosterId === roster.rosterId ? WeeklyRoster.from(roster) : r);
                scenario.updateUnallocatedRoster(unallocatedRoster);
                scenario.unallocatedRoster = scenario.unallocatedRoster.clone();
            });
            return scenario.clone();
        });
    }, [setScenario]);

    const newRoster = useCallback(() => {
        setScenario(scenario => {
            scenario.weeklyRosters = scenario.weeklyRosters.concat(WeeklyRoster.from({
                date: scenario.date,
                rosterName: 'ROS-' + (scenario.weeklyRosters.length + 1)
            }));
            return scenario.clone();
        });

        // setAllRosters(allRosters => allRosters ? {
        //     ...allRosters,
        //     [roster.rosterId]: roster
        // } : {[roster.rosterId]: roster});
    }, [setScenario, setSelectedWorkItem]);

    // useEffect(() => {
    //     if (!scenario?.weeklyRosters) return;
    //     if (!scenario.weeklyRosters.filter(r => r.rosterId !== 'unallocated').length) {
    //         newRoster();
    //     }
    // }, [scenario?.weeklyRosters, newRoster]);

    useEffect(() => {
        if (scenario?.unallocatedRoster?.isEmpty()) {
            setShowUnallocated(false);
        }
    }, [scenario?.unallocatedRoster]);

    useEffect(() => {
        if (!scenario) return;

        let totals = scenario.weeklyRosters?.reduce((acc, roster) => {
            const seconds = acc.seconds + roster.getTotalTime();
            const rosterTimeHrs = roster.getTotalTime() / 3600;
            const rosterOvertime = rosterTimeHrs > DEFAULT_MAX_DRIVER_HRS ? (rosterTimeHrs - DEFAULT_MAX_DRIVER_HRS) : 0;
            const overtime = acc.overtime + rosterOvertime * 3600;
            const wages = acc.wages + roster.getTotalWages();
            return {seconds, overtime, wages};
        }, {seconds: 0, overtime: 0, wages: 0}) || {seconds: 0, overtime: 0, wages: 0};
        let seconds = Number.isFinite(totals.seconds) ? totals.seconds : 0;
        let overtime = Number.isFinite(totals.overtime) ? totals.overtime : 0;
        let wages = Number.isFinite(totals.wages) ? totals.wages : 0;

        setWages(wages);
        setDriverHours(toTime(seconds, false));
        setOvertime(toTime(overtime, false));
        if (seconds > 0) {
            setOTPercentage(Math.ceil(overtime / seconds * 100));
        }
    }, [scenario?.weeklyRosters]);

    const setWorkItemOptions = useCallback((workItem) => {
        setScenario(scenario => {
            scenario.addOptionalWorkItems(workItem);
            scenario.setBaseData({employeeData, allVehicles});
            scenario.weeklyRosters = [...scenario.weeklyRosters];
            return scenario;
        });
    }, [setScenario, allVehicles, employeeData]);

    const onSelectWorkItem = useCallback(workItem => {
        setSelectedWorkItem(current => current?.workItemId === workItem?.workItemId ? current : workItem);
        setWorkItemOptions(workItem);
    }, [setSelectedWorkItem, setWorkItemOptions]);

    return (<div key={scenario.scenarioId}>
            <Row key={scenario.scenarioId} gutter={[20, 20]}
                 className={`Dashboards mb-4 ${showDashboards ? 'show' : 'hide'}`}>
                <Col xs={24} lg={6} onClick={() => {
                    if (showUnallocated) {
                        onSelectWorkItem(null);
                    }
                    setShowUnallocated(!showUnallocated);
                }}>
                    <Card bordered={false}
                          className="card-main card-stats card-stats-flex">
                        <BusPlus/>
                        <div>
                            <h4>Unallocated</h4>
                            <div className="text-lg">{isLoading ?
                                <SyncOutlined spin/> : (scenario?.unallocatedRoster?.getTotalWorkItems() || 0)}</div>
                        </div>
                    </Card>
                </Col>
                <Col xs={24} lg={12}>
                    <Card bordered={false}
                          className="card-main card-stats card-stats-profit card-stats-flex">
                        <BusStat/>
                        <div>
                            <h4>Driver Hours</h4>
                            <div className="text-lg">{driverHours}</div>
                        </div>
                        <div>
                            <h4>Overtime</h4>
                            <div className="text-lg">{overtime}</div>
                        </div>
                        <div>
                            <h4>Overtime %</h4>
                            <div className="text-lg">{otpercentage}%</div>
                        </div>
                    </Card>
                </Col>
                <Col xs={24} lg={6}>
                    <Card bordered={false}
                          className="card-main card-stats card-stats-flex">
                        <BusQuote/>
                        <div>
                            <h4>Total Wages</h4>
                            <div className="text-lg">${wages.toFixed(2)}</div>
                        </div>
                    </Card>
                </Col>
            </Row>

            <div
                className="d-flex align-items-center justify-content-between filter-options-main">
                <div className="d-flex align-items-center" style={{gap: '10px'}}>
                    <Button type="primary" className="icon-button" icon={<PlusOutlined/>}
                            onClick={newRoster}>New Roster</Button>
                    <Button type="primary"
                            className={`icon-button btn-filled btn-error ${showUnallocated ? 'active' : ''}`}
                            onClick={() => {
                                if (showUnallocated) {
                                    onSelectWorkItem(null);
                                }
                                setShowUnallocated(!showUnallocated);
                            }}><i>{isLoading ? <SyncOutlined
                        spin/> : (scenario?.unallocatedRoster?.getTotalWorkItems() || 0)}</i> Unallocated</Button>

                    <Button type="primary" className={`icon-button`}
                            onClick={() => {
                                toCsv(getWeeklyScenario(scenario)).then(csv => {
                                    fileDownload(csv, `Busable_WorkItems_${dayjs().format('YYYY-MM-DD_HH:mm')}.csv`, 'text/csv');
                                });
                            }}>Export</Button>
                </div>
                {/*<div className="d-flex align-items-center">*/}
                {/*    <div className="justify-content-end w-separator">*/}
                {/*        <Search*/}
                {/*            id="basic-addon1"*/}
                {/*            style={{*/}
                {/*                width: 200,*/}
                {/*            }}*/}
                {/*            allowClear={true}*/}
                {/*            // onChange={(e) => setFilterDebounced(e.target.value)}*/}
                {/*        />*/}
                {/*    </div>*/}
                {/*    <div className="filter-switches">*/}
                {/*        /!*<FilterSwitches filterItems={filterItems} filterBy={filterBy}*!/*/}
                {/*        /!*                setFilterBy={setFilterBy}/>*!/*/}
                {/*    </div>*/}
                {/*</div>*/}
            </div>

            <div className="rosters-grid">
                <div className="rosters-grid-wrap">
                    <div className={'weekly-roster-table header-roster-table'}><Table columns={columns}/></div>
                    {showUnallocated ?
                        <Splitter layout="vertical" style={{height: showDashboards ? '60vh' : '70vh'}}>
                            <Splitter.Panel defaultSize={'30%'} min={'20%'} ref={unallocatedPanel}>
                                <WeeklyUnallocatedTable
                                    scenario={scenario}
                                    selectedWorkItem={selectedWorkItem}
                                    setSelectedWorkItem={onSelectWorkItem}
                                    scroll={{y: unallocatedPanel.current?.clientHeight || 900}}
                                />
                            </Splitter.Panel>
                            <Splitter.Panel min={'20%'} ref={rostersPanel}>
                                <WeeklyRosterTable
                                    showUnallocated={showUnallocated}
                                    previousRoster={previousRoster}
                                    scenario={scenario}
                                    setScenario={setScenario}
                                    saveRoster={saveRoster}
                                    newRoster={newRoster}
                                    employeeData={employeeData}
                                    allVehicles={allVehicles}
                                    allSchedules={allSchedules}
                                    selectedWorkItem={selectedWorkItem}
                                    setSelectedWorkItem={onSelectWorkItem}
                                    scroll={{y: rostersPanel.current?.clientHeight || 900}}
                                    allStops={allStops}
                                    allRoutes={allRoutes}
                                    schedules={allSchedules}
                                    operator={operator}
                                />
                            </Splitter.Panel>
                        </Splitter>
                        :
                        <WeeklyRosterTable
                            showUnallocated={showUnallocated}
                            previousRoster={previousRoster}
                            scenario={scenario}
                            setScenario={setScenario}
                            saveRoster={saveRoster}
                            newRoster={newRoster}
                            employeeData={employeeData}
                            allVehicles={allVehicles}
                            allSchedules={allSchedules}
                            selectedWorkItem={selectedWorkItem}
                            setSelectedWorkItem={setSelectedWorkItem}
                            allStops={allStops}
                            allRoutes={allRoutes}
                            schedules={allSchedules}

                            operator={operator}
                        />}
                </div>
            </div>
        </div>
    );
};

export default React.memo(WeeklyScenarioView);