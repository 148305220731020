import React, {useCallback, useState} from "react";
import EditItemView from "../EditItemView";
import {FEATURE} from "../../model/features";
import {Col, Form, Row} from "react-bootstrap";
import ScheduleName from "../../components/ScheduleName";
import {BsPlusCircle, BsXCircle} from "react-icons/bs";
import {OPERATING_DAY_DEFAULTS, OperatingDays, Period, PREDEFINED_SCHEDULES} from "../../model/schedule";
import util from "util";
import {DatePicker, Select} from "antd";
import {capitalize} from "lodash";
import {scheduleModelData} from "../../services/ModelService";
const {Option} = Select
const {RangePicker} = DatePicker;

function EditScheduleView(props) {
    const [schedule, setSchedule] = useState(props.editing);

    const schoolPeriodButtons = useCallback((include, schedule) => {
        // setSchedule(schedule => {

        const type = include ? 'Include calendars' : 'Exclude calendars'
        // const setIncludePeriods = include ? setSchedulePeriods : setExcludedPeriods
        // const setExcludePeriods = include ? setExcludedPeriods : setSchedulePeriods
        const subSchedules = include ? schedule.includedSchedules : schedule.excludedSchedules

        return (
            <div>
                <Select className={'mt-2'} style={{width: '267px'}} value={'none'} onChange={(val, el) => {
                    if (val.startsWith('schedule')) {

                        const sId = val.split(':')[1]
                        subSchedules.push(PREDEFINED_SCHEDULES[sId] || props.items[sId])
                        setSchedule({...schedule})
                        // setIncludePeriods(periods => (periods || []).filter(p => p.isValid()).concat(schedules[sId].schedulePeriods))
                        // setExcludePeriods(periods => (periods || []).filter(p => p.isValid()).concat(schedules[sId].excludedPeriods))
//                     } else if (val === 'schoolYear') {
//                         schedules.push(SCHOOL_YEAR_SCHEDULE)
//                         // setIncludePeriods(periods => (periods || []).filter(p => p.isValid()).concat(SCHOOL_YEAR_PERIOD))
//                     } else if (val === 'schoolTerms') {
//                         schedules.push(SCHOOL_TERMS_SCHEDULE)
//                         // setIncludePeriods(periods => (periods || []).filter(p => p.isValid()).concat(SCHOOL_TERM_PERIODS))
//                     } else if (val === 'schoolHols') {
//                         schedules.push(SCHOOL_HOLIDAY_SCHEDULE)
// // setIncludePeriods(periods => (periods || []).filter(p => p.isValid()).concat(SCHOOL_HOLIDAYS))
//                     } else if (val === 'publicHols') {
                        // setIncludePeriods(periods => (periods || []).filter(p => p.isValid()).concat(PUBLIC_HOLIDAY_PERIODS))
                    }
                    el.value = 'none'
                }}>

                    <Option key={`choose-${type}`} value={'none'}>{type}</Option>
                    {Object.keys(PREDEFINED_SCHEDULES).filter(sId => subSchedules.every(subSchedule => subSchedule.scheduleId !== sId)).map(sId => {
                        const schedule = PREDEFINED_SCHEDULES[sId]
                        return <Option key={`${type}-${sId}`} value={`schedule:${sId}`}><ScheduleName schedule={schedule}/></Option>
                    })}
                    {Object.keys(props.items).filter(sId => !subSchedules?.length || subSchedules.every(subSchedule => subSchedule?.scheduleId !== sId && !subSchedule.containsSubSchedule(schedule))).map(sId => {
                        const schedule = props.items[sId]
                        return <Option key={`${type}-${sId}`} value={`schedule:${sId}`}><ScheduleName schedule={schedule}/></Option>
                    })}
                </Select>
            </div>
        )
    }, [props.items, setSchedule]);

    return (
        <EditItemView item={schedule} feature={FEATURE.schdls} modelData={scheduleModelData}
                      {...props}
        >
            <Row><Col><Form.Group className="mb-3" controlId={schedule.scheduleId + '_ScheduleName'}>
                <Form.Label>Calendar Name</Form.Label>
                <Form.Control type="text" placeholder="Calendar name" disabled={schedule.shared} value={schedule.scheduleName || ''}
                              onChange={(e) => {
                                  // updateProp(schedule, 'scheduleName', e.target.value);
                                  console.log(e.target.value)
                                  // setScheduleName(e.target.value);
                                  setSchedule(schedule => {
                                      schedule.scheduleName = e.target.value;
                                      return {...schedule};
                                  })
                              }}/>
            </Form.Group></Col></Row>

            <Row><Col><Form.Group className="mb-3" controlId={schedule.scheduleId + '_SchedulePeriods'}>
                <Form.Label>Calendar Periods</Form.Label>
                {!schedule.shared && schoolPeriodButtons(true, schedule)}

                {schedule.includedSchedules?.length ? schedule.includedSchedules.map((includedSchedule, idx) => (
                    <div key={includedSchedule.scheduleId} className="d-flex flex-row">
                        <div className="mt-2 ml-2"
                             style={{width: '258px'}}><ScheduleName schedule={includedSchedule}/></div>
                        <div className="mt-2 ml-2">
                            {!schedule.shared && <BsXCircle size={17} onClick={() => {
                                setSchedule(schedule => {
                                    schedule.includedSchedules.splice(idx, 1)
                                    return {...schedule};
                                })
                            }}/>}
                        </div>
                    </div>
                )) : <></>}
                {schedule.schedulePeriods?.length ? schedule.schedulePeriods.map((schedulePeriod, idx) => (
                    <div key={schedulePeriod.id} className="d-flex flex-row">
                        <div className="mt-2">
                            <RangePicker disabled={schedule.shared}
                                         onChange={(value) => {
                                             setSchedule(schedule => {
                                                 const period = schedule.schedulePeriods[idx]
                                                 period.update(value)
                                                 return {...schedule};
                                             })
                                         }}
                                         value={[schedulePeriod.start, schedulePeriod.end]}
                                // defaultValue={[dayjs('2015/01/01', dateFormat), dayjs('2015/01/01', dateFormat)]}
                                         format={"DD/MM/YYYY"}
                            />
                        </div>
                        <div className="mt-2 ml-2">
                            {!schedule.shared && <BsXCircle size={17} onClick={() => {
                                setSchedule(schedule => {
                                    schedule.schedulePeriods.splice(idx, 1)
                                    return {...schedule};
                                })
                            }}/>}
                        </div>
                        {idx === schedule.schedulePeriods.length - 1 && <>
                            <div className="mt-2 ml-2">
                                {!schedule.shared && <BsPlusCircle size={17} onClick={() => setSchedule(schedule => {
                                    schedule.schedulePeriods.push(new Period())
                                    return {...schedule};
                                })
                                    //     setSchedulePeriods(periods => {
                                    //     return [...periods, new Period()];
                                    // })
                                }/>}
                            </div>
                            {/*{schoolPeriodButtons(true)}*/}
                        </>
                        }
                    </div>
                )) : !schedule.shared ? <div className="mt-2 ml-2">
                        <BsPlusCircle size={17} disabled={schedule.shared} className="ml-1" onClick={() => {
                            console.log('Clicked new period')
                            // const _schedulePeriods = [new Period({
                            //     start: dayjs().startOf('year'),
                            //     end: dayjs().endOf('year')
                            // })]
                            // setSchedulePeriods(_schedulePeriods);
                            setSchedule(schedule => {
                                console.log(schedule.schedulePeriods.length)
                                schedule.schedulePeriods = schedule.schedulePeriods.concat(new Period())
                                console.log(schedule.schedulePeriods.length)
                                return {...schedule};
                            })
                        }}/> new include period
                        {/*{schoolPeriodButtons(true)}*/}
                    </div>
                    : <></>}
            </Form.Group></Col>

                <Col><Form.Group className="mb-3" controlId={schedule.scheduleId + '_ExcludedPeriods'}>
                    <Form.Label>Excluded Periods</Form.Label>
                    {!schedule.shared && schoolPeriodButtons(false, schedule)}

                    {schedule.excludedSchedules?.length ? schedule.excludedSchedules.map((excludedSchedule, idx) => (
                        <div key={excludedSchedule.scheduleId} className="d-flex flex-row">
                            <div className="mt-2 ml-2"
                                 style={{width: '258px'}}><ScheduleName schedule={excludedSchedule}/> </div>
                            <div className="mt-2 ml-2">
                                {!schedule.shared && <BsXCircle size={17} onClick={() => {
                                    setSchedule(schedule => {
                                        schedule.excludedSchedules.splice(idx, 1)
                                        return {...schedule};
                                    })
                                }}/>}
                            </div>
                        </div>
                    )) : <></>}
                    {schedule.excludedPeriods.length ? schedule.excludedPeriods.map((excludedPeriod, idx) => (
                        <div key={excludedPeriod.id} className="d-flex flex-row">
                            <div className="mt-2">
                                <RangePicker disabled={schedule.shared}
                                             onChange={(value) => {
                                                 console.log('Updated excluded period with ', value)
                                                 setSchedule(schedule => {
                                                     const period = schedule.excludedPeriods[idx]
                                                     console.log('Updating period', util.inspect(period), value)
                                                     period.update(value)
                                                     console.log('New period', util.inspect(period), value)
                                                     return {...schedule};
                                                 })
                                             }}
                                             value={[excludedPeriod.start, excludedPeriod.end]}
                                             format={"DD/MM/YYYY"}
                                />
                            </div>
                            <div className="mt-2 ml-2">
                                {!schedule.shared &&  <BsXCircle size={17} onClick={() => {
                                    // setExcludedPeriods(periods => {
                                    //     return periods.filter(period => period.id !== excludedPeriod.id)
                                    // })
                                    setSchedule(schedule => {
                                        schedule.excludedPeriods.splice(idx, 1)
                                        return {...schedule};
                                    })
                                }}/>}
                            </div>
                            {idx === schedule.excludedPeriods.length - 1 && <>
                                <div className="mt-2 ml-2">
                                    {!schedule.shared &&  <BsPlusCircle size={17}
                                                                        onClick={() => setSchedule(schedule => {
                                                                            schedule.excludedPeriods.push(new Period())
                                                                            return {...schedule};
                                                                        })}/>}
                                </div>
                            </>
                            }
                        </div>
                    )) : !schedule.shared ? <div className="mt-2 ml-2">
                        <BsPlusCircle size={17} className="ml-1" disabled={schedule.shared}
                                      onClick={() => setSchedule(schedule => {
                                          schedule.excludedPeriods.push(new Period())
                                          return {...schedule};
                                      })
                                          // setExcludedPeriods(periods => [...periods, new Period()])
                                      }/> new exclude period
                    </div> : <></>}
                </Form.Group></Col></Row>
            {!schedule.shared && <Row><Col>
                <Form.Group className="mb-2" controlId="operatingDays">
                    <Form.Label>Operating days</Form.Label>
                    <div className={'mb-2'}>
                        <Select disabled={schedule.shared} className={'mt-2'} style={{width: '267px'}} value={'none'}
                                onChange={(val, el) => {
                                    // setOperatingDays(new OperatingDays(OPERATING_DAY_DEFAULTS[val]))
                                    setSchedule(schedule => {
                                        schedule.operatingDays = new OperatingDays(OPERATING_DAY_DEFAULTS[val])
                                        return {...schedule};
                                    })
                                    el.value = 'none'
                                }}>
                            <Option key={`choose-op`} value={'none'}>Operating days</Option>
                            {Object.keys(OPERATING_DAY_DEFAULTS).map(type => {
                                return <Option key={`OD-${type}`} value={type}>{type}</Option>
                            })}
                        </Select>
                    </div>
                    {Object.keys(schedule.operatingDays).map(day => (
                        <Form.Check disabled={schedule.shared} key={schedule.scheduleId + '_op_day_' + day} type="checkbox"
                                    label={capitalize(day)}
                                    value={day}
                                    onChange={(el) => {
                                        const checkbox = el.target;
                                        // schedule.operatingDays[day] = checkbox.checked
                                        // setOperatingDays(new OperatingDays({...operatingDays}));
                                        setSchedule(schedule => {
                                            schedule.operatingDays[day] = checkbox.checked
                                            return {...schedule};
                                        })
                                    }}
                                    checked={schedule.operatingDays[day]}/>
                    ))}
                </Form.Group></Col></Row>}
        </EditItemView>
    )
}

export default React.memo(EditScheduleView);

