import * as React from 'react';
import './RouteTitle.css'
import {Badge, ListGroup,} from 'react-bootstrap';
import RouteNumber from "./RouteNumber";
import {Flex, Popover} from "antd";
import {useEffect, useRef, useState} from "react";

function RouteNumberList({routes = [], placement = 'left', noLabel = false, noLogo = false}) {

    const popover = (
        <div style={{maxHeight: '75vh', overflowY: 'scroll'}}>
            <ListGroup>
                {routes.sort((r1, r2) => r1?.routeNumber?.localeCompare(r2.routeNumber)).map(r => (
                        <ListGroup.Item key={`filtered-list-RN-${r.routeId}`}>
                            <RouteNumber link
                                         size="sm" route={r} noLogo={noLogo}/>
                        </ListGroup.Item>
                    )
                )}
            </ListGroup>
        </div>
    );

    const RoutesOverlay = () => (
        <>{
            routes.length ?
                <Popover content={popover} trigger="hover" placement={placement}>
                    <Badge pill variant="light" size='sm' className='badge-outline badge-light'>
                        {noLabel ? routes.length : `Routes: ${routes.length}`}
                    </Badge>
                </Popover> :
                <Badge pill variant="light" size='sm' className='badge-outline badge-light'>
                    {noLabel ? routes.length : `Routes: ${routes.length}`}
                </Badge>
        }</>

        // <OverlayTrigger placement="auto" trigger={routes.length ? 'hover' : 'none'} overlay={popover}>
        //     <Badge pill variant="light" size='sm'>
        //         {`Routes: ${routes.length}`}
        //     </Badge>
        // </OverlayTrigger>
    );

    const RoutesInline = () => (
            <div className="RouteNumberListWrap">
                {
                    routes.length ?
                        <Flex gap={3}>
                            {routes.sort((r1, r2) => r1?.routeNumber?.localeCompare(r2.routeNumber)).map(r => (
                                    <RouteNumber key={"rInline"+r.routeId} link size="sm" route={r} noLogo={noLogo}/>
                                )
                            )}
                        </Flex> : <></>
                        // <Badge pill variant="light" size='sm' className='badge-outline badge-light'>
                        //     {`Routes: ${routes.length}`}
                        // </Badge>
                }
            </div>
        )
    ;
    return placement === 'inline' ? <RoutesInline/> : <RoutesOverlay/>;
}

export default React.memo(RouteNumberList);
