import React from "react";
import {Route, Redirect, useLocation} from "react-router-dom";
import config from "../config"
import {useAppContext} from "../libs/contextLib";

function AdminRoute({children, ...rest}) {
    const {pathname, search} = useLocation();
    const {isAuthenticating, isAdmin} = useAppContext();
    console.log(`AdminRoute: ${pathname}${search}`)
    return (
        <>
            {isAuthenticating ? <></> :
                <Route {...rest}>
                    {isAdmin || config.local ? (
                        children
                    ) : (
                        <Redirect to={
                            `/services`
                        }/>
                    )}
                </Route>
            }
        </>
    );
}

export default React.memo(AdminRoute)
