import React, {useState} from "react";
import EditItemView from "../EditItemView";
import {FEATURE} from "../../model/features";
import {Col, Form, Row} from "react-bootstrap";
import {jobModelData} from "../../services/ModelService";
import EmployeeSelect from "../../features/EmployeeHR/EmployeeSelect";
import ShiftBatSelect from "../../features/ShiftBat/ShiftBatSelect";
import {DATE_STRING} from "../../model/schedule";
import {DatePicker, Select} from "antd";
import {Job} from "../../model/job";
import CharterSelect from "../../features/Charters/CharterSelect";
import VehicleSelect from "../vehicles/VehicleSelect";
import dayjs from "../../dayjs";
import schedules from "../schedules/Schedules";
import {useAppContext} from "../../libs/contextLib";

const jobTypeOpts = [{value: 'shiftbat', label: 'Shift Bat'}, {value: 'job', label: 'Job'}, {
    value: 'charter',
    label: 'Charter'
}];
const allocationTypeOpts = [{value: 'employee', label: 'Employee'}, {value: 'vehicle', label: 'Vehicle'}];

function EditJobView(props) {
    const {schedules} = useAppContext();
    const [job, setJob] = useState(props.editing);
    const [type, setType] = useState(props.editing.type || 'shiftbat');
    const [allocationType, setAllocationType] = useState(props.editing.allocationType || 'employee');

    return (
        <EditItemView item={job} feature={FEATURE.rstrs} modelData={jobModelData}
                      {...props}
        >
            <Row><Col><Form.Group className="mb-3" controlId={job.jobId + '_JobId'}>
                <Form.Label>Job name</Form.Label>
                <Form.Control type="text" placeholder="Job name" value={job.name || ''}
                              onChange={(e) => {
                                  setJob(job => {
                                      job.name = e.target.value;
                                      return new Job({...job});
                                  })
                              }}/>
            </Form.Group></Col></Row>
            <Row><Col>
                <Form.Group className="mb-3" controlId={job.jobId + '_ShiftBat'}>
                    <Form.Label className="mr-2">Date</Form.Label>
                    <Form.Row className="ml-0">
                        <DatePicker placeholder="Select date"
                                    format={{
                                        format: DATE_STRING,
                                        type: 'mask',
                                    }}
                                    value={job?.date}
                                    onChange={date => {
                                        setJob(job => {
                                            job.date = date;
                                            return new Job({...job});
                                        })
                                    }}/>
                    </Form.Row>
                </Form.Group>
            </Col></Row>
            <Row>
                <Col>
                    <Form.Group className="mb-3" controlId={job.jobId + '_typeOpts'}>
                        <Form.Label className="mr-2">Job Type</Form.Label>
                        <Form.Row className="ml-0">
                            <Select style={{minWidth: '300px'}} onSelect={type => {
                                setType(type)
                                setJob(job => {
                                    job.type = type;
                                    return new Job({...job});
                                })
                            }} value={type} options={jobTypeOpts}/>
                        </Form.Row>
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group className="mb-3" controlId={job.jobId + '_ShiftBat'}>
                        <Form.Label className="mr-2">{type === 'shiftbat' ? 'Shift' : 'Charter'}</Form.Label>
                        <Form.Row className="ml-0">
                            {type === 'shiftbat' ?
                                <ShiftBatSelect style={{minWidth: '300px'}} onSelect={(id, sb) => {
                                    // const nextRunDate = sb.getNextRunDate(dayjs(), schedules);
                                    setJob(job => {
                                        job.typeId = id;
                                        // job.date = dayjs();
                                        // job.date = nextRunDate;
                                        // job.recurrenceType = 'dayOfWeek'
                                        // job.recurrence = nextRunDate?.format('dddd');
                                        return new Job({...job});
                                    })
                                }}
                                                value={job.typeId}
                                /> :
                                <CharterSelect style={{minWidth: '300px'}} onSelect={(id, charter) => {
                                    setJob(job => {
                                        job.typeId = id;
                                        job.date = dayjs(charter.startDate);
                                        return new Job({...job});
                                    })
                                }} value={job.typeId}
                                />}
                        </Form.Row>
                    </Form.Group>
                </Col> </Row>
            <Row>

                <Col>
                    <Form.Group className="mb-3" controlId={job.jobId + '_typeOpts'}>
                        <Form.Label className="mr-2">Allocation Type</Form.Label>
                        <Form.Row className="ml-0">
                            <Select style={{minWidth: '300px'}} onSelect={type => {
                                setAllocationType(type)
                                setJob(job => {
                                    job.allocationType = type;
                                    return new Job({...job});
                                })
                            }} value={allocationType} options={allocationTypeOpts}/>
                        </Form.Row>
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group className="mb-3" controlId={job.jobId + '_AllocationType'}>
                        <Form.Label className="mr-2">{allocationType === 'employee' ? 'Employee' : 'Vehicle'}</Form.Label>
                        <Form.Row className="ml-0">
                            {allocationType === 'employee' ?
                                <EmployeeSelect style={{minWidth: '300px'}}
                                                onSelect={employeeId => {
                                                    setJob(job => {
                                                        job.allocationId = employeeId;
                                                        return new Job({...job});
                                                    })
                                                }}
                                                value={job.allocationId}/> :
                                <VehicleSelect style={{minWidth: '300px'}}
                                               onSelect={employeeId => {
                                                   setJob(job => {
                                                       job.allocationId = employeeId;
                                                       return new Job({...job});
                                                   })
                                               }}
                                               value={job.allocationId}/>
                            }
                        </Form.Row>
                    </Form.Group>
                </Col></Row>
        </EditItemView>
    );
}

export default React.memo(EditJobView);

