import * as React from 'react';
//import Spinner from 'react-bootstrap/Spinner';
import "./LoadMessage.css";
import logoLoading from '../assets/images/logo-loading.gif'
import {Image} from "react-bootstrap";
import {Tooltip} from "antd";

function LoadMessage({message, title, height}) {
  return (
    <div className="row">
      <div className="col-lg-12 text-center app-loading">
        <div className='app-loading-logo'>
          {/* <Spinner animation="grow" variant="primary" size={size}/>
          <Spinner animation="grow" size={size} variant="warning"/>
          <Spinner animation="grow" variant="secondary" size={size}/> */}
            <Tooltip title={title}>
                <Image src={logoLoading} height={height || 73} />
            </Tooltip>
        </div>
        <div className='app-loading-text'>{message}</div>
      </div>
    </div>
  );
}

export default React.memo(LoadMessage);
