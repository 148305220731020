import { cloneDeep } from "lodash";

export class Superannuation {
    constructor(data) {
        this.effectiveDate = null;
        this.type = null;
        this.payRate = null;

        this.fundRecords = []

        Object.assign(this, data);
    }

    clone(superannuation) {
        return new Superannuation(cloneDeep(superannuation));
    }
}