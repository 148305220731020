import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isBetween from 'dayjs/plugin/isBetween';
import isoWeek from 'dayjs/plugin/isoWeek';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import localeData from 'dayjs/plugin/localeData';
import weekday from 'dayjs/plugin/weekday';
import duration from 'dayjs/plugin/duration';

import 'dayjs/locale/en-au';

dayjs.extend(customParseFormat)
    .extend(isSameOrAfter)
    .extend(isSameOrBefore)
    .extend(isBetween)
    .extend(isoWeek)
    .extend(localizedFormat)
    .extend(localeData)
    .extend(weekday)
    .extend(duration);
dayjs.locale('en-au');

export default dayjs;
