import React from "react";
import {Button} from "antd";
import {ReactComponent as Plus} from "../../assets/icons/Plus.svg";
import "./ShiftBat.css"
import {ShiftBatRowNotePriority} from "../../model/shiftBat";
import {ReactComponent as Trash} from "../../assets/icons/Trash.svg";
import {ReactComponent as Edit} from "../../assets/icons/Edit.svg";

function ShiftBatNoteRow({shiftBatNoteRow, editMode, onAdd, onDelete, onEdit}) {

    return (<>
        <div
            className={`row align-items-center align-items-stretch justify-content-center sb-comment-row ${shiftBatNoteRow.priority === ShiftBatRowNotePriority.high ? "sb-comment-important" : ""}`}>
            <div className="col-1 sb-time"></div>
            <div className="col-2 sb-step-no"></div>
            <div className="col-9 d-flex RouteTitle flex-nowrap align-items-center sb-info">
                <h4 className="sb-comment-row-info">{shiftBatNoteRow.title}</h4>
                {editMode &&
                    <div className="sb-controls">
                        {onEdit && <Button className="sb-edit-row" icon={<Edit/>} onClick={e => {
                            e.stopPropagation();
                            onEdit(e)
                        }}/>}
                        {onDelete && <Button className="sb-delete-row" icon={<Trash/>} onClick={e => {
                            e.stopPropagation();
                            onDelete(e)
                        }}/>}
                    </div>
                }
            </div>
            {editMode && onAdd && <Button className="sb-add-row" icon={<Plus/>} onClick={e => {
                e.stopPropagation();
                onAdd(e)
            }}/>}
        </div>
    </>);
}

export default React.memo(ShiftBatNoteRow)
