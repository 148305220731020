import {useEffect, useState} from 'react';
import {deadrunModelData, shiftBatModelData} from '../services/ModelService';
import {keyBy, values} from 'lodash';
import useAllRoutes from './useAllRoutes';
import useAllStops from './useAllStops';
import useAllTransfers from './useAllTransfers';
import {useAppContext} from '../libs/contextLib';

const useAllShiftBats = ({sortFn, filterFn} = {}) => {
    const {operator} = useAppContext();
    const [allShiftBats, setAllShiftBats] = useState(null);
    const [sortedShiftBats, setSortedShiftBats] = useState(null);
    const [allShiftBatsArray, setAllShiftBatsArray] = useState(null);
    const {allStops} = useAllStops();
    const {allRoutes} = useAllRoutes();
    const {allTransfers} = useAllTransfers();

    useEffect(() => {
        const listener = shiftBatModelData.addListener({
            setterFn: setAllShiftBats, loaded: shifts => {
                setAllShiftBats(shifts);
            }
        });

        return () => {
            if (listener)
                shiftBatModelData.removeListener(listener);
        };
    }, []);

    useEffect(() => {
        if (!allShiftBats || !allStops || !allTransfers || !allRoutes || !operator) {
            return;
        }
        if (!sortFn) {
            sortFn = (sb1, sb2) => sb1.getStartTime() - sb2.getStartTime();
        }
        let shiftBatsArray = values(allShiftBats).sort(sortFn);
        if (filterFn) {
            shiftBatsArray = shiftBatsArray.filter(filterFn);
        }
        Promise.all(shiftBatsArray.map(async sb => {
            return await Promise.all(
                sb.rows
                    .filter((row) => row.updateRow)
                    .map(async (row) =>
                        await row.updateRow({
                            apiKey: operator.operatorId,
                            allStops,
                            allTransfers,
                            allRoutes,
                            deadrunModelData,
                        })
                    )
            );
        })).then(() => {
            setSortedShiftBats(keyBy(shiftBatsArray, 'shiftBatId'));
            setAllShiftBatsArray(shiftBatsArray);
        });

    }, [allShiftBats, sortFn, setSortedShiftBats, setAllShiftBatsArray, allStops, allTransfers, allRoutes, operator]);

    return {allShiftBats: sortedShiftBats, allShiftBatsArray};
};

export default useAllShiftBats;