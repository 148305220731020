import {InfoCircleOutlined, PlusOutlined} from "@ant-design/icons";
import {Button, Divider, Popover, Select} from "antd";
import React, {useCallback, useEffect, useState} from "react";
import {Input, Modal} from "antd/lib";
import RouteNumber from "./RouteNumber";
import ShiftModal from "./Shift";
import {BusRoute} from "../model/busRoute";

const {Option} = Select;

const DIRECTIONS = {
    Regular: ["Inbound", "Outbound", "Loop"],
    School: ["AM", "PM"]
}

const RouteVariantDetailsModal = ({
                                      iconOnly,
                                      width,
                                      popPlacement,
                                      disabled,
                                      setRoute,
                                      route,
                                      driverShifts,
                                      vehicleShifts,
                                      handleNewDriverShift,
                                      handleNewVehicleShift,
                                  }) => {
    const [visible, setVisible] = useState(false);

    const [newVehicleShiftVisible, setNewVehicleShiftVisible] = useState(false);
    const [newDriverShiftVisible, setNewDriverShiftVisible] = useState(false);
    const [contractId, setContractId] = useState('');
    const [routeLabel, setRouteLabel] = useState('');
    const [direction, setDirection] = useState('');
    const [routeType, setRouteType] = useState('');

    useEffect(() => {
        setContractId(route.contractId || '')
        setRouteLabel(route.routeLabel || '')
        setDirection(route.direction || '');
        setRouteType(route.routeType || '');
    }, [route.routeLabel, route.contractId, setContractId, setRouteLabel, setDirection, setRouteType]);

    const save = useCallback(() => {
        if (route && route.contractId === contractId &&
            route.routeLabel === routeLabel &&
            route.routeType === routeType &&
            route.direction === direction) return;
        setRoute(new BusRoute({
            ...route,
            direction,
            routeType,
            contractId,
            routeLabel
        }));
    }, [setRoute, contractId, routeLabel, route, routeType, direction]);

    return (
        <>
            <Popover placement={popPlacement}
                     title={<span>Route Variant Details</span>}
                     content={<div><p>Edit the {route.routeNumber} route variant details</p></div>}
                     trigger="hover">
                <Button disabled={disabled}
                        onClick={() => {
                            setVisible(true)
                        }}
                        className="icon-button">
                            {/* <InfoCircleOutlined/>{!iconOnly && ' Details'} */}
                            {routeType} {direction}
                </Button>
            </Popover>
            <Modal
                open={visible}
                width={width || '50%'} style={{top: 60}}
                title={<div className="d-flex align-items-center" style={{gap: "15px"}}><div className={"trip-details-header d-flex flex-row"}><div className={""}><RouteNumber route={route}/></div></div>Route Details</div>}
                onCancel={() => {
                    setVisible(false)
                }}
                destroyOnClose={true}
                footer={[
                    <Button key="back" type="primary" className="btn-primary" onClick={() => {
                        save();
                        setVisible(false);
                    }}>
                        Done
                    </Button>,
                ]}
            >
                <div className="py-4">
                    <div className="row">
                        <div className="col-lg-12">
                            <h3 className="title">Shifts</h3>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6">
                            <div><h5 className="title mt-2">Driver shift</h5></div>
                            <div>
                                <ShiftModal shiftType="driver" visible={newDriverShiftVisible}
                                            handleNewShift={handleNewDriverShift}
                                            closeDisplay={() => setNewDriverShiftVisible(false)}/>
                                <Select style={{width: '100%'}} placeholder="Select a driver"
                                        onChange={(value) => setRoute(route => new BusRoute({
                                            ...route,
                                            driverShift: value
                                        }))}
                                        value={route.driverShift}
                                        dropdownRender={menu => (
                                            <div>
                                                {menu}
                                                <Divider style={{margin: '4px 0'}}/>
                                                <div style={{
                                                    display: 'flex',
                                                    flexWrap: 'nowrap',
                                                    padding: 8
                                                }}>
                                                    <Button
                                                        variant="outline-dark"
                                                        style={{
                                                            color: '#1890ff',
                                                            flex: 'none',
                                                            padding: '8px',
                                                            display: 'block',
                                                            cursor: 'pointer'
                                                        }}
                                                        onClick={() => setNewDriverShiftVisible(true)}
                                                    >
                                                        <PlusOutlined/> Driver Shift
                                                    </Button>
                                                </div>
                                            </div>
                                        )}>
                                    {driverShifts.map(driverShift => (
                                        <Option key={`Driver-Shift-${driverShift.shiftId}`}
                                                value={driverShift.shiftId}>{driverShift.shiftName}</Option>
                                    ))}
                                </Select>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div><h5 className="title mt-2">Vehicle shift</h5></div>
                            <div>
                                <ShiftModal shiftType="vehicle" visible={newVehicleShiftVisible}
                                            handleNewShift={handleNewVehicleShift}
                                            closeDisplay={() => setNewVehicleShiftVisible(false)}/>
                                <Select style={{width: '100%'}}
                                        placeholder="Select a vehicle"
                                        onChange={(value) => setRoute(route => new BusRoute({
                                            ...route,
                                            vehicleShift: value
                                        }))}
                                        value={route.vehicleShift}
                                        dropdownRender={menu => (
                                            <div>
                                                {menu}
                                                <Divider style={{margin: '4px 0'}}/>
                                                <div style={{
                                                    display: 'flex',
                                                    flexWrap: 'nowrap',
                                                    padding: 8
                                                }}>
                                                    <Button
                                                        variant="outline-dark"
                                                        style={{
                                                            color: '#1890ff',
                                                            flex: 'none',
                                                            padding: '8px',
                                                            display: 'block',
                                                            cursor: 'pointer'
                                                        }}
                                                        onClick={() => setNewVehicleShiftVisible(true)}
                                                    >
                                                        <PlusOutlined/> Vehicle Shift
                                                    </Button>
                                                </div>
                                            </div>
                                        )}>
                                    {vehicleShifts.map(vehicleShift => (
                                        <Option key={`Vehicle-Shift-${vehicleShift.shiftId}`}
                                                value={vehicleShift.shiftId}>{vehicleShift.shiftName}</Option>
                                    ))}
                                </Select>
                            </div>
                        </div>

                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <h3 className="title mt-4">Route Variant Details</h3>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6">
                            <div><h5 className="title mt-2">Type</h5></div>
                            <Select style={{width: '100%'}} placeholder="Select type"
                                    onChange={value => setRouteType(value)}
                                    value={routeType}>
                                <Option key={`RouteType-Regular`} value="Regular">Regular</Option>
                                <Option key={`RouteType-School`} value="School">School</Option>
                            </Select>
                        </div>
                        <div className="col-lg-6">
                            <div><h5 className="title mt-2">Direction</h5></div>
                            <Select style={{width: '100%'}}
                                    placeholder={`Select ${routeType ? 'direction' : 'type first'}`}
                                    onChange={value => setDirection(value)}
                                    value={direction}
                            >
                                {routeType && DIRECTIONS[routeType].map(direction => (
                                    <Option key={`RouteDirection-${direction}`}
                                            value={direction}>{direction}</Option>
                                ))}
                            </Select>
                        </div>
                        <div className="col-lg-6 mt-2">
                            <div><h5 className="title mt-2">Contract ID</h5></div>
                            <Input
                                placeholder={"Contract Id..."}
                                value={contractId || ''}
                                onChange={({target: {value}}) => {
                                    setContractId(value)
                                }}/>
                        </div>
                        <div className="col-lg-6 mt-2">
                            <div><h5 className="title mt-2">Route label</h5></div>
                            <Input
                                placeholder={"Route label..."}
                                value={routeLabel || ''}
                                onChange={({target: {value}}) => {
                                    setRouteLabel(value)
                                }}/>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
}

export default React.memo(RouteVariantDetailsModal)