import dayjs from "../dayjs";

export const FEATURES = {
    all: "All",
    links: "Stop Linking",
    transfers: 'Transfers',
    routes: "Route Management",
    schedules: "Scheduling",
    exports: "Data Exporting",
    stops: "Stop management",
    charters: "Chartering",
    rosters: "Rostering",
    students: "Student Tracking",
    duty: 'Duty management',
    shifts: 'Shift Bats',
    tfnsw: 'TfNSW TODIS Exporter',
    hrm: 'Human Resource Management',
    pub: 'Publishable',
    cmnts: 'Commenting',
}
export const FEATURE_KEYS = {
    all: "all",
    links: "lnks",
    transfers: 'trnsfrs',
    routes: "rtes",
    schedules: "schdls",
    exports: "xprts",
    stops: "stps",
    charters: "chrtrs",
    rosters: "rstrs",
    students: "stdnts",
    duty: "dty",
    shifts: 'shfts',
    tfnsw: 'tfnsw',
    hrm: 'hrm',
    pub: 'pub',
    commenting: 'cmnts',
}

export const FEATURE = {
    all: {name: FEATURES.all, key: 'all'},
    lnks: {name: FEATURES.links, key: 'lnks', beta: true},
    trnsfrs: {name: FEATURES.transfers, key: 'trnsfrs', beta: true},
    rtes: {name: FEATURES.routes, key: 'rtes', beta: true},
    schdls: {name: FEATURES.schedules, key: 'schdls', beta: true},
    xprts: {name: FEATURES.exports, key: 'xprts', beta: true},
    stps: {name: FEATURES.stops, key: 'stps', beta: true},
    chrtrs: {name: FEATURES.charters, key: 'chrtrs', beta: true},
    rstrs: {name: FEATURES.rosters, key: 'rstrs', beta: true},
    stdnts: {name: FEATURES.students, key: 'stdnts', beta: true},
    dty: {name: FEATURES.duty, key: 'dty', beta: true},
    shfts: {name: FEATURES.shifts, key: 'shfts', beta: true, trial: true},
    tfnsw: {name: FEATURES.tfnsw, key: 'tfnsw', beta: false},
    hrm: {name: FEATURES.hrm, key: 'hrm', beta: true},
    pub: {name: FEATURES.pub, key: 'pub', beta: true},
    cmnts: {name: FEATURES.cmnts, key: 'pub', beta: true},
}

export default class Features {


    constructor(data) {
        Object.assign(this, data);
        return this;
    }

    revoke() {
        Object.keys(this).forEach(key => delete this[key]);
    }

    access(feature) {
        // if (config.local || this.all) {
        //     return true;
        // }
        //
        return this.all || (feature.beta && this.beta) || this._checkAuth(feature.key)
        //
        // switch (feature) {
        //
        //     case FEATURES.links:
        //         return this.links();
        //     case FEATURES.transfers:
        //         return this.transfers();
        //     case FEATURES.routes:
        //         return this.routes()
        //     case FEATURES.schedules:
        //         return this.schedules();
        //     case FEATURES.exports:
        //         return this.exports()
        //     case FEATURES.stops:
        //         return this.stops()
        //     case FEATURES.charters:
        //         return this.charters()
        //     case FEATURES.rosters:
        //         return this.rosters()
        //     case FEATURES.students:
        //         return this.students()
        //     case FEATURES.fleet:
        //         return this.fleet()
        //     case FEATURES.hr:
        //         return this.hr()
        //     case FEATURES.shifts:
        //         return this.shifts()
        //     case FEATURES.tfnsw:
        //         return this.tfnsw()
        //     default:
        //         return false;
        // }
    }

    isInTrialPeriod(featureKey) {
        return typeof this[featureKey] === 'number'
    }

    _checkAuth(feature) {
        if (!feature) {
            return false
        }
        const authFeature = this[feature]
        if (this.isInTrialPeriod(feature)) {
            // convert to date and check date is after today
            let endTrialDate = dayjs(authFeature);
            return endTrialDate.isSameOrAfter(dayjs());
        }
        return authFeature
    }

    links() {
        return this.all || this.beta || this._checkAuth("lnks")
    }

    transfers() {
        return this.all || this.beta || this._checkAuth("trnsfrs")
    }

    routes() {
        return this.all || this.beta || this._checkAuth("rtes")
    }

    schedules() {
        return this.all || this.beta || this._checkAuth("schdls")
    }

    stops() {
        return this.all || this.beta || this._checkAuth("stps")
    }

    commenting() {
        return this.all || this.beta || this._checkAuth("cmnts")
    }

    tripPlannerAccurate() {
        return this.all || this.beta || this.tp?.acs
    }

    toString() {
        Object.keys(this).join(', ')
    }
}

// const features = new Features();
// export {features};
