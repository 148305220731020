import React, {useEffect, useState} from "react";
import {transferModelData} from "../services/ModelService";

const useAllTransfers = () => {
    const [allTransfers, setAllTransfers] = useState(null);

    useEffect(() => {
        const listener = transferModelData.addListener({
            setterFn: setAllTransfers, loaded: transfers => {
                setAllTransfers(transfers);
            }
        });

        return () => {
            if (listener)
                transferModelData.removeListener(listener)
        }
    }, [])

    return {
        allTransfers
    };
}
export default useAllTransfers;
