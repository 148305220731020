import {useEffect, useState} from "react";
import {routeCommentModelData, stopCommentModelData} from "../services/ModelService";

export default function useAllComments() {
    const [allRouteComments, setAllRouteComments] = useState([]);
    const [allStopComments, setAllStopComments] = useState([]);

    useEffect(() => {
        let routeListener = {
            setterFn: setAllRouteComments,
            loaded: cmnts => setAllRouteComments(cmnts)
        };
        let stopListener = {
            setterFn: setAllStopComments,
            loaded: cmnts => setAllStopComments(cmnts)
        };

        try {
            routeCommentModelData.addListener(routeListener)
            stopCommentModelData.addListener(stopListener)
        } catch (e) {
            console.error(e, e)
        }

        return () => {
            routeCommentModelData.removeListener(routeListener);
            stopCommentModelData.removeListener(stopListener);
        }

    }, [setAllRouteComments, setAllStopComments]);

    return {allRouteComments, allStopComments};
}