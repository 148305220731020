import {Storage} from "aws-amplify";
// AWS.config.update({
//     region: config.s3.REGION,
//     credentials: new AWS.CognitoIdentityCredentials({
//         IdentityPoolId: config.cognito.IDENTITY_POOL_ID,
//     }),
// });
//
// const s3 = new AWS.S3({
//     apiVersion: "2006-03-01",
//     params: {Bucket: config.s3.BUCKET},
// });

export async function s3Upload(file, filename, options) {
    options = options || {}
    if (options.level === 'private') {
        return await s3UploadPrivateDirectly(file, filename, options);
    }
    const stored = await Storage.vault.put(filename, file, {
        ...options,
        contentType: file.type,
    });

    return stored.key;
}

// export async function s3Photo(photoKey, file, operatorId, secure) {
//     (await Auth.currentSession()).
//     return new AWS.S3.ManagedUpload({
//         params: {
//             Bucket: config.s3.BUCKET,
//             Key: `${secure ? 'private' : 'public'}/${operatorId}/${photoKey}`,
//             Body: file,
//         },
//     }).promise()
// }

export async function s3UploadPrivateDirectly(file, filename, options) {
    options = options || {}
    let stored;
    try {
        stored = await Storage.put(filename.replaceAll(' ', '_'), file, {
            contentType: file.type,
            customPrefix: {
                public: 'private/'
            },
            completeCallback: (event) => {
                console.log(`Successfully uploaded ${event.key}`);
            },
            progressCallback: (progress) => {
                console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
            },
            errorCallback: (err) => {
                console.error('Unexpected error while uploading', err);
            }
        });
    } catch (e) {
        console.error('Error uploading file', e);
        throw e;
    }

    return stored.key;
}
