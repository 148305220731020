import {getDistance, getRhumbLineBearing} from 'geolib';
import {getStartTimeAsSecondsSinceMidnight, toTime} from "./formatLib";
import dayjs from "../dayjs";
import {point, lineString, nearestPointOnLine, bearing} from '@turf/turf'
// eslint-disable-next-line
import util from "util";
import {last} from "lodash";
import config from "../config";

export const HOURS = [...Array(24).keys()].map(i => i++);
export const MINUTES = [...Array(60).keys()].map(i => i++);
export const SECONDS_IN_DAY = 86400;

export const getDistanceInMetres = (coordinate1, coordinate2) => {
    let distance = -1
    try {
        distance = getDistance(
            coordinate1,
            coordinate2
        );
    } catch (e) {
        console.log('Error getting distance: ', coordinate1, coordinate2, e)
    }
    // if (result.unit === 'km') {
    //     distance = result.distance * 1000
    // } else {
    //     distance = result.distance
    // }
    return parseInt(distance)
}

export const updateStopAfterMoved = (stop, waypoints) => {
    // const {segment, distance} = getNearestSegment(stop, waypoints);
    //
    // if (distance < 250) {
    //     stop.delta = segment.nearestWp.delta;
    //     stop.distance = segment.nearestWp.distance;
    //     stop.avgSpd = segment.nearestWp.avgSpd;
    // } else {
    //     stop.delta = -1;
    //     stop.distance = -1;
    //     stop.avgSpd = -1;
    // }
}

// function sqr(x) {
//     return x * x
// }

// function dist2(v, w) {
//     return sqr(v.x - w.x) + sqr(v.y - w.y)
// }
//
// function distToSegmentSquared(p, v, w) {
//     var l2 = dist2(v, w);
//     if (l2 === 0) return dist2(p, v);
//     var t = ((p.x - v.x) * (w.x - v.x) + (p.y - v.y) * (w.y - v.y)) / l2;
//     t = Math.max(0, Math.min(1, t));
//     let ptOnLine = {
//         x: v.x + t * (w.x - v.x),
//         y: v.y + t * (w.y - v.y)
//     }
//     return {ptOnLine, dist: dist2(p, ptOnLine)};
// }

function distToSegment(origin, start, end) {
    const pt = new point([origin.lon, origin.lat]);
    const startPt = new point([start.lon, start.lat])
    const endPt = new point([end.lon, end.lat])
    const line = new lineString([[start.lon, start.lat], [end.lon, end.lat]]);
    // const dist = pointToLineDistance(pt, line, {units: 'meters'})
    const ptOnLine = nearestPointOnLine(line, pt, {units: 'meters'});
    // console.log(ptOnLine)
    const segmentBearing = bearing(startPt, endPt)
    const bearingToSeg = bearing(pt, ptOnLine)// - segmentBearing
    // const bearingToSeg = originToSegBearing < 0 ? 360 + originToSegBearing : originToSegBearing;

    return {
        ptOnLine: {lon: ptOnLine.geometry.coordinates[0], lat: ptOnLine.geometry.coordinates[1]},
        dist: ptOnLine.properties.dist,
        bearing: segmentBearing,
        bearingToSeg
    }
    // let p = {x: origin.lon, y: origin.lat}
    // let v = {x: start.lon, y: start.lat}
    // let w = {x: end.lon, y: end.lat}
    // const {ptOnLine, dist} = distToSegmentSquared(p, v, w);
    // return {ptOnLine, dist: Math.sqrt(dist)};
}

export const getNearestWaypoint = (origin, waypoints) => {
    let distance = -1
    let nearestWp = null
    let nearestIdx = -1
    for (let i = 0; i < waypoints.length; i++) {
        let wp = waypoints[i];
        let distanceToWp = getDistanceInMetres(origin, wp);
        if (distance === -1 || distanceToWp <= distance) {
            distance = distanceToWp
            nearestWp = wp
            nearestIdx = i
        }
        if (distance === 0) {
            break;
        }
    }
    return {
        nearestWp, origin, distance, nearestIdx
    }
}


export const getNearestWaypointFromStart = (origin, waypoints) => {
    let distance = -1
    let nearestWp = null
    let nearestIdx = -1
    for (let i = 0; i < waypoints.length; i++) {
        let wp = waypoints[i];
        let distanceToWp = getDistanceInMetres(origin, wp);
        if (distanceToWp > 50) {
            continue;
        }

        // Check for waypoints going in the wrong direction
        // If the bearing from the origin point to the waypoint is between 0 and 180 it means it is on the left hand side

        let segmentA = waypoints[i - 1], segmentB = wp;
        if (i === 0) {
            segmentA = wp;
            segmentB = waypoints[i + 1];
        }
        let segmentBearing = getRhumbLineBearing(segmentA, segmentB);
        let originBearing = getRhumbLineBearing(origin, wp) - segmentBearing;
        originBearing = originBearing < 0 ? 360 + originBearing : originBearing;
        if (originBearing > 180 || originBearing < 0) {
            continue
        }

        if (distance === -1 || distanceToWp < distance) {
            distance = distanceToWp;
            nearestWp = wp;
            nearestIdx = i;
        } else if (distanceToWp > distance) {
            break;
        }
        if (distance === 0) {
            break;
        }
    }
    return {
        nearestWp, origin, distance, nearestIdx
    }
}

export const getNearestWaypointFromEnd = (origin, waypoints) => {
    let distance = -1
    let nearestWp = null
    let nearestIdx = waypoints.length - 1
    for (let i = waypoints.length - 1; i >= 0; i--) {
        let wp = waypoints[i];
        let distanceToWp = getDistanceInMetres(origin, wp);
        if (distanceToWp > 50) {
            continue;
        }
        if (distance === -1 || distanceToWp < distance) {
            distance = distanceToWp
            nearestWp = wp
            nearestIdx = i
        } else if (distanceToWp >= distance) {
            break;
        }
        if (distance === 0) {
            break;
        }
    }
    return {
        nearestWp, origin, distance, nearestIdx
    }
}

export const getNearestSegment = (origin, waypoints, options = {}) => {
    options = {
        checkDirection: false,
        fromWpIdx: -1,
        maxPointsInTrack: 0,
        sortByDist: false,
        maxDistFrom: 100,
        log: false,
        furthestSeg: false,
        ...options
    };

    let distance = -1
    let nearestSegments = []
    let prevWp = null
    for (let i = options.fromWpIdx > -1 ? options.fromWpIdx + 1 : 1; i < waypoints.length; i++) {
        prevWp = waypoints[i - 1];
        let wp = waypoints[i];

        if (options.checkDirection) {
            // Check for waypoints going in the wrong direction
            // If the bearing from the origin point to the waypoint is between 0 and 180 it means it is on the left hand side
            let segmentBearing = getRhumbLineBearing(prevWp, wp);
            let originBearing = getRhumbLineBearing(origin, wp) - segmentBearing;
            originBearing = originBearing < 0 ? 360 + originBearing : originBearing;
            if (originBearing > 180 || originBearing < 0) {
                continue;
            }
        }

        const {ptOnLine, dist, bearing, ptBearing} = distToSegment(origin, prevWp, wp);
        // options.log && console.log(ptOnLine, dist, bearing, ptBearing);
        if (isNaN(dist)) {
            console.log('No distance.')
            continue;
        }


        if (options.maxDistFrom <= 0 || dist <= options.maxDistFrom) {
            let relativeBearingToSeg = Math.round(ptBearing - bearing)
            relativeBearingToSeg = relativeBearingToSeg < 0 ? 360 + relativeBearingToSeg : relativeBearingToSeg

            nearestSegments.push({
                start: prevWp,
                startIdx: i - 1,
                end: wp,
                endIdx: i,
                ptOnLine,
                dist,
                len: getDistanceInMetres(prevWp, wp),
                bearing: Math.abs(Math.round(bearing)),
                position: relativeBearingToSeg > 90 ? 'ahead' : relativeBearingToSeg < 90 ? 'behind' : 'perp'
            })
        }
    }

    if (!nearestSegments.length) {
        return;
    }
    nearestSegments.sort((seg1, seg2) => seg1.dist - seg2.dist)
    if (options.maxPointsInTrack > 0) {
        nearestSegments = nearestSegments.slice(0, options.maxPointsInTrack);
    }
    if (!options.sortByDist) {
        nearestSegments.sort((s1, s2) => s1.endIdx - s2.endIdx)
    }

    // console.log(nearestSegments)
    // Organise the nearestSegments into consecutive segments of the route so we can find the closest segment to the origin
    let tracks = []; // Consecutive segments close to the origin
    if (nearestSegments.length === 1) {
        tracks = [nearestSegments]
    } else {
        let currentTrack = [nearestSegments[0]];
        options.log && console.log(nearestSegments);
        options.log && console.log('Creating new track', nearestSegments[0].endIdx);
        nearestSegments.forEach((seg, idx) => {

            if (idx === 0) return
            if (seg.endIdx === last(currentTrack).endIdx + 1) {
                options.log && console.log('Adding seg', seg.endIdx);
                currentTrack.push(seg);
                return
            }

            options.log && console.log('End of current track', seg.endIdx);
            tracks.push(currentTrack);

            // If NOT last segment, create a new track with the first segment
            if (idx < nearestSegments.length) {
                options.log && console.log('Creating new track', seg.endIdx);
                currentTrack = [seg];
            }
        })
        tracks.push(currentTrack);
    }

    tracks = tracks.filter(track => !!track && track.length)
    // Order the tracks by shortest segment distance
    tracks.forEach(track => track.sort((seg1, seg2) => seg1.dist - seg2.dist));
    tracks.sort((t1, t2) => t1[0].dist - t2[0].dist);
    const shortestDist = tracks[0][0].dist;
    // Remove tracks clearly not on the same road
    tracks = tracks.filter(track => {
        return track[0].dist - shortestDist < 10
    })

    // Get the next  track in the route after fromWpIdx
    options.log && console.log('Tracks: ', tracks);
    let nextTrack = tracks.reduce((prevTrack, track) => {
        return (prevTrack && track[0].endIdx > prevTrack[0].endIdx) ? prevTrack : track
    }, null)

    if (options.furthestSeg) {
        nextTrack = tracks.reduce((prevTrack, track) => {
            return (prevTrack && track[0].endIdx < prevTrack[0].endIdx) ? prevTrack : track
        }, null);
    }

    options.log && console.log('Track: ', nextTrack);
    if (!nextTrack) {
        return;
    }
    // console.log(nextTrack)
    // The nearest segment is the one closest to the source on the next Track
    const nearestSegment = nextTrack[0];
    options.log && console.log('Nearest Seg: ', nearestSegment);

    const distToStart = getDistanceInMetres(origin, nearestSegment.start);
    const distToEnd = getDistanceInMetres(origin, nearestSegment.end)

    return {
        segment: {
            ...nearestSegment,
            calculatedClosestWp: nearestSegment.ptOnLine,
            distToStart,
            distToEnd,
            distanceToNearestWp: distToEnd > distToStart ? distToStart : distToEnd,
            nearestWp: distToEnd > distToStart ? nearestSegment.start : nearestSegment.end,
            nearestWpIdx: distToEnd > distToStart ? nearestSegment.startIdx : nearestSegment.endIdx
        }, origin, distance
    }
}

export const _getNearestSegment = (origin, waypoints, checkDirection, fromWpIdx = 1) => {
    let distance = -1
    let nearestSegment = null
    let prevWp = null
    for (let i = 1; i < waypoints.length; i++) {
        prevWp = waypoints[i - 1];
        let wp = waypoints[i];

        if (checkDirection) {
            // Check for waypoints going in the wrong direction
            // If the bearing from the origin point to the waypoint is between 0 and 180 it means it is on the left hand side
            let segmentBearing = getRhumbLineBearing(prevWp, wp);
            let originBearing = getRhumbLineBearing(origin, wp) - segmentBearing;
            originBearing = originBearing < 0 ? 360 + originBearing : originBearing;
            if (originBearing > 180 || originBearing < 0) {
                continue;
            }
        }

        const {ptOnLine, dist} = distToSegment(origin, prevWp, wp);
        if (isNaN(dist)) {
            continue;
        }
        // if (ptOnLine) {
        //     ptOnLine.lat = ptOnLine.y;
        //     ptOnLine.lon = ptOnLine.x;
        //     delete ptOnLine.x;
        //     delete ptOnLine.y;
        // }
        if (distance === -1 || dist < distance) {
            distance = dist
            nearestSegment = {start: prevWp, startIdx: i - 1, end: wp, endIdx: i, ptOnLine}
        }
        if (distance === 0) {
            break;
        }
    }
    const distToStart = getDistanceInMetres(origin, nearestSegment.start)
    const distToEnd = getDistanceInMetres(origin, nearestSegment.end)

    return {
        segment: {
            ...nearestSegment,
            calculatedClosestWp: nearestSegment.ptOnLine,
            distToStart: distToStart,
            distToEnd: distToEnd,
            distanceToNearestWp: distToEnd > distToStart ? distToStart : distToEnd,
            nearestWp: distToEnd > distToStart ? nearestSegment.start : nearestSegment.end,
            nearestWpIdx: distToEnd > distToStart ? nearestSegment.startIdx : nearestSegment.endIdx
        }, origin, distance
    }
}

export const getBearingAtCoordinate = (coordinate, route) => {
    const nearestSegment = getNearestSegment(coordinate, route.waypoints, {sortByDist: true});
    if (!nearestSegment) {
        return;
    }

    return getRhumbLineBearing(nearestSegment.segment.start, nearestSegment.segment.end);
}

export const updateRouteDeltas = (idx, route) => {
    idx = idx === 0 ? 1 : idx
    const lastWp = last(route.waypoints);
    let avgSpd = (lastWp?.distance > 0 && lastWp?.delta > 0) ? lastWp.distance / lastWp.delta : config.operator?.opts?.timetable?.avgSpd
    avgSpd = (Number.isFinite(avgSpd) && 11 <= avgSpd <= 25) ? avgSpd : (config.operator?.opts?.timetable?.avgSpd || 13.8) // default to 50km/hr (14 m/s)
    for (let i = idx; i < route.waypoints.length; i++) {
        const prevWp = route.waypoints[i - 1]
        const wp = route.waypoints[i]
        const distanceToPrevWp = getDistanceInMetres(prevWp, wp)
        const delta = distanceToPrevWp / avgSpd
        wp.delta = prevWp.delta + delta
        wp.distance = prevWp.distance + distanceToPrevWp
    }
    // route.waypoints.forEach(wp => wp.delta = Math.round(wp.delta / 60) * 60)
    // route.stops.forEach(stop => updateStopAfterMoved(stop, route.waypoints))
}

function reversePoints(points) {
    const totalDistance = points[points.length - 1].distance
    const totalDelta = points[points.length - 1].delta
    points.forEach((wp, idx) => {
        if (idx < points.length - 1) {
            const nextWp = points[idx + 1]
            wp.avgSpd = nextWp.avgSpd;
        }
        wp.delta = Math.round((totalDelta - wp.delta) / 60) * 60;
        wp.distance = totalDistance - wp.distance;
        delete wp.nearestSegment;
        delete wp.wpIdx;
    })
    points.reverse()
}

export const publicStopFilter = s => !!s && 'depot' !== s.stopType && 'nonpub' !== s.stopType
export const timingPtFilter = s => !!s && 'depot' !== s.stopType && 'nonpub' !== s.stopType && s.timingPoint

export const reverseRoute = (route) => {
    reversePoints(route.waypoints)
    reversePoints(route.stops)
    reversePoints(route.stopTimes)
    route.services.forEach(trip => reversePoints(trip.stopTimes));
    delete route.startWpIdx
    delete route.endWpIdx
    delete route.startStopIdx;
    delete route.endStopIdx;
    return route
}

export const getPreviousTimingPoint = (trip, stopIdx) => {
    const stops = trip.stopTimes || trip.stops
    return stops[Number.isFinite(stopIdx) && stopIdx > -1 ? stopIdx : 0];
    // if (stopIdx === 0) {
    //     return route.stops[0]
    // }
    // // return route.stops[0]
    // for (let i = stopIdx - 1; i >= 0; i--) {
    //     const stop = route.stops[i]
    //     if (stop.timingPoint && stop.departHour !== undefined && stop.departMin !== undefined) {
    //         return stop
    //     }
    // }
}

export const getDepartureTimesAsSecondsSinceMidnightForStopId = (trip, stopId) => {
    return trip.stopTimes.reduce((prev, matchingStopTimes) => {
        if (matchingStopTimes.stopId === stopId) {
            prev.push(matchingStopTimes)
        }
        return prev
    }, []).map(st => getDepartureTimeAsSecondsSinceMidnight(trip, st));
}

export const getArrivalTimeAsSecondsSinceMidnight = (trip, stopTime, stopIdx) => {
    if(stopTime?.arriveSecs !== undefined) {
        return stopTime.arriveSecs
    }
    return getDepartureTimeAsSecondsSinceMidnight(trip, stopTime, stopIdx) - (stopTime?.dwell || 0)
}

export const getDepartureTimeAsSecondsSinceMidnight  = (trip, stopTime, stopIdx) => {
    if(stopTime?.departSecs !== undefined) {
        return stopTime.departSecs
    }
    const prevTP = getPreviousTimingPoint(trip, stopIdx);
    if (!prevTP) {
        return;
    }
    const tripStartTimeInSecondsSinceMidnight = getStartTimeAsSecondsSinceMidnight(prevTP)
    return !stopTime || stopTime === prevTP ? tripStartTimeInSecondsSinceMidnight : tripStartTimeInSecondsSinceMidnight + (Math.round((stopTime.delta || 0) / 60) * 60)
}

export const getDepartureTimeAsDayjs = (trip, stop, stopIdx, date = dayjs()) => {
    return date.clone().startOf('day').add(getDepartureTimeAsSecondsSinceMidnight(trip, stop, stopIdx), 's');
}

export const getArrivalTimeAsDayjs = (trip, stop, stopIdx, date = dayjs()) => {
    return date.clone().startOf('day').add(getArrivalTimeAsSecondsSinceMidnight(trip, stop, stopIdx), 's');
}

export const getDepartureTime = (trip, stop, stopIdx) => {
    if (!stop) {
        return "--:--"
    }
    return toTime(getDepartureTimeAsSecondsSinceMidnight(trip, stop, stopIdx));
}


export const getSecondsTilNextDeparture = (departureSecondsSinceMidnight, date = dayjs()) => {
    const secondsSinceMidnight = dayjs().diff(date.clone().startOf('day'), 's');
    if (secondsSinceMidnight < departureSecondsSinceMidnight) {
        return departureSecondsSinceMidnight - secondsSinceMidnight
    }
    return departureSecondsSinceMidnight + SECONDS_IN_DAY - secondsSinceMidnight
}

export const hasLogo = (route) => route?.routeLogo?.length;
