import React, {useCallback} from "react";
import {toHrsMinsSecs, toKmMs} from "../libs/formatLib";
import RouteNumber from "./RouteNumber";
import './TripOverview.css';
import {capitalize, last} from "lodash";

import { ReactComponent as Walking } from '../assets/icons/Walking.svg';
import { ReactComponent as Driving } from '../assets/icons/Driving.svg';
import { ReactComponent as Right } from '../assets/icons/Right.svg';

export const renderConnectionImg = (type, size = 24) => {
    if (capitalize(type) === 'Walking') {
        return <div className="connect-transfer-walking"><Walking /></div>
    }
    if (capitalize(type) === 'Driving') {
        return <div className="connect-transfer-driving"><Driving /></div>
    }
    if (capitalize(type) === 'Transfer') {
        return <div className="connect-transfer-arrow"><Right /></div>
    }
}
// export const renderConnectionImg = (type, size = 24) => {
//     return <Image
//         src={`https://prod-rm-web-infra-gpx-s3-gpxfiles47af3947-1f2jzq8rsjwfq.s3.ap-southeast-2.amazonaws.com/public/${capitalize(type)}.svg`}
//         width={size} height={size} />
// }


function Legs({tripPlan}) {

    const renderConnectStart = useCallback((connection) => {
        return (<>
            {connection.duration > 0 && connection.type !== 'direct' &&
                <div className="ConnectStart connect-transfer">
                    {renderConnectionImg(connection.type)}
                    <div className="DetailsLabel">{toKmMs(connection.distance, 0)} | {connection.duration > 59 ? `${toHrsMinsSecs(connection.duration, false, true, 0)}` : '<1min'}</div>
                </div>}
            {connection.duration > 0 && connection.type !== 'direct' &&
                <div className="connect-transfer-arrow"><Right /></div>}
        </>)
    }, []);

    const renderConnectEnd = useCallback((connection) => {
        return (<>{connection.duration > 0 && connection.type !== 'direct' &&
            <div className="connect-transfer-arrow"><Right /></div>}
            {connection.duration > 0 && connection.type !== 'direct' &&
                <div className="ConnectEnd connect-transfer">
                    {renderConnectionImg(connection.type)}
                    <div className="DetailsLabel">{toKmMs(connection.distance, 0)} | {connection.duration > 59 ? `${toHrsMinsSecs(connection.duration, false, true, 0)}` : '<1min'}</div>
                </div>}
        </>)
    }, []);

    const renderTransfer = useCallback((connection) => {
        return (
            <div className="d-flex flex-row justify-content-center">
                {connection.duration > 0 ? <>
                    <div className="connect-transfer-arrow"><Right /></div>
                    <div className="connect-transfer">
                        {renderConnectionImg('walking')}
                        <div className="DetailsLabel">{toKmMs(connection.dist, 0)} | {connection.duration > 59 ? `${toHrsMinsSecs(connection.duration, false, true, 0)}` : '<1min'}</div>
                    </div>
                    <div className="connect-transfer-arrow"><Right /></div>
                </> : renderConnectionImg('transfer')}
            </div>
        )
    }, []);

    const renderLeg = useCallback((leg) => {
        return (
            <div key={"Leg_" + (leg.routeId || leg.route?.routeId)}
                 className="d-flex flex-row justify-content-center Leg">
                {leg.type === 'transfer' ?
                    renderTransfer(leg)
                    :
                    <div className="route-details-wrap">
                        <RouteNumber size="sm" route={leg.route} style={{margin: "0px"}}/>
                        <div className="DetailsLabel">
                            <span>{leg.route.getStopCount()} stop{leg.route.getStopCount() > 1 ? 's' : ''} | {toHrsMinsSecs(leg.duration, false, true)}</span>
                        </div>
                    </div>
                }
            </div>
        );
    }, [renderTransfer]);

    return (
        <div className="d-flex flex-row align-items-stretch flex-wrap">
            <div className="d-flex flex-row justify-content-center">{renderConnectStart(tripPlan.legs[0])}</div>
            <>{tripPlan.legs.slice(1, -1).map(renderLeg)}</>
            <div className="d-flex flex-row justify-content-center">{renderConnectEnd(last(tripPlan.legs))}</div>
        </div>
    )
}

export default React.memo(Legs);
