import React from "react";
import ShiftBatStopRow from "./ShiftBatStopRow";
function ShiftBatServiceRow({
                                shiftBatRow, onUpdate,
                                editMode, printMode, onSave, selectedStop, setSelectedStop, setFocusStop
                            }) {

    const stopRows = shiftBatRow.getStopRows();

    return (
        <div className="col-lg-12 sb-row-routes">
            {stopRows.map((shiftBatStopRow, idx) => (
                <div key={'SBSR' + shiftBatStopRow.id}>
                    <ShiftBatStopRow shiftBatStopRow={shiftBatStopRow} setSelectedStop={setSelectedStop}
                                     shiftBatServiceRow={shiftBatRow}
                                     route={shiftBatRow.route} trip={shiftBatRow.trip}
                                     stopTimeId={shiftBatStopRow.stopTimeId}
                                     route={shiftBatRow.route} trip={shiftBatRow.trip}
                                     stopTimeId={shiftBatStopRow.stopTimeId}
                                     setFocusStop={setFocusStop} selectedStop={selectedStop} printMode={printMode}
                                     editMode={editMode} onSave={(e) => onSave(e, shiftBatRow)}
                                     trimStart={idx > 0 ? e => {
                                         onUpdate(e, {
                                             ...shiftBatRow,
                                             startStopIdx: shiftBatRow.trip.stopTimes.findIndex(st => st.stopTimeId === shiftBatStopRow.stopTimeId)
                                         })
                                     } : null}
                                     trimEnd={idx < stopRows.length-1 ? e => {
                                         onUpdate(e, {
                                             ...shiftBatRow,
                                             endStopIdx: shiftBatRow.trip.stopTimes.findIndex(st => st.stopTimeId === shiftBatStopRow.stopTimeId)
                                         })
                                     } : null}
                    />
                </div>))}
        </div>
    );
}

export default React.memo(ShiftBatServiceRow)
