import React, {memo, useState} from 'react';
import {Button, Modal, Switch, Typography} from 'antd';
import {operatorModelData} from '../../services/ModelService';
import {SyncOutlined} from '@ant-design/icons';
import {useAppContext} from '../../libs/contextLib';

const Paragraph = Typography.Paragraph;

export const ComplianceDisclaimerModal = memo(({}) => {
    const {operator, setOperator} = useAppContext();
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);

    return (operator ?
            <>
                <Button type="primary" className={`icon-button btn-filled ${operator.opts?.compliance?.accept ? 'active' : ''}`} icon={loading ? <SyncOutlined spin/> : null}
                        onClick={() => setVisible(true)}>Compliance</Button>
                <Modal
                    open={visible}
                    width={550}
                    title={`Compliance disclaimer`}
                    closeable={true}
                    onCancel={() => setVisible(false)}
                    destroyOnClose={true}
                    footer={[
                        <Button key="back" className="btn-secondary" onClick={() => {
                            setVisible(false);
                        }}>Close</Button>
                    ]}
                >
                    <div className="compliance-check pt-4 pb-2">
                        <Paragraph><strong>Disclaimer:</strong> Busable’s rostering software is designed to support compliance with Australian Heavy Vehicle Fatigue Management legislation. However, Busable is not liable for any legal breaches resulting from its use. Bus operators are responsible for ensuring driver compliance with all fatigue management laws; our software is a support tool, not a substitute for compliance oversight.</Paragraph>
                        <Paragraph>Do you agree? <Switch checkedChildren="Yes" loading={loading} unCheckedChildren="No" checked={operator.opts?.compliance?.accept}
                                onChange={async checked => {
                                    setLoading(true);
                                    const _operator = {...operator, opts: {...operator.opts, compliance: {accept: checked}}};
                                    try {
                                        await operatorModelData.save(_operator);
                                    } catch (e) {
                                        console.log('Error saving operator: ', e);
                                    }
                                    setOperator(_operator);
                                    setLoading(false);
                                }}/></Paragraph>
                    </div>
                </Modal>
            </> : <></>
    );
});
