import React, {useEffect, useState} from "react";
import Button from "react-bootstrap/Button";
import {BsArrowRepeat} from "react-icons/bs";
import "./LoaderButton.css";
import {useAppContext} from "../libs/contextLib";
import {checkFeatureAuth} from "../App";
import {Popconfirm} from "antd/lib";

export default function LoaderButton({
                                         isLoading,
                                         className = "",
                                         disabled = false,
                                         feature,
                                         onClick,
                                         confirmTitle,
                                         ...props
                                     }) {

    const {operator, setFaultState} = useAppContext() || {};
    const [onClickFn, setOnClickFn] = useState(null);

    useEffect(() => {
        if (!onClick) {
            return
        }

        if (!feature) {
            setOnClickFn(_ => {
                return async (e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    await onClick(e);
                }
            });
            return;
        }

        setOnClickFn(_ => {
            return e => {
                e.preventDefault();
                e.stopPropagation();
                checkFeatureAuth(setFaultState, operator, feature).then(result => {
                    if (result) {
                        onClick(e);
                    }
                })
            }
        });
    }, [onClick, feature, setOnClickFn, operator, setFaultState])

    return (
        <>
            {confirmTitle ?
                <Popconfirm title={confirmTitle} onConfirm={onClickFn} okText="Yes" cancelText="No">
                    <Button
                        disabled={disabled || isLoading}
                        className={`LoaderButton ${className}`}
                        {...props}
                    >
                        {isLoading ? <BsArrowRepeat className="spinning"/> : props.icon ? props.icon : <></>}
                        {props.children}
                    </Button>
                </Popconfirm> :

                <Button
                    disabled={disabled || isLoading}
                    className={`LoaderButton ${className}`}
                    onClick={onClickFn}
                    {...props}
                >
                    {isLoading ? <BsArrowRepeat className="spinning"/> : props.icon ? props.icon : <></>}
                    {props.children}
                </Button>
            }
        </>
    );
}
