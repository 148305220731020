import React, {useCallback, useEffect, useState} from "react";
import {ListGroup} from 'react-bootstrap';
import "./DuplicateStopList.css";
import {find, pick, values} from "lodash";
// eslint-disable-next-line
import EditStop from "./EditStop";
import DisplayStop from "./DisplayStop";
import TransferView from "./TransferView";
import log from "loglevel";
import {isPointInPolygon} from "geolib";
import {Table, Tooltip} from "antd";
import LoadMessage from "./LoadMessage";
import {ReactComponent as Services} from '../assets/icons/Services.svg';
import {ReactComponent as Transfers} from '../assets/icons/Transfers.svg';
import {ReactComponent as Warning} from '../assets/icons/Warning.svg';
import TransfersList from "./TransfersList";
import {GlobalOutlined, WarningFilled} from "@ant-design/icons";
import RouteNumberList from "./RouteNumberList";

const logger = log.getLogger('PointManager');
const selectedSort = (selectedIds, s1, s2) => {
    return (selectedIds.includes(s2.stopId) ? 1 : 0) - (selectedIds.includes(s1.stopId) ? 1 : 0);
};

function FilteredStopList({
                              showmasterStops,
                              setSelected,
                              masterStops,
                              allStops,
                              filteredStops,
                              editStop,
                              setEditStop,
                              transferStop,
                              selected,
                              handleStopClick,
                              onFocusStop,
                              handleSaveStop,
                              scrollRef,
                              focusStop,
                              handleCancelEdit,
                              handleCancelTransfer,
                              setUpdated,
                              handleStopMoved,
                              allowedStopTypes,
                              distanceToCursor,
                              bounds
                          }) {
    const [isEditing, setIsEditing] = useState(false);
    const [stopsToShow, setStopsToShow] = useState(null);
    const [listInFocus, setListInFocus] = useState(false);

    useEffect(() => {
        let stopsToShow = values(filteredStops)

        if (bounds) {
            stopsToShow = stopsToShow.filter(stop => {
                return isPointInPolygon({latitude: stop.lat, longitude: stop.lon}, bounds);
            });
            console.log(`Stops in bounds: ${stopsToShow.length}`);
        }

        if (showmasterStops && masterStops?.length) {
            stopsToShow = stopsToShow.concat(masterStops);
        }

        const selectedStopIds = Object.keys(selected);

        stopsToShow.sort((s1, s2) => (s1.stopName || '').localeCompare(s2.stopName || ''))
        // if (selectedStopIds?.length) {
        //     stopsToShow.sort((s1, s2) => selectedSort(selectedStopIds, s1, s2))
        // }
        setStopsToShow(stopsToShow);
    }, [setStopsToShow, filteredStops, showmasterStops, masterStops, bounds, selected]);

    useEffect(() => {
        if (editStop) {
            setIsEditing(editStop.stopId + "");
            // setStops(stops => [...stops]);
        }
    }, [setIsEditing, editStop]);

    const getColumns = useCallback(() => {
        const columns = [
            {
                title: 'Name',
                dataIndex: 'stopId',
                render: (_, stop) => {
                    return <DisplayStop className="FilteredStopRow" key={`DisplayStop-${stop.stopId}`}
                                        noInterchange={true}
                                        noRoutes={true}
                                        noWarning={true}
                                        stop={stop}
                                        isEditing={isEditing}
                                        handleSaveStop={handleSaveStop}
                        // onClick={e => {
                        //     if (handleStopClick) {
                        //         handleStopClick({
                        //             stop,
                        //             zoom: !e.ctrlKey && !e.metaKey && !e.nativeEvent?.metaKey,
                        //             shift: e.ctrlKey || (e.metaKey || e.nativeEvent?.metaKey)
                        //         });
                        //     }
                        // }}
                                        onFocus={() => {
                                            if (!isEditing && onFocusStop) {
                                                onFocusStop(stop);
                                            }
                                        }}
                                        onBlur={() => {
                                            if (!isEditing && onFocusStop) {
                                                onFocusStop(null);
                                            }
                                        }}
                    />
                }
            },
            {
                title: <Tooltip placement="top" title="Routes"><Services className="header-icon w-16"/></Tooltip>,
                dataIndex: 'route',
                key: 'route',
                align: 'center',
                width: 50,
                render: (_, stop) => {
                    return <RouteNumberList routes={stop.routes || []} noLabel={true}/>;
                }
            },
            {
                title: <Tooltip placement="top" title="Transfers"><Transfers className="header-icon"/></Tooltip>,
                dataIndex: 'transfer',
                key: 'transfer',
                align: 'center',
                width: 50,
                render: (_, stop) => {
                    return <TransfersList stop={stop} handleSaveStop={handleSaveStop} noLabel={true}/>;
                }
            },
            {
                title: <Tooltip placement="top" title="Validations"><Warning className="header-icon w-16"/></Tooltip>,
                dataIndex: 'validation',
                key: 'validation',
                align: 'center',
                width: 50,
                render: (_, stop) => {
                    return stop.master ?
                        <GlobalOutlined title='Point from shared data source' style={{color: '#0670F9'}}/>
                        : !stop.verified ?
                            <WarningFilled title='Unverified point' style={{color: '#dc3545'}}/>
                            : stop.warnings?.length ?
                                <Tooltip title={<div><div>{stop.warnings.map(m =>
                                    <div>{m}</div>)}</div></div>}><WarningFilled style={{color: '#dc3545'}}/></Tooltip>
                                : <></>;
                }
            }
        ];
        return columns
    }, [scrollRef, selected, handleSaveStop, handleStopClick, isEditing, onFocusStop, focusStop]);

    const getData = useCallback(() => {
        const data = stopsToShow.map((stop) => {
            return {
                key: stop.stopId,
                ...stop,
                isEditing,
                selected,
                handleSaveStop
            }
        })
        return data;
    }, [stopsToShow, isEditing, selected, handleSaveStop])

    const keyPressed = useCallback(async ({key}) => {
        if (!listInFocus) {
            return;
        }
        key = key.toLowerCase();
        console.log('Key pressed: ', key)
        if (selected && !editStop && Object.keys(selected).length === 1 && key === 'e') {
            setEditStop({...values(selected)[0], moved: false})
        }
    }, [listInFocus, selected, editStop, setEditStop]);

    useEffect(() => {
        window.addEventListener('keyup', keyPressed);
        return () => {
            window.removeEventListener('keyup', keyPressed);
        };
    }, [keyPressed]);

    logger.debug('Refreshing DOM: FilteredStopList')
    return (
        <div className="FilteredStopList"
             onMouseOver={() => {
                 setListInFocus(true);
             }}
             onMouseOut={() => {
                 setListInFocus(false);
             }}>
            {process.env.REACT_APP_VERBOSE && !editStop &&
                <div className="mb-3"><h4 className="title">{stopsToShow?.length} points in view</h4></div>
            }

            {editStop ?
                <EditStop key={`EditStop-${editStop.stopId}`}
                          allStops={allStops}
                          selectedStops={selected}
                          stop={editStop}
                          setUpdated={setUpdated}
                          handleStopMoved={handleStopMoved}
                          allowedStopTypes={allowedStopTypes}
                          distanceToCursor={distanceToCursor}
                          cancelEditStop={async (stop) => {
                              setUpdated(false);
                              setIsEditing(false);
                              await handleCancelEdit(stop);
                          }}
                          handleSaveEdit={async (stop) => {
                              setUpdated(false);
                              setIsEditing(false);
                              await handleSaveStop(stop);
                          }}/>
                : transferStop ?
                    <TransferView key={`TransferStop-${transferStop.stopId}`}
                                  selectedStops={selected}
                                  allStops={allStops}
                                  stop={transferStop}
                                  cancelTransferStop={async (stop) => {
                                      setUpdated(false);
                                      await handleCancelTransfer(stop);
                                  }}
                                  handleSaveTransfer={async (stop) => {
                                      setUpdated(false);
                                      await handleSaveStop(stop);
                                  }}
                    />
                    :

                    stopsToShow ? <Table columns={getColumns()} className="FilteredStopTable"
                                         pagination={{
                                             pageSizeOptions: [10, 20, 50],
                                             hideOnSinglePage: true,
                                         }}
                                         scroll={{
                                            y: 'auto',
                                          }}
                                         dataSource={getData()}
                                         onRow={(record, rowIndex) => {
                                             const stop = allStops[record.stopId] || find(masterStops, {stopId: record.stopId})
                                             return {
                                                 onClick: (event) => {
                                                     const shift = event.ctrlKey || event.shiftKey || event.metaKey || event.nativeEvent.metaKey || event.nativeEvent.shiftKey;
                                                     handleStopClick({stop, zoom: false, shift})
                                                 },
                                                 onDoubleClick: (event) => {
                                                     handleStopClick({stop, zoom: true})
                                                 },
                                                 onMouseEnter: (event) => {
                                                     if (!isEditing && onFocusStop) {
                                                         onFocusStop(stop);
                                                     }
                                                 },
                                                 onMouseLeave: (event) => {
                                                     if (!isEditing && onFocusStop) {
                                                         onFocusStop(null);
                                                     }
                                                 },
                                             };
                                         }}
                                         rowSelection={{
                                             selections: [
                                                 Table.SELECTION_NONE
                                             ],
                                             selectedRowKeys: Object.keys(selected),
                                             onChange: (selectedRowKeys, selectedRows, info) => {
                                                 const stops = selectedRowKeys.reduce((acc, key) => {
                                                     const stop = allStops[key] || find(masterStops, {stopId: key});
                                                     return {...acc, [key]: stop};
                                                 }, {});
                                                 setSelected(stops)
                                             }
                                         }}
                        /> :
                        <LoadMessage message={'Loading stops...'}/>
            }

        </div>
    );
}

export default React.memo(FilteredStopList);

