import React, {useCallback, useEffect, useRef, useState} from "react";
import {
    Button,
    Col,
    Row,
    ToggleButton,
    ToggleButtonGroup
} from "react-bootstrap";
import {CONNECTIONS, Prefs, WALKING_SPEED} from "../model/prefs";
import {Divider, Modal, Select, Switch} from "antd";
import "./TripPlannerAccordian.css"

const {Option} = Select;

function TripPlannerPrefsView({
                                  setPrefs,
                                  prefs,
                                  clearFn,
                                  showPrefs,
                                  onClosePrefs,
                              }) {

    const [show, setShow] = useState(false);
    const target = useRef(null);

    const [connectEnd, setConnectEnd] = useState(prefs.connectEnd);
    const [connectStart, setConnectStart] = useState(prefs.connectStart);
    const [maxConnectStartTime, setMaxConnectStartTime] = useState(prefs.maxConnectStartTime);
    const [maxConnectEndTime, setMaxConnectEndTime] = useState(prefs.maxConnectEndTime);
    const [walkingSpeed, setWalkingSpeed] = useState(prefs.walkingSpeed);
    const [avoidTransfers, setAvoidTransfers] = useState(prefs.avoidTransfers || false);
    const [avoidSchool, setAvoidSchool] = useState(prefs.avoidSchool || false);

    useEffect(() => {
        setShow(showPrefs)
    }, [setShow, showPrefs])

    const savePrefs = useCallback(({
                                       connectEnd,
                                       connectStart,
                                       maxConnectStartTime,
                                       maxConnectEndTime,
                                       walkingSpeed,
                                       avoidTransfers,
                                       avoidSchool
                                   }) => {
        if (clearFn) {
            clearFn();
        }
        setPrefs(prefs => {
            const _prefs = {
                ...prefs,
                connectEnd,
                connectStart,
                maxConnectStartTime: parseInt(maxConnectStartTime),
                maxConnectEndTime: parseInt(maxConnectEndTime),
                walkingSpeed: parseFloat(walkingSpeed),
                avoidTransfers,
                avoidSchool
            }
            return new Prefs(_prefs, true)
        })

    }, [setPrefs, clearFn]);

    const overlay = useCallback(({
                                     connectEnd,
                                     connectStart,
                                     maxConnectStartTime,
                                     maxConnectEndTime,
                                     walkingSpeed,
                                     avoidTransfers,
                                     avoidSchool
                                 }) => {
        return <Modal
            width="400px"
            open={show}
            title={`Connecting with our services`}
            centered
            destroyOnClose
            getContainer="#busable-embed,#root"
            onCancel={() => {
                setShow(false)
                onClosePrefs();
            }}
            footer={[
                <Button key="back" variant={"outline-danger"} className="mr-2 btn-secondary"
                        size={"sm"} onClick={() => {
                    setShow(false)
                    onClosePrefs();
                }}>
                    Cancel
                </Button>,
                <Button className="btn-primary" key="apply" variant={"outline-primary"}
                        size={"sm"}
                        onClick={() => {
                            savePrefs({
                                connectEnd,
                                connectStart,
                                maxConnectStartTime,
                                maxConnectEndTime,
                                walkingSpeed,
                                avoidTransfers,
                                avoidSchool
                            });
                            setShow(false);
                            onClosePrefs();
                        }}>
                    Apply
                </Button>,
            ]}
        >
            <div>
                <Row>
                    
                    <Col xs={6} style={{}}>
                        
                        <Row>
                            <Col>
                                <h6>Getting to</h6>
                            </Col>
                        </Row>

                        <Row>
                            <Col style={{paddingTop: "10px"}}>
                                <ToggleButtonGroup type="radio" name="connectEnd" value={connectStart}
                                                   className="mb-2"
                                                   size="sm"
                                                   style={{width: "100px"}}
                                                   vertical
                                                   onChange={setConnectStart}>
                                    {Object.keys(CONNECTIONS).map((d, idx) => (

                                        <ToggleButton
                                            key={`ConnectToSelectionBtn-${idx}`}
                                            variant="outline-primary"
                                            name={CONNECTIONS[d]}
                                            value={CONNECTIONS[d]}
                                            checked={connectStart === CONNECTIONS[d]}
                                        >{CONNECTIONS[d]}</ToggleButton>
                                    ))}
                                </ToggleButtonGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                Up to
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Row>
                                    <Col><span className={"TimeLabel Walking"}>{connectStart}:</span>
                                        <Select
                                            style={{
                                                marginTop: "10px",
                                                width: "100px",
                                                textAlign: "center",
                                                height: "30px"
                                            }}
                                            onChange={setMaxConnectStartTime}
                                            value={maxConnectStartTime}>
                                            {[0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60].map(mins => (
                                                <Option key={'END_TIME_OPT' + mins}
                                                        value={mins}>{mins} mins</Option>
                                            ))}
                                        </Select>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={6} style={{}}>
                        <Row>
                            <Col>
                                <h6>Getting from</h6>
                            </Col>
                        </Row>
                        <Row>
                            <Col style={{paddingTop: "10px"}}>
                                <ToggleButtonGroup type="radio" name="connectStart" value={connectEnd}
                                                   className="mb-2"
                                                   size="sm"
                                                   style={{width: "100px"}}
                                                   vertical
                                                   onChange={setConnectEnd}>
                                    {Object.keys(CONNECTIONS).map((d, idx) => (

                                        <ToggleButton
                                            key={`ConnectFromSelectionBtn-${idx}`}
                                            variant="outline-primary"
                                            name={CONNECTIONS[d]}
                                            value={CONNECTIONS[d]}
                                            checked={connectEnd === CONNECTIONS[d]}
                                        >{CONNECTIONS[d]}</ToggleButton>
                                    ))}
                                </ToggleButtonGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                Up to
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Row>
                                    <Col><span className={"TimeLabel Walking"}>{connectEnd}:</span>
                                        <Select
                                            style={{
                                                marginTop: "10px",
                                                width: "100px",
                                                textAlign: "center",
                                                height: "30px"
                                            }}
                                            onChange={setMaxConnectEndTime}
                                            value={maxConnectEndTime}>
                                            {[0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60].map(mins => (
                                                <Option key={'END_TIME_OPT' + mins}
                                                        value={mins}>{mins} mins</Option>
                                            ))}
                                        </Select>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row><Col><Divider/></Col></Row>
                <Row>
                    <Col><h6>Walking Speed</h6></Col>
                </Row>
                <Row>
                    <Col>
                        <Select
                            style={{marginTop: "10px", width: "100%", textAlign: "center"}}
                            onChange={setWalkingSpeed}
                            value={'' + walkingSpeed}>
                            {Object.keys(WALKING_SPEED).map(speed => (
                                <Option key={'SPD_' + speed} value={speed}>{WALKING_SPEED[speed]}</Option>
                            ))}
                        </Select>
                    </Col>
                </Row><Row><Col><Divider/></Col></Row>
                <Row>
                    <Col><h6>Avoid</h6></Col>
                </Row>
                <Row>
                    <Col>Service transfers<Switch className='ml-2' size='small' checked={avoidTransfers}
                                                                      onChange={(checked) => {
                                                                          setAvoidTransfers(checked);
                                                                      }}/></Col>
                </Row>
                <Row>
                    <Col>School services<Switch className='ml-2' size='small' checked={avoidSchool}
                                                    onChange={(checked) => {
                                                        setAvoidSchool(checked);
                                                    }}/></Col>
                </Row>
            </div>
        </Modal>
    }, [show, setShow, setConnectStart, setConnectEnd, setMaxConnectStartTime, setMaxConnectEndTime, savePrefs, onClosePrefs, setAvoidTransfers, setAvoidSchool]);//, setDir]);

    return (
        <div ref={target} className={''}
        >
            {/*<Button variant={"light"} className={'mt-0'}*/}
            {/*        style={{border: '1px solid #ccc'}}*/}
            {/*        size={"sm"}*/}
            {/*        onClick={() => setShow(true)}>*/}
            {/*    <BranchesOutlined className={"pt-0"}/>*/}
            {/*</Button>*/}

            {overlay({
                connectEnd,
                connectStart,
                maxConnectStartTime,
                maxConnectEndTime,
                walkingSpeed,
                avoidTransfers,
                avoidSchool
            })}
        </div>
    )
}

export default React.memo(TripPlannerPrefsView);

