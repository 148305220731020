import React, {memo, useState} from 'react';
import {Avatar, Button, Card, Col, Flex, Form, InputNumber, Modal, Row, TimePicker, Tooltip} from 'antd';
import {createAcronym} from '../Charters/CharterItinerary';
import LoadMessage from '../../components/LoadMessage';
import SelectEmployeeView, {employeeName} from './SelectEmployeeView';
import SelectVehicleView from './SelectVehicleView';
import ShareModal from '../../components/ShareModal';
import {CloseOutlined, EyeOutlined} from '@ant-design/icons';
import {ReactComponent as Print} from '../../assets/icons/Print.svg';
import {ReactComponent as Edit} from '../../assets/icons/Edit.svg';
import ShiftBatPrintModal from '../ShiftBat/ShiftBatPrintModal';
import config from '../../config';
import {dayjsToSecsSinceMidnight, secsSinceMidnightToDayjs} from '../../model/timeFilter';

const BearingMarker = memo(({rotation}) => {
    return (
        <div className="VehicleBearing">
            <div className="VehicleBearingMarker" style={{transform: `rotate(${rotation}deg)`}}></div>
        </div>
    );
});

export const EmployeeAvatar = memo(({employeeId, employeeName, employeeAvatarUrl, statusColour = '#C1C5CE'}) => {
    return <Avatar key={`employee-avatar-${employeeId}`}
                   style={{backgroundColor: statusColour, verticalAlign: 'middle'}}
                   size={34}
                   src={employeeAvatarUrl}>{createAcronym(employeeName)}</Avatar>;
});

export const Allocation = memo(({
                                    employeeId,
                                    employeeName,
                                    employeeAvatarUrl,
                                    vehicleName,
                                    statusColour,
                                    onClick
                                }) => (
    <div
        className={`allocation-item ${employeeName === '?' ? 'driver-error' : ''} ${vehicleName ? '' : 'vehicle-error'}`}
        onClick={onClick}>
        <div className="row RouteTitle flex-nowrap align-items-center w-100 py-0">
            <div className={`RouteLogo`}>
                <EmployeeAvatar employeeId={employeeId} employeeName={employeeName}
                                employeeAvatarUrl={employeeAvatarUrl}/>
                <div className="driver-name">{employeeName}</div>
            </div>

            <span className="RouteNumber" style={{backgroundColor: statusColour}}>{vehicleName}</span>
        </div>
    </div>));


export const AllocationModalDiv = memo(({
                                            scenario,
                                            roster,
                                            workItem,
                                            isDefault = false,
                                            employeeId,
                                            vehicleId,
                                            employeeData,
                                            allVehicles,
                                            save, saveRoster,
                                            allStops, allRoutes, schedules, operator,
                                            isOpen, setIsOpen
                                        }) => {

    const [selectedEmployee, setSelectedEmployee] = useState(employeeData.getEmployee(employeeId));
    const [selectedVehicle, setSelectedVehicle] = useState(allVehicles[vehicleId]);
    const [printShiftBat, setPrintShiftBat] = useState(false);

    const onClose = () => setIsOpen(false);

    return (
        <>{employeeData && allVehicles ? <>
                <Modal
                    open={isOpen}
                    width={850}
                    style={{top: 50}}
                    onCancel={onClose}
                    onClose={onClose}
                    closable={true}
                    destroyOnClose
                    footer={[
                        <Button className="btn-secondary" key="employee-close-btn" onClick={onClose}>Cancel</Button>,
                        <Button key="employee-save-btn" type="primary" className="btn-primary"
                                disabled={!selectedEmployee && !selectedVehicle}
                                onClick={() => {
                                    save({employee: selectedEmployee, vehicle: selectedVehicle});
                                    if (workItem) {
                                        saveRoster(roster);
                                    }
                                    onClose();
                                }}>Save</Button>
                    ]}
                >
                    {workItem && <>
                        <div className="ant-modal-header d-flex justify-content-between hide-print"
                             style={{paddingRight: '28px', marginBottom: '25px'}}>
                            <div>
                                <div
                                    className="ant-modal-title">{workItem ? workItem.workItemName : roster.rosterName}</div>
                                {workItem && <span>{workItem.startTimeFormatted} - {workItem.endTimeFormatted}</span>}
                            </div>
                            <div className="d-flex align-items-center" style={{gap: '10px'}}>
                                <a className="info-icon-button light"
                                   href={`${config.app.BASE_URL}/shiftbats/${workItem.duty?.shiftBatId}`}
                                   target={'_blank'}><EyeOutlined/>View duty</a>
                                <ShiftBatPrintModal
                                    allStops={allStops}
                                    allRoutes={allRoutes}
                                    apiKey={operator?.operatorId}
                                    shiftBat={workItem.duty}
                                    visible={printShiftBat}
                                    schedules={schedules}
                                    setVisible={setPrintShiftBat}/>
                                <Button className="info-icon-button light" icon={<Print style={{width: 16}}/>}
                                        onClick={() => setPrintShiftBat(!printShiftBat)}>Print</Button>
                                <div className="info-icon-button-wrap light"><ShareModal shiftBat={workItem.duty} /></div>
                                <Tooltip
                                    title={`Remove work item from ${roster.rosterName} and return to Unallocated.`}>
                                    <Button className={'info-icon-button light btn-error'}
                                            icon={<CloseOutlined/>}
                                            onClick={() => {
                                                roster.removeWorkItem(workItem);
                                                saveRoster(roster);
                                            }}>Unallocate</Button></Tooltip>
                            </div>
                        </div>
                        <Form layout="vertical" className="modal-info pb-5">
                            <Row gutter={[30, 30]}>
                                <Col xs={24} lg={8} className="justify-content-center">
                                    <div className="RouteLogo w-100 justify-content-start"
                                         style={{border: 'none', gap: '8px'}}>
                                        <EmployeeAvatar employeeId={employeeId} employeeName={'Graham Weber'}
                                            //employeeAvatarUrl={employeeAvatarUrl}
                                        />
                                        <div className="driver-name"
                                             style={{fontSize: 15}}>{selectedEmployee.firstName} {selectedEmployee.lastName}</div>
                                    </div>
                                </Col>
                                <Col xs={24} lg={8}>
                                    <label>Assigned vehicle</label>
                                    <div className="w-100">
                                        {selectedVehicle.vehicleName}
                                    </div>
                                </Col>
                                <Col xs={24} lg={8}>
                                    <label>Scheduled hours</label>
                                    <div className="w-100">
                                        {workItem.scheduledHours}
                                    </div>
                                </Col>
                                <Col xs={24} lg={8}>
                                    <label>Actual start</label>
                                    <div className="w-100">
                                        <TimePicker value={secsSinceMidnightToDayjs(workItem.actualStart)}
                                                    needConfirm={false} showNow={false} format={'HH:mm'}
                                                    onChange={time => {
                                                        workItem.actualStart = dayjsToSecsSinceMidnight(time);
                                                        saveRoster(roster);
                                                    }} className="w-100"/>
                                    </div>
                                </Col>
                                <Col xs={24} lg={8}>
                                    <label>Actual end</label>
                                    <div className="w-100">
                                        <TimePicker value={secsSinceMidnightToDayjs(workItem.actualEnd)}
                                                    needConfirm={false} showNow={false}
                                                    format={'HH:mm'} onChange={time => {
                                            workItem.actualEnd = dayjsToSecsSinceMidnight(time);
                                            saveRoster(roster);
                                        }} className="w-100"/>
                                    </div>
                                </Col>
                                <Col xs={24} lg={8}>
                                    <label>Actual pay hours</label>
                                    <div className="w-100">
                                        <InputNumber value={workItem.actualPayHours.toFixed(2)} onChange={value => {
                                            workItem.actualPayHours = value;
                                            saveRoster(roster);
                                        }} className="w-100"/>
                                    </div>
                                </Col>
                            </Row>
                        </Form> </>}

                    <div className="ant-modal-header">
                        <div className="ant-modal-title">{workItem ? '' : 'Default'} Allocation</div>
                    </div>
                    <Flex style={{gap: '30px'}}>
                        <Card title={'Select Employee'} style={{width: '50%'}}>
                            <SelectEmployeeView scenario={scenario} roster={roster} isDefault={isDefault}
                                                selectedEmployee={selectedEmployee}
                                                setSelectedEmployee={setSelectedEmployee}
                                                employeeData={employeeData}/>
                        </Card>
                        <Card title={'Select Vehicle'} style={{width: '50%'}}>
                            <SelectVehicleView scenario={scenario} roster={roster} isDefault={isDefault}
                                               selectedVehicle={selectedVehicle}
                                               setSelectedVehicle={setSelectedVehicle}
                                               allVehicles={allVehicles}/>
                        </Card></Flex>
                </Modal> </> :
            <LoadMessage title="Loading staff..."/>
        }
        </>
    );
});

export const AllocationModal = memo(({...props}) => {
    const [isOpen, setIsOpen] = useState(false);
    const {employeeData, allVehicles, employeeId, vehicleId, } = props;

    return (
        <>{employeeData && allVehicles  ? <>
                <Allocation onClick={e => {
                    e.stopPropagation();
                    e.preventDefault();
                    setIsOpen(true);
                }} employeeId={employeeData.getEmployee(employeeId)?.employeeID}
                            employeeName={employeeName(employeeData.getEmployee(employeeId))}
                            vehicleName={allVehicles[vehicleId]?.vehicleName} />
                <AllocationModalDiv {...props} isOpen={isOpen} setIsOpen={setIsOpen}/> </> :
            <LoadMessage title="Loading staff..."/>
        }
        </>
    );
});

export const AllocationBtnModal = memo(({...props}) => {
    const [isOpen, setIsOpen] = useState(false);

    return (<>
        <Button icon={<Edit/>}
                onClick={() => setIsOpen(!isOpen)}/>
        <AllocationModalDiv {...props} isOpen={isOpen} setIsOpen={setIsOpen}/>
    </>);
});
