import React, { useState } from 'react';
import './CharterForm.css'
import {Col, Row, Card, Steps, theme, message, TimePicker, Button, Input, DatePicker} from 'antd';
import { ReactComponent as Bus } from "../../assets/icons/Bus.svg";
import { ReactComponent as Pin } from "../../assets/icons/Pin.svg";
import { ReactComponent as User } from "../../assets/icons/User_s.svg";
import { ReactComponent as Close } from "../../assets/icons/Close.svg";
import { ReactComponent as Phone } from "../../assets/icons/Phone.svg";
import { ReactComponent as Email } from "../../assets/icons/Email.svg";
import { ReactComponent as Notes } from "../../assets/icons/Notes.svg";
import { ReactComponent as Back } from "../../assets/icons/Back.svg";

const { TextArea } = Input;

const steps = [
    {
      title: 'Charter Details',
      content: <div className='CharterWrap CharterDetails'>
            <div className='CharterRow'>
                <div className='CharterRowHeader'>
                    <div className='CharterRowIcon'><Bus style={{width: 16}} /></div>
                    <h4>Starting Point</h4>
                </div>
                <div className='CharterRowContent'>
                    <Input placeholder="Choose starting point" size="large" />
                    <DatePicker placeholder="Pickup date" size="large" />
                    <TimePicker placeholder="Pickup time" size="large" format={"HH:mm"} />
                </div>
            </div>
            <div className='CharterRow'>
                <div className='CharterRowHeader'>
                    <div className='CharterRowIcon'>
                        <div className='IconCircle'></div>
                    </div>
                    <h4>Stop 1</h4>
                    <span>Estimated arrival on <strong>10/07/2024</strong> at <strong>5:00pm</strong></span>
                    <Button className="btn-close" icon={<Close/>} />
                </div>
                <div className='CharterRowContent'>
                    <Input placeholder="Choose stop" size="large" />
                    <DatePicker placeholder="Depart date" size="large" />
                    <TimePicker placeholder="Depart time" size="large" format={"HH:mm"} />
                </div>
            </div>
            <div className='CharterRow'>
                <div className='CharterRowHeader'>
                    <div className='CharterRowIcon'><Pin style={{width: 12}} /></div>
                    <h4>Destination</h4>
                    <span>Estimated drop-off on <strong>10/07/2024</strong> at <strong>9:00pm</strong></span>
                </div>
                <div className='CharterRowContent'>
                    <Input placeholder="Choose destination" size="large" />
                </div>
            </div>
            <div className='CharterRow'>
                <div className='CharterRowContent pl-0'>
                    <div className='CharterRowIcon'><User style={{width: 16}} /></div>
                    <Input placeholder="No. of passengers" size="large" />
                </div>
            </div>
        </div>,
    },
    {
      title: 'Contact Details',
      content: <div className='CharterWrap CharterContact'>
            <div className='CharterRow'>
                <div className='CharterRowHeader'>
                    <div className='CharterRowIcon'><User style={{width: 16}} /></div>
                    <h4>Name</h4>
                </div>
                <div className='CharterRowContent'>
                    <Input placeholder="Enter your name" size="large" />
                </div>
            </div>
            <div className='CharterRow'>
                <div className='CharterRowHeader'>
                    <div className='CharterRowIcon'><Email style={{width: 16}} /></div>
                    <h4>Email</h4>
                </div>
                <div className='CharterRowContent'>
                    <Input placeholder="Enter your email" size="large" />
                </div>
            </div>
            <div className='CharterRow'>
                <div className='CharterRowHeader'>
                    <div className='CharterRowIcon'><Phone style={{width: 16}} /></div>
                    <h4>Phone</h4>
                </div>
                <div className='CharterRowContent'>
                    <Input placeholder="Enter your phone number" size="large" />
                </div>
            </div>
            <div className='CharterRow'>
                <div className='CharterRowHeader'>
                    <div className='CharterRowIcon'><Notes style={{width: 16}} /></div>
                    <h4>Additional information</h4>
                </div>
                <div className='CharterRowContent'>
                    <TextArea rows={3} placeholder="Enter additional information" size="large" />
                </div>
            </div>
        </div>,
    },
];

function CharterForm() {
    const { token } = theme.useToken();
    const [current, setCurrent] = useState(0);
    const next = () => {
        setCurrent(current + 1);
    };
    const prev = () => {
        setCurrent(current - 1);
    };
    const items = steps.map((item) => ({
        key: item.title,
        title: item.title,
    }));
    return (
        <div className="CharterForm">
            <Card bordered={false} className="card-main card-charters">
                <h2 className="card-title">Book your Charter</h2>
                <Steps current={current} items={items} labelPlacement="vertical" />
                <div>{steps[current].content}</div>
                <div className="d-flex justify-content-between">
                    {current < steps.length - 1 && (
                        <Button type="primary" className="primary-btn btn-lg" onClick={() => next()}>Next</Button>
                    )}
                    {current > 0 && (
                        <Button className="btn-back" onClick={() => prev()}><Back /></Button>
                    )}
                    {current === steps.length - 1 && (
                        <Button type="primary" className="primary-btn btn-lg" onClick={() => message.success('Charter inquiry submitted successfully!')}>Book Now</Button>
                    )}
                </div>
            </Card>
        </div>
    );
}

export default React.memo(CharterForm)