import { cloneDeep } from "lodash";

export class Employment {
    constructor(data) {
        this.employmentStartDate = null;
        this.employmentEndDate = null;
        this.company = null;
        this.depotLocation = null;
        this.position = null;
        this.employmentType = null;
        this.employmentReason = null;
        this.supervisor = null;
        this.probationPeriod = null;
        this.performanceAppraisalDate = null;
        this.payslipDistribution = null;
        this.payrollFrequency = null;

        Object.assign(this, data);
    }

    clone(employment) {
        return new Employment(cloneDeep(employment));
    }
}