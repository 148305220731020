import React from "react";
import {Select} from "antd";
import {ReactComponent as Down} from "../../assets/icons/Down.svg";
import {ShiftBatRowNotePriority, ShiftBatRowNotePriorityLabel} from "../../model/shiftBat";
import {Tooltip} from "antd/lib";

function ShiftBatPriorityType({
                                  priority, setPriority, ttPlacement = 'left', ...props
                              }) {

    return (
        <Tooltip title={`Select a priority for this note`} placement={ttPlacement}>
            <Select size="small" className="inputselect-small"
                    suffixIcon={<Down/>}
                    value={priority?.length ? priority : ShiftBatRowNotePriority.normal}
                    onChange={priority => {
                        setPriority(priority)
                    }}
                    {...props}>
                {Object.keys(ShiftBatRowNotePriority).map(type => (
                    <Select.Option key={'SBRPT-' + type}
                                   value={ShiftBatRowNotePriority[type]}>{ShiftBatRowNotePriorityLabel[type]}</Select.Option>)
                )}
            </Select>
        </Tooltip>
    );

}

export default React.memo(ShiftBatPriorityType)
