import * as React from 'react';
import {useState} from 'react';
import './StopInfo.css'
import {Col, Row, Select, Tooltip, Typography} from "antd";
import {StopTypes} from "../model/busRoute";
import {Button} from "react-bootstrap";
import {useAppContext} from "../libs/contextLib";
import Pin from './Pin';
import {CopyOutlined, GlobalOutlined, WarningFilled} from "@ant-design/icons";
import {ReactComponent as Accessible} from '../assets/icons/Accessible.svg';
import {ReactComponent as Check} from "../assets/icons/Check.svg";
import RouteNumberList from "./RouteNumberList";
import BearingIcon from "./BearingIcon";
import dayjs from '../dayjs';
import {ReactComponent as DownArrow} from '../assets/icons/DownArrow.svg';

const {Text} = Typography;
const {Option} = Select;

function StopInfo({stop, startTime, idx, stopCount, onStopSequenceChange, noWarning}) {
    // const {finishEditFn, startEditFn} = props;
    // const [editing, setEditing] = useState(false)
    // const [stop] = useState(props.stop)
    // function handleEditBtnClicked() {
    //     if (editing) {
    //         finishEditFn()
    //     } else {
    //         startEditFn();
    //     }
    //     setEditing(!editing)
    // }
    const {messageApi} = useAppContext();

    const getTime = (time, date = dayjs()) => {
        return date.clone().startOf('day').add(time, 's').format('HH:mm');
    }

    const [showMoreInfo, setshowMoreInfo] = useState(false);
    function handleMoreInfo() {
        setshowMoreInfo(showMoreInfo => !showMoreInfo);
    }

    return (
        <>
            <Row wrap={false} style={{gap: "8px"}}>
                <Col flex="auto" className="flex-row" style={{gap: '4px'}}>
                    <Pin size={25} type={stop.stopType}/>
                    <h5>{stop.stopName}</h5>
                </Col>
                <Col flex="none" className="d-flex flex-row align-items-start justify-content-end pt-1"
                     style={{gap: "8px"}}>
                    <div className="d-flex align-items-center" style={{gap: "8px"}}>
                        {stop.bearing && <Tooltip placement="bottom"
                                                  title={`Approximate direction of route path through this point`}>
                            <div><BearingIcon bearing={stop.bearing}/></div>
                        </Tooltip>}
                        {stop.master && <Tooltip placement="bottom" title="Point from shared data source">
                            <GlobalOutlined style={{color: "#0670F9"}}/>
                        </Tooltip>}
                        {stop.wheelchair && <Tooltip placement="bottom" title="Accessible">
                            <Accessible className={"accessible-active"} style={{width: '16px'}}/>
                        </Tooltip>}
                        {stop.verified === 1 ? <Tooltip placement="bottom" title="Verified">
                            <span className="icon-check-success"><Check/></span>
                        </Tooltip> : null}
                    </div>
                </Col>
            </Row>
            {stop.routes?.length ?
                <Row>
                    <Col span={24}>
                        <RouteNumberList routes={stop.routes} noLogo={true} bearing={true} placement="inline"/>
                    </Col>
                </Row>
                : <></>
            }
            <Row gutter={[10, 14]}>
                <Col span={12}>
                    <strong className="mb-1">Point type</strong>
                    {StopTypes[stop.stopType] ? StopTypes[stop.stopType].title : "Bus Stop"}
                </Col>
                <Col span={12}>
                    <strong className="mb-1">Owned by</strong>
                    {stop.authorityId ? stop.authorityId.split("#")[1].toUpperCase() : stop.author || "Unknown"}
                </Col>
                {/* <Col span={12}>
                    <strong className="mb-1">StopTime Id</strong>
                    {stop.stopTimeId}
                </Col> */}
                <Col span={12}>
                    <strong>Source ID</strong>
                    <div className="d-flex align-items-center" style={{gap: '8px'}}>
                        {stop.stopCode ? (
                            <>
                                <Text ellipsis={{tooltip: stop.stopCode}}>{stop.stopCode}</Text>
                                <Button className="icon-button"
                                        title={"Copy point code"}
                                        variant={"light"}
                                        size={"sm"}
                                        onClick={() => {
                                            navigator.clipboard.writeText(stop.stopCode).then(() => {
                                                messageApi.info(
                                                    <div>
                                                        <p>Source ID copied to clipboard</p>
                                                    </div>,
                                                    5
                                                );
                                            });
                                        }}
                                >
                                    <CopyOutlined/>
                                </Button>
                            </>
                        ) : (
                            <>--</>
                        )}
                    </div>
                </Col>
                <Col span={12}>
                    <strong>Point ID</strong>
                    <div className="d-flex align-items-center" style={{gap: '8px'}}>
                        {stop.stopId ? (
                            <>
                                <Text ellipsis={{tooltip: stop.stopId}}>{stop.stopId}</Text>
                                <Button className="icon-button"
                                        title={"Copy Busable point ID"}
                                        size={"sm"}
                                        variant={"light"}
                                        onClick={() => {
                                            navigator.clipboard.writeText(stop.stopId).then(() => {
                                                messageApi.info(
                                                    <div>
                                                        <p>Point ID copied to clipboard</p>
                                                    </div>,
                                                    5
                                                );
                                            });
                                        }}
                                >
                                    <CopyOutlined/>
                                </Button>
                            </>
                        ) : (
                            <>-</>
                        )}
                    </div>
                </Col>
                {/* <Col span={12} className="d-flex justify-content-end">
                    <Button size='sm' variant='danger' onClick="">Remove</Button>
                </Col> */}
                <Col span={12} className={`CardHover ${showMoreInfo ? 'hovered' : ''}`}>
                    <strong className="mb-1">Suburb</strong>
                    {stop.suburb || '--'}
                </Col>
                <Col span={12} className={`CardHover ${showMoreInfo ? 'hovered' : ''}`}>
                    <strong className="mb-1">Postcode</strong>
                    {stop.postcode || '--'}
                </Col>
                {stop.startBellWindow ? (
                    <Col span={12}>
                        <strong className="mb-1">Arrivals</strong>
                        {getTime(stop.startBell - stop.startBellWindow)}
                    </Col>
                ) : null}
                {stop.startBell ? (
                    <Col span={12}>
                        <strong className="mb-1">{stop.stopType === "school" ? "Start Bell" : "Opening"}</strong>
                        {getTime(stop.startBell)}
                    </Col>
                ) : null}
                {stop.endBell ? (
                    <Col span={12}>
                        <strong className="mb-1">{stop.stopType === "school" ? "End Bell" : "Closing"}</strong>
                        {getTime(stop.endBell)}
                    </Col>
                ) : null}
                {stop.endBellWindow ? (
                    <Col span={12}>
                        <strong className="mb-1">Departures</strong>
                        {getTime(stop.endBell + stop.endBellWindow)}
                    </Col>
                ) : null}
                {process.env.REACT_APP_VERBOSE && (
                    <Col span={12}>
                        <strong>Geohash</strong>
                        {stop.geohash}
                    </Col>
                )}
                {process.env.REACT_APP_VERBOSE && stop.lastEditor && (
                    <Col span={12} className={`CardHover ${showMoreInfo ? 'hovered' : ''}`}>
                        <strong className="mb-1">Last edit by</strong>
                        {stop.lastEditor}
                    </Col>
                )}
            </Row>

            <Row className={`CardHover ${showMoreInfo ? 'hovered' : ''}`}>
                <Col>
                    <strong>Last edited</strong>
                    {dayjs(stop.updatedAt).format('lll')}
                </Col>
            </Row>

            {!stop.verified || stop.outOfSync?.length ?
                <Row>
                    <Col className="PointErrors">
                        {!stop.verified && (
                            <div>
                                <WarningFilled style={{color: "#dc3545"}}/>
                                Point is unverified
                            </div>
                        )}
                        {stop.warnings?.length ? (
                            stop.warnings.map((m) => (
                                <div>
                                    <WarningFilled style={{color: "#dc3545"}}/>
                                    {m}
                                </div>
                            ))
                        ) : (
                            <></>
                        )}
                    </Col>
                </Row>
                : <></>
            }

            {onStopSequenceChange && (
                <Row className="stop-sequence">
                    <Col span={10}>
                        <strong className="mb-1">Stop Sequence</strong>
                        <Select disabled={idx === -1} value={idx + 1} onChange={onStopSequenceChange} className='w-100'>
                            {Array.from({length: stopCount}, (_, idx) => (
                                <Option key={`StopSeq-${idx}`} value={idx + 1}>
                                    {idx + 1}
                                </Option>
                            ))}
                        </Select>
                    </Col>
                </Row>
            )}

            <Button onClick={handleMoreInfo}
                    className={`btn-more ${showMoreInfo ? 'opened' : ''}`}><DownArrow/></Button>

            {/*<div className='row'>*/}
            {/*    <div className='col-4'><strong>Distance: </strong></div>*/}
            {/*    <div className='col'>{toKmMs(stop.distance)}</div>*/}
            {/*</div>*/}
            {/*<div className='Footer'>*/}
            {/*    <Button size='sm' variant={editing ? 'warning' : 'primary'}*/}
            {/*            onClick={handleEditBtnClicked}>{editing ? 'Finish' : 'Edit'}</Button>*/}
            {/*</div>*/}
            {/*<div className="StopInfo">*/}
            {/*    <div className={'header'}>*/}
            {/*        <h6>ID: {stopId}, {stopName}</h6>*/}
            {/*    </div>*/}
            {/*    <div className={'body'}>*/}
            {/*        <div>Time from start: {toHrsMinsSecs(delta)}</div>*/}
            {/*        <div>Distance from start: {toKmMs(distance)}</div>*/}
            {/*    </div>*/}
            {/*    <div className='footer'>*/}
            {/*        <Button size='sm' onClick={editFn}>Edit</Button>*/}
            {/*    </div>*/}
            {/*</div>*/}

        </>
    );
}

export default React.memo(StopInfo);