import React, {useCallback, useEffect, useState} from 'react';
import './ShiftBat.css';
import {Flex, message, Select, Switch, Tag, Button, Tooltip} from 'antd';
import {Col, Image, Row} from 'react-bootstrap';
import {ReactComponent as Calendar} from '../../assets/icons/Calendar.svg';
import {ReactComponent as Timer} from '../../assets/icons/Timer.svg';
import {ReactComponent as Services} from '../../assets/icons/Services.svg';
import {ReactComponent as Bus} from '../../assets/icons/Bus.svg';
import {ReactComponent as Print} from '../../assets/icons/Print.svg';
import RouteTitleEdit from '../../components/RouteTitleEdit';
import {ShiftBat, ShiftBatRowType} from '../../model/shiftBat';
import {useHistory} from 'react-router-dom';
import {useAppContext} from '../../libs/contextLib';
import {toHrsMinsSecs, toKmMs} from '../../libs/formatLib';
import {deadrunModelData} from '../../services/ModelService';
import RouteMap from '../../components/RouteMap';
import ShiftBatRows from './ShiftBatRows';
import ShiftBatPrintModal from './ShiftBatPrintModal';
import {Deadrun} from '../../model/deadrun';
import {keyBy} from 'lodash/collection';
import {values} from 'lodash/object';
import {debounce} from 'lodash/function';
import config from '../../config';
import {FEATURE} from '../../model/features';
import {checkFeatureAuth} from '../../App';
import Form from 'react-bootstrap/Form';
import {DATE_DAY_STRING, ROUTE_STATUS, ROUTE_STATUSES} from '../../model/busRoute';
import RouteMapViewer from '../../components/RouteMapViewer';
import LoadMessage from '../../components/LoadMessage';
import {reverse} from 'lodash';
import CalendarSelector from '../../components/CalendarSelector';
import VehicleTypeSelect from '../../editableList/vehicleTypes/VehicleTypeSelect';
import ShareModal from '../../components/ShareModal';

function EditShiftBat({
                          allowRoutes = true,
                          charter = false,
                          initialEditMode = true,
                          shiftBat, setShiftBatHistory, controls,
                          allStops, allTransfers, allRoutes, setAllStops, allSchedules
                      }) {
    const {
        isAuthenticated, apiKey, schedules, setBreadcrumb,
        operator,
        setFaultState,
        email,
        givenName,
        familyName
    } = useAppContext();
    const history = useHistory();
    const [fetching, setFetching] = useState(true);
    const [updating, setUpdating] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [editing, setEditing] = useState({});
    const [selectedStop, setSelectedStop] = useState(null);
    const [focusStop, setFocusStop] = useState(null);
    const [zoom, setZoom] = useState([]);
    const [viewing, setViewing] = useState({});
    const [highlightedRouteIds, setHighlightedRouteIds] = useState([]);

    // const [allStops, setAllStops] = useState(null);
    // const [allRoutes, setAllRoutes] = useState({});
    // const [allTransfers, setAllTransfers] = useState({});

    // const [route, setRoute] = useState(null);

    const [editRouteRow, setEditRouteRow] = useState(null);

    const [printShiftBat, setPrintShiftBat] = useState(false);

    const [messageApi, contextHolder] = message.useMessage();

    // eslint-disable-next-line
    const [updated, setUpdated] = useState(false);

    const [shiftBatNumber, setShiftBatNumber] = useState(null);
    const [shiftBatName, setShiftBatName] = useState(null);
    const [shiftBatDetails, setShiftBatDetails] = useState(null);
    const [shiftBatLogo, setShiftBatLogo] = useState(null);
    // const [shiftBatColour, setShiftBatColour] = useState()

    const [onMapSelection, setOnMapSelection] = useState(null);
    const [fileList, setFileList] = useState([]);
    const [recurring, setRecurring] = useState(shiftBat?.scheduleIds?.length || !charter);

    const getEditor = useCallback(() => givenName?.length && familyName?.length ? `${givenName} ${familyName}` : email,
        [email, givenName, familyName]);

    useEffect(() => {
        console.log('Setting onMapSelection...', onMapSelection);
    }, [onMapSelection]);

    useEffect(() => {
        setEditMode(editMode => initialEditMode);
    }, [setEditMode, initialEditMode]);

    const setShiftBatNow = useCallback((sb, opts = null) => {
        setShiftBatHistory(sb, opts);
        if (!opts?.overwriteLastEntry) {
            setUpdated(true);
        }
    }, [setShiftBatHistory, setUpdated, shiftBatNumber, shiftBatName, shiftBatDetails]);

    const setShiftBat = debounce(setShiftBatNow, 500);

    const disableEditForRow = useCallback(() => values(editing).some(id => !!id), [editing]);

    useEffect(() => {
        checkFeatureAuth(setFaultState, operator, FEATURE.chrtrs, () => history.push('/')).then(() => console.log('Auth checked.'));
    }, []);

    useEffect(() => {
        const beforeunload = (e) => {
            if (updated) {
                e.returnValue = 'unloading';
                return 'unloading';
            }
        };
        window.addEventListener('beforeunload', beforeunload);
        return () => {
            window.removeEventListener('beforeunload', beforeunload);
        };
    }, [updated]);

    useEffect(() => {

        if (!isAuthenticated) {
            console.log('NOT AUTHENTICATED');
            history.push('/');
        }
        // load shift bat
        if (shiftBat) {
            setFileList([{
                url: 'https://prod-rm-web-infra-gpx-s3-gpxfiles47af3947-1f2jzq8rsjwfq.s3.ap-southeast-2.amazonaws.com/public/Bus.svg',
                status: 'done'
            }]);
            setShiftBatNumber(shiftBat?.shiftBatNumber);
            setShiftBatName(shiftBat?.shiftBatName);
            setShiftBatDetails(shiftBat?.shiftBatDetails);
            setShiftBatLogo(shiftBat?.shiftBatLogo);
            const sb = new ShiftBat(shiftBat || {});
            Promise.all(sb.rows.filter(row => row.updateRow).map(async row => {
                await row.updateRow({apiKey, allStops, allTransfers, allRoutes, deadrunModelData});
            })).then(() => {
                setFetching(false);
                if (controls?.reset) {
                    controls.reset(sb.clone());
                }
            });
        }

        // eslint-disable-next-line
    }, [apiKey, schedules, isAuthenticated, history, setEditMode, shiftBat]);

    const onUpdate = useCallback((event, row, idx, keepEditing) => {

        const update = async () => {
            if (!keepEditing) {

                if (row.type === ShiftBatRowType.dead && row.id === editRouteRow?.id) {
                    setEditRouteRow(null);
                    editRouteRow.title = row.title;
                    editRouteRow.description = row.description;
                    if (row.title?.length && editRouteRow.route.routeName !== row.title) {
                        editRouteRow.route.routeId = '_'; // Creates a new Deadrun in the DB
                    }
                    editRouteRow.route.calculateDistances();
                    // editRouteRow.route.routeDetails = row.description
                    const deadrun = new Deadrun(editRouteRow.route);
                    deadrun.routeName = row.title;
                    deadrun.routeDetails = row.description;
                    deadrun.duration = row.duration;
                    const savedDeadrun = await deadrunModelData.save(deadrun.clone());
                    deadrun.routeId = savedDeadrun.routeId;
                    row.route = deadrun.clone();
                    row.routeId = deadrun.routeId;
                    row.routes = row.routes || {};
                    row.routes[deadrun.routeId] = deadrun;
                    row.distance = deadrun.distance;
                    // } else if (row.type === ShiftBatRowType.note) {
                    //     const savedRow = await noteRefModelData.save(clone(row));
                    //     row.refId = savedRow.refId;
                }
            }

        };

        // setUpdating(true)
        update().then(() => {
            setShiftBat(shiftBat => {
                const _shiftBat = new ShiftBat({...shiftBat});
                setUpdating(true);
                _shiftBat.replaceRow(row, idx);
                return _shiftBat;
            });
            setEditing(editing => {
                return ({...editing, [row.id]: !!keepEditing});
            });
            setHighlightedRouteIds([]);
            console.log('Row updated.');
        });
    }, [setShiftBat, setEditing, editRouteRow, setEditRouteRow, setHighlightedRouteIds]);

    const onCreate = useCallback((e, row, idx, keepEditing) => {
        if (row.type === ShiftBatRowType.note) {
            row.refId = '_';
        }
        onUpdate(e, row, idx, keepEditing);
    }, [onUpdate]);

    const deleteRow = useCallback((event, row) => {
        setShiftBatNow(sb => {
            const shiftBat = sb.clone();
            shiftBat.removeRow(row);
            return shiftBat;
        });
        setEditing(editing => ({...editing, [row.id]: false}));
        setHighlightedRouteIds([]);
    }, [setShiftBatNow, setEditing, setHighlightedRouteIds]);

    const cancel = useCallback((event, row) => {
        if (row.id === editRouteRow?.id) {
            setEditRouteRow(null);
        }
        if (row.isDefault()) {
            deleteRow(event, row);
        }

        setEditing(editing => ({...editing, [row.id]: false}));
        setHighlightedRouteIds([]);
    }, [setEditing, editRouteRow, setEditRouteRow, deleteRow, setHighlightedRouteIds]);

    useEffect(() => {
        const viewingRoutIds = Object.keys(viewing).filter(rowId => viewing[rowId])
            .map(rowId => shiftBat.rows[shiftBat.rows.findIndex(row => row.id === rowId)]?.route?.routeId)
            .filter(rId => !!rId);
        console.log(viewingRoutIds);
        setHighlightedRouteIds(viewingRoutIds);
    }, [viewing, setZoom, setHighlightedRouteIds, shiftBat]);

    // console.log('Updating view', shiftBat?.rows?.filter(row => row.mappedRoute).map(row=>row.mappedRoute.waypoints[0]))

    const handleNewDeadrun = useCallback((row) => {
        setEditRouteRow(editRouteRow => {
            const newDeadrun = editRouteRow.route.clone();
            newDeadrun.routeName = 'New deadrun';
            newDeadrun.routeId = '_';
            editRouteRow.routes['_'] = newDeadrun;
            return {...editRouteRow, route: newDeadrun};
        });
    }, [setEditRouteRow]);

    useEffect(() => {
        if (!shiftBat || !allRoutes || !allStops) {
            return;
        }
        setUpdating(true);
        shiftBat.updateRows({apiKey, allStops, allTransfers, allRoutes, deadrunModelData}).then(() => {
            setUpdating(false);
        });
    }, [shiftBat, allRoutes, allStops, allTransfers, apiKey, setUpdating]);

    const toggleRecurring = () => {
        setRecurring(!recurring);
    };

    return (
        <>
            <div className="ShiftBatView">
                {contextHolder}

                {printShiftBat ? <ShiftBatPrintModal
                    allStops={allStops}
                    allRoutes={allRoutes}
                    apiKey={apiKey}
                    shiftBat={shiftBat}
                    visible={printShiftBat}
                    schedules={schedules}
                    setVisible={setPrintShiftBat}/> : <></>}
                {shiftBat && !fetching && schedules ?
                    <>
                        <div className="card-main shift-bat-map no-pad d-flex">
                            <div className="map-holder" style={{width: '65%', position: 'relative'}}>
                                <div className="MapPlaceholder">
                                    {editRouteRow ?
                                        <RouteMap route={editRouteRow.route.clone()} editingRoute={true}
                                                  charter={charter}
                                                  routeOnly={true}
                                                  allStops={editRouteRow.start && editRouteRow.end ? keyBy([editRouteRow.start, editRouteRow.end], 'stopId') : charter.stops ? keyBy(charter.stops || [], 'stopId') : {}}
                                                  updateRouteFn={route => {
                                                      console.log('Updated route from ROuteMap');
                                                      setEditRouteRow(editRouteRow => {
                                                          editRouteRow.route.waypoints = route.waypoints;
                                                          editRouteRow.route.stops = route.stops;
                                                          editRouteRow.route.stopTImes = route.stopTimes;
                                                          editRouteRow.route.services = route.services;
                                                          editRouteRow.routeEdited = true;
                                                          return {...editRouteRow};
                                                      });
                                                  }}
                                                  createNewStop={(stop) => {
                                                      // if (setCharter) {
                                                      //     setCharter(charter => {
                                                      //         charter.stops = charter.stops || [];
                                                      //         charter.stops.push(stop);
                                                      //         return new Charter({...charter});
                                                      //     });
                                                      // }
                                                  }}
                                        />
                                        :
                                        <RouteMapViewer shiftBatBuilder={true} noToolbar={true}
                                                        showWpSelector={true}
                                                        routes={shiftBat.rows.filter(row => row.route).map(row => row.route).concat(shiftBat.rows.filter(row => row.mappedRoute).map(row => row.mappedRoute))}
                                                        trips={shiftBat.rows.filter(row => row.trip).map(row => row.trip)}
                                                        allStops={allStops}
                                                        locations={charter ? reverse(shiftBat.rows.filter(row => [ShiftBatRowType.location, ShiftBatRowType.stop, ShiftBatRowType.service, ShiftBatRowType.charter].includes(row.type) && row.getStartLocation()).map(row => row.getStartLocation())) : null}
                                                        verifiedOnly={true}
                                                        highlightedRouteIds={highlightedRouteIds}
                                                        selectedStop={selectedStop}
                                                        focusStop={focusStop}
                                                        zoom={zoom}
                                                        setFocusStop={setFocusStop}
                                                        handleStopClick={(stop) => setSelectedStop(stop)}
                                                        filteredRoutes={shiftBat.rows.filter(row => row.route).map(row => {
                                                            return row.route;
                                                        }).concat(shiftBat.rows.filter(row => row.mappedRoute).map(row => row.mappedRoute))}
                                                        onMapSelection={onMapSelection}
                                                        setOnMapSelection={setOnMapSelection}
                                                        charter={charter}
                                            // handleSelectedRoute={({routeId}) => {
                                            //     if (routeId) {
                                            //         const route = allRoutes[routeId]
                                            //         const id = ulid();
                                            //         console.log('New row...', route.routeId)
                                            //         shiftBat.addRow({id,
                                            //             type: ShiftBatRowType.service,
                                            //             title: `Depart ${route.stops[0].stopName}`,
                                            //             route}, shiftBat.rows.length)
                                            //         setShiftBat(shiftBat.clone())
                                            //         setEditing(editing => ({...editing, [id]: true}))
                                            //
                                            //     }
                                            // }}
                                            //             handleSelectedStop={(stop) => {
                                            //                 console.log('Selected stop: ', stop)
                                            //                 const id = ulid();
                                            //                 console.log('New row...', id)
                                            //                 shiftBat.addRow({
                                            //                     id,
                                            //                     type: ShiftBatRowType.stop,
                                            //                     title: stop.stopName,
                                            //                     stop
                                            //                 }, shiftBat.rows.length)
                                            //                 setShiftBat(shiftBat.clone())
                                            //                 setEditing(editing => ({...editing, [id]: true}))
                                            //                 setUpdated(true)
                                            //             }}
                                        />}
                                </div>
                            </div>

                            <div className="map-shiftbats" style={{width: '35%'}}>
                                <div className="sb-info-wrap">
                                    {editMode ?
                                        <div>
                                            <RouteTitleEdit route={{
                                                routeNumber: shiftBatNumber,
                                                routeName: shiftBatName,
                                                routeDetails: shiftBatDetails,
                                                routeLogo: shiftBatLogo,
                                                colour: shiftBat.shiftBatColour
                                            }}
                                                            fileList={fileList}
                                                            setFileList={setFileList}
                                                            handleLogoRemove={() => {
                                                                if (disableEditForRow()) {
                                                                    return;
                                                                }
                                                                setShiftBatLogo('');
                                                            }}
                                                            editRouteNumber={(val) => {
                                                                if (disableEditForRow()) {
                                                                    return;
                                                                }
                                                                setShiftBatNumber(val);
                                                            }}
                                                            onBlurRouteNumber={() => {
                                                                setShiftBatNow(sb => new ShiftBat({
                                                                    ...sb,
                                                                    shiftBatNumber
                                                                }));
                                                            }}
                                                            editRouteName={(val) => {
                                                                if (disableEditForRow()) {
                                                                    return;
                                                                }
                                                                setShiftBatName(val);
                                                            }}
                                                            onBlurRouteName={() => {
                                                                setShiftBatNow(sb => new ShiftBat({
                                                                    ...sb,
                                                                    shiftBatName
                                                                }));
                                                            }}
                                                            editRouteDetails={(val) => {
                                                                if (disableEditForRow()) {
                                                                    return;
                                                                }
                                                                setShiftBatDetails(val);
                                                            }}
                                                            onBlurRouteDetails={() => {
                                                                setShiftBatNow(sb => new ShiftBat({
                                                                    ...sb,
                                                                    shiftBatDetails
                                                                }));
                                                            }}
                                            />
                                            <Flex justify={'space-between'} gap={10}>
                                                <CalendarSelector obj={shiftBat}
                                                                  schedules={schedules}
                                                                  disabled={disableEditForRow()}
                                                                  status={!shiftBat.scheduleIds?.length && !shiftBat.dates?.length ? 'error' : null}
                                                                  className={'w-100'}
                                                                  recurring={recurring}
                                                                  onCalendarChange={scheduleIds => {
                                                                      const effectiveDate = scheduleIds.map(sId => schedules[sId]).filter(s => !!s).reduce((p, c) => {
                                                                          if (p === null) {
                                                                              return c.getFirstActiveDate();
                                                                          }
                                                                          if (p.isBefore(c.getFirstActiveDate())) {
                                                                              return p;
                                                                          }
                                                                          return c.getFirstActiveDate();
                                                                      }, null);
                                                                      const _shiftBat = new ShiftBat({
                                                                          ...shiftBat,
                                                                          scheduleIds,
                                                                          schedules,
                                                                          effectiveDate
                                                                      });
                                                                      setShiftBatNow(_shiftBat);
                                                                  }}
                                                                  onDatesChange={dates => {
                                                                      const effectiveDate = dates?.[0];
                                                                      setShiftBatNow(new ShiftBat({
                                                                          ...shiftBat,
                                                                          dates,
                                                                          effectiveDate
                                                                      }));
                                                                  }}
                                                />
                                                <Switch size="small" onChange={setRecurring} checked={recurring} style={{display: 'none'}}/>
                                                <Tooltip placement="bottom" title={`${recurring ? 'Operating date' : 'Operating calendar'}`}>
                                                    <Button onClick={toggleRecurring} className={`info-icon-button ${recurring ? 'active' : ''}`}><Calendar /></Button>
                                                </Tooltip>
                                            </Flex>
                                        </div>
                                        :
                                        <Row className={`RouteTitle flex-wrap align-items-center w-100 row-compact`}>
                                            <Col md="auto d-flex align-items-center">
                                                <div className={`RouteLogo`}
                                                     style={{borderColor: shiftBat.shiftBatColour}}>
                                                    {shiftBat?.shiftBatLogo?.length ?
                                                        <Image
                                                            src={`https://${config.s3.BUCKET}.s3.ap-southeast-2.amazonaws.com/public/${shiftBat.shiftBatLogo}`}/>
                                                        : <Image
                                                            src={'https://prod-rm-web-infra-gpx-s3-gpxfiles47af3947-1f2jzq8rsjwfq.s3.ap-southeast-2.amazonaws.com/public/Bus.svg'}
                                                            className="BusIcon"/>
                                                    }
                                                </div>
                                                <div className={`RouteNumber`}
                                                     style={{backgroundColor: shiftBat.shiftBatColour}}>{shiftBat.shiftBatNumber}</div>
                                            </Col>
                                            <Col>
                                                <div className={`RouteInfo align-items-center pl-2`}>
                                                    <h1 style={{color: '#000'}}>{shiftBat.shiftBatName}</h1>
                                                    <div className={`RouteDetails`}>{shiftBat.shiftBatDetails}</div>
                                                </div>
                                            </Col>

                                            {shiftBat.dates?.length ? <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="Exclusions mb-2">
                                                        {shiftBat.dates.map(date =>
                                                            <Tag>{date.format(DATE_DAY_STRING)}</Tag>)}
                                                    </div>
                                                </div>
                                            </div> : shiftBat.scheduleIds?.length ?
                                                <div className="d-flex flex-row simple-tags w-100"
                                                     style={{marginTop: '10px', gap: '8px'}}>
                                                    {shiftBat.scheduleIds.map(sId => <Tag
                                                        key={`sid-${sId}`}>{schedules[sId]?.scheduleName || 'MISSING CALENDAR'}</Tag>)}
                                                </div> : <></>}
                                        </Row>
                                    }

                                    <div className="d-flex info-text-wrap w-label align-items-center FlexColumn">
                                        <div className="d-flex" style={{gap: '8px'}}>
                                            <div className="icon-info-text">
                                                <Timer/>
                                                <div>
                                                    <span>Duration</span>{toHrsMinsSecs(shiftBat.getShiftTime(), false, true)}
                                                </div>
                                            </div>
                                            <div className="icon-info-text">
                                                <Services/>
                                                <div><span>Distance</span>{toKmMs(shiftBat.getShiftDistance(), 0)}</div>
                                            </div>

                                            {!editMode && shiftBat.charter && <div className="icon-info-text">
                                                <Bus/>
                                                <div>
                                                    <span>Vehicle Type</span>{shiftBat?.vehicleType?.vehicleTypeName ? shiftBat?.vehicleType?.vehicleTypeName : '---------'}
                                                </div>
                                            </div>}

                                            {/* {!editMode && shiftBat.charter && <div className="icon-info-text no-bg">
                                                <Button type="primary" className="icon-button btn-filled" icon={<Print/>}
                                                        disabled={!shiftBat?.isValid({allStops, allRoutes})}
                                                        onClick={async event => {
                                                            setPrintShiftBat(true);
                                                        }}>Print</Button>
                                                <ShareModal shiftBat={shiftBat}/>
                                            </div>} */}

                                            {!editMode && !shiftBat.charter && <>
                                                <div className="icon-info-text">
                                                    <Calendar/>
                                                    <div>
                                                        <span>Effective Date</span>{shiftBat?.effectiveDate ? shiftBat?.effectiveDate.format('DD/MM/YYYY') : '--/--/----'}
                                                    </div>
                                                </div>

                                                <div className="icon-info-text">
                                                    <div>
                                                        <span>{ROUTE_STATUS[shiftBat?.published]}</span>
                                                    </div>
                                                </div>
                                            </>}
                                        </div>

                                        {editMode ?
                                            <div className='d-flex align-items-center flex-wrap' style={{gap: '8px'}}>
                                                {charter ?
                                                    <VehicleTypeSelect
                                                        onSelect={(vehicleTypeId, vehicleType) => {
                                                            setShiftBatNow(new ShiftBat({
                                                                ...shiftBat,
                                                                vehicleTypeId, vehicleType
                                                            }));
                                                        }} value={shiftBat.vehicleTypeId}
                                                        status={!shiftBat.vehicleTypeId ? 'error' : null}
                                                    /> :
                                                    <Select options={ROUTE_STATUSES} defaultValue={-1}
                                                            onChange={v => {
                                                                setShiftBatNow(sb => new ShiftBat({
                                                                    ...sb,
                                                                    published: v,
                                                                    approvedAt: v === 0 ? Date.now() : sb.approvedAt,
                                                                    approvedBy: v === 0 ? getEditor() : sb.approvedBy,
                                                                    publishedAt: v === 1 ? Date.now() : sb.publishedAt,
                                                                    publishedBy: v === 1 ? getEditor() : sb.publishedBy
                                                                }));
                                                            }} value={shiftBat.published}/>
                                                }
                                                <Form.Control className="color-picker"
                                                            style={{width: '80px'}}
                                                            type="color"
                                                            list="presets"
                                                            value={shiftBat.shiftBatColour}
                                                            title="Choose shift colour"
                                                            onChange={event => {
                                                                const sb = new ShiftBat({
                                                                    ...shiftBat,
                                                                    shiftBatColour: event.target.value
                                                                });
                                                                setShiftBat(sb);
                                                            }}

                                                />
                                                <datalist id="presets">
                                                    <option value="#007bff"></option>
                                                    <option value="#1b65ac"></option>
                                                    <option value="#00aeef"></option>
                                                    <option value="#5567a9"></option>
                                                    <option value="#812990"></option>
                                                    <option value="#cb0b0b"></option>
                                                    <option value="#a02125"></option>
                                                    <option value="#c90b86"></option>
                                                    <option value="#e6b00e"></option>
                                                    <option value="#f57d20"></option>
                                                    <option value="#008a5d"></option>
                                                    <option value="#00b26a"></option>
                                                    <option value="#005a23"></option>
                                                    <option value="#76a33a"></option>
                                                    <option value="#515553"></option>

                                                    <option value="#"></option>
                                                    <option value="#"></option>
                                                </datalist>
                                            </div>
                                        : <></>}
                                    </div>
                                </div>

                                <ShiftBatRows shiftBat={shiftBat} setEditing={setEditing} schedules={schedules}
                                              editing={editing} allRoutes={allRoutes} updating={updating}
                                              setUpdating={setUpdating} allTransfers={allTransfers}
                                              allStops={allStops} onUpdate={onUpdate} onCancel={cancel}
                                              disableEditForRow={disableEditForRow} setAllStops={setAllStops}
                                              selectedStop={selectedStop} onCreate={onCreate}
                                              deleteRow={deleteRow} setSelectedStop={setSelectedStop}
                                              setFocusStop={setFocusStop} handleNewDeadrun={handleNewDeadrun}
                                              fetching={fetching} setEditRouteRow={setEditRouteRow}
                                              editMode={editMode} viewing={viewing} setViewing={setViewing}
                                              setHighlightedRouteIds={setHighlightedRouteIds}
                                              setOnMapSelection={setOnMapSelection}
                                              editRouteRow={editRouteRow} allowRoutes={allowRoutes} charter={charter}
                                />
                            </div>
                        </div>
                    </>
                    :
                    <LoadMessage message={`Loading driver duty...`}/>// ${shiftBat} && ${!fetching} && ${schedules}`}/>
                }
            </div>
        </>
    )
        ;
}

export default React.memo(EditShiftBat);
