export const getEditor = ({user}) => {
    let name = null;
    let email = null;
    let editor = null;
    if (user) {
        email = user.attributes['email']
        const isName = user.attributes['given_name']?.length && user.attributes['family_name']?.length
        name = isName ?
            user.attributes['given_name'] + ' ' + user.attributes['family_name'] :
            null

        editor = name ? name : user.attributes['email']
    }

    return {user, name, email, editor};
}
