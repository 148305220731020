import {ulid} from "ulid";
import ShiftBatRowPrint from "./ShiftBatRowPrint";
import {ShiftBatRowType} from "../../model/shiftBat";
import React, {useEffect, useState} from "react";
import {deadrunModelData} from "../../services/ModelService";

function ShiftBatRowsPrint({
                          shiftBat,
                          setEditing,
                          editing,
                          printMode,
                          updating, setUpdating,
                          onUpdate,
                          deleteRow,
                          setHighlightedRouteIds,
                          editMode, viewing, setViewing,
                          setEditRouteRow, disableEditForRow,
                          selectedStop,
                          setSelectedStop, setFocusStop
                      }) {

    const [draftShiftBat, setDraftShiftBat] = useState(null)
    useEffect(() => {
        if (!shiftBat) {
            return
        }
        setDraftShiftBat(shiftBat);
        // setDraftShiftBat(shiftBat.clone()); //Removed clone as it was causing Deadruns to not show until another update
    }, [shiftBat, setDraftShiftBat])

    return (
        <>
            <div className={`sb-items-wrap`}>
                {draftShiftBat && draftShiftBat.rows.map((row, idx) => {
                    return editMode && editing[row.id] ?
                        <></> :
                        <ShiftBatRowPrint key={'SBR-' + row.id} shiftBatRow={row} editMode={editMode} printMode={printMode}
                                     updating={updating} setUpdating={setUpdating} setSelectedStop={setSelectedStop}
                                     selectedStop={selectedStop} editing={editing} disableEdit={disableEditForRow()}
                                     viewing={viewing} setViewing={setViewing} setEditRouteRow={setEditRouteRow}
                                     setFocusStop={setFocusStop} setHighlightedRouteIds={setHighlightedRouteIds}
                                     editRow={(e, row) => {
                                         if (row.type === ShiftBatRowType.dead) {
                                             setEditRouteRow(row);
                                         }
                                         setEditing(editing => ({
                                             ...editing,
                                             [row.id]: true
                                         }));
                                     }}
                                     deleteRow={deleteRow}
                                     addRow={async () => {
                                         const id = ulid();
                                         console.log('New row...', id)
                                         const newRow = draftShiftBat.addRow({id}, idx + 1)
                                         if (newRow.updateRow) {
                                             await newRow.updateRow({deadrunModelData});
                                         }
                                         // setDraftShiftBat(draftShiftBat.clone());
                                         setEditing(editing => ({...editing, [id]: true}))
                                     }} onUpdate={(e, row) => onUpdate(e, row, idx)
                        }/>

                })}
            </div>
        </>
    )

}

export default React.memo(ShiftBatRowsPrint)
