import config from "../config";

const isLocal = process.env.NODE_ENV === "development" || config.local;

export function logError(error, errorInfo = null) {
  if (isLocal) {
    console.log(error, errorInfo)
  }
}

export function onError(error) {
  let errorInfo = {};
  let message = error.toString();

  // Auth errors
  if (!(error instanceof Error) && error.message) {
    errorInfo = error;
    message = error.message;
    error = new Error(message);
    // API errors
  } else if (error.config && error.config.url) {
    errorInfo.url = error.config.url;
  }

  logError(error, errorInfo);

  if(config.isAdmin) {
    alert(message);
  }
}

export const checkOnline = async () => {
  if (process.env.REACT_APP_STAGE !== "local") {
    try {
      const online = await fetch(`https://prod-rm-web-infra-gpx-s3-gpxfiles47af3947-1f2jzq8rsjwfq.s3.ap-southeast-2.amazonaws.com/public/t.png?date=${Date.now()}`);
      return online.status === 200;
    } catch (e) {
      return false
    }
  }
  return true;
}