import {ButtonToolbar, Col, ListGroup, Row} from "react-bootstrap";
import {FilterTags} from "../containers/FilterTag";
import LoaderButton from "../components/LoaderButton";
import React from "react";
import {DEFAULT_EDITABLE_PROPS} from "./EditableList";
import {ReactComponent as Copy} from '../assets/icons/Copy.svg';
import {ReactComponent as Trash} from '../assets/icons/Trash.svg';
import {ReactComponent as Divert} from '../assets/icons/Divert.svg';
import {ReactComponent as Edit} from '../assets/icons/Edit.svg';
import {EyeOutlined} from "@ant-design/icons";

function EditableListItem(props) {
    const {
        editableView,
        itemColumns,
        item,
        itemIdFn,
        isDeletable,
        isEditable,
        isCopyable,
        isReplicable,
        viewing,
        filterItems,
        setViewing,
        handleDelete,
        handleCopy,
        handleReplicate = () => false,
        isDeleting,
        isCopying,
        isReplicating,
        feature
    } = {...DEFAULT_EDITABLE_PROPS, ...props}

    return (
        <ListGroup.Item key={itemIdFn(item)}>
            {viewing.includes(itemIdFn(item)) ?
                <Row>
                    <Col lg={12}>
                        {editableView({item, ...DEFAULT_EDITABLE_PROPS, ...props})}
                    </Col>
                </Row>
                :
                <>
                    <Row>
                        {itemColumns(item)}
                        {props.noTags ? <></> : <Col lg={1} className="simple-tags d-flex justify-content-center">
                            <FilterTags filterItems={filterItems} model={item}/>

                            {/*<Row>*/}
                            {/*    <FilterTag showFn={() => !schedule.isObsolete() && !schedule.isFuture()} filterItems={filterItems} filterName={'Active'}/></Col>*/}
                            {/*    {schedule.diverted && <Col><FilterTag showFn={() => schedule.diverted} filterItems={filterItems} filterName={'Diverted'}/></Col>}*/}
                            {/*    {schedule.isFuture() && <Col><FilterTag showFn={() => schedule.isFuture()} filterItems={filterItems} filterName={'Future'}/></Col>}*/}
                            {/*    {schedule.isObsolete() && <Col><FilterTag showFn={() => schedule.isObsolete()} filterItems={filterItems} filterName={'Expired'}/></Col>}*/}
                            {/*</Row>*/}
                        </Col>}
                        <Col lg={2} className="d-flex justify-content-center row-col-actions gap-8">
                            <ButtonToolbar>
                                <LoaderButton size="sm"
                                    variant="outline-primary"
                                    className="btn-icon-control"
                                    onClick={(e) => setViewing(viewing => viewing.concat(itemIdFn(item)))}
                                    title={isEditable(item) ? 'Edit' : 'View'}
                                >
                                    {isEditable(item) ? <Edit style={{width: 14}} /> : <EyeOutlined />}
                                </LoaderButton>
                                {isCopyable(item) && <LoaderButton size="sm"
                                    variant="outline-primary"
                                    className="btn-icon-control"
                                    onClick={(e) => handleCopy(e, item)}
                                    disabled={isCopying.indexOf(itemIdFn(item)) > -1}
                                    isLoading={isCopying.indexOf(itemIdFn(item)) > -1}
                                    feature={feature}
                                    title="Copy"
                                >
                                    <Copy/>
                                </LoaderButton>}
                                {isDeletable(item) && <LoaderButton size="sm"
                                    variant="outline-danger"
                                    className="btn-icon-control btn-delete"
                                    onClick={(e) => handleDelete(e, item)}
                                    disabled={isDeleting.indexOf(itemIdFn(item)) > -1}
                                    isLoading={isDeleting.indexOf(itemIdFn(item)) > -1}
                                    feature={feature}
                                    title="Delete"
                                >
                                    <Trash/>
                                </LoaderButton>}
                                {
                                    isReplicable(item) &&
                                    <LoaderButton size="sm"
                                        variant="outline-secondary"
                                        className="btn-icon-control"
                                        onClick={(e) => handleReplicate(item)}
                                        disabled={isReplicating.indexOf(itemIdFn(item)) > -1}
                                        isLoading={isReplicating.indexOf(itemIdFn(item)) > -1}
                                        feature={feature}
                                        title="Replicate">
                                        <Divert />
                                    </LoaderButton>
                                }
                            </ButtonToolbar>
                        </Col>
                    </Row>
                </>
            }
        </ListGroup.Item>
    )
}

export default React.memo(EditableListItem)
