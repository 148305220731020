import {Input, Popconfirm} from 'antd';
import React, {useState} from 'react';

const EditPopover = ({save, cancel, value, title, child, ...props}) => {

    const [newValue, setNewValue] = useState(value);

    return (
        <Popconfirm
            title={title || 'Edit'} icon=""
            description={<Input value={newValue} onChange={e => setNewValue(e.target.value)}/>}
            onConfirm={() => save(newValue)}
            onCancel={cancel}
            {...props}
        >
            {child || <span
                style={{cursor: 'pointer'}}>{value}</span>}
        </Popconfirm>
    );
};

export default React.memo(EditPopover);