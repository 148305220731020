import axios from "axios";
import config from "../config";

export const sendEmailViaTemplate = async ({ to, cc, bcc, attachments = [], from = "noreply@busable.com.au", subject, merge, template }) => {
    try {
        await axios.post(
            "https://api.elasticemail.com/v4/emails/transactional",
            {
                Recipients: {
                    To: to,
                    CC: cc || [],
                    BCC: bcc || [],
                },
                Content: {
                    From: from,
                    Subject: subject,
                    Merge: merge,
                    TemplateName: template,
                    Attachments: attachments,
                },
            },
            {
                headers: {
                    "X-ElasticEmail-ApiKey": config.email.ELASTIC_EMAIL_KEY,
                },
            }
        );
    } catch (error) {
        console.log("Something went wrong while sending email", error);
    }
};
