import Geocoder from "../mapbox-geocoder/geocoder";
import config from "../config";
import React, {memo, useCallback, useEffect, useRef} from "react";
import {renderToStaticMarkup} from "react-dom/server";
import Pin from "./Pin";
import RouteTitle from "./RouteTitle";
import './TripLocation.css';

const TripLocation = ({disabled, ...props}) => {
    const geocoderContainerRef = useRef();
    const geocoder = useRef()

    useEffect(() => {
        if(disabled) {
            geocoder.current?._disable()
        } else {
            geocoder.current?._enable()
        }
    },[disabled, geocoder])

    const renderSuggestions = useCallback((feature) => {
        if (!feature) {
            return ''
        }
        let placeName = feature.place_name.split(',');
        if (feature.place_type.indexOf('stop') > -1) {
            placeName[0] = renderToStaticMarkup(<Pin size={20}
                                                     type={feature.properties.stop.stopType}/>) + placeName[0];
        } else if (feature.place_type.indexOf('route') > -1) {
            placeName[0] = renderToStaticMarkup(<RouteTitle route={feature.properties.route} size='sm'/>);
        }

        return '<div class="mapboxgl-ctrl-geocoder--suggestion"><div class="mapboxgl-ctrl-geocoder--suggestion-title">' + placeName[0] + '</div><div class="mapboxgl-ctrl-geocoder--suggestion-address">' + placeName.splice(1, placeName.length).join(',') + '</div></div>';
    },[])

    return (
        <>
            <div key={props.placeholder+"_query_input"} ref={geocoderContainerRef}/>
            <Geocoder
                mapboxApiAccessToken={config.maps.mabBox}
                containerRef={geocoderContainerRef}
                zoom={16}
                limit={100}
                clearAndBlurOnEsc={true}
                clearOnBlur={false}
                // collapsed={true}
                countries={'AU'}
                marker={false}
                render={renderSuggestions}
                onInit={gc => geocoder.current = gc}
                disabled={disabled}
                // localGeocoder={theLocalGeoCoder}
                {...props}
            />
        </>
    )
}

export default memo(TripLocation);
