import {Button, Divider, Popover, Select, Switch} from "antd";
import React, {useCallback, useEffect, useState} from "react";
import {Input, Modal, Tooltip} from "antd/lib";
import {ReactComponent as History} from '../assets/icons/History.svg';
import RouteNumber from "./RouteNumber";
import {Col, Row} from "react-bootstrap";
import {schedule_options} from "../model/schedule";
import {keyBy} from "lodash/collection";
import {isEqual, sortBy} from "lodash";
import ShiftModal from "./Shift";
import {BusRoute} from "../model/busRoute";
import dayjs from "../dayjs";

const HistoryModal = ({
                          iconOnly,
                          width,
                          popPlacement,
                          disabled,
                          route,
                      }) => {
    const [visible, setVisible] = useState(false);

    return (
        <>
            <Popover placement={popPlacement}
                     title={<span>View history</span>}
                     content={<div><p>View route variant history</p></div>}
                     trigger="hover">
                <Button disabled={disabled}
                        onClick={() => {
                            setVisible(true)
                        }}
                        className="icon-button btn-filled btn-fade"><History/></Button>
            </Popover>
            <Modal
                open={visible}
                width={width || '50%'} style={{top: 60}}
                title={<div className="d-flex align-items-center" style={{gap: "15px"}}><div className={"trip-details-header d-flex flex-row"}><div className={""}><RouteNumber route={route}/></div></div>Route History</div>}
                onCancel={() => {
                    setVisible(false)
                }}
                destroyOnClose={true}
                footer={[
                    <Button key="back" type="primary" className="btn-primary" onClick={() => {
                        setVisible(false);
                    }}>
                        Done
                    </Button>,
                ]}
            >
                <div className="py-4">
                    <ul className="app-list text-md">
                        <li>Created by&nbsp;
                            {route.author ? <strong>{route.author}</strong> :
                                <strong>Unknown</strong>}
                            &nbsp;on&nbsp;
                            <strong>{route.createdAt ? dayjs(route.createdAt).format("lll") : 'Unknown date'}</strong>
                        </li>
                        {route.published > -1 && <li>Approved by&nbsp;
                            <strong>{route.approvedBy ? route.approvedBy : 'Unknown'}</strong>&nbsp;on&nbsp;
                            <strong>{route.approvedAt ? dayjs(route.approvedAt).format("lll") : 'Unknown date'}</strong>
                        </li>}
                        {route.published > 0 && <li>Published by&nbsp;
                            <strong>{route.publishedBy ? route.publishedBy : 'Unknown'}</strong>&nbsp;on&nbsp;
                            <strong>{route.publishedAt ? dayjs(route.publishedAt).format("lll") : 'Unknown date'}</strong>
                        </li>}
                        <li>Last edit by&nbsp;
                            <strong>{route.lastEditor ? route.lastEditor : route.author ? route.author : 'Unknown'}</strong>&nbsp;on&nbsp;
                            <strong>{route.updatedAt ? dayjs(route.updatedAt).format("lll") : route.createdAt ? dayjs(route.createdAt).format("lll") : 'Unknown date'}</strong>
                        </li>
                    </ul>       
                </div>
            </Modal>
        </>
    );
}

export default React.memo(HistoryModal)