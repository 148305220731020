import {
    ApiOutlined,
    CloudDownloadOutlined,
    ColumnWidthOutlined,
    DeleteOutlined,
    LinkOutlined,
    PartitionOutlined,
    RadiusSettingOutlined
} from "@ant-design/icons";
import React, {useCallback, useEffect} from "react";
import MapToolbar from "./MapToolbar";
import {Popover, Tooltip} from "antd";
import {values} from "lodash";
import LoaderButton from "./LoaderButton";
import {FEATURE} from "../model/features";
import {ReactComponent as Edit} from "../assets/icons/Edit.svg";
import {ReactComponent as Check} from "../assets/icons/Check.svg";
import TransfersModal from "../features/Transfers/TransfersModal";
import {Button} from "react-bootstrap";
import {find} from "lodash/collection";

const linkedStopsSelected = selected => {
    return selected && Object.keys(selected).length && Object.keys(selected).every(stopId => {
        return !selected[stopId].master && selected[stopId].linkedStops?.length
    })
}


function StopsMapEditorToolbar({
                                   features, mapContainerRef,
                                   selected, editStop, setEditStop, isLinking, handleUnlinkSelectedStops,
                                   getSelectedPostfix, handleLinkSelectedStops, isMerging, handleMergeStops, syncStop,
                                   getUnverifiedOfSelected, isVerifying, verifyStops,
                                   deleteStops, isDeleting, style, handleImportStops,
                                   measureTool, setMeasureTool,
                                   showRadius, setShowRadius, setTransfersModalOpen
                               }) {

    const allowMerge = useCallback(() => {
        if (!selected || editStop || isMerging || Object.keys(selected).length < 2) {
            return false;
        }
        const selectedStops = values(selected);
        return selectedStops.every(s => !s.master)
    }, [selected, editStop, isMerging])

    const allowSync = useCallback(() => {
        if (!selected || editStop || isMerging || Object.keys(selected).length !== 2) {
            return false;
        }
        const selectedStops = values(selected);
        return selectedStops[0].master !== selectedStops[1].master
    }, [selected, editStop, isMerging])

    const keyPressed = useCallback(async ({key}) => {
        key = key.toLowerCase();
        console.log('Key pressed: ', key)
        if (selected && !editStop && Object.keys(selected).length === 1 && key === 'e') {
            setEditStop({...values(selected)[0], moved: false})
        } else if (selected && !editStop && Object.keys(selected).length === 1 && key === 'm') {
            setMeasureTool(mt => !mt)
        } else if (!isDeleting && selected && !editStop && Object.keys(selected).length > 0 &&
            values(selected).every(s => !s?.master) && ['delete', 'backspace'].includes(key)) {
            await deleteStops(selected)
        }
    }, [selected, setEditStop, editStop, setMeasureTool, deleteStops]);

    useEffect(() => {
        mapContainerRef.current.addEventListener('keyup', keyPressed);
        return () => {
            mapContainerRef.current?.removeEventListener('keyup', keyPressed);
        };
    }, [keyPressed, mapContainerRef]);

    return (
        <MapToolbar style={{...style}}>

            {/*Edit route */}
            {/*<Popover placement="right"*/}
            {/*         title={<span>New point</span>}*/}
            {/*         content={<div>*/}
            {/*             <p>Create a new point at the centre of the map. You will be able to move the point once it is*/}
            {/*                 created.</p>*/}
            {/*         </div>}*/}
            {/*         trigger="hover">*/}
            {/*    <LoaderButton className="mapbox-ctrl-icon" variant={"light"}*/}
            {/*                  onClick={() => handleNewStop()}*/}
            {/*                  disabled={editStop}*/}
            {/*                  feature={FEATURE.stps}>*/}
            {/*        <PlusOutlined/>*/}
            {/*    </LoaderButton>*/}
            {/*</Popover>*/}


            {selected && !editStop && Object.keys(selected).length > 0 && values(selected).every(s => s?.master) ?
                <Popover placement="right"
                         title={<span>Import</span>}
                         content={<div>
                             <p>Import source point(s)</p>
                         </div>}
                         trigger="hover">
                    <LoaderButton className="mapbox-ctrl-icon" variant="light"
                                  onClick={async () => {
                                      await handleImportStops(selected)
                                  }}
                                  feature={FEATURE.stps}>
                        <CloudDownloadOutlined/>
                    </LoaderButton>
                </Popover> :
                <Popover placement="right"
                         title={<span>Edit point (E)</span>}
                         content={<div>
                             <p>{editStop ? `Edit point: ${editStop?.stopName}` : 'Edit selected point'}</p>
                         </div>}
                         trigger="hover">
                    <LoaderButton className="mapbox-ctrl-icon" variant="light"
                                  disabled={!selected || editStop || Object.keys(selected).length !== 1 || values(selected)[0].master}
                                  onClick={() => setEditStop({...values(selected)[0], moved: false})}
                                  feature={FEATURE.stps}>
                        <Edit className="icon-grey"/>
                    </LoaderButton>
                </Popover>
            }
            {/* <Popover placement="right"
                     title={<span>{`${showmasterStops ? 'Hide unused' : 'Show all'} points`}</span>}
                     content={<div>
                         <p>{showmasterStops ? 'Hide unused' : 'Show all'} points including from shared data sources</p>
                     </div>}
                     trigger="hover">
                <LoaderButton className="mapbox-ctrl-icon" variant="light"
                              disabled={editStop}
                              onClick={() => setShowmasterStops(s => !s)}
                              feature={FEATURE.stps}>
                    {!showmasterStops ? <EyeOutlined/> : <EyeInvisibleOutlined/>}
                </LoaderButton>
            </Popover> */}
            {/*Link points*/}
            {features?.access(FEATURE.lnks) &&
                <>
                    <Popover placement="right"
                             title={
                                 <span>Link points</span>}
                             content={<div>
                                 <p>{`Link ${getSelectedPostfix(selected, false)} selected points`}</p>
                             </div>}
                             trigger="hover">
                        <LoaderButton className="mapbox-ctrl-icon" variant="light"
                                      isLoading={isLinking}
                                      disabled={!selected || editStop || isLinking || Object.keys(selected).length < 2 || values(selected).some(s => s.master)}
                                      onClick={async () => {
                                          await handleLinkSelectedStops()
                                      }}
                                      feature={FEATURE.stps}
                        ><LinkOutlined/></LoaderButton>
                    </Popover>
                    <Popover placement="right"
                             title={
                                 <span>Unlink points</span>}
                             content={<div>
                                 <p>{`Unlink ${getSelectedPostfix(selected, false)} points`}</p>
                             </div>}
                             trigger="hover">
                        <LoaderButton className="mapbox-ctrl-icon" variant="light"
                                      isLoading={isLinking}
                                      disabled={!selected || editStop || isLinking || !linkedStopsSelected(selected) || values(selected).some(s => s.master)}
                                      onClick={async () => {
                                          await handleUnlinkSelectedStops()
                                      }}
                                      feature={FEATURE.stps}
                        ><ApiOutlined/></LoaderButton>
                    </Popover>
                </>
            }

            {/*Verify points*/}
            <Popover placement="right"
                     title={<span>Verify points</span>}
                     content={<div>
                         <p>Verify {getSelectedPostfix(getUnverifiedOfSelected(selected, false))} selected points</p>
                     </div>}
                     trigger="hover">
                <LoaderButton className="mapbox-ctrl-icon" variant={"light"}
                              disabled={isVerifying || editStop || !getUnverifiedOfSelected(selected).length || values(selected).some(s => s.master)}
                              isLoading={isVerifying}
                              onClick={async () => {
                                  await verifyStops();
                              }}
                              feature={FEATURE.stps}><Check className="icon-grey-stroke"/>
                </LoaderButton>
            </Popover>

            {/*Merge points*/}
            {allowSync() ?
                <Popover placement="right"
                         title={<span>Merge with source point</span>}
                         content={<div>
                             <p>This will update the selected point name, location and source ID with the source point
                                 '{find(values(selected), ['master', true])?.stopName}'.</p>
                         </div>}
                         trigger="hover">
                    <LoaderButton className="mapbox-ctrl-icon" variant="light"
                                  isLoading={isMerging}
                                  onClick={async () => await syncStop(selected)}
                                  feature={FEATURE.stps}><PartitionOutlined/>
                    </LoaderButton>
                </Popover> : <Popover placement="right"
                                      title={<span>Merge points</span>}
                                      content={<div>
                                          <p>Merge {getSelectedPostfix(selected, false)} points to top most displayed point info card</p>
                                      </div>}
                                      trigger="hover">
                    <LoaderButton className="mapbox-ctrl-icon" variant="light"
                                  disabled={!allowMerge()}
                                  isLoading={isMerging}
                                  onClick={async () => {
                                      await handleMergeStops()
                                  }}
                                  feature={FEATURE.stps}><PartitionOutlined/>
                    </LoaderButton>
                </Popover>
            }

            {/*Transfers selected X*/}
            <Popover placement="right"
                     title={<span>Transfers</span>}
                     content={<div>
                         <p>Transfers
                             from {Object.keys(selected).length === 1 ? values(selected)[0].stopName : "selected stop"}</p>
                     </div>}
                     trigger="hover">
                <TransfersModal width={"70%"} iconOnly={true} popPlacement={"right"}
                                disabled={values(selected).some(s => s.master)}
                                point={Object.keys(selected).length === 1 ? values(selected)[0] : null}
                                setTransfersModalOpen={setTransfersModalOpen}/>
            </Popover>

            {/*Transfers selected X*/}
            <Popover placement="right"
                     title={<span>Measure tool (M)</span>}
                     content={<div>
                         <p>Show stop measure tool</p>
                     </div>}
                     trigger="hover">
                <Button className="mapbox-ctrl-icon" variant="light"
                        onClick={async () => {
                            setMeasureTool(!measureTool)
                        }}
                ><ColumnWidthOutlined/></Button>
            </Popover>

            <Popover placement="right"
                     title={<span>{showRadius ? 'Hide' : 'Show'} point radius</span>}
                     content={<div>
                         <p>{showRadius ? 'Hide' : 'Show'} radius point in metres when point is in edit mode</p>
                     </div>}
                     trigger="hover">
                <Button className="mapbox-ctrl-icon" variant="light"
                        onClick={async () => {
                            setShowRadius(!showRadius)
                        }}
                ><RadiusSettingOutlined/></Button>
            </Popover>

            {/*<Popover placement="right"*/}
            {/*         title={<span>Copy Point ID</span>}*/}
            {/*         content={<div>*/}
            {/*             <p>{!selected || Object.keys(selected).length !== 1 ? 'Select a point to copy' : 'Copy'} the*/}
            {/*                 Busable Point ID to the clipboard</p>*/}
            {/*         </div>}*/}
            {/*         trigger="hover">*/}
            {/*    <Button className="mapbox-ctrl-icon" variant={"light"}*/}
            {/*            disabled={!selected || Object.keys(selected).length !== 1}*/}
            {/*            onClick={() => {*/}
            {/*                navigator.clipboard.writeText(Object.keys(selected)[0]).then(() => {*/}
            {/*                    messageApi.info(<div><p>Point ID copied to clipboard</p></div>, 10)*/}
            {/*                })*/}
            {/*            }}><CopyOutlined/></Button>*/}
            {/*</Popover>*/}
            {/*Delete points X*/}
            <Popover placement="right"
                     title={<span>Delete points (DEL)</span>}
                     content={<div>
                         <p>Delete {getSelectedPostfix(selected, false)} selected points</p>
                     </div>}
                     trigger="hover">
                <LoaderButton className="mapbox-ctrl-icon" variant={"light"}
                              onClick={async (e) => await deleteStops(selected)}
                              disabled={!selected || editStop || isDeleting || Object.keys(selected).length < 1 || values(selected).some(s => s.master)}
                              isLoading={isDeleting}
                              feature={FEATURE.stps}><DeleteOutlined/>
                </LoaderButton>
            </Popover>

        </MapToolbar>);
}


export default React.memo(StopsMapEditorToolbar);
