import React, {useCallback, useMemo, useState} from 'react';
import {Avatar, Button, Empty, Table, Tooltip, Typography} from 'antd';
import './Rosters.css';
import {toHrsMinsSecs} from '../../libs/formatLib';
import {WeeklyRoster} from '../../model/roster';
import EditPopover from '../../components/EditPopover';
import {createAcronym} from '../Charters/CharterItinerary';
import {AllocationModal} from './AllocationModal';
import {DeleteOutlined} from '@ant-design/icons';
import {employeeName} from './SelectEmployeeView';
import {capitalize, find} from 'lodash';
import dayjs from '../../dayjs';
import {WorkItemForDay} from './WorkItemForDay';
import {DriverCompliance} from '../../model/compliance';
import {keys} from 'lodash/object';

const Paragraph = Typography.Paragraph;

function WeeklyRosterTable({
                               employeeData,
                               previousRoster,
                               allVehicles,
                               scenario, setScenario,
                               saveRoster,
                               newRoster,
                               selectedWorkItem,
                               setSelectedWorkItem,
                               allStops, allRoutes, schedules, operator,
                               scroll
                           }) {

    const [showAllOptional, setShowAllOptional] = useState(false);


    const doSelectWorkItem = useCallback((workItem, selectedWorkItem, roster) => {
        if (workItem?.optional) {
            workItem.prevRoster?.removeWorkItem(workItem);
            roster.saveOptionalWorkItems();
            saveRoster([roster, workItem.prevRoster]);
            setSelectedWorkItem(null);
        } else {
            if (selectedWorkItem && selectedWorkItem.workItemId === workItem?.workItemId) {
                setSelectedWorkItem(null);
            } else {
                setSelectedWorkItem(workItem);
            }
        }
    }, [setSelectedWorkItem, saveRoster]);

    const showAllOptionalWorkItems = useCallback((roster) => {
        if (selectedWorkItem) {
            const workItemsToAdd = [];

            scenario.unallocatedRoster.workItems.forEach((workItems, dayOfWeek) => {
                const workItem = workItems.find(wi => wi.duty.shiftBatId === selectedWorkItem.duty.shiftBatId);
                if (workItem) {
                    workItemsToAdd.push({...workItem, optional: true, dayOfWeek});
                }
            });

            roster.addOptionalWorkItems(workItemsToAdd);
            saveRoster(roster);
            setShowAllOptional(true);
        }
    }, [selectedWorkItem, saveRoster, scenario?.unallocatedRoster?.workItems, setShowAllOptional]);

    const canShowAllOptional = useCallback((roster) => {
        if (selectedWorkItem) {
            const workItemsToAdd = [];
            scenario.unallocatedRoster.workItems.forEach((workItems, dayOfWeek) => {
                const workItem = workItems.find(wi => wi.duty.shiftBatId === selectedWorkItem.duty.shiftBatId);
                if (workItem) {
                    workItemsToAdd.push({...workItem, optional: true, dayOfWeek});
                }
            });
            return workItemsToAdd.some(workItem => roster.isDutyPossible(workItem.duty, workItem.dayOfWeek));
        }
    }, [selectedWorkItem, scenario?.unallocatedRoster?.workItems]);

    const hideAllOptionalWorkItems = useCallback((roster) => {
        if (selectedWorkItem) {
            roster.removeOptionalWorkItems();
            roster.addOptionalWorkItems(selectedWorkItem);
            saveRoster(roster);
        }
        setShowAllOptional(false);
    }, [selectedWorkItem, saveRoster, setShowAllOptional]);

    const saveAllOptionalWorkItems = useCallback((roster) => {
        setScenario(scenario => {
            scenario.saveOptionalWorkItemsOnRoster(roster.rosterId);
            return scenario;
        });
        setSelectedWorkItem(null);
        setShowAllOptional(false);
    }, [setScenario, setSelectedWorkItem, setShowAllOptional]);


    const rosterData = useMemo(() => {

        let time = Date.now();
        const data = (scenario?.weeklyRosters || []).map(roster => {
            return WeeklyRoster.from({
                key: roster.rosterId,
                ...roster,
            });
        });
        if (operator?.opts?.compliance?.accept) {

            data.forEach(keyedRoster => {
                const employeeIds = keyedRoster?.getAllEmployeesId();
                employeeIds.forEach(employeeId => {
                    let workItems = keyedRoster?.getWorkItemsForEmployee(employeeId).concat(previousRoster?.getWorkItemsForEmployee(employeeId) || []);
                    if (workItems?.length) {
                        const compliance = DriverCompliance.checkCompliance({employeeId, workItems});
                        keys(compliance).forEach(workItemId => {
                            const workItem = find(workItems, wi => wi.workItemId === workItemId);
                            workItem.compliance = compliance[workItemId];
                            if (!workItem.compliance?.length) {
                                delete workItem.compliance;
                            }
                        });
                    }
                });
            });
        }
        console.log('WeeklyRoster data: ', Date.now() - time, 'ms');
        return data;
    }, [scenario?.weeklyRosters, previousRoster, operator]);

    const rosterRender = useCallback((roster) => {
        const time = Date.now();
        if (!roster) return <div>Loading...</div>;
        const d = <div key={'roster-row-' + roster.rosterId} span={3}
                       className="d-flex flex-column roster-cell-main" style={{gap: 8}}>
            <div className="roster-name">
                {roster.rosterId !== 'unallocated' &&
                    <div className="d-flex align-items-center justify-content-between">
                        <EditPopover title={'Edit roster name'}
                                     save={value => {
                                         roster.rosterName = value;
                                         saveRoster(roster);
                                     }}
                                     value={roster.rosterName}/>
                        <Button className={'icon-button btn-filled btn-error btn-delete'} icon={<DeleteOutlined/>}
                                onClick={() => {
                                    setScenario(scenario => {
                                        scenario.removeRoster(roster);
                                        return scenario.clone();
                                    });
                                }}/>
                    </div>}
                <div className="roster-time mt-1">{toHrsMinsSecs(roster.getTotalTime())}</div>
            </div>
            {roster.rosterId !== 'unallocated' && <>
                <AllocationModal
                    roster={roster}
                    scenario={scenario}
                    isDefault={true}
                    employeeId={roster.defaultEmployeeId}
                    vehicleId={roster.defaultVehicleId}
                    employeeData={employeeData}
                    allVehicles={allVehicles}
                    save={({employee, vehicle}) => {
                        roster.setDefaultEmployee(employee);
                        roster.setDefaultVehicle(vehicle);
                        saveRoster(roster);
                    }}
                />
                <div className="d-flex flex-column" style={{gap: 8}}>
                    {roster?.getEmployeeIds && roster.getEmployeeIds().map(employeeId => {
                        const complianceForEmployee = [];
                        if (operator?.opts?.compliance?.accept) {
                            rosterData.forEach(r => {
                                r.getWorkItemsForEmployee(employeeId).forEach(wi => {
                                    if (wi.compliance) {
                                        complianceForEmployee.push(...wi.compliance);
                                    }
                                });
                            });
                        }
                        const employee = employeeData.getEmployee(employeeId);
                        const employeeTime = toHrsMinsSecs(scenario.getTotalTime('employee', employeeId));
                        const name = employeeName(employee) || 'Unknown';
                        return (
                            <div key={roster.rosterId + '_employee_' + employeeId}
                                 className={`roster-employee`}>
                                <Tooltip overlayClassName="fixed-tooltip"
                                         title={<div>
                                             <div>{`${name} (${employeeTime})`}</div>
                                             <div>{complianceForEmployee.map(c => <div
                                                 className="mt-3">{c}</div>)}</div>
                                         </div>}
                                         placement="bottom">
                                    <Avatar size={24}
                                            style={{
                                                backgroundColor: complianceForEmployee?.length ? '#FFE6E6' : '#C1C5CE',
                                                color: complianceForEmployee?.length ? '#FF4D4D' : null,
                                                borderColor: complianceForEmployee?.length ? '#FDCCC9' : null
                                            }}>{createAcronym(name)}</Avatar>
                                </Tooltip>
                                <div>
                                    <div className="driver-name">{name}</div>
                                    <div className="roster-time">{employeeTime}</div>
                                </div>
                            </div>
                        );
                    })}
                </div>

                <div className="d-flex flex-wrap mt-2" style={{gap: 6}}>
                    {roster?.getVehicleIds && roster.getVehicleIds().map(vehicleId => {
                        const vehicle = allVehicles[vehicleId];
                        const vehicleName = vehicle?.vehicleName || 'Unknown';
                        return (
                            <Tooltip className="vehicle-name"
                                     key={roster.rosterId + '_tt_vehicle_' + vehicleId}
                                     title={`${vehicleName} (${vehicle?.vehicleRego})`}
                                     placement="bottom">
                                {vehicleName}
                            </Tooltip>
                        );
                    })}
                </div>
            </>}
            {roster.rosterId !== 'unallocated' && selectedWorkItem && roster.contains(selectedWorkItem) && !showAllOptional && canShowAllOptional(roster) ?
                <Button className="icon-button" onClick={() => showAllOptionalWorkItems(roster)}>Fill</Button> :
                roster.rosterId !== 'unallocated' && selectedWorkItem && roster.contains(selectedWorkItem) && showAllOptional ? <>
                        <Button className="icon-button" onClick={() => hideAllOptionalWorkItems(roster)}>Hide</Button>
                        <Button className="icon-button btn-success"
                                onClick={() => saveAllOptionalWorkItems(roster)}>Save</Button></>
                    : <></>}
        </div>;
        // console.log('WeeklyRosterTable rosterRender: ', Date.now() - time, 'ms');
        return d;
    }, [employeeData, allVehicles, scenario, setScenario, saveRoster, showAllOptional, selectedWorkItem,
        showAllOptionalWorkItems, hideAllOptionalWorkItems, saveAllOptionalWorkItems, rosterData, operator]);

    const workItemRenderer = useCallback((workItems, roster, selectedWorkItem, statusColour = '#C1C5CE') => {
        const time = Date.now();
        const w = <WorkItemForDay scenario={scenario} roster={roster} workItems={workItems}
                                  selectedWorkItem={selectedWorkItem}
                                  onSelectWorkItem={doSelectWorkItem} statusColour={statusColour}
                                  employeeData={employeeData} allVehicles={allVehicles} saveRoster={saveRoster}
                                  allStops={allStops} allRoutes={allRoutes} schedules={schedules}
                                  operator={operator}
        />;
        // console.log('WeeklyRosterTable workItemRenderer: ', Date.now() - time, 'ms');
        return w;
    }, [setSelectedWorkItem, doSelectWorkItem, scenario, employeeData, allVehicles, saveRoster]);

    const workItemColumns = useMemo(() => {
        const time = Date.now();
        const wiColumns = Array(7).fill().map((_, day) => ({
            title: capitalize(dayjs().weekday(day).format('dddd')), dataIndex: day,
            render: (_, roster) => {
                if (!roster) return <div>Loading...</div>;
                return workItemRenderer(roster.workItems[day], roster, selectedWorkItem);
            }
        }));
        // console.log('WeeklyRosterTable workItemColumns: ', Date.now() - time, 'ms');
        return wiColumns;
    }, [workItemRenderer, selectedWorkItem]);

    const columns = useMemo(() => {
        const time = Date.now();
        const columns = [{
            title: 'Roster',
            render: (_, roster) => rosterRender(roster)
        }].concat(workItemColumns);
        // console.log('WeeklyRosterTable columns: ', Date.now() - time, 'ms');
        return columns;
    }, [rosterRender, workItemColumns]);

    return (<>
        <div className={'weekly-roster-table allocated-roster-table'}>
            <Table dataSource={rosterData} columns={columns}
                // expandable={{
                //     onExpand: (expanded, record) => {
                //         if (expanded) {
                //             setExpandedRosterIds([...expandedRosterIds, record.rosterId]);
                //         } else {
                //             setExpandedRosterIds(expandedRosterIds.filter(id => id !== record.rosterId));
                //         }
                //     },
                //     // expandedRowRender: (record) => (
                //     //     <p
                //     //         style={{
                //     //             margin: 0,
                //     //         }}
                //     //     >
                //     //         {record.description}
                //     //     </p>
                //     // ),
                //     expandRowByClick: true,
                // }}
                   pagination={false}
                //    virtual
                //    scroll={scroll || {
                //        y: 300,
                //    }}
                   locale={{
                       emptyText: <Empty description="No Data"><Button onClick={newRoster}>New roster</Button></Empty>
                   }}
            />
        </div>
    </>);
}

export default React.memo(WeeklyRosterTable);