export class LinkedList {
    constructor() {
        this.nodes = [];
    }

    get size() {
        return this.nodes.length;
    }

    get head() {
        return this.size ? this.nodes[0] : null;
    }

    get tail() {
        return this.size ? this.nodes[this.size - 1] : null;
    }

    insertAt(index, value) {
        const previousNode = this.nodes[index - 1] || null;
        const nextNode = this.nodes[index] || null;
        const node = {value, next: nextNode};

        if (previousNode) previousNode.next = node;
        this.nodes.splice(index, 0, node);
    }

    insertFirst(value) {
        this.insertAt(0, value);
    }

    insertLast(value) {
        this.insertAt(this.size, value);
    }

    getAt(index) {
        return this.nodes[index];
    }

    removeAt(index) {
        const previousNode = this.nodes[index - 1];
        const nextNode = this.nodes[index + 1] || null;

        if (previousNode) previousNode.next = nextNode;

        return this.nodes.splice(index, 1);
    }

    clear() {
        this.nodes = [];
    }

    reverse() {
        this.nodes = this.nodes.reduce(
            (acc, {value}) => [{value, next: acc[0] || null}, ...acc],
            []
        );
    }

    * [Symbol.iterator]() {
        yield* this.nodes;
    }
}

/**
 * @description A function that toggles a string in an array.
 * For example, if the array is ['a', 'b', 'c'] and the string is 'b', the function will return ['a', 'c'].
 * If the string is 'd', the function will return ['a', 'b', 'c', 'd'].
 */
export const toggleStrInArray = (array, str) => {
    array = array || []
    array.includes(str) ? array.splice(array.indexOf(str), 1) : array.push(str)
    return [...array];
}