import {useEffect, useState} from 'react';
import {vehicleModelData} from '../services/ModelService';
import {find, values} from 'lodash';
import useJobsForDate from './useJobsForDate';
import {ShiftBat} from '../model/shiftBat';

const useAllVehicles = ({filter = null} = {}) => {
    const [allVehicles, setAllVehicles] = useState(null);
    const [loadedVehicles, setLoadedVehicles] = useState(null);
    const [allVehiclesArray, setAllVehiclesArray] = useState([]);
    const [filteredVehicles, setFilteredVehicles] = useState([]);
    const {vehicleJobs} = useJobsForDate({date: filter?.date});

    useEffect(() => {
        const load = async () => {
            try {
                const vehicles = await vehicleModelData.getAll();
                setAllVehicles(vehicles);
            } catch (e) {
                console.log(e);
            }
        };

        load().then(() => console.log('Vehicle List Updated.'));

        const vehiclesListener = vehicleModelData.addListener({
            setterFn: setAllVehicles, loaded: vehicles => {
                setAllVehicles(vehicles);
            }
        });

        return () => {
            if (vehiclesListener)
                vehicleModelData.removeListener(vehiclesListener);
        };
    }, [setAllVehicles]);

    useEffect(() => {
        const allocatedVehicleJobs = vehicleJobs?.filter(vehicleJob => vehicleJob.allocation);
        allocatedVehicleJobs?.forEach(vehicleJob => {
            const vehicle = allVehicles[vehicleJob.allocationId];
            vehicle.addJob(vehicleJob);
        });
        setLoadedVehicles({...allVehicles});
        setAllVehiclesArray(values(allVehicles));
    }, [allVehicles, setLoadedVehicles]);

    useEffect(() => {
        if (allVehiclesArray && typeof filter === 'function') {
            setFilteredVehicles(allVehiclesArray.filter(filter));
            return;
        } else if (allVehiclesArray && filter?.duty) {
            let filteredVehicles = allVehiclesArray.map(vehicle => vehicle.clone());
            vehicleJobs.forEach(j => find(filteredVehicles, {vehicleId: j.allocationId}).addJob(j));
            let {duty} = filter;
            if (!duty.getActualStartTime) {
                duty = new ShiftBat(duty);
            }
            Promise.all(filteredVehicles.map(async vehicle => {
                await vehicle.addJobOption(duty, 'shiftbat', filter.date);
                return vehicle;
            })).then(filteredVehicles => {
                filteredVehicles = filteredVehicles.filter(vehicle => vehicle.jobOption);
                filteredVehicles.sort((j1, j2) =>
                    j1.jobOption.stats.total.distance - j2.jobOption.stats.total.distance);
                setFilteredVehicles(filteredVehicles);
            });
            return;

            // const filteredVehicleMap = keyBy(filteredVehicles, 'vehicleId');
            //
            // let potentialLeadingVehicleJobs = allocatedVehicleJobs
            //     .filter(vehicleJob => vehicleJob.getActualEndTime().isBefore(duty.getActualStartTime()));
            //
            // potentialLeadingVehicleJobs.forEach(leadingVehicleJob => {
            //     const vehicle = filteredVehicleMap[leadingVehicleJob.allocationId];
            //     vehicle.prospectiveJob = vehicle.prospectiveJob || {};
            //     vehicle.prospectiveJob.duty = duty;
            //     vehicle.prospectiveJob.bestLeadingDeadrun = leadingVehicleJob.getDuty().getBestDeadrun(duty, filter.deadrunSortFn);
            // });
            //
            // // filter vehiclejobs whose actual start time is after the end time of the duty
            // let potentialTrailingVehicleJobs = allocatedVehicleJobs
            //     .filter(vehicleJob => vehicleJob.getActualStartTime().isAfter(duty.getActualEndTime()));
            // potentialTrailingVehicleJobs.forEach(trailingVehicleJob => {
            //     const vehicle = filteredVehicleMap[trailingVehicleJob.allocationId];
            //     vehicle.prospectiveJob = vehicle.prospectiveJob || {};
            //     vehicle.prospectiveJob.duty = duty;
            //     vehicle.prospectiveJob.bestTrailingDeadrun = duty.getBestDeadrun(trailingVehicleJob.getDuty(), filter.deadrunSortFn);
            // });
        }
        setFilteredVehicles(values(allVehicles));
    }, [allVehiclesArray, filter, setFilteredVehicles]);

    // useEffect(() => {
    //     const vehiclesListener = vehicleModelData.addListener({
    //         setterFn: setAllVehicles, loaded: stops => {
    //             setAllVehicles(stops);
    //         }
    //     });
    //
    //     return () => {
    //         if (vehiclesListener)
    //             vehicleModelData.removeListener(vehiclesListener)
    //     }
    // }, [])

    return {
        allVehicles, allVehiclesArray, filteredVehicles,
    };
};

export default useAllVehicles;

