import {Select} from "antd";
import React, {useEffect, useState} from "react";
import {vehicleModelData} from "../../services/ModelService";
import {values} from "lodash";

function VehicleSelect({onSelect, vehicleFilter = () => true, ...props}) {
    const [vehicles, setVehicles] = useState({})
    const [vehicleOpts, setVehicleOpts] = useState([])
    const [vehicleId, setVehicleId] = useState(props.value)

    useEffect(() => {
        let vehicleListener = vehicleModelData.addListener({
            loaded: ch => setVehicles(ch),
            setterFn: setVehicles
        })

        return () => {
            vehicleModelData.removeListener(vehicleListener)
        }
    }, [setVehicles])

    useEffect(() => {
        setVehicleOpts(values(vehicles)
            .filter(vehicleFilter)
            .map(vehicle => ({
                value: vehicle.vehicleId,
                label: `${vehicle.vehicleName || vehicle.vehicleRego}`
            })));
    }, [vehicles, setVehicleOpts])


    return <Select value={vehicleId}
                   onChange={vehicleId => {
                       setVehicleId(vehicleId);
                       onSelect(vehicleId, vehicles[vehicleId]);
                   }}
                   options={vehicleOpts} {...props}/>
}

export default React.memo(VehicleSelect);