import React, {useState} from 'react';
import {Popconfirm} from 'antd/lib';
import {Button, Tooltip} from 'antd';
import {ReactComponent as Close} from '../assets/icons/Close.svg';
import {ReactComponent as Undo} from '../assets/icons/Undo.svg';
import LoaderButton from './LoaderButton';
import {ReactComponent as Reset} from '../assets/icons/Reset.svg';
import {ReactComponent as Redo} from '../assets/icons/Redo.svg';
import {ReactComponent as Check} from '../assets/icons/Check.svg';
import {ReactComponent as Print} from '../assets/icons/Print.svg';
import {ReactComponent as Edit} from '../assets/icons/Edit.svg';
import {Prompt, useHistory} from 'react-router-dom';
import EmailButton from './EmailButton';
import DownloadButton from './DownloadButton';
import {noop} from 'lodash';

function SaveMenu({
                      editMode,
                      setEditMode,
                      modelInstance,
                      initialInstanceRef,
                      controls,
                      save,
                      updated,
                      id,
                      disableEditForRow,
                      setPrint,
                      showPrint,
                      closeView,
                      showSend,
                      mailData,
                      modelService,
                      setIsCancelling = noop,
                      isCancelling,
                      listUrl,
                      validFn,
                      files,
    onDownloadClicked,
                      children
                  }) {

    const [confirmedExit, setConfirmedExit] = useState(false);

    const history = useHistory();

    const promptExit = () => updated && !confirmedExit;
    return (<>{

        editMode ?
            <div className="d-flex align-items-center justify-content-between page-main-controls top-float">
                {/*<div className="d-flex align-items-center justify-content-between page-main-controls mb-0">*/}

                <Prompt
                    when={updated && !confirmedExit}
                    message="You have unsaved changes. Are you sure you want to leave?"
                />

                <div className="page-main-controls">
                    <div className="d-flex page-sec-controls">
                        {children}
                        {controls.backLength > 0 || updated ?
                            <Popconfirm title={'You have unsaved changes. Are you sure you want to leave?'}
                                        onConfirm={e => {
                                            setEditMode(false);
                                            setConfirmedExit(true);
                                            if (id === '_new_') {
                                                // Hack to fix double confirmation
                                                setTimeout(() => history.push(listUrl), 1);
                                            } else {
                                                setTimeout(() => controls.go(-controls.backLength), 1);
                                            }
                                        }}>
                                <Button type="primary" className="icon-button btn-filled btn-light icon-10"
                                        icon={<Close/>}
                                > Close</Button></Popconfirm> :
                            <Button type="primary" className="icon-button btn-filled btn-light icon-10"
                                    icon={<Close/>}
                                    onClick={() => {
                                        setEditMode(false);
                                        if (id === '_new_') {
                                            history.push(listUrl);
                                        } else {
                                            controls.go(-controls.backLength);
                                        }
                                    }}
                            > Close</Button>
                        }
                        <Button type="primary" className="icon-button btn-filled" icon={<Undo/>}
                                disabled={!controls.canUndo || (disableEditForRow && disableEditForRow())}
                                onClick={() => {
                                    controls.undo();
                                }}
                        >Undo ({controls.backLength})</Button>
                        <Popconfirm
                            title="Reset all changes"
                            description="Reset all changes since loading?"
                            onConfirm={() => {
                                setIsCancelling(true);
                                if (id === '_new_') {
                                    modelService.delete(id, true).then(() => {
                                        console.log('Deleted new sb.');
                                    }).catch(e => {
                                        console.log(e);
                                    }).finally(() => {
                                        setIsCancelling(false);
                                        setEditMode(false);
                                        history.push(listUrl);
                                    });
                                } else if (initialInstanceRef.current) {
                                    save(initialInstanceRef.current).then(() => {
                                        console.log('Saved original sb.');
                                        controls.reset(initialInstanceRef.current);
                                    }).catch(e => {
                                        console.log(e);
                                    }).finally(() => {
                                        setIsCancelling(false);
                                        setEditMode(false);
                                    });
                                } else {
                                    setIsCancelling(false);
                                    setEditMode(false);
                                }
                            }}
                            okText="Yes"
                            cancelText="No">
                            <LoaderButton
                                isLoading={isCancelling}
                                type="primary"
                                icon={<Reset/>}
                                disabled={controls.backLength === 0}
                                className="icon-button btn-filled btn-error"
                            >Reset</LoaderButton>
                        </Popconfirm>
                        <Button type="primary" className="icon-button btn-filled" icon={<Redo/>}
                                disabled={!controls.canRedo || (disableEditForRow && disableEditForRow())}
                                onClick={() => {
                                    controls.redo();
                                }}
                        >Redo ({controls.forwardLength})</Button>
                        <Tooltip
                            title={validFn && !validFn() ? 'Please fill all required fields before continuing.' : ''}>
                            <Button type="primary" className="icon-button btn-filled btn-success"
                                    icon={<Check/>}
                                    disabled={validFn && !validFn()}
                                    onClick={() => {
                                        save(modelInstance, true);
                                    }}
                            >Save</Button></Tooltip>
                    </div>
                </div>
            </div>
            :
            <div className="d-flex align-items-center justify-content-between page-main-controls top-float">
                <div className="d-flex page-sec-controls">
                    {closeView ?
                        <Button type="primary" className="icon-button btn-filled btn-light icon-10"
                                icon={<Close/>}
                                onClick={() => {
                                    history.push(listUrl);
                                }}
                        >Close</Button>
                        : <></>}
                    {setPrint ?
                        <Button type="primary" className="icon-button btn-filled" icon={<Print/>}
                                onClick={async event => {
                                    event.preventDefault();
                                    event.stopPropagation();
                                    setPrint(true);
                                }}>Print</Button> :
                        <></>}
                    {showPrint ?
                        <Button type="primary" className="icon-button btn-filled" icon={<Print/>} onClick={() => {
                            window.print();
                        }}>Print</Button> :
                        <></>}
                    {showSend ? <EmailButton mail={mailData}/> : <></>}
                    {files?.length || onDownloadClicked ? <DownloadButton files={files} onDownloadClicked={onDownloadClicked}/> : <></>}
                    <Button type="primary" className="icon-button btn-filled" onClick={e => {
                        initialInstanceRef.current = modelInstance.clone();
                        controls.reset(initialInstanceRef.current);
                        setEditMode(true);
                    }}
                            icon={<Edit/>}>Edit</Button>
                </div>
            </div>
    }</>);
}

export default SaveMenu;
