import { cloneDeep } from "lodash";
import config from "../config";

export class Customer {
    constructor(props){
        this.customerId = "_";
        Object.assign(this, props);

        this.companyId = config?.operator?.companyId
    }

    isValid() {
        return true
    }

    clone() {
        return new Customer(cloneDeep(this))
    }
}