import React, {useRef, useState} from "react";
import {GlobalOutlined} from "@ant-design/icons";
import {Button, Image, Overlay} from "react-bootstrap";

export default function MapLayerToolbar({setLayer}) {

    const [show, setShow] = useState(false);
    const target = useRef(null);

    const overlay = (target, show) => (
        <Overlay target={target.current} show={show} placement="right">
            <div
                style={{
                    backgroundColor: "white",
                    marginLeft: '10px',
                    marginTop: '34px',
                    color: 'black',
                    borderRadius: 3,
                    border: '1px solid #ccc',
                    textAlign: "center",
                }}
            >
                <div className='d-flex flex-row'>
                <div className="p-2">
                        <Button variant={"light"}
                                onClick={() => {
                                    setLayer("light");
                                    setShow(!show)
                                }}>
                            <Image title={"Default"}
                                   src="https://maps.gstatic.com/tactile/layerswitcher/ic_default-1x.png"
                                   rounded/>
                        </Button>
                        <p style={{margin: "0px"}}>Default</p>
                    </div>
                    <div className="p-2">
                        <Button variant={"street"}
                                onClick={() => {
                                    setLayer("street");
                                    setShow(!show)
                                }}>
                            <Image title={"Street"}
                                   src="https://maps.gstatic.com/tactile/layerswitcher/ic_transit-1x.png"
                                   rounded/>
                        </Button>
                        <p style={{margin: "0px"}}>Street</p>
                    </div>

                    {/*<div className="p-2">*/}
                    {/*    <Button variant={"light"} onClick={() => setLayer("dark")}>*/}
                    {/*        <Image title={"Dark"}*/}
                    {/*               src="https://maps.gstatic.com/tactile/layerswitcher/ic_default-1x.png"*/}
                    {/*               rounded/>*/}
                    {/*    </Button>*/}
                    {/*</div>*/}

                    <div className="p-2">
                        <Button variant={"light"}
                                onClick={() => {
                                    setLayer("satellite");
                                    setShow(!show)
                                }}>
                            <Image title={"Satellite"}
                                   src="https://maps.gstatic.com/tactile/layerswitcher/ic_satellite-1x.png"
                                   rounded/>
                        </Button>
                        <p style={{margin: "0px"}}>Satellite</p>
                    </div>

                    <div className="p-2">
                        <Button variant={"light"}
                                onClick={() => {
                                    setLayer("six");
                                    setShow(!show)
                                }}>
                            <Image title={"SIX Maps"}
                                   src="/nsw.png"
                                   width={48}
                                   height={48}
                                   rounded/>
                        </Button>
                        <p style={{margin: "0px"}}>SIX Maps</p>
                    </div>
                </div>
            </div>
        </Overlay>
    );

    return (
        <>
        {/*<div style={{position: "absolute", top: 110, left: 10}}>*/}
        {/*    <div className="mapboxgl-ctrl mapboxgl-ctrl-group">*/}
                <Button className="mapbox-ctrl-icon" variant={"light"} ref={target}
                        onClick={() => setShow(!show)}>
                    <GlobalOutlined/>
                </Button>
                {overlay(target, show)}
            {/*</div>*/}
        {/*</div>*/}
        </>
    )
}

// export default React.memo(MapLayerToolbar);

