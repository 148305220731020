import React, {useCallback, useState} from 'react';
import {Select} from 'antd';
import config from "../config";
import {useDebounce} from "rooks";

const AddressLookup = ({address, setAddress, limit = 5, type = 'amenity', ...props}) => {
    const [options, setOptions] = useState([{value: address}]);

    const handleSearch = useCallback(value => {
        if (!value) {
            setOptions([]);
            return;
        }

        let options = [{value: address}, {value}]

        fetch(`https://api.geoapify.com/v1/geocode/search?text=${value}&apiKey=${config.maps.geoapify.ctrxs}&filter=countrycode:au&limit=${limit}&type=${type}`)
            .then((response) => response.json())
            .then((data) => {
                options = options.concat(data.features.map((feature) => ({
                    value: `${feature.properties.address_line1}|${feature.properties.suburb || feature.properties.town || feature.properties.city}|${feature.properties.state}|${feature.properties.postcode}|${feature.properties.lat}|${feature.properties.lon}`,
                    label: feature.properties.formatted,
                })));
                setOptions(options);
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
                setOptions([{value: address}, {value}]);
            });
    }, [setOptions, address])

    return (
        <Select
            showSearch
            placeholder="Search for an address"
            defaultActiveFirstOption={false}
            filterOption={false}
            value={address}
            onSearch={handleSearch}
            notFoundContent={null}
            options={options}
            onSelect={(value, option) => {
                console.log(value, option);
                setAddress(value);
            }}
            {...props}
        />
    );
};

export default AddressLookup;
